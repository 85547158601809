import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
//import { ContractExtension } from '../../dashboard/dashboard-contract-details/dashboard-contract-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogReprocureContractComponent } from '../../dialogs/dialog-reprocure-contract/dialog-reprocure-contract.component';

// const EXTENSION_HISTORY_DATA: ContractExtension[] = [
//   {
//       dateExtended: new Date('2019/Sep/23'),
//       extensionPeriod: '12 Months',
//       extensionPeriodRemaining: '36 Months'
//   }
// ];

@Component({
  selector: 'app-dialog-extend-contract',
  templateUrl: './dialog-extend-contract.component.html',
  styleUrls: ['./dialog-extend-contract.component.scss']
})
export class DialogExtendContractComponent implements OnInit {

  extensionHistoryDisplayedColumns = ['ExtendedDate', 'ExtendedPeiod', 'RemainingExtension'];
  extensionHistoryDataSource:any;
  //extensionHistoryDataSource = new MatTableDataSource(EXTENSION_HISTORY_DATA);
  ContrcatTitle:string;
  ExtensionPeriod:string;
  ContractExtensionPeriod:any=0;
  ExtendContractHide:boolean=true;
  AssignProjectbtn:boolean=false;
  //ExtensionPeriods:any;
  constructor(private route:ActivatedRoute, public dialog: MatDialog, private DbService:ProjectDatabaseService,public dialogRef: MatDialogRef<DialogExtendContractComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private router:Router) {
    if(this.data.ContactID !=null)
    {
      this.getContractByID(this.data.ContactID);
      if(sessionStorage.getItem("usertype")!="Procurement Officer")
      {
        this.AssignProjectbtn=true;
      }
      //this.getExtensionPeriod();
      //this.getExtendData(this.data.ContactID);
      // if(this.data.HideAssignBtn =="true")
      // {
      //   this.AssignProjectbtn=false;
      // }
    }
    else
    {
      this.dialog.closeAll();
    }
   }

  ngOnInit() {
  }

  getContractByID(ContractID:any)
  {
    this.DbService.getContractData(ContractID).subscribe((data:any) =>
    {
      console.log(data);
      this.ContrcatTitle=data.ContractTitle;
      this.ExtensionPeriod=data.ExtentionPeriod +" Months";
      this.ContractExtensionPeriod=data.ExtentionPeriod;

      this.getExtendData(ContractID);
    });
  }
  // getExtensionPeriod()
  // {
  //   this.DbService.getExtensionPeriod().subscribe((data:any) =>
  //   {
  //     this.ExtensionPeriods=data.value;
  //   });
  // }
  getExtendData(ContractID:any)
  {
    this.DbService.getContractExtendbyContractID(ContractID).subscribe((data:any) =>
    {
      this.extensionHistoryDataSource = new MatTableDataSource(data);
      let TotalExtensionCount=0;
      
      for(let i=0;i<data.length;i++)
      {
        TotalExtensionCount+=data[i].ExtendedPeiod;
      }
      if(this.ContractExtensionPeriod == TotalExtensionCount)
      {
        this.ExtendContractHide=false;
      }
    });
  }

  InsertExtendData(ExtnPeriod:any)
  {
    if(ExtnPeriod !="")
    {
      if(ExtnPeriod !="0")
      {
        this.DbService.AddContractExtend(this.data.ContactID,ExtnPeriod).subscribe((data:any)=>
        {
          if(data.value =="Created")
          {
            //alert("This contract has been extended.");
            if(sessionStorage.getItem("usertype")=="Procurement Officer")
            {
              this.DbService.ContractExtendConfirmationMailto_TM(sessionStorage.getItem("ContactID"),this.data.ContactID,ExtnPeriod).subscribe((data:any) =>
              {
                if(data.RetStatus !="Success"){return;}
              });
            }
            alert("This Contract has been successfully extended. Please remember to update the Estimated Contract Value & Savings.");
            this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: this.data.ContactID,ViewExtension:"true"}});
            this.dialog.closeAll();
            //this.getExtendData(this.data.ContactID);
          }
          else if(data.value =="Greater than Max Extension")
          {
            alert("Extension Period should be less than or equal to Contract Extension Period");
          }
          else
          {
            alert(data.value);
          }
        });
      }
      else
      {
        alert("Extension Period must be greater than zero");
      }
    
    }
    else
    {
      alert("Enter Extension Period");
    }
   
  }

  AllowcateProject()
  {
    const dialogRef = this.dialog.open(DialogReprocureContractComponent, { data: {ContractID:this.data.ContactID, ContractTitle:this.ContrcatTitle, OrgName:this.data.OrgName,Page:"Extend"},
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        console.log(sessionStorage.getItem("AllowcationList"));
    });
  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;
  
    if (!reg.test(input)) {
      e.preventDefault();
    }
  }
  
}
