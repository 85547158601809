import { Component, OnInit, ElementRef, ChangeDetectorRef, ViewContainerRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectDatabaseService } from '../project-database.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate, DatePipe, JsonPipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardContractsNewContractService } from '../../dashboard/dashboard-contracts-new-contract/dashboard-contracts-new-contract.service';
import { DialogAddNewProjectfinancialsComponent } from '../../dialogs/dialog-add-new-projectfinancials/dialog-add-new-projectfinancials.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardContractDetailsService } from '../dashboard-contract-details/dashboard-contract-details.service';
import { DialogAddNewTaskComponent } from '../../dialogs/dialog-add-new-task/dialog-add-new-task.component';
import { LoginService } from '../../login/login.service';
import { AlertComponent } from '../../alert/alert.component';
import { MatTabChangeEvent } from '@angular/material';
import { DialogReasonforProjectapproverejectComponent } from '../../dialogs/dialog-reasonfor-projectapprovereject/dialog-reasonfor-projectapprovereject.component';
import { MatSort, Sort } from '@angular/material/sort';
import { SettingsService } from '../../settings/settings.service';
import { debug } from 'util';

export interface ProjectFinanaceData {
  ContractSpend: number;
  ContractSavings: number;
  FinanceYeardisplay: string;
}
export interface ProjectFinanaceDataFinal {
  ContractSpend: number;
  ContractSavings: number;
  FinanceYeardisplay: string;
  FinancialYear: string;
}

export interface ProjectMileStoneData {
  FK_ProjectPhaseID: string,
  StartDate: string,
  EndDate: string,
  PhaseStatus: string
}
// export interface YearlyFinancials {
//   financialYear: string;
//   projectedSpend: any;
//   projectedSavings: any;
// }

// const YEARLY_FINANCIALS_DATA: YearlyFinancials[] = [
//   {
//       financialYear: 'April 2019 - March 2020',
//       projectedSpend: 2760000,
//       projectedSavings: 230000      
//   },
//   {
//       financialYear: 'April 2020 - March 2021',
//       projectedSpend: 3000000,
//       projectedSavings: 2500000
//   },
//   {
//       financialYear: 'April 2021 - March 2022',
//       projectedSpend: 240000,
//       projectedSavings: 20000
//   }
// ];

@Component({
  selector: 'app-dashboard-projects-new-project',
  templateUrl: './dashboard-projects-new-project.component.html',
  styleUrls: ['./dashboard-projects-new-project.component.css']
})
export class DashboardProjectsNewProjectComponent implements OnInit {
  ShowpopupWindow: boolean = false;
  ProjectPlan: FormGroup;
  projectFormData: any;
  FinanceData: ProjectFinanaceData[] = [];
  FinanceDataFinal: ProjectFinanaceDataFinal[] = [];
  MileStonedata: ProjectMileStoneData[] = [];
  Taskdata: any;
  Charttitle: string;
  SubCategorySelected: any;
  Charttype: string;
  ChartcolumnNames: any;
  Chartoptions: any;
  Chartheight: any;
  Showchart: boolean = false;
  HideChartDiv: boolean = true;
  disabled: boolean = true;
  InitialSubCategory: any;
  ContractType: any;
  NewProject: FormGroup;
  MileStoneDetails: FormGroup;
  submitted: boolean = false;
  Organisation: any;
  Category: any;
  SubCategory: any;
  ExtensionPeriod: any;
  ContractData: any;
  today = new Date();
  OrganisationID: any;
  CategoryID: any;
  SubCategoryID: any;
  ExtensionID: any;
  NotNew: boolean = false;
  DisbaleExtensionPeriod: boolean = false;
  NewFinance: boolean = true;
  //yearlyFinancialsDisplayedColumns = ['FinanceYear', 'ProjectSpend', 'ContractSpend', 'ProjectSavings','ContractSavings'];
  yearlyFinancialsDisplayedColumns = ['FinanceYear', 'ContractSpend', 'ContractSavings', 'action'];
  selectedIndex: number;
  //yearlyFinancialsDataSource = new MatTableDataSource(YEARLY_FINANCIALS_DATA);
  FinanceDataDeleteOption: boolean = false;
  yearlyFinancialsDataSource: any;
  ProjectedContractSpend: any = 0;
  ProjectedAllualSpend: any = 0;
  ProjectedContractSavings: any = 0;
  ProjectedAnnualSavings: any = 0;
  phase1DataSource: any;
  phase2DataSource: any;
  phase3DataSource: any;
  phase4DataSource: any;
  InitiationClass: any;
  PlanningClass: any;
  ExecutionClass: any;
  ClosureClass: any;
  InitianDone: any;
  PlanningDone: any;
  ExecutionDone: any;
  ClosureDone: any;
  phase1DisplayedColumns = ['taskDescription', 'startDate', 'endDate', 'action', 'status'];
  tabchange: boolean = false;
  tabchangeMilestone: boolean = false;
  isDisabledContent: boolean = true;
  StatusPermission: boolean = false;
  keyword = 'Abbreviation';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Names: any;
  Tooltips: any
  ProjectMSList: any;
  bLLAEnabled: boolean = false;
  bUseSimpleSubCategory: boolean = false;
  savedetail: boolean = false;
  saveMilestone: boolean = false;
  ProjectDescErr = false;

  constructor(private router: Router, private DbService: ProjectDatabaseService, private route: ActivatedRoute, private fb: FormBuilder, private ContractService: DashboardContractsNewContractService, public dialog: MatDialog, private Dservice: DashboardContractDetailsService,
    private el: ElementRef, private lService: LoginService, private change: ChangeDetectorRef, public viewContainerRef: ViewContainerRef, private setService: SettingsService) {
    this.LoadAllCaptions();
    this.isLLAEnabled();
    this.UseSimpleSubCategory();

    this.disabled = true;
    if (sessionStorage.getItem("ContactID") != null) {

      sessionStorage.removeItem("ProjectData");
      sessionStorage.removeItem("FinanceData");
      sessionStorage.removeItem("MileStoneData");
      this.Charttitle = "Raj";
      this.Charttype = 'Gantt';

      this.HideChartDiv = false;
      this.Taskdata = [[null, null, null, new Date(2019, 0, 1), new Date(2019, 0, 2), 0, 0, null]];
      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        this.getOrganisation();

      }
      else {
        this.getOrganisationForNonHOP();
      }
      this.getCategory();

      // this.getSubCategory();

      this.getExtensionPeriod();
      this.getContractTypes();
      if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] != null) {
        // if(sessionStorage.getItem("subcat") != null)
        // {
        //    this.InitialSubCategory = sessionStorage.getItem("subcat");

        // }
        this.NotNew = false;
        this.DisbaleExtensionPeriod = false;

        if (this.route.snapshot.queryParams['For'] != null) {
          this.StatusPermission = true;
          sessionStorage.removeItem("ApproveProject");
        }
        //this.getSubCategory();
        this.getContractDetailsForReprocure(this.route.snapshot.queryParams['ContractID']);
        this.getUserDetails(sessionStorage.getItem("ContactID"));


      }
      else if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] == null) {
        // if(sessionStorage.getItem("subcat") != null)
        // {
        //    this.InitialSubCategory = sessionStorage.getItem("subcat");
        // }
        this.NotNew = true;
        this.DisbaleExtensionPeriod = true;

        this.getProjectFinancials(this.route.snapshot.queryParams['ContractID']);
        this.getContractDetails(this.route.snapshot.queryParams['ContractID']);
        //this.getSubCategory();
        this.getUserDetails(sessionStorage.getItem("ContactID"));


      }
      else {
        this.NotNew = false;
        this.DisbaleExtensionPeriod = false;
        //this.getSubCategory();
        this.getUserDetails(sessionStorage.getItem("ContactID"));
        this.FinanceDataDeleteOption = true;
        //if(this.selectedIndex ==undefined)
        //{

        //  this.onChanges();
        //  this.tabchange =false;    
        //}

      }
      if (this.route.snapshot.queryParams['Move'] == "1") {
        this.selectedIndex = 1;
      }
      else if (this.route.snapshot.queryParams['Move'] == "2") {

        this.selectedIndex = 2;

      }
    }
    else {
      if (this.route.snapshot.queryParams['For'] != null) {
        sessionStorage.setItem("ApproveProject", this.router.url);
        this.router.navigate(["/login"]);
      }
      else if (this.route.snapshot.queryParams['From'] != null) {
        sessionStorage.setItem("ApproveProject", this.router.url);
        this.router.navigate(["/login"]);
      }
      else {
        this.router.navigate(["/login"]);
      }

    }
  }

  ngOnInit() {
    this.NewProject = this.fb.group({
      ProjectTitle: ['', Validators.required],
      Organisation: [''],
      ContractTitle: ['', Validators.required],
      Category: [''],
      SubCategory: [''],
      ProjectDesc: [''],
      ContractOwnerFirstName: [''],
      ContractOwnerSurName: [''],
      ContractOwnerEmail: ['', Validators.email],
      ProjectOwnerFirstName: ['', Validators.required],
      //ProjectOwnerSurName:['',Validators.required],
      // ProjectOwnerEmail: ['', [Validators.required, Validators.email]],
      ProjectOwnerEmail: ['', [Validators.required]],
      Priority: ['High'],
      Reprocurement: ['', Validators.required],
      ContractGDPR: ['', Validators.required],
      ContractLLA: ['no'],
      ContractDepartment: [''],
      ContractStartDate: ['', Validators.required],
      ContractEndDate: ['', Validators.required],
      ContractReference: [''],
      //LifttimeContractSpend:['',Validators.required],
      //AnnualisedContractSpend:['',Validators.required],
      //LifetimeContractSavings:['',Validators.required],
      //AnnualisedContractSavings:['',Validators.required],
      ExtensionAvailable: ['1'],
      ExtensionPeriod: [''],
      // StartDate:['',Validators.required],
      // EndDate:['',Validators.required]
      StartDate: [''],
      EndDate: [''],
      ProjectRef: ['']
    });
    this.MileStoneDetails = this.fb.group({
      Phase1StartDate: ['', Validators.required],
      Phase1EndDate: ['', Validators.required],
      ddlPhase1Status: ['Incomplete'],
      Phase2StartDate: ['', Validators.required],
      Phase2EndDate: ['', Validators.required],
      ddlPhase2Status: ['Incomplete'],
      Phase3StartDate: ['', Validators.required],
      Phase3EndDate: ['', Validators.required],
      ddlPhase3Status: ['Incomplete'],
      Phase4StartDate: ['', Validators.required],
      Phase4EndDate: ['', Validators.required],
      ddlPhase4Status: ['Incomplete']
    });
    this.ProjectPlan = this.fb.group({
      ProjectStartDate: [''],
      ProjectEndDate: ['']
    });
    this.NewProject.get("Reprocurement").setValue("");
    //this.NewProject.get("Reprocurement").setValue("96");
    if (this.route.snapshot.queryParams['ContractID'] != null) {
      this.NewProject.get("SubCategory").disable();
    }


    //const FinanceformData = new FormData();
    //FinanceformData.append("ContractID", "295");
    //FinanceformData.append("ProjectID", "294");
    //FinanceformData.append("FinanceYear", "2022-23");
    //FinanceformData.append("ProjectedSpend", "1000");
    //FinanceformData.append("ProjectedSavings", "100");
    //FinanceformData.append("FinanceYeardisplay", "April 2022 - March 2023");
    ////formData.append("ReasonforChange",this.AddProjectFinance.get("ReasonforChange").value);
    //FinanceformData.append("ReasonforChange", "");
    //FinanceformData.append("CreatedNewFinance", "true");

    //this.DbService.AddprojectFinance(FinanceformData).subscribe((fdata: any) => {
    //  if (fdata.RetStatus != "Inserted") { return; }
    //});


    //alert(this.selectedIndex);


  }
  get f() { return this.NewProject.controls; };
  get g() { return this.MileStoneDetails.controls; };
  isNumber(val: any) { return typeof val === 'number'; }

  ngAfterViewInit() {
    //let t = (document.querySelector('.mat-tab-body-content') as HTMLElement);
    //console.log("t", t);
    //t.style.minHeight = "100%";

  }

  CreateProject() {
    this.submitted = true;
    this.ProjectDescErr = false;
    let desc = this.NewProject.get("ProjectDesc").value;
  
    if (!desc) {
      this.ProjectDescErr = true;
      return;
    }

    //alert(this.SubCategorySelected);
    
    if (!this.NewProject.valid) {
      const invalidElements = this.el.nativeElement.querySelectorAll('.is-invalid');
      if (invalidElements.length > 0) {

        invalidElements[0].focus();
      }
      return;
    }

    if (this.SubCategorySelected == "" || this.SubCategorySelected == undefined) {
      alert("Please select subcategory !");
      return;
    }
    if (this.NewProject.get("ExtensionAvailable").value == "1") {
      if (this.NewProject.get("ExtensionPeriod").value == "0" || this.NewProject.get("ExtensionPeriod").value == "") {
        alert("Extension period cannot be empty or zero !");
        return;
      }
    }

    var datepipe = new DatePipe('en-US');

    const formData = new FormData();
    formData.append("ProjectTitle", this.NewProject.get("ProjectTitle").value);
    formData.append("OrganisationID", this.NewProject.get("Organisation").value);
    //formData.append("FK_CategoryTypeID",this.NewProject.get("SubCategory").value);
    formData.append("FK_CategoryTypeID", this.SubCategorySelected);
    formData.append("FK_CategoryID", this.NewProject.get("Category").value);
    formData.append("FK_ProjectStatusID", "9");
    formData.append("ProjectDescription", this.NewProject.get("ProjectDesc").value);
    formData.append("ProjectReference", this.NewProject.get("ProjectRef").value);
    formData.append("SavingsCurrency", "GBP");
    formData.append("created", datepipe.transform(this.today, 'MM/dd/yyyy hh:mm:ss'));
    formData.append("modified", datepipe.transform(this.today, 'MM/dd/yyyy hh:mm:ss'));
    formData.append("LoginUserType", sessionStorage.getItem("usertype"));
    //formData.append("ProjectStartDate",datepipe.transform(this.NewProject.get("StartDate").value,'MM/dd/yyyy hh:mm:ss'));
    //formData.append("ProjectEndDate",datepipe.transform(this.NewProject.get("EndDate").value,'MM/dd/yyyy hh:mm:ss'));
    formData.append("ProjectContractTerm", this.NewProject.get("ExtensionPeriod").value);
    formData.append("ProjectOwnerID", sessionStorage.getItem("ContactID"));
    if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] == null) {
      formData.append("FK_ContractID", this.route.snapshot.queryParams['ContractID']);

    }
    else {
      formData.append("ContractStartDate", datepipe.transform(this.NewProject.get("ContractStartDate").value, 'MM/dd/yyyy hh:mm:ss'));
      formData.append("ContractEndDate", datepipe.transform(this.NewProject.get("ContractEndDate").value, 'MM/dd/yyyy hh:mm:ss'));
      formData.append("ContractGDPR", this.NewProject.get("ContractGDPR").value);
      formData.append("ContractLLA", this.NewProject.get("ContractLLA").value);
      formData.append("ContractReference", this.NewProject.get("ContractReference").value);
      formData.append("Department", this.NewProject.get("ContractDepartment").value);
    }
    formData.append("FK_ContractTypeID", this.NewProject.get("Reprocurement").value);
    formData.append("ProjectOwnerFirstName", this.NewProject.get("ProjectOwnerFirstName").value);
    formData.append("ProjectOwnerSurName", this.NewProject.get("ProjectOwnerFirstName").value);
    formData.append("ProjectOwnerEmail", this.NewProject.get("ProjectOwnerEmail").value);
    formData.append("ProjectPriority", this.NewProject.get("Priority").value);
    formData.append("Extention", this.NewProject.get("ExtensionAvailable").value);
    formData.append("ContractTitle", this.NewProject.get("ContractTitle").value);
    formData.append("ExtentionPeriod", this.NewProject.get("ExtensionPeriod").value);
    formData.append("OwnerFirstName", this.NewProject.get("ContractOwnerFirstName").value);
    formData.append("OwnerLastName", this.NewProject.get("ContractOwnerSurName").value);
    formData.append("OwnerEmail", this.NewProject.get("ContractOwnerEmail").value);
    formData.append("Priority", this.NewProject.get("Priority").value);

    const CheckTitle = new FormData();
    CheckTitle.append("ProjectTitle", this.NewProject.get("ProjectTitle").value)
    //this.DbService.CheckProjectTitleExits(this.NewProject.get("ProjectTitle").value).subscribe((data:any) =>
    this.DbService.CheckProjectTitleExists(CheckTitle).subscribe((data: any) => {
      if (data.value == "Not Exists") {
        // this.DbService.CheckContractTitleExits(this.NewProject.get("ContractTitle").value).subscribe((cdata:any) =>
        // {
        //   if(cdata.value =="")
        //   {
        //this.projectFormData=formData;
        sessionStorage.setItem("ProjectData", JSON.stringify(formData));
        //console.log(sessionStorage.getItem("ProjectData"));
        // const pData=new FormData();
        // pData.append("ProjectData",sessionStorage.getItem("ProjectData"));
        // const myObjStr = JSON.stringify(formData);
        // var object = {};
        // formData.forEach((value, key) => {object[key] = value});
        // var json = JSON.stringify(object);
        //console.log("Result : " + JSON.parse(myObjStr));
        // this.DbService.Createproject(json).subscribe((data:any) =>
        // {
        //   if(data.RetStatus =="Created")
        //   {
        //     //this.Revert("Details");
        //     alert(data.RetStatus);
        //   }
        //   else
        //   {
        //     alert(data.RetStatus);
        //     //this.Revert("Details");
        //   }
        // });
        this.savedetail = true;
        if (sessionStorage.getItem("usertype") == "Team Manager") {
          alert("Please complete financials and milestones. You may reassign this project to procurement officer");
        }
        else {
          alert("Please complete financials and milestones");
        }
        this.selectedIndex = 1;
     
        //if (this.selectedIndex == undefined ) {
        //  this.selectedIndex = 1;
        //}
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
        this.tabchange = false;
        this.ShowpopupWindow = true;
        //this.tabchangeMilestone=true;
        //this.Revert("Details");
        //this.selectedIndex=1;
        //   }
        //   else
        //   {
        //     if(this.route.snapshot.queryParams['ContractType'] !=null)
        //     {
        //       alert("Re-procured contract may not have the same name as this is a new contract. Please use different Contract Title");
        //       this.NewProject.get("ContractTitle").reset();
        //     }
        //     else
        //     {
        //       alert("Contract Title already exists. Try with different Title.");
        //       this.NewProject.get("ContractTitle").reset();
        //     }
        //   }
        // });
      }
      else {
        alert("Project title already exists. Try with different title.");
        this.NewProject.get("ProjectTitle").reset();
      }



    });
    // this.DbService.CreateNewProject(formData).subscribe((data:any)=>
    // {
    //     console.log(data.RetStatus);
    //     if(data.RetStatus =="Created")
    //     {
    //       if(this.route.snapshot.queryParams['ContractType'] !=null)
    //       {
    //         this.getContractDetails(data.ContractID);
    //         this.getProjectFinancials(data.ContractID);
    //         this.AddContractAllowcationData(data.ProjectID,data.ContractID);
    //         sessionStorage.removeItem("AllowcationList");
    //       }
    //       if(sessionStorage.getItem("usertype")=="Team Manager")
    //       {
    //         alert("Project created successfully, please complete financials and milestones. You may reassign this project to Procurement Officer");
    //       }
    //       else
    //       {
    //         alert("Project created successfully, please  complete financials and milestones, then send to Team Manager for approval");
    //       }

    //       //this.selectedIndex=1;
    //       this.DbService.Project_ID=data.ProjectID;
    //       this.DbService.Contract_ID=data.ContractID;

    //       //this.Revert("Details");
    //       this.router.navigate(['/dashboard/project/details'], { queryParams: {ProjectID: data.ProjectID,Move:"1"}});
    //     }
    //     else if(data.RetStatus =="Created From Contract")
    //     {
    //       if(this.route.snapshot.queryParams['ContractType'] !=null)
    //       {
    //         this.getContractDetails(data.ContractID);
    //         this.getProjectFinancials(data.ContractID);
    //         this.AddContractAllowcationData(data.ProjectID,data.ContractID);
    //       }

    //       if(sessionStorage.getItem("usertype")=="Team Manager")
    //       {
    //         alert("Project created successfully, please complete financials and milestones. You may reassign this project to Procurement Officer");
    //       }
    //       else
    //       {
    //         alert("Project created successfully, please  complete financials and milestones, then send to Team Manager for approval");
    //       }

    //       sessionStorage.removeItem("AllowcationList");
    //       //this.selectedIndex=1;
    //       this.DbService.Project_ID=data.ProjectID;
    //       this.router.navigate(['/dashboard/project/details'], { queryParams: {ProjectID: data.ProjectID,Move:"1"}});
    //       //this.Revert("Details");
    //     }
    //     else if(data.RetStatus =="Exists")
    //     {
    //       alert("Project Title already exists. Try with different Title.");
    //       this.NewProject.get("ProjectTitle").reset();
    //     }
    //     else if(data.RetStatus =="Contract Title Exists")
    //     {
    //       if(this.route.snapshot.queryParams['ContractType'] !=null)
    //       {
    //         alert("Re-procured contract may not have the same name as this is a new contract. Please use different Contract Title");
    //         this.NewProject.get("ContractTitle").reset();
    //       }
    //       else
    //       {
    //         alert("Contract Title already exists. Try with different Title.");
    //         this.NewProject.get("ContractTitle").reset();
    //       }

    //     }
    //     else
    //     {
    //       alert("Error : " + data.RetStatus);
    //     }
    // });
  }

  Cancel() {
    if (sessionStorage.getItem("AllowcationList") != null && this.route.snapshot.queryParams['ContractID'] != null) {
      sessionStorage.removeItem("AllowcationList");
      this.router.navigate(['/dashboard/contracts/filter'], { queryParams: { Type: 'Reprocure', Extend: 1 } });
    }
    else if (sessionStorage.getItem("AllowcationList") == null && this.route.snapshot.queryParams['ContractID'] != null) {
      this.router.navigate(['/dashboard/contracts/database']);
    }
    else {
      this.router.navigate(['dashboard/projects/database']);
    }
  }

  getOrganisation() {
    this.DbService.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data.value;
      //console.log(data.value);
      //this.OrganisationID = data.value[0].OrganisationID;
      if (this.route.snapshot.queryParams['ContractID'] == null) {
        this.NewProject.get("Organisation").setValue(data.value[0].OrganisationID);
      }

    });
  }

  getOrganisationForNonHOP() {
    this.DbService.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data;
      if (data.length > 0) {
        this.NewProject.get("Organisation").setValue(data[0].OrganisationID);
      }
    });
    //   if(this.route.snapshot.queryParams['ContractID'] !=null)
    //   {

    //     let contractId = this.route.snapshot.queryParams['ContractID'];

    //     this.Dservice.getOrganisationForContract(contractId).subscribe((data:any) =>
    //     {
    //       this.Organisation=data;

    //       this.NewProject.get("Organisation").setValue(data[0].OrganisationID);
    //     });

    //   }
    //   else
    //   {

    //   this.DbService.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    //   {
    //     this.Organisation=data;
    //     if(data.length >0)
    //       {
    //         this.NewProject.get("Organisation").setValue(data[0].OrganisationID);
    //       }
    //   });
    // }
  }

  getAllOrganisation() {
    this.DbService.getAllOrganistaion().subscribe((data: any) => {
      this.Organisation = data.value;

    });
  }

  getCategory() {
    this.DbService.getCategory().subscribe((data: any) => {
      console.log("cat", data);
      var catId = data.value[0].PK_CategoryGroupID;
      console.log("catId", catId);
      this.Category = data.value;
      //this.CategoryID=data.value[0].PK_CategoryTypeID;
      this.NewProject.get("Category").setValue(data.value[0].PK_CategoryGroupID);
      // if(this.route.snapshot.queryParams['ContractID'] == null)
      // {
      //   this.getSubCategory();
      // }

      this.setService.isSimpleSubCategory().subscribe((data: any) => {
        this.bUseSimpleSubCategory = (data.value == "yes");
        console.log("bUseSimpleSubCategory", this.bUseSimpleSubCategory);
        if (this.bUseSimpleSubCategory) {


          //this.SubCategorySelected = data.value[0].PK_CategoryGroupID;
          this.onCategorySelected(catId);
        }
      });



    });
  }

  getSubCategory() {

    //this.DbService.getSubCategoryby(this.NewProject.get("Category").value).subscribe((data:any)=>
    this.DbService.getAllSubCategories().subscribe((data: any) => {

      this.SubCategory = data;
      //if(data.value.length >0)
      //{
      // if(this.route.snapshot.queryParams['ContractID'] == null)
      // {
      //   this.NewProject.get("SubCategory").setValue(data.value[0].PK_CategoryTypeID);
      // }
      //
      //this.SubCategoryID=data.value[0].PK_CategoryTypeID;
      //}
      if (this.selectedIndex == undefined) {

        this.onChanges();
        this.tabchange = false;
      }
    });
  }

  // getSelectedCategory(SubcategoryID : any)
  //  {
  //    this.Dservice.getCategoryFromSubCategory(SubcategoryID).subscribe((data:any) =>
  //    {
  //     // alert(data.FK_CategoryGroupID);
  //      this.NewProject.get("Category").setValue(data.FK_CategoryGroupID);
  //      this.getSubCategory();
  //    });
  //   }

  getExtensionPeriod() {
    this.DbService.getExtensionPeriod().subscribe((data: any) => {

      this.ExtensionPeriod = data.value;

      if (data.value.length > 0) {
        console.log(data.value[0].period);
        //this.ExtensionID=data.value[0].period;
        //this.NewProject.get("ExtensionPeriod").setValue(data.value[0].period);
      }
    });
  }

  getContractDetails(ContractID: any) {

    this.DbService.getContractData(ContractID).subscribe((data: any) => {

      this.NewProject.get("Organisation").setValue(data.FK_OrganistaionID);

      this.NewProject.get("ContractTitle").setValue(data.ContractTitle);
      this.NewProject.get("Priority").setValue(data.Priority);
      this.NewProject.get("Category").setValue(data.CategoryID);
      if (this.bUseSimpleSubCategory) {
        this.onCategorySelected(data.CategoryID);
        this.NewProject.get("SubCategory").setValue(data.FK_CategoryTypeID);
      }
      else {
        this.DbService.getSubCategoryByID(data.FK_CategoryTypeID).subscribe((sdata: any) => {
          this.NewProject.get("SubCategory").setValue(sdata.Abbreviation + " " + sdata.CategoryTypeDescription);
        });
      }
      this.NewProject.get("ContractOwnerFirstName").setValue(data.OwnerFirst_Name);
      this.NewProject.get("ContractOwnerSurName").setValue(data.OwnerLast_Name);
      this.NewProject.get("ContractOwnerEmail").setValue(data.Owner_EmailID);
      this.NewProject.get("Reprocurement").setValue(data.FK_ContractTypeID);
      this.SubCategorySelected = data.FK_CategoryTypeID;
      this.NewProject.get("ContractGDPR").setValue(data.GDPR);
      this.NewProject.get("ContractLLA").setValue(data.LLA);
      this.NewProject.get("ContractEndDate").setValue(data.ContractEndDate);
      this.NewProject.get("ContractStartDate").setValue(data.ContractStartDate);
      //this.NewProject.get("ContractReference").setValue(data.PK_ContractID);

      if (data.InternalReference == "" || data.InternalReference == null) {
        this.NewProject.get("ContractReference").setValue(data.PK_ContractID);
      }
      else {
        this.NewProject.get("ContractReference").setValue(data.InternalReference);
      }
      //this.NewProject.get("LifttimeContractSpend").setValue(data.Lifetime_Contract_Spend);
      //this.NewProject.get("AnnualisedContractSpend").setValue(data.Annual_Contract_Spend);
      //this.NewProject.get("LifetimeContractSavings").setValue(data.Lifetime_Contract_Savings);
      //this.NewProject.get("AnnualisedContractSavings").setValue(data.Annual_Contract_Savings);
      this.NewProject.get("ExtensionAvailable").setValue(data.AutoExtend);
      this.NewProject.get("ExtensionPeriod").setValue(data.ExtentionPeriod);
      this.NewProject.get("ContractDepartment").setValue(data.Department);
      //this.ContractData=data;
      //console.log(data);
      //this.getSelectedCategory(data.FK_CategoryTypeID);
      this.getContractProjectedSpend(ContractID);
      this.getContractProjectedSavings(ContractID);
      this.getContractAnnualSpend(ContractID);
      this.getContractAnnualSavings(ContractID);
      if (this.selectedIndex == undefined) {

        this.onChanges();
        this.tabchange = false;
      }
    });
  }

  getContractDetailsForReprocure(ContractID: any) {

    this.DbService.getContractData(ContractID).subscribe((data: any) => {

      this.NewProject.get("Organisation").setValue(data.FK_OrganistaionID);

      this.NewProject.get("ContractTitle").setValue(data.ContractTitle + " (Re-procure)");
      this.NewProject.get("Priority").setValue(data.Priority);
      this.NewProject.get("Category").setValue(data.CategoryID);
      //this.NewProject.get("SubCategory").setValue(data.FK_CategoryTypeID);
      if (this.bUseSimpleSubCategory) {
        this.onCategorySelected(data.CategoryID);
        this.NewProject.get("SubCategory").setValue(data.FK_CategoryTypeID);
      }
      else {
        this.DbService.getSubCategoryByID(data.FK_CategoryTypeID).subscribe((sdata: any) => {
          this.NewProject.get("SubCategory").setValue(sdata.Abbreviation + " " + sdata.CategoryTypeDescription);
        });
      }
      this.NewProject.get("ContractOwnerFirstName").setValue(data.OwnerFirst_Name);
      this.NewProject.get("ContractOwnerSurName").setValue(data.OwnerLast_Name);
      this.NewProject.get("ContractOwnerEmail").setValue(data.Owner_EmailID);
      this.NewProject.get("Reprocurement").setValue(data.FK_ContractTypeID);
      this.SubCategorySelected = data.FK_CategoryTypeID;
      this.NewProject.get("ContractGDPR").setValue(data.GDPR);
      this.NewProject.get("ContractLLA").setValue(data.LLA);
      this.NewProject.get("ContractEndDate").setValue(data.ContractEndDate);
      this.NewProject.get("ContractStartDate").setValue(data.ContractStartDate);
      if (data.InternalReference == "" || data.InternalReference == null) {
        this.NewProject.get("ContractReference").setValue(data.PK_ContractID);
      }
      else {
        this.NewProject.get("ContractReference").setValue(data.InternalReference);
      }
      //this.NewProject.get("ContractReference").setValue(data.PK_ContractID);

      //this.NewProject.get("LifttimeContractSpend").setValue(data.Lifetime_Contract_Spend);
      //this.NewProject.get("AnnualisedContractSpend").setValue(data.Annual_Contract_Spend);
      //this.NewProject.get("LifetimeContractSavings").setValue(data.Lifetime_Contract_Savings);
      //this.NewProject.get("AnnualisedContractSavings").setValue(data.Annual_Contract_Savings);
      this.NewProject.get("ExtensionAvailable").setValue(data.AutoExtend);
      this.NewProject.get("ExtensionPeriod").setValue(data.ExtentionPeriod);
      this.NewProject.get("ContractDepartment").setValue(data.Department);
      //this.ContractData=data;
      //console.log(data);
      //this.getSelectedCategory(data.FK_CategoryTypeID);
      // this.getContractProjectedSpend(ContractID);
      // this.getContractProjectedSavings(ContractID);
      // this.getContractAnnualSpend(ContractID);
      // this.getContractAnnualSavings(ContractID);
      if (this.selectedIndex == undefined) {

        this.onChanges();
        this.tabchange = false;
      }
    });
  }

  getContractTypes() {
    //alert(this.CategoryId);
    this.ContractService.getContractTypes().subscribe((data: any) => {
      this.ContractType = data.value;
      //this.NewProject.get("Reprocurement").setValue("0");

    });
  }

  AddContractAllowcationData(ProjectID: any, NewContractID: any) {
    if (sessionStorage.getItem("AllowcationList") != null) {
      let item = JSON.parse(sessionStorage.getItem("AllowcationList"));
      for (let i = 0; i < item.length; i++) {
        if (item[i].AllowcationStatus == 1) {
          this.DbService.AllowcateContracttoUsers(item[i].ContractID, ProjectID, item[i].ContactID, item[i].AllowcationStatus, NewContractID).subscribe((data: any) => {
            if (data.value != "Success") return;

          });
        }

      }
    }
  }

  openAddNewProjectFinancials() {
    //if(this.DbService.Project_ID !=null)
    if (sessionStorage.getItem("ProjectData") != null) {
      // console.log("ProjectID"+ this.DbService.Project_ID);
      // console.log("ContractID"+ this.DbService.Contract_ID);
      const dialogRef = this.dialog.open(DialogAddNewProjectfinancialsComponent, {
        data: {
          PrjID: this.DbService.Project_ID,
          ContID: this.route.snapshot.queryParams['ContractID'] != null ? this.route.snapshot.queryParams['ContractID'] : this.DbService.Contract_ID,
          From: "Create Project"
        },
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        console.log("result", result);
        if (result != null && result != "") {
          //sessionStorage.removeItem("FinanceData");
          console.log("splice:before:FinanceData", this.FinanceData);
          this.FinanceData.splice(this.FinanceData.length - 1)
          console.log("FinanceData", this.FinanceData);

          var Exists = this.FinanceData.find(x => x.FinanceYeardisplay == result[0].FinanceYeardisplay);
          if (Exists != null) {
            let index = this.FinanceData.findIndex(x => x.FinanceYeardisplay == result[0].FinanceYeardisplay);
            //this.FinanceData[index].ContractSavings = Number(this.FinanceData[index].ContractSavings) + Number(result[0].ContractSavings);
            //this.FinanceData[index].ContractSpend = Number(this.FinanceData[index].ContractSpend) + Number(result[0].ContractSpend);

            this.FinanceData[index].ContractSavings = Number(result[0].ContractSavings);
            this.FinanceData[index].ContractSpend = Number(result[0].ContractSpend);


            let index1 = this.FinanceDataFinal.findIndex(x => x.FinanceYeardisplay == result[0].FinanceYeardisplay);
           

            this.FinanceDataFinal[index1].ContractSavings = Number(result[0].ContractSavings);
            this.FinanceDataFinal[index1].ContractSpend = Number(result[0].ContractSpend);
          }
          else {
            this.FinanceData.push({
              ContractSpend: Number(result[0].ContractSpend),
              ContractSavings: Number(result[0].ContractSavings),
              FinanceYeardisplay: result[0].FinanceYeardisplay
            });

            this.FinanceDataFinal.push({
              ContractSpend: Number(result[0].ContractSpend),
              ContractSavings: Number(result[0].ContractSavings),
              FinanceYeardisplay: result[0].FinanceYeardisplay,
              FinancialYear: result[0].FinanceYear
            });
          }


          



          this.FinanceData.push({ FinanceYeardisplay: 'Total', ContractSpend: this.FinanceData.reduce((summ, v) => summ += parseInt(v.ContractSpend.toString()), 0), ContractSavings: this.FinanceData.reduce((summ, v) => summ += parseInt(v.ContractSavings.toString()), 0) });

          //this.FinanceData=this.FinanceData.sort((a:any,b:any) => b.FinanceYeardisplay - a.FinanceYeardisplay);
          this.yearlyFinancialsDataSource = new MatTableDataSource(this.FinanceData);
          this.yearlyFinancialsDataSource.sort = this.sort;

          const sortState: Sort = { active: 'FinanceYeardisplay', direction: 'asc' };
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);

          this.ProjectedContractSpend = this.ProjectedContractSpend + Number(result[0].ContractSpend);
          this.ProjectedContractSavings = this.ProjectedContractSavings + Number(result[0].ContractSavings);

          var sFinanceDisplay = this.FinanceData.map(function (obj) { return obj.FinanceYeardisplay; });
          sFinanceDisplay = sFinanceDisplay.filter(function (v, i) { return sFinanceDisplay.indexOf(v) == i; });

          this.ProjectedAllualSpend = this.ProjectedContractSpend / Number(sFinanceDisplay.length - 1);
          this.ProjectedAnnualSavings = this.ProjectedContractSavings / Number(sFinanceDisplay.length - 1);
          sessionStorage.setItem("FinanceData", JSON.stringify(this.FinanceData));

          console.log("FinanceData1", JSON.stringify(this.FinanceData));
          console.log("financedatafinal",this.FinanceDataFinal);

          // this.FinanceData.push(result);
          // this.yearlyFinancialsDataSource=this.FinanceData;
          // console.log("after : " + this.FinanceData);
          // if(this.route.snapshot.queryParams['ContractID'] !=null)
          // {
          //   this.getProjectFinancialsbyProjectID(this.DbService.Project_ID);
          //   this.getContractProjectedSpend(this.route.snapshot.queryParams['ContractID']);
          //   this.getContractProjectedSavings(this.route.snapshot.queryParams['ContractID']);
          //   this.getContractAnnualSpend(this.route.snapshot.queryParams['ContractID']);
          //   this.getContractAnnualSavings(this.route.snapshot.queryParams['ContractID']);
          // }
          // else
          // {
          //   this.getProjectFinancialsbyProjectID(this.DbService.Project_ID);
          //   this.getContractProjectedSpend(this.DbService.Contract_ID);
          //   this.getContractProjectedSavings(this.DbService.Contract_ID);
          //   this.getContractAnnualSpend(this.DbService.Contract_ID);
          //   this.getContractAnnualSavings(this.DbService.Contract_ID);
          // }
        }
      });
    }
    else {

      //alert("Project is not created. Please create a project and then add Financials");
      alert("Please enter Project data first,then add financials");
      this.selectedIndex = 0;
    }

  }

  getProjectFinancials(ContractID: any) {
    this.DbService.GetProjectFinancebyContractID(ContractID).subscribe((data: any) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          this.FinanceData.push({
            ContractSpend: data[i].ContractSpend,
            ContractSavings: data[i].ContractSavings,
            FinanceYeardisplay: data[i].FinanceYeardisplay
          });
        }
        //data.push({FinanceYeardisplay: 'Total', ProjectSpend: data.reduce((summ, v) => summ += parseInt(v.ProjectSpend), 0), ContractSpend: data.reduce((summ, v) => summ += parseInt(v.ContractSpend), 0) ,ProjectSavings:data.reduce((summ, v) => summ += parseInt(v.ProjectSavings), 0),ContractSavings:data.reduce((summ, v) => summ += parseInt(v.ContractSavings), 0)});
        this.FinanceData.push({ FinanceYeardisplay: 'Total', ContractSpend: data.reduce((summ, v) => summ += parseInt(v.ContractSpend), 0), ContractSavings: data.reduce((summ, v) => summ += parseInt(v.ContractSavings), 0) });
      }
      //this.yearlyFinancialsDataSource = new MatTableDataSource(data);
      //console.log(this.FinanceData);
      this.yearlyFinancialsDataSource = new MatTableDataSource(this.FinanceData);
    });
  }

  getProjectFinancialsbyProjectID(ProjectID: any) {
    this.DbService.GetProjectFinancebyProjectID(ProjectID).subscribe((data: any) => {
      if (data.length > 0) {
        data.push({ FinanceYeardisplay: 'Total', ProjectSpend: data.reduce((summ, v) => summ += parseInt(v.ProjectSpend), 0), ContractSpend: data.reduce((summ, v) => summ += parseInt(v.ContractSpend), 0), ProjectSavings: data.reduce((summ, v) => summ += parseInt(v.ProjectSavings), 0), ContractSavings: data.reduce((summ, v) => summ += parseInt(v.ContractSavings), 0) });
      }
      this.yearlyFinancialsDataSource = new MatTableDataSource(data);
    });
  }

  DeleteFinanceData(FinancialYear: string) {
    var Exists = this.FinanceData.find(x => x.FinanceYeardisplay == FinancialYear);
    if (Exists != null) {

      let index = this.FinanceData.findIndex(x => x.FinanceYeardisplay == FinancialYear);

      this.ProjectedContractSpend = this.ProjectedContractSpend - Number(this.FinanceData[index].ContractSpend);
      this.ProjectedContractSavings = this.ProjectedContractSavings - Number(this.FinanceData[index].ContractSavings);

      this.FinanceData.splice(index, 1);
      this.FinanceData.splice(this.FinanceData.length - 1, 1);
    }

    var FinalExists = this.FinanceDataFinal.find(x => x.FinanceYeardisplay == FinancialYear);
    if (FinalExists != null) {
      this.FinanceDataFinal = this.FinanceDataFinal.filter(item => item.FinanceYeardisplay != FinancialYear);
    }


    if (this.FinanceData && this.FinanceData.length > 0)
    this.FinanceData.push({ FinanceYeardisplay: 'Total', ContractSpend: this.FinanceData.reduce((summ, v) => summ += parseInt(v.ContractSpend.toString()), 0), ContractSavings: this.FinanceData.reduce((summ, v) => summ += parseInt(v.ContractSavings.toString()), 0) });

    //this.FinanceData=this.FinanceData.sort((a:any,b:any) => b.FinanceYeardisplay - a.FinanceYeardisplay);
    this.yearlyFinancialsDataSource = new MatTableDataSource(this.FinanceData);
    this.yearlyFinancialsDataSource.sort = this.sort;

    const sortState: Sort = { active: 'FinanceYeardisplay', direction: 'asc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

    //this.ProjectedContractSpend = this.ProjectedContractSpend - Number(result[0].ContractSpend);
    //this.ProjectedContractSavings =this.ProjectedContractSavings - Number(result[0].ContractSavings);

    var sFinanceDisplay = this.FinanceData.map(function (obj) { return obj.FinanceYeardisplay; });
    sFinanceDisplay = sFinanceDisplay.filter(function (v, i) { return sFinanceDisplay.indexOf(v) == i; });

    this.ProjectedAllualSpend = this.ProjectedContractSpend / Number(sFinanceDisplay.length - 1);
    this.ProjectedAnnualSavings = this.ProjectedContractSavings / Number(sFinanceDisplay.length - 1);
    sessionStorage.setItem("FinanceData",JSON.stringify(this.FinanceData));

    if (!this.FinanceData) {
      sessionStorage.removeItem("FinanceData");

    } if (this.FinanceData && this.FinanceData.length == 0) {
      sessionStorage.removeItem("FinanceData");
    }

    console.log("datahai", this.FinanceData);
  }

  SelectedChnage(ExtendionAvailable: string) {
    if (ExtendionAvailable == "0") {
      this.ExtensionPeriod = null;
      this.DisbaleExtensionPeriod = true;
      this.NewProject.get("ExtensionPeriod").setValue("0");
      //this.NewProject.get("Reprocurement").setValue("94");
    }
    else {
      this.getExtensionPeriod();
      this.DisbaleExtensionPeriod = false;
      this.NewProject.get("ExtensionPeriod").setValue("");
      //this.NewProject.get("Reprocurement").setValue("96");
    }
  }

  getContractProjectedSpend(Contract_ID: any) {

    // alert(id);
    this.Dservice.getContractProjectedSpend(Contract_ID).subscribe((data: any) => {

      this.ProjectedContractSpend = data.value;


    });
  }
  getContractProjectedSavings(Contract_ID: any) {

    // alert(id);
    this.Dservice.getContractProjectedSavings(Contract_ID).subscribe((data: any) => {

      this.ProjectedContractSavings = data.value;


    });
  }
  getContractAnnualSpend(Contract_ID: any) {
    this.Dservice.getAnnualContractProjectedSpend(Contract_ID).subscribe((data: any) => {


      this.ProjectedAllualSpend = data.value;


    });
  }
  getContractAnnualSavings(Contract_ID: any) {
    this.Dservice.getAnnualContractProjectedSavings(Contract_ID).subscribe((data: any) => {
      this.ProjectedAnnualSavings = data.value;


    });
  }

  GetProjectPhaseTasks(ProjectID: any, Phase: string, PhaseFor: string) {
    if (this.DbService.Project_ID != null) {
      this.DbService.GetTasksByProjectIDandPhase(ProjectID, Phase).subscribe((data: any) => {
        if (PhaseFor == "1") {
          this.phase1DataSource = new MatTableDataSource(data);
        }
        else if (PhaseFor == "2") {
          this.phase2DataSource = new MatTableDataSource(data);
        }
        else if (PhaseFor == "3") {
          this.phase3DataSource = new MatTableDataSource(data);
        }
        else {
          this.phase4DataSource = new MatTableDataSource(data);
        }
      });
    }
  } 10

  getProjectPhaseDates(ProjectID: any, PhaseType: any) {
    if (this.DbService.Project_ID != null) {
      this.DbService.GetProjectPhaseDatesbyProjectID_PhaseID(ProjectID, PhaseType).subscribe((data: any) => {

        if (PhaseType == "1") {
          this.MileStoneDetails.get("Phase1StartDate").setValue(data.StartDate);
          this.MileStoneDetails.get("Phase1EndDate").setValue(data.EndDate);
        }
        else if (PhaseType == "2") {
          this.MileStoneDetails.get("Phase2StartDate").setValue(data.StartDate);
          this.MileStoneDetails.get("Phase2EndDate").setValue(data.EndDate);
        }
        else if (PhaseType == "3") {
          this.MileStoneDetails.get("Phase3StartDate").setValue(data.StartDate);
          this.MileStoneDetails.get("Phase3EndDate").setValue(data.EndDate);
        }
        else {
          this.MileStoneDetails.get("Phase4StartDate").setValue(data.StartDate);
          this.MileStoneDetails.get("Phase4EndDate").setValue(data.EndDate);
        }

      });
    }
  }

  UpdateMilestone() {

    sessionStorage.setItem("MileStoneData", "true");
    this.submitted = true;
    if (!this.MileStoneDetails.valid) {
      return;
    }

    var datepipe = new DatePipe('en-US');

    const formData = new FormData();
    formData.append("ProjectTitle", this.NewProject.get("ProjectTitle").value);
    formData.append("OrganisationID", this.NewProject.get("Organisation").value);
    //formData.append("FK_CategoryTypeID",this.NewProject.get("SubCategory").value);
    formData.append("FK_CategoryTypeID", this.SubCategorySelected);
    formData.append("FK_CategoryID", this.NewProject.get("Category").value);
    formData.append("FK_ProjectStatusID", "9");
    formData.append("ProjectDescription", this.NewProject.get("ProjectDesc").value);
    formData.append("ProjectReference", this.NewProject.get("ProjectRef").value);
    formData.append("SavingsCurrency", "GBP");
    formData.append("created", datepipe.transform(this.today, 'MM/dd/yyyy hh:mm:ss'));
    formData.append("modified", datepipe.transform(this.today, 'MM/dd/yyyy hh:mm:ss'));
    formData.append("LoginUserType", sessionStorage.getItem("usertype"));
    //formData.append("ProjectStartDate",datepipe.transform(this.NewProject.get("StartDate").value,'MM/dd/yyyy hh:mm:ss'));
    //formData.append("ProjectEndDate",datepipe.transform(this.NewProject.get("EndDate").value,'MM/dd/yyyy hh:mm:ss'));
    formData.append("ProjectContractTerm", this.NewProject.get("ExtensionPeriod").value);
    formData.append("ProjectOwnerID", sessionStorage.getItem("ContactID"));
    if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] == null) {
      formData.append("FK_ContractID", this.route.snapshot.queryParams['ContractID']);

    }
    else {
      formData.append("ContractStartDate", datepipe.transform(this.NewProject.get("ContractStartDate").value, 'MM/dd/yyyy hh:mm:ss'));
      formData.append("ContractEndDate", datepipe.transform(this.NewProject.get("ContractEndDate").value, 'MM/dd/yyyy hh:mm:ss'));
      formData.append("ContractGDPR", this.NewProject.get("ContractGDPR").value);
      formData.append("ContractLLA", this.NewProject.get("ContractLLA").value);
      formData.append("ContractReference", this.NewProject.get("ContractReference").value);
      formData.append("Department", this.NewProject.get("ContractDepartment").value);
    }
    formData.append("FK_ContractTypeID", this.NewProject.get("Reprocurement").value);
    formData.append("ProjectOwnerFirstName", this.NewProject.get("ProjectOwnerFirstName").value);
    formData.append("ProjectOwnerSurName", this.NewProject.get("ProjectOwnerFirstName").value);
    formData.append("ProjectOwnerEmail", this.NewProject.get("ProjectOwnerEmail").value);
    formData.append("ProjectPriority", this.NewProject.get("Priority").value);
    formData.append("Extention", this.NewProject.get("ExtensionAvailable").value);
    formData.append("ContractTitle", this.NewProject.get("ContractTitle").value);
    formData.append("ExtentionPeriod", this.NewProject.get("ExtensionPeriod").value);
    formData.append("OwnerFirstName", this.NewProject.get("ContractOwnerFirstName").value);
    formData.append("OwnerLastName", this.NewProject.get("ContractOwnerSurName").value);
    formData.append("OwnerEmail", this.NewProject.get("ContractOwnerEmail").value);
    formData.append("Priority", this.NewProject.get("Priority").value);
    this.DbService.Createproject(formData).subscribe((data: any) => {
      if (data.RetStatus == "Created" || data.RetStatus == "Created From Contract") {
        if (sessionStorage.getItem("AllowcationList") != null) {
          this.AddContractAllowcationData(data.ProjectID, data.ContractID);
        }

        for (let j = 0; j < this.FinanceDataFinal.length; j++) {
          const FinanceformData = new FormData();
          FinanceformData.append("ContractID", data.ContractID);
          FinanceformData.append("ProjectID", data.ProjectID);
          FinanceformData.append("FinanceYear", this.FinanceDataFinal[j].FinancialYear);
          FinanceformData.append("ProjectedSpend", this.FinanceDataFinal[j].ContractSpend.toString());
          FinanceformData.append("ProjectedSavings", this.FinanceDataFinal[j].ContractSavings.toString());
          FinanceformData.append("FinanceYeardisplay", this.FinanceDataFinal[j].FinanceYeardisplay);
          //formData.append("ReasonforChange",this.AddProjectFinance.get("ReasonforChange").value);
          FinanceformData.append("ReasonforChange", "");
          FinanceformData.append("CreatedNewFinance", "true");

          this.DbService.AddprojectFinance(FinanceformData).subscribe((fdata: any) => {
            if (fdata.RetStatus != "Inserted") { return; }
          });
        }

        // this.MileStonedata.push({
        //   FK_ProjectPhaseID:"Project Initiation",
        //   StartDate:datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   EndDate:datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   PhaseStatus:this.MileStoneDetails.get("ddlPhase1Status").value
        // });
        //  //For Phase 2
        //  this.MileStonedata.push({
        //   FK_ProjectPhaseID:"Project Planning",
        //   StartDate:datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   EndDate:datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   PhaseStatus:this.MileStoneDetails.get("ddlPhase2Status").value
        // });
        //  //For Phase 3
        //  this.MileStonedata.push({
        //   FK_ProjectPhaseID:"Project Execution",
        //   StartDate:datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   EndDate:datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   PhaseStatus:this.MileStoneDetails.get("ddlPhase3Status").value
        // });
        //  //For Phase 4
        //  this.MileStonedata.push({
        //   FK_ProjectPhaseID:"Project Closure",
        //   StartDate:datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   EndDate:datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value,'MM/dd/yyyy hh:mm:ss'),
        //   PhaseStatus:this.MileStoneDetails.get("ddlPhase4Status").value
        // });

        // //Add MileStone data

        // for(let i=0;i<this.MileStonedata.length;i++)
        // {
        //   const Phaese1formData=new FormData();
        //   Phaese1formData.append("FK_ProjectID",data.ProjectID);
        //   Phaese1formData.append("FK_ProjectPhaseID",this.MileStonedata[i].FK_ProjectPhaseID);
        //   Phaese1formData.append("StartDate",datepipe.transform(this.MileStonedata[i].StartDate,'MM/dd/yyyy hh:mm:ss'));
        //   Phaese1formData.append("EndDate",datepipe.transform(this.MileStonedata[i].EndDate,'MM/dd/yyyy hh:mm:ss'));
        //   Phaese1formData.append("PhaseStatus",this.MileStonedata[i].PhaseStatus);
        //   this.DbService.AddprojectPhaseDates(Phaese1formData).subscribe((mdata:any) =>
        //   {
        //     if(mdata.RetStatus !="Created"){return;}
        //   });
        // }

        const Phaese1formData = new FormData();
        Phaese1formData.append("FK_ProjectID", data.ProjectID);
        Phaese1formData.append("FK_ProjectPhaseID", this.getProjectCaption(1));
        Phaese1formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
        Phaese1formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
        Phaese1formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase1Status").value);
        this.DbService.AddprojectPhaseDates(Phaese1formData).subscribe((data1: any) => {
          //alert("1" + data1.RetStatus);
          if (data1.RetStatus == "Created" || data1.RetStatus == "Updated") {
            const Phaese2formData = new FormData();
            Phaese2formData.append("FK_ProjectID", data.ProjectID);
            Phaese2formData.append("FK_ProjectPhaseID", this.getProjectCaption(2));
            Phaese2formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
            Phaese2formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
            Phaese2formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase2Status").value);
            this.DbService.AddprojectPhaseDates(Phaese2formData).subscribe((Phaese2Data: any) => {
              //alert("2" + Phaese2Data.RetStatus);
              if (Phaese2Data.RetStatus == "Created" || Phaese2Data.RetStatus == "Updated") {
                const Phaese3formData = new FormData();
                Phaese3formData.append("FK_ProjectID", data.ProjectID);
                Phaese3formData.append("FK_ProjectPhaseID", this.getProjectCaption(3));
                Phaese3formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
                Phaese3formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
                Phaese3formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase3Status").value);
                this.DbService.AddprojectPhaseDates(Phaese3formData).subscribe((Phaese3Data: any) => {
                  if (Phaese3Data.RetStatus == "Created" || Phaese3Data.RetStatus == "Updated") {
                    //alert("3" + Phaese3Data.RetStatus);
                    const Phaese4formData = new FormData();
                    Phaese4formData.append("FK_ProjectID", data.ProjectID);
                    Phaese4formData.append("FK_ProjectPhaseID", this.getProjectCaption(4));
                    Phaese4formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
                    Phaese4formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
                    Phaese4formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase4Status").value);
                    this.DbService.AddprojectPhaseDates(Phaese4formData).subscribe((Phaese4Data: any) => {
                      if (Phaese4Data.RetStatus == "Created" || Phaese4Data.RetStatus == "Updated") {
                        //alert("4" + Phaese4Data.RetStatus);
                        // this.DbService.ProjectCreateConfirmationMailto_TM(sessionStorage.getItem("ContactID"),data.ProjectID,data.ContractID).subscribe((mdata:any) =>
                        // {
                        //   if(data.RetStatus !="Success"){return;}
                        // });
                        alert("Project saved successfully.");
                        this.DeleteTemporaryProject();
                        this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: data.ProjectID } });
                        //this.getProjectDetails(this.DbService.Project_ID);
                      }
                      else if (Phaese4Data.RetStatus == "Invalid Date") {
                        this.MileStoneDetails.get("Phase4StartDate").reset();
                        this.MileStoneDetails.get("Phase4EndDate").reset();
                        alert("Phase 4 start date & end date should be with in project start date & end date");
                      }
                      else {
                        alert(Phaese4Data.RetStatus);
                      }
                    });
                  }
                  else if (Phaese3Data.RetStatus == "Invalid Date") {
                    this.MileStoneDetails.get("Phase3StartDate").reset();
                    this.MileStoneDetails.get("Phase3EndDate").reset();
                    alert("Phase 3 start date & end date should be with in project start date & end date");
                  }
                  else {
                    alert(Phaese3Data.RetStatus);
                  }
                });
              }
              else if (Phaese2Data.RetStatus == "Invalid Date") {
                this.MileStoneDetails.get("Phase2StartDate").reset();
                this.MileStoneDetails.get("Phase2EndDate").reset();
                alert("Phase 2 start date & end date should be with in project start date & end date");
              }
              else {
                alert(Phaese2Data.RetStatus);
              }
            });
          }
          else if (data1.RetStatus == "Invalid Date") {
            this.MileStoneDetails.get("Phase1StartDate").reset();
            this.MileStoneDetails.get("Phase1EndDate").reset();
            alert("Phase 1 start date & end date should be with in project start date & end date");
          }
          else {
            alert(data.RetStatus);
          }
        });


      }
      else {
        alert(data.RetStatus);
      }
    });
    //var datepipe = new DatePipe('en-US');
    //For Phase 1

    sessionStorage.removeItem("ProjectData");
    sessionStorage.removeItem("FinanceData");
    sessionStorage.removeItem("MileStoneData");


  }
  //  UpdateMilestone()
  // {
  //   this.submitted=true;
  //   if (!this.MileStoneDetails.valid) {
  //     const invalidElements = this.el.nativeElement.querySelectorAll('.is-invalid');
  //     if (invalidElements.length > 0) {

  //       invalidElements[0].focus();
  //     }
  //     return;
  //     }
  //     if(this.DbService.Project_ID !=null)
  //     {
  //       var datepipe = new DatePipe('en-US');

  //       const Phaese1formData=new FormData();
  //       Phaese1formData.append("FK_ProjectID",this.DbService.Project_ID.toString());
  //       Phaese1formData.append("FK_ProjectPhaseID","Project Initiation");
  //       Phaese1formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //       Phaese1formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //       Phaese1formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase1Status").value);
  //       this.DbService.AddprojectPhaseDates(Phaese1formData).subscribe((data:any) =>
  //       {
  //         if(data.RetStatus =="Created" || data.RetStatus =="Updated")
  //         {
  //           const Phaese2formData=new FormData();
  //           Phaese2formData.append("FK_ProjectID",this.DbService.Project_ID.toString());
  //           Phaese2formData.append("FK_ProjectPhaseID","Project Planning");
  //           Phaese2formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //           Phaese2formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //           Phaese2formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase2Status").value);
  //           this.DbService.AddprojectPhaseDates(Phaese2formData).subscribe((Phaese2Data:any) =>
  //           {
  //             if(Phaese2Data.RetStatus =="Created" || Phaese2Data.RetStatus =="Updated")
  //             {
  //               const Phaese3formData=new FormData();
  //               Phaese3formData.append("FK_ProjectID",this.DbService.Project_ID.toString());
  //               Phaese3formData.append("FK_ProjectPhaseID","Project Execution");
  //               Phaese3formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //               Phaese3formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //               Phaese3formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase3Status").value);
  //               this.DbService.AddprojectPhaseDates(Phaese3formData).subscribe((Phaese3Data:any) =>{
  //                 if(Phaese3Data.RetStatus =="Created" || Phaese3Data.RetStatus =="Updated")
  //                 {
  //                   const Phaese4formData=new FormData();
  //                   Phaese4formData.append("FK_ProjectID",this.DbService.Project_ID.toString());
  //                   Phaese4formData.append("FK_ProjectPhaseID","Project Closure");
  //                   Phaese4formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //                   Phaese4formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //                   Phaese4formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase4Status").value);
  //                   this.DbService.AddprojectPhaseDates(Phaese4formData).subscribe((Phaese4Data:any) =>{
  //                     if(Phaese4Data.RetStatus =="Created" || Phaese4Data.RetStatus =="Updated")
  //                     {
  //                       this.getProjectPhaseDates(this.DbService.Project_ID,"Project Initiation");
  //                       this.getProjectPhaseDates(this.DbService.Project_ID,"Project Planning");
  //                       this.getProjectPhaseDates(this.DbService.Project_ID,"Project Execution");
  //                       this.getProjectPhaseDates(this.DbService.Project_ID,"Project Closure");
  //                       alert("Saved Successfully");
  //                       this.router.navigate(['/dashboard/project/details'], { queryParams: {ProjectID: this.DbService.Project_ID}});
  //                       //this.getProjectDetails(this.DbService.Project_ID);
  //                     }
  //                     else if(Phaese4Data.RetStatus=="Invalid Date")
  //                     {
  //                       this.MileStoneDetails.get("Phase4StartDate").reset();
  //                       this.MileStoneDetails.get("Phase4EndDate").reset();
  //                       alert("Phase 4 Start Date & End Date should be with in Project Start Date & End date");
  //                     }
  //                     else
  //                     {
  //                       alert(Phaese4Data.RetStatus);
  //                     }
  //                   });
  //                 }
  //                 else if(Phaese3Data.RetStatus=="Invalid Date")
  //                 {
  //                   this.MileStoneDetails.get("Phase3StartDate").reset();
  //                   this.MileStoneDetails.get("Phase3EndDate").reset();
  //                   alert("Phase 3 Start Date & End Date should be with in Project Start Date & End date");
  //                 }
  //                 else
  //                 {
  //                   alert(Phaese3Data.RetStatus);
  //                 }
  //               });
  //             }
  //             else if(Phaese2Data.RetStatus=="Invalid Date")
  //             {
  //               this.MileStoneDetails.get("Phase2StartDate").reset();
  //               this.MileStoneDetails.get("Phase2EndDate").reset();
  //                alert("Phase 2 Start Date & End Date should be with in Project Start Date & End date");
  //             }
  //             else
  //             {
  //               alert(Phaese2Data.RetStatus);
  //             }
  //           });
  //         }
  //         else if(data.RetStatus=="Invalid Date")
  //         {
  //           this.MileStoneDetails.get("Phase1StartDate").reset();
  //           this.MileStoneDetails.get("Phase1EndDate").reset();
  //           alert("Phase 1 Start Date & End Date should be with in Project Start Date & End date");
  //         }      
  //         else
  //         {
  //             alert(data.RetStatus);
  //         }
  //       });
  //     }
  //     else
  //     {
  //       alert("Project is not created. Please create a project and then add Milestones");
  //     }

  // }

  openDialogAddNewTask(Param: any) {
    if (this.DbService.Project_ID != null) {
      if (Param == "Add") {
        const dialogRef = this.dialog.open(DialogAddNewTaskComponent, {
          data: { ProjectID: this.DbService.Project_ID },
          width: '730px'
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(1), "1");
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(2), "2");
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(3), "3");
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(4), "4");
          this.FillGanttChart(this.DbService.Project_ID);
          this.GetTaskStatusByProjectIDandPhase(this.DbService.Project_ID, this.getProjectCaption(1), "1");
          this.GetTaskStatusByProjectIDandPhase(this.DbService.Project_ID, this.getProjectCaption(2), "2");
          this.GetTaskStatusByProjectIDandPhase(this.DbService.Project_ID, this.getProjectCaption(3), "3");
          this.GetTaskStatusByProjectIDandPhase(this.DbService.Project_ID, this.getProjectCaption(4), "4");
        });
      }
      else {
        const dialogRef = this.dialog.open(DialogAddNewTaskComponent, {
          data: { TaskID: Param },
          width: '730px'
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(1), "1");
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(2), "2");
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(3), "3");
          this.GetProjectPhaseTasks(this.DbService.Project_ID, this.getProjectCaption(4), "4");
        });
      }
    }
    else {
      alert("Project is not created. Please create a project and then add Milestones");
    }


  }
  getUserDetails(UserID: any) {
    UserID = sessionStorage.getItem('userMail');
    if (this.route.snapshot.queryParams['ContractType'] != null) {
      if (this.route.snapshot.queryParams['From'] != null || this.route.snapshot.queryParams['For'] != null) {
        this.lService.getContactByEmail(UserID).subscribe((data: any) => {
          this.NewProject.get("ProjectOwnerFirstName").setValue(data.ContactFirstName + " " + data.ContactSurname);
          //this.NewProject.get("ProjectOwnerSurName").setValue(data.ContactSurname);
          this.NewProject.get("ProjectOwnerEmail").setValue(data.ContactEMailAddress);

        });
        sessionStorage.removeItem("AllowcationList");
      }
      else {
        if (sessionStorage.getItem("AllowcationList") != null) {
          let ContactID;
          let item = JSON.parse(sessionStorage.getItem("AllowcationList"));
          for (let i = 0; i < item.length; i++) {
            if (item[i].AllowcationStatus == 1) {
              ContactID = item[i].ContactID;
              break;
            }
          }
          this.lService.getContactByEmail(ContactID).subscribe((data: any) => {
            this.NewProject.get("ProjectOwnerFirstName").setValue(data.ContactFirstName + " " + data.ContactSurname);
            //this.NewProject.get("ProjectOwnerSurName").setValue(data.ContactSurname);
            this.NewProject.get("ProjectOwnerEmail").setValue(data.ContactEMailAddress);
          });

          sessionStorage.removeItem("AllowcationList");
        }
        else {
          this.lService.getContactByEmail(UserID).subscribe((data: any) => {
            this.NewProject.get("ProjectOwnerFirstName").setValue(data.ContactFirstName + " " + data.ContactSurname);
            //this.NewProject.get("ProjectOwnerSurName").setValue(data.ContactSurname);
            this.NewProject.get("ProjectOwnerEmail").setValue(data.ContactEMailAddress);

          });
        }

      }

    }
    else {
      this.lService.getContactByEmail(UserID).subscribe((data: any) => {
        this.NewProject.get("ProjectOwnerFirstName").setValue(data.ContactFirstName + " " + data.ContactSurname);
        //this.NewProject.get("ProjectOwnerSurName").setValue(data.ContactSurname);
        this.NewProject.get("ProjectOwnerEmail").setValue(data.ContactEMailAddress);
        if (this.selectedIndex == undefined) {

          this.onChanges();
          this.tabchange = false;
        }
      });
      sessionStorage.removeItem("AllowcationList");
    }

  }

  onChanges(): void {
    this.NewProject.get("Organisation").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ProjectTitle").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ProjectDesc").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ProjectRef").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("Priority").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ContractTitle").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("Category").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    // this.NewProject.get("SubCategory").valueChanges.subscribe(val => {
    //   //alert("hi");
    //   this.tabchange =true;
    //   this.isDisabledContent = false;
    // });
    this.NewProject.get("ContractOwnerFirstName").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ContractOwnerSurName").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ContractOwnerEmail").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ContractStartDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ContractEndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ExtensionAvailable").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ExtensionPeriod").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ContractGDPR").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("ContractLLA").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
    this.NewProject.get("Reprocurement").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;

    });
  }

  onChangeMilestones(): void {
    this.MileStoneDetails.get("Phase1StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;

    });
    this.MileStoneDetails.get("Phase1EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("ddlPhase1Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });

    this.MileStoneDetails.get("Phase2StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("Phase2EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("ddlPhase2Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });

    this.MileStoneDetails.get("Phase3StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("Phase3EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("ddlPhase3Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });

    this.MileStoneDetails.get("Phase4StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("Phase4EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("ddlPhase4Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    //sessionStorage.setItem("MileStoneData","true");
  }

  OpenDialogforDetailsChange() {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "NewProject" },
      width: '640px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.CreateProject();
      }
      else {
        this.Revert("Details");
      }
    });
  }

  Revert(tab: string) {
    //this.router.navigate(["/dashboard/projects/database"]);
    if (tab == "Details") {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] != null) {
        this.router.navigate(['/dashboard/projects/new-project'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'], ContractType: this.route.snapshot.queryParams['ContractType'] } });

      }
      else if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] == null) {
        this.router.navigate(['/dashboard/projects/new-project'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'] } });
      }
      else {
        this.router.navigate(['/dashboard/projects/new-project']);
      }
    }
    else if (tab == "MileStone") {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] != null) {
        this.router.navigate(['/dashboard/projects/new-project'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'], ContractType: this.route.snapshot.queryParams['ContractType'] } });
      }
      else if (this.route.snapshot.queryParams['ContractID'] != null && this.route.snapshot.queryParams['ContractType'] == null) {
        this.router.navigate(['/dashboard/projects/new-project'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'] } });
      }
      else {
        //this.router.navigate(['/dashboard/projects/new-project']);


        this.MileStoneDetails.get("Phase1StartDate").setValue('');
        this.MileStoneDetails.get("Phase1EndDate").setValue('');

        this.MileStoneDetails.get("Phase2StartDate").setValue('');
        this.MileStoneDetails.get("Phase2EndDate").setValue('');

        this.MileStoneDetails.get("Phase3StartDate").setValue('');
        this.MileStoneDetails.get("Phase3EndDate").setValue('');

        this.MileStoneDetails.get("Phase4StartDate").setValue('');
        this.MileStoneDetails.get("Phase4EndDate").setValue('');

        this.tabchangeMilestone = false;
      }
    }
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
    }
  }

  CheckforPreviousPhaseStatus(Phase: string) {
    if (Phase == "2") {
      if (this.MileStoneDetails.get("ddlPhase2Status").value == "Completed" && this.MileStoneDetails.get("ddlPhase1Status").value == "Incomplete") {
        alert("Cannot update status to Completed. Because, Project Initiation phase still incomplete.");
        this.MileStoneDetails.get("ddlPhase2Status").setValue("Incomplete");
      }
    }
    else if (Phase == "3") {
      if (this.MileStoneDetails.get("ddlPhase3Status").value == "Completed" && (this.MileStoneDetails.get("ddlPhase2Status").value == "Incomplete" || this.MileStoneDetails.get("ddlPhase1Status").value == "Incomplete")) {
        alert("Cannot update status to Completed. Because, Project Planning phase still incomplete.");
        this.MileStoneDetails.get("ddlPhase3Status").setValue("Incomplete");
      }
    }
    else {
      if (this.MileStoneDetails.get("ddlPhase4Status").value == "Completed" && (this.MileStoneDetails.get("ddlPhase3Status").value == "Incomplete" || this.MileStoneDetails.get("ddlPhase2Status").value == "Incomplete" || this.MileStoneDetails.get("ddlPhase1Status").value == "Incomplete")) {
        alert("Cannot update status to Completed. Because, Project Execution phase still incomplete.");
        this.MileStoneDetails.get("ddlPhase4Status").setValue("Incomplete");
      }
    }
  }

  FillGanttChart(ProjectID: any) {
    //this.BindGantt();
    this.Charttitle = "Raj";
    this.Charttype = 'Gantt';
    //this.ChartcolumnNames=["TaskID", "Task Name", "Resource ID","Start","End","Duration","Percent","Dependencies"];
    //var testdata="[['TaskID', 'Task Name', 'Resource ID','Start','End','Duration','Percent','Dependencies']";
    let testdata = [];
    let i = 0;

    this.DbService.GetTasksByProjectID(ProjectID).subscribe((data: any) => {
      if (data.length > 0) {
        data.forEach(element => {
          testdata.push(["" + element.TaskID + "", "" + element.TaskName + "", "", new Date(element.StartDate), new Date(element.EndDate), 0, 0, null]);
        });
        this.Taskdata = testdata;
        this.Chartoptions = {
          width: 1536,
          hAxis: {
            textStyle: {
              color: '#000'
            }
          },
          bars: 'horizontal',
          axes: {
            x: {
              0: { side: 'top' }
            }
          },
          gantt: {
            palette: [{
              color: "#5e97f6",
              dark: "#2a56c6",
              light: "#c6dafc"
            }],
            criticalPathEnabled: false,
            barHeight: 15,
            trackHeight: 40,
            labelStyle: {
              fontSize: 14,
              color: '#000000'
            },
            backgroundColor: {
              fill: 'transparent',
            },

            barCornerRadius: 2,
            innerGridHorizLine: {
              stroke: '#ddd',
              strokeWidth: 1
            },
            innerGridTrack: { fill: '#ffffff' },
            innerGridDarkTrack: { fill: '#ffffff' }
          }
        };
        this.Chartheight = 650;
      }
      else {
        this.HideChartDiv = false;
        this.Taskdata = [[null, null, null, new Date(2019, 0, 1), new Date(2019, 0, 2), 0, 0, null]];
      }
    });
  }

  checktabchanges() {

    if (sessionStorage.getItem("ProjectData") != null && sessionStorage.getItem("FinanceData") == null) {
      //alert("Please enter finance data!");
      if (confirm('Please enter financial data!. Click OK to Continue or Click Cancel to clear all data entered for this project')) {
        // Save it!
        this.selectedIndex = 1;
      } else {
        this.Revert("Details");
      }
    }
    else {

      if (sessionStorage.getItem("FinanceData") != null && sessionStorage.getItem("MileStoneData") == null) {
        if (confirm('Please enter milestone data!. Click OK to continue or click Cancel to clear all data entered for this project.')) {
          this.selectedIndex = 2;
        }
        else {
          this.Revert("Details");
        }

      }
      else if (this.tabchange || this.savedetail) {
        //this.showSave =true;

        this.OpenDialogforDetailsChange();
      }
      else if (this.tabchangeMilestone) {
        //this.showMilestoneActions=true;
        //this.OpenDialogforMileStoneChange();
        this.UpdateMilestone();
      }

    }
  }

  OpenDialogforMileStoneChange() {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "MileStoneEdit", Resubmit: 'no' },
      width: '640px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        if (sessionStorage.getItem("FinanceData") != null) {
          this.UpdateMilestone();
        }
        else {
          alert("Please add finance data !");
          //this.Revert("Details");
          this.selectedIndex = 1;
        }
      }
      else {
        this.Revert("MileStone");
        //this.selectedIndex=1;
      }
    });
  }

  onselected(event) {
    //console.log(event);

    console.log("onselected: this.FinanceDataFinal", this.FinanceDataFinal);

    if (this.tabchange) {

      //this.isDisabledContent = false;
      this.isDisabledContent = false;
      if (event.index != 0) {

        //this.selectedIndex = 0;
        if (!this.ShowpopupWindow) {
          let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
          element.click();
          //this.showSave =true;
          //this.OpenDialogforDetailsChange();
          this.CreateProject();
        }
      }
      else {
        //this.change.detach();
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
      }
    }
    else if (this.tabchangeMilestone && this.FinanceDataFinal && this.FinanceDataFinal.length > 0) {

      this.isDisabledContent = false;
      if (event.index != 2) {
        this.selectedIndex = 2;
        //this.showMilestoneActions =true;
        //this.OpenDialogforMileStoneChange();
        this.UpdateMilestone();
      }
      else {
        //this.change.detach();
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
      }
    }
    else {
      if (event.index == 1) {
        if (sessionStorage.getItem("ProjectData") == null) {
          alert("Please add project details");
          this.selectedIndex = 0;
          let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
          element.click();
        }
      }
      else if (event.index == 2 || event.index == 4) {
        if (sessionStorage.getItem("ProjectData") != null) {
          if (sessionStorage.getItem("FinanceData") != null) {

            if (sessionStorage.getItem("MileStoneData") == null && event.index == 4) {
              alert("Please add project milestone");
              this.selectedIndex = 2;
              let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
              element.click();
              return;
            }

            this.onChangeMilestones();
            this.tabchangeMilestone = false;
          }
          else {
            if (confirm('Please enter finance data!. Click OK to Continue or Click Cancel to clear all data entered for this project')) {
              this.selectedIndex = 1;
              let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
              element.click();
            } else {
              this.Revert("Details");
            }
          }
        }
        else {
          alert("Please add project details");
          this.selectedIndex = 0;
          let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
          element.click();
        }


      }
    }

    // if(this.DbService.Project_ID !=null)
    // {
    //   if(event.index == 3)
    //   {
    //     //this.FillGanttChart(this.DbService.Project_ID);
    //     this.Showchart=true;
    //     this.FillGanttChart(this.DbService.Project_ID);
    //   }
    //   else
    //   {
    //     this.Showchart=false;
    //   }
    // }

  }
  getProjectDetails(ProjectID: any) {
    this.DbService.getContractandprojectDataby(ProjectID).subscribe((data: any) => {
      this.ProjectPlan.get("ProjectStartDate").setValue(data.StartDate != "0001-01-01T00:00:00" ? data.StartDate : null);
      this.ProjectPlan.get("ProjectEndDate").setValue(data.EndDate != "0001-01-01T00:00:00" ? new Date(data.EndDate) : null);
      this.NewProject.get("StartDate").setValue(data.StartDate != "0001-01-01T00:00:00" ? data.StartDate : null);;
      this.NewProject.get("EndDate").setValue(data.EndDate != "0001-01-01T00:00:00" ? new Date(data.EndDate) : null);;
    });
  }

  UpdateTaskStatus(TaskID: any, Phase: string) {
    console.log(TaskID + "_" + Phase);
    // this.DbService.UpdateTaskStatus(TaskID).subscribe((data:any) =>
    // {
    //   this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'],PhaseFor,Phase);
    //   this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'],PhaseFor);
    // });
  }

  ValidateStartDate(Phase: string) {
    var datepipe = new DatePipe('en-US');
    sessionStorage.setItem("MileStoneData", "true");
    if (Phase == "1") {
      if (this.MileStoneDetails.get("Phase1EndDate").value != "" && this.MileStoneDetails.get("Phase1EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to end date");
          this.MileStoneDetails.get("Phase1StartDate").reset();
        }
      }
    }
    else if (Phase == "2") {

      if (this.MileStoneDetails.get("Phase2EndDate").value != "" && this.MileStoneDetails.get("Phase2EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to end date");
          this.MileStoneDetails.get("Phase2StartDate").reset();
        }
      }
      // else
      // {
      //   this.openDialogReasonforChange("2");
      // }
    }
    else if (Phase == "3") {
      if (this.MileStoneDetails.get("Phase3EndDate").value != "" && this.MileStoneDetails.get("Phase3EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to End date");
          this.MileStoneDetails.get("Phase3StartDate").reset();
        }
      }

      // else
      // {
      //   this.openDialogReasonforChange("3");
      // }
    }
    else {
      if (this.MileStoneDetails.get("Phase4EndDate").value != "" && this.MileStoneDetails.get("Phase4EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to End date");
          this.MileStoneDetails.get("Phase4StartDate").reset();
        }
      }

      // else
      // {
      //   this.openDialogReasonforChange("4");
      // }
    }
  }

  CheckForDates(Phase: string) {
    var datepipe = new DatePipe('en-US');
    sessionStorage.setItem("MileStoneData", "true");
    if (Phase == "1") {
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to Start date");
        this.MileStoneDetails.get("Phase1EndDate").reset();
      }
    }
    else if (Phase == "2") {
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to Start date");
        this.MileStoneDetails.get("Phase2EndDate").reset();
      }
    }
    else if (Phase == "3") {
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to Start date");
        this.MileStoneDetails.get("Phase3EndDate").reset();
      }
    }
    else {
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to Start date");
        this.MileStoneDetails.get("Phase4EndDate").reset();
      }
    }
  }
  GetTaskStatusByProjectIDandPhase(ProjectID: any, Phase: string, PhaseFor: string) {
    this.DbService.GetTaskStatusByProjectIDandPhase(ProjectID, PhaseFor).subscribe((data: any) => {
      console.log(data);
      if (PhaseFor == "1") {
        if (data.Percent == 0) {
          this.InitianDone = "";
          this.InitiationClass = "psp-card--progress--incomplete";
        }
        else if (data.Percent == 100) {
          this.InitianDone = "";
          this.InitiationClass = "psp-card--progress--complete";
        }
        else {
          this.InitiationClass = "psp-card--progress--value text-color-danger";
          this.InitianDone = data.Percent + "%";
        }
      }
      else if (PhaseFor == "2") {
        if (data.Percent == 0) {
          this.PlanningDone = "";
          this.PlanningClass = "psp-card--progress--incomplete";
        }
        else if (data.Percent == 100) {
          this.PlanningDone = "";
          this.PlanningClass = "psp-card--progress--complete";
        }
        else {
          this.PlanningClass = "psp-card--progress--value text-color-danger";
          this.PlanningDone = data.Percent + "%";
        }
      }
      else if (PhaseFor == "3") {
        if (data.Percent == 0) {
          this.ExecutionDone = "";
          this.ExecutionClass = "psp-card--progress--incomplete";
        }
        else if (data.Percent == 100) {
          this.ExecutionDone = "";
          this.ExecutionClass = "psp-card--progress--complete";
        }
        else {
          this.ExecutionClass = "psp-card--progress--value text-color-danger";
          this.ExecutionDone = data.Percent + "%";
        }
      }
      else {
        if (data.Percent == 0) {
          this.ClosureDone = "";
          this.ClosureClass = "psp-card--progress--incomplete";
        }
        else if (data.Percent == 100) {
          this.ClosureDone = "";
          this.ClosureClass = "psp-card--progress--complete";
        }
        else {
          this.ClosureDone = data.Percent + "%";
          this.ClosureClass = "psp-card--progress--value text-color-danger";
        }
      }
    });
  }
  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }

  RejectProjectApproval() {
    const dialogRef = this.dialog.open(DialogReasonforProjectapproverejectComponent, {
      data: { ContractID: this.route.snapshot.queryParams['ContractID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result != "") {
        this.DbService.AcceptorRejectProjectRequestmail(this.route.snapshot.queryParams['TMID'], sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['ContractID'], "Reject", result).subscribe((data: any) => {
          if (data.RetStatus == "Success") {
            alert("Your reject project message is sent");
            this.router.navigate(['dashboard/projects/database']);
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = 'reload';
            // this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: this.route.snapshot.queryParams['ContractID'], ContractType:"Reprocure"}});
          }
          else {
            alert(data.RetStatus);
          }
        });
      }
    });
  }
  AcceptProjectApproval() {
    this.DbService.AcceptorRejectProjectRequestmail(this.route.snapshot.queryParams['TMID'], sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['ContractID'], "Accept", "").subscribe((data: any) => {
      if (data.RetStatus == "Success") {
        alert("New project request accepted");
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['dashboard/projects/new-project'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'], ContractType: "Reprocure" } });
      }
      else {
        alert(data.RetStatus);
      }
    });
  }

  selectEvent(item) {
    // do something with selected item
    //alert(item.PK_CategoryTypeID);
    this.SubCategorySelected = item.PK_CategoryTypeID;
    //this.NewProject.get("SubCategory").setValue(item.PK_CategoryTypeID);
    //
    if (this.route.snapshot.queryParams['ContractID'] == null) {
      this.tabchange = true;
      this.isDisabledContent = false;
    }
    this.SubCategory = null;
  }

  selectSimpleCatEvent(item: string) {

    this.SubCategorySelected = item

    if (this.route.snapshot.queryParams['ContractID'] == null) {
      this.tabchange = true;
      this.isDisabledContent = false;
    }
    //this.SubCategory = null;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    //alert(val);
    if (val.length > 1) {
      this.getSubCategories(val);
    }
  }

  onFocused(e) {
    // do something when input is focused
  }

  getSubCategories(searchtext: any) {
    //alert(this.CategoryId);
    //this.Dservice.getSubCategory(this.newcontract.get("ddlCategory").value).subscribe((data:any) =>
    this.Dservice.getAllSubCategories_Search(searchtext).subscribe((data: any) => {
      //console.log(data);
      this.SubCategory = data;
      //this.newcontract.get("ddlSubCategory").setValue(data[0].PK_CategoryTypeID);
      //this.onChanges();
    });


  }

  ClearSubCategorySelection(e) {
    this.SubCategorySelected = "";
  }

  DeleteTemporaryProject() {
    if (sessionStorage.getItem("usertype") == "Procurement Officer") {
      this.DbService.DeleteTemporaryProject(Number(this.route.snapshot.queryParams['ContractID'])).subscribe((data: any) => {
        if (data.value != "Success") alert(data.value);
      });
    }
  }

  LoadAllCaptions() {
    this.setService.getCaptionFromDB("dashboard-projects-new-project").subscribe((data: any) => {
      this.Names = data;
    });
    this.setService.getProjectPhaseLabelFromDB().subscribe((data: any) => {
      this.ProjectMSList = data;
      console.log("ProjectMSList", this.ProjectMSList);
    });
    this.setService.getTooltipFromDB("dashboard-projects-new-project").subscribe((data: any) => {
      this.Tooltips = data;
    });



  }


  getCaption(sCaption: string) {
    var sResult = sCaption;
    if (this.Names != null) {
      this.Names.forEach(element => {
        if (element.OriginalName === sCaption) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }

  getProjectCaption(projectID: any) {
    var sResult = "";
    if (this.ProjectMSList != null) {
      this.ProjectMSList.forEach(element => {
        if (element.Id === projectID) {
          sResult = element.ProjectPhase_Type;
          return false;
        }

      });
    }
    return sResult;
  }

  getProjectTooltip(sTooltip: any) {
    var sResult = "";
    if (this.Tooltips != null) {
      this.Tooltips.forEach(element => {
        if (element.OriginalName === sTooltip) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }

  isLLAEnabled() {
    this.setService.enableLondonLivingAllowance().subscribe((data: any) => {
      this.bLLAEnabled = (data.value == "yes")
    });

  }

  UseSimpleSubCategory() {
    this.setService.isSimpleSubCategory().subscribe((data: any) => {
      this.bUseSimpleSubCategory = (data.value == "yes")
    });

  }

  onCategorySelected(value: string) {
    console.log("value", value);
    this.DbService.getSubCategorybyCat(Number(value)).subscribe((data: any) => {
      this.SubCategorySelected = data[0].PK_CategoryTypeID;
      this.SubCategory = data;
      this.NewProject.get("SubCategory").setValue(data[0].PK_CategoryTypeID);

      console.log("onCategorySelected", data);
    });
  }

  cancelProjectOverview() {
    this.Revert("Details");
  }

  routeToHome() {
    if (!this.tabchange && !this.tabchangeMilestone && !this.savedetail) {
      this.router.navigate(["/dashboard/performance"]);
    }
  }

  routeToProjects() {
    if (!this.tabchange && !this.tabchangeMilestone && !this.savedetail) {
      this.router.navigate(["/dashboard/projects"]);
    }
  }

  routeToDatabase() {
    if (!this.tabchange && !this.tabchangeMilestone && !this.savedetail) {
      this.router.navigate(["/dashboard/projects/database"]);
    }
  }
}


