import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import {DashboardPerformanceService} from '../../dashboard/dashboard-performance/dashboard-performance.service';


@Component({
  selector: 'app-dialog-resubmit-project-timetable-impact',
  templateUrl: './dialog-resubmit-project-timetable-impact.component.html',
  styleUrls: ['./dialog-resubmit-project-timetable-impact.component.scss']
})
export class DialogResubmitProjectTimetableImpactComponent implements OnInit {
  SlippageValue : any;
  constructor() { }

  ngOnInit() {
    if(sessionStorage.getItem('ResubmitProjectSlippage') != null)
    {
      if(sessionStorage.getItem('ResubmitProjectSlippage') != "null")
      {
        this.SlippageValue = sessionStorage.getItem('ResubmitProjectSlippage');
      }

    }
  }

}
