import { Component, OnInit } from '@angular/core';
import { InvitesuserService } from '../invitesuser.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-editsubcategory',
  templateUrl: './editsubcategory.component.html',
  styleUrls: ['./editsubcategory.component.scss']
})
export class EditsubcategoryComponent implements OnInit {

 
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    //this.LoadCategoryMasterList();
    this.LoadCategoryList();
   }
   CategoryMaster:any;
   Categories:any;
 
   CategoryGroup : FormGroup;
   submitted:boolean=false;
   ngOnInit() {
     this.CategoryGroup = this.fb.group({
      ddlcategory : ['',Validators.required],
      txtSubcategory:['',Validators.required]
     });
   }
   get f() { return this.CategoryGroup.controls; };
  //  selectchanged()
  //  {
  //    let categoryID=this.CategoryGroup.get("ddlcategory").value;
  //    this.InvserObj.GetSubcategories()
  //    .subscribe((sData:any) => {
  //      this.subcategoryList=sData;
  //    });
  //  }

   subcategoryList:any;
   LoadCategoryList()
   {
    //  this.InvserObj.GetCategories().subscribe((data:any) => 
    //  {
    //    this.Categories=data.value;
    //   if(this.Categories.length>0){
       // this.CategoryGroup.get("ddlcategory").setValue(this.Categories[0].PK_CategoryGroupID);
        this.InvserObj.GetSubcategories()
        .subscribe((sData:any) => {
          this.subcategoryList=sData;
        });
       
     //}});
   }
   RemoveSubCategroy(SubCategory:String)
   {
     for(let i=0;i<this.subcategoryList.length;i++)
     {
       if(this.subcategoryList[i].CategoryTypeDescription == SubCategory)
       {
         this.subcategoryList.splice(i, 1);
         break;
       }
     }
   }
  
   AddSubCategorylist()
   {
    this.submitted = true;
 
    // stop here if form is invalid
    if (this.CategoryGroup.invalid) 
    {
      
      return;
    }

    let categoryexist=false;
  
     for(let p=0;p<this.subcategoryList.length;p++)
     { 
       
       if(this.subcategoryList[p].CategoryTypeDescription == this.CategoryGroup.get("txtSubcategory").value)
       {
       categoryexist=true;
        break;
        }
     }
     
      if(categoryexist == false)
      {
        let jsonstring= '{"CategoryTypeDescription" :"'+this.CategoryGroup.get("txtSubcategory").value+'","Abbreviation":"'+ this.CategoryGroup.get("ddlcategory").value +'","Active":"0"}';
        this.subcategoryList.push(JSON.parse(jsonstring));
       
        this.CategoryGroup.get("txtSubcategory").setValue(" ");
        this.CategoryGroup.get("ddlcategory").setValue(" ");
      }
      else

      {
        alert("Category already exists!");
      }
        
     
      
     
   }
   SaveSubCategories(event)
   {
     const formdecdata=new FormData();
   
     formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
     formdecdata.append('CategoryID',this.CategoryGroup.get("ddlcategory").value);
     this.InvserObj.DeactiveSubCategory(formdecdata).subscribe((data:any) => 
     {
     
      if(data.RetStatus = "Deleted")
      {
        // if(this.subcategoryList.length>0)
        // {
          if(this.subcategoryList.length==0)
          {
            alert("Saved Successfully");
          }
          else
          {
          for(let i=0;i <this.subcategoryList.length;i++)
           { 
             const formdata=new FormData();
             formdata.append('CategoryID', this.subcategoryList[i].CategoryID);
             formdata.append('CategoryCode', this.subcategoryList[i].Abbreviation);
             formdata.append('CategoryTypeDescription', this.subcategoryList[i].CategoryTypeDescription);
             formdata.append('Active', this.subcategoryList[i].Active);
             formdata.append('ContactID',sessionStorage.getItem("ContactID"));
             this.InvserObj.AddSubCategoryList(formdata).subscribe((data:any) => 
             {
               if(i== this.subcategoryList.length-1)
               {
                  alert("Saved Successfully");
                  this.InvserObj.GetSubcategories().subscribe((data:any) => 
                  {
                    this.subcategoryList=data;
                  });
               }
             });
            }
         //  }
         }
       }
         });
     
   }


}
