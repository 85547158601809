import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { SettingsService } from '../settings/settings.service';
//import { DomSanitizer } from '@angular/platform-browser'; 
import { Observable, Observer } from 'rxjs';
import { EventEmitterService } from '../settings/event-emitter.service';
import { SystemNotificationsComponent } from '../system-notifications/system-notifications.component';
import { SystemNotificationsService } from '../system-notifications/system-notifications.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [SystemNotificationsComponent]
})
export class HeaderComponent implements OnInit {
  url: any;
  SettingsShow: boolean = false;
  NotificationCount: number = 0;
  @Input() selectedSection: string = 'performance';

  @Input() inputTabchange: boolean = false;
  @Input() inputtabchangeMilestone: boolean = false;
  @Input() inputsaveDetail: boolean = false;


  constructor(private router: Router, private lService: LoginService, private SetSer: SettingsService, private eventEmitterService: EventEmitterService,
    private systemNotification: SystemNotificationsComponent, private sysnotobj: SystemNotificationsService) {
    if (sessionStorage.getItem("ContactID") != null) {
      if (sessionStorage.getItem("firsttime") == null) {
        this.getLoginUserFirstName(sessionStorage.getItem("ContactID"));
        this.getCompanyLogo();

      }
      else {
        this.LoginUser = sessionStorage.getItem("firsttime");
        this.url = sessionStorage.getItem("CompanyLogo");

      }

      this.GetLoginUserForPermission();
      this.GetNotificationCount();
    }
    /* else if(localStorage.getItem("ContactID") !=null)
     {
       this.getLoginUserFirstName(localStorage.getItem("ContactID"));
     }*/
    else {
      this.router.navigate(['/login']);
    }
  }
  LoginUser: string;
  ngOnInit() {
    //this.getCompanyLogo();
    if (this.eventEmitterService.subsVar == undefined) {

      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeFirstComponentFunction.subscribe((name: string) => {
          this.getCompanyLogo();
          this.GetNotificationCount();
        });
    }
  }

  GetLoginUserForPermission() {
    if (sessionStorage.getItem("usertype") == "Head of Procurement") {
      this.SettingsShow = true;
    }
    else {
      this.lService.getContactByEmail(sessionStorage.getItem('userMail')).subscribe((data: any) => {
        if (data.AdminPerMission == "Yes" || data.ContractConfig == "Yes") {
          this.SettingsShow = true;
        }

      });
    }

  }

  showSystemNotification() {

    //this.systemNotification.show();
    //added on 10/02/2020
    this.SetSer.CheckNotificationBell().subscribe((data: any) => {
      // alert(data);
      if (data.value == "Yes") {

        this.systemNotification.show();
      }
    });
  }

  getCompanyLogo() {
    this.SetSer.getCompanyLogo().subscribe((data: any) => {
      //this.url=this.lService.ServiceIP + data.value;
      this.getBase64ImageFromURL(this.lService.ServiceIP + data.value).subscribe(base64data => {
        console.log(base64data);
        // this is the image as dataUrl
        this.url = 'data:image/jpg;base64,' + base64data;
        sessionStorage.setItem("CompanyLogo", this.url);
        this.url = sessionStorage.getItem("CompanyLogo");
      });

    });
  }
  Clearsession() {
    sessionStorage.removeItem('ContactID');
    sessionStorage.removeItem('usertype');
    sessionStorage.removeItem('AdminPermission');
    sessionStorage.removeItem('firsttime');
    sessionStorage.removeItem("ApproveProject");
    localStorage.removeItem('register');
    localStorage.removeItem('organisation');
    localStorage.removeItem('team');
    localStorage.removeItem('inviteuser');
    localStorage.removeItem('inviteClientAdmin');
    sessionStorage.removeItem("AllowcationList");
    sessionStorage.removeItem("ProjectData");
    sessionStorage.removeItem("ProjectFinanceData");
    sessionStorage.removeItem("IsSuperUser");
    this.router.navigate(['/login']);
  }
  Result: any;
  async getLoginUserFirstName(ContactID: any) {

    this.Result = await this.lService.getContactUserForMyAccount(sessionStorage.getItem('userMail'));
    console.log("result", this.Result);

    this.LoginUser = this.Result.ContactFirstName + " " + this.Result.ContactSurname;
    sessionStorage.setItem("firsttime", this.LoginUser);
    //this.url=this.lService.ServiceIP +"/UploadFile/SystemSettings/c8c734ec-d885-484f-938a-b10c6ff0454fabout-02.jpg";
    //console.log("Img URl" + this.url);
    // this.lService.getContactUserForMyAccount(ContactID).subscribe((data:any)=>
    // {
    //   this.LoginUser=data.ContactFirstName +" "+data.ContactSurname;
    // });
  }
  // sanitize(url: string) {  
  //   return this.sanitizer.bypassSecurityTrustUrl(url);  
  // }  



  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  GetNotificationCount() {
    this.sysnotobj.GetSystemNotifications(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.NotificationCount = data.length;
    });
  }


  routeToPerformance() {    
    if (!this.inputTabchange && !this.inputtabchangeMilestone && !this.inputsaveDetail) {
      this.router.navigate(["/dashboard/performance"]);
    }
  }


  routeToProjects() {
    if (!this.inputTabchange && !this.inputtabchangeMilestone &&  !this.inputsaveDetail) {
      this.router.navigate(["/dashboard/projects"]);
    }
  }

  routeToContracts() {
    if (!this.inputTabchange && !this.inputtabchangeMilestone && !this.inputsaveDetail) {
      this.router.navigate(["/dashboard/contracts"]);
    }

  }


}

