import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'; 
import { TellusService } from './tellus.service';

@Component({
  selector: 'app-tellus',
  templateUrl: './tellus.component.html',
  styleUrls: ['./tellus.component.css']
})
export class TellusComponent implements OnInit {

  organisation: FormGroup;
  Roles:any;
  //LoginContactID:any;
  //LoginUserType:any;

  constructor(private fb:FormBuilder, private router:Router,private tService:TellusService,private route: ActivatedRoute) { 
    
    if(sessionStorage.getItem("ContactID") !=null)
     {
      this.getUserTypes();
      //this.LoginContactID=sessionStorage.getItem("ContactID");
      //this.LoginUserType=sessionStorage.getItem("usertype");
     }
     else
     {
      if(this.route.snapshot.queryParamMap.get('valid') =="true")
      {
        //console.log(localStorage.getItem("ContactID"));
        this.getUserTypes();
        //this.LoginContactID=this.route.snapshot.queryParamMap.get('val');
        //this.LoginContactID=localStorage.getItem("ContactID");
        //this.LoginUserType=localStorage.getItem("UserType");
        //localStorage.setItem('ContactID',data.UserID);
      }
      else
      {
        this.router.navigate(["/login"]);
      }
        
     }
  }

  ngOnInit() {
  //   this.router.events.subscribe((evt) => {
  //     if (!(evt instanceof NavigationEnd)) {
  //         return;
  //     }
  //     window.scrollTo(0, 0)
  // });

    this.organisation = this.fb.group({
      organisationFormControl:['', [Validators.required]],
      NoofPeopleFormControl:['',[Validators.required, Validators.min(1), Validators.max(25000)]],
      //ClientemailFormControl:['',[Validators.required,Validators.email]],
      //ClientNameFormControl:['',[Validators.required]],
      PeopleRole:['Head of Procurement']
    });
    //this.getUserTypebyID();
  }
  getUserTypes()
  {
    this.tService.getAllUserTypes().subscribe((data:any) =>
      {
        //console.log(data);
        this.Roles=data.value;
        
      });
  }
  // getUserTypebyID()
  // {
  //   this.tService.getUserType(this.LoginUserType).subscribe((data:any) =>
  //         {
  //           //console.log(data.UserType_ID);
  //           var uID=data.UserType_ID;
  //           this.organisation.get('PeopleRole').setValue(uID.toString());
  //         });
  // }

  OrganisationSubmit()
  {
    if (!this.organisation.valid) {
      return;
      }
      //console.log(this.LoginContactID);
      const formdata=new FormData();
      formdata.append('Organisation_Name', this.organisation.get('organisationFormControl').value);
      formdata.append('No_of_People', this.organisation.get('NoofPeopleFormControl').value);
      //formdata.append('ContactID', this.LoginContactID);
      localStorage.setItem("organisation",JSON.stringify(this.organisation.value));

      //this.tService.OrganisationExists(this.organisation.get('organisationFormControl').value).subscribe((data:any) =>
      this.tService.CheckOrganisationName(formdata).subscribe((data:any) =>
      {
          if(data.value =="Exists")
          {
            this.organisation.get('organisationFormControl').reset();
            alert("Organisation Name already exists.");
          }
          else
          {
          this.router.navigate(['/inviteuser'], { queryParams: {valid: 'true'}});
          }
      });
      // this.tService.createOrganisation(formdata).subscribe((data:any) =>
      // {
      //     if(data.RetStatus =="Success")
      //     {
      //       this.router.navigate(['/createteam'], { queryParams: {valid: 'true', OrgID: data.OrganisationID}});

      //     }
      //     else if(data.RetStatus =="Organisation already exists.")
      //     {
      //       this.organisation.get('organisationFormControl').reset();
      //         alert("Organisation already exists.");
      //     }
      //     else
      //     {
      //       this.organisation.get('organisationFormControl').reset();
      //       alert(data.RetStatus);
      //     }
      // });
  }
}
