import { Component, OnInit,Inject } from '@angular/core';
import { InvitesuserService } from "../../invitesuser/invitesuser.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {SettingsService} from '../../settings/settings.service';
import { DecimalPipe } from '@angular/common';
import {DashboardPerformanceService} from '../../dashboard/dashboard-performance/dashboard-performance.service';
import { AppSettingsService } from 'src/app/service/app-settings.service';

@Component({
  selector: 'app-dialog-estimated-savings-target',
  templateUrl: './dialog-estimated-savings-target.component.html',
  styleUrls: ['./dialog-estimated-savings-target.component.scss']
})
export class DialogEstimatedSavingsTargetComponent implements OnInit {
  Roles:any;
  Teams:any;
  SetTargets:FormGroup;
  submitted:boolean=false;
  FinancialYears:any;
  isOnesource=false;
  //contactDetails:any;
  constructor(private IService:InvitesuserService, private fb: FormBuilder,private _decimalPipe: DecimalPipe,private Dservice:DashboardPerformanceService,
    public dialog: MatDialog ,@Inject(MAT_DIALOG_DATA) public data: any,
    private settingService:SettingsService,appSettings:AppSettingsService) {
      if( appSettings.siteKeys.domain == 'onesource')
      {
       this.isOnesource=true;
      }
      if(this.data.OrgID == 0)
      {
        this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
        {
          this.data.OrgID = tdata.value[0].OrganisationID;
        });
      }
   }

  ngOnInit() {
    this.SetTargets = this.fb.group({
      UserName : [''],
      ddlRole : [''],
      ddlaccountstatus : [''],
      ddlconfig :[''],
      ddladmin :[''],
      ddlAllocateTeam : [''],
      ddlFinancialyear : ['2020-21'],
      txtSavingsTarget:['0',Validators.required],
      txtLifetimeSavingsTarget:['0']
      
   });
  
   this.getInviteeRoles();
    this.getUserLoadTeams();
    
   this.GetContactDetails(this.data.ContactID);
   this.GetFinancialYear();
   
  }

  get f(){return this.SetTargets.controls};

  GetFinancialYear()
  {
    this.Dservice.GetFinancialYear().subscribe((data:any) =>{
        this.FinancialYears=data.value;
        
        if(data.value.length >0)
        {
          //this.SetTargets.get("ddlFinancialyear").setValue(data.value[0].FinanceYear);
          this.SetTargets.get("ddlFinancialyear").setValue("2020-21");
          this.getEstimatedSavingsTargetsby_ContactID_FinancialYear(this.data.ContactID,this.data.TeamID,"2020-21");
        }
        else
        {
          this.SetTargets.get("ddlFinancialyear").setValue("2020-21");
        }
    });
  }

  getInviteeRoles()
  {
    
    this.IService.GetUserTypesforInviteUserwithoutHOP().subscribe((data:any) =>
    {
        this.Roles=data;
        this.SetTargets.get("ddlRole").setValue(this.Roles[0].UserType_ID);
        
    });
  }

  getUserLoadTeams()
  {
    this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
    {
      this.Teams=tdata.value;
      this.SetTargets.get("ddlAllocateTeam").setValue(tdata.value[0].TeamID);
    });
    //alert(this.data.OrgID);
  //  if(this.data.OrgID == "0")
  //  {
  //   this.IService.getTeamsbyContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  //   {
  //     this.Teams=data;
  //     //console.log( this.Teams);
  //     //this.SetTargets.get("ddlCategory").setValue(this.Teams[0].TeamID);
  
      
  //   });
  //  }
  //  else
  //  {
  //     this.IService.getTeamsbyOrganisation(this.data.OrgID).subscribe((data:any) =>
  //     {
  //       this.Teams=data.value;
     
  //     });
  //  }
  }

  GetContactDetails(contactID:any)
  {

    this.IService.ConctactDetails(contactID).subscribe((data1:any) =>
    {
      //console.log(data);
      //this.contactDetails=data;
      this.SetTargets.get("UserName").setValue(data1.ContactFirstName +" "+data1.ContactSurname);
      
      this.SetTargets.get("ddlRole").setValue(data1.ContactUser_Type) ;
      
      this.SetTargets.get("ddlaccountstatus").setValue(data1.ContractConfig =="1"?"Inactive":"Active");
      
      this.SetTargets.get("ddlconfig").setValue(data1.ContractConfig);
      
       this.SetTargets.get("ddladmin").setValue(data1.AdminPerMission);
       
      //this.SetTargets.get("ddlAllocateTeam").setValue(data1.TeamID);
      
    });
  }

  getEstimatedSavingsTarget()
  {
    this.getEstimatedSavingsTargetsby_ContactID_FinancialYear(this.data.ContactID,this.data.TeamID,this.SetTargets.get("ddlFinancialyear").value);
  }

  getEstimatedSavingsTargetsby_ContactID_FinancialYear(ContactID:any,TeamID:number,FinancialYear:string)
  {
    this.settingService.GetEST_by_ContactID_FY_TeamID(ContactID,TeamID,FinancialYear).subscribe((data1:any) =>
    //this.settingService.GetEstimatedSavingsTargetsby_ContactID_FinancialYear(ContactID,FinancialYear).subscribe((data1:any) =>
    {
      console.log("hi " + data1);
      if(data1.length >0)
      {
        // this.SetTargets.get("txtSavingsTarget").setValue("\u00A3"+ this._decimalPipe.transform(data1[0].EstimatedSavingTarget,'1.0-0'));
        this.SetTargets.get("txtSavingsTarget").setValue( this._decimalPipe.transform(data1[0].EstimatedSavingTarget));
        this.SetTargets.get("txtLifetimeSavingsTarget").setValue(this._decimalPipe.transform(data1[0].LifetimeSavingsTarget));
      }
      else
      {
        this.SetTargets.get("txtSavingsTarget").setValue("");
        this.SetTargets.get("txtLifetimeSavingsTarget").setValue("");
      }
      
    });
  }

  clearValue()
  {
    this.SetTargets.get("txtSavingsTarget").setValue("");
    this.SetTargets.get("txtLifetimeSavingsTarget").setValue("");
  }

  AddEstimatedSavingsData()
  {
    this.submitted=true;
    if(this.SetTargets.invalid)
    {
      return;
    }
    const formData=new FormData();
    formData.append("TeamID",this.data.TeamID);
    formData.append("ContactID",this.data.ContactID);
    formData.append("FinancialYear",this.SetTargets.get("ddlFinancialyear").value);
    formData.append("EstimatedSavingTarget",this.SetTargets.get("txtSavingsTarget").value);
    formData.append("LifetimeSavingsTarget",this.SetTargets.get("txtLifetimeSavingsTarget").value);
    formData.append("isOnesource",this.isOnesource.toString());
    this.settingService.AddEstimatedSavingsTarget(formData).subscribe((data:any) =>
    {
      if(data.RetStatus=="Created")
      {
        alert("Contract Savings Target Added Successfully");
        this.dialog.closeAll();
      }
      else if(data.RetStatus=="Updated")
      {
        alert("Contract Savings Target Updated");
        this.dialog.closeAll();
      }
      else
      {
        alert(data.RetStatus);
      }
    });

  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
}
}
