import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InvitesuserService } from '../invitesuser.service';

@Component({
  selector: 'app-editprojectmilestone',
  templateUrl: './editprojectmilestone.component.html',
  styleUrls: ['./editprojectmilestone.component.scss']
})

export class EditprojectmilestoneComponent implements OnInit {

  show:boolean=true;
  ProjectMilestone : FormGroup;
  submitted:boolean=false;
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    this.LoadProjectStatuList();
   }
   get f(){ return this.ProjectMilestone.controls;}
  ProjectMSList:any;
  ngOnInit() {
    this.ProjectMilestone = this.fb.group({
      ProjectMS : ['',Validators.required],
     });
  }
  LoadProjectStatuList()
  {
    this.InvserObj.GetProjectMileStoneList().subscribe((data:any) => 
     {
       this.ProjectMSList=data;
           
     });

  }
  RemoveProjectStatus(PStatus:String)
  {
    
    for(let i=0;i<this.ProjectMSList.length;i++)
    {
      if(this.ProjectMSList[i].ProjectPhase_Type == PStatus)
      {
        this.ProjectMSList.splice(i, 1);
        
        break;
      }
    }
  }
 
  AddProjectStatus()
  {
    
    this.submitted = true;
    if (this.ProjectMilestone.invalid) 
    {
       return ;
    }
    let statusexist=false;
    let PS=this.ProjectMilestone.get("ProjectMS").value;
    
    for(let p=0;p<this.ProjectMSList.length;p++)
    { 
     
      if(this.ProjectMSList[p].ProjectPhase_Type == PS )
      {
        statusexist=true;
        break;
       }
    }
    
    if(statusexist == false)
    {
      let jsonstring= '{"ProjectPhase_Type" :"'+PS+'","Active" :0}';
      this.ProjectMSList.push(JSON.parse(jsonstring));
    }
}
SaveProjectMilestone()
  {
    
    const formdecdata=new FormData();
  
    formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
    this.InvserObj.DeactiveProjectMileStone(formdecdata).subscribe((data:any) => 
    {
    
        if(data.RetStatus = "Deleted")
        {
        if(this.ProjectMSList.length>0)
        {
          for(let i=0;i <this.ProjectMSList.length;i++)
            { 
              const formdata=new FormData();
              formdata.append('ProjectPhase_Type', this.ProjectMSList[i].ProjectPhase_Type);
              formdata.append('ContactID',sessionStorage.getItem("ContactID"));
              formdata.append('Active', this.ProjectMSList[i].Active);
              if(i==0)
              {
              formdata.append('Status','true');
              }
              else
              {
                formdata.append('Status','false');
              }
              this.InvserObj.AddProjectMSList(formdata).subscribe((data:any) => 
              {
                
                if(i== this.ProjectMSList.length-1)
                {
                  alert("Saved Successfully");
                    this.InvserObj.GetProjectMileStoneList().subscribe((data:any) => 
                    {
                      this.ProjectMSList=data;
                    
                    });
                }
              });
            }
        }
        }
    });
  }

}
