import { Component, OnInit,ViewChild,ViewEncapsulation} from '@angular/core';
import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';

@Component({
  selector: 'app-dialog-team-gantt',
  templateUrl: './dialog-team-gantt.component.html',
  styleUrls: ['./dialog-team-gantt.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogTeamGanttComponent implements OnInit {
  @ViewChild('editor' ,{static:true}) editor: GanttEditorComponent;
  public editorOptions: any = {};
  public data: any;
  constructor(private DbService:ProjectDatabaseService) { }

  ngOnInit() {
    this.BindGanttChart();
    //this.data=this.initialData();
    this.editorOptions = {
      vFormatArr: ['Day', 'Week', 'Month', 'Quarter'],
     vFormat: 'week',
     vShowRes:(0),
     vShowComp:(0),
     vShowStartDate:(0),
     vShowEndDate:(0),
     vShowDur:(0),
     vDayColWidth:36,
     vWeekColWidth:54,
     vQuarterColWidth:36,
     vShowTaskInfoRes:(0),
     vShowTaskInfoNotes:(0),
     vUseSort:(0),
     vMaxDate:"2025-12-31"
        };
    this.editor.setOptions(this.editorOptions);
  }
  
  async BindGanttChart()
  {
    if(sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.data=await this.DbService.GetAllProjectByTeamForGanttChart(sessionStorage.getItem("ContactID"));
    }
    else
    {
      //this.data=await this.DbService.GetProjectForNonHOPByTeamForGanttChart(sessionStorage.getItem("ContactID"));
      this.data=await this.DbService.GetProjectGanttChartbyForTeamUserType(sessionStorage.getItem("ContactID"),sessionStorage.getItem("usertype"));
      console.log(this.data);
    }
    //console.log(this.data);
  }

  // initialData()
  // {
  //   return [{
  //     "pID":60,"pName":"Procurement (WF)","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":725,"pName":"Tushar Chitre","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":60,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12269,"pName":"Test Project by AW","pStart":"2019-12-12","pEnd":"2019-12-31","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":725,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":727,"pName":"Chitre Tushar","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":60,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12254,"pName":"Test Project #26112019","pStart":"2019-12-01","pEnd":"2019-12-26","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":100.0,"pGroup":0,"pParent":727,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12253,"pName":"(TC) Creating UI & Mockups for Demo","pStart":"2019-12-09","pEnd":"2019-12-31","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":727,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":734,"pName":"T C","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":60,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12254,"pName":"Test Project #26112019","pStart":"2019-12-01","pEnd":"2019-12-26","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":100.0,"pGroup":0,"pParent":734,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":736,"pName":"C T","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":60,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":61,"pName":"Procurement (R)","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":726,"pName":"Tush Chit","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":61,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12272,"pName":"Project to test Team Allocation","pStart":"2019-12-16","pEnd":"2019-12-27","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12271,"pName":"To check multiple projects under one contract","pStart":"2019-12-13","pEnd":"2019-12-21","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12267,"pName":"(TC) Development of Reports for New Portal UI & UX Work","pStart":"2019-12-16","pEnd":"2020-01-31","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12265,"pName":"New Test Project via New Contract 02122019","pStart":"2019-12-03","pEnd":"2019-12-31","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12263,"pName":"New Test Project => New Project 01122019 (also creates New Contract)","pStart":"2019-12-01","pEnd":"2019-12-15","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":100.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12262,"pName":"(TC) Test Project for Gantt Chart Example","pStart":"2019-11-29","pEnd":"2020-01-02","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":33.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12261,"pName":"(TC) UI for Mobile Procurement Performance Portal {New Contract}`","pStart":"2019-12-01","pEnd":"2020-01-03","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12260,"pName":"Temp Hiring for new software development","pStart":"","pEnd":"","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12259,"pName":"New Test Project => New Contract 28/11/2019","pStart":"2019-11-28","pEnd":"2019-12-16","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12258,"pName":"New Test Project => New Contract 28/11/2019","pStart":"2019-12-01","pEnd":"2020-01-30","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":100.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12255,"pName":"(TC) Development of Mobile Procurement Performance Portal {New Project}","pStart":"2019-11-27","pEnd":"2020-01-03","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":17.0,"pGroup":0,"pParent":726,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":729,"pName":"Ben Peggs","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":61,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12272,"pName":"Project to test Team Allocation","pStart":"2019-12-16","pEnd":"2019-12-27","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12271,"pName":"To check multiple projects under one contract","pStart":"2019-12-13","pEnd":"2019-12-21","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12267,"pName":"(TC) Development of Reports for New Portal UI & UX Work","pStart":"2019-12-16","pEnd":"2020-01-31","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12265,"pName":"New Test Project via New Contract 02122019","pStart":"2019-12-03","pEnd":"2019-12-31","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12264,"pName":"TV Re-licensing - Andrew Weston","pStart":"2020-01-11","pEnd":"2020-04-27","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12262,"pName":"(TC) Test Project for Gantt Chart Example","pStart":"2019-11-29","pEnd":"2020-01-02","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":33.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12258,"pName":"New Test Project => New Contract 28/11/2019","pStart":"2019-12-01","pEnd":"2020-01-30","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":100.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12257,"pName":"(TC) Development of UI & Graphics for the new Portal","pStart":"2019-11-29","pEnd":"2019-12-28","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":23.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":12255,"pName":"(TC) Development of Mobile Procurement Performance Portal {New Project}","pStart":"2019-11-27","pEnd":"2020-01-03","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":17.0,"pGroup":0,"pParent":729,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":730,"pName":"Amanda Prett","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":61,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":1,"pName":"Project to test Team Allocation","pStart":"2019-12-16","pEnd":"2019-12-27","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":730,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":2,"pName":"To check multiple projects under one contract","pStart":"2019-12-13","pEnd":"2019-12-21","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":730,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":3,"pName":"New Test Project via New Contract 02122019","pStart":"2019-12-03","pEnd":"2019-12-31","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":730,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":4,"pName":"TV Re-licensing - Andrew Weston","pStart":"2020-01-11","pEnd":"2020-04-27","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":730,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":5,"pName":"(TC) UI for Mobile Procurement Performance Portal {New Contract}`","pStart":"2019-12-01","pEnd":"2020-01-03","pClass":"gtaskblue","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":0,"pParent":730,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":737,"pName":"Ann Bradley","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":61,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":738,"pName":"Tom McKenzie","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":61,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":62,"pName":"IT LBR","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":64,"pName":"IT LBWF","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":65,"pName":"Finance (WF)","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":66,"pName":"Finance (R)","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":67,"pName":"Administration (WF)","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""},
  //     {"pID":68,"pName":"Administration (R)","pStart":"","pEnd":"","pClass":"ggroupblack","pLink":"","pMile":0,"pRes":"","pComp":0.0,"pGroup":1,"pParent":0,"pOpen":1,"pDepend":"","pCaption":"","pNotes":""}]
  // }
  // initialData() {
  //   return [{
  //     'pID': 1,
  //     'pName': 'Define Chart API',
  //     'pStart': '',
  //     'pEnd': '',
  //     'pClass': 'ggroupblack',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 0,
  //     'pGroup': 1,
  //     'pParent': 0,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': 'Some Notes text'
  //   },
  //   {
  //     'pID': 11,
  //     'pName': 'Chart Object',
  //     'pStart': '2020-01-20',
  //     'pEnd': '2020-02-20',
  //     'pClass': 'gmilestone',
  //     'pLink': '',
  //     'pMile': 1,
  //     'pRes': 'Shlomy',
  //     'pComp': 100,
  //     'pGroup': 0,
  //     'pParent': 1,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 12,
  //     'pName': 'Task Objects',
  //     'pStart': '',
  //     'pEnd': '',
  //     'pClass': 'ggroupblack',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Shlomy',
  //     'pComp': 40,
  //     'pGroup': 1,
  //     'pParent': 1,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 121,
  //     'pName': 'Constructor Proc #1234 of February 2017',
  //     'pStart': '2020-02-21',
  //     'pEnd': '2020-03-09',
  //     'pClass': 'gtaskblue',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian T.',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 12,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 122,
  //     'pName': 'Task Variables',
  //     'pStart': '2017-03-06',
  //     'pEnd': '2017-03-11',
  //     'pClass': 'gtaskred',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 12,
  //     'pOpen': 1,
  //     'pDepend': 121,
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 123,
  //     'pName': 'Task by Minute/Hour',
  //     'pStart': '2017-03-09',
  //     'pEnd': '2017-03-14 12: 00',
  //     'pClass': 'gtaskyellow',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Ilan',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 12,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 124,
  //     'pName': 'Task Functions',
  //     'pStart': '2017-03-09',
  //     'pEnd': '2017-03-29',
  //     'pClass': 'gtaskred',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Anyone',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 12,
  //     'pOpen': 1,
  //     'pDepend': '123SS',
  //     'pCaption': 'This is a caption',
  //     'pNotes': null
  //   },
  //   {
  //     'pID': 2,
  //     'pName': 'Create HTML Shell',
  //     'pStart': '2017-03-24',
  //     'pEnd': '2017-03-24',
  //     'pClass': 'gtaskyellow',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 20,
  //     'pGroup': 0,
  //     'pParent': 0,
  //     'pOpen': 1,
  //     'pDepend': 122,
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 3,
  //     'pName': 'Code Javascript',
  //     'pStart': '',
  //     'pEnd': '',
  //     'pClass': 'ggroupblack',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 0,
  //     'pGroup': 1,
  //     'pParent': 0,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 31,
  //     'pName': 'Define Variables',
  //     'pStart': '2017-02-25',
  //     'pEnd': '2017-03-17',
  //     'pClass': 'gtaskpurple',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 30,
  //     'pGroup': 0,
  //     'pParent': 3,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 32,
  //     'pName': 'Calculate Chart Size',
  //     'pStart': '2017-03-15',
  //     'pEnd': '2017-03-24',
  //     'pClass': 'gtaskgreen',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Shlomy',
  //     'pComp': 40,
  //     'pGroup': 0,
  //     'pParent': 3,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 33,
  //     'pName': 'Draw Task Items',
  //     'pStart': '',
  //     'pEnd': '',
  //     'pClass': 'ggroupblack',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Someone',
  //     'pComp': 40,
  //     'pGroup': 2,
  //     'pParent': 3,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 332,
  //     'pName': 'Task Label Table',
  //     'pStart': '2017-03-06',
  //     'pEnd': '2017-03-09',
  //     'pClass': 'gtaskblue',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 33,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 333,
  //     'pName': 'Task Scrolling Grid',
  //     'pStart': '2017-03-11',
  //     'pEnd': '2017-03-20',
  //     'pClass': 'gtaskblue',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 0,
  //     'pGroup': 0,
  //     'pParent': 33,
  //     'pOpen': 1,
  //     'pDepend': '332',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 34,
  //     'pName': 'Draw Task Bars',
  //     'pStart': '',
  //     'pEnd': '',
  //     'pClass': 'ggroupblack',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Anybody',
  //     'pComp': 60,
  //     'pGroup': 1,
  //     'pParent': 3,
  //     'pOpen': 0,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 341,
  //     'pName': 'Loop each Task',
  //     'pStart': '2017-03-26',
  //     'pEnd': '2017-04-11',
  //     'pClass': 'gtaskred',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 34,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 342,
  //     'pName': 'Calculate Start/Stop',
  //     'pStart': '2017-04-12',
  //     'pEnd': '2017-05-18',
  //     'pClass': 'gtaskpink',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 34,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 343,
  //     'pName': 'Draw Task Div',
  //     'pStart': '2017-05-13',
  //     'pEnd': '2017-05-17',
  //     'pClass': 'gtaskred',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 34,
  //     'pOpen': 1,
  //     'pDepend': '',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 344,
  //     'pName': 'Draw Completion Div',
  //     'pStart': '2017-05-17',
  //     'pEnd': '2017-06-04',
  //     'pClass': 'gtaskred',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 60,
  //     'pGroup': 0,
  //     'pParent': 34,
  //     'pOpen': 1,
  //     'pDepend': '342,343',
  //     'pCaption': '',
  //     'pNotes': ''
  //   },
  //   {
  //     'pID': 35,
  //     'pName': 'Make Updates',
  //     'pStart': '2017-07-17',
  //     'pEnd': '2017-09-04',
  //     'pClass': 'gtaskpurple',
  //     'pLink': '',
  //     'pMile': 0,
  //     'pRes': 'Brian',
  //     'pComp': 30,
  //     'pGroup': 0,
  //     'pParent': 3,
  //     'pOpen': 1,
  //     'pDepend': '333',
  //     'pCaption': '',
  //     'pNotes': ''
  //   }];
  // }
}
