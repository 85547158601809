import { Component, OnInit,Inject} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import {DashboardPerformanceService} from '../../dashboard/dashboard-performance/dashboard-performance.service';


@Component({
  selector: 'app-dialog-resubmit-project-timetable-financials-add-new',
  templateUrl: './dialog-resubmit-project-timetable-financials-add-new.component.html',
  styleUrls: ['./dialog-resubmit-project-timetable-financials-add-new.component.scss']
})
export class DialogResubmitProjectTimetableFinancialsAddNewComponent implements OnInit {
  FinancialYears : any;
  ddlContractFinancialYear : any;
  txtEstimatedContractSavings : any ="";
  txtEstimatedContractValue : any ="";
  findata : string ="";
  isdisabled : boolean = true;
  FinancSelectedText:string;
  constructor(private Cservice: DashboardPerformanceService,private DbService : ProjectDatabaseService,public dialogRef: MatDialogRef<DialogResubmitProjectTimetableFinancialsAddNewComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.ddlContractFinancialYear = "0";
    this.GetFinancialYear();
  }

  GetFinancialYear()
  {
    this.Cservice.GetFinancialYear().subscribe((data:any) =>{
       // alert(data.value);
       // console.log(data.value);
        this.FinancialYears=data.value;
        
      });
  }

  AddFinancialData()
  {
       //alert(this.txtEstimatedContractValue);
      if(this.ddlContractFinancialYear == "0")
      {
         alert("Please select financial year !");
         return;
      }
      if(this.txtEstimatedContractValue == "")
      {
        alert("Please enter estimated contract value");
        return;
      }
      // if(this.txtEstimatedContractValue == "0")
      // {
      //   alert("Estimated contract value cannot be zero");
      //   return;
      // }
      if(this.txtEstimatedContractSavings == "")
      {
        alert("Please enter estimated contract savings");
        return;
      }
      // if(this.txtEstimatedContractSavings == "0")
      // {
      //   alert("Estimated contract savings cannot be zero");
      //   return;
      // }
      //project Financial data......
      this.findata += this.ddlContractFinancialYear + ";" + this.txtEstimatedContractValue + ";" + this.txtEstimatedContractSavings + ";" +  this.FinancSelectedText + "$";
      //alert(this.findata);
      alert("Financial data added successfully!");
      this.ddlContractFinancialYear = "0";
      this.txtEstimatedContractSavings ="";
      this.txtEstimatedContractValue ="";
      
      this.isdisabled = false;
    

  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;
    //const reg = /^[1-9]\d*$/  ;
    if (!reg.test(input)) {
      e.preventDefault();
    }
  }

  Update()
  {
    sessionStorage.removeItem('ResubmitProjectSlippage1');
        //alert("hi");


    const slipageForm = new FormData();
    slipageForm.append("ProjectID", this.data.ProjectID);
    slipageForm.append("ContractStartDate", sessionStorage.getItem('ResubmitStartDate'));
    slipageForm.append("FinancialData", this.findata);
      //this.DbService.getSlippageValueResubmitProject(this.data.ProjectID,sessionStorage.getItem('ResubmitStartDate'),this.findata).subscribe((data:any) =>{
    this.DbService.getSlippageValueResubmitProjectPost(slipageForm).subscribe((data: any) => {
       // alert(data.value);
        // console.log(data.value);
        // this.FinancialYears=data.value;
        sessionStorage.setItem('ResubmitProjectFinancialData',this.findata);
        sessionStorage.setItem('ResubmitProjectSlippage', data.value);
        this.dialogRef.close();


       });
  }

  SelectedText(event)
  {
    this.FinancSelectedText=event.target.options[event.target.options.selectedIndex].text;

    //console.log(event.target.options[event.target.options.selectedIndex].text);
    
  }

}
