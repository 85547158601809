import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSetupWizardService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  getWizardCheckListForUser(UserType:string,SystemAdmin:string,ContractConfig:string)
  {
    return this.http.get(this.lService.ServiceIP +"/contact/GetWizardCheckListByUserType(UserType='"+ UserType +"',SystemAdmin='"+ SystemAdmin +"','"+ ContractConfig +"')");
  }

  getWizardCheckListByContactID(ContactID:number,UserType:string)
  {
    return this.http.get(this.lService.ServiceIP +"/contact/GetWizardCheckListByContactID(ContactID="+ ContactID +",UserType='"+ UserType +"')");
  }

  WizardMenuCheckList_Insert(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/contact/WizardMenuCheckList_Insert",formData);
  }

}
