import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InvitesuserService } from '../invitesuser.service';

@Component({
  selector: 'app-editprojectprogress',
  templateUrl: './editprojectprogress.component.html',
  styleUrls: ['./editprojectprogress.component.scss']
})
export class EditprojectprogressComponent implements OnInit {

  show:boolean=true;
  ProjectGroup : FormGroup;
  submitted:boolean=false;
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    this.LoadProjectProgressList();
   }
  ProjectProgressList:any;
  ngOnInit() {
    this.ProjectGroup = this.fb.group({
      ProjectprogDesc : ['',Validators.required],
     });
  }
  get f(){ return this.ProjectGroup.controls;}
  LoadProjectProgressList()
  {
    this.InvserObj.GetProjectProgress().subscribe((data:any) => 
     {
       this.ProjectProgressList=data;
      console.log(data);
       
     });

  }
  RemoveProjectProgress(PStatus:String)
  {
    for(let i=0;i<this.ProjectProgressList.length;i++)
    {
      if(this.ProjectProgressList[i].ProjectprogDesc == PStatus)
      {
        
    
        this.ProjectProgressList.splice(i, 1);
      }
    }
  }
 
  AddProjectProgress()
  {
    
    this.submitted = true;
    if (this.ProjectGroup.invalid) 
    {
       return ;
    }
    let statusexist=false;
    let PS=this.ProjectGroup.get("ProjectprogDesc").value;
    for(let p=0;p<this.ProjectProgressList.length;p++)
    { 
     
      if(this.ProjectProgressList[p].ProjectprogDesc == PS )
      {
        statusexist=true;
        break;
       }
    }
    
    if(statusexist == false)
    {
      let jsonstring= '{"ProjectprogDesc" :"'+PS.trim()+'","Active" :0}';
      this.ProjectProgressList.push(JSON.parse(jsonstring));
      this.ProjectGroup.get("ProjectprogDesc").setValue(" ");
    }
}
  SaveProjectStatus(event)
  {
    const formdecdata=new FormData();
  
    formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
    this.InvserObj.DeactiveProjectProgress(formdecdata).subscribe((data:any) => 
    {
    
  if(data.RetStatus = "Deleted")
  {
  
      if(this.ProjectProgressList.length>0)
      {
        for(let i=0;i <this.ProjectProgressList.length;i++)
          { 
            
            const formdata=new FormData();
            formdata.append('ProjectprogDesc', this.ProjectProgressList[i].ProjectprogDesc);
            formdata.append('Active', this.ProjectProgressList[i].Active);
            formdata.append('ContactID',sessionStorage.getItem("ContactID"));
            if(i==0)
            {
            formdata.append('Status','true');
            
            }
            else
            {
              formdata.append('Status','false');
            }
            this.InvserObj.AddProjectStatusList(formdata).subscribe((data:any) => 
            {
              
              if(i== this.ProjectProgressList.length-1)
              {
                alert("Saved Successfully");
                  this.InvserObj.GetProjectProgress().subscribe((data:any) => 
                  {
                    this.ProjectProgressList=data;
                  
                  });
              }
            });
            
          }
        }
      }
        });
    
  }

}
