import { Component, OnInit } from '@angular/core';
import { InvitesuserService } from "../invitesuser.service";
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-editfinanciallist',
  templateUrl: './editfinanciallist.component.html',
  styleUrls: ['./editfinanciallist.component.scss']
})
export class EditfinanciallistComponent implements OnInit {

  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    this.LoadFinancialYearMasterList();
    this.LoadAddedFinancialYearList();
   }
  FinancialMasteryear:any;
  DeleteYears : any = ["",""];
  SortedListYears : any =["",""];
  Financialyear:any=[];
  show:boolean=true;
  YearGroup : FormGroup;
  ngOnInit() {
    this.YearGroup = this.fb.group({
      ddlyear : ['',Validators.required],
    });
  }
  get f() { return this.YearGroup.controls; };
  LoadFinancialYearMasterList()
  {
    
    //
    this.InvserObj.GetFinanceMasterYears().subscribe((data:any) => 
    {
      this.FinancialMasteryear=data;
      if(this.FinancialMasteryear.length>0)
      {
               
        this.YearGroup.get("ddlyear").setValue(this.FinancialMasteryear[0].FinanceYear);
      }
      else
      {
        this.show=false;
      }
    
    });
  }
  LoadAddedFinancialYearList()
  {
    this.InvserObj.GetFinanceYears().subscribe((data:any) => 
    {
      //console.log(data);
      this.Financialyear=data;
     
      
    });
  }

  
  
  RemoveYear(Years:String)
  {
      for(let i=0;i<this.Financialyear.length;i++)
      {
        if(this.Financialyear[i].FinanceYearDisplay == Years)
        {
          
          let jsonstring= '{"FinanceYear" :"'+this.Financialyear[i].FinanceYear +'","FinanceYearDisplay":"'+ this.Financialyear[i].FinanceYearDisplay  +'"}';
          this.FinancialMasteryear.push(JSON.parse(jsonstring));
           this.DeleteYears.push(JSON.parse(jsonstring));
          this.Financialyear.splice(i, 1);
        /* let r : string;
         let s : any;
         r = this.Financialyear[i].FinanceYear;
         
         
         for(let i=0;i<this.FinancialMasteryear.length;i++)
         {
                 s= this.FinancialMasteryear[i].FinanceYear;
                 if(parseInt(r.substring(0,4)) > parseInt(s.substring(0,4)))
                 {
                  this.SortedListYears.push(JSON.parse(jsonstring));
                 }else{
                  this.SortedListYears.push(JSON.parse('{"FinanceYear" :"'+this.FinancialMasteryear[i].FinanceYear +'","FinanceYearDisplay":"'+ this.FinancialMasteryear[i].FinanceYearDisplay  +'"}'));
                 }
         }
         // this.LoadFinancialYearMasterList();*/
         
        }
      }
      //this.FinancialMasteryear = this.SortedListYears;
  }
 
  AddYeartoList()
  {
    let FinanceYearDisplay;
   
    for(let p=0;p<this.FinancialMasteryear.length;p++)
    { 
      
      if(this.FinancialMasteryear[p].FinanceYear == this.YearGroup.get("ddlyear").value)
      {
        FinanceYearDisplay=this.FinancialMasteryear[p].FinanceYearDisplay;
        this.FinancialMasteryear.splice(p, 1);
        if(this.FinancialMasteryear.length>0)
        {
          
          let jsonstring= '{"FinanceYear" :"'+this.YearGroup.get("ddlyear").value+'","FinanceYearDisplay":"'+ FinanceYearDisplay +'"}';
          this.Financialyear.push(JSON.parse(jsonstring));
          //this.YearGroup.get("ddlyear").setValue(this.FinancialMasteryear[p].FinanceYear);
          console.log(this.Financialyear);
        }
        else
        {
          this.show=false;
        }
        break;
      }
      this.Financialyear.sort((a,b)=>a.FinanceYearDisplay > b.FinanceYearDisplay)
    }
    
    
  }
  SaveFinancialYears(event)
  {
    let count =0;
   if(this.Financialyear.length>0)
   {
    for(let i=0;i <this.Financialyear.length;i++)
      { 
        //count =count + 1;
        const formdata=new FormData();
        formdata.append('FinanceYear', this.Financialyear[i].FinanceYear);
        formdata.append('FinanceYearDisplay',this.Financialyear[i].FinanceYearDisplay);
        formdata.append('ContactID',sessionStorage.getItem("ContactID"));
      /*  if(i==0)
        {
        formdata.append('Status','true');
        }
        else
        {
          formdata.append('Status','false');
        }*/
        this.InvserObj.UpdateFinancialyears(formdata).subscribe((data:any) => 
        {
          
          /*if(i== this.Financialyear.length-1)
          {
            //alert("Saved Sucessfully");
              this.InvserObj.GetFinanceYears().subscribe((data:any) => 
              {
                this.Financialyear=data;
              
              });
          }*/
        });
        
      }
      //alert(count);
      //
      if(this.DeleteYears.length>0)
      {
       for(let i=0;i <this.DeleteYears.length;i++)
         { 
           //count =count + 1;
           const formdata=new FormData();
           formdata.append('FinanceYear', this.DeleteYears[i].FinanceYear);
           formdata.append('FinanceYearDisplay',this.DeleteYears[i].FinanceYearDisplay);
           formdata.append('ContactID',sessionStorage.getItem("ContactID"));
           
           formdata.append('Status','true');
          
           this.InvserObj.UpdateFinancialyears(formdata).subscribe((data:any) => 
           {
              
            if(i== this.DeleteYears.length-1)
            {
              alert("Saved Successfully");
                this.InvserObj.GetFinanceYears().subscribe((data:any) => 
                {
                  this.Financialyear=data;
                
                });
            }
           });
           
         }
        }
    }
    else
    {
      const formdata=new FormData();
      formdata.append('FinanceYear', "No Data");
      formdata.append('FinanceYearDisplay',"No Data");
      formdata.append('ContactID',sessionStorage.getItem("ContactID"));
      
      formdata.append('Status','true');
      this.InvserObj.UpdateFinancialyears(formdata).subscribe((data:any) => 
      {
        alert("Saved Successfully");
      });
     
    }
  }
 
}
