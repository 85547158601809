import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {RegisterService} from '../register/register.service';
import { TellusService } from '../tellus/tellus.service';
import {CreateteamService} from '../createteam/createteam.service';

@Component({
  selector: 'app-signupcomplete',
  templateUrl: './signupcomplete.component.html',
  styleUrls: ['./signupcomplete.component.css']
})
export class SignupcompleteComponent implements OnInit {

  constructor(private router:Router,private route:ActivatedRoute,private rService: RegisterService,private tellusService: TellusService,
    private ctService: CreateteamService) { 
    if(sessionStorage.getItem("ContactID") !=null)
     {
      
      
     }
     else
     {
      if(this.route.snapshot.queryParamMap.get('valid') =="true")
      {

      }
      else
      {
        this.router.navigate(["/login"]);
      }
     }
  }
  GotoDashboard()
  {
    var PostRegisterData= JSON.parse(localStorage.getItem("register"));
    var OrgLocalData=JSON.parse(localStorage.getItem("organisation"));
    //var TeamLocalData=JSON.parse(localStorage.getItem("team"));
    var InviteUserLocaldata=JSON.parse(localStorage.getItem("inviteuser"));
    var InviteClientAdminLocalData=JSON.parse(localStorage.getItem("inviteClientAdmin"));
    if(PostRegisterData !=null)
    {
      var registerformData = new FormData();
      registerformData.append('ContactSurname', PostRegisterData.ContactSurname);
      registerformData.append('ContactFirstName', PostRegisterData.ContactFirstName);
      registerformData.append('ContactEMailAddress', PostRegisterData.ContactEMailAddress);
      registerformData.append('UserPassword', PostRegisterData.UserPassword);
      registerformData.append('ContactName', PostRegisterData.ContactFirstName +' '+PostRegisterData.ContactSurname);
      registerformData.append('superadmin', '0');
      registerformData.append('ContactUser_Type', 'Head of Procurement');
      registerformData.append('ContactTitle', 'Mr');

      this.rService.CreateContact(registerformData).subscribe((data:any) =>
      {
          if(data.RetStatus =="Registerted")
          {
            var OrganisationFormData=new FormData();
            OrganisationFormData.append('Organisation_Name', OrgLocalData.organisationFormControl);
            OrganisationFormData.append('No_of_People', OrgLocalData.NoofPeopleFormControl);
            OrganisationFormData.append('ContactID', data.UserID);
            if(OrgLocalData !=null)
            {
              this.tellusService.createOrganisation(OrganisationFormData).subscribe((Orgdata:any) =>
              {
                  if(Orgdata.RetStatus =="Success")
                  {
                    //if(TeamLocalData !=null)
                    //{
                      var TeamFormData=new FormData();
                      //TeamFormData.append("Team_Name",TeamLocalData.teamFormControl);
                      TeamFormData.append("Team_Name","Procurement");
                      TeamFormData.append("OrganisationID",Orgdata.OrganisationID);
  
                      this.ctService.createTeam(TeamFormData).subscribe((datateam:any) =>
                      {
                          if(datateam.RetStatus =="Success")
                          {
                            if(InviteUserLocaldata !=null)
                            {
                              var InviteUserFormData=new FormData();
                              InviteUserFormData.append('ContactSurname', "");
                              InviteUserFormData.append('ContactFirstName', InviteUserLocaldata.InviteeFormControl);
                              InviteUserFormData.append('ContactEMailAddress', InviteUserLocaldata.emailFormControl);
                              InviteUserFormData.append('UserPassword', 'Password');
                              InviteUserFormData.append('ContactName', InviteUserLocaldata.InviteeFormControl);
                              InviteUserFormData.append('superadmin', '0');
                              InviteUserFormData.append('ContactUser_Type', InviteUserLocaldata.RoleFormControl);
                              InviteUserFormData.append('ContactTitle', 'Mr');
                              InviteUserFormData.append('TeamID', datateam.TeamID);
                              InviteUserFormData.append('ContractConfig', InviteUserLocaldata.ContractFormControl);
                              InviteUserFormData.append('AdminPerMission', InviteUserLocaldata.AdminFormControl);
                              InviteUserFormData.append('CreatorID', data.UserID);
                              this.ctService.InviteUser(InviteUserFormData).subscribe((dataInviteUser:any)=>
                              {
                                if(dataInviteUser.RetStatus =="Registerted")
                                {
                                    if(InviteClientAdminLocalData !=null)
                                    {
                                        var InviteClientFomrData=new FormData();
                                        InviteClientFomrData.append('ContactSurname', "");
                                        InviteClientFomrData.append('ContactFirstName', InviteClientAdminLocalData.InviteeFormControl);
                                        InviteClientFomrData.append('ContactEMailAddress', InviteClientAdminLocalData.emailFormControl);
                                        InviteClientFomrData.append('UserPassword', 'Password');
                                        InviteClientFomrData.append('ContactName', InviteClientAdminLocalData.InviteeFormControl);
                                        InviteClientFomrData.append('superadmin', '0');
                                        InviteClientFomrData.append('ContactUser_Type', InviteClientAdminLocalData.RoleFormControl);
                                        InviteClientFomrData.append('ContactTitle', 'Mr');
                                        InviteClientFomrData.append('TeamID', datateam.TeamID);
                                        InviteClientFomrData.append('ContractConfig', InviteClientAdminLocalData.ContractFormControl);
                                        InviteClientFomrData.append('AdminPerMission', InviteClientAdminLocalData.AdminFormControl);
                                        InviteClientFomrData.append('CreatorID', data.UserID);
                                        this.ctService.InviteUser(InviteClientFomrData).subscribe((dataInviteClient:any)=>
                                        {
                                          if(dataInviteClient.RetStatus =="Registerted")
                                          {
                                            this.router.navigate(['/login']);
                                            // sessionStorage.setItem('ContactID',data.UserID);
                                            // sessionStorage.setItem('usertype',data.UserType);
                                            //   this.router.navigate(['/dashboard/performance'], { queryParams: {valid: 'true'}});
                                          }
                                          else
                                          {
                                            alert(dataInviteClient.RetStatus);
                                            
                                          }
                                        });
                                    }
                                    else
                                    {
                                      this.router.navigate(['/login']);
                                      // sessionStorage.setItem('ContactID',data.UserID);
                                      // sessionStorage.setItem('usertype',data.UserType);
                                      // this.router.navigate(['/dashboard/performance'], { queryParams: {valid: 'true'}});
                                    }
                                }
                                else
                                {
                                  alert(dataInviteUser.RetStatus);
                                  
                                }
                              });
                            }
                          }
                          else
                          {
                            alert(datateam.RetStatus);
                            
                          }
                      });
                    //}
                  }
                  else
                  {
                    alert(Orgdata.RetStatus);
                    
                  }
              });
            }
          }
          else
          {
            alert(data.value);
            console.log("123");
          }
      });
    }
    else
    {
      alert("Site is not responding please try after some time.");
      this.router.navigate(['/login']);
    }
    

    
    //console.log(JSON.parse(localStorage.getItem("register")));
    //console.log(JSON.parse(localStorage.getItem("organisation")));
    //console.log(JSON.parse(localStorage.getItem("team")));
    //console.log(JSON.parse(localStorage.getItem("inviteuser")));
    //console.log(JSON.parse(localStorage.getItem("inviteClientAdmin")));
    //this.router.navigate(['/dashboard/performance'], { queryParams: {valid: 'true'}});
  }
  ngOnInit() {
  }

}
