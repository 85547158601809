import { Injectable } from '@angular/core';
import {LoginService} from '../login/login.service';
import {HttpClient, HttpHeaders} from '@angular/common/http'; 
@Injectable({
  providedIn: 'root'
})
export class CreateTeamDepartmentService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  GetDepartments()
  {
 
    return this.http.get(this.lService.ServiceIP + "/Department");
  }
  UpdateTeamDepartment(formData:any)
  {
    
    return this.http.post(this.lService.ServiceIP +"/Team/CreateTeamDepartment",formData);
  }
  TeamExists(TeamName:string,OrgID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/Team/TeamExists(Team_Name='"+TeamName+"',OrgID="+ OrgID+")");
  }
  getCategories()
  {
    return this.http.get(this.lService.ServiceIP + "/Category");
  }
  // TeamExists(TeamName:string)
  // {
  //   return this.http.get(this.lService.ServiceIP +"/Team/TeamExists(Team_Name='"+TeamName+"')");
  // }
}
