import { Component, OnInit } from '@angular/core';
import { InvitesuserService } from '../invitesuser.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-editextensionperiod',
  templateUrl: './editextensionperiod.component.html',
  styleUrls: ['./editextensionperiod.component.scss']
})
export class EditextensionperiodComponent implements OnInit {


  PeriodGroup : FormGroup;
  submitted:boolean=false;
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    this.LoadExtensionPeriod();
   }
   get f(){ return this.PeriodGroup.controls;}
  ExtensionPeriodList:any;
  ngOnInit() {
    this.PeriodGroup = this.fb.group({
      period : ['',Validators.required],
     });
  }
  LoadExtensionPeriod()
  {
    this.InvserObj.GetExtensionPeriod().subscribe((data:any) => 
     {
       this.ExtensionPeriodList=data;
           
     });

  }
  RemoveExtensionPeriod(PStatus:String)
  {
    
    for(let i=0;i<this.ExtensionPeriodList.length;i++)
    {
      if(this.ExtensionPeriodList[i].period == PStatus)
      {
        this.ExtensionPeriodList.splice(i, 1);
        
        break;
      }
    }
  }
 
  AddExtensionPeriod()
  {
    
    this.submitted = true;
    if (this.PeriodGroup.invalid) 
    {
       return ;
    }
    let statusexist=false;
    let PS=this.PeriodGroup.get("period").value;
    if(PS>60)
    {
    alert("Extension period should not more than 60 months");
    }
    else
    {
      for(let p=0;p<this.ExtensionPeriodList.length;p++)
      { 
      
        if(this.ExtensionPeriodList[p].period == PS )
        {
          statusexist=true;
          break;
        }
      }
      
      if(statusexist == false)
      {
        let jsonstring= '{"period" :"'+PS+'","Active" :0}';
        this.ExtensionPeriodList.push(JSON.parse(jsonstring));
        this.PeriodGroup.get("period").setValue(" ");
      }
    }
}
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  
  return true;

}
SaveProjectMilestone()
  {
    
    const formdecdata=new FormData();
  
    formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
    this.InvserObj.DeactiveExtensionPeriod(formdecdata).subscribe((data:any) => 
    {
    
        if(data.RetStatus = "Deleted")
        {
        if(this.ExtensionPeriodList.length>0)
        {
          for(let i=0;i <this.ExtensionPeriodList.length;i++)
            { 
              const formdata=new FormData();
              formdata.append('period', this.ExtensionPeriodList[i].period);
              formdata.append('ContactID',sessionStorage.getItem("ContactID"));
              formdata.append('Active', this.ExtensionPeriodList[i].Active);
              if(i==0)
              {
              formdata.append('Status','true');
              }
              else
              {
                formdata.append('Status','false');
              }
              this.InvserObj.AddExtensionPeriod(formdata).subscribe((data:any) => 
              {
                
                if(i== this.ExtensionPeriodList.length-1)
                {
                  alert("Saved Successfully");
                    this.InvserObj.GetExtensionPeriod().subscribe((data:any) => 
                    {
                      this.ExtensionPeriodList=data;
                    
                    });
                }
              });
            }
        }
        }
    });
  }


}
