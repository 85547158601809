import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import {RegisterService} from'./register.service';
import { Router } from '@angular/router';
//import { ErrorStateMatcher } from '@angular/material/core';

//import { from } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  register: FormGroup;
  isHidden:boolean=true;

  hide:any="password";
  hide1:any="password";
  constructor(private fb: FormBuilder, private rService:RegisterService,private router:Router) { 
    this.hide="password";
    this.hide1="password";
    this.isHidden=true;
    this.Clearsession();
  }

//   emailFormControl = new FormControl('', [
//     Validators.required,
//     Validators.email
// ]);
// firstnameFormControl = new FormControl('', [
//   Validators.required]);

// lastnameFormControl = new FormControl('', [
//   Validators.required
// ]);
// passwordFormControl = new FormControl('', [
//   Validators.required
// ]);
// reenterpasswordFormControl = new FormControl('', [
//   Validators.required
// ]);
// termsFormControl = new FormControl(false, [
//   Validators.requiredTrue
// ]);

// termsFormControl = new FormControl('', [(control) => {    
//   return !control.value ? { 'required': true } : null;
// }]
// );
//termsFormControl:new FormControl('', Validators.requiredTrue);


  ngOnInit() {
    this.register = this.fb.group({
      ContactEMailAddress:['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      ContactFirstName:['',[Validators.required]],
      ContactSurname:['',[Validators.required]],
      termsFormControl: [false, [Validators.requiredTrue]],
      UserPassword:['',[Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}')]]
      //reenterpasswordFormControl:['',[Validators.required]]
    });
    
    //this.register.get('termsFormControl').setValue(false);
  }

  Clearsession()
  {
    sessionStorage.removeItem('ContactID');
    sessionStorage.removeItem('usertype');
    sessionStorage.removeItem('AdminPermission');
    localStorage.removeItem('register');
    localStorage.removeItem('organisation');
    localStorage.removeItem('team');
    localStorage.removeItem('inviteuser');
    localStorage.removeItem('inviteClientAdmin');
  }

  RegisterContact()
  {
    this.isHidden=false;
    if (!this.register.valid) {
      return;
      }
      
      const formdata=new FormData();
      formdata.append('ContactSurname', this.register.get('ContactSurname').value);
      formdata.append('ContactFirstName', this.register.get('ContactFirstName').value);
      formdata.append('ContactEMailAddress', this.register.get('ContactEMailAddress').value);
      formdata.append('UserPassword', this.register.get('UserPassword').value);
      formdata.append('ContactName', this.register.get('ContactSurname').value +' '+this.register.get('ContactFirstName').value);
      formdata.append('superadmin', '0');
      formdata.append('ContactUser_Type', 'Head of Procurement');
      formdata.append('ContactTitle', 'Mr');

      // localStorage.setItem('ContactSurname',this.register.get('lastnameFormControl').value);
      // localStorage.setItem('ContactFirstName',this.register.get('firstnameFormControl').value);
      // localStorage.setItem('ContactEMailAddress',this.register.get('emailFormControl').value);
      // localStorage.setItem('UserPassword',this.register.get('passwordFormControl').value);
      // localStorage.setItem('ContactName',this.register.get('lastnameFormControl').value +' '+this.register.get('firstnameFormControl').value;
      // localStorage.setItem('superadmin','0');
      // localStorage.setItem('ContactUser_Type','Head of Procurement');
      // localStorage.setItem('ContactTitle','Mr');
      //console.log(this.register.value);
      localStorage.setItem("register",JSON.stringify(this.register.value));
      this.rService.CheckEmailExists(this.register.get('ContactEMailAddress').value).subscribe((data:any)=>
      {
          if(data.value =="Exists")
          {
            alert("This user exists in the system. Click ok to login");
            this.router.navigate(['/login']);
          }
          else
          {
            this.router.navigate(["/tellus"], { queryParams: {valid: 'true'}});
          }
      });
      //console.log(JSON.parse(localStorage.getItem("register")));
      // this.rService.CreateContact(formdata).subscribe((data:any) =>
      // {
      //     if(data.RetStatus =="Registerted")
      //     {
      //       //alert("Email is sent to registered address");
      //       this.router.navigate(["/tellus"], { queryParams: {valid: 'true', val: data.UserID}});
      //       localStorage.setItem('ContactID',data.UserID);
      //       localStorage.setItem('UserType',data.UserType);
      //     }
      //     else if(data.RetStatus =="Email-iD already exists. Try with different email-id")
      //     {
      //       //this.register.get('passwordFormControl').reset();
      //       //this.register.get('reenterpasswordFormControl').reset();
      //       alert("This user exists in the system. Click ok to login");
      //       this.router.navigate(['/login']);
      //     }
      //     else
      //     {
      //       this.register.get('passwordFormControl').reset();
      //       this.register.get('reenterpasswordFormControl').reset();
      //       alert(data.value);
      //     }
      // });
  }
}
