// import { Component, OnInit } from '@angular/core';
// import {SystemNotificationsService} from './system-notifications.service';
// @Component({
//   selector: 'app-system-notifications',
//   templateUrl: './system-notifications.component.html',
//   styleUrls: ['./system-notifications.component.scss']
// })
// export class SystemNotificationsComponent implements OnInit {

//   body: HTMLElement;
//   systemNotifications: HTMLElement;
//   SysNotifications:HTMLElement;

//   constructor(public sysnotobj:SystemNotificationsService) { }

//   ngOnInit() {
//     this.sysnotobj.GetSystemNotifications(761).subscribe((data:any)=>
//     {
//     this.SysNotifications=data;
   
//     });
//   }

//   RedirectURL(RedireURL:any,NotifyID:any)
//   {
//     this.sysnotobj.UpdateNoticationStatus(NotifyID).subscribe((data)=>{
//     this.sysnotobj.GetSystemNotifications(761).subscribe((data:any)=>
//     {
//       this.SysNotifications=data;
//     //  window.open(RedireURL,"_self");
//     window.location.replace(RedireURL);
//     });});
    
//   }

//   public show(): void {
//     if (!this.body) this.body = document.querySelector('body');
//     if (!this.systemNotifications) this.systemNotifications = document.querySelector('.system-notifications')

//     this.systemNotifications.classList.add('show');
//     this.body.classList.add('overflow-hidden');
// }

// public hide(): void {
//     if (!this.body) this.body = document.querySelector('body');
//     if (!this.systemNotifications) this.systemNotifications = document.querySelector('.system-notifications')

//     this.systemNotifications.classList.remove('show');
//     this.body.classList.remove('overflow-hidden');
// }
// }
import { Component, OnInit } from '@angular/core';
import { SystemNotificationsService } from "./system-notifications.service";
import { EventEmitterService } from '../settings/event-emitter.service';

@Component({
  selector: 'app-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss']
})
export class SystemNotificationsComponent implements OnInit {

  body: HTMLElement;
  systemNotifications: HTMLElement;
  SysNotifications:any;
  constructor(private sysnotobj:SystemNotificationsService,private eventEmitterService: EventEmitterService) {
   sessionStorage.removeItem("NotificationID");
   }
   RedirectURL(RedireURL:any,NotifyID:any)
   {
    sessionStorage.setItem("NotificationID",NotifyID);
    window.location.replace(RedireURL);
    //  this.sysnotobj.UpdateNoticationStatus(NotifyID).subscribe((data)=>{
    //  this.sysnotobj.GetSystemNotifications(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
    //  {
    //    this.SysNotifications=data;
    //  //  window.open(RedireURL,"_self");
    //  window.location.replace(RedireURL);
    //  });
    // });
     
   } 
   DeleteNotification(RedireURL:any,NotifyID:any)
   {
    this.sysnotobj.UpdateNoticationStatus(NotifyID).subscribe((data)=>{
      this.sysnotobj.GetSystemNotifications(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
      {
        this.SysNotifications=data;
        this.firstComponentFunction();
      });
    });
   }

  ngOnInit() {
    this.sysnotobj.GetSystemNotifications(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
    {
      console.log("sys" + data);
        this.SysNotifications=data;
   
    });
  }

  public show(): void {
    
  
    if (!this.body) this.body = document.querySelector('body');
    if (!this.systemNotifications) 
    this.systemNotifications = document.querySelector('.system-notifications')

    this.systemNotifications.classList.add('show');
    //this.body.classList.add('overflow-hidden');
}

public hide(): void {
 
    if (!this.body) this.body = document.querySelector('body');
   
    if (!this.systemNotifications) this.systemNotifications = document.querySelector('.system-notifications')
   
    this.systemNotifications.classList.remove('show');
    //this.body.classList.remove('overflow-hidden');
}

firstComponentFunction(){
  this.eventEmitterService.onFirstComponentButtonClick();
}
}
