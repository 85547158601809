import { Component, OnInit } from '@angular/core';
import {SettingsService} from '../../settings/settings.service';

@Component({
  selector: 'app-project-advance-search',
  templateUrl: './project-advance-search.component.html',
  styleUrls: ['./project-advance-search.component.scss']
})
export class ProjectAdvanceSearchComponent implements OnInit {
  Names:any;
  
  constructor(private setService: SettingsService) { this.LoadAllCaptions();}

  ngOnInit() {
  }
  
  LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("project-advance-search").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
  
}
