import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-dialog-reason-for-change',
  templateUrl: './dialog-reason-for-change.component.html',
  styleUrls: ['./dialog-reason-for-change.component.scss']
})
export class DialogReasonForChangeComponent implements OnInit {
  error:string="";
  
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DialogReasonForChangeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
  AddReasonForChange(rText:string)
  {
    if(rText =="")
    {
      this.error="Reason for Change is required";
    }
    else
    {
      this.dialogRef.close(rText);
    }
  }
}
