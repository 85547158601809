import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
//import { Action } from 'rxjs/internal/scheduler/Action';
import {DialogReprocureContractService} from './dialog-reprocure-contract.service';
import {DialogTeamGanttComponent} from '../dialog-team-gantt/dialog-team-gantt.component';


export interface UserProjectAllocation {
    firstName: string;
    lastName: string;
    allocated: boolean;
}

export interface AllowcatedUsers {
  ContactID: number;
  ContractID: number;
  AllowcationStatus: number;
  FirstName:string;
  LastName:string;
  Action:number;
}

export interface AllowcatedUsers {
  ContactID: number;
  ContractID: number;
  AllowcationStatus: number;
  
}

const USER_PROJECT_ALLOCATION_DATA: UserProjectAllocation[] = [
  {
      firstName: 'John',
      lastName: 'Morrison',
      allocated: false
  }, 
  {
      firstName: 'Kim',
      lastName: 'Washington',
      allocated: true
  },
  {
      firstName: 'Sarah',
      lastName: 'Johnson',
      allocated: false
  },
  {
      firstName: 'Fred',
      lastName: 'Tate',
      allocated: true
  },
  {
      firstName: 'Ryan',
      lastName: 'Wilson',
      allocated: true
  }
];

@Component({
  selector: 'app-dialog-reprocure-contract',
  templateUrl: './dialog-reprocure-contract.component.html',
  styleUrls: ['./dialog-reprocure-contract.component.scss']
})
export class DialogReprocureContractComponent implements OnInit {
  ContrcatTitle:string;
  Heading:string;
  SubmitButton:string;
  userProjectAllocationDisplayedColumns = ['Select','FirstName', 'LastName', 'Action'];
  //userProjectAllocationDataSource = new MatTableDataSource(USER_PROJECT_ALLOCATION_DATA);
  userProjectAllocationDataSource:any;
  AllowcateList:AllowcatedUsers[]=[];
  constructor(public dialog: MatDialog, private DbService:ProjectDatabaseService,public dialogRef: MatDialogRef<DialogReprocureContractComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private router:Router) { 
    sessionStorage.removeItem("AllowcationList");
    this.ContrcatTitle=this.data.ContractTitle;
    if(this.data.Page =="Extend")
    {
      this.Heading="Assign Project Owner";
      this.SubmitButton="Confirm Assignment";
    }
    else
    {
      this.Heading="Re-procure Contract";
      this.SubmitButton="Confirm Assignment";
    }
    
    this.getContactUser(this.data.OrgName,this.data.ContractID);
    //console.log(this.data.ContractID);
  }

  ngOnInit() {
  }

  getContactUser(OrgName:string, ContractID:any)
  {
   
    //this.DbService.getContactUserByOrganisationName(OrgName,ContractID).subscribe((data:any)=>
    this.DbService.getContactUserByOrganisationwithoutLoggedinUser(OrgName,ContractID,sessionStorage.getItem("ContactID")).subscribe((data:any)=>
    {
      //this.userProjectAllocationDataSource = new MatTableDataSource(data);

      if(data.length >0)
      {
        for(let i=0; i<data.length;i++)
        {
          this.AllowcateList.push({
            ContactID:data[i].ContactID,
            ContractID:this.data.ContractID,
            AllowcationStatus:data[i].Action,
            Action:data[i].Action,
            FirstName:data[i].FirstName,
            LastName:data[i].LastName
          });
          
          // if(data[i].ContactID == sessionStorage.getItem("ContactID"))
          // {
          //   this.AllowcateList.push({
          //     ContactID:data[i].ContactID,
          //     ContractID:this.data.ContractID,
          //     AllowcationStatus:1,
          //     Action:1,
          //     FirstName:data[i].FirstName,
          //     LastName:data[i].LastName
          //   });
          // }
          // else
          // {
          //   this.AllowcateList.push({
          //     ContactID:data[i].ContactID,
          //     ContractID:this.data.ContractID,
          //     AllowcationStatus:data[i].Action,
          //     Action:data[i].Action,
          //     FirstName:data[i].FirstName,
          //     LastName:data[i].LastName
          //   });
          // }
         
        }
        this.userProjectAllocationDataSource = new MatTableDataSource(this.AllowcateList);
        //console.log(this.AllowcateList);
      }
    });
  }
  onSelect(ContactID:any,FirstName:string,LastName:string)
  {
    for(let i=0; i<this.AllowcateList.length;i++)
    {
      if(this.AllowcateList[i].ContactID !=ContactID)
      {
        let index=this.AllowcateList.findIndex(x=>x.ContactID == this.AllowcateList[i].ContactID);
        this.AllowcateList[index].Action =0;
        this.AllowcateList[index].AllowcationStatus=0;
      }
    }
    //console.log(this.data.ContractID +"_" +ContactID);
    var Exists=this.AllowcateList.find(x=>x.ContactID == ContactID);
    if(Exists !=null)
    {
      //this.AllowcateList.splice(this.AllowcateList.findIndex(x=>x.ContactID == ContactID),1);
      let index=this.AllowcateList.findIndex(x=>x.ContactID == ContactID);
      //this.AllowcateList[index];
      //alert(this.AllowcateList[index].Action);
      if(this.AllowcateList[index].Action == 0)
      {
        this.AllowcateList[index].Action =1;
        this.AllowcateList[index].AllowcationStatus=1;
      }
      else
      {
        this.AllowcateList[index].Action =0;
        this.AllowcateList[index].AllowcationStatus=0;
      } 
      //console.log(this.AllowcateList);
    }
    else
    {
      this.AllowcateList.push({
        ContactID:ContactID,
        ContractID:this.data.ContractID,
        AllowcationStatus:1,
        Action:1,
        FirstName:FirstName,
        LastName:LastName
      });
      
    }
    //console.log(this.AllowcateList);
    this.userProjectAllocationDataSource = new MatTableDataSource(this.AllowcateList);
    //console.log(this.AllowcateList);
  }

  openViewGanttDailog()
  {
    //this.dialogRef.close();
    const dRef = this.dialog.open(DialogTeamGanttComponent, { 
      width: '1366px'
    });
    dRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result == true)
      {
      }
    });
    
  }

  ConfirmAllowcation()
  {
    let Allowcated=0;
    let AllocatedIndex:number=0;
    if(this.AllowcateList.length >0)
    {
      for(let i=0; i<this.AllowcateList.length;i++)
      {
        if(this.AllowcateList[i].AllowcationStatus ==1)
        {
          Allowcated=1;
          AllocatedIndex=i;
          break;
        }
      }
     
        if(Allowcated==1)
        {
          if(this.data.Page =="Extend")
          {
            if(Number(sessionStorage.getItem("ContactID")) == this.AllowcateList[AllocatedIndex].ContactID)
            {
             
              this.dialogRef.close();
            }
            else
            {
              //send mail to PO
              this.DbService.ExtendContractRequestSendMailtoPO(sessionStorage.getItem("ContactID"),this.AllowcateList[AllocatedIndex].ContactID,this.data.ContractID).subscribe((data:any) =>
              {
                if(data.RetStatus =="Success")
                {
                  alert("Your request to extend the contract has been sent");
                }
                else
                {
                  alert(data.RetStatus);
                }
              });
              this.dialog.closeAll();
			  this.dialogRef.close(true);
              
            }
            
          }
          else
          {
            
            if(Number(sessionStorage.getItem("ContactID")) == this.AllowcateList[AllocatedIndex].ContactID)
            {
              
              sessionStorage.setItem("AllowcationList",JSON.stringify(this.AllowcateList));
              this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: this.data.ContractID, ContractType:"Reprocure"}});
              this.dialog.closeAll();
            }
            else
            {
              
              //sendMail to PO
              this.DbService.ContractReProcureMailSendtoPO(sessionStorage.getItem("ContactID"),this.AllowcateList[AllocatedIndex].ContactID,this.data.ContractID).subscribe((data:any) =>
              {
                if(data.RetStatus =="Success")
                {
                  alert("Your contract re-procure request has been sent.");
				  
                  this.dialog.closeAll();
				  this.dialogRef.close(true);
                }
                else
                {
                  alert(data.RetStatus);
                }
              });
            }
          }
          
        }
        else
        {
          alert("Assign atleast one user to Project");
        }
     
      }
    else
    {
      alert("Assign atleast one user to Project");
    }
  }
  
}

