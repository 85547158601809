import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../login/login.service';
import {CreateteamService} from '../createteam/createteam.service';

@Component({
  selector: 'app-createclient-admin',
  templateUrl: './createclient-admin.component.html',
  styleUrls: ['./createclient-admin.component.css']
})
export class CreateclientAdminComponent implements OnInit {
  inviteUsers:FormGroup;
  Roles:any;
  //CreatorID:any;
  constructor(private fb:FormBuilder,private router:Router,private lService:LoginService, private route: ActivatedRoute,private ctService:CreateteamService) { 
    if(sessionStorage.getItem("ContactID") !=null)
     {
       //this.CreatorID =sessionStorage.getItem("ContactID");
        if(this.route.snapshot.queryParamMap.get('Val') !=null)
        {
          this.getInviteeRoles();
        }
        else
        {
          this.router.navigate(["/createteam"]);
        }
     }
     else
     {
     
      if(this.route.snapshot.queryParamMap.get('CAdmin') =="true")
      {
        //this.CreatorID =localStorage.getItem("ContactID");

        this.getInviteeRoles();
      }
      else
      {
        this.router.navigate(["/login"]);
      }
        
     }
  }

  ngOnInit() {

    this.inviteUsers = this.fb.group({
      emailFormControl:['',[Validators.required,Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      RoleFormControl:['', [Validators.required]],
      InviteeFormControl:['',[Validators.required]],
      ContractFormControl:[''],
      AdminFormControl:['']
    });

    this.inviteUsers.get("ContractFormControl").setValue("Yes");
    this.inviteUsers.get("AdminFormControl").setValue("Yes");
  }

  getInviteeRoles()
  {
    this.lService.GetUserTypesforInviteUser().subscribe((data:any) =>
    {
        this.Roles=data;
    })
  }
  TeamUserInvite()
  {
    if(!this.inviteUsers.valid)
    {
      return;
    }

    const formdata=new FormData();
    formdata.append('ContactSurname', "");
    formdata.append('ContactFirstName', this.inviteUsers.get('InviteeFormControl').value);
    formdata.append('ContactEMailAddress', this.inviteUsers.get('emailFormControl').value);
    formdata.append('UserPassword', '100100');
    formdata.append('ContactName', this.inviteUsers.get('InviteeFormControl').value);
    formdata.append('superadmin', '0');
    formdata.append('ContactUser_Type', this.inviteUsers.get('RoleFormControl').value);
    formdata.append('ContactTitle', 'Mr');
    formdata.append('TeamID', this.route.snapshot.queryParamMap.get('Val'));
    formdata.append('ContractConfig', this.inviteUsers.get('ContractFormControl').value);
    formdata.append('AdminPerMission', this.inviteUsers.get('AdminFormControl').value);
    //formdata.append('CreatorID',this.CreatorID);
    localStorage.setItem("inviteClientAdmin",JSON.stringify(this.inviteUsers.value));

    this.lService.EmailExists(this.inviteUsers.get('emailFormControl').value).subscribe((data:any)=>
    {
      if(data.value =="Exists")
      {
        alert("This user exists in the system. Please use another email");
        this.inviteUsers.get('emailFormControl').reset();
      }
      else
      {
        this.router.navigate(['/signupcomplete'], { queryParams: {valid: 'true'}});
      }
    });

    // this.ctService.InviteUser(formdata).subscribe((data:any)=>
    // {
    //   if(data.RetStatus =="Registerted")
    //   {
    //       //alert("Invitation mail sent to given Email-ID");
    //       //this.router.navigate(["/signupcomplete?valid=true"]);
    //       this.router.navigate(['/signupcomplete'], { queryParams: {valid: 'true'}});
          
    //   }
    //   else if(data.RetStatus =="Email-iD already exists. Try with different email-id")
    //   {
    //     this.inviteUsers.get('emailFormControl').reset();
    //       alert("This user exists in the system. Please use another email ID");
    //   }
    //   else
    //   {
    //     this.inviteUsers.get('emailFormControl').reset();
    //     alert(data.RetStatus);
    //   }
    // });
  }


}
