import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardContractDetailsService } from './dashboard-contract-details.service';
import { saveAs } from 'file-saver';
import { DatePipe } from "@angular/common"
import { MatDialog } from '@angular/material/dialog';
import { DialogAddNewSupplierComponent } from '../../dialogs/dialog-add-new-supplier/dialog-add-new-supplier.component'
import { ProjectDatabaseService } from '../project-database.service';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DialogAddNewContractfinanceComponent } from 'src/app/dialogs/dialog-add-new-contractfinance/dialog-add-new-contractfinance.component';
import { AlertComponent } from '../../alert/alert.component';
import { async } from '@angular/core/testing';
import { DialogReasonforProjectapproverejectComponent } from '../../dialogs/dialog-reasonfor-projectapprovereject/dialog-reasonfor-projectapprovereject.component';
import { DashboradContractsService } from '../dashboard-contracts/dashborad-contracts.service';
import { SystemNotificationsService } from '../../system-notifications/system-notifications.service';
import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-dashboard-contract-details',
  templateUrl: './dashboard-contract-details.component.html',
  styleUrls: ['./dashboard-contract-details.component.scss']
})
export class DashboardContractDetailsComponent implements OnInit {

  ContractTitle: string = "";
  newcontract: FormGroup;
  UploadContract: FormGroup;
  UploadSupportingDoc: FormGroup;
  SupplierDetails: FormGroup;
  ContractSocialValue: FormGroup;
  Organisation: any;
  ExtentionPeriod: any;
  Category: any;
  SubCategory: any;
  public ContractType = [];
  Status: any;
  submitted = false;
  Contractfile: any;
  SupportDocfile: any;
  Suppliers: any;
  actualSpend: any;
  actualSavings: any;
  projectedSpend: any;
  projectedSavings: any;
  Spendtarget: any;
  Savingstarget: any;
  supplierselect: any;
  spendP: any;
  spendAP: any;
  savingsAP: any;
  savingsP: any;
  showF2: boolean = false;
  showF1: boolean = true;
  showSave: boolean = false;
  public selectedtabIndex: number;
  tabchange: boolean = false;
  isDisabledContent: boolean = true;
  keyword = 'Abbreviation';
  SubCategorySelected: any = "";
  InitialSubCategory: any;
  subcat: any;
  IntSub: any;

  DAtes: any;
  contractDocumentsDataSource: any;
  supportingDocumentsDataSource: any;
  suppliersDocumentsDataSouce: any;
  yearlyFinancialsDataSource: any;
  yearlyFinancialsDisplayedColumns = ['financialYear', 'projectedSpend', 'actualSpend', 'projectedSavings', 'actualSavings'];
  contractDocumentsDisplayedColumns = ['DocumentName', 'CreatedDate', 'PK_DocumentID'];
  supportingDocumentsDisplayedColumns = ['DocumentName', 'CreatedDate', 'PK_DocumentID'];
  extensionHistoryDisplayedColumns = ['ExtendedDate', 'ExtendedPeiod', 'RemainingExtension'];
  extensionHistoryDataSource: any;
  suppliersDocumentsDisplayColumns = ['DocumentName', 'CreatedDate', 'ExpiryDate', 'PK_DocumentID'];
  StatusPermission: boolean = false;
  Names: any;
  bLLAEnabled: boolean = false;
  bUseSimpleSubCategory: boolean = false;
  constructor(private change: ChangeDetectorRef, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private Dservice: DashboardContractDetailsService, public dialog: MatDialog, private DbService: ProjectDatabaseService, private Cservice: DashboradContractsService,
    private SysNotifications: SystemNotificationsService, private setService: SettingsService) {
    this.LoadAllCaptions();
    this.isLLAEnabled();
    this.UseSimpleSubCategory();
    if (sessionStorage.getItem("ContactID") != null) {

      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        this.getOrganisation();

      }
      else {
        this.getOrganisationForNonHop();
      }

      if (this.route.snapshot.queryParams['ContractID'] != null) {

        this.getCategories();
        this.getContractTypes();

        this.Cservice.GetSubCategoryForContract(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
          this.newcontract.get("SubCategory").setValue(data.value);
        });


        this.getContractStatus();
        this.getContractDocuments();
        this.getSupportingDocuments();
        this.getContractSuppliers();
        this.getContractFinancials();
        this.getContractActualSpend();
        this.getContractActualSavings();
        this.getContractProjectedSpend();
        this.getContractProjectedSavings();
        this.getExtendData(this.route.snapshot.queryParams['ContractID']);
        //
        this.Dservice.getAnnualContractProjectedSpend(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
          console.log(data);
          this.spendAP = data.value;
        });

        //
        this.Dservice.getAnnualContractProjectedSavings(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
          this.savingsAP = data.value;

        });
        //calculate spend target
        this.Dservice.getSpendPercentage(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
          this.Spendtarget = data.value;

        });
        //calculate spend target
        this.Dservice.getSavingsPercentage(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
          this.Savingstarget = data.value;
        });
        this.getContractDetails(this.route.snapshot.queryParams['ContractID']);
        if (this.route.snapshot.queryParams['ViewExtension'] != null) {
          this.selectedtabIndex = 3;
        }
        if (this.route.snapshot.queryParams['For'] != null && this.route.snapshot.queryParams['POID'] != null) {

          this.StatusPermission = true;
        }
        else if (this.route.snapshot.queryParams['Reprocure'] != null) {

          this.StatusPermission = true;
        }
        else if (this.route.snapshot.queryParams['Extend'] != null) {

          this.StatusPermission = true;
        }
        else if (this.route.snapshot.queryParams['ViewDocument'] != null) {
          this.selectedtabIndex = 2;
        }
        else if (this.route.snapshot.queryParams['AddSupplier'] != null) {
          this.selectedtabIndex = 4;
        }
        else if (this.route.snapshot.queryParams['CanExtend'] != null && this.route.snapshot.queryParams['CanReprocure'] != null) {

        }
        else if (this.route.snapshot.queryParams['ExtendContract'] != null) {
        }

        sessionStorage.removeItem("ApproveProject");
      }
      else {
        if (this.route.snapshot.queryParams['For'] != null && this.route.snapshot.queryParams['POID'] != null) {
          this.router.navigate(['/login']);
          sessionStorage.setItem("ApproveProject", this.router.url);
        }
        else if (this.route.snapshot.queryParams['ExtendContract'] != null) {
          this.router.navigate(['/login']);
          sessionStorage.setItem("ApproveProject", this.router.url);
        }
        else if (this.route.snapshot.queryParams['Reprocure'] != null) {

          this.router.navigate(['/login']);
          sessionStorage.setItem("ApproveProject", this.router.url);
        }
        else if (this.route.snapshot.queryParams['Extend'] != null) {
          this.router.navigate(['/login']);
          sessionStorage.setItem("ApproveProject", this.router.url);
        }
        else if (this.route.snapshot.queryParams['CanExtend'] != null && this.route.snapshot.queryParams['CanReprocure'] != null) {
          this.router.navigate(['/login']);
          sessionStorage.setItem("ApproveProject", this.router.url);
        }
        else {
          this.router.navigate(["/login"]);
        }

      }

    }
    else {
      if (this.route.snapshot.queryParams['ViewContract'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['For'] != null && this.route.snapshot.queryParams['POID'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['ExtendContract'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['Reprocure'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['Extend'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['CanExtend'] != null && this.route.snapshot.queryParams['CanReprocure'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else {
        this.router.navigate(["/login"]);
      }

    }


  }

  ngOnInit() {

    this.newcontract = this.fb.group({
      updateOn: 'blur',
      ddlOrganistaion: [''],
      txtContractTitle: ['', Validators.required],
      txtContractReference: ['', Validators.required],
      txtDepartment: [''],
      StartDate: [''],
      EndDate: [''],
      ddlCategory: [''],
      SubCategory: [''],
      ddlSubCategory: [''],
      ddlType: [''],
      ddlStatus: [''],
      OwnerFirstName: [''],
      OwnerLastName: [''],
      OwnerEmail: ['', [Validators.email]],
      ProjectedLifetimeContractSpend: ['0'],
      ProjectedAnnualisedContractSpend: ['0'],
      ProjectedLifetimeContractSavings: ['0'],
      ProjectedAnnualisedContractSavings: ['0'],
      NoticePeriod: [''],
      ddlKPI: [''],
      Description: [''],
      ddlOneOfContract: [''],
      ddlExtensionAvailable: [''],
      ddlExtensionPeriod: [''],
      ddlPriority: [''],
      ddlGDPR: [''],
      ddlLLA: ['']

    });

    this.UploadContract = this.fb.group({
      FileUpload1: [''],

    });

    this.UploadSupportingDoc = this.fb.group({
      FileUploadSDoc: ['']
    });

    this.SupplierDetails = this.fb.group({
      ddlSupplierName: [''],
      RegistrationNumber: ['']
    });

    this.ContractSocialValue = this.fb.group({
      SocialValueDesc: ['', Validators.required]
    });

    this.newcontract.get("ddlType").setValue("0");
    this.newcontract.get("ddlType").setValue("0");
    this.newcontract.get("ddlStatus").setValue("0");
    this.newcontract.get("ddlOneOfContract").setValue("0");
    this.newcontract.get("ddlExtensionAvailable").setValue("0");
    this.newcontract.get("ddlKPI").setValue("0");
    this.newcontract.get("ddlPriority").setValue("0");
    this.newcontract.get("ddlGDPR").setValue("0");
    this.newcontract.get("ddlLLA").setValue("no");

  }


  get f() { return this.newcontract.controls; };
  get C() { return this.UploadContract.controls; };
  get S() { return this.UploadSupportingDoc.controls; };
  get SD() { return this.SupplierDetails.controls; };
  get sv() { return this.ContractSocialValue.controls; };

  selectEvent(item) {

    if (item.PK_CategoryTypeID != undefined) {
      this.SubCategorySelected = item.PK_CategoryTypeID
      this.tabchange = true;
      this.isDisabledContent = false;
      this.SubCategory = null;
    }
  }

  onChangeSearch(val: string) {
    if (val.length > 1) {
      this.getSubCategories(val);
    }

  }

  onFocused(e) {
    // do something when input is focused
  }

  ClearSubCategorySelection(e) {
    this.tabchange = true;
    this.isDisabledContent = false;
    this.SubCategorySelected = "";
  }


  onselected(event) {
    //this.selectedIndex =2;
    if (this.tabchange) {
      // alert("hi");
      this.isDisabledContent = false;
      //alert(event.index);
      if (event.index != 0) {
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
        this.SaveNewContract();
        //this.SaveContract();
      }
      else {
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
      }

    }

  }

  checktabchanges() {
    if (this.tabchange) {

     this.SaveContract();
    }
  }

  SaveContract() {

    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "SaveContract" },
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        //alert("yes");
        this.SaveNewContract();
      }
      else {
        this.Cancel();
      }

    });


  }

  onChanges(): void {
    this.newcontract.get("ddlOrganistaion").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("txtContractTitle").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("StartDate").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("EndDate").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlCategory").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("txtContractReference").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("Description").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("txtDepartment").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlExtensionAvailable").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlExtensionPeriod").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlPriority").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlGDPR").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlLLA").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlType").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlStatus").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("OwnerFirstName").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("OwnerLastName").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("OwnerEmail").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
  }

  onFileChange(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.Contractfile = file;
      this.SaveContractDocument();
    }
  }

  onFileChangeS(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.SupportDocfile = file;
      this.SaveSupportingDocument();
    }
  }

  getContractSuppliers() {
    let id = this.route.snapshot.queryParams['ContractID'];
    this.Dservice.getContractSuppliers(id).subscribe((data: any) => {

      this.Suppliers = data;

      this.SupplierDetails.get("ddlSupplierName").setValue("0");
      if (data.length > 0) {
        this.SupplierDetails.get("ddlSupplierName").setValue(data[0].FK_SupplierID);
        this.getSupplierDocuments();

        if (this.SupplierDetails.get("ddlSupplierName").value != "0") {
          this.Dservice.getSupplierDetails(this.SupplierDetails.get("ddlSupplierName").value).subscribe((data: any) => {

            this.SupplierDetails.get("RegistrationNumber").setValue(data.CompanyRegistrationNo);
          });
        }
      }


    });

  }

  getContractFinancials() {
    let id = this.route.snapshot.queryParams['ContractID'];
    this.Dservice.getContractFinancials(id).subscribe((data: any) => {
      console.log(data);
      this.yearlyFinancialsDataSource = new MatTableDataSource(data);
    });

    this.Dservice.getFinancialSettings().subscribe((data: any) => {
      if (data.value == "Yes") {
        this.showF1 = true;
        this.showF2 = false;
      } else {
        this.showF1 = false;
        this.showF2 = true;
      }
    });

  }

  getContractActualSpend() {
    let id = this.route.snapshot.queryParams['ContractID'];
    this.Dservice.getContractActualSpend(id).subscribe((data: any) => {
      console.log(data);
      this.actualSpend = data.value;
    });



  }

  getContractProjectedSpend() {
    let id = this.route.snapshot.queryParams['ContractID'];
    this.Dservice.getContractProjectedSpend(id).subscribe((data: any) => {
      console.log(data);
      this.projectedSpend = data.value;
      this.Dservice.globalprojectspend = data.value;
      this.spendP = data.value;
    });

  }

  getContractActualSavings() {
    let id = this.route.snapshot.queryParams['ContractID'];
    this.Dservice.getContractActualSavings(id).subscribe((data: any) => {
      console.log(data);
      this.actualSavings = data.value;
    });
  }

  getContractProjectedSavings() {
    let id = this.route.snapshot.queryParams['ContractID'];
    this.Dservice.getContractProjectedSavings(id).subscribe((data: any) => {
      console.log(data);
      this.projectedSavings = data.value;
      this.savingsP = data.value;
    });
  }


  getOrganisation() {
    this.DbService.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      console.log(data);
      this.Organisation = data.value;
      this.newcontract.get("ddlOrganistaion").setValue(data.value[0].OrganisationID);

    });
  }

  getOrganisationForNonHop() {
    this.Dservice.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data;

      this.newcontract.get("ddlOrganistaion").setValue(data[0].OrganisationID);




    });
    // if(this.route.snapshot.queryParams['ContractID'] !=null)
    // {

    //   let contractId = this.route.snapshot.queryParams['ContractID'];

    //   this.Dservice.getOrganisationForContract(contractId).subscribe((data:any) =>
    //   {
    //     this.Organisation=data;

    //     this.newcontract.get("ddlOrganistaion").setValue(data[0].OrganisationID);
    //   });

    // }
    // else
    // {

    // this.Dservice.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    //   {
    //             this.Organisation=data;

    //             this.newcontract.get("ddlOrganistaion").setValue(data[0].OrganisationID);




    //   });
    // }
  }

  getExtention() {
    this.Dservice.getExtentionPeriod().subscribe((data: any) => {
      this.ExtentionPeriod = data.value;
    });

  }

  getCategories() {
    this.Dservice.getAllCategories().subscribe((data: any) => {
      this.Category = data.value;
      if (this.bUseSimpleSubCategory) {
        this.SubCategorySelected = data.value[0].PK_CategoryGroupID;
        this.onCategorySelected(data.value[0].PK_CategoryGroupID);
      }
    });

  }

  getSubCategories(searchtext: any) {
    this.Dservice.getAllSubCategories_Search(searchtext).subscribe((data: any) => {
      this.SubCategory = data;
    });


  }

  getContractTypes() {
    this.Dservice.getContractTypes().subscribe((data: any) => {
      console.log(data);
      let length = data.value.length;

      for (let i = 0; i < length; i++) {
        let jsonstring = '{"PK_ContractTypeID" :"' + data.value[i].PK_ContractTypeID + '","ContractTypeDescription" :"' + data.value[i].ContractTypeDescription + '"}';
        this.ContractType.push(JSON.parse(jsonstring));
      }
    });
  }
  UpdateContractSocialValue() {

    this.submitted = true;
    if (this.ContractSocialValue.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append("SocialValueDesc", this.ContractSocialValue.get("SocialValueDesc").value);
    formData.append('PK_ContractID', this.route.snapshot.queryParams['ContractID']);
    this.Dservice.UpdateContractSocialValue(formData).subscribe((data: any) => {
      if (data.RetStatus == 'Updated') {
        alert("Contract social value has been updated successfully.");
      } else {
        alert(data.RetStatus);
      }
    });

  }



  getContractStatus() {
    this.Dservice.getContractStatus().subscribe((data: any) => {
      console.log(data);
      this.Status = data.value;

    });
  }

  getContractDetails(ContractID: any) {

    this.Dservice.getContractDetails(ContractID).subscribe((data: any) => {
      //console.log(data);
      this.ContractTitle = data.ContractTitle;
      this.newcontract.get("ddlExtensionAvailable").setValue(data.AutoExtend == 1 ? "Yes" : "No");

      this.newcontract.get("ddlOrganistaion").setValue(data.FK_OrganistaionID);
      this.newcontract.get("ddlType").setValue(data.FK_ContractTypeID);
      this.newcontract.get("ddlStatus").setValue(data.FK_ContractStatusID);
      this.newcontract.get("ddlExtensionPeriod").setValue(data.ExtentionPeriod);
      this.newcontract.get("ddlKPI").setValue(data.IsKPI == 1 ? "Yes" : "No");
      this.newcontract.get("ddlOneOfContract").setValue(data.IsOneOfContract == 1 ? "Yes" : "No");
      this.newcontract.get("txtContractTitle").setValue(data.ContractTitle);
      this.newcontract.get("OwnerFirstName").setValue(data.OwnerFirst_Name);
      this.newcontract.get("OwnerLastName").setValue(data.OwnerLast_Name);
      this.newcontract.get("OwnerEmail").setValue(data.Owner_EmailID);
      this.newcontract.get("Description").setValue(data.ContractDescription);
      this.newcontract.get("NoticePeriod").setValue(data.NoticePeriod);
      this.newcontract.get("ddlCategory").setValue(data.CategoryID);
      this.newcontract.get("StartDate").setValue(data.ContractStartDate);
      this.newcontract.get("EndDate").setValue(data.ContractEndDate);
      this.newcontract.get("ddlPriority").setValue(data.Priority);
      this.newcontract.get("ddlGDPR").setValue(data.GDPR);
      this.newcontract.get("ddlLLA").setValue(data.LLA);
      this.ContractSocialValue.get("SocialValueDesc").setValue(data.SocialValueDescription);

      this.newcontract.get("txtContractReference").setValue(data.InternalReference);
      this.newcontract.get("txtDepartment").setValue(data.Department);

      if (this.newcontract.get("txtContractReference").value == null || this.newcontract.get("txtContractReference").value == "") {
        this.newcontract.get("txtContractReference").setValue(data.PK_ContractID);

      }
      if (this.bUseSimpleSubCategory) {
        this.onCategorySelected(data.CategoryID);
        this.newcontract.get("SubCategory").setValue(data.FK_CategoryTypeID);
      }
      this.SubCategorySelected = data.FK_CategoryTypeID;
      if (this.newcontract.get("ddlExtensionAvailable").value == "No") {

        this.newcontract.get("ddlExtensionPeriod").disable();
        this.newcontract.get("ddlExtensionPeriod").setValue("0");
      }
      this.onChanges();
      this.tabchange = false;
      this.showSave = false;

    });
  }




  getSelectedCategory(SubcategoryID: any) {
    this.Dservice.getCategoryFromSubCategory(SubcategoryID).subscribe((data: any) => {
      this.newcontract.get("ddlCategory").setValue(data.FK_CategoryGroupID);
    });
  }

  //
  getContractDocuments() {
    this.Dservice.getContractDocuments(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
      this.contractDocumentsDataSource = new MatTableDataSource(data.value);
    });
  }

  getSupplierDocuments() {
    this.Dservice.getContractSupplierDocuments(this.SupplierDetails.get("ddlSupplierName").value, this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {

      if (data.value.length > 0) {
        if (data.value[0].DocumentName != "") {
          let st = data.value;


          this.suppliersDocumentsDataSouce = new MatTableDataSource(st);
          st.push({ DocumentName: "", CreatedDate: '', ExpiryDate: '', Actions: '' });
        }
        else {
          this.suppliersDocumentsDataSouce = new MatTableDataSource(data.value);
        }
      }
      if (this.SupplierDetails.get("ddlSupplierName").value != "0") {
        this.Dservice.getSupplierDetails(this.SupplierDetails.get("ddlSupplierName").value).subscribe((data1: any) => {


          this.SupplierDetails.get("RegistrationNumber").setValue(data1.CompanyRegistrationNo);

        });
      }
      else {
        this.SupplierDetails.get("RegistrationNumber").setValue("");
      }

    });

  }

  openDialogAddNewTask() {
    const dialogRef = this.dialog.open(DialogAddNewSupplierComponent, {
      data: { ContractID: this.route.snapshot.queryParams['ContractID'] },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.SupplierDetails.reset();
      let id = this.route.snapshot.queryParams['ContractID'];
      this.Dservice.getContractSuppliers(id).subscribe((data: any) => {
        this.Suppliers = data;
        if (data.length > 0) {
          this.SupplierDetails.get("ddlSupplierName").setValue(data[0].FK_SupplierID);
          this.getSupplierDocuments();
        }
      });
    });
  }

  openDialogUploadDocument() {
    const dialogRef = this.dialog.open(DialogAddNewSupplierComponent, {
      data: { ContractID: this.route.snapshot.queryParams['ContractID'], SupplierID: this.SupplierDetails.get("ddlSupplierName").value },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.SupplierDetails.reset();
      let id = this.route.snapshot.queryParams['ContractID'];
      this.Dservice.getContractSuppliers(id).subscribe((data: any) => {

        this.Suppliers = data;
        if (data.length > 0) {
          this.SupplierDetails.get("ddlSupplierName").setValue(data[0].FK_SupplierID);
          this.getSupplierDocuments();
        }


      });

    });
  }



  openDialogAddNewfinance() {
    const dialogRef = this.dialog.open(DialogAddNewContractfinanceComponent, {
      data: { ContractID: this.route.snapshot.queryParams['ContractID'] },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getContractFinancials();
      this.getContractActualSpend();
      this.getContractActualSavings();
      this.getContractProjectedSpend();
      this.getContractProjectedSavings();
      this.Dservice.getSpendPercentage(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {


        this.Spendtarget = data.value;

      });
      this.Dservice.getSavingsPercentage(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {


        this.Savingstarget = data.value;

      });

    });
  }

  getSupportingDocuments() {
    this.Dservice.getSupportingDocuments(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
      this.supportingDocumentsDataSource = new MatTableDataSource(data.value);
    });

  }

  SelectedChange() {
    if (this.newcontract.get("ddlExtensionAvailable").value == "Yes") {
      this.newcontract.get("ddlExtensionPeriod").enable();
      this.newcontract.get("ddlExtensionPeriod").setValue("");
    }
    else if (this.newcontract.get("ddlExtensionAvailable").value == "No") {

      this.newcontract.get("ddlExtensionPeriod").disable();
      this.newcontract.get("ddlExtensionPeriod").setValue("0");
    }
    else {
      this.newcontract.get("ddlExtensionPeriod").enable();
      this.newcontract.get("ddlExtensionPeriod").setValue("0");
    }

  }


  SaveNewContract() {

    this.submitted = true;
    if (this.newcontract.invalid) {

      return;
    }
    if (this.newcontract.get("ddlExtensionAvailable").value == "0") {
      alert("Please select extension available !");
      return;
    }
    //
    if (this.newcontract.get("ddlExtensionPeriod").value == "") {
      alert("Please enter extension period !");
      return;
    }
    //
    if (this.newcontract.get("ddlExtensionAvailable").value == "Yes") {
      if (this.newcontract.get("ddlExtensionPeriod").value == "0") {
        alert("Extension period cannot be zero !");
        return;
      }
    }
    //

    if (this.newcontract.get("StartDate").value == "") {
      alert("Please select start date !");
      return;
    }
    if (this.newcontract.get("EndDate").value == "") {
      alert("Please select end date!");
      return;
    }
    //
    if (this.newcontract.get("EndDate").value <= this.newcontract.get("StartDate").value) {
      alert("Start date can not be greater than end date!");
      return;

    }
    if (this.SubCategorySelected == "") {
      alert("Please select subcategory !");
      return;
    }

    if (this.newcontract.get("ddlType").value == "0") {
      alert("Please select type!");
      return;
    }
    if (this.newcontract.get("ddlStatus").value == "0") {
      alert("Please select status!");
      return;
    }
    if (this.newcontract.get("ddlPriority").value == "0") {
      alert("Please select priority!");
      return;
    }
    var datepipe = new DatePipe('en-US');
    const formdata = new FormData();
    formdata.append('PK_ContractID', this.route.snapshot.queryParams['ContractID']);
    formdata.append('OrganisationID', this.newcontract.get("ddlOrganistaion").value);
    formdata.append('Extention', this.newcontract.get("ddlExtensionAvailable").value == "Yes" ? "1" : "0");
    formdata.append('ContractTitle', this.newcontract.get("txtContractTitle").value);
    formdata.append('ExtentionPeriod', this.newcontract.get("ddlExtensionPeriod").value);
    formdata.append('StartDate', datepipe.transform(this.newcontract.get("StartDate").value, 'MM/dd/yyyy'));
    formdata.append('EndDate', datepipe.transform(this.newcontract.get("EndDate").value, 'MM/dd/yyyy'));
    formdata.append('CategoryID', this.SubCategorySelected);
    formdata.append('ContractType', this.newcontract.get("ddlType").value);
    formdata.append('ContractStatus', this.newcontract.get("ddlStatus").value);
    formdata.append('OwnerFirstName', this.newcontract.get("OwnerFirstName").value);
    formdata.append('OwnerLastName', this.newcontract.get("OwnerLastName").value);
    formdata.append('OwnerEmail', this.newcontract.get("OwnerEmail").value);
    formdata.append('MainCategoryID', this.newcontract.get("ddlCategory").value);
    formdata.append('Description', this.newcontract.get("Description").value);
    formdata.append('Priority', this.newcontract.get("ddlPriority").value);
    formdata.append('GDPR', this.newcontract.get("ddlGDPR").value);
    formdata.append('LLA', this.newcontract.get("ddlLLA").value);
    formdata.append('Reference', this.newcontract.get("txtContractReference").value);
    formdata.append('Department', this.newcontract.get("txtDepartment").value);
    this.Dservice.CreateContract(formdata).subscribe((data: any) => {
      if (data == "success") {
        alert("Updated Successfully !");
        this.Cancel();
      }
      else {
        alert(data);
      }
    });

  }

  test12() {
    alert("in onblur");
  }

  Cancel() {
    this.Cservice.GetSubCategoryForContract(this.route.snapshot.queryParams['ContractID']).subscribe((data: any) => {
      sessionStorage.setItem('subcat', data.value);

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'] } });

    });
  }
  downloadFile(data: any, filename: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    saveAs(blob, filename);
  }

  getContractDocument(DocumentID: any, filename: string) {
    this.Dservice.getContractFile(DocumentID).subscribe((data: any) => this.downloadFile(data, filename), error => console.log('Error downloading the file.'),
      () => console.info('OK'));

  }

  getSupportingDocument(DocumentID: any, filename: string) {
    this.Dservice.getSupportingFile(DocumentID).subscribe((data: any) => this.downloadFile(data, filename), error => console.log('Error downloading the file.'),
      () => console.info('OK'));
  }

  getSupplierDocument(DocumentID: any, filename: string) {
    this.Dservice.getContractSupplierFile(DocumentID).subscribe((data: any) => this.downloadFile(data, filename), error => console.log('Error downloading the file.'),
      () => console.info('OK'));
  }

  SaveContractDocument() {

    if (this.Contractfile == null || this.Contractfile == "") {
      alert("Please choose file to upload !");
      return;

    }
    const formdata = new FormData();
    formdata.append('PK_ContractID', this.route.snapshot.queryParams['ContractID']);
    formdata.append('ContractFile', this.Contractfile);
    this.Dservice.SaveContractDocument(formdata).subscribe((data: any) => {
      if (data == "success") {
        this.getContractDocuments();
        this.UploadContract.reset();
        this.Contractfile = "";
        alert("Upload successful !");
      }
      else {
        alert("Something went wrong !");

      }
    });

  }

  DeleteContractDoc(cellID: any) {
    const formdata = new FormData();
    formdata.append('PK_DocumentID', cellID);
    this.Dservice.SaveContractDocument(formdata).subscribe((data: any) => {
      if (data == "success") {
        this.getContractDocuments();
      }
      else {
        alert("Something went wrong !");
      }
    });
  }

  DeleteSupplierDoc(cellID: any) {

    const formdata = new FormData();
    formdata.append('PK_DocumentID', cellID);

    this.Dservice.SaveSupplierDocument(formdata).subscribe((data: any) => {
      if (data == "success") {
        this.getSupplierDocuments();
      }
      else {
        alert("Something went wrong !");
      }
    });
  }

  DeleteSupportingDoc(cellID: any) {
    const formdata = new FormData();
    formdata.append('PK_DocumentID', cellID);
    this.Dservice.SaveSupportingDocument(formdata).subscribe((data: any) => {
      if (data == "success") {
        this.getSupportingDocuments();

      }
      else {
        alert("Something went wrong !");

      }
    });
  }

  getExtendData(ContractID: any) {
    this.DbService.getContractExtendbyContractID(ContractID).subscribe((data: any) => {
      if (data != "No Data") {
        this.extensionHistoryDataSource = new MatTableDataSource(data);
      }
    });
  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;
    if (!reg.test(input)) {
      e.preventDefault();
    }
  }

  SaveSupportingDocument() {
    if (this.SupportDocfile == null || this.SupportDocfile == "") {
      alert("Please choose file to upload !");
      return;

    }
    const formdata = new FormData();
    formdata.append('PK_ContractID', this.route.snapshot.queryParams['ContractID']);
    formdata.append('SupportFile', this.SupportDocfile);
    this.Dservice.SaveSupportingDocument(formdata).subscribe((data: any) => {
      if (data == "success") {
        this.getSupportingDocuments();
        this.UploadSupportingDoc.reset();
        this.SupportDocfile = "";
        alert("Upload successful !");
      }
      else {
        alert("Something went wrong !");

      }
    });

  }

  isNumber(val: any) { return typeof val === 'number'; }

  AcceptProjectApproval() {
    if (this.route.snapshot.queryParams['Reprocure'] != null) {
      this.DbService.AcceptorRejectProjectRequestmail(this.route.snapshot.queryParams['TMID'], sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['ContractID'], "Accept", "").subscribe((data: any) => {
        if (data.RetStatus == "Success") {

          if (sessionStorage.getItem("NotificationID") != null) {

            this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
          }
          else {
            this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ContractID']), Number(sessionStorage.getItem("ContactID")));
          }

          alert("Re-procure contract request accepted!");
          this.router.navigate(['dashboard/projects/new-project'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'], ContractType: "Reprocure" } });
        }
        else {
          alert(data.RetStatus);
        }
      });
    }
    else if (this.route.snapshot.queryParams['Extend'] != null) {
      this.DbService.RejectExtendContractRequest_SendMailto_TM(this.route.snapshot.queryParams['TMID'], sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['ContractID'], "Accept", "").subscribe((data: any) => {
        if (data.RetStatus == "Success") {
          if (sessionStorage.getItem("NotificationID") != null) {
            this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
          }
          else {
            this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ContractID']), Number(sessionStorage.getItem("ContactID")));
          }
          this.DeleteTemporaryProject();
          alert("Project approval request accepted");
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'], ExtendContract: "true", POID: sessionStorage.getItem("ContactID") } });
        }
      });

    }

    else {
      this.DbService.AcceptorRejectReProcure_ExtendContractStatusMail(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['POID'], this.route.snapshot.queryParams['ContractID'], this.route.snapshot.queryParams['For'], "Accept", "").subscribe((data: any) => {
        if (data.RetStatus == "Success") {
          if (sessionStorage.getItem("NotificationID") != null) {
            this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
          }
          else {
            this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ContractID']), Number(sessionStorage.getItem("ContactID")));
          }
          this.UpdateTemporaryProjectTMtoPO();
          alert("Project approval request accepted");
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'] } });
        }
        else {
          alert(data.RetStatus);
        }
      });

    }

  }

  RejectProjectApproval() {
    const dialogRef = this.dialog.open(DialogReasonforProjectapproverejectComponent, {
      data: { ContractID: this.route.snapshot.queryParams['ContractID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result != "") {
        if (this.route.snapshot.queryParams['Reprocure'] != null) {
          this.DbService.AcceptorRejectProjectRequestmail(this.route.snapshot.queryParams['TMID'], sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['ContractID'], "Reject", result).subscribe((data: any) => {
            if (data.RetStatus == "Success") {
              if (sessionStorage.getItem("NotificationID") != null) {
                this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
              }
              else {
                this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ContractID']), Number(sessionStorage.getItem("ContactID")));
              }
              this.DeleteTemporaryProject();
              alert("Project request rejected");
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'] } });
            }
            else {
              alert(data.RetStatus);
            }
          });
        }
        else if (this.route.snapshot.queryParams['Extend'] != null) {
          this.DbService.RejectExtendContractRequest_SendMailto_TM(this.route.snapshot.queryParams['TMID'], sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['ContractID'], "Reject", result).subscribe((data: any) => {
            if (data.RetStatus == "Success") {
              if (sessionStorage.getItem("NotificationID") != null) {
                this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
              }
              else {
                this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ContractID']), Number(sessionStorage.getItem("ContactID")));
              }
              this.DeleteTemporaryProject();
              alert("Project Request Rejected");
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'] } });
            }
            else {
              alert(data.RetStatus);
            }
          });
        }
        else {
          this.DbService.AcceptorRejectReProcure_ExtendContractStatusMail(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['POID'], this.route.snapshot.queryParams['ContractID'], this.route.snapshot.queryParams['For'], "Reject", result).subscribe((data: any) => {
            if (data.RetStatus == "Success") {
              if (sessionStorage.getItem("NotificationID") != null) {
                this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
              }
              else {
                this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ContractID']), Number(sessionStorage.getItem("ContactID")));
              }
              this.DeleteTemporaryProject();
              alert("Project approval request rejected");
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: this.route.snapshot.queryParams['ContractID'] } });
            }
            else {
              alert(data.RetStatus);
            }
          });
        }
      }
    });
  }

  NotificationStatusUpdate(From: string, NotifyID: number, NotificationFor: number, NotificationTo: number) {
    if (From == "FromNotification") {
      this.SysNotifications.UpdateNoticationStatus(NotifyID).subscribe((data: any) => {
        if (data.value != "Updated") alert(data.value);
      });
    }
    else {
      this.SysNotifications.UpdateNoticationStatus_FromSentID(NotificationFor, NotificationTo).subscribe((data: any) => {
        if (data.value != "Success") alert(data.value);
      });
    }
  }


  DeleteTemporaryProject() {

    this.DbService.DeleteTemporaryProject(Number(this.route.snapshot.queryParams['ContractID'])).subscribe((data: any) => {
      if (data.value != "Success") alert(data.value);
    });
  }


  UpdateTemporaryProjectTMtoPO() {
    this.DbService.UpdateTemporaryProjectTMtoPO(Number(this.route.snapshot.queryParams['ContractID'])).subscribe((data: any) => {
      if (data.value != "Success") alert(data.value);
    });
  }

  LoadAllCaptions() {
    this.setService.getCaptionFromDB("dashboard-contract-details").subscribe((data: any) => {
      this.Names = data;
    });

  }


  getCaption(sCaption: string) {
    var sResult = sCaption;
    if (this.Names != null) {
      this.Names.forEach(element => {
        if (element.OriginalName === sCaption) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }

  isLLAEnabled() {
    this.setService.enableLondonLivingAllowance().subscribe((data: any) => {
      this.bLLAEnabled = (data.value == "yes")
    });

  }

  UseSimpleSubCategory() {
    this.setService.isSimpleSubCategory().subscribe((data: any) => {
      this.bUseSimpleSubCategory = (data.value == "yes")
    });

  }

  onCategorySelected(value: string) {
    this.DbService.getSubCategorybyCat(Number(value)).subscribe((data: any) => {
      this.SubCategory = data;
    });
  }

  selectSimpleCatEvent(item: string) {

    this.SubCategorySelected = item
    this.tabchange = true;
    this.isDisabledContent = false;
  }

  routeTo(route) {
    if (!this.tabchange) {
      if (route == 'home')
        this.router.navigate(["/dashboard/performance"]);
      if (route == 'contracts')
        this.router.navigate(["/dashboard/contracts"]);
      if (route == 'database')
        this.router.navigate(["/dashboard/contracts/database"]);
     
    }
  }

}



export interface YearlyFinancials {
  financialYear: string;
  projectedSpend: any;
  actualSpend: number;
  projectedSavings: any;
  actualSavings: number;
}

export interface Documents {
  documentName: string;
  dateUploaded: Date;
  expiryDate?: Date;
}

export interface ContractExtension {
  dateExtended: Date;
  extensionPeriod: string;
  extensionPeriodRemaining: string;
}

const YEARLY_FINANCIALS_DATA: YearlyFinancials[] = [
  {
    financialYear: 'April 2019 - March 2020',
    projectedSpend: 2760000,
    actualSpend: 2100000,
    projectedSavings: 230000,
    actualSavings: 174800
  },
  {
    financialYear: 'April 2020 - March 2021',
    projectedSpend: 3000000,
    actualSpend: 2500000,
    projectedSavings: 2500000,
    actualSavings: 207500
  },
  {
    financialYear: 'April 2021 - March 2022',
    projectedSpend: 240000,
    actualSpend: 100000,
    projectedSavings: 20000,
    actualSavings: 8200
  },
  {
    financialYear: '',
    projectedSpend: 'Total Spend to Date',
    actualSpend: 4700000,
    projectedSavings: 'Total Saving to Date',
    actualSavings: 390500
  }
];

const CONTRACT_DOCUMENTS_DATA: Documents[] = [
  {
    documentName: 'Signedcontract.pdf',
    dateUploaded: new Date('2019/Sep/23')
  }
];

const SUPPORTING_DOCUMENTS_DATA: Documents[] = [
  {
    documentName: 'Scheduleofrates.pdf',
    dateUploaded: new Date('2019/Sep/23')
  }
];

const EXTENSION_HISTORY_DATA: ContractExtension[] = [
  {
    dateExtended: new Date('2019/Sep/23'),
    extensionPeriod: '12 Months',
    extensionPeriodRemaining: '36 Months'
  }
];

const SUPPLIERS_DOCUMENTS_DATA: Documents[] = [
  {
    documentName: 'Professional Indemnity Insurance',
    dateUploaded: new Date('2019/Sep/23'),
    expiryDate: new Date('2022/Sep/22')
  }
];

