import { Component, OnInit, ElementRef, ViewChild, TestabilityRegistry } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {DashboardPerformanceService} from './dashboard-performance.service';
import * as jsPDF  from 'jspdf';
import * as html2canvas from 'html2canvas';
import {HeroDashboardPerformanceService} from '../../hero/hero-dashboard-performance/hero-dashboard-performance.service';
import {DashboardProjectsService} from '../dashboard-projects/dashboard-projects.service';
import { tick } from '@angular/core/testing';
import {ProjectDatabaseService} from '../project-database.service';
import {AccountSetupWizardService} from '../../account-setup-wizard/account-setup-wizard.service';
import { AppSettingsService } from 'src/app/service/app-settings.service';

@Component({
  selector: 'app-dashboard-performance',
  templateUrl: './dashboard-performance.component.html',
  styleUrls: ['./dashboard-performance.component.css']
})
export class DashboardPerformanceComponent implements OnInit {
  public loading = false;
 Organisation:any;
 nameId:string;
 Teams:any;
 ProcurementFor:any;
TeamID:string;
pagecontent:any;
Financialyear:string;
Projectowner:string;
FinancialYears:any;
ProjectOwners:any;
AssignedProjects:any;
//CancelledProjects:any;
//InCompletedProjects:any;
CompletedProjects:any;
EstimatedSavings : any;
EstimatedTarget : any ="0";
ActualSavings : any;
//
TotalAssignedProject : any;
TotalSavings : any;
SavingsPercentage : any;
//
InCompleteProject : any;
InCompleteProjectPercentage : any;
ProjectsnotStarted : any;
ProjectsnotStartedPercentage : any;
ProjectsInProgress :any;
ProjectsInProgressPercentage : any;
ProjectsOnHold : any;
ProjectsOnHoldPercentage : any;
TotalCancelledProject : any;
TotalCancelledProjectPercentage :any;
//
Savings_Incomplete : any;
Savings_Incomplete_Per : any;
Savings_NotStarted : any;
Savings_NotStarted_Per : any;
Savings_InProgress : any;
Savings_InProgress_Per : any;
Savings_OnHold : any;
Savings_OnHold_Per : any;
IncompleteSavings_Slippage : any;
IncompleteSavingsPercentage_Slippage : any;
CancelledProject_Slippage : any;
CancelledProject_Slippage_Percentage : any;
//
CompletedProject : any;
CompletedProjectPercentage : any;
ProjectsCompletedEarly : any;
ProjectsCompletedEarlyPercentage : any;
ProjectsCompletedOnTime : any;
ProjectsCompletedOnTimePercentage : any;
ProjectsCompletedLate  :any;
ProjectsCompletedLatePercentage :any;
//
Savings_Complete : any;
Savings_Complete_Per : any;
SavingsCompletedEarly : any;
SavingsCompletedEarlyPercentage : any;
SavingsCompletedOnTime : any;
SavingsCompletedOnTimePercentage : any;
SavingsCompletedLate : any;
SavingsCompletedLatePercentage : any;
CompletedLateSavings_Slippage : any;
CompletedLateSavingsPercentage_Slippage : any;
//
showOrg : boolean = true;
ForTeams:boolean=true;
ForOwners:boolean=true;
ShowWizardMenu:boolean=false;
@ViewChild('content', {static: false}) content: ElementRef;

@ViewChild('ExportButton' , {static: false}) private ExportButton: ElementRef;

  constructor(private router:Router,private route:ActivatedRoute,private Dservice:DashboardPerformanceService,private HeroService: HeroDashboardPerformanceService,private PService :DashboardProjectsService,
    private DbService:ProjectDatabaseService, private wizard:AccountSetupWizardService,appSettings:AppSettingsService) {
    
    if(sessionStorage.getItem("ContactID") !=null)
     {
       this.FinancialYears =[{"FinanceYearDisplay":"April 2020 - March 2021","FinanceYear":"2020-21"}];
       this.Financialyear = "2020-21";
        this.GetFinancialYear();
       
      this.WizardHideorShow();
       
        //this.GetTotalCompletedProjectssandSavings();
       
      /*  if(sessionStorage.getItem("usertype") =="Head of Procurement")
        {
          this.getOrganisation();
          //this.getTeams();
        }
        else
        {
          this.getOrganisationForNonHop();
          //this.getTeams();
                
        }*/
        
        
     }
     else
     {
      if(this.route.snapshot.queryParamMap.get('valid') =="true")
      {

      }
      else
      {
        this.router.navigate(["/login"]);
      }
     }

     if(appSettings.siteKeys.domain=='onesource')
     {
       this.router.navigate(['/dashboard/performanceonesource']);
     }

     
   }

  ngOnInit() {
    this.nameId ="0";
    this.TeamID ="0";
   
    this.Projectowner="0";
    this.ProcurementFor="0";
    //this.pagecontent = this.content.nativeElement;
  }


  Procurementselect()
  {
    this.router.navigate(["/dashboard/performancelifetime"]);
  }

  getOrganisationForNonHop()
  {
    this.showOrg =false;
    this.Dservice.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
      {
          this.Organisation=data;
          //this.nameId = data[0].OrganisationID;
          this.selectName();
          
        
      });
  }

  getOrganisation()
  {
    this.Dservice.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
    {
        this.Organisation=data.value;
        this.getOwners();
        this.OwnerChanged();
    });
  }

  getTeams()
  {
    this.Dservice.getAllTeams().subscribe((data:any)=>{
      this.Teams=data.value;
    });
  }
  
  selectName()
  {
    
    if(this.nameId =="0")
    {
     
      this.Dservice.getTeamsbyOrganisation(this.nameId).subscribe((data:any) =>
      {
        this.Teams=data.value;
        this.TeamID = "0";
        //
        this.Projectowner ="0";
       
        this.GetBlock1data();
        this.GetTotalAssignedProjectsandSavings();
        this.GetTotalIncompleteProjectssandSavings();
        this.GetTotalCompletedProjectssandSavings();
        if(sessionStorage.getItem("usertype") =="Procurement Officer")
        {
          this.ForOwners=false;
          this.PService.GetProjectOwnerByContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
          {
            this.ProjectOwners=data;
            if(data.length >0)
            {
              this.Projectowner=data[0].OwnerValue;
              this.OwnerChanged();
            }
          });
        }
        else{
        this.getOwners_BySelection();
        }
      });
    }
    else
    {
      if(sessionStorage.getItem("usertype") =="Head of Procurement")
      {
        this.Dservice.getTeamsbyOrganisation(this.nameId).subscribe((data:any) =>
        {
          this.Teams=data.value;
          this.TeamID = "0";
          //
          this.Projectowner ="0";
          this.getOwners_BySelection();
          this.GetBlock1data();
          this.GetTotalAssignedProjectsandSavings();
          this.GetTotalIncompleteProjectssandSavings();
          this.GetTotalCompletedProjectssandSavings();
        });
      }
      else
      {
        this.Dservice.getTeamsbyOrganisationNonHOP(sessionStorage.getItem("ContactID"),this.nameId).subscribe((data:any) =>
        {
          this.Teams=data;
          if(data.length >0)
          {
            this.TeamID="0";
            if(sessionStorage.getItem("usertype") =="Procurement Officer")
            {
              this.ForOwners=false;
              this.PService.GetProjectOwnerByContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
              {
                this.ProjectOwners=data;
                if(data.length >0)
                {
                  this.Projectowner=data[0].OwnerValue;
                  this.OwnerChanged();
                }
              });
            }
            else
            {
              this.getOwners_BySelection();
              this.OwnerChanged();
            }
          }
        });
      }
     
    }  
  }

  TeamChanged()
  {
    if(sessionStorage.getItem("usertype") !="Procurement Officer")
    {
    this.getOwners_BySelection();
    }
    
       if(this.TeamID == "0")
       {
        
        this.GetBlock1data();
        this.GetTotalAssignedProjectsandSavings();
        this.GetTotalIncompleteProjectssandSavings();
        this.GetTotalCompletedProjectssandSavings();
       }
       else{
        this.GetBlock1data();
       this.GetTotalAssignedProjectsandSavings();
       this.GetTotalIncompleteProjectssandSavings();
       this.GetTotalCompletedProjectssandSavings();
       }

  }
  
  GetFinancialYear()
  {
    this.Dservice.GetFinancialYear().subscribe((data:any) =>{
        this.FinancialYears=data.value;
        //
        
        this.Financialyear ='2020-21'; //(new Date()).getFullYear() + '-' + (new Date()).getFullYear().toString().substring(0,2);
       
        if(sessionStorage.getItem("usertype") =="Head of Procurement")
        {
          this.getOrganisation();
          //this.getTeams();
        }
        else
        {
          this.ForTeams=false;
          this.getOrganisationForNonHop();
          //this.getTeams();
                
        }
       // this.GetBlock1data();
        //
       // this.GetTotalAssignedProjectsandSavings();
        //
        //this.GetTotalIncompleteProjectssandSavings();
        //
        //this.GetTotalCompletedProjectssandSavings();
        //this.getOwners();
        //
        
    });
  }

  getOwners()
  {
    this.Dservice.getAllProjectsOwners(sessionStorage.getItem("ContactID"),this.Financialyear).subscribe((data:any) =>
      {
        console.log(data);
          this.ProjectOwners=data;
          this.Projectowner ="0";
        
      });
  }
  getOwnerbyOrgID(OrgID:any)
  {
    this.Dservice.getAllProjectsOwnersByOrgID(OrgID,this.Financialyear).subscribe((data:any) =>
      {  
      
          this.ProjectOwners=data;
          this.Projectowner ="0";
      });
  }

  getOwnerbyTeamID()
  {
    this.Dservice.GetProjectOwners_ByTeam(this.TeamID,this.Financialyear).subscribe((data:any) =>
      {  
      
          this.ProjectOwners=data;
          this.Projectowner ="0";
      });
  }
  GetTotalAssignedProjectsandSavings()
  {
    if(this.nameId == "0") // All Organisation
    {
      if(this.Projectowner == "0") //All Owner
      {
    this.Dservice.GetTotalAssisgnedProjects(sessionStorage.getItem("ContactID"),this.Financialyear).subscribe((data:any) =>{
         console.log(data);
         this.TotalAssignedProject = data[0].NoOfAssignedProjects;
         this.TotalSavings = data[0].AssignedProjectsSavings;
         this.SavingsPercentage = data[0].SavingsPercentage;
       
       });
        }
        else{

          this.Dservice.GetTotalAssisgnedProjects_Owner(sessionStorage.getItem("ContactID"),this.Financialyear,this.Projectowner).subscribe((data:any) =>{
            console.log(data);
            this.TotalAssignedProject = data[0].NoOfAssignedProjects;
            this.TotalSavings = data[0].AssignedProjectsSavings;
            this.SavingsPercentage = data[0].SavingsPercentage;
          
          });
        }
    }
    else{
         if(this.Projectowner !="0")//Some Owner
         {
          this.Dservice.GetTotalAssisgnedProjectsByOrg_Owner(this.nameId,this.Financialyear,this.Projectowner).subscribe((data:any) =>{
            console.log(data);
            this.TotalAssignedProject = data[0].NoOfAssignedProjects;
            this.TotalSavings = data[0].AssignedProjectsSavings;
            this.SavingsPercentage = data[0].SavingsPercentage;
          
          });
         }
        else if(this.TeamID == "0")
        {
      this.Dservice.GetTotalAssisgnedProjects_ByOrg(sessionStorage.getItem("ContactID"),this.nameId,this.Financialyear).subscribe((data:any) =>{
        console.log(data);
        this.TotalAssignedProject = data[0].NoOfAssignedProjects;
        this.TotalSavings = data[0].AssignedProjectsSavings;
        this.SavingsPercentage = data[0].SavingsPercentage;
      
      });
      }
      else{
        this.Dservice.GetTotalAssisgnedProjectsTeam_ByOrg(this.nameId,this.Financialyear,this.TeamID).subscribe((data:any) =>{
          console.log(data);
          this.TotalAssignedProject = data[0].NoOfAssignedProjects;
          this.TotalSavings = data[0].AssignedProjectsSavings;
          this.SavingsPercentage = data[0].SavingsPercentage;
          //
          
        //this.EstimatedSavings = data[0].AssignedProjectsSavings;
        this.ActualSavings = data[0].AssignedProjectsActualSavings;
        });
      }
   
    }
  }

  GetTotalCompletedProjectssandSavings()
  {
    if(this.nameId == "0") // All Organisation
    {
      if(this.Projectowner != "0") //All Owner
      {
        this.Dservice.GetTotalCompleteProjects_ByOwner(sessionStorage.getItem("ContactID"),this.Financialyear,this.Projectowner).subscribe((data:any) =>{
          //alert(data[0].NoOfProjects) ;
         this.CompletedProject = data[0].NoOfProjects;
          this.CompletedProjectPercentage = data[0].ProjectsPercentage;
          this.ProjectsCompletedEarly = data[0].ProjectsEarly;
          this.ProjectsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage;
          this.ProjectsCompletedOnTime = data[0].ProjectsOnTime;
          this.ProjectsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage;
          this.ProjectsCompletedLate = data[0].ProjectsLate;
          this.ProjectsCompletedLatePercentage = data[0].ProjectsLatePercentage;
          
          //
          this.Savings_Complete = data[0].NoOfProjects_Savings;
          this.Savings_Complete_Per = data[0].ProjectsPercentage_Savings;
          this.SavingsCompletedEarly = data[0].ProjectsEarly_Savings;
          this.SavingsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage_Savings;
          this.SavingsCompletedOnTime = data[0].ProjectsOnTime_Savings;
          this.SavingsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage_Savings;
          this.SavingsCompletedLate = data[0].ProjectsLate_Savings;
          this.SavingsCompletedLatePercentage = data[0].ProjectsLatePercentage_Savings;
          //
          this.CompletedLateSavings_Slippage = data[0].Slippage_Savings;
          this.CompletedLateSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
               });
      }
      else 
      {
      this.Dservice.GetTotalCompleteProjects(sessionStorage.getItem("ContactID"),this.Financialyear).subscribe((data:any) =>{
        //alert(data[0].NoOfProjects) ;
       this.CompletedProject = data[0].NoOfProjects;
        this.CompletedProjectPercentage = data[0].ProjectsPercentage;
        this.ProjectsCompletedEarly = data[0].ProjectsEarly;
        this.ProjectsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage;
        this.ProjectsCompletedOnTime = data[0].ProjectsOnTime;
        this.ProjectsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage;
        this.ProjectsCompletedLate = data[0].ProjectsLate;
        this.ProjectsCompletedLatePercentage = data[0].ProjectsLatePercentage;
        
        //
        this.Savings_Complete = data[0].NoOfProjects_Savings;
        this.Savings_Complete_Per = data[0].ProjectsPercentage_Savings;
        this.SavingsCompletedEarly = data[0].ProjectsEarly_Savings;
        this.SavingsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage_Savings;
        this.SavingsCompletedOnTime = data[0].ProjectsOnTime_Savings;
        this.SavingsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage_Savings;
        this.SavingsCompletedLate = data[0].ProjectsLate_Savings;
        this.SavingsCompletedLatePercentage = data[0].ProjectsLatePercentage_Savings;
        //
        this.CompletedLateSavings_Slippage = data[0].Slippage_Savings;
        this.CompletedLateSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
        
             });
            }
    }
    else
    {
       if(this.Projectowner !="0")//Some Owner
       {
        this.Dservice.GetTotalCompleteProjectsByOrg_Owner(this.nameId,this.Financialyear,this.Projectowner).subscribe((data:any) =>{
          //alert(data[0].NoOfProjects) ;
         this.CompletedProject = data[0].NoOfProjects;
          this.CompletedProjectPercentage = data[0].ProjectsPercentage;
          this.ProjectsCompletedEarly = data[0].ProjectsEarly;
          this.ProjectsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage;
          this.ProjectsCompletedOnTime = data[0].ProjectsOnTime;
          this.ProjectsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage;
          this.ProjectsCompletedLate = data[0].ProjectsLate;
          this.ProjectsCompletedLatePercentage = data[0].ProjectsLatePercentage;
          
          //
          this.Savings_Complete = data[0].NoOfProjects_Savings;
          this.Savings_Complete_Per = data[0].ProjectsPercentage_Savings;
          this.SavingsCompletedEarly = data[0].ProjectsEarly_Savings;
          this.SavingsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage_Savings;
          this.SavingsCompletedOnTime = data[0].ProjectsOnTime_Savings;
          this.SavingsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage_Savings;
          this.SavingsCompletedLate = data[0].ProjectsLate_Savings;
          this.SavingsCompletedLatePercentage = data[0].ProjectsLatePercentage_Savings;
          //
          this.CompletedLateSavings_Slippage = data[0].Slippage_Savings;
          this.CompletedLateSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
          
               });
       }
        else if(this.TeamID == "0")
      {
      this.Dservice.GetTotalCompleteProjects_ByOrg(sessionStorage.getItem("ContactID"),this.nameId,this.Financialyear).subscribe((data:any) =>{
        //alert(data[0].NoOfProjects) ;
       this.CompletedProject = data[0].NoOfProjects;
        this.CompletedProjectPercentage = data[0].ProjectsPercentage;
        this.ProjectsCompletedEarly = data[0].ProjectsEarly;
        this.ProjectsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage;
        this.ProjectsCompletedOnTime = data[0].ProjectsOnTime;
        this.ProjectsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage;
        this.ProjectsCompletedLate = data[0].ProjectsLate;
        this.ProjectsCompletedLatePercentage = data[0].ProjectsLatePercentage;
        //
        this.Savings_Complete = data[0].NoOfProjects_Savings;
        this.Savings_Complete_Per = data[0].ProjectsPercentage_Savings;
        this.SavingsCompletedEarly = data[0].ProjectsEarly_Savings;
        this.SavingsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage_Savings;
        this.SavingsCompletedOnTime = data[0].ProjectsOnTime_Savings;
        this.SavingsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage_Savings;
        this.SavingsCompletedLate = data[0].ProjectsLate_Savings;
        this.SavingsCompletedLatePercentage = data[0].ProjectsLatePercentage_Savings;
        //
        this.CompletedLateSavings_Slippage = data[0].Slippage_Savings;
        this.CompletedLateSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
        
             });
      }
      else{
        this.Dservice.GetTotalCompleteProjectsTeam_ByOrg(this.nameId,this.Financialyear,this.TeamID).subscribe((data:any) =>{
          //alert(data[0].NoOfProjects) ;
         this.CompletedProject = data[0].NoOfProjects;
          this.CompletedProjectPercentage = data[0].ProjectsPercentage;
          this.ProjectsCompletedEarly = data[0].ProjectsEarly;
          this.ProjectsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage;
          this.ProjectsCompletedOnTime = data[0].ProjectsOnTime;
          this.ProjectsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage;
          this.ProjectsCompletedLate = data[0].ProjectsLate;
          this.ProjectsCompletedLatePercentage = data[0].ProjectsLatePercentage;
          //
          this.Savings_Complete = data[0].NoOfProjects_Savings;
          this.Savings_Complete_Per = data[0].ProjectsPercentage_Savings;
          this.SavingsCompletedEarly = data[0].ProjectsEarly_Savings;
          this.SavingsCompletedEarlyPercentage = data[0].ProjectsEarlyPercentage_Savings;
          this.SavingsCompletedOnTime = data[0].ProjectsOnTime_Savings;
          this.SavingsCompletedOnTimePercentage = data[0].ProjectsOnTimePercentage_Savings;
          this.SavingsCompletedLate = data[0].ProjectsLate_Savings;
          this.SavingsCompletedLatePercentage = data[0].ProjectsLatePercentage_Savings;
          //
          this.CompletedLateSavings_Slippage = data[0].Slippage_Savings;
          this.CompletedLateSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
          
               });

      }
    }
   
  }

  GetTotalIncompleteProjectssandSavings()
  {
    //this.InCompleteProject = 15;
   
     if(this.nameId == "0") // All Organisation
     {
      if(this.Projectowner == "0") //All Owner
      {
    this.Dservice.GetTotalIncompleteProjects(sessionStorage.getItem("ContactID"),this.Financialyear).subscribe((data:any) =>{
       //alert(data[0].NoOfProjects) ;
      this.InCompleteProject = data[0].NoOfProjects;
       this.InCompleteProjectPercentage = data[0].ProjectsPercentage;
       this.ProjectsnotStarted = data[0].NotStartedProjects;
       this.ProjectsnotStartedPercentage = data[0].NotStartedPercentage;
       this.ProjectsInProgress = data[0].InProgressProjects;
       this.ProjectsInProgressPercentage = data[0].InProgressPercentage;
       this.ProjectsOnHold = data[0].OnHoldProjects;
       this.ProjectsOnHoldPercentage = data[0].OnHoldPercentage;
       this.TotalCancelledProject = data[0].CancelledProjects;
       this.TotalCancelledProjectPercentage = data[0].CancelledPercentage;
       //
       this.Savings_Incomplete = data[0].NoOfProjects_Savings;
       this.Savings_Incomplete_Per = data[0].ProjectsPercentage_Savings;
       this.Savings_NotStarted = data[0].NotStartedProjects_Savings;
       this.Savings_NotStarted_Per = data[0].NotStartedPercentage_Savings;
       this.Savings_InProgress = data[0].InProgressProjects_Savings;
       this.Savings_InProgress_Per = data[0].InProgressPercentage_Savings;
       this.Savings_OnHold = data[0].OnHoldProjects_Savings;
       this.Savings_OnHold_Per = data[0].OnHoldPercentage_Savings;
       //
       this.IncompleteSavings_Slippage = data[0].Slippage_Savings;
       this.IncompleteSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
       //
       this.CancelledProject_Slippage = data[0].CancelledProjects_Savings;
       this.CancelledProject_Slippage_Percentage = data[0].CancelledPercentage_Savings;
            });
        }
        else{
          this.Dservice.GetTotalIncompleteProjects_ByOwner(sessionStorage.getItem("ContactID"),this.Financialyear,this.Projectowner).subscribe((data:any) =>{
            //alert(data[0].NoOfProjects) ;
           this.InCompleteProject = data[0].NoOfProjects;
            this.InCompleteProjectPercentage = data[0].ProjectsPercentage;
            this.ProjectsnotStarted = data[0].NotStartedProjects;
            this.ProjectsnotStartedPercentage = data[0].NotStartedPercentage;
            this.ProjectsInProgress = data[0].InProgressProjects;
            this.ProjectsInProgressPercentage = data[0].InProgressPercentage;
            this.ProjectsOnHold = data[0].OnHoldProjects;
            this.ProjectsOnHoldPercentage = data[0].OnHoldPercentage;
            this.TotalCancelledProject = data[0].CancelledProjects;
            this.TotalCancelledProjectPercentage = data[0].CancelledPercentage;
             //
       this.Savings_Incomplete = data[0].NoOfProjects_Savings;
       this.Savings_Incomplete_Per = data[0].ProjectsPercentage_Savings;
       this.Savings_NotStarted = data[0].NotStartedProjects_Savings;
       this.Savings_NotStarted_Per = data[0].NotStartedPercentage_Savings;
       this.Savings_InProgress = data[0].InProgressProjects_Savings;
       this.Savings_InProgress_Per = data[0].InProgressPercentage_Savings;
       this.Savings_OnHold = data[0].OnHoldProjects_Savings;
       this.Savings_OnHold_Per = data[0].OnHoldPercentage_Savings;
         //
         this.IncompleteSavings_Slippage = data[0].Slippage_Savings;
         this.IncompleteSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
          //
       this.CancelledProject_Slippage = data[0].CancelledProjects_Savings;
       this.CancelledProject_Slippage_Percentage = data[0].CancelledPercentage_Savings;
                 });
        }
    }
    else{
      if(this.Projectowner !="0")//Some Owner
      {
        this.Dservice.GetTotalIncompleteProjectsByOrg_Owner(this.nameId,this.Financialyear,this.Projectowner).subscribe((data:any) =>{
          //alert(data[0].NoOfProjects) ;
         this.InCompleteProject = data[0].NoOfProjects;
          this.InCompleteProjectPercentage = data[0].ProjectsPercentage;
          this.ProjectsnotStarted = data[0].NotStartedProjects;
          this.ProjectsnotStartedPercentage = data[0].NotStartedPercentage;
          this.ProjectsInProgress = data[0].InProgressProjects;
          this.ProjectsInProgressPercentage = data[0].InProgressPercentage;
          this.ProjectsOnHold = data[0].OnHoldProjects;
          this.ProjectsOnHoldPercentage = data[0].OnHoldPercentage;
          this.TotalCancelledProject = data[0].CancelledProjects;
          this.TotalCancelledProjectPercentage = data[0].CancelledPercentage;
           //
       this.Savings_Incomplete = data[0].NoOfProjects_Savings;
       this.Savings_Incomplete_Per = data[0].ProjectsPercentage_Savings;
       this.Savings_NotStarted = data[0].NotStartedProjects_Savings;
       this.Savings_NotStarted_Per = data[0].NotStartedPercentage_Savings;
       this.Savings_InProgress = data[0].InProgressProjects_Savings;
       this.Savings_InProgress_Per = data[0].InProgressPercentage_Savings;
       this.Savings_OnHold = data[0].OnHoldProjects_Savings;
       this.Savings_OnHold_Per = data[0].OnHoldPercentage_Savings;
         //
         this.IncompleteSavings_Slippage = data[0].Slippage_Savings;
         this.IncompleteSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
          //
       this.CancelledProject_Slippage = data[0].CancelledProjects_Savings;
       this.CancelledProject_Slippage_Percentage = data[0].CancelledPercentage_Savings;
               });
      }
        else if(this.TeamID == "0") //All Team
        {
      this.Dservice.GetTotalIncompleteProjects_ByOrg(sessionStorage.getItem("ContactID"),this.nameId,this.Financialyear).subscribe((data:any) =>{
        //alert(data[0].NoOfProjects) ;
       this.InCompleteProject = data[0].NoOfProjects;
        this.InCompleteProjectPercentage = data[0].ProjectsPercentage;
        this.ProjectsnotStarted = data[0].NotStartedProjects;
        this.ProjectsnotStartedPercentage = data[0].NotStartedPercentage;
        this.ProjectsInProgress = data[0].InProgressProjects;
        this.ProjectsInProgressPercentage = data[0].InProgressPercentage;
        this.ProjectsOnHold = data[0].OnHoldProjects;
        this.ProjectsOnHoldPercentage = data[0].OnHoldPercentage;
        this.TotalCancelledProject = data[0].CancelledProjects;
        this.TotalCancelledProjectPercentage = data[0].CancelledPercentage;
         //
       this.Savings_Incomplete = data[0].NoOfProjects_Savings;
       this.Savings_Incomplete_Per = data[0].ProjectsPercentage_Savings;
       this.Savings_NotStarted = data[0].NotStartedProjects_Savings;
       this.Savings_NotStarted_Per = data[0].NotStartedPercentage_Savings;
       this.Savings_InProgress = data[0].InProgressProjects_Savings;
       this.Savings_InProgress_Per = data[0].InProgressPercentage_Savings;
       this.Savings_OnHold = data[0].OnHoldProjects_Savings;
       this.Savings_OnHold_Per = data[0].OnHoldPercentage_Savings;
         //
         this.IncompleteSavings_Slippage = data[0].Slippage_Savings;
         this.IncompleteSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
          //
       this.CancelledProject_Slippage = data[0].CancelledProjects_Savings;
       this.CancelledProject_Slippage_Percentage = data[0].CancelledPercentage_Savings;
           });
         }
         else{
          this.Dservice.GetTotalIncompleteProjectsTeam_ByOrg(this.nameId,this.Financialyear,this.TeamID).subscribe((data:any) =>{
            //alert(data[0].NoOfProjects) ;
           this.InCompleteProject = data[0].NoOfProjects;
            this.InCompleteProjectPercentage = data[0].ProjectsPercentage;
            this.ProjectsnotStarted = data[0].NotStartedProjects;
            this.ProjectsnotStartedPercentage = data[0].NotStartedPercentage;
            this.ProjectsInProgress = data[0].InProgressProjects;
            this.ProjectsInProgressPercentage = data[0].InProgressPercentage;
            this.ProjectsOnHold = data[0].OnHoldProjects;
            this.ProjectsOnHoldPercentage = data[0].OnHoldPercentage;
            this.TotalCancelledProject = data[0].CancelledProjects;
            this.TotalCancelledProjectPercentage = data[0].CancelledPercentage;
             //
       this.Savings_Incomplete = data[0].NoOfProjects_Savings;
       this.Savings_Incomplete_Per = data[0].ProjectsPercentage_Savings;
       this.Savings_NotStarted = data[0].NotStartedProjects_Savings;
       this.Savings_NotStarted_Per = data[0].NotStartedPercentage_Savings;
       this.Savings_InProgress = data[0].InProgressProjects_Savings;
       this.Savings_InProgress_Per = data[0].InProgressPercentage_Savings;
       this.Savings_OnHold = data[0].OnHoldProjects_Savings;
       this.Savings_OnHold_Per = data[0].OnHoldPercentage_Savings;
         //
         this.IncompleteSavings_Slippage = data[0].Slippage_Savings;
         this.IncompleteSavingsPercentage_Slippage  =data[0].SlippagePercentage_Savings;
          //
       this.CancelledProject_Slippage = data[0].CancelledProjects_Savings;
       this.CancelledProject_Slippage_Percentage = data[0].CancelledPercentage_Savings;
               });
             }

         }

    }
  
 

  ClearAllDropDowns()
  {
    if(sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.nameId ="0";
      this.TeamID ="0";
      this.Projectowner="0";
      this.ProcurementFor="0";
      this.Financialyear ='2019-20';
      this.GetFinancialYear();
    }
    else if(sessionStorage.getItem("usertype") =="Procurement Officer")
    {
      this.nameId ="0";
      this.TeamID ="0";
  
      this.Financialyear ='2019-20';
      this.GetFinancialYear();
    }
    else
    {
      this.nameId ="0";
      this.TeamID ="0";
      this.Projectowner="0";
     
      this.Financialyear ='2019-20';
      this.GetFinancialYear();
    }
    // this.nameId ="0";
    // this.TeamID ="0";
    
    // this.Projectowner="0";
    // this.ProcurementFor="0";
    //this.Financialyear ='2019-20';
    
    // this.GetBlock1data();
    // this.GetTotalAssignedProjectsandSavings();
    // this.GetTotalIncompleteProjectssandSavings();
    // this.GetTotalCompletedProjectssandSavings();
    // this.getOwners();
  }

  // public downloadPDF() {
  //   const doc = new jsPDF();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const content = this.content.nativeElement;

  //   doc.fromHTML(content.innerHTML, 15, 15, {
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save('test.pdf');
  // }

  

  public captureScreen() {

    //this.HeroService.visible=false;
    //this.ExportButton.nativeElement.style.display = 'none';
    //this.ExportButton.nativeElement.remove();
    //this.nav.hide();
    //var delay=2000;
    //setTimeout(function() { }, delay);
    //var header ="<section class='hero' style='background-color: #F6F7FC;'><div class='section-container'><div class='hero--wrap'><div class='hero--title><h2>Performance</h2 </div><div class='hero--action'></div></div></div></section>";
    //const data = document.getElementById('content');
    this.loading=true;
    const data=this.content.nativeElement;
    
      html2canvas(data).then(canvas => {
    const imgWidth = 210;
    const pageHeight = 295;
    //var top_left_margin = 15;
    const imgHeight = (canvas.height * imgWidth / canvas.width);
    console.log("imgHeight" +imgHeight);
    let heightLeft = imgHeight;
    //var totalPDFPages = Math.ceil(data.clientHeight/canvas.height)-1;
    //var PDF_Width = data.clientWidth+(top_left_margin*2);
		//var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    const contentDataURL = canvas.toDataURL('image/png',1.0);
    
    const pdf = new jsPDF('p', 'mm','a4'); 
    let position = 0;
    
    pdf.addImage(contentDataURL, 'PNG', 0, position,imgWidth,imgHeight);
    heightLeft -= pageHeight;
    console.log("after minus heightLeft" +heightLeft);
        while (heightLeft >= 0) {
            position = (heightLeft - imgHeight) + 5;
            pdf.addPage();
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        } 
        //this.ExportButton.nativeElement.style.display = 'block';
    pdf.save('Performance_Dashboard_Report.pdf');
    this.loading=false;
    alert("Download completed!");
    });
    //this.nav.show();
    //this.HeroService.visible=true;
    
    
  }

  
  // public captureScreen() {
  //   const data = document.getElementById('content');
  //   html2canvas(data).then(canvas => {
  //   const imgWidth = 208;
  //   const pageHeight = 900;
  //   var top_left_margin = 15;
  //   const imgHeight = canvas.height * imgWidth / canvas.width;
  //   const heightLeft = imgHeight;
  //   var totalPDFPages = Math.ceil(data.clientHeight/canvas.height)-1;
  //   var PDF_Width = data.clientWidth+(top_left_margin*2);
	// 	var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
  //   const contentDataURL = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF('p', 'pt','a4'); 
  //   const position = 0;
  //   pdf.addImage(contentDataURL, 'PNG', top_left_margin, top_left_margin,imgWidth,imgHeight);
  //   for (var i = 1; i <= totalPDFPages; i++) { 
  //     pdf.addPage(PDF_Width, PDF_Height);
  //     pdf.addImage(contentDataURL, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),imgWidth,imgHeight);
  //   }
  //   pdf.save('invoice.pdf'); 
  //   });
  //   }

  // generatePdf() {
  //   const div = document.getElementById("content");
  //   const options = {background: "white", height: div.clientHeight, width: div.clientWidth};

  //   html2canvas(div, options).then((canvas) => {
  //       //Initialize JSPDF
  //       let doc = new jsPDF("p", "mm", "a4");
  //       //Converting canvas to Image
  //       let imgData = canvas.toDataURL("image/PNG");
  //       //Add image Canvas to PDF
  //       doc.addImage(imgData, 'PNG', 20, 20);

  //       let pdfOutput = doc.output();
  //       // using ArrayBuffer will allow you to put image inside PDF
  //       let buffer = new ArrayBuffer(pdfOutput.length);
  //       let array = new Uint8Array(buffer);
  //       for (let i = 0; i < pdfOutput.length; i++) {
  //           array[i] = pdfOutput.charCodeAt(i);
  //       }

  //       //Name of pdf
  //       const fileName = "example.pdf";

  //       // Make file
  //       doc.save(fileName);

  //   });
  // }

   //added by zuber on 14/12/2019
   YearChanged()
   {
    //this.getOwners_BySelection();
    if(sessionStorage.getItem("usertype") =="Procurement Officer")
    {
      this.ForOwners=false;
      this.PService.GetProjectOwnerByContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
      {
        this.ProjectOwners=data;
        if(data.length >0)
        {
          this.Projectowner=data[0].OwnerValue;
          this.OwnerChanged();
        }
      });
    }
    else
    {
      this.getOwners_BySelection();
      this.OwnerChanged();
    }
    //  this.GetBlock1data();
    //  this.GetTotalAssignedProjectsandSavings();
    //  this.GetTotalIncompleteProjectssandSavings();
    //  this.GetTotalCompletedProjectssandSavings();
   }

   getOwners_BySelection()
   {
      if(this.nameId =="0") // All Organisation
      {
          this.getOwners();
      }else
      {
           if(this.TeamID != "0")
           {
              this.getOwnerbyTeamID();
           }
           else{
              this.getOwnerbyOrgID(this.nameId);
           }
      }

   }

   OwnerChanged()
   {
     //this.TeamID ="0";
       if( this.Projectowner=="0")
       {
        this.GetBlock1data();
        this.GetTotalAssignedProjectsandSavings();
        this.GetTotalIncompleteProjectssandSavings();
        this.GetTotalCompletedProjectssandSavings();
       }else
       {
        this.GetBlock1data();
        this.GetTotalAssignedProjectsandSavings();
        this.GetTotalIncompleteProjectssandSavings();
        this.GetTotalCompletedProjectssandSavings();
       }
   }

   GetBlock1data()
   {
     if(this.nameId == "0") //All Organisation
     {

         if(this.Projectowner == "0") //All Owner
         {
    this.Dservice.GetEstimatedSavings(sessionStorage.getItem("ContactID"),this.Financialyear).subscribe((data:any) =>{
         this.EstimatedSavings = data.value;
   });

   this.Dservice.GetContractSavingsTarget(sessionStorage.getItem("ContactID"),this.Financialyear).subscribe((data:any) =>{
    this.EstimatedTarget = data.value;
   });
         }
         else{
          this.Dservice.GetEstimatedContractsSavings_Owner(sessionStorage.getItem("ContactID"),this.Financialyear,this.Projectowner).subscribe((data:any) =>{
            this.EstimatedSavings = data.value;
      });
   
      this.Dservice.GetContractSavingsTarget_ByOwner(sessionStorage.getItem("ContactID"),this.Financialyear,this.Projectowner).subscribe((data:any) =>{
       this.EstimatedTarget = data.value;
   });
         }
     }
     else{

        if(this.Projectowner !="0") //Some Owner
        {
          //alert("In Owner");
          this.Dservice.GetEstimatedContractsSavingsByOrg_Owner(this.nameId,this.Financialyear,this.Projectowner).subscribe((data:any) =>{
            this.EstimatedSavings = data.value;
              });
    
      this.Dservice.GetContractSavingsTarget_ByOrg_Owner(this.nameId,this.Financialyear,this.Projectowner).subscribe((data:any) =>{
       this.EstimatedTarget = data.value;
            });
        }
        else if(this.TeamID == "0")
        { //alert("In Organisation");
      this.Dservice.GetEstimatedSavings_ByOrg(sessionStorage.getItem("ContactID"),this.nameId,this.Financialyear).subscribe((data:any) =>{
        this.EstimatedSavings = data.value;
          });

  this.Dservice.GetContractSavingsTarget_ByOrg(this.nameId,this.Financialyear).subscribe((data:any) =>{
   this.EstimatedTarget = data.value;
        });
        }
        else{
          this.Dservice.GetEstimatedContractsSavings_ByOrg_Team(this.nameId,this.Financialyear,this.TeamID).subscribe((data:any) =>{
            this.EstimatedSavings = data.value;
              });

              this.Dservice.GetContractSavingsTarget_ByOrgTeam(this.nameId,this.Financialyear,this.TeamID).subscribe((data:any) =>{
                this.EstimatedTarget = data.value;
                  });

        }
        

     }

   }

   //added on 18/01/2020 //changed on 23/02/2020 to show project details
   showContractDetails(statustype : any) {
    //ContacID
   
    this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, OrganisationID: this.nameId,TeamID : this.TeamID,Owner : this.Projectowner,Statustype : statustype}});
    
    //this.router.navigateByUrl('dashboard/contract/details');
  }

  showIncompleteProjectDetails(statustype : any)
  {
    
    this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, OrganisationID: this.nameId,TeamID : this.TeamID,Owner : this.Projectowner,Statustype_I : statustype}});
    
  }

  showProjectDetails()
  {

    this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, OrganisationID: this.nameId,TeamID : this.TeamID,Owner : this.Projectowner}});

  }

 
  showCancelProjectDetails()
  {
    
    this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, OrganisationID: this.nameId,TeamID : this.TeamID,Owner : this.Projectowner,Statustype_C : 'cancel'}});
    
  }



  showSlippageProjectDetails(statustype : any)
  {
    
    this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, OrganisationID: this.nameId,TeamID : this.TeamID,Owner : this.Projectowner,slippage : 'yes',Statustype: statustype}});
    
  }
   

  //added on 24 Jan 2020 by Vikas 
  showProjectCompleted(status){
    this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, Owner: this.Projectowner, OrganisationID: this.nameId,TeamID : this.TeamID, ProjCompleteStatus: status}});
  }
//25/01/2020 vikas: completed project status deatils
showProjectInCompleted(status){
  this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, Owner: this.Projectowner, OrganisationID: this.nameId,TeamID : this.TeamID, ProjInCompleteStatus: status}});
}
 //27/01/2020 vikas: completed project status deatils
 showProjectCancelled(){
  this.router.navigate(['dashboard/projects/database'], { queryParams: {Year: this.Financialyear, Owner: this.Projectowner, OrganisationID: this.nameId,TeamID : this.TeamID, ProjCancelStatus: 'cancel'}});
}

//Date : 02 Mar 2020 by Arun
WizardHideorShow()
{
  this.wizard.getWizardCheckListByContactID(Number(sessionStorage.getItem("ContactID")),sessionStorage.getItem("usertype")).subscribe((data:any) =>
  {
    if(data.length == data.filter(x=>x.WizardCheckList_Viewed == 1).length)
    {
      this.ShowWizardMenu=false;
    }
    else
    {
      this.ShowWizardMenu=true;
    }
  });
}
}
