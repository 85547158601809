import { Component, OnInit, Inject } from '@angular/core';

import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import {DashboardContractsNewContractService} from '../../dashboard/dashboard-contracts-new-contract/dashboard-contracts-new-contract.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import {SettingsService} from '../../settings/settings.service';

@Component({
  selector: 'app-dialog-advanced-search',
  templateUrl: './dialog-advanced-search.component.html',
  styleUrls: ['./dialog-advanced-search.component.scss']
})
export class DialogAdvancedSearchComponent implements OnInit {
  AdvanceSearch:FormGroup;
  Organisation:any;
  ProjectOwners:any;
  Category:any;
  SubCategory:any;
  ExtensionPeriod:any;
  ContractType:any;
  ExtentionNo:boolean=false;
  ForOrganisation:boolean=true;
  Names :any;
  ProjectMSList:any;


  
  constructor(private router:Router,private DbService:ProjectDatabaseService,private ContractService:DashboardContractsNewContractService,
              private fb:FormBuilder,public dialogRef: MatDialogRef<DialogAdvancedSearchComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private setService:SettingsService) { 
    this.LoadAllCaptions();
	sessionStorage.removeItem("Item1");
    if(sessionStorage.getItem("ContactID") !=null)
    {
      if(sessionStorage.getItem("usertype") =="Head of Procurement")
      {
        this.getOrganisation();
        this.ForOrganisation=true;
      }
      else
      {
        this.getOrganisationForNonHOP();
        this.ForOrganisation=true;
      }
      this.getCategory();
        this.getExtensionPeriod();
        this.getContractTypes();
        this.getProjectOwners(sessionStorage.getItem("ContactID"));
    }
    else
    {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.AdvanceSearch=this.fb.group({
      ddlOrganisation:['All'],
      txtProjectTitle :[''],
      ddlCategory:['All'],
      //ddlSubCategory : ['All'],
      txtProjectOwner : ['All'],
      ddlPriority : ['All'],
      //ddlReProcurementIntension : ['All'],
      //txtStartDate :[''],
      //txtEndDate :[''],
      //ddlExtensionAvailable :['All'],
      //ddlExtensionPeriod :['All'],
      //ddlStatus:['All'],
      ddlProgress:['All'],
      ddlSlippage:['All'],
      ddlRisk:['All'],
      txtContractTitle:[''], 
      txtContractsRefNo:['']
        });

  }

  getOrganisation()
  {
    this.DbService.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
    {
        this.Organisation=data.value;
        
    });
  }

  getOrganisationForNonHOP()
  {
    this.DbService.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    {
      this.Organisation=data;
      if(data.length >0)
      {
        //this.AdvanceSearch.get("ddlOrganisation").setValue(data[0].OrganisationID);
        this.SelectProjectOwners();
      }
    });
  }

  getCategory()
  {
    this.DbService.getCategory().subscribe((data:any) =>
    {
      
      this.Category=data.value;
    });
  }

  getSubCategory(PK_CategoryTypeID:any)
  {
    console.log(PK_CategoryTypeID);
    if(PK_CategoryTypeID =="All")
    {
      this.SubCategory=null;
    }
    else
    {
      this.DbService.getSubCategoryby(PK_CategoryTypeID).subscribe((data:any)=>
      {
          this.SubCategory=data.value;
      });
    }
   
  }
  getExtensionPeriod()
  {
    this.DbService.getExtensionPeriod().subscribe((data:any) =>
    {
      
      this.ExtensionPeriod=data.value;
    });
  }

  getContractTypes()
   {
     //alert(this.CategoryId);
    this.ContractService.getContractTypes().subscribe((data:any) =>
    {
      this.ContractType=data.value;
      //this.NewProject.get("Reprocurement").setValue("0");
    });
   }
   SelectedChnage(ExtendionAvailable:string)
   {
     if(ExtendionAvailable =="No")
     {
       this.ExtensionPeriod=null;
       this.ExtentionNo=true;
     }
     else
     {
       this.ExtentionNo=false;
     }
   }
   AddSearch()
    {
       
        var datepipe = new DatePipe('en-US');
    
        const formData=new FormData();
        formData.append("Search", "Yes");
        formData.append("UserType", sessionStorage.getItem("usertype"));
        formData.append("ContactID", sessionStorage.getItem("ContactID"));
        formData.append("OrganisationID", this.AdvanceSearch.get("ddlOrganisation").value);
        formData.append("ProjectTitle", this.AdvanceSearch.get("txtProjectTitle").value);
        formData.append("Category", this.AdvanceSearch.get("ddlCategory").value);
        //formData.append("SubCategory", this.AdvanceSearch.get("ddlSubCategory").value);
        formData.append("ProjectOwner", this.AdvanceSearch.get("txtProjectOwner").value);
        formData.append("Priority", this.AdvanceSearch.get("ddlPriority").value);
        //formData.append("Type", this.AdvanceSearch.get("ddlReProcurementIntension").value);
        //formData.append("ExtensionAvailable", this.AdvanceSearch.get("ddlExtensionAvailable").value);
        //formData.append("ExtensionPeriod", this.AdvanceSearch.get("ddlExtensionPeriod").value);
        formData.append("ProjectStatus", "All");
        formData.append("ProjectProgress", this.AdvanceSearch.get("ddlProgress").value);
        formData.append("ProjectSlippage",this.AdvanceSearch.get("ddlSlippage").value);
        formData.append("ProjectRisk",this.AdvanceSearch.get("ddlRisk").value);
        formData.append("ContractTitle",this.AdvanceSearch.get("txtContractTitle").value);
        formData.append("ContractRefNo",this.AdvanceSearch.get("txtContractsRefNo").value);
        //formData.append("StartDate", datepipe.transform(this.AdvanceSearch.get("txtStartDate").value,'MM/dd/yyyy'));
        //formData.append("EndDate", datepipe.transform(this.AdvanceSearch.get("txtEndDate").value,'MM/dd/yyyy'));
        this.DbService.CreateNewProject(formData).subscribe((data:any) =>
        {
         //   alert(data.length + " records found for the search !");
            console.log(data);
            //let key = 'Item1';
            sessionStorage.setItem("Item1",JSON.stringify(data));
            this.dialogRef.close();
            //this.router.navigate(['dashboard/contracts/database']);
            
          /* if(data =="success")
            {
              //this.router.navigate(['dashboard/contracts']);
              alert("Updated Successfully !");
            
            }
            else{
                alert("Something went wrong !");
            
            }*/
        });
    }

    getProjectOwners(ContactID:any)
    {
      this.DbService.GetUsersUnderAllOrganisationbyContactID(ContactID).subscribe((data:any) =>
      {
        this.ProjectOwners=data;
      });
    }

    SelectProjectOwners()
    {
      if(this.AdvanceSearch.get("ddlOrganisation").value !="All")
      {
        this.DbService.GetProjectOwnerbyOrganisation(this.AdvanceSearch.get("ddlOrganisation").value).subscribe((data:any) =>
        { 
          this.ProjectOwners=data;
        });
      }
      else
      {
        this.getProjectOwners(sessionStorage.getItem("ContactID"));
      }
    }
	
	 LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dialog-advanced-search").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	this.setService.getProjectPhaseLabelFromDB().subscribe((data:any) =>
			{
			  this.ProjectMSList = data;
			});
	
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
  
  getProjectCaption (projectID : any)
  {
       var sResult = "";
	   if (this.ProjectMSList != null)
	   {
		   this.ProjectMSList.forEach(element => {
			if (element.Id === projectID) {
				sResult = element.ProjectPhase_Type;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
}
