import { Component, OnInit,Input,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() alertTitle: string;
  @Input() alertMessage: string;
  @Input() alertFooterNote: string;
  @Input() alertButtonYesText: string;
  @Input() alertButtonNoText: string;
  @Input() alertButtonYesStyle: string = 'flat';
  @Input() alertButtonNoStyle: string = 'stroked';
  constructor(public dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {
    if(this.data.RetStatus =="Updated")
    {
      this.alertTitle="Submit Project Plan";
      this.alertMessage="Your Project plan has been submitted for approval";
      this.alertButtonYesText="Great, thanks!";
      this.alertButtonNoText="";
    }
    else if(this.data.RetStatus =="CancelProject")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Cancel Project";
      this.alertMessage="Are you sure you want to cancel the Project...?";
      this.alertButtonYesText="Yes";
      this.alertButtonNoText="No";
    }
    else if(this.data.RetStatus =="CompleteProject")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Complete Project";
      this.alertMessage="Are you sure you want Complete the Project...?";
      this.alertButtonYesText="Yes";
      this.alertButtonNoText="No";
    }
    else if(this.data.RetStatus =="OnholdProject")
    {
      dialogRef.disableClose = true;
      this.alertTitle="On-hold Project";
      this.alertMessage="Are you sure you want On-hold the Project...?";
      this.alertButtonYesText="Yes";
      this.alertButtonNoText="No";
    }
    else if(this.data.RetStatus =="DeleteContract")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Delete Contract";
      this.alertMessage="Are you sure you want to delete...?";
      this.alertButtonYesText="Yes";
      this.alertButtonNoText="No";
    }
    else if(this.data.RetStatus =="DeleteContractYes")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Delete Contract";
      this.alertMessage=" These will also delete projects linked to this contract, do you really want to go ahead ?";
      this.alertButtonYesText="Yes";
      this.alertButtonNoText="No";
    }
    else if(this.data.RetStatus =="ReassignProject")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Remove User";
      this.alertMessage="Before you remove this user you need to reassign their projects";
      this.alertButtonYesText="Reassign Projects";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="ProjectReassign")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Projects Reassigned";
      this.alertMessage="Would you still like to remove this user?";
      this.alertButtonYesText="Yes, Remove User";
      this.alertButtonNoText="No, Don't Remove";
    }
    else if(this.data.RetStatus =="NewContract")
    {
      dialogRef.disableClose = true;
      this.alertTitle="New Contract";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="SaveContract")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Contract Details";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="DetailsEdit")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Project Details";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="AddTask")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Add New Task";
      this.alertMessage="Please note this phase already completed. In case you add a task, the phase will be incomplete. Do you want to proceed..?";
      this.alertButtonYesText="Yes";
      this.alertButtonNoText="No";
    }
    else if(this.data.RetStatus =="MileStoneEdit")
    {
      if(this.data.Resubmit =="no")
      {
      dialogRef.disableClose = true;
      this.alertTitle="Project Milestones";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
      }
      else{
        
        dialogRef.disableClose = true;
        this.alertTitle="Resubmit Project Timetable";
        this.alertMessage="Any changes to project milestone dates require you to resubmit your project timetable for approval.\n\nDo you wish to continue?";
        this.alertButtonYesText="Continue to Next Step ";
        this.alertButtonNoText="Cancel";

      }
      // dialogRef.disableClose = true;
      // this.alertTitle="Project MileStones";
      // this.alertMessage="Do you want to save changes?";
      // this.alertButtonYesText="Save Changes";
      // this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="SystemSettings")
    {
      dialogRef.disableClose = true;
      this.alertTitle="System Settings";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="NewProject")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Add New Project";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="ManageTeams")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Manage Teams";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="Notifications")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Notifications";
      this.alertMessage="Do you want to save changes?";
      this.alertButtonYesText="Save Changes";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="ContractDataUpload")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Contract Data Upload";
      this.alertMessage="Uploaded excel has some errors. Click on Download button to download the error list file";
      this.alertButtonYesText="Download";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="ProjectDataUpload")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Project Data Upload";
      this.alertMessage="Uploaded excel has some errors. Click on Download button to download the error list file";
      this.alertButtonYesText="Download";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="ExtendContract")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Extend Contract";
      this.alertMessage="Approval required to Extend contract";
      this.alertButtonYesText="Request Approval";
      this.alertButtonNoText="Cancel";
    }
    else if(this.data.RetStatus =="ContractRe-procure")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Re-procure Contract";
      this.alertMessage="Do you wish to assign the reprocurement of this contract to a member of your team?";
      this.alertButtonYesText="Yes";
      this.alertButtonNoText="No";
    }
    else if(this.data.RetStatus =="ReprocureContract")
    {
      dialogRef.disableClose = true;
      this.alertTitle="Re-procure Contract";
      this.alertMessage="Approval required to Re-procure contract";
      this.alertButtonYesText="Request Approval";
      this.alertButtonNoText="Cancel";
    }
    else
    {
      this.alertTitle="Submit Project Plan";
      this.alertMessage="No Changes found.";
      this.alertButtonYesText="OK";
      this.alertButtonNoText="";
    }
   }

  ngOnInit() {
  }

}
