import { Component, OnInit,Inject } from '@angular/core';
import {InvitesuserService} from './invitesuser.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-invitesuser',
  templateUrl: './invitesuser.component.html',
  styleUrls: ['./invitesuser.component.scss']
})
export class InvitesuserComponent implements OnInit {
visible:boolean=true;
Roles:any;
Teams:any;
newcontract : FormGroup;
submitted:boolean=false;
  constructor(private fb: FormBuilder,private IService: InvitesuserService,@Inject(MAT_DIALOG_DATA) public data: any,
  private mtdg:MatDialog) { 
    if(this.data.OrgID == 0)
    {
      this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
      {
        this.data.OrgID = tdata.value[0].OrganisationID;
        //alert(this.data.OrgID);
      });
    }
  }
  getInviteeRoles()
  {
    if (sessionStorage.getItem("usertype") == "Head of Procurement" && sessionStorage.getItem('IsSuperUser') != null) {
      this.IService.GetAllUserTypesforInviteUser().subscribe((data: any) => {
        this.Roles = data;
        this.newcontract.get("ddlRole").setValue(this.Roles[0].UserType_ID);
      });
    }
    else if (sessionStorage.getItem("usertype") == "Head of Procurement")
    {
      this.IService.GetUserTypesforInviteUserwithoutHOP().subscribe((data:any) =>
      {
          this.Roles=data;
          this.newcontract.get("ddlRole").setValue(this.Roles[0].UserType_ID);
      });
    }
    else
    { 
      this.IService.GetUserTypesforInviteUser().subscribe((data:any) =>
      {
          this.Roles=data;
          this.newcontract.get("ddlRole").setValue(this.Roles[0].UserType_ID);
      })
    }
    
  }
  get f() { return this.newcontract.controls; };
  getUserLoadTeams()
  {
    this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((data:any) =>
    {
      this.Teams=data.value;
      if(data.value.length >0)
      {
        this.newcontract.get("ddlAllocateTeam").setValue(this.Teams[0].TeamID);
      }
    });

  //  if(this.data.OrgID == "0")
  //  {
  //   this.IService.getTeamsbyContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  //   {
  //     this.Teams=data;
     
      
  //       this.newcontract.get("ddlAllocateTeam").setValue(this.Teams[0].TeamID);
     
      
  //   });
  //  }
  //  else
  //  {
  //     this.IService.getTeamsbyOrganisation(this.data.OrgID).subscribe((data:any) =>
  //     {
  //       this.Teams=data.value;
       
  //         this.newcontract.get("ddlAllocateTeam").setValue(this.Teams[0].TeamID);
       
  //     });
  //  }
  }
  
  ngOnInit() {
    this.newcontract = this.fb.group({
      txtfirstname : ['',Validators.required],
      txtlastname : ['',Validators.required],
      ddlRole : ['',Validators.required],
      ddlaccountstatus : ['',Validators.required],
      ddlconfig :['No',Validators.required],
      ddladmin :['No',Validators.required],
      ddlAllocateTeam : ['',Validators.required],
   
      txtemailaddress : ['',[Validators.required, Validators.email]],
      //ProjectedLifetimeContractSpend : ['',Validators.required],
      //ProjectedAnnualisedContractSpend :['',Validators.required],
      //ProjectedLifetimeContractSavings :['',Validators.required],
      //ProjectedAnnualisedContractSavings :['',Validators.required],
    

    });
    this.getInviteeRoles();
    this.getUserLoadTeams();
    this.newcontract.get("ddlaccountstatus").setValue("Active");
    this.newcontract.get("ddlconfig").setValue("No");
    this.newcontract.get("ddladmin").setValue("No");
   
  }
  SaveNewContract()
   {
    this.submitted = true;
      // stop here if form is invalid
      if (this.newcontract.invalid) 
      {
        return;
      }
      const formdata=new FormData();
      formdata.append('FirstName', this.newcontract.get("txtfirstname").value);
      formdata.append('Config', this.newcontract.get("ddlconfig").value );
      formdata.append('LastName', this.newcontract.get("txtlastname").value);
      formdata.append('EmailAddress', this.newcontract.get("txtemailaddress").value);
      formdata.append('Admin', this.newcontract.get("ddladmin").value);
      formdata.append('Team', this.newcontract.get("ddlAllocateTeam").value);
      formdata.append('AccountStatus',this.newcontract.get("ddlaccountstatus").value== "Yes" ? "1": "0");
      formdata.append('Role',this.newcontract.get("ddlRole").value);
      formdata.append('Organization',this.data.OrgID);
      formdata.append('ContactID', sessionStorage.getItem("ContactID"));
     
      
      this.IService.CreateContract(formdata).subscribe((data:any) =>
        {
            //alert(data);
            if(data.RetStatus =="Created")
            {
              alert("User created successfully !");
              this.mtdg.closeAll();
              //this.router.navigate(['dashboard/contracts/database']);
              //this.router.navigate(['/dashboard/projects/new-project'], { queryParams: {ContractID: data.ContractID}});
            }
            else{
                 alert(data.RetStatus);
            
            }
        });
       
  
   }

}
