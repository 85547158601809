import { Component, OnInit, Inject } from '@angular/core';
import { DashboardContractDetailsService } from 'src/app/dashboard/dashboard-contract-details/dashboard-contract-details.service';
import { MatDialog, TransitionCheckState } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-upload-contract-documents',
  templateUrl: './dialog-upload-contract-documents.component.html',
  styleUrls: ['./dialog-upload-contract-documents.component.scss']
})
export class DialogUploadContractDocumentsComponent implements OnInit {

  Contractfile :any;
  constructor(private Dservice:DashboardContractDetailsService,public dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogUploadRef: MatDialogRef<DialogUploadContractDocumentsComponent>) { }

  ngOnInit() {
  }

  onFileChange(event):void
  {
   if (event.target.files.length > 0) {
     const file = event.target.files[0];
      this.Contractfile = file;
   }
}
SaveContractDocument()
   {
    
    if(this.Contractfile == null || this.Contractfile =="")
    {
        alert("Please choose file to upload !");
        return;
    
    }
    const formdata=new FormData();
    formdata.append('PK_ContractID',this.data.ContractID);
    formdata.append('ContractFile', this.Contractfile);
    
    this.Dservice.SaveContractDocument(formdata).subscribe((data:any) =>
    {
       
        if(data =="success")
        {
         
          this.Contractfile ="";
      
          alert("Upload successful !");
          this.dialogUploadRef.close();
        }
        else{
             alert("Something went wrong !");
        
        }
    });
  }
}
