import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from './settings.service';
import { DashboardPerformanceService } from '../dashboard/dashboard-performance/dashboard-performance.service';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { InvitesuserComponent } from "../invitesuser/invitesuser.component";
import { EdituserComponent } from "../invitesuser/edituser/edituser.component";
import { DialogEstimatedSavingsTargetComponent } from "../dialogs/dialog-estimated-savings-target/dialog-estimated-savings-target.component";
import { HeaderComponent } from '../header/header.component';
import { EventEmitterService } from '../settings/event-emitter.service';
import { MatDialog } from '@angular/material/dialog';
//import { CreateteamComponent } from '../createteam/createteam.component'
import { CreateTeamDepartmentComponent } from '../create-team-department/create-team-department.component';
import { LoginService } from '../login/login.service'
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { EditfinanciallistComponent } from '../invitesuser/editfinanciallist/editfinanciallist.component';
import { EditcategorylistComponent } from '../invitesuser/editcategorylist/editcategorylist.component';
import { saveAs as importedSaveAs } from "file-saver";

import { AlertComponent } from '../alert/alert.component';
import { EditprojectstatusComponent } from '../invitesuser/editprojectstatus/editprojectstatus.component';
import { EditprojectmilestoneComponent } from '../invitesuser/editprojectmilestone/editprojectmilestone.component';
import { EditprojectrisksComponent } from '../invitesuser/editprojectrisks/editprojectrisks.component';
import { EditextensionperiodComponent } from '../invitesuser/editextensionperiod/editextensionperiod.component';
import { EditcontracttypeComponent } from '../invitesuser/editcontracttype/editcontracttype.component';
import { EditcontractstatusComponent } from '../invitesuser/editcontractstatus/editcontractstatus.component';
import { EditcontractGDPRComponent } from '../invitesuser/editcontract-gdpr/editcontract-gdpr.component';

import { EditprojectpriorityComponent } from '../invitesuser/editprojectpriority/editprojectpriority.component';
import { EditprojectprogressComponent } from '../invitesuser/editprojectprogress/editprojectprogress.component';
import { EditsubcategoryComponent } from '../invitesuser/editsubcategory/editsubcategory.component';
import { InvitesuserService } from "../invitesuser/invitesuser.service";

import { AllocatenewteamComponent } from '../invitesuser/allocatenewteam/allocatenewteam.component';
import { DialogAddNewOrganisationComponent } from '../dialogs/dialog-add-new-organisation/dialog-add-new-organisation.component';
import { EditProjectSavingsTargetComponent } from '../invitesuser/edit-project-savings-target/edit-project-savings-target.component';
import { AppSettingsService } from '../service/app-settings.service';
import { AdduserComponent } from '../invitesuser/adduser/adduser.component';
export interface Teams {
  teamName: string;
  dateCreated: Date;
  activeUsers: number;
  action: string;
}

export interface ContractOfficers {
  firstName: string;
  lastName: string;
  role: string;
  contractConfigurator: boolean;
  admin: boolean;
  accountStatus: string;
  action: string;
}

export interface Lists {
  listName: string;
  action: string;
}

const TEAMS: Teams[] = [
  {
    teamName: 'The name of the team goes here',
    dateCreated: new Date('2019/Jun/23'),
    activeUsers: 4,
    action: 'Manage Teams'
  }
];


// const CONTRACT_OFFICERS: ContractOfficers[] = [
//   {
//       firstName: 'John',
//       lastName: 'Watson',
//       role: 'Contract Manager',
//       contractConfigurator: true,
//       admin: true,
//       accountStatus: 'Active',
//       action: 'Edit User'
//   }
// ];
// {
//   listName: 'Extension Period',
//   action: 'Edit List'
// }, 
const LIST_CONTRACTS: Lists[] = [
  {
    listName: 'Contract Status',
    action: 'Edit List'
  }
];

const LIST_PROJECTS: Lists[] = [
  {
    listName: 'Project Milestones',
    action: 'Edit List'
  },
  {
    listName: 'Project Risks',
    action: 'Edit List'
  }
];

const LIST_GENERAL: Lists[] = [
  {
    listName: 'Financial Year',
    action: 'Edit List'
  },

  {
    listName: 'Category',
    action: 'Edit List'
  }

];

// {
//   listName: 'Sub Category',
//   action: 'Edit List'
// }

// ,
//   {
//     listName: 'Priority',
//     action: 'Edit List'
// }

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public loading = false;
  CurrencyCodes: any;
  teamsDisplayedColumns = ['teamName', 'Department', 'dateCreated', 'activeUsers', 'action']
  selectedIndex: number;
  contractOfficersDisplayedColumns = ['firstName', 'lastName', 'role', 'contractConfigurator', 'admin', 'accountStatus', 'action'];
  HOPDisplayedColumns = ['firstName', 'lastName', 'role', 'contractConfigurator', 'admin', 'accountStatus', 'action'];
  //contractOfficersDataSource = new MatTableDataSource(CONTRACT_OFFICERS);
  contractsListDisplayedColumns = ['listName', 'action'];
  contractsListDataSource = new MatTableDataSource(LIST_CONTRACTS);
  projectsListDisplayedColumns = ['listName', 'action'];
  projectsListDataSource = new MatTableDataSource(LIST_PROJECTS);
  public CONTRACT_OFFICERS: any;
  contractOfficersDataSource: any;
  TeamManagerDataSource: any;
  ProcurementDatasource: any;
  teamsDataSource: any;
  teamManageDataSource: any;
  Organisation: any;
  OrgID: any;
  TeamForm: FormGroup;
  ShowMT: boolean = true;
  ShowMD: boolean = false;
  TeamName: any;
  SystemSettings: FormGroup;
  systemsettingdata: any;
  submitted: boolean = false;
  LogoFile: string;
  ChangeLogoFile: string;
  ContractDataSource: any;
  SelectTeamName: string = "";
  TeamSubmit: boolean = false;
  Notifications: any;
  GeneralListDisplayedColumns = ['listName', 'action'];
  GeneralListDataSource = new MatTableDataSource(LIST_GENERAL);
  tabchange: boolean = false;
  tabchangeNotofications: boolean = false;
  tabmanageTeam: boolean = false;
  isDisabledContent: boolean = true;
  NotificationChnage: boolean = false;

  FinancialYearStarts: string;
  DateFormat: string;
  Currency: string;
  PasswordExpiry: string;
  PrimaryColour: string;
  ProjectApproval: string;
  ProjectPlanTemplate: string;
  MileStoneTolerancelevel: string;
  ActualAnnualContractValue: string;
  IsFinancialYearStartsused: string;
  IsFinancialUsed: string;
  IsDateFormatUsed: string;
  IsCurrencyUsed: string;
  IsPrimaryColourUser: string;
  IsProjectPlanTemplateUsed: string;
  IsProjectApprovalUsed: string;
  ProjectEnforceClosure: string;
  IsBringForwardPreviousYearsSavings: string;
  BringNumberOfPreviousYearsSavings: string;
  UploadedProjectDataFile: any;
  ForOrganisation: boolean = true;
  DataImportTab: boolean = true;
  SettingsTabs: boolean = true;
  NotificationTab: boolean = false;
  ShowAddOrganisation: boolean = false;
  EnableLondonLivingAllowance: string;
  SimpleSubType: string;
  disabledNumberOfPreviousYearsSavings = true;
  isOnesource = false;
  IsSuperUser = false;
  domain = '';
  constructor(private SetServ: SettingsService, private fb: FormBuilder,
    private logservice: LoginService, public HeaderComp: HeaderComponent, private eventEmitterService: EventEmitterService, private route: ActivatedRoute,
    private Dservice: DashboardPerformanceService, public dialog: MatDialog, private router: Router, private change: ChangeDetectorRef,
    private IService: InvitesuserService, appSettings: AppSettingsService) {

    this.IsSuperUser = sessionStorage.getItem("IsSuperUser") != null;
    this.domain = appSettings.siteKeys.domain;


    if (appSettings.siteKeys.domain == 'onesource') {
      this.isOnesource = true;
    }
    if (sessionStorage.getItem("ContactID") != null) {
      this.NotificationChnage = false;
      this.GetNotifications();
      this.OrgID = "0";
      this.getOrganisation();
      this.getCurrency();

      if (sessionStorage.getItem("usertype") != "Head of Procurement") {
        this.DataImportTabAction();
      }
      else {

        //this.DataImportTab=true;
        //this.SettingsTabs=true;
        this.ShowAddOrganisation = true;
        //this.selectedIndex=1;
      }
      if (this.route.snapshot.queryParams['Move'] == "3") {
        this.selectedIndex = 3;
      }
      else if (this.route.snapshot.queryParams['Move'] == "1") {
        this.selectedIndex = 1;
      }
      else if (this.route.snapshot.queryParams['ShowDataImport'] != null) {
        this.selectedIndex = 4;
      }
    }
    else {
      this.router.navigate(["/login"]);
    }
  }

  Logoupload: FormGroup;
  ngOnInit() {
    this.TeamForm = this.fb.group({
      TeamName: ['', Validators.required]
    });
    this.SystemSettings = this.fb.group({
      ddlFinancialYearStarts: ['', Validators.required],
      ddlCurrency: ['', Validators.required],
      ddlMilestoneToleranceLevel: ['', Validators.required],
      Dateformat: ['', Validators.required],
      ddlProjectApproval: ['', Validators.required],
      ddlPrimaryColour: ['', Validators.required],
      //companylogo:[''],
      PasswordExpiry: [''],
      ProjectPlanTemplate: [''],
      Financial: [''],
      ProjectEnforceClosure: [''],
      EnableLondonLivingAllowance: [''],
      ddlSimpleSubType: [''],
      IsBringForwardPreviousYearsSavings: [''],
      BringNumberOfPreviousYearsSavings: ['1'],
      // ddlCategory : ['',Validators.required],

      // txtemailaddress : ['',[Validators.required, Validators.email]],
      //ProjectedLifetimeContractSpend : ['',Validators.required],
      //ProjectedAnnualisedContractSpend :['',Validators.required],
      //ProjectedLifetimeContractSavings :['',Validators.required],
      //ProjectedAnnualisedContractSavings :['',Validators.required],


    });
    // this.Logoupload = this.fb.group({
    //   companylogo : [''],

    // });

    this.getTeamsandTeamUsers();


  }

  DataImportTabAction() {
    this.logservice.getContactByEmail(sessionStorage.getItem("userMail")).subscribe((data: any) => {
      if (data.AdminPerMission == "Yes") {
        //this.SettingsTabs=true;
        //this.DataImportTab=true;
      }
      if (data.ContractConfig == "Yes" && data.AdminPerMission == "No") {
        this.SettingsTabs = false;
        //this.DataImportTab=true;
      }
    });
  }
  getCurrency() {
    this.SetServ.getCurrencyCodes().subscribe((data: any) => {
      this.CurrencyCodes = data;
      if (data.length > 0) {
        console.log(data[0].PK_CurrencyCodeID);
        this.SystemSettings.get("ddlCurrency").setValue(data[0].PK_CurrencyCodeID);
      }
    });
  }

  UpdateTeamName() {
    this.TeamSubmit = true;
    if (this.TeamForm.invalid) {
      return;
    }

    const formdata = new FormData();
    formdata.append('TeamName', this.TeamForm.get("TeamName").value);
    formdata.append('TeamID', sessionStorage.getItem("TeamID"));
    this.SetServ.updateTeamName(formdata).subscribe((data: any) => {
      if (data.RetStatus == "Team Updated") {
        alert("Team Name updated successfully");
        this.Revert("ManageList");
      }
    }
    );
  }

  getOrganisation() {
    if (sessionStorage.getItem("usertype") == "Head of Procurement") {
      this.OrgID = "0";
      this.ForOrganisation = true;
      this.Dservice.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
        this.Organisation = data.value;
        // if(data.length >0)
        // {
        //   this.OrgID=0;
        //   this.selectName();
        // }
      });
    }
    else {
      this.ForOrganisation = true;
      this.OrgID = "0";
      this.IService.GetAssignedOrganisationForUser(Number(sessionStorage.getItem("ContactID"))).subscribe((odata: any) => {
        this.Organisation = odata;
        //if(odata.value.length >0)
        //{
        //this.OrgID=odata.value[0].OrganisationID;
        //  this.selectName();
        //}
      });
      // this.Dservice.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
      // {
      //     this.Organisation=data;
      //     if(data.length >0)
      //     {
      //       this.OrgID=data[0].OrganisationID;

      //       this.selectName();
      //     }

      // });
    }
  }

  getTeamsandTeamUsers() {
    if (sessionStorage.getItem("usertype") == "Head of Procurement") {
      this.SetServ.GetTeamMemCount(this.OrgID, sessionStorage.getItem("ContactID")).subscribe(
        (data: any) => {

          this.teamManageDataSource = new MatTableDataSource(data);
          if (data.length > 0) {
            // alert("dfgdf");
            this.SelectTeamName = data[0].teamName;
            sessionStorage.setItem("TeamID", data[0].TeamId);
            //this.SetServ.GetTeamDataOrganigataion(data[0].TeamId,this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
            this.SetServ.GetTeamUsersbyTeamID(data[0].TeamId).subscribe((condata: any) => {
              // console.log(condata);
              this.ContractDataSource = new MatTableDataSource(condata);
            });
          }

          this.GetSystemSettings();
        }
      );
    }
    else {
      this.SetServ.getTeamandTeamUsersCount_ForNonHOP(this.OrgID, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
        this.teamManageDataSource = new MatTableDataSource(data);
        if (data.length > 0) {
          this.SelectTeamName = data[0].teamName;
          sessionStorage.setItem("TeamID", data[0].TeamId);
          //this.SetServ.GetTeamDataOrganigataion(data[0].TeamId,this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
          this.SetServ.GetTeamUsersbyTeamID(data[0].TeamId).subscribe((condata: any) => {
            // console.log(condata);
            this.ContractDataSource = new MatTableDataSource(condata);
          });
        }
        this.GetSystemSettings();
      });
    }
  }

  GetContractOfficers(TeamID: any, TeamName: string) {
    this.SelectTeamName = TeamName;
    sessionStorage.setItem("TeamID", TeamID);
    //this.SetServ.GetTeamDataOrganigataion(TeamID,this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
    this.SetServ.GetTeamUsersbyTeamID(TeamID).subscribe((condata: any) => {
      // console.log(condata);
      this.ContractDataSource = new MatTableDataSource(condata);
    });
  }
  selectName() {
    if (sessionStorage.getItem("usertype") == "Head of Procurement") {
      this.SetServ.GetTeamMemCount(this.OrgID, sessionStorage.getItem("ContactID")).subscribe(
        (data: any) => {

          this.teamManageDataSource = new MatTableDataSource(data);
          if (data.length > 0) {
            this.SelectTeamName = data[0].teamName;
            sessionStorage.setItem("TeamID", data[0].TeamId);
            //this.SetServ.GetTeamDataOrganigataion(data[0].TeamId,this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
            this.SetServ.GetTeamUsersbyTeamID(data[0].TeamId).subscribe((condata: any) => {
              // console.log(condata);
              this.ContractDataSource = new MatTableDataSource(condata);
            });
          }
          else {
            this.SelectTeamName = "";
            this.ContractDataSource = null;
          }
        }
      );
    }
    else {
      this.SetServ.getTeamandTeamUsersCount_ForNonHOP(this.OrgID, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
        this.teamManageDataSource = new MatTableDataSource(data);
        if (data.length > 0) {
          this.SelectTeamName = data[0].teamName;
          sessionStorage.setItem("TeamID", data[0].TeamId);
          //this.SetServ.GetTeamDataOrganigataion(data[0].TeamId,this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
          this.SetServ.GetTeamUsersbyTeamID(data[0].TeamId).subscribe((condata: any) => {
            // console.log(condata);
            this.ContractDataSource = new MatTableDataSource(condata);
          });
        }
        else {
          this.SelectTeamName = "";
          this.ContractDataSource = null;
        }
      });
    }

    //  this.SetServ.GetTeamMemCount(this.OrgID,sessionStorage.getItem("ContactID")).subscribe(
    //   (data :any)=>
    //   {
    //     //
    //    this.teamManageDataSource = new MatTableDataSource(data);
    //    if(data.length>0)
    //    {
    //     // alert("dfgdf");
    //      this.SetServ.GetTeamDataOrganigataion(data[0].TeamId,this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
    //       {
    //        // console.log(condata);
    //       this.ContractDataSource=new MatTableDataSource(condata);
    //      });
    //    }
    //   }
    // );

  }
  checktabchanges() {

    if (this.tabchange) {
      //this.OpenDialogforDetailsChange();
      if (this.FinancialYearStarts != this.SystemSettings.get("ddlFinancialYearStarts").value || this.DateFormat != this.SystemSettings.get("Dateformat").value ||
        this.Currency != this.SystemSettings.get("ddlCurrency").value || this.PasswordExpiry != this.SystemSettings.get("PasswordExpiry").value ||
        this.PrimaryColour != this.SystemSettings.get("ddlPrimaryColour").value || this.ProjectApproval != this.SystemSettings.get("ddlProjectApproval").value || this.ProjectEnforceClosure != this.SystemSettings.get("ProjectEnforceClosure").value ||
        this.IsBringForwardPreviousYearsSavings != this.SystemSettings.get("IsBringForwardPreviousYearsSavings").value ||
        this.BringNumberOfPreviousYearsSavings != this.SystemSettings.get("BringNumberOfPreviousYearsSavings").value ||
        this.MileStoneTolerancelevel != this.SystemSettings.get("ddlMilestoneToleranceLevel").value || this.ProjectPlanTemplate != this.SystemSettings.get("ProjectPlanTemplate").value ||
        this.ActualAnnualContractValue != this.SystemSettings.get("Financial").value || this.ChangeLogoFile != null || this.EnableLondonLivingAllowance != this.SystemSettings.get("EnableLondonLivingAllowance").value ||
        this.SimpleSubType != this.SystemSettings.get("ddlSimpleSubType").value) {

        this.OpenDialogforDetailsChange();
      }
      else {
        this.tabchange = false;
        this.isDisabledContent = true;
      }

    }
    if (this.tabchangeNotofications) {
      this.OpenDialogforNotificationChange();
    }
    if (this.tabmanageTeam) {
      this.OpenDialogforManageTeams();
    }
  }

  OpenDialogforManageTeams() {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "ManageTeams" },
      width: '640px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.UpdateTeamName();
      }
      else {
        this.Revert("ManageList");
      }
    });
  }


  OpenDialogforDetailsChange() {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "SystemSettings" },
      width: '640px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.SaveSystemSettings();
      }
      else {
        this.Revert("Settings");
      }
    });
  }

  StatusUpdate() {
    this.NotificationChnage = true;
    this.onNotificationsChanges();
  }
  onNotificationsChanges(): void {


    //const changes = this.differ.find(this.Notifications);
    if (this.NotificationChnage) {

      this.tabchangeNotofications = true;
      this.isDisabledContent = false;
    }

    // this.Notifications.valueChanges.subscribe(val=>
    //   {
    //     this.tabchangeNotofications =true;
    //     this.isDisabledContent = false;
    //   });
  }
  onManageTeamChanges(): void {

    this.TeamForm.get("TeamName").valueChanges.subscribe(val => {
      alert("ManageTeam");
      this.tabmanageTeam = true;
      this.isDisabledContent = false;
    });
  }

  ShoworHideManageTeamsTab() {
    this.ShowMD = false;
    this.ShowMT = true;
    if (sessionStorage.getItem("usertype") != "Head of Procurement") {
      this.ShowAddOrganisation = false;
    }
    else {
      this.ShowAddOrganisation = true;
    }

    this.SetServ.GetTeamMemCount(this.OrgID, sessionStorage.getItem("ContactID")).subscribe(
      (data: any) => {
        this.teamManageDataSource = new MatTableDataSource(data);
        if (data.length > 0) {
          // alert("dfgdf");
          this.SelectTeamName = data[0].teamName;
          sessionStorage.setItem("TeamID", data[0].TeamId);
          this.SetServ.GetTeamUsersbyTeamID(data[0].TeamId).subscribe((condata: any) =>
          //this.SetServ.GetTeamDataOrganigataion(data[0].TeamId,this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
          {
            // console.log(condata);
            this.ContractDataSource = new MatTableDataSource(condata);
          });
        }
      }
    );
  }

  onTeamnameChange(event) {
    this.tabmanageTeam = true;
    this.isDisabledContent = false;
  }

  OpenDialogforNotificationChange() {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "Notifications" },
      width: '640px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.SaveNotifications(this.Notifications);
      }
      else {
        this.Revert("Notifications");
      }
    });
  }

  onChanges(): void {

    this.SystemSettings.get("ddlFinancialYearStarts").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("Dateformat").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("ddlCurrency").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("PasswordExpiry").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("ddlPrimaryColour").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("ddlProjectApproval").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("ddlMilestoneToleranceLevel").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("ProjectPlanTemplate").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("Financial").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("ProjectEnforceClosure").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("IsBringForwardPreviousYearsSavings").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("BringNumberOfPreviousYearsSavings").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });

    this.SystemSettings.get("EnableLondonLivingAllowance").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.SystemSettings.get("ddlSimpleSubType").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });

  }

  Revert(tab: string) {
    //this.router.navigate(["/dashboard/projects/database"]);
    if (tab == "Settings") {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/settings']);
    }
    else if (tab == "Notifications") {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/settings'], { queryParams: { Move: '3' } });
    }
    else if (tab == "ManageList") {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/settings'], { queryParams: { Move: '1' } });
    }
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/settings']);
    }

  }

  public onselected(event) {
    this.ShowMT = true;
    //this.ShowAddOrganisation=true;
    this.ShowMD = false;
    if (this.tabchange) {

      //this.router.navigate(['/settings']);
      this.isDisabledContent = false;
      this.tabchangeNotofications = false;
      this.tabmanageTeam = false;
      if (event.index != 0) {
        this.selectedIndex = 0;
        //this.OpenDialogforDetailsChange();
        this.SaveSystemSettings();
      }
      else {
        this.change.detach();

      }
    }
    else if (this.tabchangeNotofications) {
      //this.router.navigate(['/settings'], { queryParams: {Move:'3'}});
      this.isDisabledContent = false;
      this.tabchange = false;
      this.tabmanageTeam = false;
      if (event.index != 3) {
        this.selectedIndex = 3;
        this.OpenDialogforNotificationChange();
        //this.OpenDialogforMileStoneChange();
      }

      else {
        this.change.detach();

      }
    }
    else if (this.tabmanageTeam) {
      //this.router.navigate(['/settings'], { queryParams: {Move:'1'}});
      this.isDisabledContent = false;
      this.tabchange = false;
      this.tabchangeNotofications = false;
      if (event.index != 1) {
        this.selectedIndex = 1;
        this.OpenDialogforManageTeams();
        //this.OpenDialogforMileStoneChange();
      }

      else {
        this.change.detach();

      }
    }
    else {

      //this.router.navigate(['/settings']);
      if (event.index == 3)
      //if(event.index == 2)
      {

        this.onNotificationsChanges();
        this.tabchangeNotofications = false;
        //this.showMilestoneActions =false;
      }
      // else if(event.index ==1)
      // {
      //   this.onManageTeamChanges();
      //   this.tabmanageTeam=false;
      // }
    }
  }

  LoadJson(TeamID: any, name: any) {
    // alert(name);

    sessionStorage.setItem("TeamID", TeamID);
    sessionStorage.setItem("name", name);
    this.TeamForm.get("TeamName").setValue(name);
    //alert(this.TeamForm.get("TeamName").value);
    this.TeamName = name;
    this.ShowMT = false;
    this.ShowMD = true;
    this.ShowAddOrganisation = false;

    // if(this.ShowMD)
    // {
    //   this.onManageTeamChanges();
    //   this.tabmanageTeam=false;
    // }
    //this.SetServ.GetTeamDataOrganigataion(TeamID,this.OrgID,1,sessionStorage.getItem("ContactID")).subscribe(
    this.SetServ.GetHOPUsersbyTeamID(this.OrgID, TeamID).subscribe(
      (data: any) => {
        this.contractOfficersDataSource = new MatTableDataSource(data);

      }
    );

    //this.SetServ.GetTeamDataOrganigataion(TeamID,this.OrgID,2,sessionStorage.getItem("ContactID")).subscribe(
    this.SetServ.GetTeamUsersbyUserType(TeamID, 2).subscribe(
      (data: any) => {


        this.teamsDataSource = new MatTableDataSource(data);


      }
    );
    //this.SetServ.GetTeamDataOrganigataion(TeamID,this.OrgID,3,sessionStorage.getItem("ContactID")).subscribe(
    this.SetServ.GetTeamUsersbyUserType(TeamID, 3).subscribe(
      (data: any) => {

        this.ProcurementDatasource = new MatTableDataSource(data);


      }
    );

  }
  openDailogNewTeam() {
    //alert(this.OrgID);
    if (this.OrgID != 0) {
      const dialogRef = this.dialog.open(CreateTeamDepartmentComponent, {
        data: { OrgID: this.OrgID },
        width: '630px', height: '630px'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        let key = 'Item1';
        if (sessionStorage.getItem("usertype") == "Head of Procurement") {
          this.SetServ.GetTeamMemCount(this.OrgID, sessionStorage.getItem("ContactID")).subscribe(
            (data: any) => {

              this.teamManageDataSource = new MatTableDataSource(data);
            });
        }
        else {
          this.SetServ.getTeamandTeamUsersCount_ForNonHOP(this.OrgID, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
            this.teamManageDataSource = new MatTableDataSource(data);
          });
        }
        // this.SetServ.GetTeamMemCount(this.OrgID,sessionStorage.getItem("ContactID")).subscribe(
        //   (data :any)=>
        //   {
        //     this.teamManageDataSource = new MatTableDataSource(data);

        //   }
        // );
      });
    }
    else {
      alert("Please select organisation");
    }

  }

  openDialogAddUser() {
    const dialogRef = this.dialog.open(AdduserComponent, {
      data: { OrgID: this.OrgID, TeamID: sessionStorage.getItem("TeamID") },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      let key = 'Item1';
      this.SetServ.GetTeamUsersbyTeamID(Number(sessionStorage.getItem("TeamID"))).subscribe((condata: any) => {
        this.ContractDataSource = new MatTableDataSource(condata);
      });


      this.SetServ.GetHOPUsersbyTeamID(this.OrgID, Number(sessionStorage.getItem("TeamID"))).subscribe(
        (data: any) => {
          this.contractOfficersDataSource = new MatTableDataSource(data);
        }
      );
      this.SetServ.GetTeamUsersbyUserType(Number(sessionStorage.getItem("TeamID")), 2).subscribe(
        (data: any) => {
          this.teamsDataSource = new MatTableDataSource(data);
        }
      );
      this.SetServ.GetTeamUsersbyUserType(Number(sessionStorage.getItem("TeamID")), 3).subscribe(
        (data: any) => {
          this.ProcurementDatasource = new MatTableDataSource(data);
        }
      );
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      let key = 'Item1';
    });
  }

  openDialogAdvanceSearch() {
    // if(this.OrgID !=0)
    // {
    const dialogRef = this.dialog.open(InvitesuserComponent, {
      data: { OrgID: this.OrgID, TeamID: sessionStorage.getItem("TeamID") },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      let key = 'Item1';
      //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
      this.SetServ.GetTeamUsersbyTeamID(Number(sessionStorage.getItem("TeamID"))).subscribe((condata: any) => {
        // console.log(condata);
        this.ContractDataSource = new MatTableDataSource(condata);
      });

      //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,1,sessionStorage.getItem("ContactID")).subscribe(
      this.SetServ.GetHOPUsersbyTeamID(this.OrgID, Number(sessionStorage.getItem("TeamID"))).subscribe(
        (data: any) => {
          this.contractOfficersDataSource = new MatTableDataSource(data);

        }
      );
      //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,2,sessionStorage.getItem("ContactID")).subscribe(
      this.SetServ.GetTeamUsersbyUserType(Number(sessionStorage.getItem("TeamID")), 2).subscribe(
        (data: any) => {
          this.teamsDataSource = new MatTableDataSource(data);
        }
      );
      //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,3,sessionStorage.getItem("ContactID")).subscribe(
      this.SetServ.GetTeamUsersbyUserType(Number(sessionStorage.getItem("TeamID")), 3).subscribe(
        (data: any) => {
          this.ProcurementDatasource = new MatTableDataSource(data);
        }
      );
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      let key = 'Item1';
    });
  }


  openDialogSetTargets(UserID: any) {

    const dialogRef = this.dialog.open(DialogEstimatedSavingsTargetComponent, {
      data: { ContactID: UserID, OrgID: this.OrgID, TeamID: sessionStorage.getItem("TeamID") },
      width: '730px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogEdituser(Id: any, email: string) {


    //alert(sessionStorage.getItem("TeamID"));
    const dialogRef = this.dialog.open(EdituserComponent, {
      data: { ContactID: Id, OrgID: this.OrgID, TeamID: sessionStorage.getItem("TeamID"), Email: email },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      // this.SetServ.GetTeamMemCount(this.OrgID,sessionStorage.getItem("ContactID")).subscribe(
      //   (data :any)=>
      //   {

      //     this.teamManageDataSource = new MatTableDataSource(data);
      //   });
      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        this.SetServ.GetTeamMemCount(this.OrgID, sessionStorage.getItem("ContactID")).subscribe(
          (data: any) => {
            //alert("Hi");
            this.teamManageDataSource = new MatTableDataSource(data);
          });
      }
      else {
        this.SetServ.getTeamandTeamUsersCount_ForNonHOP(this.OrgID, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
          this.teamManageDataSource = new MatTableDataSource(data);
        });
      }
      //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
      this.SetServ.GetTeamUsersbyTeamID(Number(sessionStorage.getItem("TeamID"))).subscribe((condata: any) => {
        // console.log(condata);
        this.ContractDataSource = new MatTableDataSource(condata);
      });

      this.SetServ.GetTeamUsersbyUserType(Number(sessionStorage.getItem("TeamID")), 2).subscribe(
        (data: any) => {
          this.teamsDataSource = new MatTableDataSource(data);
        }
      );
      //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,3,sessionStorage.getItem("ContactID")).subscribe(
      this.SetServ.GetTeamUsersbyUserType(Number(sessionStorage.getItem("TeamID")), 3).subscribe(
        (data: any) => {
          this.ProcurementDatasource = new MatTableDataSource(data);
        }
      );

      this.SetServ.GetHOPUsersbyTeamID(this.OrgID, Number(sessionStorage.getItem("TeamID"))).subscribe(
        (data: any) => {
          this.contractOfficersDataSource = new MatTableDataSource(data);

        }
      );
      //alert(sessionStorage.getItem("TeamID")); ///added by zuber on 23/12/2019
      // this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,1).subscribe(
      //   ( data:any)=>
      //   {
      //     this.contractOfficersDataSource = new MatTableDataSource(data);

      //   }
      // );
      //----------------------------------------------------------
      // this.SetServ.GetTeamMemCount(this.OrgID,sessionStorage.getItem("ContactID")).subscribe(
      //   (data :any)=>
      //   {

      //     this.teamManageDataSource = new MatTableDataSource(data);
      //   });

      //   //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
      //   this.SetServ.GetTeamUsersbyTeamID(Number(sessionStorage.getItem("TeamID"))).subscribe((condata:any) =>
      //   {
      //   // console.log(condata);
      //   this.ContractDataSource=new MatTableDataSource(condata);
      //   });
      // this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,2).subscribe(
      //   (data :any)=>
      //   {
      //     this.teamsDataSource = new MatTableDataSource(data);
      //   }
      // );
      // this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,3).subscribe(
      //   (data :any)=>
      //     {
      //       this.ProcurementDatasource = new MatTableDataSource(data);
      //     }
      // );
      //this.ShowMT=true;
      //this.ShowMD=false;

    });

  }

  get C() { return this.Logoupload.controls; };
  get g() { return this.TeamForm.controls };
  GetSystemSettings() {
    //alert( sessionStorage.getItem("ContactID"));
    //this.SetServ.getSystemSettings(sessionStorage.getItem("ContactID")).subscribe(
    this.SetServ.getSystemSettingswithoutcontactID().subscribe(
      (data: any) => {
        console.log(data);
        this.systemsettingdata = data;
        if (this.systemsettingdata != null) {
          this.SystemSettings.get("ddlFinancialYearStarts").setValue(this.systemsettingdata.StartYear);
          this.SystemSettings.get("ddlCurrency").setValue(this.systemsettingdata.Currency);
          this.SystemSettings.get("ddlMilestoneToleranceLevel").setValue(this.systemsettingdata.MilestoneLevel);
          this.SystemSettings.get("Dateformat").setValue(this.systemsettingdata.DateFormat);
          this.SystemSettings.get("ddlProjectApproval").setValue(this.systemsettingdata.ProjectApproval);
          this.SystemSettings.get("ddlPrimaryColour").setValue(this.systemsettingdata.PrimaryColor);
          this.SystemSettings.get("PasswordExpiry").setValue(this.systemsettingdata.PasswordExpiry);
          this.SystemSettings.get("ProjectPlanTemplate").setValue(this.systemsettingdata.ProjectPlanTemplate);
          this.SystemSettings.get("Financial").setValue(this.systemsettingdata.Financial);
          this.SystemSettings.get("ProjectEnforceClosure").setValue(this.systemsettingdata.ProjectEnforceClosure);
          this.SystemSettings.get("IsBringForwardPreviousYearsSavings").setValue(this.systemsettingdata.IsBringForwardPreviousYearsSavings);
          this.SystemSettings.get("BringNumberOfPreviousYearsSavings").setValue(this.systemsettingdata.BringNumberOfPreviousYearsSavings);
          this.SystemSettings.get("EnableLondonLivingAllowance").setValue(this.systemsettingdata.EnableLondonLivingAllowance);
          this.SystemSettings.get("ddlSimpleSubType").setValue(this.systemsettingdata.SimpleSubType);
          this.url = this.logservice.ServiceIP + this.systemsettingdata.LogoPath;
          //console.log(this.url);
          this.FinancialYearStarts = this.systemsettingdata.StartYear;
          this.Currency = this.systemsettingdata.Currency;
          this.MileStoneTolerancelevel = this.systemsettingdata.MilestoneLevel;
          this.DateFormat = this.systemsettingdata.DateFormat;
          this.ProjectApproval = this.systemsettingdata.ProjectApproval;
          this.PrimaryColour = this.systemsettingdata.PrimaryColor;
          this.IsPrimaryColourUser = this.systemsettingdata.IsPrimaryColorUsed;
          this.PasswordExpiry = this.systemsettingdata.PasswordExpiry;
          this.ProjectPlanTemplate = this.systemsettingdata.ProjectPlanTemplate;
          this.ActualAnnualContractValue = this.systemsettingdata.Financial;
          this.IsFinancialYearStartsused = this.systemsettingdata.IsFinancialYearUsed;
          this.IsDateFormatUsed = this.systemsettingdata.IsDateFormatUsed;
          this.IsCurrencyUsed = this.systemsettingdata.IsCurrencyUsed;
          this.IsProjectApprovalUsed = this.systemsettingdata.IsProjectApprovalUsed;
          this.IsProjectPlanTemplateUsed = this.systemsettingdata.IsProjectPlanTemplateUsed;
          this.ProjectEnforceClosure = this.systemsettingdata.ProjectEnforceClosure;
          this.IsBringForwardPreviousYearsSavings = this.systemsettingdata.IsBringForwardPreviousYearsSavings;
          this.BringNumberOfPreviousYearsSavings = this.systemsettingdata.BringNumberOfPreviousYearsSavings;
          this.EnableLondonLivingAllowance = this.systemsettingdata.EnableLondonLivingAllowance;
          this.IsFinancialUsed = this.systemsettingdata.IsFinancialUsed;
          this.SimpleSubType = this.systemsettingdata.SimpleSubType;
        }
        else {
          this.SystemSettings.get("ddlFinancialYearStarts").setValue("April");
          this.SystemSettings.get("ddlCurrency").setValue("gbp");
          this.SystemSettings.get("ddlMilestoneToleranceLevel").setValue("5");
          this.SystemSettings.get("Dateformat").setValue("ddmmyyyy");
          this.SystemSettings.get("ddlProjectApproval").setValue("yes");
          this.SystemSettings.get("ddlPrimaryColour").setValue("navy");
          this.SystemSettings.get("PasswordExpiry").setValue("90");
          this.SystemSettings.get("ProjectPlanTemplate").setValue("Yes");
          this.SystemSettings.get("Financial").setValue("Yes");
          this.SystemSettings.get("ProjectEnforceClosure").setValue("yes");
          this.SystemSettings.get("EnableLondonLivingAllowance").setValue("yes");
          this.SystemSettings.get("ddlSimpleSubType").setValue("no");
        }

        if (this.selectedIndex == undefined) {
          this.onChanges();
          this.tabchange = false;
          //this.showSave =false;
        }
      });

  }
  url: any;
  onFileChange(event): void {
    //alert("dsf");
    if (event.target.files.length > 0) {
      this.tabchange = true;
      this.isDisabledContent = false;
      const file = event.target.files[0];
      // this.UploadContract.get('Paper_FileName').setValue(file);
      this.LogoFile = file;
      this.ChangeLogoFile = file;
      // this.url=file.filepath;
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }

    }
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  firstComponentFunction() {
    this.eventEmitterService.onFirstComponentButtonClick();
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  SaveSystemSettings() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.SystemSettings.invalid) {
      return;
    }

    const formdata = new FormData();
    formdata.append('StartYear', this.SystemSettings.get("ddlFinancialYearStarts").value);
    formdata.append('Currency', this.SystemSettings.get("ddlCurrency").value);
    formdata.append('MilestoneLevel', this.SystemSettings.get("ddlMilestoneToleranceLevel").value);
    formdata.append('DateFormat', this.SystemSettings.get("Dateformat").value);
    formdata.append('ProjectApproval', this.SystemSettings.get("ddlProjectApproval").value);
    formdata.append('PrimaryColor', this.SystemSettings.get("ddlPrimaryColour").value);
    formdata.append('ContactID', sessionStorage.getItem("ContactID"));
    formdata.append('LogoPath', this.LogoFile);
    formdata.append('PasswordExpiry', this.SystemSettings.get("PasswordExpiry").value);
    formdata.append('ProjectPlanTemplate', this.SystemSettings.get("ProjectPlanTemplate").value);
    formdata.append('Financial', this.SystemSettings.get("Financial").value);
    formdata.append('ProjectEnforceClosure', this.SystemSettings.get("ProjectEnforceClosure").value);
    formdata.append('IsBringForwardPreviousYearsSavings', this.SystemSettings.get("IsBringForwardPreviousYearsSavings").value);
    formdata.append('BringNumberOfPreviousYearsSavings', this.SystemSettings.get("BringNumberOfPreviousYearsSavings").value);
    formdata.append('EnableLondonLivingAllowance', this.SystemSettings.get("EnableLondonLivingAllowance").value);
    formdata.append('SimpleSubType', this.SystemSettings.get("ddlSimpleSubType").value);


    this.SetServ.UpdateSystemSettings(formdata).subscribe((data: any) => {

      if (data.RetStatus == "Updated") {
        if (this.url != null) {
          this.getBase64ImageFromURL(this.url).subscribe(base64data => {
            sessionStorage.setItem("CompanyLogo", 'data:image/jpg;base64,' + base64data);
          });
        }


        this.firstComponentFunction();
        //this.HeaderComp.getCompanyLogo();
        alert("System Settings saved successfully !");

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/settings'], { queryParams: { Reload: 'true' } });


      }
      else {
        alert("Something went wrong !");

      }
    });


  }
  GetNotifications() {
    this.SetServ.GetNotifications().subscribe((data: any) => {
      this.Notifications = data.value;
    }
    );
  }
  //Manage list

  // openDialogEditList() {
  //   const dialogRef = this.dialog.open(EditfinanciallistComponent, {
  //       width: '730px'
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //       console.log(`Dialog result: ${result}`);
  //   });
  // }

  // opencategoryEditList(listtype:string) {

  //   if(listtype=="Financial Year")
  //   {
  //     this.openDialogEditList();
  //   }
  //   else if(listtype=="Category Type")
  //   {
  //   const dialogRef = this.dialog.open(EditcategorylistComponent, {
  //         width: '730px'
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //         console.log(`Dialog result: ${result}`);
  //     });
  //   }

  //   }

  async SaveNotifications(NS: any) {

    let cnt = 0;
    let showalert = true;
    let modifiedstatus = false;
    for (let i = 0; i < NS.length; i++) {
      modifiedstatus = false;
      const formdata = new FormData();
      formdata.append('ID', NS[i].id);
      formdata.append('Active', NS[i].Active);
      formdata.append('ContactID', sessionStorage.getItem("ContactID"))

      this.SetServ.SaveNotifications(formdata).subscribe(
        (data: any) => {
          if (data.RetStatus == "Updated") {
            cnt = cnt + 1;
            if (cnt > 0 && showalert == true) {
              alert("Saved Successfully");
              showalert = false;
              this.Revert("Notifications");
            }
          }
        }
      );
    }
  }


  opencategoryEditList(listtype: string) {

    if (listtype == "Financial Year") {
      const dialogRef = this.dialog.open(EditfinanciallistComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }

    else if (listtype == "Category") {
      const dialogRef = this.dialog.open(EditcategorylistComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "Priority") {
      const dialogRef = this.dialog.open(EditprojectpriorityComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "Project Status") {
      const dialogRef = this.dialog.open(EditprojectstatusComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "Project Milestones") {
      const dialogRef = this.dialog.open(EditprojectmilestoneComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "Project Progress") {
      const dialogRef = this.dialog.open(EditprojectprogressComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "Project Risks") {
      const dialogRef = this.dialog.open(EditprojectrisksComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    //   else if(listtype=="Extension Period")
    // {
    // const dialogRef = this.dialog.open(EditextensionperiodComponent, {
    //       width: '730px'
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //       console.log(`Dialog result: ${result}`);
    //   });
    // }
    else if (listtype == "Contract Type") {
      const dialogRef = this.dialog.open(EditcontracttypeComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "Contract Status") {
      const dialogRef = this.dialog.open(EditcontractstatusComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "GDPR") {
      const dialogRef = this.dialog.open(EditcontractGDPRComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (listtype == "Sub Category") {
      const dialogRef = this.dialog.open(EditsubcategoryComponent, {
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }
  IsUsed(For: string) {
    if (For == "FinancialYear") {
      if (this.IsFinancialYearStartsused == "Used") {
        alert("You cannot change this value since transactions are already done with current financial year setup");
        this.SystemSettings.get("ddlFinancialYearStarts").setValue(this.FinancialYearStarts);
      }
    }
    else if (For == "DateFormat") {
      if (this.IsDateFormatUsed == "Used") {
        alert("You cannot change this value since transactions are already done with current date format setup");
        this.SystemSettings.get("Dateformat").setValue(this.DateFormat);
      }
    }
    else if (For == "Currency") {
      if (this.IsCurrencyUsed == "Used") {
        alert("You cannot change this value since transactions are already done with current currency setup");
        this.SystemSettings.get("ddlCurrency").setValue(this.Currency);
      }
    }
    else if (For == "Colour") {
      if (this.IsPrimaryColourUser == "Used") {
        alert("You cannot change this value since the portal is already setup with this scheme");
        this.SystemSettings.get("ddlPrimaryColour").setValue(this.PrimaryColour);
      }
    }
    else if (For == "ProjectPlanTemplate") {
      if (this.IsProjectPlanTemplateUsed == "Used") {
        alert("You cannot change this value since transactions are already done with current project plan template setup");
        this.SystemSettings.get("ProjectPlanTemplate").setValue(this.ProjectPlanTemplate);
      }
    }
    // else if(For =="FinancialUsed")
    // {
    //   if(this.IsFinancialUsed =="Used")
    //   {
    //     alert("You cannot change this value since transactions are already done with current Financials - Actual Annual Contract Value & Savings setup");
    //     this.SystemSettings.get("Financial").setValue(this.ActualAnnualContractValue);
    //   }
    // }
    else {
      if (this.IsProjectApprovalUsed == "Used") {
        alert("You cannot change this value since transactions are already done with current project approval setup");
        this.SystemSettings.get("ddlProjectApproval").setValue(this.ProjectApproval);
      }
    }

  }

  OpenDialogforUploadData(FileName: string, For: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: For },
      width: '640px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.SetServ.getErrorDocument(FileName);
      }
      else {

        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate(['settings']);
      }
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/settings'], { queryParams: { ShowDataImport: 'true' } });
    });

  }

  // DownFile()
  // {
  //   this.SetServ.getErrorDocument("ErrorFile_iProcure Projects Upload Spreadsheet.xlsx");
  // }
  onContractUploadFileChange(event): void {
    if (event.target.files.length > 0) {
      this.loading = true;
      const file = event.target.files[0];
      if (event.target.files[0].name.split(".").pop() == "xlsx" || event.target.files[0].name.split(".").pop() == "xls") {
        const formdata = new FormData();
        formdata.append('UploadedFilename', file);
        formdata.append('ProjectOwnerID', sessionStorage.getItem("ContactID"));
        this.SetServ.ContractDataUploadFromExcel(formdata).subscribe((data: any) => {
          if (data.Retval == "Updated") {
            if (data.ErrorCount > 0) {
              this.OpenDialogforUploadData(data.ErrorFile, "ContractDataUpload");
            }
            else {
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/settings'], { queryParams: { ShowDataImport: 'true' } });
              alert("Data imported successfully");
            }
          }
          else {
            alert(data.Retval);
          }
          this.loading = false;
        }, (error) => {
          this.loading = false;
        });
      }
      else {
        alert("Please Upload a excel file.");
      }
    }
  }
  onUploadFileChange(event): void {
    if (event.target.files.length > 0) {
      this.loading = true;
      const file = event.target.files[0];
      console.log(event.target.files[0].name.split(".").pop());
      if (event.target.files[0].name.split(".").pop() == "xlsx" || event.target.files[0].name.split(".").pop() == "xls") {
        //this.UploadedProjectDataFile=file;
        const formdata = new FormData();
        formdata.append('UploadedFilename', file);
        formdata.append('ProjectOwnerID', sessionStorage.getItem("ContactID"));
        this.SetServ.UploadProjectDataFromExcel(formdata).subscribe((data: any) => {
          if (data.Retval == "Updated") {
            if (data.ErrorCount > 0) {
              this.OpenDialogforUploadData(data.ErrorFile, "ProjectDataUpload");
            }
            else {
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/settings'], { queryParams: { ShowDataImport: 'true' } });
              alert("Data imported successfully");
            }
          }
          else {
            alert(data.Retval);
          }
          this.loading = false;
        }, (error) => {
          this.loading = false;
        });
      }
      else {
        alert("Please Upload a excel file.");
      }
    }
  }
  Showalert() {
    alert('Template downloaded successfully. Please add data and upload');
  }
  OpenDialogAllocateUser(ContactID: number) {
    const dialogRef = this.dialog.open(AllocatenewteamComponent, {
      data: { ContactID: ContactID, OrgID: this.OrgID, TeamID: sessionStorage.getItem("TeamID") },
      width: '730px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        this.SetServ.GetTeamMemCount(this.OrgID, sessionStorage.getItem("ContactID")).subscribe(
          (data: any) => {

            this.teamManageDataSource = new MatTableDataSource(data);
          });
      }
      else {
        this.SetServ.getTeamandTeamUsersCount_ForNonHOP(this.OrgID, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
          this.teamManageDataSource = new MatTableDataSource(data);
        });
      }


      //this.SetServ.GetTeamDataOrganigataion(sessionStorage.getItem("TeamID"),this.OrgID,0,sessionStorage.getItem("ContactID")).subscribe((condata:any) =>
      this.SetServ.GetTeamUsersbyTeamID(Number(sessionStorage.getItem("TeamID"))).subscribe((condata: any) => {
        // console.log(condata);
        this.ContractDataSource = new MatTableDataSource(condata);
      });
    });
  }

  openDailogAddOrganisation() {
    const dialogRef = this.dialog.open(DialogAddNewOrganisationComponent, {
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result New Organisation: ${result}`);
      this.getOrganisation();
      this.dialog.closeAll();
    });
  }

  onBringPreviousYearsSavings(event) {

    if (event == 'Yes') {
      this.SystemSettings.get("BringNumberOfPreviousYearsSavings").enable();
      // this.disabledNumberOfPreviousYearsSavings=false;
    } else {
      // this.disabledNumberOfPreviousYearsSavings=true;
      this.SystemSettings.get("BringNumberOfPreviousYearsSavings").disable();
    }
  }

  impersonateUser(Id: any, email: string) {
    this.clearSession();

    this.logservice.getContactByEmail(email).subscribe((data: any) => {
      console.log(data);
      if (data.ContactEMailAddress) {
        sessionStorage.setItem('ContactID', data.PK_ContactID);
        sessionStorage.setItem('usertype', data.UserType);
        sessionStorage.setItem('AdminPermission', data.AdminPerMission);
        sessionStorage.setItem('userMail', data.ContactEMailAddress);
        if (data.IsSuperUser) {
          sessionStorage.setItem('IsSuperUser', data.IsSuperUser);
        }

        if (sessionStorage.getItem("ApproveProject") != null) {
          this.router.navigateByUrl(sessionStorage.getItem("ApproveProject"));
        }
        else {
          if (this.domain.toLowerCase() == 'onesource') {
            this.router.navigate(['/dashboard/performanceonesource']);

          } else {
            if (data.AdminPermission == "Yes") {
              this.router.navigate(['/dashboard/performance']);
            }
            else {
              this.router.navigate(['/dashboard/performance']);
            }

          }
        }
      }
    });

  }

  clearSession() {
    sessionStorage.removeItem('ContactID');
    sessionStorage.removeItem('usertype');
    sessionStorage.removeItem('AdminPermission');
    sessionStorage.removeItem('firsttime');
    sessionStorage.removeItem("ApproveProject");
    localStorage.removeItem('register');
    localStorage.removeItem('organisation');
    localStorage.removeItem('team');
    localStorage.removeItem('inviteuser');
    localStorage.removeItem('inviteClientAdmin');
    sessionStorage.removeItem("AllowcationList");
    sessionStorage.removeItem("ProjectData");
    sessionStorage.removeItem("ProjectFinanceData");
    sessionStorage.removeItem("IsSuperUser");
    sessionStorage.removeItem('userMail');
    localStorage.removeItem("rememberemail");
  }

} 
