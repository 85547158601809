import { Component, OnInit, TestabilityRegistry, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardContractsNewContractService } from './dashboard-contracts-new-contract.service'
import { DatePipe } from "@angular/common"
import { ProjectDatabaseService } from '../project-database.service';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { async } from '@angular/core/testing';
import { AlertComponent } from '../../alert/alert.component';
import { DialogAddNewProjectfinancialsComponent } from 'src/app/dialogs/dialog-add-new-projectfinancials/dialog-add-new-projectfinancials.component';
import { MatTableDataSource } from '@angular/material/table';
import { DialogAddNewSupplierComponent } from 'src/app/dialogs/dialog-add-new-supplier/dialog-add-new-supplier.component';
import { DashboradContractsService } from '../dashboard-contracts/dashborad-contracts.service';
import { SettingsService } from '../../settings/settings.service';



@Component({
  selector: 'app-dashboard-contracts-new-contract',
  templateUrl: './dashboard-contracts-new-contract.component.html',
  styleUrls: ['./dashboard-contracts-new-contract.component.scss']
})
export class DashboardContractsNewContractComponent implements OnInit {
  newcontract: FormGroup;
  Organisation: any;
  ExtentionPeriod: any;
  Category: any;
  SubCategory: any;
  public ContractType = [];
  Status: any;
  submitted = false;
  isDisabledContent: boolean = true;
  selectedtabIndex: any;
  tabchange: boolean = false;
  tabsave: boolean = true;
  showSaveButton = false;
  Suppliers: any;
  txtRegNumber: any;
  //
  findata: string = "";
  SupplierData: string = "";
  detailsdata: boolean = false;
  projectedSpend: any = "0";
  projectedSavings: any = "0";
  DAtes: any;
  //
  //filteredOptions: Observable<User[]>;

  keyword = 'Abbreviation';
  //testds : any ='test';
  SubCategorySelected: any = "";
  /* data_s = [
      {
        id: 1,
        name: 'Usa'
      },
      {
        id: 2,
        name: 'England'
      }
   ];*/
  //
  contractDocumentsDataSource: any;
  supportingDocumentsDataSource: any;
  suppliersDocumentsDataSouce: any;
  yearlyFinancialsDataSource: any;
  ddlSupplierName: any;
  yearlyFinancialsDisplayedColumns = ['financialYear', 'projectedSpend', 'actualSpend', 'projectedSavings', 'actualSavings'];
  //yearlyFinancialsDataSource = new MatTableDataSource(YEARLY_FINANCIALS_DATA);
  contractDocumentsDisplayedColumns = ['DocumentName', 'CreatedDate', 'PK_DocumentID'];
  //contractDocumentsDataSource = new MatTableDataSource(CONTRACT_DOCUMENTS_DATA);
  supportingDocumentsDisplayedColumns = ['DocumentName', 'CreatedDate', 'PK_DocumentID'];
  // supportingDocumentsDataSource =new MatTableDataSource(SUPPORTING_DOCUMENTS_DATA);
  extensionHistoryDisplayedColumns = ['ExtendedDate', 'ExtendedPeiod', 'RemainingExtension'];
  //extensionHistoryDataSourceextensionHistoryDataSource = new MatTableDataSource(EXTENSION_HISTORY_DATA);
  extensionHistoryDataSource: any;
  suppliersDocumentsDisplayColumns = ['DocumentName', 'CreatedDate', 'ExpiryDate', 'PK_DocumentID'];
  // suppliersDocumentsDataSouce = new MatTableDataSource(SUPPLIERS_DOCUMENTS_DATA);
  Names: any;
  bLLAEnabled: boolean = false;
  bUseSimpleSubCategory: boolean = false;
  tabMileStonechange: boolean = false;
  tabSupplierchange: boolean = false;
  constructor(private change: ChangeDetectorRef, public dialog: MatDialog, private fb: FormBuilder, private router: Router, private route: ActivatedRoute,
    private Dservice: DashboardContractsNewContractService,
    private DbService: ProjectDatabaseService, private Cservice: DashboradContractsService, private setService: SettingsService) {
    this.LoadAllCaptions();
    this.isLLAEnabled();
    this.UseSimpleSubCategory();
    if (sessionStorage.getItem("ContactID") != null) {
      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        this.getOrganisation();
      }
      else {
        this.getOrganisationForNonHop();
      }
    }
  }

  ngOnInit() {
    //

    //
    this.newcontract = this.fb.group({
      ddlOrganistaion: [''],
      txtContractTitle: ['', Validators.required],
      txtContractReference: ['', Validators.required],
      StartDate: [''],
      txtDepartment: [''],
      EndDate: [''],
      ddlCategory: [''],
      //  ddlSubCategory :[''],
      ddlType: [''],
      ddlStatus: [''],
      OwnerFirstName: [''],
      OwnerLastName: [''],
      OwnerEmail: ['', [Validators.email]],
      //ProjectedLifetimeContractSpend : ['',Validators.required],
      //ProjectedAnnualisedContractSpend :['',Validators.required],
      //ProjectedLifetimeContractSavings :['',Validators.required],
      //ProjectedAnnualisedContractSavings :['',Validators.required],
      NoticePeriod: [''],
      ddlKPI: [''],
      Description: ['', Validators.required],
      ddlOneOfContract: [''],
      ddlExtensionAvailable: [''],
      ddlExtensionPeriod: ['', Validators.required],
      ddlPriority: [''],
      ddlGDPR: [''],
      ddlLLA: ['']

    });

    // convenience getter for easy access to form fields

    //
    //this.newcontract.get("ddlod").value;


    //this.getExtention();

    this.getContractTypes();
    this.getContractStatus();
    //this.newcontract.get("ddlExtensionPeriod").setValue("0");
    this.newcontract.get("ddlType").setValue("0");
    this.newcontract.get("ddlStatus").setValue("0");
    this.newcontract.get("ddlOneOfContract").setValue("0");
    this.newcontract.get("ddlExtensionAvailable").setValue("0");
    this.newcontract.get("ddlKPI").setValue("0");
    this.newcontract.get("ddlPriority").setValue("0");
    this.newcontract.get("ddlGDPR").setValue("0");
    this.newcontract.get("ddlLLA").setValue("no");
    this.getCategories();
    this.ddlSupplierName = "0";
    //this.getSubCategories();
    sessionStorage.setItem('FinancialdummyPrj', null);
    sessionStorage.setItem('FinancialdummySupplier', null);

  }


  get f() { return this.newcontract.controls; };



  selectEvent(item) {
    // do something with selected item
    //alert(item.PK_CategoryTypeID);
    this.SubCategorySelected = item.PK_CategoryTypeID
    //
    this.tabchange = true;
    this.isDisabledContent = false;
    //
    this.SubCategory = null;
  }



  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    //alert(val);
    if (val.length > 1) {
      this.getSubCategories(val);
    }
    else {
      //this.SubCategory = null;
    }
  }

  onFocused(e) {
    // do something when input is focused
  }

  ClearSubCategorySelection(e) {
    this.SubCategorySelected = "";
  }

  checktabchanges() {
   
    if (this.tabchange || this.showSaveButton || this.tabMileStonechange || this.tabSupplierchange) {
      //this.showSave =true;
     // this.showSaveButton = false;
      this.SaveContract();
    //  this.SaveNewContract();
    }
  }

  SaveContract() {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "NewContract" },
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        //alert("yes");
        this.SaveNewContract();
      }
      else {
        this.Cancel();
      }

    });


  }


  onChanges(): void {
    this.newcontract.get("ddlOrganistaion").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("txtContractTitle").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("StartDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlCategory").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("txtContractReference").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("Description").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("txtDepartment").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlExtensionAvailable").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlExtensionPeriod").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlPriority").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlGDPR").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlLLA").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlType").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("ddlStatus").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("OwnerFirstName").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("OwnerLastName").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.newcontract.get("OwnerEmail").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    
  }

  SelectedChange() {
    // this.ContractType = [];
    if (this.newcontract.get("ddlExtensionAvailable").value == "Yes") {
      this.newcontract.get("ddlExtensionPeriod").enable();
      this.newcontract.get("ddlExtensionPeriod").setValue("");


    }
    else if (this.newcontract.get("ddlExtensionAvailable").value == "No") {

      this.newcontract.get("ddlExtensionPeriod").disable();
      this.newcontract.get("ddlExtensionPeriod").setValue("0");

    }
    else {
      this.newcontract.get("ddlExtensionPeriod").enable();
      this.newcontract.get("ddlExtensionPeriod").setValue("0");
      //this.newcontract.get("ddlType").setValue("0");
    }

  }

  getOrganisation() {
    this.DbService.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      console.log(data);
      this.Organisation = data.value;
      this.newcontract.get("ddlOrganistaion").setValue(data.value[0].OrganisationID);

    });
  }

  getOrganisationForNonHop() {

    this.Dservice.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data;

      this.newcontract.get("ddlOrganistaion").setValue(data[0].OrganisationID);


    });
  }

  getExtention() {
    this.Dservice.getExtentionPeriod().subscribe((data: any) => {
      console.log(data);
      this.ExtentionPeriod = data.value;
    });

  }

  getCategories() {
    this.Dservice.getAllCategories().subscribe((data: any) => {
      //console.log(data);
      this.Category = data.value;
      this.newcontract.get("ddlCategory").setValue(data.value[0].PK_CategoryGroupID);
      //this.getSubCategories();
      this.onChanges();
      if (this.bUseSimpleSubCategory) {
        this.SubCategorySelected = data.value[0].PK_CategoryGroupID;
        this.onCategorySelected(data.value[0].PK_CategoryGroupID);
      }
    });

  }

  getSubCategories(searchtext: any) {

    this.Dservice.getAllSubCategories_Search(searchtext).subscribe((data: any) => {
      this.SubCategory = data;

    });


  }

  getContractTypes() {

    this.Dservice.getContractTypes().subscribe((data: any) => {

      let length = data.value.length;

      for (let i = 0; i < length; i++) {

        let jsonstring = '{"PK_ContractTypeID" :"' + data.value[i].PK_ContractTypeID + '","ContractTypeDescription" :"' + data.value[i].ContractTypeDescription + '"}';
        this.ContractType.push(JSON.parse(jsonstring));
      }
    });


  }

  getContractStatus() {
    //alert(this.CategoryId);
    this.Dservice.getContractStatus().subscribe((data: any) => {
      //console.log(data);
      this.Status = data.value;

    });


  }

  SaveNewContract() {

    if (this.newcontract.get("txtContractTitle").value == "") {
      alert("Please enter contract title !");
      return;
    }

    if (this.newcontract.get("Description").value == "") {
      alert("Please enter contract description !");
      return;
    }

    //for  validations......
    if (this.newcontract.get("ddlExtensionAvailable").value == "0") {
      alert("Please select extension available !");
      return;
    }
    //
    if (this.newcontract.get("ddlExtensionAvailable").value == "Yes") {
      if (this.newcontract.get("ddlExtensionPeriod").value == "0" || this.newcontract.get("ddlExtensionPeriod").value == "") {
        alert("Extension period cannot be zero or empty !");
        return;
      }
    }
    //
    var datepipe = new DatePipe('en-US');
    if (this.newcontract.get("StartDate").value == "") {
      alert("Please select start date !");
      return;
    }
    if (this.newcontract.get("EndDate").value == "") {
      alert("Please select end date!");
      return;
    }
    //
    if (this.newcontract.get("EndDate").value <= this.newcontract.get("StartDate").value) {
      alert("Start date cannot be greater than end date!");
      return;
    }

    if (this.SubCategorySelected == "") {
      alert("Please select subcategory !");
      return;
    }
    if (this.newcontract.get("txtContractReference").value == "") {
      alert("Please enter contract reference !");
      return;
    }
    if (this.newcontract.get("ddlType").value == "0") {
      alert("Please select type!");
      return;
    }
    if (this.newcontract.get("ddlStatus").value == "0") {
      alert("Please select status!");
      return;
    }
    if (this.newcontract.get("ddlPriority").value == "0") {
      alert("Please select priority!");
      return;
    }
    this.detailsdata = true;
    this.tabsave = false;
    if (this.findata == "" && this.SupplierData == "") {
      alert("Please enter financial & supplier data");
      this.selectedtabIndex = 1;
      let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
      element.click();
      this.showSaveButton = true;
      this.tabchange = false;
      return;
    }
    else if (this.findata == "") {
      alert("Please enter financial data");
      if (this.selectedtabIndex != 1) {
        this.selectedtabIndex = 1;
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
      }
      return;
      //  this.tabchange =false;
      //this.isDisabledContent =false;

    }
    else if (this.SupplierData == "") {
      alert("Please enter supplier data");

      if (this.selectedtabIndex != 4) {
        this.selectedtabIndex = 4;
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
      }

      return;

    }
    //
    const formdata = new FormData();
    formdata.append('OrganisationID', this.newcontract.get("ddlOrganistaion").value);
    formdata.append('Extention', this.newcontract.get("ddlExtensionAvailable").value == "Yes" ? "1" : "0");
    formdata.append('ContractTitle', this.newcontract.get("txtContractTitle").value);
    formdata.append('ExtentionPeriod', this.newcontract.get("ddlExtensionPeriod").value);
    formdata.append('StartDate', datepipe.transform(this.newcontract.get("StartDate").value, 'MM/dd/yyyy'));
    formdata.append('EndDate', datepipe.transform(this.newcontract.get("EndDate").value, 'MM/dd/yyyy'));
    formdata.append('CategoryID', this.SubCategorySelected);
    formdata.append('ContractType', this.newcontract.get("ddlType").value);
    formdata.append('ContractStatus', this.newcontract.get("ddlStatus").value);
    formdata.append('OwnerFirstName', this.newcontract.get("OwnerFirstName").value);
    formdata.append('OwnerLastName', this.newcontract.get("OwnerLastName").value);
    formdata.append('OwnerEmail', this.newcontract.get("OwnerEmail").value);
    formdata.append('MainCategoryID', this.newcontract.get("ddlCategory").value);
    formdata.append('Description', this.newcontract.get("Description").value);
    formdata.append('Priority', this.newcontract.get("ddlPriority").value);
    formdata.append('GDPR', this.newcontract.get("ddlGDPR").value);
    formdata.append('LLA', this.newcontract.get("ddlLLA").value);
    formdata.append('Financialdata', this.findata);
    formdata.append('Supplierdata', this.SupplierData);
    formdata.append('Reference', this.newcontract.get("txtContractReference").value);
    formdata.append('Department', this.newcontract.get("txtDepartment").value);
    this.Dservice.CreateNewWorkFlow(formdata).subscribe((data: any) => {
      if (data.RetStatus == "Created") {
        alert("Contract created successfully !");

        let contract = data.ContractID
        this.Cservice.GetSubCategoryForContract(contract).subscribe((data: any) => {
          sessionStorage.setItem('subcat', data.value);
          this.router.navigate(['/dashboard/contract/details'], { queryParams: { ContractID: contract } });

        });
      }
      else {
        alert(data.RetStatus);

      }
    });


  }

  onselected(event) {
    if (this.detailsdata) {
      this.selectedtabIndex = event.index;
    }
    if (this.tabsave) {
      if (this.tabchange) {
        if (event.index != 0) {

          let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
          element.click();
          //this.SaveContract();
          this.SaveNewContract();
        }
        else {
          let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
          element.click();
        }
      }
    }

  }

  PopUpAlert() {

    alert("Please add contract details first");

  }
  isNumber(val: any) { return typeof val === 'number'; }

  openAddNewProjectFinancials() {
    if (!this.detailsdata) {
      alert("Please add contract details first");
      return;
    }

    const dialogRef = this.dialog.open(DialogAddNewProjectfinancialsComponent, {
      data: { PrjID: 'dummy', ContID: 'dummy' },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == undefined) {
        // alert("hi");
        if (sessionStorage.getItem('FinancialdummyPrj') != null) {
          if (sessionStorage.getItem('FinancialdummyPrj') != 'null') {
            this.findata = sessionStorage.getItem('FinancialdummyPrj');
            this.Dservice.GetFinancialdataDummyPrj(this.findata).subscribe((data: any) => {
              console.log(data);
              if (data.length > 0) {
                this.tabMileStonechange = true;
             
                this.yearlyFinancialsDataSource = new MatTableDataSource(data);
                this.projectedSpend = data[data.length - 1].projectedSpend;
                this.projectedSavings = data[data.length - 1].projectedSavings;
              }


            });
          }

        }
      }

    });




  }


  openDialogAddNewTask() {
    if (!this.detailsdata) {
      alert("Please add contract details first");
      return;
    }
    const dialogRef = this.dialog.open(DialogAddNewSupplierComponent, {
      data: { ContractID: 'dummy', OrgID: this.newcontract.get("ddlOrganistaion").value },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      //this.getSupplierDocuments();
      if (result == undefined) {

        if (sessionStorage.getItem('FinancialdummySupplier') != null) {
          if (sessionStorage.getItem('FinancialdummySupplier') != 'null') {
            this.SupplierData = sessionStorage.getItem('FinancialdummySupplier');
            //alert(this.SupplierData);
            const formdataS = new FormData();
            formdataS.append('FinancialdummySupplier', this.SupplierData);
            this.tabSupplierchange = true;
            this.Dservice.GetAllContractSuppliersDummy(formdataS).subscribe((data: any) => {

              this.Suppliers = data;
              if (data.length > 0) {
                //alert(data[0].FK_SupplierID);
                console.log(data);
                this.ddlSupplierName = data[0].RegistrationNo;
                this.txtRegNumber = data[0].RegistrationNo;
              }
            });
          }

        }
      }
    });
  }


  supplierChanged() {
    //alert(this.ddlSupplierName);
    this.txtRegNumber = this.ddlSupplierName;
  }
  Cancel() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard/contracts/new-contract']);

  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;
    //alert(this.newcontract.get("ddlExtensionPeriod").value);
    if (!reg.test(input)) {
      e.preventDefault();
    }

  }

  LoadAllCaptions() {
    this.setService.getCaptionFromDB("dashboard-contracts-new-contract").subscribe((data: any) => {
      this.Names = data;
    });

  }


  getCaption(sCaption: string) {
    var sResult = sCaption;
    if (this.Names != null) {
      this.Names.forEach(element => {
        if (element.OriginalName === sCaption) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }

  isLLAEnabled() {
    this.setService.enableLondonLivingAllowance().subscribe((data: any) => {
      this.bLLAEnabled = (data.value == "yes")
    });

  }

  UseSimpleSubCategory() {
    this.setService.isSimpleSubCategory().subscribe((data: any) => {
      this.bUseSimpleSubCategory = (data.value == "yes")
    });

  }

  onCategorySelected(value: string) {
    this.DbService.getSubCategorybyCat(Number(value)).subscribe((data: any) => {
      this.SubCategorySelected = data[0].PK_CategoryTypeID;
      this.SubCategory = data;
    });

    //console.log("the selected value is " + value);
  }

  selectSimpleCatEvent(item: string) {

    this.SubCategorySelected = item

    this.tabchange = true;
    this.isDisabledContent = false;

  }

  routeTo(route) {
  
    if (!this.tabchange && !this.tabMileStonechange && !this.tabSupplierchange && !this.showSaveButton) {
      if (route =='home')
        this.router.navigate(["/dashboard/performance"]);
      if (route == 'contracts')
      this.router.navigate(["/dashboard/contracts"]);
      if (route == 'database')
        this.router.navigate(["/dashboard/contracts/database"]);
      if (route == 'new')
        this.router.navigate(["/dashboard/new-contract"]);
    }
  }

}
