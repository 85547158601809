import { Component, OnInit } from '@angular/core';
import { InvitesuserService } from '../invitesuser.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-editprojectstatus',
  templateUrl: './editprojectstatus.component.html',
  styleUrls: ['./editprojectstatus.component.scss']
})
export class EditprojectstatusComponent implements OnInit {
  show:boolean=true;
  ProjectGroup : FormGroup;
  submitted:boolean=false;
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    this.LoadProjectStatuList();
   }
  ProjectStatusList:any;
  ngOnInit() {
    this.ProjectGroup = this.fb.group({
      ProjectStatus : ['',Validators.required],
     });
  }
  get f(){ return this.ProjectGroup.controls;}
  LoadProjectStatuList()
  {
    this.InvserObj.GetProjectStatusList().subscribe((data:any) => 
     {
       this.ProjectStatusList=data;
      console.log(data);
       
     });

  }
  RemoveProjectStatus(PStatus:String)
  {
    for(let i=0;i<this.ProjectStatusList.length;i++)
    {
      if(this.ProjectStatusList[i].ProjectStatusDescription == PStatus)
      {
        
    
        this.ProjectStatusList.splice(i, 1);
      }
    }
  }
 
  AddProjectStatus()
  {
    
    this.submitted = true;
    if (this.ProjectGroup.invalid) 
    {
       return ;
    }
    let statusexist=false;
    let PS=this.ProjectGroup.get("ProjectStatus").value;
    for(let p=0;p<this.ProjectStatusList.length;p++)
    { 
     
      if(this.ProjectStatusList[p].ProjectStatusDescription == PS )
      {
        statusexist=true;
        break;
       }
    }
    
    if(statusexist == false)
    {
      let jsonstring= '{"ProjectStatusDescription" :"'+PS.trim()+'","Active" :0}';
      this.ProjectStatusList.push(JSON.parse(jsonstring));
      this.ProjectGroup.get("ProjectStatus").setValue(" ");
    }
}
  SaveProjectStatus(event)
  {
    const formdecdata=new FormData();
  
    formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
    this.InvserObj.DeactiveProjectStatus(formdecdata).subscribe((data:any) => 
    {
    
  if(data.RetStatus = "Deleted")
  {
  
      if(this.ProjectStatusList.length>0)
      {
        for(let i=0;i <this.ProjectStatusList.length;i++)
          { 
            
            const formdata=new FormData();
            formdata.append('ProjectStatusDescription', this.ProjectStatusList[i].ProjectStatusDescription);
            formdata.append('Active', this.ProjectStatusList[i].Active);
            formdata.append('ContactID',sessionStorage.getItem("ContactID"));
            if(i==0)
            {
            formdata.append('Status','true');
            
            }
            else
            {
              formdata.append('Status','false');
            }
            this.InvserObj.AddProjectStatusList(formdata).subscribe((data:any) => 
            {
              
              if(i== this.ProjectStatusList.length-1)
              {
                alert("Saved Successfully");
                  this.InvserObj.GetProjectStatusList().subscribe((data:any) => 
                  {
                    this.ProjectStatusList=data;
                  
                  });
              }
            });
            
          }
        }
      }
        });
    
  }
}
