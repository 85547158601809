import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-reasonfor-projectapprovereject',
  templateUrl: './dialog-reasonfor-projectapprovereject.component.html',
  styleUrls: ['./dialog-reasonfor-projectapprovereject.component.scss']
})
export class DialogReasonforProjectapproverejectComponent implements OnInit {
  error:string="";
  constructor(public dialogRef: MatDialogRef<DialogReasonforProjectapproverejectComponent>) { }

  ngOnInit() {
  }

  AddReasonForReject(rText:string)
  {
    if(rText =="")
    {
      this.error="Reason for Reject is required";
    }
    else
    {
      this.dialogRef.close(rText);
    }
  }

}
