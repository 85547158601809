import { Component, OnInit,ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import {DashboradContractsService} from '../dashboard-contracts/dashborad-contracts.service';
import {ProjectDatabaseService} from '../project-database.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogExtendContractComponent } from '../../dialogs/dialog-extend-contract/dialog-extend-contract.component';
import { DialogReprocureContractComponent } from '../../dialogs/dialog-reprocure-contract/dialog-reprocure-contract.component';
import {DialogAdvanceSearchcontractComponent} from '../../dialogs/dialog-advance-searchcontract/dialog-advance-searchcontract.component';
import {SettingsService} from '../../settings/settings.service';
import {AlertComponent} from '../../alert/alert.component';
import {LoginService} from '../../login/login.service';

@Component({
  selector: 'app-dashboard-contract-filter',
  templateUrl: './dashboard-contract-filter.component.html',
  styleUrls: ['./dashboard-contract-filter.component.scss']
})
export class DashboardContractFilterComponent implements OnInit {
  displayedColumns: string[] = ['Organisation_Name', 'ContractTitle','type','ContractStartDate', 'ContractEndDate','category','subCategory','Reference','status','contractOwnerFirstName','contractOwnerSurname','contractOwnerEmail','projectedLifetimeContractSpend','projectedContractLifetimeSaving','Priority','extensionAvailable','extensionPeriod'];
  //displayedColumns: string[] = ['Organisation_Name', 'ContractTitle','type','ContractStartDate', 'ContractEndDate','category','subCategory','Reference','status','contractOwnerFirstName','contractOwnerSurname','contractOwnerEmail','projectedLifetimeContractSpend','projectedAnnualisedContractSpend','projectedContractLifetimeSaving','projectedAnnualisedLifetimeSaving','Priority','extensionAvailable','extensionPeriod'];
    contractsDatabaseDataSource :any;
    UploadedContracts:boolean=false;
    ShowButton:boolean=true;
    ShowButtonR : boolean =true;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	Names : any;
    constructor(private router:Router,private route:ActivatedRoute,private Cservice : DashboradContractsService, private PDbService: ProjectDatabaseService, public dialog: MatDialog,
      private setService:SettingsService,private lService:LoginService) { 
      this.LoadAllCaptions();
	  if(sessionStorage.getItem("ContactID") !=null)
      {
        sessionStorage.removeItem("AllowcationList");
       this.router = router;
       //
       if(this.route.snapshot.queryParams['advancesearch'] != null)
       {
       if(this.route.snapshot.queryParams['ContractType'] != null)
        {
           if(this.route.snapshot.queryParams['ContractType'] == "Extend")
           {
            this.ShowButton = true;
            
            }
       else {
        this.ShowButtonR = true;
        this.ShowButton = false;
           }
           let key = 'Item1';
        let item  =JSON.parse(sessionStorage.getItem(key)) ;
       
        this.contractsDatabaseDataSource= new MatTableDataSource(item);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      }
    }
       else{
       if(this.route.snapshot.queryParams['OrganisationID'] != null)
      {
        if(this.route.snapshot.queryParams['ContractType'] != null)
        {
             if(this.route.snapshot.queryParams['ContractType'] == "Extend")
             {
                  this.ShowButton = true;
             }
             else{
              this.ShowButtonR = true;
             }

           if(this.route.snapshot.queryParams['Month'] != null)
           {
            if(this.route.snapshot.queryParams['OrganisationID'] == "0")
            {
              
            this.getExpiryContract(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['Month']);
            }
            else{
              
              this.getExpiryContractByOrg(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['Month']);
            }
           }
           else if(this.route.snapshot.queryParams['Category'] != null)
           {
            if(this.route.snapshot.queryParams['OrganisationID'] == "0")
            {
              
            this.getCategoryContract(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['Category']);
            }
            else{
              
              this.getCategoryContractByOrg(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['Category']);
            }
           }
           else if(this.route.snapshot.queryParams['ContractValue'] != null)
           {
            if(this.route.snapshot.queryParams['OrganisationID'] == "0")
            {
              
            this.getProcureContract(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['ContractValue']);
            }
            else{
              
              this.getProcureContractByOrg(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['ContractValue']);
            }
           }
           else  if(this.route.snapshot.queryParams['Priority'] != null)
           {
            if(this.route.snapshot.queryParams['OrganisationID'] == "0")
            {
              
            this.getPriorityContract(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['Priority']);
            }
            else{
             
              this.getPriorityContractByOrg(this.route.snapshot.queryParams['ContractType'],this.route.snapshot.queryParams['Priority']);
            }
           }
           else {
          if(this.route.snapshot.queryParams['OrganisationID'] == "0")
          {
            
          this.getContractsByType(this.route.snapshot.queryParams['ContractType']);
          }
          else{
          
            this.getContractsByOrg_Type(this.route.snapshot.queryParams['ContractType']);
          }
        }
        }
        else
        {
         
       }
      }
       else if(this.route.snapshot.queryParams['Type'] !=null)
         {
          if(sessionStorage.getItem("usertype") =="Head of Procurement")
          {
            this.ShowButton=true;
            this.ShowButtonR = true;
            this.getContractsbyType_ContactID(this.route.snapshot.queryParams['Type'], sessionStorage.getItem("ContactID"));
          }
          else
          {
            this.ShowButton=true;
            this.ShowButtonR = true;
            this.getContractsbyType_ContactID(this.route.snapshot.queryParams['Type'], sessionStorage.getItem("ContactID"));
          }
         }
         else
         {
           this.ShowButton=false;
           this.ShowButtonR =false;
           this.getAllContracts();
         }
      }
      this.getNonPublishedContracts();
    }
      else
      {
       this.router.navigate(["/login"]);
      }  
    }

  ngOnInit() {
    
  }

  getContractsByType(ContractType : any)
  {
    this.Cservice.getContractsByType(sessionStorage.getItem("ContactID"),ContractType).subscribe((data:any) =>
    {
      console.log(data);
      this.contractsDatabaseDataSource= new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
     
    });
  }

  getContractsByOrg_Type(ContractType : any)
  {
    this.Cservice.getContractsByOrg_Type(this.route.snapshot.queryParams['OrganisationID'],ContractType).subscribe((data:any) =>
    {
      console.log(data);
      this.contractsDatabaseDataSource= new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
	  this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  //
  getExpiryContract(ContractType : any,Month :any)
  {
    this.Cservice.getContractsExpiryByType(sessionStorage.getItem("ContactID"),ContractType,Month).subscribe((data:any) =>
    {
      console.log(data);
      this.contractsDatabaseDataSource= new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
	  this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  //
  getPriorityContract(ContractType : any,Priority :any)
  {
    this.Cservice.getContractsPriorityByType(sessionStorage.getItem("ContactID"),ContractType,Priority).subscribe((data:any) =>
    {
      //console.log(data);
      this.contractsDatabaseDataSource= new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
	  this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }
  //
  getProcureContract(ContractType : any,Value :any)
  {
    this.Cservice.getContractsProcureByType(sessionStorage.getItem("ContactID"),ContractType,Value).subscribe((data:any) =>
    {
      //console.log(data);
      this.contractsDatabaseDataSource= new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
	  this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }
  //
  //
  getCategoryContract(ContractType : any,Category :any)
  {
    this.Cservice.getContractsCategoryByType_ForDrillDown(sessionStorage.getItem("ContactID"),ContractType,Category).subscribe((data:any) =>
    {
      this.contractsDatabaseDataSource= new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
	  this.contractsDatabaseDataSource.paginator = this.paginator;
    });
    // this.Cservice.getContractsCategoryByType(sessionStorage.getItem("ContactID"),ContractType,Category).subscribe((data:any) =>
    // {
    //   //console.log(data);
    //   this.contractsDatabaseDataSource= new MatTableDataSource(data);
    //   this.contractsDatabaseDataSource.sort = this.sort;
    // });
  }
    //
    getExpiryContractByOrg(ContractType : any,Month :any)
    {
      this.Cservice.getContractsExpiryByOrg_Type(this.route.snapshot.queryParams['OrganisationID'],ContractType,Month).subscribe((data:any) =>
      {
        //console.log(data);
        this.contractsDatabaseDataSource= new MatTableDataSource(data);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      });
    }

    //
    getPriorityContractByOrg(ContractType : any,Priority :any)
    {
      this.Cservice.getContractsPriorityByOrg_Type(this.route.snapshot.queryParams['OrganisationID'],ContractType,Priority).subscribe((data:any) =>
      {
        console.log(data);
        this.contractsDatabaseDataSource= new MatTableDataSource(data);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      });
    }

    //
    getProcureContractByOrg(ContractType : any,Value :any)
    {
      this.Cservice.getContractsProcureByOrg_Type(this.route.snapshot.queryParams['OrganisationID'],ContractType,Value).subscribe((data:any) =>
      {
        console.log(data);
        this.contractsDatabaseDataSource= new MatTableDataSource(data);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      });
    }

    //
    getCategoryContractByOrg(ContractType : any,Category :any)
    {
      this.Cservice.getContractsCategoryByOrg_Type_ForDrillDown(this.route.snapshot.queryParams['OrganisationID'],ContractType,Category).subscribe((data:any) =>
      {
        this.contractsDatabaseDataSource= new MatTableDataSource(data);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      });
      // this.Cservice.getContractsCategoryByOrg_Type(this.route.snapshot.queryParams['OrganisationID'],ContractType,Category).subscribe((data:any) =>
      // {
      //   console.log(data);
      //   this.contractsDatabaseDataSource= new MatTableDataSource(data);
      //   this.contractsDatabaseDataSource.sort = this.sort;
      // });
    }
  

  getAllContracts()
  {
    this.Cservice.getContractsByContactID(sessionStorage.getItem("ContactID") ).subscribe((data:any) =>
      {
        console.log(data);
        this.contractsDatabaseDataSource= new MatTableDataSource(data);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      });
  }

 /* showContractDetails(row) {
    //ContacID
    if(this.route.snapshot.queryParams['Type'] ==null)
    {
      this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: row.ContacID}});
    }
    //this.router.navigateByUrl('dashboard/contract/details');
  }*/
  showContractDetails(Id :any) {
    //ContacID
    if(this.route.snapshot.queryParams['Type'] ==null)
    {
      this.Cservice.GetSubCategoryForContract(Id).subscribe((data:any) =>
      {
        //alert(data.value);
        sessionStorage.setItem('subcat',data.value);
        this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: Id}});

       });
    }
    // if(this.route.snapshot.queryParams['Type'] ==null)
    // {
    //   this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: Id}});
    // }
    //this.router.navigateByUrl('dashboard/contract/details');
  }

  searchText(title:string) {
    if(title!="")
  {
    const formdata=new FormData();
    formdata.append("ContractTitle",title);
    formdata.append("ContactID",sessionStorage.getItem("ContactID"));
    this.Cservice.SearchContractbyTitleandContactID(formdata).subscribe((data:any) =>
    //this.Cservice.SearchAllContractsNew(title,sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    {
      console.log(data);
      if(data.length > 0)
      {
      this.contractsDatabaseDataSource= new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
	  this.contractsDatabaseDataSource.paginator = this.paginator;
      }
      else{
           alert("No data found for the search!");
           this.contractsDatabaseDataSource= new MatTableDataSource(data);
           this.contractsDatabaseDataSource.sort = this.sort;
		   this.contractsDatabaseDataSource.paginator = this.paginator;
         }
      
      
    });
  }
  else
  {
        //alert("Please enter some search text !");
        this.getContractsbyType_ContactID(this.route.snapshot.queryParams['Type'], sessionStorage.getItem("ContactID"));
  }
  }

  getContractsbyType_ContactID(Type:string,ContactID:any)
  {
    //GetAllContractListByForNonHOP

    if(sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.PDbService.GetAllContractListByContractType_ContactID(Type, ContactID).subscribe((data:any) =>
      {
        this.contractsDatabaseDataSource= new MatTableDataSource(data);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      });
    }
    else
    {
      this.PDbService.GetAllContractListByForNonHOP(Type, ContactID).subscribe((data:any) =>
      {
        this.contractsDatabaseDataSource= new MatTableDataSource(data);
        this.contractsDatabaseDataSource.sort = this.sort;
		this.contractsDatabaseDataSource.paginator = this.paginator;
      });
    }
      
  }

  AlertDialogforExtendorReprocure(sType:string,ContractId:any)
  {
    
    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:sType},
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result ==true)
      {
         //send mail
         this.PDbService.ReProcure_ExtendContractSendMailtoTM(sessionStorage.getItem("ContactID"),ContractId,sType).subscribe((data:any) =>
         {
            if(data.RetStatus =="Success")
            {
              if(sType =="ExtendContract")
              {
                alert("Your request to extend the contract has been sent");
				this.contractsDatabaseDataSource.data.forEach((value,key)=>{
                if(value.ContacID == ContractId){
                  value.DisableExtend = 1; value.DiableReprocure = 1;}
				});
              }
              else
              {
                //alert("Your request sent Successfully");
                alert("Your request to reprocure the contract has been sent");
				this.contractsDatabaseDataSource.data.forEach((value,key)=>{
                if(value.ContacID == ContractId){
                  value.DisableReprocure = 1; value.DisableExtend = 1;}
				});
              }
              
              this.dialog.closeAll();
            }
            else
            {
              alert(data.RetStatus);
            }
         });
      }
    });
  }


  openDialogReprocureContract(ContractID:any,cTitle:string,OrganisationName:any)
  {
    if(sessionStorage.getItem("usertype")=="Procurement Officer")
    {
      //this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: ContractID}});
      //this.dialog.closeAll();

        this.setService.getSystemSettingswithoutcontactID().subscribe((data:any) =>
        {
          if(data.ProjectApproval =="no")
          {
            this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: ContractID, ContractType:"Reprocure"}});
            this.dialog.closeAll();
            // const dialogRef = this.dialog.open(DialogReprocureContractComponent, { data: {ContractID:ContractID, ContractTitle:cTitle, OrgName:OrganisationName, Page:"Contract"},
            // width: '730px'
            // });
            //   dialogRef.afterClosed().subscribe(result => {
            //       console.log(`Dialog result: ${result}`);
              
            //   });
          }
          else
          {
            this.AlertDialogforExtendorReprocure("ReprocureContract",ContractID);
          }
        });
    }
    else
    {
      const dialogTeamRef = this.dialog.open(AlertComponent, { data :{RetStatus:"ContractRe-procure"},
        width: '600px'
      });
      dialogTeamRef.afterClosed().subscribe(result => {
        if(result == false)
        {
          this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: ContractID, ContractType:"Reprocure"}});
          this.dialog.closeAll();
        }
        else
        {
          const dialogRef = this.dialog.open(DialogReprocureContractComponent, { data: {ContractID:ContractID, ContractTitle:cTitle, OrgName:OrganisationName, Page:"Contract"},
          width: '730px'
          });

          dialogRef.afterClosed().subscribe(result => {
			  if (result == true)
		      {
			  this.contractsDatabaseDataSource.data.forEach((value,key)=>{
                if(value.ContacID == ContractID){
                  value.DisableExtend = 1; value.DisableReprocure = 1;}
				});
              }
			  console.log(`Dialog result: ${result}`);
          
          });
        }
      });
      
    }
    
  }
  openDialogExtendContract(ContactID:any, OrganisationName:any)
  {
    if(sessionStorage.getItem("usertype")=="Procurement Officer")
    {
      this.setService.getSystemSettingswithoutcontactID().subscribe((data:any) =>
        {
          if(data.ProjectApproval =="no")
          {
            const dialogRef = this.dialog.open(DialogExtendContractComponent, {data: {ContactID:ContactID, OrgName:OrganisationName}});

            dialogRef.afterClosed().subscribe(result => {
				
                console.log(`Dialog result: ${result}`);
            });
          }
          else
          {
            this.AlertDialogforExtendorReprocure("ExtendContract",ContactID);
          }
        });
    }
    else
    {
      const dialogRef = this.dialog.open(DialogExtendContractComponent, {data: {ContactID:ContactID, OrgName:OrganisationName}});

      dialogRef.afterClosed().subscribe(result => {
		  if (result == true)
		      {
		  this.contractsDatabaseDataSource.data.forEach((value,key)=>{
                if(value.ContacID == ContactID){
                  value.DisableExtend = 1; value.DisableReprocure = 1;}
				});
			  }
          console.log(`Dialog result: ${result}`);
      });
    }
   
  }

  openDialogAdvanceSearch()
  {
    const dialogRef = this.dialog.open(DialogAdvanceSearchcontractComponent, { data: {ContactID:sessionStorage.getItem("ContactID")},
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        let key = 'Item1';
        if(sessionStorage.getItem('type') == null)
        {
     
        let item  =JSON.parse(sessionStorage.getItem(key)) ;
        //console.log(item);
        //this.contractsDatabaseDataSource= new MatTableDataSource(item);
        //this.contractsDatabaseDataSource.sort = this.sort;
        //this.ShowButtonR = false;
        //this.ShowButton =false;
        this.router.navigate(['dashboard/contracts/database'],{ queryParams: {advancesearch : 'yes' }});
        
        }
        else {
        if(sessionStorage.getItem('type') == 'null')
        {
         
          let item  =JSON.parse(sessionStorage.getItem(key)) ;
          //console.log(item);
         // this.contractsDatabaseDataSource= new MatTableDataSource(item);
         // this.contractsDatabaseDataSource.sort = this.sort;
         // this.ShowButtonR = false;
         // this.ShowButton =false;
         this.router.navigate(['dashboard/contracts/database'],{ queryParams: {advancesearch : 'yes' }});
        
        }
        else{
         
          if(sessionStorage.getItem('type') == 'Reprocure')
          {
         // this.ShowButtonR = true;
         // this.ShowButton =false;
          }
          else{
         // this.ShowButton =true;
         // this.ShowButtonR = true;
          }
          sessionStorage.setItem('type','null');
          let item  =JSON.parse(sessionStorage.getItem(key)) ;
          this.contractsDatabaseDataSource= new MatTableDataSource(item);
          this.contractsDatabaseDataSource.sort = this.sort;
		  this.contractsDatabaseDataSource.paginator = this.paginator;
        }
        }
    });
  }
  getNonPublishedContracts()
  {
    this.lService.getContactUser(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    {
      
     if(data.ContractConfig =="No")
     {
        this.UploadedContracts=false;
     }
     else
     {
      this.Cservice.GetAllNonPublishedContractListByContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
      {
        if(data.length >0)
        {
          this.UploadedContracts=true;
        }
        else
        {
          this.UploadedContracts=false;
        }
      });
     }
    });
  }
  
  LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dashboard-contract-filter").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }  
  
}
