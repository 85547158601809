import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardProjectsService {

  constructor(private http:HttpClient,private lService: LoginService) { }

    getAllProjectsNo(ContactID : any)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectsNo(ContactID=" + ContactID + ")");
    }

    getAllProjectsNoByOrg(OrganisationID:any)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectsNoByOrg(OrganisationID=" + OrganisationID + ")");
    }

    getAllProjectsNoByContactID_Org(OrganisationID:number,ContactID:number)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectsNoBy_ContactID_Org(OrganisationID=" + OrganisationID + ",ContactID="+ ContactID +")");
    }

    getAllProjectsOwners(ContactID:any)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectOwners(ContactID=" + ContactID + ")");
    }

    getAllProjectsOwnersByOrgID(OrgID:any)
    {

      return this.http.get(this.lService.ServiceIP +"/project/GetProjectOwnersByOrg(OrgID=" + OrgID + ")");
    }

    getProjectsOwnersByTeamID(TeamID:any)
    {

      return this.http.get(this.lService.ServiceIP +"/project/GetProjectOwnersByTeam(TeamID=" + TeamID + ")");
    }

    GetProjectOwnersforTeamByUser(TeamID:number,ContactID:number)
    {
      return this.http.get(this.lService.ServiceIP +"/project/GetProjectOwnersforTeamByUser(TeamID=" + TeamID + ",ContactID="+ ContactID +")");
    }

    GetProjectOwnersByOrganisationID(OrganisationID:any)
    {
      return this.http.get(this.lService.ServiceIP +"/project/GetProjectOwnersByOrganisationID(OrganisationID=" + OrganisationID + ")");
    }

    GetAllProjectOwnersByTeamID(TeamID:any)
    {
      return this.http.get(this.lService.ServiceIP +"/project/GetProjectOwnersByTeamID(TeamID=" + TeamID + ")");
    }

    GetProjectOwnerByContactID(ContactID:any)
    {
      return this.http.get(this.lService.ServiceIP +"/project/GetProjectOwnerByContactID(ContactID=" + ContactID + ")");
    }
    //added on 10/12/2019 by zuber
    getAllProjectsNoByTeam(OrganisationID : any,TeamID : any)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectsNoByTeam(OrganisationID=" + OrganisationID +",TeamID=" + TeamID +")");
    }

    getAllProjectsNoByTeamForUser(OrganisationID:any,TeamID:any,ContactID:number)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectsNoByTeam(OrganisationID=" + OrganisationID +",TeamID=" + TeamID +",ContactID="+ ContactID +")");
    }

    getAllProjectsNoByOwner(ContactID : any,Owner : any)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectsNoByOwner(ContactID=" + ContactID +",Owner=" + Owner +")");
    }

    getAllProjectsNoByOrg_Owner(OrganisationID : any,Owner : any)
    {

      return this.http.get(this.lService.ServiceIP +"/ProjectStatus/GetProjectsNoByOrg_Owner(OrganisationID=" + OrganisationID +",Owner=" + Owner +")");
    }
	
	
}
