import { Component, OnInit, Inject } from '@angular/core';
import { InvitesuserService } from "../invitesuser.service";
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { InvitesuserComponent } from '../invitesuser.component';
import { DashboardPerformanceService } from 'src/app/dashboard/dashboard-performance/dashboard-performance.service';

@Component({
  selector: 'app-allocatenewteam',
  templateUrl: './allocatenewteam.component.html',
  styleUrls: ['./allocatenewteam.component.scss']
})
export class AllocatenewteamComponent implements OnInit {
  AllocateUser:FormGroup;
  Organisations:any;
  Teams:any;
  Roles:any;
  ContactDetails:any;
  Submitted:boolean=false;
  constructor(private IService:InvitesuserService,private fb: FormBuilder,public dialog: MatDialog ,@Inject(MAT_DIALOG_DATA) public data: any,
  private Dservice:DashboardPerformanceService) 
  { 
    this.getInviteeRoles();
    this.getOrganisations();
  }

  ngOnInit() {
    this.AllocateUser = this.fb.group({
      txtfirstname : [''],
      txtlastname : [''],
      ddlRole : ['',Validators.required],
      ddlaccountstatus : ['',Validators.required],
      ddlconfig :['',Validators.required],
      ddladmin :['',Validators.required],
      ddlTeam : ['',Validators.required],
      ddlOrganisation:['',Validators.required],
      txtemailaddress : ['',[Validators.required, Validators.email]],
   });
   this.GetContactDetails(this.data.ContactID);
  }

  SaveAllocateUser()
  {
    this.Submitted = true;
    if (this.AllocateUser.invalid) 
    {
      return;
    }
    const formData=new FormData();
    formData.append("FK_OrganisationID",this.AllocateUser.get("ddlOrganisation").value);
    formData.append("FK_TeamID",this.AllocateUser.get("ddlTeam").value);
    formData.append("FK_ContactID",this.data.ContactID);
    this.IService.AssignTeamstoUsers(formData).subscribe((data:any) =>
    {
      if(data =="Success")
      {
        alert("User assigned Successfully.");
        this.dialog.closeAll();
      }
      else if(data =="Team already exists")
      {
        alert("Selected Team already allocated for this User.");
      }
      else
      {
        alert(data);
        this.dialog.closeAll();
      }
    });
  }

  getInviteeRoles()
  {
    if(sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.IService.GetUserTypesforInviteUserwithoutHOP().subscribe((data:any) =>
      {
          this.Roles=data;
      })
    }
    else
    {
      this.IService.GetUserTypesforInviteUser().subscribe((data:any) =>
      {
          this.Roles=data;
      })
    }
  }

  getOrganisations()
  {
    if(sessionStorage.getItem("usertype")=="Head of Procurement")
    {
      this.Dservice.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((odata:any)=>
      {
        this.Organisations=odata.value;
        
        if(this.data.OrgID != 0)
        {
          this.AllocateUser.get("ddlOrganisation").setValue(this.data.OrgID);
          this.getHOPTeamsByOrganisation(this.data.OrgID);
        }
        else
        {
          
          this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
          {
            this.AllocateUser.get("ddlOrganisation").setValue(tdata.value[0].OrganisationID);
            this.getHOPTeamsByOrganisation(tdata.value[0].OrganisationID);
          });
          //this.newcontract.get("ddlOrganisation").setValue(81);
        }
       
      });
    }
    else
    {
      this.IService.GetAssignedOrganisationForUser(Number(sessionStorage.getItem("ContactID"))).subscribe((adata:any) =>
      {
        this.Organisations=adata;
        if(this.data.OrgID !=0)
        {
          this.AllocateUser.get("ddlOrganisation").setValue(this.data.OrgID);
           //this.getHOPTeamsByOrganisation(this.data.OrgID);
          this.getNonHOPTeamsByOrganisation(Number(sessionStorage.getItem("ContactID")),this.data.OrgID);
        }
        else
        {
          this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
          {
            this.AllocateUser.get("ddlOrganisation").setValue(tdata.value[0].OrganisationID);
            //this.getHOPTeamsByOrganisation(tdata.value[0].OrganisationID);
            this.getNonHOPTeamsByOrganisation(Number(sessionStorage.getItem("ContactID")),tdata.value[0].OrganisationID);
          });
        }
      });
    }
  }

  getHOPTeamsByOrganisation(OrganisationID:any)
  {
    
    this.IService.getTeamsbyOrganisation(OrganisationID).subscribe((tdata:any) =>
      {
        this.Teams=tdata.value;
        this.AllocateUser.get("ddlTeam").setValue(this.data.TeamID);
      });
  }
  getNonHOPTeamsByOrganisation(ContactID:number,OrganisationID:any)
  {
    
    this.IService.getAssignedTeamsForUserbyOrgID(ContactID, OrganisationID).subscribe((tdata:any) =>
      {
        this.Teams=tdata;
        this.AllocateUser.get("ddlTeam").setValue(this.data.TeamID);
      });
  }

  GetContactDetails(contactID:any)
  {

    this.IService.ConctactDetails(contactID).subscribe((data:any) =>
    {
      this.ContactDetails=data;
      this.AllocateUser.get("txtfirstname").setValue(this.ContactDetails.ContactFirstName) ;
      //this.ContactFirstName=this.contactDetails.ContactFirstName;
      this.AllocateUser.get("txtlastname").setValue(this.ContactDetails.ContactSurname );
      //this.LastName=this.contactDetails.ContactSurname;
      this.AllocateUser.get("txtemailaddress").setValue(this.ContactDetails.ContactEMailAddress) ;
      //this.EmailAddress=this.contactDetails.ContactEMailAddress;
      this.AllocateUser.get("ddlRole").setValue(this.ContactDetails.ContactUser_Type) ;
      //this.Role=this.contactDetails.ContactUser_Type;
      this.AllocateUser.get("ddlaccountstatus").setValue(this.ContactDetails.ContractConfig =="1"?"Inactive":"Active") ;
      //this.AccountStatus=this.contactDetails.ContractConfig;
      this.AllocateUser.get("ddlconfig").setValue(this.ContactDetails.ContractConfig );
      //this.Contractconfig=this.contactDetails.ContractConfig;
       this.AllocateUser.get("ddladmin").setValue(this.ContactDetails.AdminPerMission) ;
       //this.Admin=this.contactDetails.AdminPerMission;
      this.AllocateUser.get("ddlTeam").setValue(this.ContactDetails.TeamID) ;
      //this.AllocateTeam=this.contactDetails.TeamID;
    });
  }
  get f() { return this.AllocateUser.controls; };

  OrganisationSelectedChange()
  {
    if(sessionStorage.getItem("usertype")=="Head of Procurement")
    {
      this.IService.getTeamsbyOrganisation(this.AllocateUser.get("ddlOrganisation").value).subscribe((tdata:any) =>
      {
        this.Teams=tdata.value;
        this.AllocateUser.get("ddlTeam").setValue(tdata.value[0].TeamID);
      });
    }
    else
    {
      this.IService.getAssignedTeamsForUserbyOrgID(Number(sessionStorage.getItem("ContactID")), this.AllocateUser.get("ddlOrganisation").value).subscribe((tdata:any) =>
      {
        this.Teams=tdata;
        this.AllocateUser.get("ddlTeam").setValue(tdata[0].TeamID);
      });
    }
    
  }
}
