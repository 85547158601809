import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {LoginComponent} from './login/login.component';
import { ResetpasswordComponent} from './resetpassword/resetpassword.component';
import {RegisterComponent} from './register/register.component';
import {TellusComponent} from './tellus/tellus.component';
import {CreateteamComponent} from './createteam/createteam.component';
import {InviteusersComponent} from './inviteusers/inviteusers.component';
import {SignupcompleteComponent} from './signupcomplete/signupcomplete.component';
import {CreatepasswordComponent} from './createpassword/createpassword.component';
import {CreateclientAdminComponent} from './createclient-admin/createclient-admin.component';
import {DashboardPerformanceComponent} from './dashboard/dashboard-performance/dashboard-performance.component';
import {DashboardProjectsComponent} from './dashboard/dashboard-projects/dashboard-projects.component';
import { DashboardContractsComponent } from './dashboard/dashboard-contracts/dashboard-contracts.component';
import { DashboardProjectsDatabaseComponent } from './dashboard/dashboard-projects-database/dashboard-projects-database.component';
import { DashboardProjectsNewProjectComponent } from './dashboard/dashboard-projects-new-project/dashboard-projects-new-project.component';
import { DashboardProjectDetailsComponent } from './dashboard/dashboard-project-details/dashboard-project-details.component';
import { DashboardContractsNewContractComponent } from './dashboard/dashboard-contracts-new-contract/dashboard-contracts-new-contract.component';
import { DashboardContractDetailsComponent } from './dashboard/dashboard-contract-details/dashboard-contract-details.component';
import { ProjectAdvanceSearchComponent } from './AdvanceSearch/project-advance-search/project-advance-search.component';
import { DashboardProjectsReportComponent } from './dashboard/dashboard-projects-report/dashboard-projects-report.component';
import { DashboardContractsReportComponent } from './dashboard/dashboard-contracts-report/dashboard-contracts-report.component';
import {DashboardContractFilterComponent} from './dashboard/dashboard-contract-filter/dashboard-contract-filter.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {DashboardContractsNewComponent} from './dashboard/dashboard-contracts-new/dashboard-contracts-new.component';
import {TestGanttComponent} from './test-gantt/test-gantt.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountComponent } from './account/account.component';
import {DashboardPerformancelifetimeComponent} from './dashboard/dashboard-performancelifetime/dashboard-performancelifetime.component';
import {ReportsProjectComponent} from './reports/reports-project/reports-project.component';
import {PrivacypolicyComponent} from './privacypolicy/privacypolicy.component';
import {TermsandConditionComponent} from './termsand-condition/termsand-condition.component';
import {CompanydetailsComponent} from './companydetails/companydetails.component';
import {DashboardPerformanceOnesourceComponent} from './dashboard/dashboard-performance-onesource/dashboard-performance-onesource.component';


import { from } from 'rxjs';
import { DefaultComponent } from './login/default.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'resetpassword', component:ResetpasswordComponent  },
  { path: 'register', component:RegisterComponent  },
  { path: 'tellus', component:TellusComponent  },
  { path: 'createteam', component:CreateteamComponent  },
  { path: 'inviteuser', component:InviteusersComponent  },
  { path: 'inviteclientadmin', component:CreateclientAdminComponent  },
  { path: 'signupcomplete', component:SignupcompleteComponent  },
  { path: 'createpassword', component:CreatepasswordComponent  },
  { path: 'dashboard/performance', component:DashboardPerformanceComponent  },
  { path: 'dashboard/performancelifetime', component:DashboardPerformancelifetimeComponent  },
  { path: 'dashboard/projects', component:DashboardProjectsComponent  },
  { path: 'dashboard/projects/database',      component: DashboardProjectsDatabaseComponent },
  { path: 'dashboard/projects/new-project',   component: DashboardProjectsNewProjectComponent },
  { path: 'dashboard/project/details',        component: DashboardProjectDetailsComponent },
  { path: 'dashboard/projects/report',        component: DashboardProjectsReportComponent },
  { path: 'dashboard/projects/advancesearch', component:ProjectAdvanceSearchComponent  },
  { path: 'dashboard/contracts/database',              component: DashboardContractsComponent },
  { path: 'dashboard/contracts',              component: DashboardContractsNewComponent },
  { path: 'dashboard/contracts/filter',              component: DashboardContractFilterComponent },
  { path: 'dashboard/contract/details',       component: DashboardContractDetailsComponent },
  { path: 'dashboard/contracts/new-contract', component: DashboardContractsNewContractComponent },
  { path: 'dashboard/contracts/report',       component: DashboardContractsReportComponent },
  { path: 'reports/project',                  component: ReportsProjectComponent },
  { path: 'settings',                         component: SettingsComponent },
  { path: 'account',                          component: AccountComponent },
  { path:'privacypolicy', component:PrivacypolicyComponent},
  { path:'termsandconditions', component:TermsandConditionComponent},
  { path:'companydetails', component:CompanydetailsComponent},
  { path: 'testgantt',       component: TestGanttComponent },
  { path: 'dashboard/performanceonesource', component:DashboardPerformanceOnesourceComponent  },
  { path : '', component : LoginComponent},
  // { path : 'login', component : LoginComponent},
  // { path : '', component : DefaultComponent},
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
