import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InvitesuserService } from '../invitesuser.service';

@Component({
  selector: 'app-editcontractstatus',
  templateUrl: './editcontractstatus.component.html',
  styleUrls: ['./editcontractstatus.component.scss']
})
export class EditcontractstatusComponent implements OnInit {

  ContractStatusGroup : FormGroup;
  submitted:boolean=false;
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    this.LoadContractStatus();
   }
   get f(){ return this.ContractStatusGroup.controls;}
   ContractStatusList:any;
  ngOnInit() {
    this.ContractStatusGroup = this.fb.group({
      ContractStatusDescription : ['',Validators.required],
     });
  }
  LoadContractStatus()
  {
    this.InvserObj.GetContractStatus().subscribe((data:any) => 
     {
       this.ContractStatusList=data;
      });

  }
  RemoveContractStatus(PStatus:String)
  {
   for(let i=0;i<this.ContractStatusList.length;i++)
    {
      if(this.ContractStatusList[i].ContractStatusDescription == PStatus)
      {
        this.ContractStatusList.splice(i, 1);
     //   alert("deleted");
        break;
      }
    }
  }
 
  AddContractStatus()
  {
    this.submitted = true;
    if (this.ContractStatusGroup.invalid) 
    {
       return ;
    }
    let statusexist=false;
    let PS=this.ContractStatusGroup.get("ContractStatusDescription").value;
  
    for(let p=0;p<this.ContractStatusList.length;p++)
    { 
     
      if(this.ContractStatusList[p].ContractStatusDescription == PS )
      {
        statusexist=true;
        break;
       }
    }
    
    if(statusexist == false)
    {
      let jsonstring= '{"ContractStatusDescription" :"'+PS+'","Active" :0}';
      this.ContractStatusList.push(JSON.parse(jsonstring));
      this.ContractStatusGroup.get("ContractStatusDescription").setValue(" ");
    }
}
SaveContractSatus()
  {
    
    const formdecdata=new FormData();
  
    formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
    this.InvserObj.DeactiveContractStatus(formdecdata).subscribe((data:any) => 
    {
    
        if(data.RetStatus = "Deleted")
        {
        if(this.ContractStatusList.length>0)
        {
          for(let i=0;i <this.ContractStatusList.length;i++)
            { 
              const formdata=new FormData();
              formdata.append('ContractStatusDescription', this.ContractStatusList[i].ContractStatusDescription);
              formdata.append('ContactID',sessionStorage.getItem("ContactID"));
              formdata.append('Active', this.ContractStatusList[i].Active);
              if(i==0)
              {
              formdata.append('Status','true');
              }
              else
              {
                formdata.append('Status','false');
              }
              this.InvserObj.AddContracStatus(formdata).subscribe((data:any) => 
              {
                
                if(i== this.ContractStatusList.length-1)
                {
                  alert("Saved Successfully");
                    this.InvserObj.GetContractStatus().subscribe((data:any) => 
                    {
                      this.ContractStatusList=data;
                    
                    });
                }
              });
            }
        }
        }
    });
  }

}
