import { Component, OnInit,Inject } from '@angular/core';
import { CreateTeamDepartmentService } from "./create-team-department.service";
import { CreateteamService } from '../createteam/createteam.service';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-create-team-department',
  templateUrl: './create-team-department.component.html',
  styleUrls: ['./create-team-department.component.scss']
})
export class CreateTeamDepartmentComponent implements OnInit {
  Teams : FormGroup;
  submitted:boolean=false;
  constructor(private CTDS : CreateTeamDepartmentService,private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
  private mtdg:MatDialog) {

    this.LoadDepartments();
   }

  ngOnInit() {
    this.Teams = this.fb.group({
      TeamName : ['',Validators.required],
      Department:['',Validators.required]
    });
  }
  Departments:any;
  get f() { return this.Teams.controls };
  SaveNewTeam()
  {
    this.submitted = true;
     // stop here if form is invalid
     if (this.Teams.invalid) 
     {
       return;
     }
     //alert(this.data.OrgID);
    
     const formdata=new FormData();
     formdata.append('TeamName', this.Teams.get("TeamName").value);
     formdata.append('Department', this.Teams.get("Department").value );
     formdata.append('OrgId', this.data.OrgID );
     this.CTDS.UpdateTeamDepartment(formdata).subscribe((data:any) =>
            {
                //alert(data);
                if(data.RetStatus =="Team Added")
                {
                  alert("Team added successfully !");
                  this.mtdg.closeAll();
                  //this.router.navigate(['dashboard/contracts/database']);
                  //this.router.navigate(['/dashboard/projects/new-project'], { queryParams: {ContractID: data.ContractID}});
                }
                else if(data.RetStatus =="Team Name Exists")
                {
                  alert("Team name already exists.");
                  this.Teams.get("TeamName").reset();
                }
                else{
                      alert("Something went wrong !");
                
                }
            });
   //  this.CTDS.TeamExists(this.Teams.get("TeamName").value,this.data.OrgID).subscribe((data:any)=>
     //this.CTDS.TeamExists(this.Teams.get("TeamName").value).subscribe((data:any)=>
     // {
          // if(data.value=="Exists")
          // {
          //   alert("Team Name already exists.");
          //   //this.CreateTeam.get("teamFormControl").reset();
          // }
          // else
          // {
            
           //}
     // }
    //);
  }
  LoadDepartments()
  {
  
    //this.CTDS.GetDepartments().subscribe((data:any)=> 
    this.CTDS.getCategories().subscribe((data:any) =>
    {
      console.log(data);
      this.Departments=data.value;
          if(this.Departments.length>0)
          {
            this.Teams.get("Department").setValue(this.Departments[0].PK_CategoryGroupID) ;
          }
      }
    );
  }
}
