import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class TellusService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  getUserType(UserType_ID:string)
  {
    return this.http.get(this.lService.ServiceIP +"/usertype/"+ UserType_ID +"");
  }

  getAllUserTypes()
  {
    return this.http.get(this.lService.ServiceIP +"/usertype");
  }

  createOrganisation(formData:any)
  {
      return this.http.post(this.lService.ServiceIP +"/organisation",formData);
  }

  OrganisationExists(Organisation_Name:string)
  {
    return this.http.get(this.lService.ServiceIP + "/organisation/OrganisationExists(Organisation_Name='"+ Organisation_Name +"')");
  }
  CheckOrganisationName(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/organisation/CheckOrganisationName",formData);
  }
}

