import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';
import {InvitesuserService} from '../invitesuser/invitesuser.service';
import {AccountSetupWizardService} from './account-setup-wizard.service';
// export interface WizardCheckList {
//  WizardCheckListName:string,
//  Viewed:number,
//  ContactID:number
// }
import { MatDialog } from '@angular/material/dialog';
import {DialogGanttProjectComponent} from '../dialogs/dialog-gantt-project/dialog-gantt-project.component';
import { DialogTeamGanttComponent } from '../dialogs/dialog-team-gantt/dialog-team-gantt.component';


@Component({
  selector: 'app-account-setup-wizard',
  templateUrl: './account-setup-wizard.component.html',
  styleUrls: ['./account-setup-wizard.component.css']
})
export class AccountSetupWizardComponent implements OnInit {
  
  @Input() dialogOpen:boolean = false;
    @Input() totalSteps:number = 4;
    @Input() currentStep:number = 0;
    @Input() progressPercentage:number = 0;
    //ForSystemAdmin:boolean=false;
    //ForContractConfig:boolean=false;
    //ForHOP:boolean=true;
    TeamCount:any=0;
    UsersCount:any=0;
    SetupCount:any=0;
    WizardCheckList:any;
  constructor(private router: Router,private DbService:InvitesuserService, private wizard:AccountSetupWizardService,public dialog: MatDialog) { 
   
    if(sessionStorage.getItem("ContactID") !=null)
     {
       //this.GetWizardCheckList();
      //  if(sessionStorage.getItem("usertype") =="Head of Procurement")
      //  {
      //   this.ForHOP=true;
      //   this.ForSystemAdmin=false;
      //   this.ForContractConfig=false;
        
      //  }
      //  else 
      //  {
      //     if(sessionStorage.getItem("AdminPermission") =="Yes")
      //     {
      //       this.ForHOP=false;
      //       this.ForSystemAdmin=true;
      //       this.ForContractConfig=false;
            
      //     }
      //     else
      //     {
      //       this.ForHOP=false;
      //       this.ForSystemAdmin=false;
      //       this.ForContractConfig=true;
            
      //     }
      //  }
     }
     else
     {
      this.router.navigate(['/login']);
     }
   }

  ngOnInit() {
  }

  // GetWizardCheckList()
  // {
  //   this.wizard.getWizardCheckListByContactID(Number(sessionStorage.getItem("ContactID")),sessionStorage.getItem("usertype")).subscribe((data:any) =>
  //   {
  //     this.WizardCheckList=data;
  //     if(data.length >0)
  //     {
  //       this.totalSteps=data.length;
  //     }
  //   });
  // }

  RedirecttoLink(sURL:string,wListID:string,wListValue:string) 
  {
    const formData=new FormData();
    formData.append("ContactID",sessionStorage.getItem("ContactID"));
    formData.append("UserType",sessionStorage.getItem("usertype"));
    formData.append("WizardCheckList",wListID);
    this.wizard.WizardMenuCheckList_Insert(formData).subscribe((data:any) =>
    {
      if(data.value =="Success")
      {
        if(wListValue =="View my Teams Gantt")
        {
          this.openViewTeamGanttDailog();
        }
        else if(wListValue =="View my Teams Project Gantt")
        {
          this.openDialogGanttChart();
        }
        else if(wListValue =="Download contracts and projects database template" || wListValue == "Upload completed contracts and projects database")
        {
          // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([sURL], { queryParams: {ShowDataImport:'true'}});
        }
        else if(wListValue =="Create Teams" || wListValue =="Issue user logins")
        {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([sURL], { queryParams: {Move:'1'}});
        }
        else
        {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([sURL]);
        }
        
      }
      else
      {
        alert(data.value);
      }
    });
  }

  openDialogGanttChart()
{
  const dialogRef = this.dialog.open(DialogGanttProjectComponent, {
    width: '1366px'
  });
  
  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    this.toggleDialog();
  });
}

openViewTeamGanttDailog()
  {
    //this.dialogRef.close();
    const dRef = this.dialog.open(DialogTeamGanttComponent, { 
      width: '1366px'
    });
    dRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.toggleDialog();
    });
    
  }

  toggleDialog() {
    this.dialogOpen = !this.dialogOpen;

    if (this.dialogOpen) {

      this.wizard.getWizardCheckListByContactID(Number(sessionStorage.getItem("ContactID")),sessionStorage.getItem("usertype")).subscribe((data:any) =>
      {
        this.WizardCheckList=data;
        
        if(data.length >0)
        {

          this.totalSteps=data.length;
          this.currentStep = data.filter(x=>x.WizardCheckList_Viewed == 1).length;
          //alert(data.filter(x=>x.WizardCheckList_Viewed == 1).length);
        }
      });

        // get the current step and apply it accordingly here
      // if(sessionStorage.getItem("usertype") =="Head of Procurement")
      // {
      //   this.totalSteps=5;
      //   this.currentStep = 3;
      // } 
      // else
      // {
      //   if(sessionStorage.getItem("AdminPermission") =="Yes")
      //     {
      //       this.DbService.GetUserCountandTeamsCountUnder(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
      //       {
      //         this.TeamCount=data.TeamCount;
      //         this.UsersCount=data.UserCount;
      //         if(data.UserCount >0 && data.TeamCount >0)
      //         {
      //           this.totalSteps=4;
      //           this.currentStep = 3;
      //         }
      //         else if(data.TeamCount >0 && data.UserCount ==0)
      //         {
      //           console.log(data.totalSteps +" "+ this.currentStep);
      //           this.totalSteps=4;
      //           this.currentStep = 2;
      //         }
      //         else
      //         {
      //           this.totalSteps=4;
      //           this.currentStep = 1;
      //         }
      //       });
           
      //     }
      //     else
      //     {
      //       this.totalSteps=4;
      //       this.currentStep = 1;
      //     }
      // }       
    } 
    else {
        this.currentStep = 0;
    }

    window.setTimeout(() => {
        this.progressPercentage = Math.round(this.currentStep / this.totalSteps * 100);
    }, 100);
}
async getCount()
{
  return await this.DbService.GetUserCountandTeamsCountUnder(sessionStorage.getItem("ContactID"));
}
}
