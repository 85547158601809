import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  @Input() notificationMessage: string = '';
    @Input() notificationType: string = 'info';
    // other options for notificationtypes are 'warning' and 'danger'
    @Input() notificationCTAText: string = 'Dismiss';
    // @Input() notificationCTACallback: any;

  constructor() { }

  ngOnInit() {
  }

  public notificationCTA() {
    this.notificationMessage = '';

} 
}
