import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDatabaseService {


  public Project_ID: number;
  public Contract_ID: number;
  constructor(private http: HttpClient, private lService: LoginService) { }

  GetPerformanceProjects(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any, OwnerID: any, type: string, performacetype) {
    return this.http.get(this.lService.ServiceIP + "/project/GetPerformanceProjects(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner=" + OwnerID + ",TeamID=" + TeamID + ",type='" + type + "',performacetype=" + performacetype + ")");
  }

  getContactUser(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/contact/" + ContactID + "");
  }

  getAllOrganistaion() {
    return this.http.get(this.lService.ServiceIP + "/organisation");
  }

  getOrganisationByHOP(HOPContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/organisation?$filter=ContactID eq " + HOPContactID);
  }

  getOrganisationForNonHOP(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/organisation/GetOrganisationForNonHOP(ContactID=" + ContactID + ")");
  }
  getTeambyContactID(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/team/GetTeambyContactID(ContactID=" + ContactID + ")");
  }
  GetAssignedTeamsForUser(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/team/GetAssignedTeamsForUser(ContactID=" + ContactID + ")");
  }

  GetAssignedTeamsForUserbyOrgID(ContactID: number, OrganisationID: number) {
    return this.http.get(this.lService.ServiceIP + "/team/GetAssignedTeamsForUserbyOrgID(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ")");
  }

  getCategory() {
    return this.http.get(this.lService.ServiceIP + "/Category");
  }

  getSubCategory() {
    return this.http.get(this.lService.ServiceIP + "/SubCategory");
  }
  getSubCategoryByID(SubCateID: any) {
    return this.http.get(this.lService.ServiceIP + "/SubCategory/" + SubCateID + "");
  }

  getSubCategoryForProject(SubCateID: any) {
    return this.http.get(this.lService.ServiceIP + "/SubCategory/GetSubCategoryByID(SubCategoryID=" + SubCateID + ")");
  }



  //changed on 11/02/2020
  getAllSubCategories() {
    return this.http.get(this.lService.ServiceIP + "/SubCategory/GetSubCategories_Autocomplete");
  }

  getSubCategoryby(CategoryID: any) {
    return this.http.get(this.lService.ServiceIP + "/SubCategory?$filter=FK_CategoryGroupID eq " + CategoryID);
  }

  getSubCategorybyCat(CategoryID: number) {
    return this.http.get(this.lService.ServiceIP + "/SubCategory/GetSubCategorybyCategoryID(CategoryID=" + CategoryID + ")");
  }

  getExtensionPeriod() {
    return this.http.get(this.lService.ServiceIP + "/ExtentionPeriod");
  }

  getContractData(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/Contract/" + ContractID);
  }
  CreateNewProject(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project", formData);
  }
  UpdateProjectSocialValue(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/UpdateProjectSocialValue", formData);
  }
  CheckProjectTitleExits(ProjectTitle: string) {
    return this.http.get(this.lService.ServiceIP + "/project?$filter=ProjectTitle eq '" + ProjectTitle + "'");
  }

  CheckProjectTitleExists(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/CheckProjectTitleExists", formData);
  }

  getProjectDatabyProjectID(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project(" + ProjectID + ")");
  }
  getAllProjects() {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjects");
  }

  getAllProjectsbyContactID(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyContactID(ContactID=" + ContactID + ")");
  }

  GetNonPublishedProjects(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNonPublishedProjects(ContactID=" + ContactID + ")");
  }

  GetProjectsForNonHOPbyContactID(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsForNonHOPbyContactID(ContactID=" + ContactID + ")");
  }

  GetProjectbyContactIDandUserType(ContactID: any, UserType: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectbyContactIDandUserType(ContactID=" + ContactID + ",UserType='" + UserType + "')");
  }

  GetProjectbyContactIDandUserTypewithSearchText(ContactID: any, UserType: string, SearchText: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectbyContactIDandUserType(ContactID=" + ContactID + ",UserType='" + UserType + "',SearchText='" + SearchText + "')");
  }

  getProjectbyID(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectbyProjectID(PrjID=" + ProjectID + ")");
  }

  getProjectbyIDForDatabase(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProject_byProjectIDForDatabase(PrjID=" + ProjectID + ")");
  }
  getContractandprojectDataby(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetContract_ProjectDataby(ProjectID=" + ProjectID + ")");
  }

  SearchProjects(searchtext: any) {
    //return this.http.get(this.lService.ServiceIP +"/Contract?$filter=indexof(ContractTitle,'" + searchtext + "') gt -1");

    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjects(ContractTitle='" + searchtext + "')");
  }

  SearchProjectsbyContactID(searchtext: string, ContactID: any) {
    //return this.http.get(this.lService.ServiceIP +"/Contract?$filter=indexof(ContractTitle,'" + searchtext + "') gt -1");

    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyContactID(ContractTitle='" + searchtext + "',ContactID=" + ContactID + ")");
  }
  SearchProjectsbyTitleFor_HOP(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/SearchProjectsbyTitleFor_HOP", formData);
  }
  SearchProjectbyTitlefor_NonHOP(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/SearchProjectbyTitlefor_NonHOP", formData);
  }

  SearchProjectsForNonHOPbyContactID(ProjectTitle: string, ContactID: any) {
    //return this.http.get(this.lService.ServiceIP +"/Contract?$filter=indexof(ContractTitle,'" + searchtext + "') gt -1");

    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsForNonHOPbyContactID(ProjectTitle='" + ProjectTitle + "',ContactID=" + ContactID + ")");
  }

  getProjectPhases() {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectPhase");
  }

  getProjectHistory(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectHistory(ProjectID=" + ProjectID + ")");
  }

  getProjectDetailsHistory(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectDetailsHistorybyProjectID(ProjectID=" + ProjectID + ")");
  }

  GetProjectPhaseDateHisstoryByProjectID(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectPhaseDateHisstoryByProjectID(ProjectID=" + ProjectID + ")");
  }

  GetProjecPlanHistoryByProjectID(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjecPlanHistoryByProjectID(ProjectID=" + ProjectID + ")");
  }

  GetProjecMileStoneHistoryByProjectID(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjecMileStoneHistoryByProjectID(ProjectID=" + ProjectID + ")");
  }

  GetContractHistoryByProjectID(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetContractHistoryByProjectID(ProjectID=" + ProjectID + ")");
  }

  GetProjectFinanceSavingsHistorybyProjectID(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinanceSavingsHistorybyProjectID(ProjectID=" + ProjectID + ")");
  }

  GetProjectFinanceSpendHistorybyProjectID(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinanceSpendHistorybyProjectID(ProjectID=" + ProjectID + ")");
  }

  AddorUpdateTasks(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/AddTaks", formData);
  }

  GetTasksByProjectIDandPhase(ProjectID: any, Phase: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetTasksByProjectIDandPhase(ProjectID=" + ProjectID + ",Phase='" + Phase + "')");
  }

  GetTasksforGanttChartByProjectIDandPhase(ProjectID: any, Phase: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetTasksforGanttChartByProjectIDandPhase(ProjectID=" + ProjectID + ",Phase=" + Phase + ")");
  }

  UpdateProjectPhaseStatus(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/UpdateProjectPhaseStatus", formData);
  }

  UpdateTaskStatus(TaskID: any, Status: any) {
    return this.http.get(this.lService.ServiceIP + "/project/UpdateTaskStatus(TaskID=" + TaskID + ", Status=" + Status + ")");
  }

  GetTasksByProjectID(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetTasksByProjectID(ProjectID=" + ProjectID + ")");
  }

  GetTaskbyTaskID(TaskID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetTaskbyTaskID(TaskID=" + TaskID + ")");
  }
  DeleteTask(TaskID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/TaskDelete(TaskID=" + TaskID + ")");
  }
  AddprojectPhaseDates(fromData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/AddProjectPhases", fromData);
  }
  UpdateprojectPhaseDates(fromData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/UpdateProjectPhases", fromData);
  }

  GetProjectPhaseDatesbyProjectID_PhaseID(ProjectID: any, PhaseType: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectPhaseDatesbyProjectID(ProjectID=" + ProjectID + ",PhaseType='" + PhaseType + "')");
  }

  GetAllContractListByContractType_ContactID(sType: string, ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllContractListBy(Type='" + sType + "', ContactID=" + ContactID + ")");
  }

  AddContractExtend(ContractID: any, ExtendPeriod: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ExtendContract(ContractID=" + ContractID + ",ExtendPeriod=" + ExtendPeriod + ")");
  }

  getContractExtendbyContractID(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetContractExtendByContractID(ContractID=" + ContractID + ")");
  }

  CheckContractTitleExits(ContractTitle: string) {
    return this.http.get(this.lService.ServiceIP + "/Contract?$filter=ContractTitle eq '" + ContractTitle + "'");
  }

  getContactUserByOrganisationName(OrgName: string, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/contact/GetContactUserByOrganisationName(OrganisationName='" + OrgName + "', ContractID=" + ContractID + ")");
  }
  getContactUserByOrganisationwithoutLoggedinUser(OrgName: string, ContractID: any, LoggedinUserID: any) {
    return this.http.get(this.lService.ServiceIP + "/contact/GetContactUserByOrganisationwithoutLoggedinUser(OrganisationName='" + OrgName + "', ContractID=" + ContractID + ",LoggedinUserID=" + LoggedinUserID + ")");
  }

  GetUsersbyLoginContactID(LoginContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/contact/GetUsersbyLoginContactID(LoginContactID=" + LoginContactID + ")");
  }

  AllowcateContracttoUsers(FK_ContractID: any, FK_ProjectID: any, FK_ContactID: any, AllowcationStatus: any, NewContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/AllowcateContracttoUsers(FK_ContractID=" + FK_ContractID + ",FK_ProjectID=" + FK_ProjectID + ",FK_ContactID=" + FK_ContactID + ",AllowcationStatus=" + AllowcationStatus + ",NewContractID=" + NewContractID + ")");
  }
  GetContractAllocationbyContractID(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetContractAllocationbyContractID(ContractID=" + ContractID + ")");
  }
  AddprojectFinance(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/AddProjectFinance", formData);
  }
  GetProjectFinancebyContractID(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinancebyContractID(ContractID=" + ContractID + ")");

  }

  GetProjectFinancebyProjectID(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinancebyProjectID(ProjectID=" + ProjectID + ")");

  }

  getContractHistoryby(ContractID: number) {
    return this.http.get(this.lService.ServiceIP + "/ContractHistory?$filter=FK_ContractID eq " + ContractID);
  }

  GetProjectFinanceHistorybyContractID(ContractID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinanceHistorybyContractID(ContractID=" + ContractID + ")");
  }

  GetProjectFinanceHistorybyProject(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinanceHistorybyProject(ProjectID=" + ProjectID + ")");
  }

  GetNumberofProjectsbyRisk(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsbyRisk(ContactID=" + ContactID + ")");
  }
  GetValueofProjectsbyRisk(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsbyRisk(ContactID=" + ContactID + ")");
  }
  GetValueofProjectsForCategorybyRisk(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForCategorybyRisk(ContactID=" + ContactID + ")");
  }

  GetValueofProjectsForPrioritybyRisk(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForPrioritybyRisk(ContactID=" + ContactID + ")");
  }

  GetTaskStatusByProjectIDandPhase(ProjectID: any, Phase: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetTaskStatusByProjectIDandPhase(ProjectID=" + ProjectID + ",Phase='" + Phase + "')");
  }

  GetMileStoneStatusByProjectIDandPhase(ProjectID: any, Phase: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetMileStoneStatusByProjectIDandPhase(ProjectID=" + ProjectID + ",Phase='" + Phase + "')");
  }

  UpdateProjectTimetableSubmitDate(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/UpdateProjectTimetableSubmitDate(ProjectID=" + ProjectID + ")");
  }
  UpdateProjectFinanceSubmittedDate(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/UpdateProjectFinanceSubmittedDate(ProjectID=" + ProjectID + ")");
  }

  UpdateProjectStatus(ProjectID: any, Status: string) {
    return this.http.get(this.lService.ServiceIP + "/project/UpdateProjectStatus(ProjectID=" + ProjectID + ",Status='" + Status + "')");
  }
  GetPhaseandTasksbyProjectID(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetPhaseandTasksbyProjectID(ProjectID=" + ProjectID + ")");
  }
  async GetPhaseandTasksbyProjectIDForGanttChart(ProjectID: any) {
    return await this.http.get(this.lService.ServiceIP + "/project/GetPhaseandTasksbyProjectID(ProjectID=" + ProjectID + ")").toPromise();
  }

  getMileStonePhaseStatus(ProjectID: any, PhaseType: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectPhaseDatesbyProjectID(ProjectID=" + ProjectID + ",PhaseType='" + PhaseType + "')");
  }
  GetAllProjectsbyContactID_Status(ContactID: any, pStatus: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyContactID_Status(ContactID=" + ContactID + ",pStatus='" + pStatus + "')");
  }

  GetProjectsByFilter_Risk(ContactID: any, RiskType: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Risk(ContactID=" + ContactID + ",RiskType='" + RiskType + "')");
  }

  GetProjectsByFilter_ProrityandRisk(ContactID: any, Priority: string, RiskType: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_ProrityandRisk(ContactID=" + ContactID + ",Priority='" + Priority + "',RiskType='" + RiskType + "')");
  }

  GetProjectsByFilter_CategoryandRisk(ContactID: any, Cat: any, RiskType: string) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_CategoryandRisk(ContactID=" + ContactID + ",Cat=" + Cat + ",RiskType='" + RiskType + "')");
  }

  GetAllProjectsbyContactID_Status_OrgID(ContactID: any, pStatus: string, OrgID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyContactID_Status_OrgID(ContactID=" + ContactID + ",pStatus='" + pStatus + "',OrgID=" + OrgID + ")");
  }

  GetProjectsByFilter_Risk_OrgID(ContactID: any, RiskType: string, OrgID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Risk_OrgID(ContactID=" + ContactID + ",RiskType='" + RiskType + "',OrgID=" + OrgID + ")");
  }

  GetProjectsByFilter_ProrityandRiskandOrgID(ContactID: any, Priority: string, RiskType: string, OrgID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_ProrityandRiskandOrgID(ContactID=" + ContactID + ",Priority='" + Priority + "',RiskType='" + RiskType + "',OrgID=" + OrgID + ")");
  }

  GetProjectsByFilter_CategoryandRiskandOrgID(ContactID: any, Cat: any, RiskType: string, OrgID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_CategoryandRiskandOrgID(ContactID=" + ContactID + ",Cat=" + Cat + ",RiskType='" + RiskType + "',OrgID=" + OrgID + ")");
  }

  GetProjectsByFilter_Risk_OrgID_TeamID(ContactID: any, RiskType: string, OrgID: any, TeamID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Risk_OrgID_TeamID(ContactID=" + ContactID + ",RiskType='" + RiskType + "',OrgID=" + OrgID + ",TeamID=" + TeamID + ")");
  }

  GetProjectsByFilter_ProrityandRiskandOrgIDandTeamID(ContactID: any, Priority: string, RiskType: string, OrgID: any, TeamID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_ProrityandRiskandOrgIDandTeamID(ContactID=" + ContactID + ",Priority='" + Priority + "',RiskType='" + RiskType + "',OrgID=" + OrgID + ",TeamID=" + TeamID + ")");
  }

  GetProjectsByFilter_CategoryandRiskandOrgIDandTeamID(ContactID: any, Cat: any, RiskType: string, OrgID: any, TeamID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_CategoryandRiskandOrgIDandTeamID(ContactID=" + ContactID + ",Cat=" + Cat + ",RiskType='" + RiskType + "',OrgID=" + OrgID + ",TeamID=" + TeamID + ")");
  }

  GetAllProjectsbyContactID_Status_OrgID_TeamID(ContactID: any, pStatus: string, OrgID: any, TeamID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyContactID_Status_OrgID_TeamID(ContactID=" + ContactID + ",pStatus='" + pStatus + "',OrgID=" + OrgID + ",TeamID=" + TeamID + ")");
  }

  async GetAllProjectsbyContactIDForGanttChart(ContactID: any) {
    return await this.http.get(this.lService.ServiceIP + "/project/GetAllProjectByTeamForGanttChart(ContactID=" + ContactID + ")").toPromise();
  }

  async GetProjectsForNonHOPbyContactIDForGanttChart(ContactID: any) {
    return await this.http.get(this.lService.ServiceIP + "/project/GetProjectForNonHOPsbyContactIDForGanttChart(ContactID=" + ContactID + ")").toPromise();
  }
  async GetProjectGanttChartbyForTeamUserType(ContactID: any, UserType: string) {
    return await this.http.get(this.lService.ServiceIP + "/project/GetProjectGanttChartbyForTeamUserType(ContactID=" + ContactID + ",UserType='" + UserType + "')").toPromise();
  }

  async GetProjectGanttChartbyContactUserType(ContactID: any, UserType: string) {
    return await this.http.get(this.lService.ServiceIP + "/project/GetProjectGanttChartbyForTeamUserType(ContactID=" + ContactID + ",UserType='" + UserType + "')").toPromise();
  }


  async GetAllProjectByTeamForGanttChart(ContactID: any) {
    return await this.http.get(this.lService.ServiceIP + "/project/GetAllProjectByTeamForGanttChart(ContactID=" + ContactID + ")").toPromise();
  }

  async GetProjectForNonHOPByTeamForGanttChart(ContactID: any) {
    return await this.http.get(this.lService.ServiceIP + "/project/GetProjectforNonHOPByTeamForGanttChart(ContactID=" + ContactID + ")").toPromise();
  }

  //Date 10 Dec 2019
  GetAllProjectsbyContactID_Status_ProjectOwner(ContactID: any, pStatus: string, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyContactID_Status_ProjectOwner(ContactID=" + ContactID + ",pStatus='" + pStatus + "',ProjectOwner=" + ProjectOwner + ")");
  }

  GetAllProjectsbyContactID_Status_OrgID_ProjectOwner(ContactID: any, pStatus: string, OrgID: any, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyContactID_Status_OrgID_ProjectOwner(ContactID=" + ContactID + ",pStatus='" + pStatus + "',OrgID=" + OrgID + ",ProjectOwner=" + ProjectOwner + ")");
  }

  GetProjectsByFilter_Risk_ProjectOwner(ContactID: any, RiskType: string, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Risk_ProjectOwner(ContactID=" + ContactID + ",RiskType='" + RiskType + "',ProjectOwner=" + ProjectOwner + ")");
  }

  GetProjectsByFilter_Risk_OrgID_ProjectOwner(ContactID: any, RiskType: string, OrgID: any, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Risk_OrgID_ProjectOwner(ContactID=" + ContactID + ",RiskType='" + RiskType + "',OrgID=" + OrgID + ",ProjectOwner=" + ProjectOwner + ")");
  }

  GetProjectsByFilter_Prority_Risk_ProjectOwner(ContactID: any, Priority: string, RiskType: string, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Prority_Risk_ProjectOwner(ContactID=" + ContactID + ",Priority='" + Priority + "',RiskType='" + RiskType + "',ProjectOwner=" + ProjectOwner + ")");
  }

  GetProjectsByFilter_Prority_Risk_OrgID_ProjectOwner(ContactID: any, Priority: string, RiskType: string, OrgID: any, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Prority_Risk_OrgID_ProjectOwner(ContactID=" + ContactID + ",Priority='" + Priority + "',RiskType='" + RiskType + "',OrgID=" + OrgID + ",ProjectOwner=" + ProjectOwner + ")");
  }

  GetProjectsByFilter_Category_Risk_ProjectOwner(ContactID: any, Cat: any, RiskType: string, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Category_Risk_ProjectOwner(ContactID=" + ContactID + ",Cat=" + Cat + ",RiskType='" + RiskType + "',ProjectOwner=" + ProjectOwner + ")");
  }

  GetProjectsByFilter_Category_Risk_OrgID_ProjectOwner(ContactID: any, Cat: any, RiskType: string, OrgID: any, ProjectOwner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectsByFilter_Category_Risk_OrgID_ProjectOwner(ContactID=" + ContactID + ",Cat=" + Cat + ",RiskType='" + RiskType + "',OrgID=" + OrgID + ",ProjectOwner=" + ProjectOwner + ")");
  }
  //..........................................................................................................................................................

  GetNumberofProjectsForPrioritybyRisk(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForPrioritybyRisk(ContactID=" + ContactID + ")");
  }

  GetNumberofProjectsForPrioritybyRisk_byOrg(OrgID: any, ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForPrioritybyRisk_byOrg(OrgID=" + OrgID + ",ContactID=" + ContactID + ")");
  }

  GetNumberofProjectsForPrioritybyRisk_byOrg_byTeam(OrgID: any, TeamID: any, ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForPrioritybyRisk_byOrg_byTeam(OrgID=" + OrgID + ",TeamID=" + TeamID + ",ContactID=" + ContactID + ")");
  }
  GetNumberofProjectsForPrioritybyRisk_byOwner(ContactID: any, OwnerName: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForPrioritybyRisk_byOwner(ContactID=" + ContactID + ",OwnerName=" + OwnerName + ")");
  }
  GetNumberofProjectsForPrioritybyRisk_byOrg_byOwner(OrgID: any, OwnerName: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForPrioritybyRisk_byOrg_byOwner(OrgID=" + OrgID + ",OwnerName=" + OwnerName + ")");
  }

  GetNumberofProjectsForCategorybyRisk(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForCategorybyRisk(ContactID=" + ContactID + ")");
  }

  GetNumberofProjectsForCategorybyRisk_byOrgID(OrgID: any, ContactID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForCategorybyRisk_byOrgID(OrgID=" + OrgID + ",ContactID=" + ContactID + ")");
  }
  GetNumberofProjectsForCategorybyRisk_byOrgID_byTeam(OrgID: any, TeamID: any, ContactID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForCategorybyRisk_byOrgID_byTeam(OrgID=" + OrgID + ",TeamID=" + TeamID + ",ContactID=" + ContactID + ")");
  }
  GetNumberofProjectsForCategorybyRisk_OwnerName(ContactID: any, OwnerName: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForCategorybyRisk_OwnerName(ContactID=" + ContactID + ",OwnerName=" + OwnerName + ")");
  }
  GetNumberofProjectsForCategorybyRisk_byOrg_byOwnerName(OrgID: any, OwnerName: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsForCategorybyRisk_byOrg_byOwnerName(OrgID=" + OrgID + ",OwnerName=" + OwnerName + ")");
  }
  GetUsersUnderAllOrganisationbyContactID(ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/contact/GetUsersUnderAllOrganisation(ContactID=" + ContactID + ")");
  }

  GetProjectOwnerbyOrganisation(OrgID: any) {
    return this.http.get(this.lService.ServiceIP + "/contact/GetProjectOwnerbyOrganisation(OrgID=" + OrgID + ")");
  }

  // GetProjectOwnerbyOrganisation(ContactID:number,OrgID:any)
  // {
  //   return this.http.get(this.lService.ServiceIP +"/contact/GetProjectOwnerbyOrganisation(ContactID="+ ContactID +", OrgID="+ OrgID +")");
  // }

  GetAllProjectOwnersBy_OrgID(OrgID: any) {
    return this.http.get(this.lService.ServiceIP + "/contact/GetAllProjectOwnersBy_OrgID(OrgID=" + OrgID + ")");
  }
  GetAllProjectOwnersBy_OrgID_withoutCurrentUser(OrgID: any, ContactID: any, LoginUser: Number) {
    return this.http.get(this.lService.ServiceIP + "/contact/GetAllProjectOwnersBy_OrgID_withoutCurrentUser(OrgID=" + OrgID + ",ContactID=" + ContactID + ",LoginUser=" + LoginUser + ")");
  }

  //..........................................................................................................................................................
  //addded by zuber on 07/12/2019
  GetNumberofProjectsbyRiskByOrg(OrganisationID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsbyRisk_ByOrg(OrganisationID=" + OrganisationID + ",ContactID=" + ContactID + ")");
  }
  GetValueofProjectsbyRiskByOrg(OrganisationID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsbyRisk_ByOrg(OrganisationID=" + OrganisationID + ",ContactID=" + ContactID + ")");
  }
  GetValueofProjectsForCategorybyRiskByOrg(OrganisationID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForCategorybyRisk_ByOrg(OrganisationID=" + OrganisationID + ",ContactID=" + ContactID + ")");
  }

  GetValueofProjectsForPrioritybyRiskByOrg(OrganisationID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForPrioritybyRisk_ByOrg(OrganisationID=" + OrganisationID + ",ContactID=" + ContactID + ")");
  }
  //
  //added by zuber on 09/12/2019
  GetNumberofProjectsbyRiskByTeam(OrganisationID: any, TeamID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsbyRisk_ByTeamID(OrganisationID=" + OrganisationID + ",TeamID=" + TeamID + ",ContactID=" + ContactID + ")");
  }
  GetValueofProjectsbyRiskByTeam(OrganisationID: any, TeamID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsbyRisk_ByTeamID(OrganisationID=" + OrganisationID + ",TeamID=" + TeamID + ",ContactID=" + ContactID + ")");
  }
  GetValueofProjectsForCategorybyRiskByTeam(OrganisationID: any, TeamID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForCategorybyRisk_ByTeamID(OrganisationID=" + OrganisationID + ",TeamID=" + TeamID + ",ContactID=" + ContactID + ")");
  }

  GetValueofProjectsForPrioritybyRiskByTeam(OrganisationID: any, TeamID: any, ContactID: Number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForPrioritybyRisk_ByTeamID(OrganisationID=" + OrganisationID + ",TeamID=" + TeamID + ",ContactID=" + ContactID + ")");
  }

  GetNumberofProjectsbyRiskByOwner(ContactID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsbyRiskOwner(ContactID=" + ContactID + ",Owner=" + Owner + ")");
  }
  GetValueofProjectsbyRiskByOwner(ContactID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsbyRiskOwner(ContactID=" + ContactID + ",Owner=" + Owner + ")");
  }
  GetValueofProjectsForCategorybyRiskByOwner(ContactID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForCategorybyRiskOwner(ContactID=" + ContactID + ",Owner=" + Owner + ")");
  }

  GetValueofProjectsForPrioritybyRiskByOwner(ContactID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForPrioritybyRisk(ContactID=" + ContactID + ",Owner=" + Owner + ")");
  }

  //
  GetNumberofProjectsbyRiskByOrg_Owner(OrganisationID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetNumberofProjectsRiskByOrg_Owner(OrganisationID=" + OrganisationID + ",Owner=" + Owner + ")");
  }
  GetValueofProjectsbyRiskByOrg_Owner(OrganisationID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsRiskByOrg_Owner(OrganisationID=" + OrganisationID + ",Owner=" + Owner + ")");
  }
  GetValueofProjectsForCategorybyRiskByOrg_Owner(OrganisationID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForCategoryRiskByOrg_Owner(OrganisationID=" + OrganisationID + ",Owner=" + Owner + ")");
  }

  GetValueofProjectsForPrioritybyRiskByOrg_Owner(OrganisationID: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetValueofProjectsForPriorityRiskByOrg_Owner(OrganisationID=" + OrganisationID + ",Owner=" + Owner + ")");
  }
  UpdateProjectFlag(ProjectID: any, Action: string) {
    return this.http.get(this.lService.ServiceIP + "/project/ProjectFlagUpdate(ProjectID=" + ProjectID + ",aType='" + Action + "')");
  }

  DeleteUploadedProject(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/DeleteUploadedProject(ProjectID=" + ProjectID + ")");
  }

  //added on 20/01/2020 by zuber
  GetAllProjectsbyFinanceYear(ContactID: any, FinanceYear: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYear(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "')");
  }

  GetAllProjectsbyFinanceYear_Org(ContactID: any, OrganisationID: any, FinanceYear: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYear_Org(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "')");
  }

  GetAllProjectsbyFinanceYear_Org_Team(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYear_Org_Team(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");
  }

  GetAllProjectsbyFinanceYear_Owner(ContactID: any, FinanceYear: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYear_Owner(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");
  }

  GetAllProjectsbyFinanceYear_Org_Owner(OrganisationID: any, FinanceYear: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYear_Org_Owner(OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");
  }

  //
  //changed on 21/02/2020
  GetAllProjectsbyLifetime(ContactID: any, FinanceYear: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyLifetime(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "')");
  }

  //changed on 21/02/2020
  GetAllProjectsbyLifetime_Org(ContactID: any, OrganisationID: any, FinanceYear: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyLifetime_Org(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "')");
  }

  //changed on 22/02/2020
  GetAllProjectsbyLifetime_Org_Team(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyLifetime_Org_Team(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");
  }

  //changed on 22/02/2020
  GetAllProjectsbyLifetime_Owner(ContactID: any, FinanceYear: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyLifetime_Owner(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");
  }

  //changed on 22/02/2020
  GetAllProjectsbyLifetime_Org_Owner(OrganisationID: any, FinanceYear: any, Owner: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyLifetime_Org_Owner(OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");
  }

  ProjectClosureMailSend(ContactID: any, ProjectID: any, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ProjectClosureMailSend(ContactID=" + ContactID + ",ProjectID=" + ProjectID + ",ContractID=" + ContractID + ")");
  }

  ProjectStatusChangeRequestMailSend(ContactID: any, ProjectID: any, NewStatus: string) {
    return this.http.get(this.lService.ServiceIP + "/project/ProjectStatusChangeRequestMailSend(ContactID=" + ContactID + ",ProjectID=" + ProjectID + ",NewStatus='" + NewStatus + "')");
  }

  SendMailtoPOforApprovedProjectRequest(ProjectID: any, rStatus: string, RejectReason: string, ApprovedBy: number) {
    return this.http.get(this.lService.ServiceIP + "/project/SendMailtoPOforApprovedProjectRequest(ProjectID=" + ProjectID + ",rStatus='" + rStatus + "',RejectReason='" + RejectReason + "', ApprovedBy=" + ApprovedBy + ")");
  }

  ContractReProcureMailSendtoPO(TeamManagerID: any, ProcurementOfficerID: any, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ReProcureContractSendMailtoPO(TeamManagerID=" + TeamManagerID + ",ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ")");
  }

  ExtendContractRequestSendMailtoPO(TeamManagerID: any, ProcurementOfficerID: any, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ExtendContractRequestSendMailtoPO(TeamManagerID=" + TeamManagerID + ",ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ")");
  }

  AcceptorRejectProjectRequestmail(TeamManagerID: any, ProcurementOfficerID: any, ContractID: any, sResponse: string, ReasonforRejection: string) {
    return this.http.get(this.lService.ServiceIP + "/project/AcceptorRejectProjectRequestmail(TeamManagerID=" + TeamManagerID + ",ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ",sResponse='" + sResponse + "',ReasonforRejection='" + ReasonforRejection + "')");
  }


  RejectExtendContractRequest_SendMailto_TM(TeamManagerID: any, ProcurementOfficerID: any, ContractID: any, sResponse: string, ReasonforRejection: string) {
    return this.http.get(this.lService.ServiceIP + "/project/RejectExtendContractRequest_SendMailto_TM(TeamManagerID=" + TeamManagerID + ",ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ",sResponse='" + sResponse + "',ReasonforRejection='" + ReasonforRejection + "')");
  }


  ReProcure_ExtendContractSendMailtoTM(ProcurementOfficerID: any, ContractID: any, fAction: string) {
    return this.http.get(this.lService.ServiceIP + "/project/ReProcure_ExtendContractSendMailtoTM(ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ",fAction='" + fAction + "')");
  }

  AcceptorRejectReProcure_ExtendContractStatusMail(TeamManagerID: any, ProcurementOfficerID: any, ContractID: any, fAction: string, AcceptorReject: string, ReasonforReject: string) {
    return this.http.get(this.lService.ServiceIP + "/project/AcceptorRejectReProcure_ExtendContractStatusMail(TeamManagerID=" + TeamManagerID + ",ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ",fAction='" + fAction + "',AcceptorReject='" + AcceptorReject + "',ReasonforReject='" + ReasonforReject + "')");
  }


  ExtendContractSendMailtoPO(TeamManagerID: any, ProcurementOfficerID: any, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ExtendContractSendMailtoPO(TeamManagerID=" + TeamManagerID + ",ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ")");
  }

  SendMailtoTMforProjectApproval(ProcurementOfficerID: any, ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/SendMailtoTMforProjectApproval(ProcurementOfficerID=" + ProcurementOfficerID + ",ProjectID=" + ProjectID + ")");
  }

  SendMailtoPO_AcceptorRejectProjectApproval(ProcurementOfficerID: any, ProjectID: any, rStatus: string, RejectReason: string, TMID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/SendMailtoPO_AcceptorRejectProjectApproval(ProcurementOfficerID=" + ProcurementOfficerID + ",ProjectID=" + ProjectID + ",rStatus='" + rStatus + "',RejectReason='" + RejectReason + "',TMID=" + TMID + ")");
  }

  Accept_RejectProjectStatusChangeRequestto_PO(POID: number, ProjectID: number, NewStatus: number, rStatus: string, RejectReason: string, ContactID: number ){
    return this.http.get(this.lService.ServiceIP + "/project/Accept_RejectProjectStatusChangeRequestto_PO(POID=" + POID + ",ProjectID=" + ProjectID + ",NewStatus=" + NewStatus + ",rStatus='" + rStatus + "',RejectReason='" + RejectReason + "',ContactID=" + ContactID + ")");
  }

  ContractExtendConfirmationMailto_TM(ProcurementOfficerID: any, ContractID: any, ExtendPeriod: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ContractExtendConfirmationMailto_TM(ProcurementOfficerID=" + ProcurementOfficerID + ",ContractID=" + ContractID + ",ExtendPeriod=" + ExtendPeriod + ")");
  }

  ProjectCreateConfirmationMailto_TM(ProcurementOfficerID: any, ProjectID: any, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ProjectCreateConfirmationMailto_TM(ProcurementOfficerID=" + ProcurementOfficerID + ",ProjectID=" + ProjectID + ",ContractID=" + ContractID + ")");
  }
  //24 Jan 2020

  GetAllContractListByForNonHOP(Type: string, ContactID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllContractListByForNonHOP(Type='" + Type + "',ContactID=" + ContactID + ")");
  }

  //added by zuber on 23/01/2020
  getSlippageValueResubmitProject(ProjectID: any, ContractStartDate: string, FinancialData: string) {
    return this.http.get(this.lService.ServiceIP + "/project/getSlippageValueResubmitProject(ProjectID=" + ProjectID + ",ContractStartDate='" + ContractStartDate + "',FinancialData='" + FinancialData + "')");
  }

  getSlippageValueResubmitProjectPost(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/getSlippageValueResubmitProject", formData);
  }

  getSlippageValueResubmitProject1(ProjectID: any, ContractStartDate: string, FinancialData: string) {
    return this.http.get(this.lService.ServiceIP + "/project/getSlippageValueResubmitProject1(ProjectID=" + ProjectID + ",ContractStartDate='" + ContractStartDate + "',FinancialData='" + FinancialData + "')");
  }

  //added by zuber on 24/02/2020
  ResubmitProjectTimeTable(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/ResubmitProjectTimeTable", formData);
  }

  AddProjectNotification_Resubmit(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/AddProjectNotification_Resubmit(ProjectID=" + ProjectID + ")");
  }

  AddProjectPhases_Resubmit(fromData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/AddProjectPhases_Resubmit", fromData);
  }

  SendMailForProjectResubmit(resubmitID: any, rStatus: string, Type: string) {
    return this.http.get(this.lService.ServiceIP + "/project/SendMailForProjectResubmit(resubmitID=" + resubmitID + ",rStatus='" + rStatus + "',Type='" + Type + "')");
  }

  SendMailForProjectResubmit_TeamManager(resubmitID: any, rStatus: string) {
    return this.http.get(this.lService.ServiceIP + "/project/SendMailForProjectResubmit_TeamManager(resubmitID=" + resubmitID + ",rStatus='" + rStatus + "')");
  }

  ProjectMileStoneDataExists(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ProjectMileStoneDataExists(ProjectID=" + ProjectID + ")");
  }

  //added on 28/01/2020
  RejectResubmit(resubmitID: any, ProjectID: any, Reason: any, UserId: any) {
    return this.http.get(this.lService.ServiceIP + "/project/RejectResubmit(resubmitID=" + resubmitID + ",ProjectID=" + ProjectID + ",Reason='" + Reason + "',UserID=" + UserId + ")");
  }

  ApproveResubmit(resubmitID: any, ProjectID: any, UserId: any) {
    return this.http.get(this.lService.ServiceIP + "/project/ApproveResubmit(resubmitID=" + resubmitID + ",ProjectID=" + ProjectID + ",UserID=" + UserId + ")");
  }

  //added on 29/01/2020
  GetContractHistoryByProjectID_Resubmit(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetContractHistoryByProjectID_Resubmit(ProjectID=" + ProjectID + ")");
  }

  GetProjectFinanceSavingsHistorybyProjectID_Resubmit(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinanceSavingsHistorybyProjectID_Resubmit(ProjectID=" + ProjectID + ")");
  }

  GetProjectFinanceSpendHistorybyProjectID_Resubmit(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectFinanceSpendHistorybyProjectID_Resubmit(ProjectID=" + ProjectID + ")");
  }

  GetProjecPlanHistoryByProjectID_Resubmit(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjecPlanHistoryByProjectID_Resubmit(ProjectID=" + ProjectID + ")");
  }

  GetProjecMileStoneHistoryByProjectID_Resubmit(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjecMileStoneHistoryByProjectID_Resubmit(ProjectID=" + ProjectID + ")");
  }

  CheckResubmitStatus(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/CheckResubmitStatus(ProjectID=" + ProjectID + ")");
  }

  //Date 03 Feb 2020

  CheckFinanceandMilestoneDataEnteredForProject(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/CheckFinanceandMilestoneDataEnteredForProject(ProjectID=" + ProjectID + ")");
  }

  //Date 04 Feb 2020
  Createproject(ProjectData: any) {
    return this.http.post(this.lService.ServiceIP + "/project/CreateProject", ProjectData);
  }

  // Createproject(formData:any)
  // {
  //   return this.http.post(this.lService.ServiceIP +"/project/CreateProject",formData);
  // }
  //..................................................................................................................................................................

  //24/01/2020 - project complete status by Vikas

  GetProjectCompletedStatus(ContactID: any, pStatus: string, OrganisationID: any, FinanceYear: any, TeamID: any, OwnerID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCompletedProjectsbyContactID_Status(ContactID=" + ContactID + ",pStatus='" + pStatus + "',OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner=" + OwnerID + ",TeamID=" + TeamID + ")");

  }

  //25/01/2020 - project complete status
  GetProjectInCompletedStatus(ContactID: any, pStatus: string, OrganisationID: any, FinanceYear: any, TeamID: any, OwnerID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllInCompletedProjectsbyContactID_Status(ContactID=" + ContactID + ",pStatus='" + pStatus + "',OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner=" + OwnerID + ",TeamID=" + TeamID + ")");

  }

  //27/01/2020 - project complete status
  GetProjectCancelStatus(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any, OwnerID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCancelledProjectsbyContactID_Status(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner=" + OwnerID + ",TeamID=" + TeamID + ")");
  }

  // 14 Feb 2020
  GetProjectBaseLineData(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectBaseLineData(ProjectID=" + ProjectID + ")");
  }

  getContractStartDate(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/getContractStartDate(ContractID=" + ContractID + ")");
  }

  GetEstimatedContractValue_Baseline(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetEstimatedBaselineContractValue(ProjectID=" + ProjectID + ")");
  }

  GetEstimatedContractSavings_Baseline(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetEstimatedBaselineContractSavings(ProjectID=" + ProjectID + ")");
  }

  GetProjectBaseLineData1(ProjectID: any, RevisionVersion: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectBaseLineData1(ProjectID=" + ProjectID + ",RevisionVersion=" + RevisionVersion + ")");
  }
  GetProjectBaseLineDataRevisionHistory(ProjectID: any, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectBaseLineDataRevisionHistory(ProjectID=" + ProjectID + ",ContractID=" + ContractID + ")");
  }

  getContractStartDate1(ContractID: any, ProjectID: any, RevisionVersion: number) {
    return this.http.get(this.lService.ServiceIP + "/project/getContractStartDate1(ContractID=" + ContractID + ",ProjectID=" + ProjectID + ",RevisionVersion=" + RevisionVersion + ")");
  }

  getResubmissionContractStartDate(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/getResubmissionContractStartDate(ContractID=" + ContractID + ")");
  }

  GetEstimatedContractValue_Baseline1(ProjectID: number, RevisionVersion: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetEstimatedBaselineContractValue1(ProjectID=" + ProjectID + ",RevisionVersion=" + RevisionVersion + ")");
  }

  GetEstimatedContractSavings_Baseline1(ProjectID: number, RevisionVersion: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetEstimatedBaselineContractSavings1(ProjectID=" + ProjectID + ",RevisionVersion=" + RevisionVersion + ")");
  }

  GetProjectRevisionData(ProjectID: number) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectRevisionData(ProjectID=" + ProjectID + ")");
  }

  //added on 23/02/2020
  GetAllCompletedProjectsbyFinanceYear(ContactID: any, FinanceYear: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCompletedProjectsbyFinanceYear(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllCompletedProjectsbyFinanceYear_ByOrg(ContactID: any, OrganisationID: any, FinanceYear: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCompletedProjectsbyFinanceYear_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllCompletedProjectsbyFinanceYear_ByOrgTeam(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCompletedProjectsbyFinanceYear_ByOrgTeam(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',TeamID =" + TeamID + ",Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllCompletedProjectsbyFinanceYear_ByOwner(ContactID: any, FinanceYear: any, Owner: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCompletedProjectsbyFinanceYear_ByOwner(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllCompletedProjectsbyFinanceYear_ByOrgOwner(OrganisationID: any, FinanceYear: any, Owner: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCompletedProjectsbyFinanceYear_ByOrgOwner(OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  //added on 23/02/2020
  GetAllInCompleteProjectsbyFinanceYear(ContactID: any, FinanceYear: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllInCompleteProjectsbyFinanceYear(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllInCompleteProjectsbyFinanceYear_ByOrg(ContactID: any, OrganisationID: any, FinanceYear: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllInCompleteProjectsbyFinanceYear_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllInCompleteProjectsbyFinanceYear_ByOrgTeam(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllInCompleteProjectsbyFinanceYear_ByOrgTeam(ContactID=" + ContactID + ", OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',TeamID =" + TeamID + ",Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllInCompleteProjectsbyFinanceYear_ByOwner(ContactID: any, FinanceYear: any, Owner: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllInCompleteProjectsbyFinanceYear_ByOwner(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  GetAllInCompleteProjectsbyFinanceYear_ByOrgOwner(OrganisationID: any, FinanceYear: any, Owner: any, Statustype: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllInCompleteProjectsbyFinanceYear_ByOrgOwner(OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',Statustype='" + Statustype + "',type='" + type + "')");
  }

  //added on 23/02/2020
  GetAllCancelProjectsbyFinanceYear(ContactID: any, FinanceYear: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCancelProjectsbyFinanceYear(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',type='" + type + "')");
  }

  GetAllCancelProjectsbyFinanceYear_ByOrg(ContactID: any, OrganisationID: any, FinanceYear: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCancelProjectsbyFinanceYear_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',type='" + type + "')");
  }

  GetAllCancelProjectsbyFinanceYear_ByOrgTeam(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCancelProjectsbyFinanceYear_ByOrgTeam(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',TeamID =" + TeamID + ",type='" + type + "')");
  }

  GetAllCancelProjectsbyFinanceYear_ByOwner(ContactID: any, FinanceYear: any, Owner: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCancelProjectsbyFinanceYear_ByOwner(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',type='" + type + "')");
  }

  GetAllCancelProjectsbyFinanceYear_ByOrgOwner(OrganisationID: any, FinanceYear: any, Owner: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllCancelProjectsbyFinanceYear_ByOrgOwner(OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',type='" + type + "')");
  }

  //added on 23/02/2020
  GetAllProjectsbyFinanceYearSlippage(ContactID: any, FinanceYear: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYearSlippage(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',type='" + type + "')");
  }

  GetAllProjectsbyFinanceYearSlippage_ByOrg(ContactID: any, OrganisationID: any, FinanceYear: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYearSlippage_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',type='" + type + "')");
  }

  GetAllProjectsbyFinanceYearSlippage_ByOrgTeam(ContactID: any, OrganisationID: any, FinanceYear: any, TeamID: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYearSlippage_ByOrgTeam(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',TeamID =" + TeamID + ",type='" + type + "')");
  }

  GetAllProjectsbyFinanceYearSlippage_ByOwner(ContactID: any, FinanceYear: any, Owner: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYearSlippage_ByOwner(ContactID=" + ContactID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',type='" + type + "')");
  }

  GetAllProjectsbyFinanceYearSlippage_ByOrgOwner(OrganisationID: any, FinanceYear: any, Owner: any, type: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetAllProjectsbyFinanceYearSlippage_ByOrgOwner(OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner ='" + Owner + "',type='" + type + "')");
  }

  //Date : 26 Feb 2020

  GetCategoryID_byName(formData: any) {
    return this.http.post(this.lService.ServiceIP + "/Category/GetCategoryID_byName", formData);
  }

  GetProjectResubmissionData(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetProjectResubmissionData(ProjectID=" + ProjectID + ")");
  }

  GetProjectResubmissionReason(ResubmitID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetResubmitReason(ResubmitID=" + ResubmitID + ")");
  }

  GetResubmitReasonByProjectAndReason(ProjectId: any, Revision: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetResubmitReasonByProjectAndReason(ProjectId=" + ProjectId + ",Revision=" + Revision + ")");
  }

  GetEstimatedContractSavings_Resubmission(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetEstimatedBaselineContractSavingsResubmission(ProjectID=" + ProjectID + ")");
  }

  GetEstimatedContractValue_Resubmission(ProjectID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/GetEstimatedBaselineContractValueResubmission(ProjectID=" + ProjectID + ")");
  }

  IsTemporaryProject(ProjectID: any, ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/IsTemporaryProject(ProjectID=" + ProjectID + ",ContractID=" + ContractID + ")");
  }

  DeleteTemporaryProject(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/DeleteTemporaryProject(ContractID=" + ContractID + ")");
  }

  UpdateTemporaryProjectTMtoPO(ContractID: any) {
    return this.http.get(this.lService.ServiceIP + "/project/UpdateTemporaryProjectTMtoPO(ContractID=" + ContractID + ")");
  }


}
