import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertComponent } from '../../alert/alert.component';
import { Router, ActivatedRoute, NavigationEnd  } from '@angular/router';
import {DialogProjectClosureComponent} from '../dialog-project-closure/dialog-project-closure.component';
import {SettingsService} from '../../settings/settings.service';


@Component({
  selector: 'app-dialog-complete-project',
  templateUrl: './dialog-complete-project.component.html',
  styleUrls: ['./dialog-complete-project.component.scss']
})
export class DialogCompleteProjectComponent implements OnInit {
  Initiation:any;
  Planning:any;
  Execution:any;
  Closure:any;
  CancelButton:boolean;
  CompleteButton:boolean;
  OnHoldButton:boolean;
  CompleteTask:number=0;
  ddlCurrentStatus:any;
  ProjectComplete:boolean=false;
  Action:string="Update Status";
  ShowButton:boolean=false;
  //Complete:boolean=false;
  ProjectStatus:any; 
  ProjectMSList : any;
  bEnforceProjectClosure : boolean = true;
  ProjectTitle:string;
  ContractID:any; 
  
  constructor(private router:Router,public dialog: MatDialog, private DbService:ProjectDatabaseService,public dialogRef: MatDialogRef<DialogCompleteProjectComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private setService:SettingsService) 
  { 
    this.LoadAllCaptions();
	if(sessionStorage.getItem("usertype") =="Procurement Officer" || sessionStorage.getItem("usertype") =="Team Manager")
    {
      this.ShowButton=true;
    }
    else
    {
      if(this.data.ProjectStatus == "Complete")
      {
        this.ShowButton=true;
      }
    }
    
    if(this.data.ProjectID !=null)
    {
      //alert(this.data.ProjectStatus);
	  this.EnforceProjectClosure();

      this.ddlCurrentStatus=this.data.ProjectStatus;
      if(this.data.ProjectStatus == "Not Started")
      {
        //this.ProjectStatus= [{ Status: 'Not Started',Value:'Not Started' }, { Status: 'Cancel',Value:'Cancelled'}, { Status: 'On-hold',Value:'On hold' }];
        this.ProjectStatus= [{ Status: 'Not Started',Value:'Not Started' }, { Status: 'Cancelled',Value:'Cancelled'}];
      }
      else if(this.data.ProjectStatus == "In Progress")
      {
        this.ProjectStatus= [{ Status: 'In-Progress',Value:'In Progress'},{ Status: 'Completed',Value:'Complete'}, { Status: 'Cancelled',Value:'Cancelled'}, { Status: 'On-hold',Value:'On hold' }];
      }
      else if(this.data.ProjectStatus == "Cancelled")
      {
        this.ProjectStatus= [{ Status: 'Cancelled',Value:'Cancelled'}];
      }
      else if(this.data.ProjectStatus == "Complete")
      {
        	this.ProjectStatus= [{ Status: 'Completed',Value:'Complete'}];
			this.ProjectComplete=true;
			this.Action="Close Project";
		
      }
      else if(this.data.ProjectStatus == "Awaiting Sign-Off")
      {
        this.ProjectStatus= [{ Status: 'Awaiting Sign-Off',Value:'Awaiting Sign-Off'}];
        this.ProjectComplete=true;
        this.ShowButton=false;
      }
      else if(this.data.ProjectStatus == "Closed")
      {
        this.ProjectStatus= [{ Status: 'Closed',Value:'Closed'}];
        this.ProjectComplete=true;
        this.ShowButton=false;
      }
      else
      {
        this.ProjectStatus= [{ Status: 'On-hold',Value:'On hold' },{ Status: 'Cancelled',Value:'Cancelled'},{ Status: 'In-Progress',Value:'In Progress'}];
      }
      this.GetTaskStatusByProjectIDandPhase(this.data.ProjectID,this.getProjectCaption(1),"1");
      this.GetTaskStatusByProjectIDandPhase(this.data.ProjectID,this.getProjectCaption(2),"2");
      this.GetTaskStatusByProjectIDandPhase(this.data.ProjectID,this.getProjectCaption(3),"3");
      this.GetTaskStatusByProjectIDandPhase(this.data.ProjectID,this.getProjectCaption(4),"4");
	  
      
    }
  }

  ngOnInit() {
     this.GetProjectData(this.data.ProjectID);
    
  }
  
  GetProjectData(ProjectID:any)
  {
    this.DbService.getProjectDatabyProjectID(ProjectID).subscribe((data:any) =>
    {
      this.ProjectTitle=data.ProjectTitle;
      this.ContractID=data.FK_ContractID;
    });
  }
  
  UpdateProjectStatus()
  {
    debugger;
    
    if(this.Action =="Close Project")
    {
      if (this.bEnforceProjectClosure)
	    this.opendialogProjectClosure();
	  else
        this.SubmitforApproval();
    }
	else
	if (this.Action =="Submit Project")
    {
        this.SubmitforApproval();
    }
    else
    {
      if(this.ddlCurrentStatus =="Complete" && this.CompleteTask != 4)
      {
        alert("Project cannot complete unless all Project Phases complete");
      }
      else if(this.CompleteTask == 4 && (this.ddlCurrentStatus =="Not Started" || this.ddlCurrentStatus =="In Progress" || this.ddlCurrentStatus =="On hold"))
      {
        alert("Cannot set Project Status as "+ this.ddlCurrentStatus +". Because all project phases are complete.");
      }
      else if(this.ddlCurrentStatus =="Not Started" && this.data.ProjectStatus =="In Progress")
      {
        alert("Project already In progress. You cannot set status as Not Started");
      }
      else
      {
        if(sessionStorage.getItem("usertype")=="Procurement Officer")
        {
          this.setService.getSystemSettingswithoutcontactID().subscribe((data:any) =>
          {
            if(data.ProjectApproval =="no")
            {
              this.DbService.UpdateProjectStatus(this.data.ProjectID,this.ddlCurrentStatus).subscribe((data:any) =>
              {
                if(data =="Updated")
                {
                  alert("Project Status Updated Successfully");
                  this.router.navigate(["/dashboard/projects/database"]);
                  this.dialog.closeAll();
                }
                else
                {
                  alert(data);
                  this.router.navigate(["/dashboard/projects/database"]);
                  this.dialog.closeAll();
                }
              });
            }
            else
            {
                this.DbService.ProjectStatusChangeRequestMailSend(sessionStorage.getItem("ContactID"),this.data.ProjectID,this.ddlCurrentStatus).subscribe((data:any) =>
                {
                  alert("Your request to change the status of your project has been submitted for approval");
                  this.dialog.closeAll();
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = 'reload';
                  this.router.navigate(['dashboard/project/details'], { queryParams: {ProjectID: this.data.ProjectID}});
                  
                });
              }
          });
          
        }
        else
        {
          this.DbService.UpdateProjectStatus(this.data.ProjectID,this.ddlCurrentStatus).subscribe((data:any) =>
          {
            if(data =="Updated")
            {
              alert("Project Status Updated Successfully");
              this.router.navigate(["/dashboard/projects/database"]);
              this.dialog.closeAll();
            }
            else
            {
              alert(data);
              this.router.navigate(["/dashboard/projects/database"]);
              this.dialog.closeAll();
            }
          });
        }
      }
    }
  }

  GetTaskStatusByProjectIDandPhase(ProjectID:any,Phase:string, PhaseFor:string)
  {
    this.DbService.GetMileStoneStatusByProjectIDandPhase(ProjectID,PhaseFor).subscribe((data:any) =>
    {
      
      if(PhaseFor =="1")
      {
        this.Initiation=data.Percent;
        if(data.Percent == 100)
        {
          this.CompleteTask +=1;
        }
      }
      else if(PhaseFor =="2")
      {
        this.Planning=data.Percent;
        if(data.Percent == 100)
        {
          this.CompleteTask +=1;
        }
      }
      else if(PhaseFor =="3")
      {
        this.Execution=data.Percent;
        if(data.Percent == 100)
        {
          this.CompleteTask +=1;
        }
      }
      else
      {
        this.Closure=data.Percent;
        if(data.Percent == 100)
        {
          this.CompleteTask +=1;
        }
      }
      if(this.CompleteTask == 4)
      {
        this.CompleteButton=false;
        this.CancelButton=true;
        this.OnHoldButton=true;
        //this.Complete=true;
      }
      else
      {
        this.CompleteButton=true;
        this.CancelButton=false;
        this.OnHoldButton=false;
        //this.Complete=false;
      }
    });
  }


  SubmitforApproval()
  {
    if(sessionStorage.getItem("usertype") =="Team Manager" || sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.DbService.UpdateProjectStatus(this.data.ProjectID,"Closed").subscribe((data:any) =>
      {
          //alert("Project approval request accepted.");
          alert("Project closed successfully!");
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/project/details'], { queryParams: {ProjectID: this.data.ProjectID}});
          this.dialog.closeAll();
      });
    }
    else
    {
      this.DbService.ProjectClosureMailSend(sessionStorage.getItem("ContactID"),this.data.ProjectID,this.ContractID).subscribe((data:any) =>
      {
        if(data.RetStatus =="Success")
        {
          //alert("Your request for Project Approval Submitted Successfully");
          //alert("Your completed project has been submitted for approval");
          alert("Your request to change the status of your project has been submitted for approval");
          this.router.navigate(['dashboard/project/details'], { queryParams: {ProjectID: this.data.ProjectID}});
          this.dialog.closeAll();
        }
        else
        {
          alert(data.RetStatus);
        }
      });
    }
  }


  opendialogProjectClosure()
  {
    	this.dialog.closeAll();
		const dialogRef = this.dialog.open(DialogProjectClosureComponent, { data :{ProjectID:this.data.ProjectID},
		width: '740px'
		});
		dialogRef.afterClosed().subscribe(result => {
		  console.log(`Dialog result: ${result}`);
		});
	
  }
  OnHoldProject()
  {
    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"OnholdProject"},
    width: '540px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result ==true)
      {
        this.DbService.UpdateProjectStatus(this.data.ProjectID,"On hold").subscribe((data:any) =>
        {
          if(data =="Updated")
          {
            alert("Project On-hold");
            this.router.navigate(["/dashboard/projects/database"]);
            this.dialog.closeAll();
          }
        });
      }
    });
  }

  CancelProject()
  {

    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"CancelProject"},
      width: '540px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result ==true)
      {
        this.DbService.UpdateProjectStatus(this.data.ProjectID,"Cancelled").subscribe((data:any) =>
        {
          if(data =="Updated")
          {
            alert("Project Cancelled");
            this.router.navigate(["/dashboard/projects/database"]);
            this.dialog.closeAll();
          }
        });
      }
      // else
      // {

      // }
     
    });

    
  }
  CompleteProject()
  {
    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"CompleteProject"},
      width: '540px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result ==true)
      {
        this.DbService.UpdateProjectStatus(this.data.ProjectID,"Complete").subscribe((data:any) =>
        {
          if(data =="Updated")
          {
            alert("Project Completed Successfully");
            this.router.navigate(["/dashboard/projects/database"]);
            this.dialog.closeAll();
          }
        });
      }
    });
    
  }
  
  EnforceProjectClosure()
  {
    if(this.data.ProjectStatus == "Complete")
    {
		this.setService.enForceProjectClosure().subscribe((data:any) =>
		{
			this.bEnforceProjectClosure = (data.value == "yes")
     
				if (!this.bEnforceProjectClosure)
				{
					if(sessionStorage.getItem("usertype") =="Procurement Officer")
					{
						this.ProjectStatus= [{ Status: 'Completed',Value:'Complete'}];
						this.ProjectComplete=true;
						this.Action="Submit Project";
					}
					else
					{
						this.ProjectStatus= [{ Status: 'Completed',Value:'Complete'}];
						this.ProjectComplete=true;
						this.Action="Close Project";
					}

				}
			
		 
		});
    }
  }
  
  LoadAllCaptions()
  {
    
	this.setService.getProjectPhaseLabelFromDB().subscribe((data:any) =>
			{
			  this.ProjectMSList = data;
			});
  }
  
  getProjectCaption (projectID : any)
  {
       var sResult = "";
	   if (this.ProjectMSList != null)
	   {
		   this.ProjectMSList.forEach(element => {
			if (element.Id === projectID) {
				sResult = element.ProjectPhase_Type;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
}
