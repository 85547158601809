import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { AlertComponent } from '../../alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import {DashboardContractDetailsService} from '../../dashboard/dashboard-contract-details/dashboard-contract-details.service';
import { DialogExtendContractComponent } from '../../dialogs/dialog-extend-contract/dialog-extend-contract.component';
import { DialogNewProjectComponent } from '../../dialogs/dialog-new-project/dialog-new-project.component';
import { DashboradContractsService } from 'src/app/dashboard/dashboard-contracts/dashborad-contracts.service';

@Component({
  selector: 'app-hero-dashboard-contract-details',
  templateUrl: './hero-dashboard-contract-details.component.html',
  styleUrls: ['./hero-dashboard-contract-details.component.scss']
})
export class HeroDashboardContractDetailsComponent implements OnInit {

  DeleteContractbtn:boolean=false;
  ExtendContract:boolean=false;
  BackProjectClosure:boolean=false;
  NewProject:boolean=true;
  ReprocureContract:boolean=false;
  DisableReprocure : boolean = false;
  
  constructor(private Dservice :DashboardContractDetailsService, private route:ActivatedRoute,private router:Router,public dialog: MatDialog,private Cservice : DashboradContractsService) { 
    
	if(sessionStorage.getItem("usertype") == "Team Manager")
    {
      if(this.route.snapshot.queryParams['ProjectID'] !=null && (this.route.snapshot.queryParams['ViewDocument'] !=null || this.route.snapshot.queryParams['AddSupplier'] !=null))
      {
        this.BackProjectClosure=true;
        this.NewProject=false;
      }
      else if(this.route.snapshot.queryParams['CanExtend'] !=null && this.route.snapshot.queryParams['CanReprocure'] !=null)
      {
        this.BackProjectClosure=false;
        this.NewProject=false;
        this.DeleteContractbtn=false;
        
        if(this.route.snapshot.queryParams['CanExtend'] =="true" && this.route.snapshot.queryParams['CanReprocure'] =="true")
        {
          this.ExtendContract=true;
          this.ReprocureContract=true;
        }
        else if(this.route.snapshot.queryParams['CanExtend'] =='false' && this.route.snapshot.queryParams['CanReprocure'] =='true')
        {
          this.ExtendContract=false;
          this.ReprocureContract=true;
        }
        else
        {
          this.ExtendContract=true;
          this.ReprocureContract=false;
        }
      }
      else
      {
        this.DeleteContractbtn=true;
      }
      
    }
    else
    {
      if(this.route.snapshot.queryParams['ExtendContract'] !=null)
      {
        this.ExtendContract=true;
      }
      else if(this.route.snapshot.queryParams['ProjectID'] !=null && (this.route.snapshot.queryParams['ViewDocument'] !=null || this.route.snapshot.queryParams['AddSupplier'] !=null))
      {
        this.BackProjectClosure=true;
        this.NewProject=false;
      }
      else if(this.route.snapshot.queryParams['CanExtend'] !=null && this.route.snapshot.queryParams['CanReprocure'] !=null)
      {
        
        this.BackProjectClosure=false;
        this.NewProject=false;
        this.DeleteContractbtn=false;
        if(this.route.snapshot.queryParams['CanExtend'] =='true' && this.route.snapshot.queryParams['CanReprocure'] =='true')
        {
          this.ExtendContract=true;
          this.ReprocureContract=true;
        }
        else if(this.route.snapshot.queryParams['CanExtend'] =='false' && this.route.snapshot.queryParams['CanReprocure'] =='true')
        {
          this.ExtendContract=false;
          this.ReprocureContract=true;
        }
        else
        {
          this.ExtendContract=true;
          this.ReprocureContract=false;
        }
      }
    }
	this.getDisableNewProjectButton();
  }

  ngOnInit() {
    console.log(this.route.snapshot.queryParams['ContractID']);
  }

  BacktoProjectClosure()
  {
    this.router.navigate(['/dashboard/project/details'], { queryParams: {ProjectID: this.route.snapshot.queryParams['ProjectID'],"ProjectClosure":"true"}});
  }

  GotonewProject() { 
    //ContacID
    if(this.route.snapshot.queryParams['ContractID'] !=null)
    {
      this.Cservice.GetSubCategoryForContract(this.route.snapshot.queryParams['ContractID']).subscribe((data:any) =>
      {
        //alert(data.value);
        sessionStorage.setItem('subcat',data.value);
        
        //this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: Id}});
        this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: this.route.snapshot.queryParams['ContractID']}});

       });
      
      //this.openDialogNewProject();
    }
    
  }

//   openDialogNewProject() {
//     const dialogRef = this.dialog.open(DialogNewProjectComponent, {data: {From:"Project Database"}});

//     dialogRef.afterClosed().subscribe(result => {
//         if (result === true) {
//             this.router.navigate(['/dashboard/contracts/filter'], { queryParams: {Type: 'Re-procure', Extend:1}});
//             //this.router.navigateByUrl('/dashboard/contracts');
//         } else if (result === false) {
//             this.router.navigateByUrl('/dashboard/projects/new-project');
//             //this.router.navigate(['/dashboard/contracts'], { queryParams: {Type: 'New'}});
//         }
//     });
// }

  OpenDialogExtendContract() 
  {
    this.Dservice.getContractDetails(this.route.snapshot.queryParams['ContractID']).subscribe((data:any) =>
    {
      const dialogRef = this.dialog.open(DialogExtendContractComponent, {data: {ContactID:this.route.snapshot.queryParams['ContractID'], OrgName:data.Organisation_Name,HideAssignBtn:'true'}});

      dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          this.dialog.closeAll();
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: this.route.snapshot.queryParams['ContractID'],ViewExtension:"true"}});
          
      });
    });
    
  }
  Re_procure_Contract()
  {
    this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: this.route.snapshot.queryParams['ContractID'], ContractType:"Reprocure"}});
  }
  DeleteContract()
  {

    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"DeleteContract"},
      width: '540px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result ==true)
      {
         this.DeleteContractYes();
       
      }
      else
       {

      }
     
    });

    
  }

  DeleteContractYes()
  {

    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"DeleteContractYes"},
      width: '540px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result ==true)
      {
        
        this.Dservice.DeleteContract(this.route.snapshot.queryParams['ContractID'],sessionStorage.getItem("ContactID")).subscribe((data:any) =>
        {
         // alert(data);
          if(data.value =="success")
          {
            alert("Deleted Successfully !");
            this.dialog.closeAll();
            this.router.navigate(['dashboard/contracts/database']);

          }
        });
      }
      
     
    });

    
  }

  getDisableNewProjectButton ()
  {
    this.Dservice.getDisableCreateProjectFromContract(this.route.snapshot.queryParams['ContractID']).subscribe((data:any) =>
        {
         // alert(data);
          if(data.value =="yes")
          {
            this.DisableReprocure = true;

          }
        });
  }
  

}
