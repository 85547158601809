import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'; 
import { TellusService } from '../../tellus/tellus.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-add-new-organisation',
  templateUrl: './dialog-add-new-organisation.component.html',
  styleUrls: ['./dialog-add-new-organisation.component.scss']
})
export class DialogAddNewOrganisationComponent implements OnInit {

  organisation: FormGroup;
  Roles:any;

  constructor(private fb:FormBuilder, private router:Router,private tService:TellusService,public dialogRef: MatDialogRef<DialogAddNewOrganisationComponent>) {
    this.getUserTypes();
   }

  ngOnInit() {
    this.organisation = this.fb.group({
      organisationFormControl:['', [Validators.required]],
      NoofPeopleFormControl:['',[Validators.required, Validators.min(1), Validators.max(25000)]],
      PeopleRole:['Head of Procurement']
    });
  }

  getUserTypes()
  {
    this.tService.getAllUserTypes().subscribe((data:any) =>
      {
        //console.log(data);
        this.Roles=data.value;
        
      });
  }

  OrganisationSubmit()
  {
    if (!this.organisation.valid) {
      return;
      }
      const formdata=new FormData();
      formdata.append('Organisation_Name', this.organisation.get('organisationFormControl').value);
      formdata.append('No_of_People', this.organisation.get('NoofPeopleFormControl').value);
      formdata.append('ContactID', sessionStorage.getItem("ContactID"));
      this.tService.createOrganisation(formdata).subscribe((data:any) =>
      {
        if(data.RetStatus =="Organisation already exists.")
        {
          this.organisation.get('organisationFormControl').reset();
          alert("Organisation name already exists.");
        }
        else if(data.RetStatus =="Success")
        {
          alert("Organisation created successfully");
          this.dialogRef.close();
        }
        else
        {
          alert(data.RetStatus);
        }
      });
      // this.tService.OrganisationExists(this.organisation.get('organisationFormControl').value).subscribe((data:any) =>
      // {
      //     if(data.value =="Exists")
      //     {
      //       this.organisation.get('organisationFormControl').reset();
      //       alert("Organisation Name already exists.");
      //     }
      //     else
      //     {
            
      //     }
      // });
     
  }

}
