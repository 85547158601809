import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {LoginService} from '../login/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
export interface Invoices {
  date: Date;
  reference: string;
  status: string;
  action: string;
}

const INVOICES: Invoices[] = [
  {
      date: new Date('2019/Sep/23'),
      reference: '001',
      status: 'Paid',
      action: 'View Invoice'
  }
];


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  PersonalDetails:FormGroup;
  PasswordReset:FormGroup;
  submitted:boolean=false;
  psubmitted:boolean=false;
  show_button: Boolean = false;
  show_eye: Boolean = false;

  invoicesDisplayedColumns = ['date', 'reference', 'status', 'action'];
    invoicesDataSource = new MatTableDataSource(INVOICES);
  constructor(private lService:LoginService,private fb:FormBuilder,private router:Router) { 
    if(sessionStorage.getItem("ContactID") ==null)
    {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.PersonalDetails=this.fb.group({
      txtFirstName:['',Validators.required],
      txtSurname:['',Validators.required],
      txtEmail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      ddlEmailNotification: ['1'],
    });

    this.PasswordReset=this.fb.group(
      {
        txtCurrentPassword:['',Validators.required],
        txtNewPassword:['',Validators.required]
      }
    );

    //this.getUserDetails(sessionStorage.getItem("ContactID"));
    this.getUserDetails();
  }

  get f(){return this.PasswordReset.controls};
  get g(){return this.PersonalDetails.controls};


  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  getUserDetails()
  {
    this.lService.getContactByEmail(sessionStorage.getItem('userMail')).subscribe((data:any) =>
    {
      this.PersonalDetails.get("txtFirstName").setValue(data.ContactFirstName);
      this.PersonalDetails.get("txtSurname").setValue(data.ContactSurname);
      this.PersonalDetails.get("txtEmail").setValue(data.ContactEMailAddress);
      this.PersonalDetails.get("ddlEmailNotification").setValue(data.EmailNotification ? '1' : '0');
    });
  }
  UpdatePersonalDetails()
  {
    this.psubmitted=true;
    if(this.PersonalDetails.invalid)
    {
      return;
    }
    const formdata=new FormData();
    formdata.append('ContactID', sessionStorage.getItem("ContactID"));
    formdata.append('ContactFirstName', this.PersonalDetails.get('txtFirstName').value);
    formdata.append('ContactSurname', this.PersonalDetails.get('txtSurname').value);
    formdata.append('ContactEMailAddress', this.PersonalDetails.get('txtEmail').value);
    formdata.append('EmailNotification', this.PersonalDetails.get('ddlEmailNotification').value);
    formdata.append('LoggedInUserMail', sessionStorage.getItem('userMail'));


    this.lService.PersonalDetailsUpdate(formdata).subscribe((data:any) =>
    {
        if(data.value =="Success")
        {
          sessionStorage.setItem('userMail', this.PersonalDetails.get('txtEmail').value);
          sessionStorage.setItem("firsttime",this.PersonalDetails.get('txtFirstName').value +" "+this.PersonalDetails.get('txtSurname').value);
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/account']);
          alert("Personal details updated successfully.");
        }
        else if(data.value =="Exists")
        {
          this.PersonalDetails.get('txtEmail').setValue("");
          alert("Email-ID already exists!.Try with different Email-ID.");
        }
        else
        {
          alert(data.Status);
        }
    });
  }
  ResetPassword()
  {
    this.submitted=true;
    if(this.PasswordReset.invalid)
    {
      return;
    }
    const formdata=new FormData();
    formdata.append('ContactID', sessionStorage.getItem("ContactID"));
    formdata.append('OldPassword', this.PasswordReset.get('txtCurrentPassword').value);
    formdata.append('UserPassword', this.PasswordReset.get('txtNewPassword').value);
    formdata.append('userMail', sessionStorage.getItem("userMail"));
    this.lService.CreatenewPassword_ForAccount(formdata).subscribe((data:any) =>
    {
        if(data.Status =="Success")
        {
          this.PasswordReset.get('txtNewPassword').setValue("");
          alert("Your password has been changed successfully.");
        }
        else if(data.Status =="Failure")
        {
          this.PasswordReset.get('txtCurrentPassword').setValue("");
          this.PasswordReset.get('txtNewPassword').setValue("");
          alert("Invalid current password!");
        }
        else
        {
          alert(data.Status);
        }
    });
  }

  togglePasswordVisibility(event) {
    let triggerElement = event.target || event.currentTarget;
    let containerElement = triggerElement ? triggerElement.closest('.psp-password-visibility') : undefined;
    let inputElement = containerElement ? containerElement.querySelector('input[type="text"], input[type="password"]') : undefined;

    if (inputElement) {
        if (inputElement.getAttribute('type') === 'text') {
            inputElement.setAttribute('type', 'password');
        } else if (inputElement.getAttribute('type') === 'password') {
            inputElement.setAttribute('type', 'text');
        }
    }
}
}
