import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InvitesuserService } from '../invitesuser.service';
import {SettingsService} from '../../settings/settings.service';


@Component({
  selector: 'app-editprojectpriority',
  templateUrl: './editprojectpriority.component.html',
  styleUrls: ['./editprojectpriority.component.scss']
})
export class EditprojectpriorityComponent implements OnInit {

  show:boolean=true;
  ProjectPriority : FormGroup;
  submitted:boolean=false;
  ProjecttPriorityList:any;
  Names:any;
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder, private setService:SettingsService) { 
    this.LoadAllCaptions();
	this.LoadProjectPriorityList();
  }

  ngOnInit() {
    this.ProjectPriority = this.fb.group({
      PriorityDescription : ['',Validators.required],
     });
  }

  get f(){ return this.ProjectPriority.controls;}

  LoadProjectPriorityList()
  {
    this.InvserObj.GetProjectPriority().subscribe((data:any) => 
    {
      this.ProjecttPriorityList=data;
    
    });

  }

  RemoveProjectRisk(RStatus:String)
  {
    for(let i=0;i<this.ProjecttPriorityList.length;i++)
    {
      if(this.ProjecttPriorityList[i].ProjectPriority == RStatus)
      {
        this.ProjecttPriorityList.splice(i, 1);
      }
    }
  }

  AddProjectPriority()
  {
    this.submitted = true;
    if (this.ProjectPriority.invalid) 
    {
       return ;
    }
    let statusexist=false;
    let PS=this.ProjectPriority.get("PriorityDescription").value;
    for(let p=0;p<this.ProjecttPriorityList.length;p++)
    { 
      if(this.ProjecttPriorityList[p].PriorityDescription == PS )
      {
        statusexist=true;
        break;
      }
    }
    if(statusexist == false)
    {
      let jsonstring= '{"PriorityDescription" :"'+PS.trim()+'","Active" :0}';
      this.ProjecttPriorityList.push(JSON.parse(jsonstring));
      this.ProjectPriority.get("PriorityDescription").setValue(" ");
    }
 }

 SaveProjectPriority()
  {
    const formdecdata=new FormData();
  
    formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
    this.InvserObj.DeactiveProjectPriority(formdecdata).subscribe((data:any) => 
    {
    
  if(data.RetStatus = "Deleted")
  {
  
      if(this.ProjecttPriorityList.length>0)
      {
        for(let i=0;i <this.ProjecttPriorityList.length;i++)
          { 
            
            const formdata=new FormData();
            formdata.append('PriorityDescription', this.ProjecttPriorityList[i].PriorityDescription);
            formdata.append('Active', this.ProjecttPriorityList[i].Active);
            formdata.append('ContactID',sessionStorage.getItem("ContactID"));
            if(i==0)
            {
            formdata.append('Status','true');
            
            }
            else
            {
              formdata.append('Status','false');
            }
            this.InvserObj.AddProjectPriority(formdata).subscribe((data:any) => 
            {
              
              if(i== this.ProjecttPriorityList.length-1)
              {
                alert("Saved Successfully");
                  this.InvserObj.GetProjectPriority().subscribe((data:any) => 
                  {
                    this.ProjecttPriorityList=data;
                  
                  });
              }
            });
            
          }
        }
      }
   });
  
  }

 LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dashboard-contracts").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
  
}
