import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-dashboard-projects-new-project',
  templateUrl: './hero-dashboard-projects-new-project.component.html',
  styleUrls: ['./hero-dashboard-projects-new-project.component.css']
})
export class HeroDashboardProjectsNewProjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
