import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ProjectDatabaseService } from '../project-database.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboradContractsService } from '../dashboard-contracts/dashborad-contracts.service'
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DashboardContractsNewService } from '../dashboard-contracts-new/dashboard-contracts-new.service';
import { DashboardPerformanceService } from '../dashboard-performance/dashboard-performance.service';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import { AccountSetupWizardService } from 'src/app/account-setup-wizard/account-setup-wizard.service';
import { SettingsService } from '../../settings/settings.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-dashboard-contracts-new',
  templateUrl: './dashboard-contracts-new.component.html',
  styleUrls: ['./dashboard-contracts-new.component.scss']
})
export class DashboardContractsNewComponent implements OnInit {
  contractdashboard: FormGroup;
  Organisation: any;
  TotalContracts: any;
  TotalContractsValue: any;
  Charttype: any = "PieChart";
  Charttype1: any = "ColumnChart";
  ProjectDataforNumber: any;
  ProjectDataforValue: any;
  ChartdataforCategory: any;
  ChartdataforPriority: any;
  columnNames: any;
  columnNamesforColumnChart: any;
  columnNamesforColumnChart1: any;
  Chartoptions: any;
  Chartoptions1: any;
  Chartheight: any;
  Chartheight1: any;
  Chartwidth: any;
  Chartwidth1: any;
  showOrg: boolean = true;
  //
  Charttype2: any = "PieChart";
  columnNames2: any;
  Chartoptions2: any;
  Chartwidth2: any;
  Chartheight2: any;
  //
  Charttype3: any = "ColumnChart";
  columnNamesforColumnChart3: any;
  Chartoptions3: any;
  Chartwidth3: any;
  Chartheight3: any;
  //
  Charttype4: any = "ColumnChart";
  columnNamesforColumnChart4: any;
  Chartoptions4: any;
  Chartheight4: any;
  Chartwidth4: any;
  //
  Charttype5: any = "ColumnChart";
  ChartdataforProcure: any;
  columnNamesforColumnChart5: any;
  Chartoptions5: any;
  Chartheight5: any;
  Chartwidth5: any;
  //
  ChartdataforExpiry: any;
  //
  OneOff: any;
  Reprocure: any;
  Extend: any;
  OneOffP: any;
  ReprocureP: any;
  ExtendP: any;
  showG1: boolean = false;
  //
  OneOffValue: any;
  ReprocureValue: any;
  ExtendValue: any;
  //
  OneOffValue3: any;
  ReprocureValue3: any;
  ExtendValue3: any;
  //
  OneOffValue4: any;
  ReprocureValue4: any;
  ExtendValue4: any;
  //
  OneOffValue6: any;
  ReprocureValue6: any;
  ExtendValue6: any;
  public loading = false;
  ShowWizardMenu: boolean = false;
  bLLAEnabled: boolean = false;

  selectedOrgValue="0";

  @ViewChild('content', { static: false }) content: ElementRef;

  @ViewChild('ExportButton', { static: false }) private ExportButton: ElementRef;
  Names: any;
  constructor(private route: ActivatedRoute, private DnewService: DashboardContractsNewService, private DbService: ProjectDatabaseService, private DService: DashboradContractsService, private DPService: DashboardPerformanceService, private router: Router, private fb: FormBuilder,
    private wizard: AccountSetupWizardService, private setService: SettingsService,private _decimalPipe: DecimalPipe) {
    this.LoadAllCaptions();
    if (sessionStorage.getItem("ContactID") != null) {
      this.WizardHideorShow();
      //this.getOrganisation();
      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        this.getOrganisation();
        this.getTotalContracts();
        this.getTotalContractsValue();
        this.ProjectDataforNumber = [["", 0]];
        this.pieChartforNumberofProjects();
        this.ProjectDataforValue = [["", 0]];
        this.pieChartforValueofProjects();
        this.ChartdataforCategory = [["", 0]];
        this.ColumnChartforCategory();
        this.ChartdataforPriority = [["", 0]];
        this.ColumnChartforPriority();
        this.ChartdataforExpiry = [["", 0]];
        this.ColumnChartforExpiry();
        this.ChartdataforProcure = [["", 0]];
        this.ColumnChartforProcure_Value();
        //this.getTeams();
      }
      else {
        this.ProjectDataforNumber = [["", 0]];

        this.ProjectDataforValue = [["", 0]];

        this.ChartdataforCategory = [["", 0]];

        this.ChartdataforPriority = [["", 0]];

        this.ChartdataforExpiry = [["", 0]];

        this.ChartdataforProcure = [["", 0]];

        this.getOrganisationForNonHop();
        //this.getTeams();

      }

      //

    }
    else {
      this.router.navigate(["/login"]);
    }

  }

  ngOnInit() {

    this.contractdashboard = this.fb.group({
      ddlOrganistaion: ['0'],
      ddlContractExpiry: [''],
      ddlContractPriority: [''],
      ddlContractCategory: [''],
      ddlContractProcure: ['']
    });
    //this.contractdashboard.get("ddlOrganistaion").setValue("0");
    this.contractdashboard.get("ddlContractExpiry").setValue("0");
    this.contractdashboard.get("ddlContractPriority").setValue("0");
    this.contractdashboard.get("ddlContractCategory").setValue("0");
    this.contractdashboard.get("ddlContractProcure").setValue("0");
  }

  get f() { return this.contractdashboard.controls; };

  getOrganisationForNonHop() {
    this.showOrg = false;
    this.DPService.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data;
      //this.contractdashboard.get("ddlOrganistaion").setValue(data[0].OrganisationID);

      this.Organisationchanged();

    });
  }

  ContractExpirychanged() {
    // alert(this.contractdashboard.get("ddlContractExpiry").value);
    if (this.contractdashboard.get("ddlContractExpiry").value == "0")//Value
    {
      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Organisation
      {
        this.DnewService.GetPieChartContractsValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffValue3 = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureValue3 ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendValue3 ="\u00A3" +  data[0].Extend + "M";

          this.OneOffValue3 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');
          

        });
        this.ColumnChartforExpiry();
      }
      else {
        this.DnewService.GetPieChartContractsValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffValue3 = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureValue3 ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendValue3 ="\u00A3" +  data[0].Extend + "M";

          this.OneOffValue3 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

        });
        this.ColumnChartforExpiryByOrg();
      }

    }
    else {
      // alert("hi");
      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Orgnisation
      {
        this.DnewService.GetPieChartContracts(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffValue3 = data[0].OneOff;
          this.ReprocureValue3 = data[0].Reprocure;
          this.ExtendValue3 = data[0].Extend;
        });
        this.ColumnChartforExpiry_Nos();

      }
      else {
        this.DnewService.GetPieChartContractsByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffValue3 = data[0].OneOff;
          this.ReprocureValue3 = data[0].Reprocure;
          this.ExtendValue3 = data[0].Extend;
        });
        this.ColumnChartforExpiryNos_ByOrg();
      }

    }

  }


  getOrganisation() {
    this.DbService.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data.value;
      this.contractdashboard.get("ddlOrganistaion").setValue("0");
    });
  }

  getTotalContracts() {
    this.DnewService.getTotalContracts(sessionStorage.getItem("ContactID"),this.selectedOrgValue ).subscribe((data: any) => {

      this.TotalContracts = data.value;
    });
  }

  getTotalContractsValue() {
    this.DnewService.getTotalContractsValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

      this.TotalContractsValue = data.value;
    });
  }

  getTotalContractsValueByOrg() {

    this.DnewService.getTotalContractsValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {

      this.TotalContractsValue = data.value;
    });
  }


  Organisationchanged() {
    this.contractdashboard.get("ddlContractExpiry").setValue("0");
    this.contractdashboard.get("ddlContractPriority").setValue("0");
    this.contractdashboard.get("ddlContractCategory").setValue("0");
    this.contractdashboard.get("ddlContractProcure").setValue("0");
    this.selectedOrgValue=this.contractdashboard.get("ddlOrganistaion").value;
    this.getTotalContracts();
    if (this.contractdashboard.get("ddlOrganistaion").value == "0") {
      // this.getTotalContracts();
      this.getTotalContractsValue();
      this.pieChartforNumberofProjects();
      this.pieChartforValueofProjects();
      this.ColumnChartforPriority();
      this.ColumnChartforCategory();
      this.ColumnChartforExpiry();
      this.ColumnChartforProcure_Value();
    }
    else {
      //alert(this.contractdashboard.get("ddlOrganistaion").value);
      //  this.DnewService.getContractsByOrganisation(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data :any)=>
      //  {
      //   this.TotalContracts = data.value.length;

      //  });
      this.getTotalContractsValueByOrg();
      this.pieChartforNumberofProjectsByOrg();
      this.pieChartforValueofProjectsByOrg();
      this.ColumnChartforPriorityByOrg();
      this.ColumnChartforCategoryByOrg();
      this.ColumnChartforExpiryByOrg();
      this.ColumnChartforProcureValue_ByOrg();

    }
  }


  showContractDetails() {

    //let orgId = this.contractdashboard.get("ddlOrganistaion").value;
    //orgId = orgId == "0" ? orgId : orgId;
    //alert(localStorage.getItem('organisation'));
    //ContacID
    if (this.route.snapshot.queryParams['Type'] == null) {     
      this.router.navigate(['dashboard/contracts/database'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value } });
    }
    //this.router.navigateByUrl('dashboard/contract/details');
  }

  pieChartforNumberofProjects() {

    this.DnewService.GetPieChartContracts(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      // alert(data[0].OneOff);
      this.OneOff = data[0].OneOff;
      this.Reprocure = data[0].Reprocure;
      this.Extend = data[0].Extend;



      this.Charttype = 'PieChart';
      //alert(this.OneOff);
      this.ProjectDataforNumber = [

        ['One-Off', data[0].OneOff],
        ['Re-procure', data[0].Reprocure],
        ['Extend', data[0].Extend]
      ];
      this.columnNames = ['One-Off', 'Re-procure'];
      this.Chartoptions = {
        pieHole: 0.52,
        legend: 'none',
        enableInteractivity: true,
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 50, top: 0, width: '100%', height: '100%' },
        pieSliceText: 'none',
        // add this line
      };
      this.Chartwidth = 430;
      this.Chartheight = 280;
      this.showG1 = true;
    });
    //


  }

  //
  onContractsPieChartSelect($event: any) {
    console.log($event);
    //alert($event[0].row);
    let type = "";
    if ($event[0].row == "0") {
      type = "One-Off";
    }
    else if ($event[0].row == "1") {
      type = "Re-procure";
    }
    else {
      type = "Extend";
    }
    //
    if (this.route.snapshot.queryParams['Type'] == null) {

      if (type == "One-Off") {
        this.router.navigate(['dashboard/contracts/database'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type } });
      }
      else {

        this.router.navigate(['dashboard/contracts/filter'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type } });

      }
    }
  }

  //


  pieChartforNumberofProjectsByOrg() {

    this.DnewService.GetPieChartContractsByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
      // alert(data[0].OneOff);
      this.OneOff = data[0].OneOff;
      this.Reprocure = data[0].Reprocure;
      this.Extend = data[0].Extend;



      this.Charttype = 'PieChart';
      // alert(this.OneOff);
      this.ProjectDataforNumber = [

        ['One-Off', data[0].OneOff],
        ['Re-procure', data[0].Reprocure],
        ['Extend', data[0].Extend]
      ];
      this.columnNames = ['Browser', 'Percentage'];
      this.Chartoptions = {
        pieHole: 0.52,
        legend: 'none',
        enableInteractivity: true,
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 50, top: 0, width: '100%', height: '100%' },
        pieSliceText: 'none',
        // add this line
      };
      this.Chartwidth = 430;
      this.Chartheight = 280;
    });
    //



  }
  pieChartforValueofProjects() {

    this.DnewService.GetPieChartContractsValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      // alert(data[0].OneOff);
      this.OneOffValue = data[0].OneOff;
      this.ReprocureValue = data[0].Reprocure;
      this.ExtendValue = data[0].Extend;

      //  this.OneOffValue3 = "\u00A3" + data[0].OneOff + "M";
      //  this.ReprocureValue3 ="\u00A3" + data[0].Reprocure  + "M";
      //  this.ExtendValue3 = "\u00A3" + data[0].Extend + "M";
      //  //
      //  this.OneOffValue4 = "\u00A3" + data[0].OneOff + "M";
      //  this.ReprocureValue4 ="\u00A3" + data[0].Reprocure  + "M";
      //  this.ExtendValue4 = "\u00A3" + data[0].Extend + "M";
      //  //
      //  this.OneOffValue6 = "\u00A3" + data[0].OneOff + "M";
      //  this.ReprocureValue6 = "\u00A3" + data[0].Reprocure  + "M";
      //  this.ExtendValue6 = "\u00A3" + data[0].Extend + "M";

      //  this.OneOffP = "\u00A3" + data[0].OneOff + "M";
      //  this.ReprocureP = "\u00A3" + data[0].Reprocure  + "M";
      //  this.ExtendP = "\u00A3" + data[0].Extend + "M";

      this.OneOffValue3 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      this.ReprocureValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

      this.OneOffValue4 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      this.ReprocureValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

      this.OneOffValue6 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      this.ReprocureValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

      this.OneOffP = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      this.ReprocureP = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendP = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');



      this.Charttype2 = 'PieChart';
      this.ProjectDataforValue = [
        ['One-Off', data[0].OneOff],
        ['Re-procure', data[0].Reprocure],
        ['Extend', data[0].Extend]
      ];

      //   this.ProjectDataforValue = [
      //     ['Firefox', 45.0],
      //     ['IE', 26.8],
      //     ['Chrome', 12.8]
      // ];
      this.columnNames2 = ['Priority', 'Value'];
      this.Chartoptions2 = {
        pieHole: 0.52,
        enableInteractivity: true,
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 50, top: 0, width: '100%', height: '100%' },
        pieSliceText: 'none',
        // add this line
      };
      this.Chartwidth2 = 430;
      this.Chartheight2 = 280;

    });



  }

  pieChartforValueofProjectsByOrg() {

    this.DnewService.GetPieChartContractsValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
      // alert(data[0].OneOff);
      this.OneOffValue = data[0].OneOff;
      this.ReprocureValue = data[0].Reprocure;
      this.ExtendValue = data[0].Extend;

      // this.OneOffValue3 = "\u00A3" + data[0].OneOff + "M";
      // this.ReprocureValue3 ="\u00A3" + data[0].Reprocure  + "M";
      // this.ExtendValue3 = "\u00A3" + data[0].Extend + "M";
      // //
      // this.OneOffValue4 = "\u00A3" + data[0].OneOff + "M";
      // this.ReprocureValue4 = "\u00A3" + data[0].Reprocure  + "M";
      // this.ExtendValue4 = "\u00A3" + data[0].Extend + "M";
      // //
      // this.OneOffValue6 = "\u00A3" + data[0].OneOff + "M";
      // this.ReprocureValue6 = "\u00A3" + data[0].Reprocure  + "M";
      // this.ExtendValue6 = "\u00A3" + data[0].Extend + "M";

      this.OneOffValue3 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      
      this.ReprocureValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendValue3 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

      this.OneOffValue4 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      this.ReprocureValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

      this.OneOffValue6 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      this.ReprocureValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

      // this.OneOffP = "\u00A3" + data[0].OneOff + "M";
      // this.ReprocureP = "\u00A3" + data[0].Reprocure  + "M";
      // this.ExtendP = "\u00A3" + data[0].Extend + "M";


      this.OneOffP = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
      this.ReprocureP = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
      this.ExtendP = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

      this.Charttype2 = 'PieChart';
      this.ProjectDataforValue = [
        ['One-Off', data[0].OneOff],
        ['Re-procure', data[0].Reprocure],
        ['Extend', data[0].Extend]
      ];

      //   this.ProjectDataforValue = [
      //     ['Firefox', 45.0],
      //     ['IE', 26.8],
      //     ['Chrome', 12.8]
      // ];
      this.columnNames2 = ['Priority', 'Value'];
      this.Chartoptions2 = {
        pieHole: 0.52,
        enableInteractivity: true,
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 50, top: 0, width: '100%', height: '100%' },
        pieSliceText: 'none',
        // add this line
      };
      this.Chartwidth2 = 430;
      this.Chartheight2 = 280;
    });
    //

  }


  onCategoryColumnChartSelect($event: any) {
    //console.log($event); 
    //alert($event[0].column)
    /*      ['People', data[0].OneOff_People,data[0].Reprocure_People,data[0].Extend_People],
      ['Place', data[0].OneOff_Place,data[0].Reprocure_Place, data[0].Extend_Place],
      ['Corporate', data[0].OneOff_Corporate, data[0].Reprocure_Corporate, data[0].Extend_Corporate]   
    */
    //alert("Category Name : "+ this.ChartdataforCategory[$event[0].row][0]);
    console.log("Category Name : " + this.ChartdataforCategory[$event[0].row][0]);
    const fData = new FormData();
    fData.append("CategoryName", this.ChartdataforCategory[$event[0].row][0]);
    this.DbService.GetCategoryID_byName(fData).subscribe((data: any) => {
      //alert("Cat_ID"+data.value);
      console.log("Cat_ID" + data.value);
      let type = "";
      let category = "";
      if ($event[0].row == "0") {
        //category = "People";
        //category=this.ChartdataforCategory[$event[0].row][0];
        category = data.value;
        if ($event[0].column == "1") {
          type = "One-Off"
        }
        else if ($event[0].column == "2") {
          type = "Re-procure"
        }
        else if ($event[0].column == "3") {
          type = "Extend"
        }
      }
      else if ($event[0].row == "1") {
        //category = "Place";
        //category=this.ChartdataforCategory[$event[0].row][0];
        category = data.value;
        if ($event[0].column == "1") {
          type = "One-Off"
        }
        else if ($event[0].column == "2") {
          type = "Re-procure"
        }
        else if ($event[0].column == "3") {
          type = "Extend"
        }
      }
      else if ($event[0].row == "2") {
        //category = "Corporate";
        //category=this.ChartdataforCategory[$event[0].row][0];
        category = data.value;
        if ($event[0].column == "1") {
          type = "One-Off"
        }
        else if ($event[0].column == "2") {
          type = "Re-procure"
        }
        else if ($event[0].column == "3") {
          type = "Extend"
        }
      }
      // for sovergen
      //else if ($event[0].row == "3") {
      //  category = data.value;
      //  if ($event[0].column == "1") {
      //    type = "One-Off"
      //  }
      //  else if ($event[0].column == "2") {
      //    type = "Re-procure"
      //  }
      //  else if ($event[0].column == "3") {
      //    type = "Extend"
      //  }
      //}
      //else if ($event[0].row == "4") {
      //  category = data.value;
      //  if ($event[0].column == "1") {
      //    type = "One-Off"
      //  }
      //  else if ($event[0].column == "2") {
      //    type = "Re-procure"
      //  }
      //  else if ($event[0].column == "3") {
      //    type = "Extend"
      //  }
      //}
      //else if ($event[0].row == "5") {
      //  category = data.value;
      //  if ($event[0].column == "1") {
      //    type = "One-Off"
      //  }
      //  else if ($event[0].column == "2") {
      //    type = "Re-procure"
      //  }
      //  else if ($event[0].column == "3") {
      //    type = "Extend"
      //  }
      //}

      //
      if (this.route.snapshot.queryParams['Type'] == null) {
        if (type == "One-Off") {
          this.router.navigate(['dashboard/contracts/database'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, Category: category } });
        } else {

          this.router.navigate(['dashboard/contracts/filter'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, Category: category } });

        }
      }
    });

  }


  ContractCategorychanged() {
    if (this.contractdashboard.get("ddlContractCategory").value == "0")//Value
    {
      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Organisation
      {
        this.DnewService.GetPieChartContractsValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffValue6 = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureValue6 ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendValue6 ="\u00A3" +  data[0].Extend + "M";

          this.OneOffValue6 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');
        });
        this.ColumnChartforCategory();
      }
      else {
        this.DnewService.GetPieChartContractsValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffValue6 = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureValue6 ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendValue6 ="\u00A3" +  data[0].Extend + "M";

          this.OneOffValue6 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendValue6 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');
        });
        this.ColumnChartforCategoryByOrg();
      }

    }
    else {

      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Orgnisation
      {
        this.DnewService.GetPieChartContracts(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffValue6 = data[0].OneOff;
          this.ReprocureValue6 = data[0].Reprocure;
          this.ExtendValue6 = data[0].Extend;
        });
        this.ColumnChartforCategoryNo();

      }
      else {
        this.DnewService.GetPieChartContractsByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffValue6 = data[0].OneOff;
          this.ReprocureValue6 = data[0].Reprocure;
          this.ExtendValue6 = data[0].Extend;
        });
        this.ColumnChartforCategoryNoByOrg();
      }
    }
  }

  ColumnChartforCategory() {

    this.DnewService.GetChartContractsCategoryValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

      this.Charttype4 = 'ColumnChart';
      this.ChartdataforCategory = [
        [data[0].CategoryName1.replace('& ', '&\n'), data[0].OneOff_People, data[0].Reprocure_People, data[0].Extend_People],
        [data[0].CategoryName2.replace('& ', '&\n'), data[0].OneOff_Place, data[0].Reprocure_Place, data[0].Extend_Place],
        [data[0].CategoryName3.replace('& ', '&\n'), data[0].OneOff_Corporate, data[0].Reprocure_Corporate, data[0].Extend_Corporate],

        //for sovergin
        //[data[0].CategoryName4.replace('& ', '&\n'), data[0].OneOff_Cat4, data[0].Reprocure_Cat4, data[0].Extend_Cat4],
        //[data[0].CategoryName5.replace('& ', '&\n'), data[0].OneOff_Cat5, data[0].Reprocure_Cat5, data[0].Extend_Cat5],
        //[data[0].CategoryName6.replace('& ', '&\n'), data[0].OneOff_Cat6, data[0].Reprocure_Cat6, data[0].Extend_Cat6]
      ];

      //  this.ChartdataforCategory = [
      //   ['People', data[0].OneOff_People,data[0].Reprocure_People,data[0].Extend_People],
      //   ['Place', data[0].OneOff_Place,data[0].Reprocure_Place, data[0].Extend_Place],
      //   ['Corporate', data[0].OneOff_Corporate, data[0].Reprocure_Corporate, data[0].Extend_Corporate]   
      //  ];
      this.columnNamesforColumnChart4 = ['Year', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions4 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 11,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          //format: '\u00A3',
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth4 = 430;
      this.Chartheight4 = 300;
    });
  }


  ColumnChartforCategoryNo() {

    this.DnewService.GetChartContractsCategoryNo(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

      this.Charttype4 = 'ColumnChart';
      this.ChartdataforCategory = [
        [data[0].CategoryName1.replace('& ', '&\n'), data[0].OneOff_People, data[0].Reprocure_People, data[0].Extend_People],
        [data[0].CategoryName2.replace('& ', '&\n'), data[0].OneOff_Place, data[0].Reprocure_Place, data[0].Extend_Place],
        [data[0].CategoryName3.replace('& ', '&\n'), data[0].OneOff_Corporate, data[0].Reprocure_Corporate, data[0].Extend_Corporate],

        //for sovergin
        //[data[0].CategoryName4.replace('& ', '&\n'), data[0].OneOff_Cat4, data[0].Reprocure_Cat4, data[0].Extend_Cat4],
        //[data[0].CategoryName5.replace('& ', '&\n'), data[0].OneOff_Cat5, data[0].Reprocure_Cat5, data[0].Extend_Cat5],
        //[data[0].CategoryName6.replace('& ', '&\n'), data[0].OneOff_Cat6, data[0].Reprocure_Cat6, data[0].Extend_Cat6]
      ];
      this.columnNamesforColumnChart4 = ['Year', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions4 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 11,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/

          format: '0',

          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth4 = 430;
      this.Chartheight4 = 300;
    });
  }

  ColumnChartforCategoryNoByOrg() {
    //alert("hi");
    this.DnewService.GetChartContractsCategoryNoByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {

      this.Charttype4 = 'ColumnChart';
      this.ChartdataforCategory = [
        [data[0].CategoryName1.replace('& ', '&\n'), data[0].OneOff_People, data[0].Reprocure_People, data[0].Extend_People],
        [data[0].CategoryName2.replace('& ', '&\n'), data[0].OneOff_Place, data[0].Reprocure_Place, data[0].Extend_Place],
        [data[0].CategoryName3.replace('& ', '&\n'), data[0].OneOff_Corporate, data[0].Reprocure_Corporate, data[0].Extend_Corporate],

        //for sovergin
        //[data[0].CategoryName4.replace('& ', '&\n'), data[0].OneOff_Cat4, data[0].Reprocure_Cat4, data[0].Extend_Cat4],
        //[data[0].CategoryName5.replace('& ', '&\n'), data[0].OneOff_Cat5, data[0].Reprocure_Cat5, data[0].Extend_Cat5],
        //[data[0].CategoryName6.replace('& ', '&\n'), data[0].OneOff_Cat6, data[0].Reprocure_Cat6, data[0].Extend_Cat6]
      ];
      this.columnNamesforColumnChart4 = ['Year', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions4 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 11,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          format: '0',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth4 = 430;
      this.Chartheight4 = 300;
    });
  }


  ColumnChartforCategoryByOrg() {

    this.DnewService.GetChartContractsCategoryValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {


      this.Charttype4 = 'ColumnChart';
      this.ChartdataforCategory = [
        [data[0].CategoryName1.replace('& ', '&\n'), data[0].OneOff_People, data[0].Reprocure_People, data[0].Extend_People],
        [data[0].CategoryName2.replace('& ', '&\n'), data[0].OneOff_Place, data[0].Reprocure_Place, data[0].Extend_Place],
        [data[0].CategoryName3.replace('& ', '&\n'), data[0].OneOff_Corporate, data[0].Reprocure_Corporate, data[0].Extend_Corporate],

        //for sovergin
        //[data[0].CategoryName4.replace('& ', '&\n'), data[0].OneOff_Cat4, data[0].Reprocure_Cat4, data[0].Extend_Cat4],
        //[data[0].CategoryName5.replace('& ', '&\n'), data[0].OneOff_Cat5, data[0].Reprocure_Cat5, data[0].Extend_Cat5],
        //[data[0].CategoryName6.replace('& ', '&\n'), data[0].OneOff_Cat6, data[0].Reprocure_Cat6, data[0].Extend_Cat6]
      ];
      this.columnNamesforColumnChart4 = ['Year', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions4 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 11,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          //format: '\u00A3',
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth4 = 430;
      this.Chartheight4 = 300;
    });
  }

  onPriorityColumnChartSelect($event: any) {
    console.log($event);
    //alert($event[0].column)
    /*      ['High', data[0].OneOff_High,data[0].Reprocure_High,data[0].Extend_High],
      ['Medium', data[0].OneOff_Medium,data[0].Reprocure_Medium,data[0].Extend_Medium],
      ['Low',data[0].OneOff_Low,data[0].Reprocure_Low,data[0].Extend_Low]*/
    let type = "";
    let priority = "";
    if ($event[0].row == "0") {
      priority = "High";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "1") {
      priority = "Medium";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "2") {
      priority = "Low";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }

    //
    if (this.route.snapshot.queryParams['Type'] == null) {
      if (type == "One-Off") {
        this.router.navigate(['dashboard/contracts/database'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, Priority: priority } });
      }
      else {
        this.router.navigate(['dashboard/contracts/filter'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, Priority: priority } });

      }
    }

  }


  ColumnChartforPriority() {
    this.DnewService.GetChartContractsPriorityValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

      this.Charttype3 = 'ColumnChart';
      this.ChartdataforPriority = [
        ['High', data[0].OneOff_High, data[0].Reprocure_High, data[0].Extend_High],
        ['Medium', data[0].OneOff_Medium, data[0].Reprocure_Medium, data[0].Extend_Medium],
        ['Low', data[0].OneOff_Low, data[0].Reprocure_Low, data[0].Extend_Low]
      ];
      this.columnNamesforColumnChart3 = ['Priority', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions3 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /*  gridlines: {
                color: 'transparent'
            },*/
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth3 = 430;
      this.Chartheight3 = 300;
    });

  }

  ContractPrioritychanged() {
    // alert(this.contractdashboard.get("ddlContractExpiry").value);
    if (this.contractdashboard.get("ddlContractPriority").value == "0")//Value
    {
      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Organisation
      {
        this.DnewService.GetPieChartContractsValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffValue4 = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureValue4 ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendValue4 ="\u00A3" +  data[0].Extend + "M";

          this.OneOffValue4 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');
        });
        this.ColumnChartforPriority();
      }
      else {
        this.DnewService.GetPieChartContractsValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffValue4 = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureValue4 ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendValue4 ="\u00A3" +  data[0].Extend + "M";


          this.OneOffValue4 = "\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendValue4 = "\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');
        });
        this.ColumnChartforPriorityByOrg();
      }


    }
    else {
      // alert("hi");
      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Organisation
      {
        this.DnewService.GetPieChartContracts(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffValue4 = data[0].OneOff;
          this.ReprocureValue4 = data[0].Reprocure;
          this.ExtendValue4 = data[0].Extend;
        });
        this.ColumnChartforPriorityNo();
      }
      else {
        this.DnewService.GetPieChartContractsByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffValue4 = data[0].OneOff;
          this.ReprocureValue4 = data[0].Reprocure;
          this.ExtendValue4 = data[0].Extend;
        });
        this.ColumnChartforPriorityByOrg_No();
      }

    }


  }

  ColumnChartforPriorityNo() {
    this.DnewService.GetChartContractsPriorityNo(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

      this.Charttype3 = 'ColumnChart';
      this.ChartdataforPriority = [
        ['High', data[0].OneOff_High, data[0].Reprocure_High, data[0].Extend_High],
        ['Medium', data[0].OneOff_Medium, data[0].Reprocure_Medium, data[0].Extend_Medium],
        ['Low', data[0].OneOff_Low, data[0].Reprocure_Low, data[0].Extend_Low]
      ];
      this.columnNamesforColumnChart3 = ['Priority', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions3 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /*gridlines: {
              color: 'transparent'
          },*/
          format: '0',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth3 = 430;
      this.Chartheight3 = 300;
    });

  }

  ColumnChartforPriorityByOrg_No() {
    this.DnewService.GetChartContractsPriorityNoByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {

      this.Charttype3 = 'ColumnChart';
      this.ChartdataforPriority = [
        ['High', data[0].OneOff_High, data[0].Reprocure_High, data[0].Extend_High],
        ['Medium', data[0].OneOff_Medium, data[0].Reprocure_Medium, data[0].Extend_Medium],
        ['Low', data[0].OneOff_Low, data[0].Reprocure_Low, data[0].Extend_Low]
      ];
      this.columnNamesforColumnChart3 = ['Priority', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions3 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /*  gridlines: {
                color: 'transparent'
            },*/
          format: '0',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth3 = 430;
      this.Chartheight3 = 300;
    });

  }

  ColumnChartforPriorityByOrg() {
    this.DnewService.GetChartContractsPriorityValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {

      this.Charttype3 = 'ColumnChart';
      this.ChartdataforPriority = [
        ['High', data[0].OneOff_High, data[0].Reprocure_High, data[0].Extend_High],
        ['Medium', data[0].OneOff_Medium, data[0].Reprocure_Medium, data[0].Extend_Medium],
        ['Low', data[0].OneOff_Low, data[0].Reprocure_Low, data[0].Extend_Low]
      ];
      this.columnNamesforColumnChart3 = ['Priority', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions3 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /*gridlines: {
              color: 'transparent'
          },*/
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth3 = 430;
      this.Chartheight3 = 300;
    });

  }


  onContractsColumnChartSelect($event: any) {
    console.log($event);
    //alert($event[0].column)
    /*   ['< 6 mo', data[0].OneOff_6M,data[0].Reprocure_6M,data[0].Extend_6M],
      ['6-12 mo', data[0].OneOff_12M,data[0].Reprocure_12M,data[0].Extend_12M],
      ['12-24 mo',data[0].OneOff_24M,data[0].Reprocure_24M,data[0].Extend_24M],
      ['24+ mo',data[0].OneOff_24Plus,data[0].Reprocure_24MPlus,data[0].Extend_24MPlus]*/
    let type = "";
    let month = "";
    if ($event[0].row == "0") {
      month = "6";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "1") {
      month = "12";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "2") {
      month = "24";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "3") {
      month = "24plus";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    //
    if (this.route.snapshot.queryParams['Type'] == null) {
      if (type == "One-Off") {
        this.router.navigate(['dashboard/contracts/database'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, Month: month } });
      }
      else {
        this.router.navigate(['dashboard/contracts/filter'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, Month: month } });

      }
    }


  }

  ColumnChartforExpiry() {
    this.DnewService.GetChartContractsExpiryValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      //alert(data);
      this.Charttype1 = 'ColumnChart';
      this.ChartdataforExpiry = [
        ['< 6 mo', data[0].OneOff_6M, data[0].Reprocure_6M, data[0].Extend_6M],
        ['6-12 mo', data[0].OneOff_12M, data[0].Reprocure_12M, data[0].Extend_12M],
        ['12-24 mo', data[0].OneOff_24M, data[0].Reprocure_24M, data[0].Extend_24M],
        ['24+ mo', data[0].OneOff_24Plus, data[0].Reprocure_24MPlus, data[0].Extend_24MPlus]
      ];
      this.columnNamesforColumnChart1 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions1 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth1 = 430;
      this.Chartheight1 = 300;
    });

  }

  ColumnChartforExpiry_Nos() {
    this.DnewService.GetChartContractsExpiryNos(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      //alert(data);
      this.Charttype1 = 'ColumnChart';
      this.ChartdataforExpiry = [
        ['< 6 mo', data[0].OneOff_6M, data[0].Reprocure_6M, data[0].Extend_6M],
        ['6-12 mo', data[0].OneOff_12M, data[0].Reprocure_12M, data[0].Extend_12M],
        ['12-24 mo', data[0].OneOff_24M, data[0].Reprocure_24M, data[0].Extend_24M],
        ['24+ mo', data[0].OneOff_24Plus, data[0].Reprocure_24MPlus, data[0].Extend_24MPlus]
      ];
      this.columnNamesforColumnChart1 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions1 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          format: '0',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth1 = 430;
      this.Chartheight1 = 300;
    });

  }

  ColumnChartforExpiryNos_ByOrg() {
    //alert(this.contractdashboard.get("ddlOrganistaion").value);
    this.DnewService.GetChartContractsExpiryNosByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
      //alert(data);
      this.Charttype1 = 'ColumnChart';
      this.ChartdataforExpiry = [
        ['< 6 mo', data[0].OneOff_6M, data[0].Reprocure_6M, data[0].Extend_6M],
        ['6-12 mo', data[0].OneOff_12M, data[0].Reprocure_12M, data[0].Extend_12M],
        ['12-24 mo', data[0].OneOff_24M, data[0].Reprocure_24M, data[0].Extend_24M],
        ['24+ mo', data[0].OneOff_24Plus, data[0].Reprocure_24MPlus, data[0].Extend_24MPlus]
      ];
      this.columnNamesforColumnChart1 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions1 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          format: '0',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth1 = 430;
      this.Chartheight1 = 300;
    });

  }

  ColumnChartforExpiryByOrg() {
    //alert(this.contractdashboard.get("ddlOrganistaion").value);
    this.DnewService.GetChartContractsExpiryValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
      //alert(data);
      this.Charttype1 = 'ColumnChart';
      this.ChartdataforExpiry = [
        ['< 6 mo', data[0].OneOff_6M, data[0].Reprocure_6M, data[0].Extend_6M],
        ['6-12 mo', data[0].OneOff_12M, data[0].Reprocure_12M, data[0].Extend_12M],
        ['12-24 mo', data[0].OneOff_24M, data[0].Reprocure_24M, data[0].Extend_24M],
        ['24+ mo', data[0].OneOff_24Plus, data[0].Reprocure_24MPlus, data[0].Extend_24MPlus]
      ];
      this.columnNamesforColumnChart1 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions1 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth1 = 430;
      this.Chartheight1 = 300;
    });

  }


  onProcureColumnChartSelect($event: any) {
    console.log($event);
    //alert($event[0].column)
    /*       ['<\u00A31,000', data[0].OneOff_1K,data[0].Reprocure_1K,data[0].Extend_1K],
      ['\u00A31,000-\n\u00A310,000', data[0].OneOff_10K,data[0].Reprocure_10K,data[0].Extend_10K],
      ['\u00A310,000-\n\u00A349,999',data[0].OneOff_50K,data[0].Reprocure_50K,data[0].Extend_50K],
      ['\u00A350,000-\n\u00A399,999',data[0].OneOff_100K,data[0].Reprocure_100K,data[0].Extend_100K],
      ['>\u00A3100,000',data[0].OneOff_200K,data[0].Reprocure_200K,data[0].Extend_200K]
    */
    let type = "";
    let Value = "";
    if ($event[0].row == "0") {
      Value = "1K";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "1") {
      Value = "10K";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "2") {
      Value = "50K";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "3") {
      Value = "100K";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }
    else if ($event[0].row == "4") {
      Value = "200K";
      if ($event[0].column == "1") {
        type = "One-Off"
      }
      else if ($event[0].column == "2") {
        type = "Re-procure"
      }
      else if ($event[0].column == "3") {
        type = "Extend"
      }
    }

    //
    if (this.route.snapshot.queryParams['Type'] == null) {
      if (type == "One-Off") {
        this.router.navigate(['dashboard/contracts/database'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, ContractValue: Value } });
      }
      else {
        this.router.navigate(['dashboard/contracts/filter'], { queryParams: { OrganisationID: this.contractdashboard.get("ddlOrganistaion").value, ContractType: type, ContractValue: Value } });

      }
    }

  }

  ContractProcurechanged() {
    
    if (this.contractdashboard.get("ddlContractProcure").value == "0")//Value
    {
      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Organisation
      {
        this.DnewService.GetPieChartContractsValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffP = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureP ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendP ="\u00A3" +  data[0].Extend + "M";

          this.OneOffP = "\u00A3" +  this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureP ="\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendP ="\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

        });
        this.ColumnChartforProcure_Value();
      }
      else {
        this.DnewService.GetPieChartContractsValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          //  this.OneOffP = "\u00A3" + data[0].OneOff + "M";
          //  this.ReprocureP ="\u00A3" +  data[0].Reprocure + "M";
          //  this.ExtendP ="\u00A3" +  data[0].Extend + "M";


          this.OneOffP ="\u00A3" + this._decimalPipe.transform(data[0].OneOff,'1.0-0');
          this.ReprocureP ="\u00A3" + this._decimalPipe.transform(data[0].Reprocure,'1.0-0');
          this.ExtendP ="\u00A3" + this._decimalPipe.transform(data[0].Extend,'1.0-0');

        });
        this.ColumnChartforProcureValue_ByOrg();
      }

    }
    else {

      if (this.contractdashboard.get("ddlOrganistaion").value == "0")//All Orgnisation
      {
        this.DnewService.GetPieChartContracts(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffP = data[0].OneOff;
          this.ReprocureP = data[0].Reprocure;
          this.ExtendP = data[0].Extend;
        });
        this.ColumnChartforProcure();

      }
      else {
        this.DnewService.GetPieChartContractsByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
          // alert(data[0].OneOff);
          this.OneOffP = data[0].OneOff;
          this.ReprocureP = data[0].Reprocure;
          this.ExtendP = data[0].Extend;
        });
        this.ColumnChartforProcureByOrg();
      }
    }
  }

  ColumnChartforProcure() {
    this.DnewService.GetChartContractsProcurementValue(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      //alert(data);
      this.Charttype5 = 'ColumnChart';
      this.ChartdataforProcure = [
        ['<\u00A31K', data[0].OneOff_1K, data[0].Reprocure_1K, data[0].Extend_1K],
        ['\u00A31K-\n\u00A310K', data[0].OneOff_10K, data[0].Reprocure_10K, data[0].Extend_10K],
        ['\u00A310K-\n\u00A350K', data[0].OneOff_50K, data[0].Reprocure_50K, data[0].Extend_50K],
        ['\u00A350K-\n\u00A3100K', data[0].OneOff_100K, data[0].Reprocure_100K, data[0].Extend_100K],
        ['>\u00A3100K', data[0].OneOff_200K, data[0].Reprocure_200K, data[0].Extend_200K]
      ];
      this.columnNamesforColumnChart5 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions5 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 12,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          },
          format: 'short'
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          format: '0',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth5 = 430;
      this.Chartheight5 = 300;
    });

  }

  ColumnChartforProcure_Value() {
    this.DnewService.GetChartContractsProcureValue_Real(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      //alert(data);
      this.Charttype5 = 'ColumnChart';
      this.ChartdataforProcure = [
        ['<\u00A31K', data[0].OneOff_1K, data[0].Reprocure_1K, data[0].Extend_1K],
        ['\u00A31K-\n\u00A310K', data[0].OneOff_10K, data[0].Reprocure_10K, data[0].Extend_10K],
        ['\u00A310K-\n\u00A350K', data[0].OneOff_50K, data[0].Reprocure_50K, data[0].Extend_50K],
        ['\u00A350K-\n\u00A3100K', data[0].OneOff_100K, data[0].Reprocure_100K, data[0].Extend_100K],
        ['>\u00A3100K', data[0].OneOff_200K, data[0].Reprocure_200K, data[0].Extend_200K]
      ];
      this.columnNamesforColumnChart5 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions5 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 12,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          },
          format: 'short'
        },
        vAxis: {
          baselineColor: 'black',
          /*  gridlines: {
                color: 'transparent'
            },*/
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth5 = 430;
      this.Chartheight5 = 300;
    });

  }

  ColumnChartforProcureValue_ByOrg() {
    this.DnewService.GetChartContractsProcureValueReal_ByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
      //alert(data);
      this.Charttype5 = 'ColumnChart';
      this.ChartdataforProcure = [
        ['<\u00A31,000', data[0].OneOff_1K, data[0].Reprocure_1K, data[0].Extend_1K],
        ['\u00A31,000-\n\u00A310,000', data[0].OneOff_10K, data[0].Reprocure_10K, data[0].Extend_10K],
        ['\u00A310,000-\n\u00A349,999', data[0].OneOff_50K, data[0].Reprocure_50K, data[0].Extend_50K],
        ['\u00A350,000-\n\u00A399,999', data[0].OneOff_100K, data[0].Reprocure_100K, data[0].Extend_100K],
        ['>\u00A3100,000', data[0].OneOff_200K, data[0].Reprocure_200K, data[0].Extend_200K]
      ];
      this.columnNamesforColumnChart5 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions5 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 10,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /*gridlines: {
              color: 'transparent'
          },*/
          format: 'short',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth5 = 430;
      this.Chartheight5 = 300;
    });

  }

  ColumnChartforProcureByOrg() {
    this.DnewService.GetChartContractsProcurementValueByOrg(this.contractdashboard.get("ddlOrganistaion").value).subscribe((data: any) => {
      //alert(data);
      this.Charttype5 = 'ColumnChart';
      this.ChartdataforProcure = [
        ['<\u00A31,000', data[0].OneOff_1K, data[0].Reprocure_1K, data[0].Extend_1K],
        ['\u00A31,000-\n\u00A310,000', data[0].OneOff_10K, data[0].Reprocure_10K, data[0].Extend_10K],
        ['\u00A310,000-\n\u00A349,999', data[0].OneOff_50K, data[0].Reprocure_50K, data[0].Extend_50K],
        ['\u00A350,000-\n\u00A399,999', data[0].OneOff_100K, data[0].Reprocure_100K, data[0].Extend_100K],
        ['>\u00A3100,000', data[0].OneOff_200K, data[0].Reprocure_200K, data[0].Extend_200K]
      ];
      this.columnNamesforColumnChart5 = ['Expiry', 'One-Off', 'Re-procure', 'Extend'];
      this.Chartoptions5 = {
        legend: 'none',
        colors: ['#FF6139', '#FABF2D', '#177DAD'],
        chartArea: { left: 40, top: 20, bottom: 30, width: '100%', height: '100%' },
        bar: { groupWidth: "80%" },
        enableInteractivity: true,
        crosshair: { trigger: 'both' },
        hAxis:
        {
          baselineColor: 'black',
          baseline: 0,
          textStyle: {
            fontSize: 10,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        },
        vAxis: {
          baselineColor: 'black',
          /* gridlines: {
               color: 'transparent'
           },*/
          format: '0',
          textStyle: {
            fontSize: 13,
            bold: 'true',
            fontName: 'IBM Plex Sans'
          }
        }
      };
      this.Chartwidth5 = 430;
      this.Chartheight5 = 300;
    });

  }


  WizardHideorShow() {
    this.wizard.getWizardCheckListByContactID(Number(sessionStorage.getItem("ContactID")), sessionStorage.getItem("usertype")).subscribe((data: any) => {
      if (data.length == data.filter(x => x.WizardCheckList_Viewed == 1).length) {
        this.ShowWizardMenu = false;
      }
      else {
        this.ShowWizardMenu = true;
      }
    });
  }

  // public captureScreen1() {
  //   const data=this.content.nativeElement;
  //   html2canvas(data, { allowTaint: true }).then(canvas => {
  //     let HTML_Width = canvas.width;
  //     let HTML_Height = canvas.height;
  //     let top_left_margin = 15;
  //     let PDF_Width = HTML_Width + (top_left_margin * 2);
  //     let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
  //     let canvas_image_width = HTML_Width;
  //     let canvas_image_height = HTML_Height;
  //     let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
  //     canvas.getContext('2d');
  //     let imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //     pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
  //     for (let i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage([PDF_Width, PDF_Height], 'p');
  //       pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
  //     }
  //      pdf.save("HTML-Document.pdf");
  //   });
  // }
  //................................................................................................................
  public captureScreen() {
    this.loading = true;
    const data = this.content.nativeElement;

    html2canvas(data).then(canvas => {

      const imgWidth = 210;
      const pageHeight = 295;
      //var top_left_margin = 15;
      const imgHeight = (canvas.height * imgWidth / canvas.width);
      console.log("imgHeight" + imgHeight);
      let heightLeft = imgHeight;
      //var totalPDFPages = Math.ceil(data.clientHeight/canvas.height)-1;
      //var PDF_Width = data.clientWidth+(top_left_margin*2);
      //var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
      const contentDataURL = canvas.toDataURL('image/png', 1.0);
      const img = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      let position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, canvas.width, canvas.height);
      // heightLeft -= pageHeight;
      // console.log("after minus heightLeft" +heightLeft);
      //     while (heightLeft >= 0) {
      //         position = (heightLeft - imgHeight) + 5;
      //         pdf.addPage();
      //         pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      //         heightLeft -= pageHeight;
      //     } 
      //this.ExportButton.nativeElement.style.display = 'block';
      pdf.save('Contracts_Dashboard_Report.pdf');
      this.loading = false;
      alert("Download completed!");
    });

  }

  LoadAllCaptions() {
    this.setService.getCaptionFromDB("dashboard-contracts-new").subscribe((data: any) => {
      this.Names = data;
      console.log("names",this.Names);
    });

  }


  getCaption(sCaption: string) {
    var sResult = sCaption;
    if (this.Names != null) {
      this.Names.forEach(element => {
        if (element.OriginalName === sCaption) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }


}
