import { Component, OnInit } from '@angular/core';
import {DashboardProjectsComponent} from '../../dashboard/dashboard-projects/dashboard-projects.component'
@Component({
  selector: 'app-hero-dashboard-projects',
  templateUrl: './hero-dashboard-projects.component.html',
  styleUrls: ['./hero-dashboard-projects.component.css']
})
export class HeroDashboardProjectsComponent implements OnInit {

  constructor(private page:DashboardProjectsComponent) { }

  ngOnInit() {
  }

  public downloadPDF() {
    this.page.captureScreen();
  }
}
