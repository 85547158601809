import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-dashboard-contracts',
  templateUrl: './hero-dashboard-contracts.component.html',
  styleUrls: ['./hero-dashboard-contracts.component.css']
})
export class HeroDashboardContractsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
