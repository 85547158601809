import { Component, OnInit,Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';


export interface ProjectFinancials {
  financialYear: string;
  estimatedContractValue: number;
  estimatedContractSavings: number;
}

const PROJECT_FINANCIALS_DATA: ProjectFinancials[] = [
  {
      financialYear: 'Apr 18 - Mar 19',
      estimatedContractValue: 100000,
      estimatedContractSavings: 10000
  },
  {
      financialYear: 'Total',
      estimatedContractValue: 100000,
      estimatedContractSavings: 10000
  }
];

@Component({
  selector: 'app-dialog-resubmit-project-timetable-financials',
  templateUrl: './dialog-resubmit-project-timetable-financials.component.html',
  styleUrls: ['./dialog-resubmit-project-timetable-financials.component.scss']
})
export class DialogResubmitProjectTimetableFinancialsComponent implements OnInit {
  projectFinancialsDisplayedColumns = ['FinanceYear', 'ContractSpend', 'ContractSavings'];//['financialYear', 'estimatedContractValue', 'estimatedContractSavings'];
  projectFinancialsDataSource = new MatTableDataSource(PROJECT_FINANCIALS_DATA);
  financeData: any;
  constructor(private DbService : ProjectDatabaseService,public dialogRef: MatDialogRef<DialogResubmitProjectTimetableFinancialsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  ddlContractFinancialChanges  :any;
  ngOnInit() {
    this.ddlContractFinancialChanges = "yes";
// alert(sessionStorage.getItem('ResubmitProjectFinancial'));
    if(sessionStorage.getItem('ResubmitProjectFinancial') != null)
    {
      if(sessionStorage.getItem('ResubmitProjectFinancial') != "null")
    {
      this.ddlContractFinancialChanges =sessionStorage.getItem('ResubmitProjectFinancial');
    }
        
    }
    this.DbService.GetProjectFinancebyProjectID(this.data.ProjectID).subscribe((data:any) =>
    {
      this.financeData = data;
      
      if(data.length >0)
      {
       

        data.push({FinanceYeardisplay: 'Total', ProjectSpend: data.reduce((summ, v) => summ += parseInt(v.ProjectSpend), 0), ContractSpend: data.reduce((summ, v) => summ += parseInt(v.ContractSpend), 0) ,ProjectSavings:data.reduce((summ, v) => summ += parseInt(v.ProjectSavings), 0),ContractSavings:data.reduce((summ, v) => summ += parseInt(v.ContractSavings), 0)});
      }
      this.projectFinancialsDataSource = new MatTableDataSource(data);
    });
  }

  Save()
  {
    sessionStorage.setItem('ResubmitProjectFinancial', this.ddlContractFinancialChanges);
    console.log(" this.financeData", this.financeData);
    let finance = "";
    if (sessionStorage.getItem('ResubmitProjectFinancial').toString().toLowerCase() == "no") {
      let arr: Array<any> = this.financeData.slice(0, this.financeData.length - 1);

      for (var i in arr) {
        finance += arr[i]["FinanceYear"] + ";" + arr[i]["ProjectSpend"] + ";" + arr[i]["ProjectSavings"] + ";" + arr[i]["FinanceYeardisplay"] + "$";
      }
      console.log("finance", finance);
    }

    const slipageForm = new FormData();
    slipageForm.append("ProjectID", this.data.ProjectID);
    slipageForm.append("ContractStartDate", sessionStorage.getItem('ResubmitStartDate'));
    slipageForm.append("FinancialData", finance);

    //this.DbService.getSlippageValueResubmitProject(this.data.ProjectID, sessionStorage.getItem('ResubmitStartDate'), finance).subscribe((data:any) =>{
    this.DbService.getSlippageValueResubmitProjectPost(slipageForm).subscribe((data: any) => {
      // alert(data.value);
       // console.log(data.value);
       // this.FinancialYears=data.value;
    //  alert(data.value);
       sessionStorage.setItem('ResubmitProjectSlippage',data.value);
       this.dialogRef.close();
      });
    
  }

}
