import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
//import { CustomMaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatIconModule } from '@angular/material/icon'; 
import { MatInputModule } from '@angular/material/input'; 
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
// import { GanttModule } from '@syncfusion/ej2-angular-gantt';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgGanttEditorModule } from 'ng-gantt';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';

//import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import {
  MatDialogModule,MatCardModule,MatProgressSpinnerModule,
  MatToolbarModule
} from '@angular/material';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { RegisterComponent } from './register/register.component';
import { TellusComponent } from './tellus/tellus.component';
import { CreateteamComponent } from './createteam/createteam.component';
import { InviteusersComponent } from './inviteusers/inviteusers.component';
import { SignupcompleteComponent } from './signupcomplete/signupcomplete.component';
import { CreatepasswordComponent } from './createpassword/createpassword.component';
import { CreateclientAdminComponent } from './createclient-admin/createclient-admin.component';
import { HeaderComponent } from './header/header.component';
import { DashboardPerformanceComponent } from './dashboard/dashboard-performance/dashboard-performance.component';
import { DashboardProjectsComponent } from './dashboard/dashboard-projects/dashboard-projects.component';
import {HeroDashboardPerformanceComponent} from './hero/hero-dashboard-performance/hero-dashboard-performance.component';
import {HeroDashboardProjectsComponent} from './hero/hero-dashboard-projects/hero-dashboard-projects.component';
import { HeroDashboardContractsComponent } from './hero/hero-dashboard-contracts/hero-dashboard-contracts.component';
import { HeroDashboardProjectsDatabaseComponent } from './hero/hero-dashboard-projects-database/hero-dashboard-projects-database.component';
import { HeroDashboardProjectsNewProjectComponent } from './hero/hero-dashboard-projects-new-project/hero-dashboard-projects-new-project.component';
import { NotificationComponent } from './notification/notification.component';
import { DashboardContractsComponent } from './dashboard/dashboard-contracts/dashboard-contracts.component';
import { DashboardProjectsDatabaseComponent } from './dashboard/dashboard-projects-database/dashboard-projects-database.component';
import { DashboardProjectsNewProjectComponent } from './dashboard/dashboard-projects-new-project/dashboard-projects-new-project.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { LoginAlertDialogComponent } from './login-alert-dialog/login-alert-dialog.component';
import { DashboardProjectDetailsComponent } from './dashboard/dashboard-project-details/dashboard-project-details.component';
import { HeroDashboardProjectDetailsComponent } from './hero/hero-dashboard-project-details/hero-dashboard-project-details.component';
import { AccountSetupWizardComponent } from './account-setup-wizard/account-setup-wizard.component';
import { ExportDashboardPerformanceComponent } from './dashboard/export-dashboard-performance/export-dashboard-performance.component';
import { HeroDashboardContractsNewContractComponent } from './hero/hero-dashboard-contracts-new-contract/hero-dashboard-contracts-new-contract.component';
import { DashboardContractsNewContractComponent } from './dashboard/dashboard-contracts-new-contract/dashboard-contracts-new-contract.component';
import { HeroDashboardContractDetailsComponent } from './hero/hero-dashboard-contract-details/hero-dashboard-contract-details.component';
import { DashboardContractDetailsComponent } from './dashboard/dashboard-contract-details/dashboard-contract-details.component';
import { ProjectAdvanceSearchComponent } from './AdvanceSearch/project-advance-search/project-advance-search.component';
import { FormStepSaveComponent } from './form-step-save/form-step-save.component';
import { DialogAddNewTaskComponent } from './dialogs/dialog-add-new-task/dialog-add-new-task.component';
import { DialogAdvancedSearchComponent } from './dialogs/dialog-advanced-search/dialog-advanced-search.component';
import { DialogExtendContractComponent } from './dialogs/dialog-extend-contract/dialog-extend-contract.component';
import { DialogReprocureContractComponent } from './dialogs/dialog-reprocure-contract/dialog-reprocure-contract.component';
import { DialogNewProjectComponent } from './dialogs/dialog-new-project/dialog-new-project.component';
import { HeroDashboardContractsReportComponent } from './hero/hero-dashboard-contracts-report/hero-dashboard-contracts-report.component';
import { HeroDashboardProjectsReportComponent } from './hero/hero-dashboard-projects-report/hero-dashboard-projects-report.component';
import { DashboardContractsReportComponent } from './dashboard/dashboard-contracts-report/dashboard-contracts-report.component';
import { DashboardProjectsReportComponent } from './dashboard/dashboard-projects-report/dashboard-projects-report.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DashboardContractFilterComponent } from './dashboard/dashboard-contract-filter/dashboard-contract-filter.component';
import { DialogAddNewSupplierComponent } from './dialogs/dialog-add-new-supplier/dialog-add-new-supplier.component';
import { DialogAddNewProjectfinancialsComponent } from './dialogs/dialog-add-new-projectfinancials/dialog-add-new-projectfinancials.component';

import { DialogAdvanceSearchcontractComponent } from './dialogs/dialog-advance-searchcontract/dialog-advance-searchcontract.component';
import { DialogAddNewContractfinanceComponent } from './dialogs/dialog-add-new-contractfinance/dialog-add-new-contractfinance.component';
import { DashboardContractsNewComponent } from './dashboard/dashboard-contracts-new/dashboard-contracts-new.component';
import { HeroDashboardContractsDatabaseComponent } from './hero/hero-dashboard-contracts-database/hero-dashboard-contracts-database.component';
import { TestGanttComponent } from './test-gantt/test-gantt.component';
import { HeroSettingsComponent } from './hero/hero-settings/hero-settings.component';
import { SettingsComponent } from './settings/settings.component';
import { DateAdapter, MAT_DATE_LOCALE , MAT_DATE_FORMATS} from '@angular/material';
import {MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import { AlertComponent } from './alert/alert.component';
import { DialogCompleteProjectComponent } from './dialogs/dialog-complete-project/dialog-complete-project.component';
import { HeroAccountComponent } from './hero/hero-account/hero-account.component';
import { AccountComponent } from './account/account.component';
import { InvitesuserComponent } from './invitesuser/invitesuser.component';
import { EdituserComponent } from './invitesuser/edituser/edituser.component';
import { DialogGanttProjectComponent } from './dialogs/dialog-gantt-project/dialog-gantt-project.component';
import { CreateTeamDepartmentComponent } from './create-team-department/create-team-department.component';
import { DecimalPipe } from '@angular/common';
import { DialogTeamGanttComponent } from './dialogs/dialog-team-gantt/dialog-team-gantt.component';
import { DashboardPerformancelifetimeComponent } from './dashboard/dashboard-performancelifetime/dashboard-performancelifetime.component';
import { ReassignprojectComponent } from './invitesuser/reassignproject/reassignproject.component';
import { DialogPopupTestComponent } from './dialogs/dialog-popup-test/dialog-popup-test.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogEstimatedSavingsTargetComponent } from './dialogs/dialog-estimated-savings-target/dialog-estimated-savings-target.component';
import { DialogProjectDashboardComponent } from './dialogs/dialog-project-dashboard/dialog-project-dashboard.component';
import {NgxPrintModule} from 'ngx-print';
import { NgxLoadingModule } from 'ngx-loading';
import { EditfinanciallistComponent } from './invitesuser/editfinanciallist/editfinanciallist.component';
import { DialogReasonForChangeComponent } from './dialogs/dialog-reason-for-change/dialog-reason-for-change.component';
import { SystemNotificationsComponent } from './system-notifications/system-notifications.component';
import { EditcategorylistComponent } from './invitesuser/editcategorylist/editcategorylist.component';
import { HeroReportsProjectComponent } from './hero/hero-reports-project/hero-reports-project.component';
import { ReportsProjectComponent } from './reports/reports-project/reports-project.component';
import { EditprojectmilestoneComponent } from './invitesuser/editprojectmilestone/editprojectmilestone.component';
import { EditprojectstatusComponent } from './invitesuser/editprojectstatus/editprojectstatus.component';
import { EditprojectrisksComponent } from './invitesuser/editprojectrisks/editprojectrisks.component';
import { EditcontractGDPRComponent } from './invitesuser/editcontract-gdpr/editcontract-gdpr.component';
import { EditcontractstatusComponent } from './invitesuser/editcontractstatus/editcontractstatus.component';
import { EditcontracttypeComponent } from './invitesuser/editcontracttype/editcontracttype.component';
import { EditextensionperiodComponent } from './invitesuser/editextensionperiod/editextensionperiod.component';
import { EditprojectpriorityComponent } from './invitesuser/editprojectpriority/editprojectpriority.component';
import { EditprojectprogressComponent } from './invitesuser/editprojectprogress/editprojectprogress.component';
import { EditsubcategoryComponent } from './invitesuser/editsubcategory/editsubcategory.component';
import { DialogProjectClosureComponent } from './dialogs/dialog-project-closure/dialog-project-closure.component';
import { DialogUploadContractDocumentsComponent } from './dialogs/dialog-upload-contract-documents/dialog-upload-contract-documents.component';
import { DialogReasonforProjectapproverejectComponent } from './dialogs/dialog-reasonfor-projectapprovereject/dialog-reasonfor-projectapprovereject.component';
import { DialogResubmitProjectTimetableContractDatesComponent } from './dialogs/dialog-resubmit-project-timetable-contract-dates/dialog-resubmit-project-timetable-contract-dates.component';
import { DialogResubmitProjectTimetableFinancialsComponent } from './dialogs/dialog-resubmit-project-timetable-financials/dialog-resubmit-project-timetable-financials.component';
import { DialogResubmitProjectTimetableFinancialsAddNewComponent } from './dialogs/dialog-resubmit-project-timetable-financials-add-new/dialog-resubmit-project-timetable-financials-add-new.component';
import { DialogResubmitProjectTimetableImpactComponent } from './dialogs/dialog-resubmit-project-timetable-impact/dialog-resubmit-project-timetable-impact.component';
import { DialogResubmitProjectTimetableReasonComponent } from './dialogs/dialog-resubmit-project-timetable-reason/dialog-resubmit-project-timetable-reason.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsandConditionComponent } from './termsand-condition/termsand-condition.component';
import { CompanydetailsComponent } from './companydetails/companydetails.component';
import { OrderByPipe } from './order-by.pipe';
import { AllocatenewteamComponent } from './invitesuser/allocatenewteam/allocatenewteam.component';
import { DialogAddNewOrganisationComponent } from './dialogs/dialog-add-new-organisation/dialog-add-new-organisation.component';
import { DashboardPerformanceOnesourceComponent } from './dashboard/dashboard-performance-onesource/dashboard-performance-onesource.component';
import { EditProjectSavingsTargetComponent } from './invitesuser/edit-project-savings-target/edit-project-savings-target.component';
import { AppSettingsService } from './service/app-settings.service';
import { DefaultComponent } from './login/default.component';
import { AdduserComponent } from './invitesuser/adduser/adduser.component';

export const MY_Format = {
  parse: {
      dateInput: 'LL',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetpasswordComponent,
    RegisterComponent,
    TellusComponent,
    CreateteamComponent,
    InviteusersComponent,
    SignupcompleteComponent,
    CreatepasswordComponent,
    CreateclientAdminComponent,
    HeaderComponent,
    HeroDashboardPerformanceComponent,
    HeroDashboardProjectsComponent,
    DashboardPerformanceComponent,
    DashboardProjectsComponent,
    HeroDashboardContractsComponent,
    HeroDashboardProjectsDatabaseComponent,
    HeroDashboardProjectsNewProjectComponent,
    NotificationComponent,
    DashboardContractsComponent,
    DashboardProjectsDatabaseComponent,
    DashboardProjectsNewProjectComponent,
    AlertDialogComponent,
    LoginAlertDialogComponent,
    DashboardProjectDetailsComponent,
    HeroDashboardProjectDetailsComponent,
    AccountSetupWizardComponent,
    ExportDashboardPerformanceComponent,
    HeroDashboardContractsNewContractComponent,
    DashboardContractsNewContractComponent,
    HeroDashboardContractDetailsComponent,
    DashboardContractDetailsComponent,
    ProjectAdvanceSearchComponent,
    FormStepSaveComponent,
    DialogAddNewTaskComponent,
    DialogAdvancedSearchComponent,
    DialogExtendContractComponent,
    DialogReprocureContractComponent,
    DialogNewProjectComponent,
    HeroDashboardContractsReportComponent,
    HeroDashboardProjectsReportComponent,
    DashboardContractsReportComponent,
    DashboardProjectsReportComponent,
    PageNotFoundComponent,
    DashboardContractFilterComponent,
    DialogAddNewSupplierComponent,
    DialogAddNewProjectfinancialsComponent,
    DialogAdvanceSearchcontractComponent,
    DialogAddNewContractfinanceComponent,
    DashboardContractsNewComponent,
    HeroDashboardContractsDatabaseComponent,
    TestGanttComponent,
    HeroSettingsComponent,
    SettingsComponent,
    AlertComponent,
    DialogCompleteProjectComponent,
    HeroAccountComponent,
    AccountComponent,
    InvitesuserComponent,
    EdituserComponent,
    DialogGanttProjectComponent,
    CreateTeamDepartmentComponent,
    DialogTeamGanttComponent,
    DashboardPerformancelifetimeComponent,
    ReassignprojectComponent,
    DialogPopupTestComponent,
    DialogEstimatedSavingsTargetComponent,
    DialogProjectDashboardComponent,
    EditfinanciallistComponent,
    DialogReasonForChangeComponent,
    SystemNotificationsComponent,
    EditcategorylistComponent,
    HeroReportsProjectComponent,
    ReportsProjectComponent,
    EditprojectmilestoneComponent,
    EditprojectstatusComponent,
    EditprojectrisksComponent,
    EditcontractGDPRComponent,
    EditcontractstatusComponent,
    EditcontracttypeComponent,
    EditextensionperiodComponent,
    EditprojectpriorityComponent,
    EditprojectprogressComponent,
    EditsubcategoryComponent,
    DialogProjectClosureComponent,
    DialogUploadContractDocumentsComponent,
    DialogReasonforProjectapproverejectComponent,
    DialogResubmitProjectTimetableContractDatesComponent,
    DialogResubmitProjectTimetableFinancialsComponent,
    DialogResubmitProjectTimetableFinancialsAddNewComponent,
    DialogResubmitProjectTimetableImpactComponent,
    DialogResubmitProjectTimetableReasonComponent,
    PrivacypolicyComponent,
    TermsandConditionComponent,
    CompanydetailsComponent,
    OrderByPipe,
    AllocatenewteamComponent,
    DialogAddNewOrganisationComponent,
    DashboardPerformanceOnesourceComponent,
    EditProjectSavingsTargetComponent,
    DefaultComponent,
    AdduserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatChipsModule,
    // MatCoreModule,
    MatFormFieldModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatProgressSpinnerModule,
    //Ng2GoogleChartsModule,
    NgGanttEditorModule,
    // GanttModule,
    GoogleChartsModule,
    MatMomentDateModule,
    NgbModule,
    NgxPrintModule,
    NgxLoadingModule,
    AutocompleteLibModule,
	MatPaginatorModule,
	MatTableExporterModule
      ],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  
  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }, DecimalPipe, HeaderComponent, SystemNotificationsComponent,
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [AppSettingsService],
    useFactory: (appSettingsService: AppSettingsService) => {
      return () => {
        //Make sure to return a promise!
        return appSettingsService.loadAppConfig();
      };
    }
  }
  ],
  entryComponents: [
    AlertDialogComponent,
    LoginAlertDialogComponent,
    DialogAddNewTaskComponent,
    DialogAdvancedSearchComponent,
    DialogExtendContractComponent,
    DialogNewProjectComponent,
    DialogReprocureContractComponent,
    DialogAddNewSupplierComponent,
    DialogAddNewProjectfinancialsComponent,
    DialogAdvanceSearchcontractComponent,
    DialogAddNewContractfinanceComponent,
    AlertComponent,
    DialogCompleteProjectComponent,
    EdituserComponent,
    InvitesuserComponent,
    DialogGanttProjectComponent,
    CreateTeamDepartmentComponent,
    DialogTeamGanttComponent,
    ReassignprojectComponent,
    DialogEstimatedSavingsTargetComponent,
    DialogPopupTestComponent,
    DialogProjectDashboardComponent,
    EditfinanciallistComponent,
    DialogReasonForChangeComponent,
    EditcategorylistComponent,
    EditprojectstatusComponent,
    EditprojectmilestoneComponent,
    EditprojectrisksComponent,
    EditcontractGDPRComponent,
    EditcontractstatusComponent,
    EditcontracttypeComponent,
    EditextensionperiodComponent,
    EditprojectpriorityComponent,
    EditprojectprogressComponent,
    EditsubcategoryComponent,
    DialogProjectClosureComponent,
    DialogUploadContractDocumentsComponent,
    DialogReasonforProjectapproverejectComponent,
    DialogResubmitProjectTimetableContractDatesComponent,
    DialogResubmitProjectTimetableFinancialsComponent,
    DialogResubmitProjectTimetableFinancialsAddNewComponent,
    DialogResubmitProjectTimetableImpactComponent,
    DialogResubmitProjectTimetableReasonComponent,
    AllocatenewteamComponent,
    DialogAddNewOrganisationComponent,
    EditProjectSavingsTargetComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
