import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InvitesuserService } from "../invitesuser.service";
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import {AlertComponent} from '../../alert/alert.component';

@Component({
  selector: 'app-reassignproject',
  templateUrl: './reassignproject.component.html',
  styleUrls: ['./reassignproject.component.scss']
})
export class ReassignprojectComponent implements OnInit {

  constructor(public dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,private InvSerObj: InvitesuserService,private fb: FormBuilder) { 

    this.GetProjectsForUser();
  }
  ProjectsInfo:any;
  UserInfo:any;
  ReassignProjects:FormGroup;
  submitted:boolean=false;
  NoData:boolean=false;
  ngOnInit() {
    this.ReassignProjects = this.fb.group({
     
      //  ReassignUser0 : ['0'],
      //  ReassignUser1 : ['0'],
      //  ReassignUser2 : ['0'],
      //  ReassignUser3 : ['0']
   //  ProjectId0:['fff'],
  //   txtfirstname : [''],
    });
  }
  get f() { return this.ReassignProjects.controls; };
      GetProjectsForUser()
      {
        //alert(this.data.TeamID);
        this.InvSerObj.GetContacts_TeamID_withoutSelctedContact(this.data.TeamID,this.data.ContactID).subscribe(
          (data:any) => this.UserInfo=data
        );
        
         this.InvSerObj.getProjects_User(this.data.ContactID).subscribe((data:any) =>{
          this.ProjectsInfo=data;
          if(this.ProjectsInfo.length ==0)
          {
            this.NoData=true;
          }
          else
          {
            this.NoData=false;
          }
         });
         
      }
      
      UpdateReaeassignProjects()
      {
        console.log(this.ProjectsInfo);
      
        this.submitted=true;
        // if (this.ReassignProjects.invalid) 
        // {
        //   return;
        // }
        let validation=false;
        for(let i=0;i<this.ProjectsInfo.length;i++)
        {
          if(this.ProjectsInfo[i].user=="0")
          {
            validation=true;
            break;
          }
        }
        //alert(validation);
        if(validation==true)
        {
          alert("Please assign the all projects");
        }
        else
        {
              for(let i=0;i<this.ProjectsInfo.length;i++)
              {
                const formdata=new FormData();
                formdata.append('ProjectID', this.ProjectsInfo[i].ProjectID);
                formdata.append('user', this.ProjectsInfo[i].user );
                formdata.append('ContractorID', this.ProjectsInfo[i].ContractorID);
            
                this.InvSerObj.UpdateProjectUserDetails(formdata).subscribe((data:any)=>
                {
                  if(data.RetStatus =="Project Allocated")
                  {
                   //alert("Project(s) allocated sucessfully !");
                   this.openDialogRemoveUser();
                  }
                  else
                  {
                   alert("Something went wrong !");
                  
                  }
                });
              }
        }
      }
  openDialogRemoveUser()
  {
   
    //this.dialog.closeAll();
    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"ProjectReassign",ContactID:this.data.ContactID},
    width: '580px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result==true)
      {
        this.InvSerObj.ContactUserStatusUpdate(this.data.ContactID).subscribe((data:any) =>
        {
          if(data.value =="Updated")
          {
            alert("User Removed Successfully");
            this.dialog.closeAll();
          }
          else
          {
            alert(data.value);
          }
        });
      }
  });
  }

  UpdateUserStatus()
  {
    this.InvSerObj.ContactUserStatusUpdate(this.data.ContactID).subscribe((data:any) =>
    {
      if(data.value =="Updated")
      {
        alert("User Removed Successfully");
        this.dialog.closeAll();
      }
      else
      {
        alert(data.value);
      }
    });
  }
}
