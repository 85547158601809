import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginAlertDialogComponent } from '../login-alert-dialog/login-alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-createpassword',
  templateUrl: './createpassword.component.html',
  styleUrls: ['./createpassword.component.css']
})
export class CreatepasswordComponent implements OnInit {

  createpassword: FormGroup;
  hide: any = "password";
  hide1: any = "password";
  constructor(private fb: FormBuilder, private lService: LoginService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {
    this.hide = "password";
    this.hide1 = "password";
    if (sessionStorage.getItem("ContactID") != null) {

    }
    else {
      if (this.route.snapshot.queryParamMap.get('email') != null) {
        this.lService.EmailExists(this.route.snapshot.queryParamMap.get('email')).subscribe((data: any) => {
          console.log(data);
          if (data.value != "Exists") {
            alert("Not a valid User");
            this.router.navigate(["/login"]);
          }

        });
      }
      else {
        this.router.navigate(["/login"]);
      }
    }
  }

  ngOnInit() {
    this.createpassword = this.fb.group({
      passwordFormControl: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}')]],
      reenterpasswordFormControl: ['', [Validators.required]]
    });
  }
  ContactID: any;
  PasswordCreate() {
    if (!this.createpassword.valid) {
      return;
    }
    if (this.route.snapshot.queryParamMap.get('email') != null) {
      this.ContactID = this.route.snapshot.queryParamMap.get('int');
    }
    else {
      this.ContactID = sessionStorage.getItem("ContactID")
    }
    let email = sessionStorage.getItem("userMail");
    const formdata = new FormData();
    formdata.append('ContactID', this.ContactID);
    formdata.append('UserPassword', this.createpassword.get('passwordFormControl').value);
    formdata.append('Email', email);
    this.lService.Createnewpassword(formdata).subscribe((data: any) => {
      if (data.Status == "Success") {


        // if(this.route.snapshot.queryParamMap.get('email') !=null)
        if (sessionStorage.getItem("userMail")) {
          // alert("Your password has been changed successfully.");
          // 
          this.showdialog();
         
        }
        else {
          //this.router.navigate(['/tellus']); 

          this.lService.getContactByEmail(email).subscribe((data: any) => {

            this.Clearsession();
            sessionStorage.setItem('ContactID', data.ContactID);
            sessionStorage.setItem('usertype', data.UserType);
            sessionStorage.setItem('AdminPermission', data.AdminPermission);
            sessionStorage.setItem('userMail', data.ContactEmail);
            if (data.IsSuperUser) {
              sessionStorage.setItem('IsSuperUser', data.IsSuperUser);
            }

            if (data.AdminPerMission == "Yes") {
              this.router.navigate(['/dashboard/performance']);
            }
            else {
              this.router.navigate(['/dashboard/performance']);
            }
          });
          //this.lService.getUserType(sessionStorage.getItem("usertype")).subscribe((data:any) =>{


          // if(data.UserType_Name =="Client Administrator")
          // {
          //   this.router.navigate(['/createteam']);
          // }
          // else if(data.UserType_Name =="Head of Procurement")
          // {
          //   this.router.navigate(['/tellus']);
          // }
          // else
          // {
          //   this.router.navigate(['/dashboard']);
          // }
          //});     
        }
      }
      else {
        alert(data.Status);
        this.createpassword.get('passwordFormControl').reset();
        this.createpassword.get('reenterpasswordFormControl').reset();
      }
    });
  }

  showdialog() {
    let dialogRef = this.dialog.open(LoginAlertDialogComponent, { data: { Message: "Your password has been changed successfully." } });
    dialogRef.afterClosed().subscribe(result => {
      // NOTE: The result can also be nothing if the user presses the `esc` key or clicks outside the dialog
      if (result == 'confirm') {

        this.router.navigate(["/login"]);
      }
    });
  }

  Clearsession() {
    sessionStorage.removeItem('ContactID');
    sessionStorage.removeItem('usertype');
    sessionStorage.removeItem('AdminPermission');
    sessionStorage.removeItem('userMail');
    localStorage.removeItem('register');
    localStorage.removeItem('organisation');
    localStorage.removeItem('team');
    localStorage.removeItem('inviteuser');
    localStorage.removeItem('inviteClientAdmin');
    localStorage.removeItem("rememberemail");
    sessionStorage.removeItem("IsSuperUser");
  }


}
