import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from 'src/app/login/login.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardContractDetailsService {
  globalprojectspend : any;
  constructor(private http:HttpClient,private lService: LoginService) {

   }

   getContractDetails(ContractID : any)
  {
    return this.http.get(this.lService.ServiceIP +"/Contract/" + ContractID);
  }

  getAllOrganistaion()
  {
    return this.http.get(this.lService.ServiceIP +"/organisation");
  }

  getOrganisationForNonHOP(ContactID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/organisation/GetOrganisationForNonHOP(ContactID="+ ContactID +")");
  }

  getOrganisationForContract(ContractID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/project/GetOrganisationForContract(ContractID="+ ContractID +")");
  }

  getExtentionPeriod()
  {
    return this.http.get(this.lService.ServiceIP +"/ExtentionPeriod");
  }

  getAllCategories()
  {
    return this.http.get(this.lService.ServiceIP +"/Category");
  }
  getAllSubCategories()
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory");
  }

  //added on 11/02/2020
  getAllSubCategories_autocomplete()
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory/GetSubCategories_Autocomplete");

  }
 

  getSubCategory(CategoryID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory?$filter=FK_CategoryGroupID eq " + CategoryID);

  }

  UpdateContractSocialValue(formData:any)
  {
   return this.http.post(this.lService.ServiceIP + "/Contract/UpdateContractSocialValue", formData);
  }

  
   getSubCategorybyCat(CategoryID:number)
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory/GetSubCategorybyCategoryID(CategoryID="+ CategoryID +")");
  }

  getCategoryFromSubCategory(SubCategoryID : any)
  {

    return this.http.get(this.lService.ServiceIP +"/SubCategory/" + SubCategoryID);

  }

  getContractTypes()
  {
     return this.http.get(this.lService.ServiceIP +"/ContractType");
  
  }

  getContractStatus()
  {
     return this.http.get(this.lService.ServiceIP +"/ContractStatus");
  
  }
  CreateContract(formData:any)
  {
    return this.http.post(this.lService.ServiceIP + "/Contract/", formData);
  }

  getContractDocuments(ContractID : any)
  {
    return this.http.get(this.lService.ServiceIP +"/ContractDocument?$filter=FK_ContractID eq " + ContractID);
  }

  getSupportingDocuments(ContractID : any)
  {
    return this.http.get(this.lService.ServiceIP +"/SupportingDocument?$filter=FK_ContractID eq " + ContractID);
  }

  SaveContractDocument(formData:any)
  {
    return this.http.post(this.lService.ServiceIP + "/ContractDocument/", formData);
  }

  SaveSupportingDocument(formData:any)
  {
    return this.http.post(this.lService.ServiceIP + "/SupportingDocument/", formData);
  }

   getContractFile(DocumentID : any)
   {

    return this.http.get(this.lService.ServiceIP +"/ContractDocument/PdfFileDown(DocumentID=" + DocumentID  + ")", {responseType: 'blob'});

   }

   getSupportingFile(DocumentID : any)
   {

    return this.http.get(this.lService.ServiceIP +"/SupportingDocument/PdfFileDown(DocumentID=" + DocumentID  + ")", {responseType: 'blob'});

   }

   getContractSupplierFile(DocumentID : any)
   {

    return this.http.get(this.lService.ServiceIP +"/ContractSupplier/PdfFileDown(DocumentID=" + DocumentID  + ")", {responseType: 'blob'});

   }

   getContractSuppliers(ContractID : any)
   {

    return this.http.get(this.lService.ServiceIP +"/ContractSupplier/GetAllContractSuppliers(ContractID=" + ContractID + ")");

   }

   getSuppliersforOrganisation(ContractID : any)
   {

    return this.http.get(this.lService.ServiceIP +"/Supplier/getSuppliersForOrganisation(ContractID=" + ContractID + ")");

   }

   getAllSuppliersForOrganisation(ContractID : any)
   {

    return this.http.get(this.lService.ServiceIP +"/Supplier/getAllSuppliersForOrganisation(ContractID=" + ContractID + ")");

   }



   getContractSupplierDocuments(SupplierID : any,ContractID  :any)
   {

    return this.http.get(this.lService.ServiceIP +"/ContractSupplier?$filter=FK_SupplierID eq " + SupplierID + " and FK_ContractID eq " + ContractID);

   }

   getAllSuppliers()
   {
     return this.http.get(this.lService.ServiceIP + "/Supplier");
   }

   getSupplierDetails(ID :any)
   {
    return this.http.get(this.lService.ServiceIP + "/Supplier/" + ID);
   }

   SaveSupplierDocument(formData:any)
   {
    return this.http.post(this.lService.ServiceIP + "/ContractSupplier/", formData);
   
   }
   SaveContractFinance(formData:any)
   {
    return this.http.post(this.lService.ServiceIP + "/ContractFinance/", formData);
   
   }

   getContractFinancials(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/GetAllContractFinancial(ContractID=" + ContractID + ")");
   }

   getContractActualSpend(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/ContractActualSpend(ContractID=" + ContractID + ")");
   }

   getContractActualSavings(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/ContractActualSavings(ContractID=" + ContractID + ")");
   }

   getContractProjectedSpend(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/ContractProjectedSpend(ContractID=" + ContractID + ")");
   }

   getContractProjectedSavings(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/ContractProjectedSavings(ContractID=" + ContractID + ")");
   }

   getAnnualContractProjectedSpend(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/AnnualContractProjectedSpend(ContractID=" + ContractID + ")");
   }

   getAnnualContractProjectedSavings(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/AnnualContractProjectedSavings(ContractID=" + ContractID + ")");
   }

   getSpendPercentage(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/SpendPercentage(ContractID=" + ContractID + ")");
   }

   getSavingsPercentage(ContractID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/SavingPercentage(ContractID=" + ContractID + ")");
   }

   DeleteContract(ContractID : any,ContactID :any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/DeleteContract(ContractID=" + ContractID + ",ContactID=" + ContactID + ")");
 
   }


   getFinancialSettings()
   {
    return this.http.get(this.lService.ServiceIP +"/contractfinance/GetContractFinancialSettings");
 
   }

 //added on 05/02/2020
 getSuppliersforOrganisation_Dummy(OrganisationID : any)
 {

  return this.http.get(this.lService.ServiceIP +"/Supplier/getSuppliersForOrganisation_Dummy(OrganisationID=" + OrganisationID + ")");

 }

 //added on 13/02/2020
 getAllSubCategories_Search(searchtext : any)
 {
   return this.http.get(this.lService.ServiceIP +"/SubCategory/GetSubCategories_Autocomplete_Search(SearchSubCat='" + searchtext + "')");
 }

 getDisableCreateProjectFromContract (ContractID : any)
 {
   return this.http.get(this.lService.ServiceIP +"/Contract/DisableCreateProjectFromContract(ContractID=" + ContractID + ")");
 
 }	 
}
