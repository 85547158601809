import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  appConfig:any;
  constructor(private http: HttpClient) { }

  async loadAppConfig() {
    const data = await this.http.get('/assets/AppSetting.json')
      .toPromise();
    this.appConfig = data;
  }

  get siteKeys() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig;
  }

}
