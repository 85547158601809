import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class CreateteamService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  createTeam(formData:any)
  {
      return this.http.post(this.lService.ServiceIP +"/team",formData);
  }
  
  InviteUser(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/team/InviteTeamUser",formData);
  }

  TeamNameExists(TeamName:string)
  {
    return this.http.get(this.lService.ServiceIP +"/team/TeamExists(Team_Name='"+ TeamName +"')");
  }
}
