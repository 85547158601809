import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import { LoginService } from "../login/login.service";
@Injectable({
  providedIn: 'root'
})
export class InvitesuserService {

  //public ServiceIP:any;
  
  constructor(private http : HttpClient,private lgservice:LoginService) { 
   // this.ServiceIP="http://68.169.60.139:1311";
   // this.ServiceIP="http://localhost:50613";
  }
  GetAllUserTypesforInviteUser()
  {
    return this.http.get(this.lgservice.ServiceIP + "/usertype/GetAllUserTypesforInviteUser");
  }
  GetUserTypesforInviteUser()
  {
    return this.http.get(this.lgservice.ServiceIP +"/usertype/GetUserTypesforInviteUser");
  }
  GetUserTypesforInviteUserwithoutHOP()
  {
    return this.http.get(this.lgservice.ServiceIP +"/usertype/GetUserTypesforInviteUserWithHOP");
  }
  getTeamsbyOrganisation(Organisation_ID:string)
  {
    return this.http.get(this.lgservice.ServiceIP +"/team?$filter=OrganisationID eq " + Organisation_ID);
  }
  getTeamsbyTeamID(TeamID:string)
  {
    return this.http.get(this.lgservice.ServiceIP +"/team?$filter=TeamID eq " + TeamID);
  }
  getAssignedTeamsForUserbyOrgID(ContactID:number,OrganisationID:number)
  {
    return this.http.get(this.lgservice.ServiceIP +"/team/GetAssignedTeamsForUserbyOrgID(ContactID="+ ContactID+",OrganisationID="+ OrganisationID +")");
  }

  getTeamsbyContactID(ContactID:string)
  {
    return this.http.get(this.lgservice.ServiceIP +"/team/GetTeams_ContactId(contactID='"+ ContactID+"')");
  }

  AssignTeamstoUsers(formData:any)
  {
    
    return this.http.post(this.lgservice.ServiceIP +"/team/AssignTeamstoUsers",formData);
  }

  GetAssignedOrganisationForUser(ContactID:number)
  {
    return this.http.get(this.lgservice.ServiceIP +"/team/GetAssignedOrganisationForUser(ContactID="+ ContactID+")");
  }

  GetAssignedTeamsForUser(ContactID:number)
  {
    return this.http.get(this.lgservice.ServiceIP +"/team/GetAssignedTeamsForUser(ContactID='"+ ContactID+"')");
  }

  CreateContract(formData:any)
  {
    
    return this.http.post(this.lgservice.ServiceIP +"/team/CreateUser",formData);
  }
  UpdateContact(formData:any)
  {
 
    return this.http.post(this.lgservice.ServiceIP +"/team/UpdateUser",formData);
  }
  ConctactDetails(ContactID:any)
  {
   
    return this.http.get(this.lgservice.ServiceIP +"/Contact("+ ContactID+")");

  }

  GetConctactDetails(Email:any)
  {
   
    return this.http.get(this.lgservice.ServiceIP +"/contact/GetContactDetail(Email='"+ Email +"')");

  }

  UpdateContactLog(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/team/UpdateContactLog",formData);
  }
  getProjects_User(ContactID:any)
  {
    return this.http.get(this.lgservice.ServiceIP +"/Project/GetProjectforContactId(contactID="+ ContactID+")");
  }
  GetContacts(TeamID:any)
  {
    return this.http.get(this.lgservice.ServiceIP +"/Contact/GetContacts_TeamID(TeamID="+TeamID +")");
  }
  GetContacts_TeamID_withoutSelctedContact(TeamID:any,ContactID:any)
  {
    return this.http.get(this.lgservice.ServiceIP +"/Contact/GetContacts_TeamID_withoutSelctedContact(TeamID="+TeamID +",ContactID="+ ContactID +")");
  }
  UpdateProjectUserDetails(UserPeojectInfo:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/Project/UpdateProjectUser",UserPeojectInfo);
  }
  
  ContactUserStatusUpdate(ContactID:any)
  {
    return this.http.get(this.lgservice.ServiceIP +"/Contact/ContactStatusUpdate(ContactID="+ ContactID+")");
  }

  GetFinanceMasterYears()
  {
    return this.http.get(this.lgservice.ServiceIP +"/FinancialYearMaster/GetMasterYearList");
  }
  GetFinanceYears()
  {
    return this.http.get(this.lgservice.ServiceIP +"/FinanceYear/GetFinancialYears");
  }
  GetFinancialYearList()
  {
    return this.http.get(this.lgservice.ServiceIP +"/FinanceYear");
  }

  // GetFinanceYears()
  // {
  //   return this.http.get(this.lgservice.ServiceIP +"/FinanceYear?select=FinanceYearDisplay,FinanceYear");
  // }
  UpdateFinancialyears( formData:any) 
  {
    return this.http.post(this.lgservice.ServiceIP +"/FinanceYear/UpdateFinancialYears",formData);
  }

  GetCategories()
  {
    return this.http.get(this.lgservice.ServiceIP +"/Category?select=CategoryGroupDescription");
  }

  GetSelectedCategories()
  {
    return this.http.get(this.lgservice.ServiceIP +"/Category/GetCategories");
  }

  UpdateCategories( formData:any) 
  {
   return this.http.post(this.lgservice.ServiceIP +"/Category/UpdateCategories",formData);
   
  }

  CategoryNameUpdate(formData:any) 
  {
   return this.http.post(this.lgservice.ServiceIP +"/Category/CategoryUpdate",formData);
   
  }

  GetProjectStatusList()
  {
    return this.http.get(this.lgservice.ServiceIP +"/ProjectStatus/GetProjectStatus");
  }
DeactiveProjectStatus(formData:any)
{
  return this.http.post(this.lgservice.ServiceIP +"/ProjectStatus/DeactiveProjectStatus",formData);
}
  AddProjectStatusList(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/ProjectStatus/AddProjectStatus",formData);
  }
  GetProjectMileStoneList()
  {
    return this.http.get(this.lgservice.ServiceIP +"/project/GetProjectPhaseList");
  }
  DeactiveProjectMileStone(formData:any)
{
  return this.http.post(this.lgservice.ServiceIP +"/project/DeactiveProjectphase",formData);
}
  AddProjectMSList(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/project/AddProjectPhaseList",formData);
  }
  
  GetProjectRisks()
  {
    return this.http.get(this.lgservice.ServiceIP +"/projectrisk/GetProjectRisks");
  }
  DeactiveProjectRisks(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/projectrisk/DeactiveProjectRisks",formData);
  }
  AddProjectRisks(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/projectrisk/AddProjectRisks",formData);
  }
  
  GetProjectPriority()
  {
    return this.http.get(this.lgservice.ServiceIP +"/projectpriority/GetProjectPriority");
  }
  DeactiveProjectPriority(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/projectpriority/DeactiveProjectPriority",formData);
  }
  AddProjectPriority(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/projectpriority/AddProjectPriority",formData);
  }


  GetUserCountandTeamsCountUnder(LoginContactID:any)
  {
    return this.http.get(this.lgservice.ServiceIP +"/contact/GetUserCountandTeamsCountUnder(LoginContactID="+ LoginContactID +")");
  }

  GetExtensionPeriod()
  {
    return this.http.get(this.lgservice.ServiceIP +"/extentionperiod/GetExtensionPeriodList");
  }
  DeactiveExtensionPeriod(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/extentionperiod/DeactiveExtensionPeriodList",formData);
  }
  AddExtensionPeriod(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/extentionperiod/AddExtensionPeriodList",formData);
  }
  GetContracType()
  {
    return this.http.get(this.lgservice.ServiceIP +"/ContractType/GetContractTypeList");
  }
  DeactiveContracType(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/ContractType/DeactiveContractTypeList",formData);
  }
  AddContracType(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/ContractType/AddContractTypeList",formData);
  }
  GetContractStatus()
  {
    return this.http.get(this.lgservice.ServiceIP +"/ContractStatus/GetContractStatusList");
  }
  DeactiveContractStatus(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/ContractStatus/DeactiveContractStatusList",formData);
  }
  AddContracStatus(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/ContractStatus/AddContractStatusList",formData);
  }
  GetContractGDPR()
  {
    return this.http.get(this.lgservice.ServiceIP +"/GDPR/GetContractGDPRList");
  }
  DeactiveContractGDPR(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/GDPR/DeactiveContractGDPRist",formData);
  }
  AddContracGDPR(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/GDPR/AddContractGDPRList",formData);
  }
  GetProjectProgress()
  {
    return this.http.get(this.lgservice.ServiceIP +"/ProjectProgress/GetProjectProgress");
  }
  DeactiveProjectProgress(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/ProjectProgress/DeactiveProjectProgress",formData);
  }
  AddProjectProgress(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/ProjectProgress/AddProjectProgress",formData);
  }

  GetSubcategories() {
    return this.http.get(this.lgservice.ServiceIP +"/SubCategory/GetSubCategories");
  }
  DeactiveSubCategory(formData:any)
  {
  return this.http.post(this.lgservice.ServiceIP +"/SubCategory/DeactiveSubCategory",formData);
  }
  AddSubCategoryList(formData:any)
  {
    return this.http.post(this.lgservice.ServiceIP +"/SubCategory/AddSubCategoryList",formData);
  }
  
}
