import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jsPDF  from 'jspdf';
import * as html2canvas from 'html2canvas';
import {DashboardPerformanceService} from '../../dashboard/dashboard-performance/dashboard-performance.service';
import {DashboardProjectsService} from '../../dashboard/dashboard-projects/dashboard-projects.service';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import { DecimalPipe } from '@angular/common';
import {SettingsService} from '../../settings/settings.service';


@Component({
  selector: 'app-dialog-project-dashboard',
  templateUrl: './dialog-project-dashboard.component.html',
  styleUrls: ['./dialog-project-dashboard.component.scss']
})
export class DialogProjectDashboardComponent implements OnInit {

  @ViewChild('CategoryType',{static:true}) CategoryType:ElementRef;
  @ViewChild('PriorityType',{static:true}) PriorityType:ElementRef;

  Organisation:any;
 nameId:string;
 Teams:any;
TeamID:string;
OwnerID : any;
Owners :any;
Category:any;
ProjectNotStarted : any;
ProjectInProgress :any;
ProjectCompleted :any;
ProjectOnHold : any;
ProjectCancelled :any;
ProjectInProgress_N :any;
ContractValue : any;
//Charttitle = 'Browser market shares at a specific website, 2014';
Charttype:string;
Charttype1:string="ColumnChart";
Charttype2:string;
Charttype3:string="ColumnChart";

ProjectDataforNumber:any;
ProjectDataforValue:any;
ChartdataforCategory:any=[["",0]];
ChartdataforPriority:any=[["",0]];
columnNames:any;
columnNames2:any;
columnNamesforColumnChart:any;
columnNamesforColumnChart1:any;
Chartoptions:any;
Chartoptions1:any;
Chartoptions2:any;
Chartoptions3:any;

Chartheight:any;
Chartheight1:any;
Chartheight2:any;
Chartwidth:any;
Chartwidth1:any;
Chartwidth2:any;
NumberofProjects:any;
HighNumber:any=0;
MediumNumber:any=0;
LowNumber:any=0;
HighValue:any=0;
MediumValue:any=0;
LowValue:any=0;
CategoryHighRisk:any=0;
CategoryAtRisk:any=0;
CategoryOnTrack:any=0;
PriorityHighRisk:any=0;
PriorityAtRisk:any=0;
PriorityOnTrack:any=0;

@ViewChild('content', {static: false}) content: ElementRef;

@ViewChild('ExportButton' , {static: false}) private ExportButton: ElementRef;

Names:any;
constructor(private _decimalPipe: DecimalPipe, private PService :DashboardProjectsService, private router:Router,
 private route:ActivatedRoute,private Dservice:DashboardPerformanceService,private DbService:ProjectDatabaseService, private setService:SettingsService) {
  this.LoadAllCaptions();
  if(sessionStorage.getItem("ContactID") !=null)
   {
    this.Charttype = 'PieChart';
    this.Chartoptions={};
    this.ProjectDataforNumber=[["",0]];
    this.ProjectDataforValue=[["",0]];
    this.Charttype2 = 'PieChart';
    this.Chartoptions2={};
  

    if(sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.getOrganisation();
      //this.getOwners();
      this.getProjectsData();
    }
    else
    {
      this.getAllOrganisation();
    }
    this.pieChartforNumberofProjects();
    this.pieChartforValueofProjects();
    //this.getCategory();
    this.ColumnChartforCategory();
    this.ColumnChartforPriority();
   }
   else
   {
    if(localStorage.getItem("ContactID") !=null)
    {

    }
    else
    {
      this.router.navigate(["/login"]);
    }
   }
 }

ngOnInit() {
  this.nameId ="0";
  this.TeamID ="0";
  this.OwnerID="0";
  //this.getTeams();
 
  
}

ClearAllDropDowns()
{
  this.nameId ="0";
  this.TeamID ="0";
  this.OwnerID="0";
  if(sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.getOrganisation();
      //this.getOwners();
      this.getProjectOnwersbyTeamID(0);
      this.getProjectsData();
    }
    else
    {
      this.getAllOrganisation();
    }
    this.pieChartforNumberofProjects();
    this.pieChartforValueofProjects();
    //this.getCategory();
    this.ColumnChartforCategory();
    this.ColumnChartforPriority();
}
getOrganisation()
{
  this.Dservice.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    {
        this.Organisation=data.value;
      
    });
}

// getOwners()
// {
//   this.PService.getAllProjectsOwners(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
//     {
//         this.Owners=data;
//         this.OwnerID ="0";
      
//     });
// }
getProjectOnwersbyTeamID(TeamID:any)
{
  this.PService.getProjectsOwnersByTeamID(TeamID).subscribe((data:any) =>
  {
    this.Owners=data;
    this.OwnerID ="0";
  });
}

// getOwnersbyOrganisation()
// {
//   this.PService.getAllProjectsOwnersByOrgID(this.nameId).subscribe((data:any) =>
//     {
//         this.Owners=data;
//         this.OwnerID ="0";
      
//     });
// }

getAllOrganisation()
{
  this.Dservice.getAllOrganistaion().subscribe((data:any) =>
    {
        this.Organisation=data.value;
      
    });
}

getTeams()
{
  this.Dservice.getAllTeams().subscribe((data:any)=>{
    //this.Teams=data.value;
  });
}

getProjectsData()
{

  this.PService.getAllProjectsNo(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
  {
    this.ProjectNotStarted =data[0].ProjectNotStarted;
    this.ProjectInProgress =data[0].ProjectInProgress;
    this.ProjectCompleted =data[0].ProjectCompleted;
    this.ProjectOnHold =data[0].ProjectOnHold;
    this.ProjectCancelled =data[0].ProjectCancelled;
    this.ProjectInProgress_N =data[0].ProjectInProgress;
    this.ContractValue =data[0].ContractValue;
  });
}

getProjectsDataByOrg()
{

  this.PService.getAllProjectsNoByOrg(this.nameId).subscribe((data:any)=>
  {
    this.ProjectNotStarted =data[0].ProjectNotStarted;
    this.ProjectInProgress =data[0].ProjectInProgress;
    this.ProjectCompleted =data[0].ProjectCompleted;
    this.ProjectOnHold =data[0].ProjectOnHold;
    this.ProjectCancelled =data[0].ProjectCancelled;
    this.ProjectInProgress_N =data[0].ProjectInProgress;
    this.ContractValue =data[0].ContractValue;
  });
}

selectName()
{
  //alert(this.nameId);
  this.CategoryType.nativeElement.value=0;
  this.PriorityType.nativeElement.value=0;
  if(this.nameId =="0")
  {
    //this.getOwners();
    //this.getTeams();
    // this.Dservice.getTeamsbyOrganisation(this.nameId).subscribe((data:any) =>
    // {
    //   this.Teams=data.value;
    //   this.TeamID = "0";
    // });

    //added on 09/12/2019
    this.Dservice.getTeamsbyOrganisation(this.nameId).subscribe((data:any) =>
    {
      this.Teams=data.value;
      this.TeamID = "0";
      this.OwnerID="0";
      this.getProjectOnwersbyTeamID(0);
    });
  
    if(this.OwnerID != "0")
    {
      //added on 10/12/2019
     this.getProjectsDataByOwner();
     this.pieChartforNumberofProjects_ByOwner();
     this.pieChartforValueofProjects_ByOwner();
     this.ColumnChartforCategory_ByOwner();
     this.ColumnChartforPriority_ByOwner();
     
    }
    else{
      this.getProjectsData();
     this.pieChartforNumberofProjects();
     this.pieChartforValueofProjects();
     this.ColumnChartforCategory();
     this.ColumnChartforPriority();
     
    }
  }
  else
  {
    //this.getOwnersbyOrganisation();
    this.Dservice.getTeamsbyOrganisation(this.nameId).subscribe((data:any) =>
    {
      this.Teams=data.value;
      this.TeamID = "0";
    });
    
    //added on 09/12/2019
    if(this.OwnerID != "0")
    {
      //added on 10/12/2019
     this.getProjectsDataByOrg_Owner();
     this.pieChartforNumberofProjects_ByOrg_Owner();
     this.pieChartforValueofProjects_ByOrg_Owner();
     this.ColumnChartforCategory_ByOrg_Owner();
     this.ColumnChartforPriority_ByOrg_Owner();
    }
    else
    {
      this.getProjectsDataByOrg();
      this.pieChartforNumberofProjects_ByOrg();
      this.pieChartforValueofProjects_ByOrg();
      this.ColumnChartforCategory_ByOrg();
      this.ColumnChartforPriority_ByOrg();
    }
  }
  //get dashboard data

  
}


ProjectsFilter(FilterType:string)
{
  if(FilterType != "No Data")
  {
    if(this.OwnerID ==0)
    {
      if(this.TeamID == "0")
      {
        this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterBy: FilterType,OrgID:this.nameId}});
      }
      else
      {
        this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterBy: FilterType,OrgID:this.nameId,TeamID:this.TeamID}});
      }
      
    }
    else
    {
      this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterBy: FilterType,OrgID:this.nameId,OwnerName:this.OwnerID}});
    }
  }
}

onPrint()
{
  window.print();
}

printToCart(printSectionId: string){
  window.print();
  // let printContents = document.getElementById(printSectionId).innerHTML;
  // document.body.innerHTML = printContents;
  // window.print();
  // let popupWinindow
  // let innerContents = document.getElementById(printSectionId).innerHTML;
  // popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
  // popupWinindow.document.open();
  // popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
  // popupWinindow.document.close();
}

public captureScreen() {

  //this.ExportButton.nativeElement.style.display = 'none';


  //this.HeroService.visible=false;
  //this.ExportButton.nativeElement.remove();
  //this.nav.hide();
  //var delay=2000;
  //setTimeout(function() { }, delay);
  //var header ="<section class='hero' style='background-color: #F6F7FC;'><div class='section-container'><div class='hero--wrap'><div class='hero--title><h2>Performance</h2 </div><div class='hero--action'></div></div></div></section>";
  //const data = document.getElementById('content');
  const data=this.content.nativeElement;
  
    html2canvas(data).then(canvas => {
  const imgWidth = 210;
  const pageHeight = 295;
  //var top_left_margin = 15;
  const imgHeight = (canvas.height * imgWidth / canvas.width);
  console.log("imgHeight" +imgHeight);
  let heightLeft = imgHeight;
  //var totalPDFPages = Math.ceil(data.clientHeight/canvas.height)-1;
  //var PDF_Width = data.clientWidth+(top_left_margin*2);
  //var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
  const contentDataURL = canvas.toDataURL('image/png',1.0);
  
  const pdf = new jsPDF('p', 'mm','a4'); 
  let position = 0;
  
  pdf.addImage(contentDataURL, 'PNG', 0, position,imgWidth,imgHeight);
  heightLeft -= pageHeight;
  console.log("after minus heightLeft" +heightLeft);
      while (heightLeft >= 0) {
          position = (heightLeft - imgHeight) + 5;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
      } 
  //this.ExportButton.nativeElement.style.display = 'block';
  pdf.save('ProjectDashboard_Report.pdf');
  alert("Download Completed!");
  });
  
  //this.nav.show();
  //this.HeroService.visible=true;
}

pieChartforNumberofProjects()
{
  this.Charttype = 'PieChart';
  this.DbService.GetNumberofProjectsbyRisk(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  {
    //console.log("Number:" + data[0].Priority);
    this.HighNumber = data.HighRisk !=null ? data.HighRisk :0;
    this.MediumNumber = data.AtRisk !=null ? data.AtRisk :0;
    this.LowNumber = data.OnTrack !=null ? data.OnTrack :0;

    this.ProjectDataforNumber = [
      ['High Risk', data.HighRisk !=null ? data.HighRisk :0],
      ['At Risk', data.AtRisk !=null ? data.AtRisk :0],
      ['On Track', data.OnTrack !=null ? data.OnTrack :0]
   ];
   this.columnNames = ['Risk', 'Count'];
   this.Chartoptions = {  
      pieHole:0.52,
      legend: 'none',
      enableInteractivity: true,
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
   };
   this.Chartwidth = 430;
   this .Chartheight = 270;
  });
  
}
pieChartforValueofProjects()
{
  
  this.DbService.GetValueofProjectsbyRisk(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  {
    this.HighValue=data.HighRisk !=null ? data.HighRisk :0;
    this.MediumValue= data.AtRisk !=null ? data.AtRisk :0;
    this.LowValue=data.OnTrack !=null ? data.OnTrack :0;
   
    this.Charttype2 = 'PieChart';
    console.log(data.length);
    this.ProjectDataforValue = [
        ['High Risk', this.HighValue],
        ['At Risk', this.MediumValue],
        ['On Track', this.LowValue]
    ];
  //   this.ProjectDataforValue = [
  //     ['Firefox', 45.0],
  //     ['IE', 26.8],
  //     ['Chrome', 12.8]
  // ];
  this.columnNames2 = ['Priority', 'Value'];
  this.Chartoptions2 = {  
      pieHole:0.52,
      enableInteractivity: true,
      legend: 'none',
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
  };
  this.Chartwidth2 = 430;
  this .Chartheight2 = 270;
    });

  
}

getCategory()
{
  this.DbService.getCategory().subscribe((data:any) =>
  {
    
    this.Category=data.value;
    //this.CategoryID=data.value[0].PK_CategoryTypeID;
    //this.getSubCategory();
  });
}

ColumnChartforCategory()
{
  this.CategoryHighRisk=0;
  this.CategoryAtRisk=0;
  this.CategoryOnTrack=0;
  this.DbService.GetValueofProjectsForCategorybyRisk(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  {
    
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    this.CategoryHighRisk="\u00A3"+ this._decimalPipe.transform(this.CategoryHighRisk,'1.1-2')+"M";
    this.CategoryAtRisk= "\u00A3"+ this._decimalPipe.transform(this.CategoryAtRisk,'1.1-2')+"M";
    this.CategoryOnTrack= "\u00A3"+ this._decimalPipe.transform(this.CategoryOnTrack,'1.1-2')+"M";
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     hAxis:
     {
      baseline:0,
      // gridlines: {count: 2},
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       //gridlineColor: '#000000',
      //  gridlines: {
      //      color: 'transparent'
      //  },
      //  //format: '\u00A3',
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

onNumberofProjectSelect($event:any)
{
  console.log($event);
  let Filter="";
  if($event[0].row == 2)
  {
    Filter="OnTrack";
  }
  else if($event[0].row == 1)
  {
    Filter="AtRisk";
  }
  else
  {
    Filter="HighRisk";
  }
  if(this.OwnerID ==0)
  {
    if(this.TeamID =="0")
    {
      this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterByRisk: Filter,OrgID:this.nameId}});
    }
    else
    {
      this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterByRisk: Filter,OrgID:this.nameId, TeamID:this.TeamID}});
    }
    
  }
  else
  {
    this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterByRisk: Filter,OrgID:this.nameId,OwnerName:this.OwnerID}});
  }
  
}

onCategoryProjectSelect($event:any)
{
  let Filter="";
  let Risk="";
  if($event[0].row == 2)
  {
    Filter="Place";
    if($event[0].column == 1)
    {
      Risk="HighRisk";
    }
    else if($event[0].column == 2)
    {
      Risk="AtRisk";
    }
    else
    {
      Risk="OnTrack";
    }
  }
  else if($event[0].row == 1)
  {
    Filter="People";
    if($event[0].column == 1)
    {
      Risk="HighRisk";
    }
    else if($event[0].column == 2)
    {
      Risk="AtRisk";
    }
    else
    {
      Risk="OnTrack";
    }
  }
  else
  {
    Filter="Corporate";
    if($event[0].column == 1)
    {
      Risk="HighRisk";
    }
    else if($event[0].column == 2)
    {
      Risk="AtRisk";
    }
    else
    {
      Risk="OnTrack";
    }
  }
  if(this.OwnerID ==0)
  {
    if(this.TeamID =="0")
    {
      this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterbyCategory: Filter, RiskType:Risk,OrgID:this.nameId}});
    }
    else
    {
      this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterbyCategory: Filter, RiskType:Risk,OrgID:this.nameId,TeamID:this.TeamID}});
    }
    
  }
  else
  {
    this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterbyCategory: Filter, RiskType:Risk,OrgID:this.nameId,OwnerName:this.OwnerID}});
  }
  
  //console.log($event);
}
onPriorityProjectSelect($event:any)
{
  //console.log($event);
  let Filter="";
  let Risk="";
  if($event[0].row == 2)
  {
    Filter="Low";
    if($event[0].column == 1)
    {
      Risk="HighRisk";
    }
    else if($event[0].column == 2)
    {
      Risk="AtRisk";
    }
    else
    {
      Risk="OnTrack";
    }
  }
  else if($event[0].row == 1)
  {
    Filter="Medium";
    if($event[0].column == 1)
    {
      Risk="HighRisk";
    }
    else if($event[0].column == 2)
    {
      Risk="AtRisk";
    }
    else
    {
      Risk="OnTrack";
    }
  }
  else
  {
    Filter="High";
    if($event[0].column == 1)
    {
      Risk="HighRisk";
    }
    else if($event[0].column == 2)
    {
      Risk="AtRisk";
    }
    else
    {
      Risk="OnTrack";
    }
  }
  if(this.OwnerID ==0)
  {
    if(this.TeamID =="0")
    {
      this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterbyPriority: Filter, RiskType:Risk,OrgID:this.nameId}});
    }
    else
    {
      this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterbyPriority: Filter, RiskType:Risk,OrgID:this.nameId,TeamID:this.TeamID}});
    }
  }
  else
  {
    this.router.navigate(['/dashboard/projects/database'], { queryParams: {FilterbyPriority: Filter, RiskType:Risk,OrgID:this.nameId,OwnerName:this.OwnerID}});
  }
}

onCategorySelect(val:any)
{
  if(val == 1)
  {
    if(this.OwnerID ==0)
    {
      if(this.nameId =="0")
      {
        this.ColumnChart_GetNumberofProjectsForCategorybyRisk();
      }
      else
      {
        if(this.TeamID =="0")
        {
          this.ColumnChart_GetNumberofProjectsForCategorybyRisk_byOrgID();
        }
        else
        {
          this.ColumnChart_GetNumberofProjectsForCategorybyRisk_byOrgID_byTeam();
        }
      }
    }
    else
    {
      if(this.nameId =="0")
      {
        this.ColumnChart_GetNumberofProjectsForCategorybyRisk_OwnerName();
      }
      else
      {
        this.ColumnChart_GetNumberofProjectsForCategorybyRisk_byOrg_byOwnerName();
      }
    }
  }
  else
  {
    if(this.OwnerID ==0)
    {
      if(this.nameId =="0")
      {
        this.ColumnChartforCategory();
      }
      else
      {
        if(this.TeamID =="0")
        {
          this.ColumnChartforCategory_ByOrg();
        }
        else
        {
          this.ColumnChartforCategory_ByTeam();
        }
      }
    }
    else
    {
      if(this.nameId =="0")
      {
        this.ColumnChartforCategory_ByOwner();
      }
      else
      {
        this.ColumnChartforCategory_ByOrg_Owner();
      }
    }
  }
}
onPrioritySelect(val:any)
{
  if(val == 1)
  {
    if(this.OwnerID ==0)
    {
      if(this.nameId =="0")
      {
        this.ColumnChart_GetNumberofProjectsForPrioritybyRisk();
      }
      else
      {
        if(this.TeamID =="0")
        {
          this.ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOrg();
        }
        else
        {
          this.ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOrg_byTeam();
        }
      }
    }
    else
    {
      if(this.nameId =="0")
      {
        this.ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOwner();
      }
      else
      {
        this.ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOrg_byOwner();
      }
    }
  }
  else
  {
    if(this.OwnerID ==0)
    {
      if(this.nameId =="0")
      {
        this.ColumnChartforPriority();
      }
      else
      {
        if(this.TeamID =="0")
        {
          this.ColumnChartforPriority_ByOrg();
        }
        else
        {
          this.ColumnChartforPriority_ByTeam();
        }
      }
    }
    else
    {
      if(this.nameId =="0")
      {
        this.ColumnChartforPriority_ByOwner();
      }
      else
      {
        this.ColumnChartforPriority_ByOrg_Owner();
      }
    }
  }
}

ColumnChartforPriority()
{
    this.PriorityHighRisk=0;
    this.PriorityAtRisk=0;
    this.PriorityOnTrack=0;
  this.DbService.GetValueofProjectsForPrioritybyRisk(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
    this.PriorityHighRisk="\u00A3"+ this._decimalPipe.transform(this.PriorityHighRisk,'1.1-2') +"M";
    this.PriorityAtRisk="\u00A3"+ this._decimalPipe.transform(this.PriorityAtRisk,'1.1-2')+"M";
    this.PriorityOnTrack="\u00A3"+ this._decimalPipe.transform(this.PriorityOnTrack,'1.1-2')+"M";
    //this.Charttype1 = 'ColumnChart';
    // this.ChartdataforPriority = [
    //  ['High', 78, 40, 20],
    //  ['Medium', 98, 46, 25],
    //  ['Low', 66, 89, 30]   
    // ];
   // this.columnNamesforColumnChart1 = ['Priority', 'High','Medium', 'Low'];
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}

//added by zuber on 07/12/2019
pieChartforNumberofProjects_ByOrg()
{
  this.Charttype = 'PieChart';
  this.DbService.GetNumberofProjectsbyRiskByOrg(this.nameId,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    //console.log("Number:" + data[0].Priority);
    this.HighNumber = data.HighRisk !=null ? data.HighRisk :0;
    this.MediumNumber = data.AtRisk !=null ? data.AtRisk :0;
    this.LowNumber = data.OnTrack !=null ? data.OnTrack :0;

    this.ProjectDataforNumber = [
      ['High Risk', data.HighRisk !=null ? data.HighRisk :0],
      ['At Risk', data.AtRisk !=null ? data.AtRisk :0],
      ['On Track', data.OnTrack !=null ? data.OnTrack :0]
   ];
   this.columnNames = ['Risk', 'Count'];
   this.Chartoptions = {  
      pieHole:0.52,
      legend: 'none',
      enableInteractivity: true,
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
   };
   this.Chartwidth = 430;
   this .Chartheight = 270;
  });
  
}

pieChartforValueofProjects_ByOrg()
{
  
  this.DbService.GetValueofProjectsbyRiskByOrg(this.nameId,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    this.HighValue=data.HighRisk !=null ? data.HighRisk :0;
    this.MediumValue= data.AtRisk !=null ? data.AtRisk :0;
    this.LowValue=data.OnTrack !=null ? data.OnTrack :0;
   
    this.Charttype2 = 'PieChart';
    console.log(data.length);
    this.ProjectDataforValue = [
        ['High Risk', this.HighValue],
        ['At Risk', this.MediumValue],
        ['On Track', this.LowValue]
    ];
  //   this.ProjectDataforValue = [
  //     ['Firefox', 45.0],
  //     ['IE', 26.8],
  //     ['Chrome', 12.8]
  // ];
  this.columnNames2 = ['Priority', 'Value'];
  this.Chartoptions2 = {  
      pieHole:0.52,
      enableInteractivity: true,
      legend: 'none',
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
  };
  this.Chartwidth2 = 430;
  this .Chartheight2 = 270;
    });

  
}

ColumnChartforCategory_ByOrg()
{
  this.CategoryHighRisk=0;
  this.CategoryAtRisk=0;
  this.CategoryOnTrack=0;
  this.DbService.GetValueofProjectsForCategorybyRiskByOrg(this.nameId,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
     
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    this.CategoryHighRisk="\u00A3"+ this._decimalPipe.transform(this.CategoryHighRisk,'1.1-2')+"M";
    this.CategoryAtRisk= "\u00A3"+ this._decimalPipe.transform(this.CategoryAtRisk,'1.1-2')+"M";
    this.CategoryOnTrack= "\u00A3"+ this._decimalPipe.transform(this.CategoryOnTrack,'1.1-2')+"M";
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3',
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChartforPriority_ByOrg()
{
    this.PriorityHighRisk=0;
    this.PriorityAtRisk=0;
    this.PriorityOnTrack=0;

  this.DbService.GetValueofProjectsForPrioritybyRiskByOrg(this.nameId,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
    this.PriorityHighRisk="\u00A3" + this._decimalPipe.transform(this.PriorityHighRisk,'1.1-2') +"M";
    this.PriorityAtRisk="\u00A3" + this._decimalPipe.transform(this.PriorityAtRisk,'1.1-2')+"M";
    this.PriorityOnTrack="\u00A3" + this._decimalPipe.transform(this.PriorityOnTrack,'1.1-2')+"M";
    //this.Charttype1 = 'ColumnChart';
    // this.ChartdataforPriority = [
    //  ['High', 78, 40, 20],
    //  ['Medium', 98, 46, 25],
    //  ['Low', 66, 89, 30]   
    // ];
   // this.columnNamesforColumnChart1 = ['Priority', 'High','Medium', 'Low'];
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}

//added by zuber on 09/12/2019
TeamChange()
{
  this.CategoryType.nativeElement.value=0;
  this.PriorityType.nativeElement.value=0;
 if(this.TeamID =="0") //All Teams
 {
   this.OwnerID="0";
   this.getProjectOnwersbyTeamID(this.TeamID);
   this.pieChartforNumberofProjects_ByOrg();
   this.pieChartforValueofProjects_ByOrg();
   this.ColumnChartforCategory_ByOrg();
   this.ColumnChartforPriority_ByOrg();
   this.getProjectsData();
 }
 else
 {
   this.pieChartforNumberofProjects_ByTeam();
   this.pieChartforValueofProjects_ByTeam();
   this.ColumnChartforCategory_ByTeam();
   this.ColumnChartforPriority_ByTeam();
    //added on 10/12/2019
    this.getProjectsDataByTeam();
    this.getProjectOnwersbyTeamID(this.TeamID);
 }
}
pieChartforNumberofProjects_ByTeam()
{
  this.Charttype = 'PieChart';
  this.DbService.GetNumberofProjectsbyRiskByTeam(this.nameId,this.TeamID,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    //console.log("Number:" + data[0].Priority);
    this.HighNumber = data.HighRisk !=null ? data.HighRisk :0;
    this.MediumNumber = data.AtRisk !=null ? data.AtRisk :0;
    this.LowNumber = data.OnTrack !=null ? data.OnTrack :0;

    this.ProjectDataforNumber = [
      ['High Risk', data.HighRisk !=null ? data.HighRisk :0],
      ['At Risk', data.AtRisk !=null ? data.AtRisk :0],
      ['On Track', data.OnTrack !=null ? data.OnTrack :0]
   ];
   this.columnNames = ['Risk', 'Count'];
   this.Chartoptions = {  
      pieHole:0.52,
      legend: 'none',
      enableInteractivity: true,
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
   };
   this.Chartwidth = 430;
   this .Chartheight = 270;
  });
  
}

pieChartforValueofProjects_ByTeam()
{
  
  this.DbService.GetValueofProjectsbyRiskByTeam(this.nameId,this.TeamID,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    this.HighValue=data.HighRisk !=null ? data.HighRisk :0;
    this.MediumValue= data.AtRisk !=null ? data.AtRisk :0;
    this.LowValue=data.OnTrack !=null ? data.OnTrack :0;
   
    this.Charttype2 = 'PieChart';
    console.log(data.length);
    this.ProjectDataforValue = [
        ['High Risk', this.HighValue],
        ['At Risk', this.MediumValue],
        ['On Track', this.LowValue]
    ];
  //   this.ProjectDataforValue = [
  //     ['Firefox', 45.0],
  //     ['IE', 26.8],
  //     ['Chrome', 12.8]
  // ];
  this.columnNames2 = ['Priority', 'Value'];
  this.Chartoptions2 = {  
      pieHole:0.52,
      enableInteractivity: true,
      legend: 'none',
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
  };
  this.Chartwidth2 = 430;
  this .Chartheight2 = 270;
    });

  
}

ColumnChartforCategory_ByTeam()
{
 this.CategoryHighRisk =0;
 this.CategoryAtRisk =0;
this.CategoryOnTrack =0;
  this.DbService.GetValueofProjectsForCategorybyRiskByTeam(this.nameId,this.TeamID,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
  
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
     
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    this.CategoryHighRisk="\u00A3"+ this._decimalPipe.transform(this.CategoryHighRisk,'1.1-2')+"M";
    this.CategoryAtRisk= "\u00A3"+ this._decimalPipe.transform(this.CategoryAtRisk,'1.1-2')+"M";
    this.CategoryOnTrack= "\u00A3"+ this._decimalPipe.transform(this.CategoryOnTrack,'1.1-2')+"M";
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3',
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChartforPriority_ByTeam()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetValueofProjectsForPrioritybyRiskByTeam(this.nameId,this.TeamID,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
    this.PriorityHighRisk="\u00A3" + this._decimalPipe.transform(this.PriorityHighRisk,'1.1-2') +"M";
    this.PriorityAtRisk="\u00A3" + this._decimalPipe.transform(this.PriorityAtRisk,'1.1-2')+"M";
    this.PriorityOnTrack="\u00A3" + this._decimalPipe.transform(this.PriorityOnTrack,'1.1-2')+"M";
    //this.Charttype1 = 'ColumnChart';
    // this.ChartdataforPriority = [
    //  ['High', 78, 40, 20],
    //  ['Medium', 98, 46, 25],
    //  ['Low', 66, 89, 30]   
    // ];
   // this.columnNamesforColumnChart1 = ['Priority', 'High','Medium', 'Low'];
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}

//
pieChartforNumberofProjects_ByOwner()
{
  this.Charttype = 'PieChart';
  this.DbService.GetNumberofProjectsbyRiskByOwner(sessionStorage.getItem("ContactID"),this.OwnerID).subscribe((data:any) =>
  {
    //console.log("Number:" + data[0].Priority);
    this.HighNumber = data.HighRisk !=null ? data.HighRisk :0;
    this.MediumNumber = data.AtRisk !=null ? data.AtRisk :0;
    this.LowNumber = data.OnTrack !=null ? data.OnTrack :0;

    this.ProjectDataforNumber = [
      ['High Risk', data.HighRisk !=null ? data.HighRisk :0],
      ['At Risk', data.AtRisk !=null ? data.AtRisk :0],
      ['On Track', data.OnTrack !=null ? data.OnTrack :0]
   ];
   this.columnNames = ['Risk', 'Count'];
   this.Chartoptions = {  
      pieHole:0.52,
      legend: 'none',
      enableInteractivity: true,
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
   };
   this.Chartwidth = 430;
   this .Chartheight = 270;
  });
  
}

pieChartforValueofProjects_ByOwner()
{
  
  this.DbService.GetValueofProjectsbyRiskByOwner(sessionStorage.getItem("ContactID"),this.OwnerID).subscribe((data:any) =>
  {
    this.HighValue=data.HighRisk !=null ? data.HighRisk :0;
    this.MediumValue= data.AtRisk !=null ? data.AtRisk :0;
    this.LowValue=data.OnTrack !=null ? data.OnTrack :0;
   
    this.Charttype2 = 'PieChart';
    console.log(data.length);
    this.ProjectDataforValue = [
        ['High Risk', this.HighValue],
        ['At Risk', this.MediumValue],
        ['On Track', this.LowValue]
    ];
  //   this.ProjectDataforValue = [
  //     ['Firefox', 45.0],
  //     ['IE', 26.8],
  //     ['Chrome', 12.8]
  // ];
  this.columnNames2 = ['Priority', 'Value'];
  this.Chartoptions2 = {  
      pieHole:0.52,
      enableInteractivity: true,
      legend: 'none',
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
  };
  this.Chartwidth2 = 430;
  this .Chartheight2 = 270;
    });

  
}

ColumnChartforCategory_ByOwner()
{
 this.CategoryHighRisk =0;
 this.CategoryAtRisk =0;
this.CategoryOnTrack =0;
  this.DbService.GetValueofProjectsForCategorybyRiskByOwner(sessionStorage.getItem("ContactID"),this.OwnerID).subscribe((data:any) =>
  {
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
  
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
     
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    this.CategoryHighRisk="\u00A3"+ this._decimalPipe.transform(this.CategoryHighRisk,'1.1-2')+"M";
    this.CategoryAtRisk= "\u00A3"+ this._decimalPipe.transform(this.CategoryAtRisk,'1.1-2')+"M";
    this.CategoryOnTrack= "\u00A3"+ this._decimalPipe.transform(this.CategoryOnTrack,'1.1-2')+"M";
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3',
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChartforPriority_ByOwner()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetValueofProjectsForPrioritybyRiskByOwner(sessionStorage.getItem("ContactID"),this.OwnerID).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
    this.PriorityHighRisk="\u00A3" + this._decimalPipe.transform(this.PriorityHighRisk,'1.1-2') +"M";
    this.PriorityAtRisk="\u00A3" + this._decimalPipe.transform(this.PriorityAtRisk,'1.1-2')+"M";
    this.PriorityOnTrack="\u00A3" + this._decimalPipe.transform(this.PriorityOnTrack,'1.1-2')+"M";
    //this.Charttype1 = 'ColumnChart';
    // this.ChartdataforPriority = [
    //  ['High', 78, 40, 20],
    //  ['Medium', 98, 46, 25],
    //  ['Low', 66, 89, 30]   
    // ];
   // this.columnNamesforColumnChart1 = ['Priority', 'High','Medium', 'Low'];
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}


OwnerChange()
{
  this.CategoryType.nativeElement.value=0;
  this.PriorityType.nativeElement.value=0;
  //this.TeamID="0";
      if(this.OwnerID == "0") //All Owner's
      {
        // alert("hi");
       this.pieChartforNumberofProjects();
       this.pieChartforValueofProjects();
       this.ColumnChartforCategory();
       this.ColumnChartforPriority();
        //added on 10/12/2019
        this.nameId ="0";
        this.getProjectsData();

      }else{
       //alert("hi2");
        if(this.nameId == "0") // All Organisation
        {
         this.pieChartforNumberofProjects_ByOwner();
         this.pieChartforValueofProjects_ByOwner();
         this.ColumnChartforCategory_ByOwner();
         this.ColumnChartforPriority_ByOwner();
         //added on 10/12/2019
         this.getProjectsDataByOwner();

        }
        else{
         this.pieChartforNumberofProjects_ByOrg_Owner();
         this.pieChartforValueofProjects_ByOrg_Owner();
         this.ColumnChartforCategory_ByOrg_Owner();
         this.ColumnChartforPriority_ByOrg_Owner();
          //added on 10/12/2019
          this.getProjectsDataByOrg_Owner();
        }

      }
      
}

pieChartforNumberofProjects_ByOrg_Owner()
{
  this.Charttype = 'PieChart';
  this.DbService.GetNumberofProjectsbyRiskByOrg_Owner(this.nameId,this.OwnerID).subscribe((data:any) =>
  {
    //console.log("Number:" + data[0].Priority);
    this.HighNumber = data.HighRisk !=null ? data.HighRisk :0;
    this.MediumNumber = data.AtRisk !=null ? data.AtRisk :0;
    this.LowNumber = data.OnTrack !=null ? data.OnTrack :0;

    this.ProjectDataforNumber = [
      ['High Risk', data.HighRisk !=null ? data.HighRisk :0],
      ['At Risk', data.AtRisk !=null ? data.AtRisk :0],
      ['On Track', data.OnTrack !=null ? data.OnTrack :0]
   ];
   this.columnNames = ['Risk', 'Count'];
   this.Chartoptions = {  
      pieHole:0.52,
      legend: 'none',
      enableInteractivity: true,
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
   };
   this.Chartwidth = 430;
   this .Chartheight = 270;
  });
  
}

pieChartforValueofProjects_ByOrg_Owner()
{
  
  this.DbService.GetValueofProjectsbyRiskByOrg_Owner(this.nameId,this.OwnerID).subscribe((data:any) =>
  {
    this.HighValue=data.HighRisk !=null ? data.HighRisk :0;
    this.MediumValue= data.AtRisk !=null ? data.AtRisk :0;
    this.LowValue=data.OnTrack !=null ? data.OnTrack :0;
   
    this.Charttype2 = 'PieChart';
    console.log(data.length);
    this.ProjectDataforValue = [
        ['High Risk', this.HighValue],
        ['At Risk', this.MediumValue],
        ['On Track', this.LowValue]
    ];
  //   this.ProjectDataforValue = [
  //     ['Firefox', 45.0],
  //     ['IE', 26.8],
  //     ['Chrome', 12.8]
  // ];
  this.columnNames2 = ['Priority', 'Value'];
  this.Chartoptions2 = {  
      pieHole:0.52,
      enableInteractivity: true,
      legend: 'none',
      colors:['#FF6139','#FABF2D','#177DAD'],
      chartArea:{left:50,top:5,bottom:5,width:'100%',height:'100%'},
      pieSliceText: 'none',
        // add this line
  };
  this.Chartwidth2 = 430;
  this .Chartheight2 = 270;
    });

  
}

ColumnChartforCategory_ByOrg_Owner()
{
 this.CategoryHighRisk =0;
 this.CategoryAtRisk =0;
this.CategoryOnTrack =0;
  this.DbService.GetValueofProjectsForCategorybyRiskByOrg_Owner(this.nameId,this.OwnerID).subscribe((data:any) =>
  {
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
  
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
     
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    this.CategoryHighRisk="\u00A3"+ this._decimalPipe.transform(this.CategoryHighRisk,'1.1-2')+"M";
    this.CategoryAtRisk= "\u00A3"+ this._decimalPipe.transform(this.CategoryAtRisk,'1.1-2')+"M";
    this.CategoryOnTrack= "\u00A3"+ this._decimalPipe.transform(this.CategoryOnTrack,'1.1-2')+"M";
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3',
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChartforPriority_ByOrg_Owner()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetValueofProjectsForPrioritybyRiskByOrg_Owner(this.nameId,this.OwnerID).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
    this.PriorityHighRisk="\u00A3" + this._decimalPipe.transform(this.PriorityHighRisk,'1.1-2') +"M";
    this.PriorityAtRisk="\u00A3" + this._decimalPipe.transform(this.PriorityAtRisk,'1.1-2')+"M";
    this.PriorityOnTrack="\u00A3" + this._decimalPipe.transform(this.PriorityOnTrack,'1.1-2')+"M";
    //this.Charttype1 = 'ColumnChart';
    // this.ChartdataforPriority = [
    //  ['High', 78, 40, 20],
    //  ['Medium', 98, 46, 25],
    //  ['Low', 66, 89, 30]   
    // ];
   // this.columnNamesforColumnChart1 = ['Priority', 'High','Medium', 'Low'];
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}

 //addded on 10/12/2019 by zuber
 getProjectsDataByTeam()
 {

   this.PService.getAllProjectsNoByTeam(this.nameId,this.TeamID).subscribe((data:any)=>
   {
     this.ProjectNotStarted =data[0].ProjectNotStarted;
     this.ProjectInProgress =data[0].ProjectInProgress;
     this.ProjectCompleted =data[0].ProjectCompleted;
     this.ProjectOnHold =data[0].ProjectOnHold;
     this.ProjectCancelled =data[0].ProjectCancelled;
     this.ProjectInProgress_N =data[0].ProjectInProgress;
     this.ContractValue =data[0].ContractValue;
   });
 }

 getProjectsDataByOwner()
 {

   this.PService.getAllProjectsNoByOwner(sessionStorage.getItem("ContactID"),this.OwnerID).subscribe((data:any)=>
   {
     this.ProjectNotStarted =data[0].ProjectNotStarted;
     this.ProjectInProgress =data[0].ProjectInProgress;
     this.ProjectCompleted =data[0].ProjectCompleted;
     this.ProjectOnHold =data[0].ProjectOnHold;
     this.ProjectCancelled =data[0].ProjectCancelled;
     this.ProjectInProgress_N =data[0].ProjectInProgress;
     this.ContractValue =data[0].ContractValue;
   });
 }

 getProjectsDataByOrg_Owner()
 {

   this.PService.getAllProjectsNoByOrg_Owner(this.nameId,this.OwnerID).subscribe((data:any)=>
   {
     this.ProjectNotStarted =data[0].ProjectNotStarted;
     this.ProjectInProgress =data[0].ProjectInProgress;
     this.ProjectCompleted =data[0].ProjectCompleted;
     this.ProjectOnHold =data[0].ProjectOnHold;
     this.ProjectCancelled =data[0].ProjectCancelled;
     this.ProjectInProgress_N =data[0].ProjectInProgress;
     this.ContractValue =data[0].ContractValue;
   });
  }

ColumnChart_GetNumberofProjectsForPrioritybyRisk()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetNumberofProjectsForPrioritybyRisk(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
      format:'0',
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}

ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOrg()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetNumberofProjectsForPrioritybyRisk_byOrg(this.nameId,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
      format:'0',
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}
ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOrg_byTeam()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetNumberofProjectsForPrioritybyRisk_byOrg_byTeam(this.nameId,this.TeamID,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
      format:'0',
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}
ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOwner()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetNumberofProjectsForPrioritybyRisk_byOwner(sessionStorage.getItem("ContactID"),this.OwnerID).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
      format:'0',
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}

ColumnChart_GetNumberofProjectsForPrioritybyRisk_byOrg_byOwner()
{
 this.PriorityHighRisk =0;
 this.PriorityAtRisk =0;
 this.PriorityOnTrack =0;
  this.DbService.GetNumberofProjectsForPrioritybyRisk_byOrg_byOwner(this.nameId,this.OwnerID).subscribe((data:any) =>
  {
    let FinalData=[];
    this.Charttype3 = 'ColumnChart';
   
    data.forEach(element => {
      FinalData.push(["" + element.Priority +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.PriorityHighRisk +=element.HighRisk;
      this.PriorityAtRisk +=element.AtRisk;
      this.PriorityOnTrack +=element.OnTrack;
    });
    this.ChartdataforPriority=FinalData;
   this.columnNamesforColumnChart1 = ['', 'High Risk','At Risk', 'On Track'];
    this.Chartoptions3 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     crosshair: { trigger: 'both' },
     hAxis:
     {
       baselineColor: 'black',
       baseline: 0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
      format:'0',
       baselineColor: 'black',
      //  gridlines: {
      //      color: 'transparent'
      //  },
      // format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
  
}

ColumnChart_GetNumberofProjectsForCategorybyRisk()
{
  this.CategoryHighRisk=0;
  this.CategoryAtRisk=0;
  this.CategoryOnTrack=0;
  this.DbService.GetNumberofProjectsForCategorybyRisk(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
  {
    
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,right:0,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     hAxis:
     {
      baseline:0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
      format:'0',
       //gridlineColor: '#000000',
      //  gridlines: {
      //      color: 'transparent'
      //  },
      //  //format: '\u00A3',
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChart_GetNumberofProjectsForCategorybyRisk_byOrgID()
{
  this.CategoryHighRisk=0;
  this.CategoryAtRisk=0;
  this.CategoryOnTrack=0;
  this.DbService.GetNumberofProjectsForCategorybyRisk_byOrgID(this.nameId,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     hAxis:
     {
      baseline:0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       //gridlineColor: '#000000',
      //  gridlines: {
      //      color: 'transparent'
      //  },
      //  //format: '\u00A3',
       //format: '\u00A3###M',
       format:'0',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChart_GetNumberofProjectsForCategorybyRisk_byOrgID_byTeam()
{
  this.CategoryHighRisk=0;
  this.CategoryAtRisk=0;
  this.CategoryOnTrack=0; 
  this.DbService.GetNumberofProjectsForCategorybyRisk_byOrgID_byTeam(this.nameId,this.TeamID,Number(sessionStorage.getItem("ContactID"))).subscribe((data:any) =>
  {
    
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     hAxis:
     {
      baseline:0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
      format:'0',
       //gridlineColor: '#000000',
      //  gridlines: {
      //      color: 'transparent'
      //  },
      //  //format: '\u00A3',
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChart_GetNumberofProjectsForCategorybyRisk_OwnerName()
{
  this.CategoryHighRisk=0;
  this.CategoryAtRisk=0;
  this.CategoryOnTrack=0;
  this.DbService.GetNumberofProjectsForCategorybyRisk_OwnerName(sessionStorage.getItem("ContactID"),this.OwnerID).subscribe((data:any) =>
  {
    
   
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     hAxis:
     {
      baseline:0,
   
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       //gridlineColor: '#000000',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       format:'0',
      //  //format: '\u00A3',
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}

ColumnChart_GetNumberofProjectsForCategorybyRisk_byOrg_byOwnerName()
{
  this.CategoryHighRisk=0;
  this.CategoryAtRisk=0;
  this.CategoryOnTrack=0;
  this.DbService.GetNumberofProjectsForCategorybyRisk_byOrg_byOwnerName(this.nameId,this.OwnerID).subscribe((data:any) =>
  {
    
    let FinalData=[];
    //let ColumnData=[];
    this.Charttype1 = 'ColumnChart';
    data.forEach(element => {
      FinalData.push(["" + element.Category +"",element.HighRisk,element.AtRisk,element.OnTrack]);
      this.CategoryHighRisk +=element.HighRisk;
      this.CategoryAtRisk +=element.AtRisk;
      this.CategoryOnTrack +=element.OnTrack;
    });
    this.ChartdataforCategory=FinalData;
    // this.ChartdataforCategory = [
    //  ['People', 78, 40, 20],
    //  ['Place', 98, 46, 25],
    //  ['Corporate', 66, 89, 30]   
    // ];
    this.columnNamesforColumnChart = ['', 'High Risk','At Risk', 'On Track'];
    //this.columnNamesforColumnChart=ColumnData;
    this.Chartoptions1 = {
     legend: 'none',
     colors:['#FF6139','#FABF2D','#177DAD'],
     chartArea:{left:40,top:20,bottom:30,width:'100%',height:'100%'},
     bar: { groupWidth: "80%" },
     enableInteractivity: true,
     hAxis:
     {
      baseline:0,
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
     },
     vAxis: {
       //gridlineColor: '#000000',
      //  gridlines: {
      //      color: 'transparent'
      //  },
       format:'0',
      //  //format: '\u00A3',
       //format: '\u00A3###M',
       textStyle: {
         fontSize : 13,
         bold:'true',
         fontName:'IBM Plex Sans'
       }
   }
    };
    this.Chartwidth1 = 430;
    this.Chartheight1 = 300;
  });
}


  LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dashboard-contracts").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }

}
