import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-dashboard-contracts-new-contract',
  templateUrl: './hero-dashboard-contracts-new-contract.component.html',
  styleUrls: ['./hero-dashboard-contracts-new-contract.component.css']
})
export class HeroDashboardContractsNewContractComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
