import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-account',
  templateUrl: './hero-account.component.html',
  styleUrls: ['./hero-account.component.scss']
})
export class HeroAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
