import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-login-alert-dialog',
  templateUrl: './login-alert-dialog.component.html',
  styleUrls: ['./login-alert-dialog.component.css']
})
export class LoginAlertDialogComponent implements OnInit {
  Message:string;
  constructor(public dialogRef: MatDialogRef<LoginAlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {
    this.dialog.closeAll();
    this.Message=this.data.Message;
   }

  ngOnInit() {
  }

}
