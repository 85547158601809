import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InvitesuserService } from '../invitesuser.service';

@Component({
  selector: 'app-editprojectrisks',
  templateUrl: './editprojectrisks.component.html',
  styleUrls: ['./editprojectrisks.component.scss']
})
export class EditprojectrisksComponent implements OnInit {

  show:boolean=true;
  ProjectRisk : FormGroup;
  submitted:boolean=false;
  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    this.LoadProjectRiskList();
   }
  ProjectRiskList:any;
  ngOnInit() {
    this.ProjectRisk = this.fb.group({
      ProjectRiskDesc : ['',Validators.required],
     });
  }
  get f(){ return this.ProjectRisk.controls;}
  LoadProjectRiskList()
  {
    this.InvserObj.GetProjectRisks().subscribe((data:any) => 
     {
       this.ProjectRiskList=data;
     
     });

  }
  RemoveProjectRisk(RStatus:String)
  {
    for(let i=0;i<this.ProjectRiskList.length;i++)
    {
      if(this.ProjectRiskList[i].ProjectRiskDesc == RStatus)
      {
        this.ProjectRiskList.splice(i, 1);
      }
    }
  }
 
AddProjectRisk()
  {
    this.submitted = true;
    if (this.ProjectRisk.invalid) 
    {
       return ;
    }
    let statusexist=false;
    let PS=this.ProjectRisk.get("ProjectRiskDesc").value;
    for(let p=0;p<this.ProjectRiskList.length;p++)
    { 
      if(this.ProjectRiskList[p].ProjectRiskDesc == PS )
      {
        statusexist=true;
        break;
      }
    }
    if(statusexist == false)
    {
      let jsonstring= '{"ProjectRiskDesc" :"'+PS.trim()+'","Active" :0}';
      this.ProjectRiskList.push(JSON.parse(jsonstring));
      this.ProjectRisk.get("ProjectRiskDesc").setValue(" ");
    }
 }
SaveProjectRisk(event)
  {
    const formdecdata=new FormData();
  
    formdecdata.append('ContactID',sessionStorage.getItem("ContactID"));
    this.InvserObj.DeactiveProjectRisks(formdecdata).subscribe((data:any) => 
    {
    
  if(data.RetStatus = "Deleted")
  {
  
      if(this.ProjectRiskList.length>0)
      {
        for(let i=0;i <this.ProjectRiskList.length;i++)
          { 
            
            const formdata=new FormData();
            formdata.append('ProjectRiskDesc', this.ProjectRiskList[i].ProjectRiskDesc);
            formdata.append('Active', this.ProjectRiskList[i].Active);
            formdata.append('ContactID',sessionStorage.getItem("ContactID"));
            if(i==0)
            {
            formdata.append('Status','true');
            
            }
            else
            {
              formdata.append('Status','false');
            }
            this.InvserObj.AddProjectRisks(formdata).subscribe((data:any) => 
            {
              
              if(i== this.ProjectRiskList.length-1)
              {
                alert("Saved Successfully");
                  this.InvserObj.GetProjectRisks().subscribe((data:any) => 
                  {
                    this.ProjectRiskList=data;
                  
                  });
              }
            });
            
          }
        }
      }
   });
    
  }

}
