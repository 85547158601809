import { Component, OnInit,Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {DashboardContractDetailsService} from '../../dashboard/dashboard-contract-details/dashboard-contract-details.service'
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dialog-add-new-supplier',
  templateUrl: './dialog-add-new-supplier.component.html',
  styleUrls: ['./dialog-add-new-supplier.component.scss']
})
export class DialogAddNewSupplierComponent implements OnInit {
  AddTask:FormGroup;
  Suppliers : any;
  SupplierFile : any;
  submitted:boolean=false;
  name :any;
  Title : any ;
  showS : any =true;
  ButtonText : any;
  SupplierData : any ="";
  showR : boolean = true;
  constructor(private Dservice :DashboardContractDetailsService, private fb:FormBuilder,public dialogRef: MatDialogRef<DialogAddNewSupplierComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    
  }

  ngOnInit() {
    this.AddTask=this.fb.group({
      ddlSupplier:[''],
      SupplierName :[''],
      TaskStartDate:[''],
      FileUpload1 : [''],
      RegistrationNo : ['']
      
        });
        
        this.AddTask.get("ddlSupplier").setValue("new");
       // this.AddTask.get("SupplierName").disable();
        //this.Hideshow();
        if(this.data.SupplierID != null)
        {
            
            this.Title = "Upload Document";
            this.showS =false;
            this.AddTask.get("ddlSupplier").disable();
            this.Dservice.getAllSuppliersForOrganisation(this.data.ContractID).subscribe((data:any)=>
            {
                 this.Suppliers = data;
                 this.AddTask.get("ddlSupplier").setValue(this.data.SupplierID);
                 this.Dservice.getSupplierDetails(this.AddTask.get("ddlSupplier").value).subscribe((data:any)=>
            {
              console.log(data);
              this.AddTask.get("RegistrationNo").setValue(data.CompanyRegistrationNo);
            });
           });
            this.AddTask.get("SupplierName").disable();
            this.AddTask.get("RegistrationNo").disable();
            this.ButtonText = "Save";
        }
        else{
          //added on 04/02/2020
         if(this.data.ContractID == "dummy")
         {
          this.AddTask.get("TaskStartDate").disable();
          this.AddTask.get("FileUpload1").disable();
          this.ButtonText = "Save";
          this.showR = false;
          this.getAllSuppliers_Dummy();
         }
        else{
          this.Title = "Add New Supplier";
          this.ButtonText = "Add Supplier";
          this.showR = true;
          this.getAllSuppliers();
        }
        }
  }

  getAllSuppliers()
  {
    //alert(this.data.ContractID);
    this.Dservice.getSuppliersforOrganisation(this.data.ContractID).subscribe((data:any)=>
    {
       this.Suppliers = data;
      
    });
  
  }

  getAllSuppliers_Dummy()
  {
    this.Dservice.getSuppliersforOrganisation_Dummy(this.data.OrgID).subscribe((data:any)=>
    {
       this.Suppliers = data;
      
    });
  
  }OrgID

  Hideshow()
  {
      if(this.AddTask.get("ddlSupplier").value == "new")
      {
        this.AddTask.get("SupplierName").enable();
        this.AddTask.get("RegistrationNo").enable();
      }
      else{
        this.AddTask.get("SupplierName").disable();
        this.AddTask.get("RegistrationNo").disable();
        this.Dservice.getSupplierDetails(this.AddTask.get("ddlSupplier").value).subscribe((data:any)=>
    {
      console.log(data);
      this.AddTask.get("RegistrationNo").setValue(data.CompanyRegistrationNo);
    });
      }
  }

  onFileChange(event):void
  {
   if (event.target.files.length > 0) {
     const file = event.target.files[0];
    // this.UploadContract.get('Paper_FileName').setValue(file);
      this.SupplierFile = file;
      this.name =file.name;
   }
}

  AddSupplier()
  {
    this.submitted=true;
    if (!this.AddTask.valid) {
      return;
      }
      if(this.AddTask.get("ddlSupplier").value == "0")
      {
          alert("Please select supplier!");
          return;
      
      }
      if(this.AddTask.get("ddlSupplier").value == "new")
      {
            if(this.AddTask.get("SupplierName").value  =="")
            {
                alert("Please enter supplier name!");
                return;
            }
            else if(this.AddTask.get("RegistrationNo").value  =="")
            {
                alert("Please enter company registration number !");
                return;
            }

      }
      if(this.data.SupplierID != null)
        {
          
      if(this.SupplierFile == null || this.SupplierFile =="")
      {
          alert("Please choose file to upload !");
          return;
      
      }
    }

    if(this.data.ContractID == "dummy")
    {
      if(sessionStorage.getItem('FinancialdummySupplier') != null)
      {
        if(sessionStorage.getItem('FinancialdummySupplier') != 'null')
      {
          this.SupplierData = sessionStorage.getItem('FinancialdummySupplier');
      }
  
      }
      this.SupplierData += this.AddTask.get("ddlSupplier").value + ";" + this.AddTask.get("SupplierName").value + ";" + this.AddTask.get("RegistrationNo").value + "$";
      sessionStorage.setItem('FinancialdummySupplier',this.SupplierData);
      alert("Added Successfully");
     // alert(this.SupplierData);
       this.dialogRef.close();
    }
  else {
      var datepipe = new DatePipe('en-US');
    
      const formData=new FormData();
      if(this.data.SupplierID != null)
      {
        formData.append("Type", "Update");
      }
      formData.append("ContractID", this.data.ContractID);
      formData.append("SupplierID", this.AddTask.get("ddlSupplier").value);
      formData.append("SupplierName", this.AddTask.get("SupplierName").value);
      formData.append("CompanyRegistrationNo", this.AddTask.get("RegistrationNo").value);
      formData.append("ExpiryDate", datepipe.transform(this.AddTask.get("TaskStartDate").value,'MM/dd/yyyy'));
      formData.append('SupplierFile', this.SupplierFile);
      this.Dservice.SaveSupplierDocument(formData).subscribe((data:any) =>
    {
        //alert(data);
        if(data =="success")
        {
          //this.router.navigate(['dashboard/contracts']);
          alert("Updated Successfully !");
          this.AddTask.reset();
          this.name ="";
          this.dialogRef.close();
        }
        else{
             alert(data);
        
        }
    });
  }
  
  }

  
  
  get f() { return this.AddTask.controls; };

}
