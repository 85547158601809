import { Component, OnInit } from '@angular/core';
import {SettingsService} from '../../settings/settings.service';

@Component({
  selector: 'app-dashboard-contracts-report',
  templateUrl: './dashboard-contracts-report.component.html',
  styleUrls: ['./dashboard-contracts-report.component.scss']
})
export class DashboardContractsReportComponent implements OnInit {
  Names : any;
  constructor(private setService:SettingsService) { }

  ngOnInit() {
  }

  LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dashboard-contracts-report").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }

}
