import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProjectDatabaseService } from '../project-database.service';
import { DialogAdvancedSearchComponent } from '../../dialogs/dialog-advanced-search/dialog-advanced-search.component';
import { LoginService } from '../../login/login.service';
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
//import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
import { SelectionModel } from '@angular/cdk/collections';
import { AccountSetupWizardService } from 'src/app/account-setup-wizard/account-setup-wizard.service';
import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-dashboard-projects-database',
  templateUrl: './dashboard-projects-database.component.html',
  styleUrls: ['./dashboard-projects-database.component.css']
})
export class DashboardProjectsDatabaseComponent implements OnInit {
  // @ViewChild('editor',{static:true}) editor: GanttEditorComponent;
  // public editorOptions: GanttEditorOptions;
  // public data: any;

  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild(MatSort, { static: true }) sort: MatSort;

  UploadedProjects: boolean = false;
  NotLiveRecords: boolean = false;
  LiveRecords: boolean = true;
  public loading = false;
  closeResult: string;
  //displayedColumns: string[] = ['Organisation_Name', 'ProjectTitle', 'OwnerFirstName','StartDate','EndDate','ProjectStatus','ProjectPriority','ContractID','ContractTitle','Category','SubCategory','OwnerEmail','EstimatedTotalContractValue','EstimatedAnnualContractValue','Description'];    
  displayedColumns: string[] = ['Organisation_Name', 'ProjectTitle', 'OwnerFirstName', 'StartDate', 'EndDate', 'ProjectStatus', 'ProjectProgress', 'ProjectPriority', 'ProjectRisk', 'ProjectSlippage', 'ContractID', 'ContractTitle', 'Category', 'EstimatedTotalContractValue', 'EstimatedAnnualContractValue'];
  displayedColumnsNotLive: string[] = ['Selection', 'Organisation_Name', 'ProjectTitle', 'OwnerFirstName', 'StartDate', 'EndDate', 'ProjectStatus', 'ProjectProgress', 'ProjectPriority', 'ProjectRisk', 'ProjectSlippage', 'ContractID', 'ContractTitle', 'Category', 'EstimatedTotalContractValue', 'EstimatedAnnualContractValue'];

  projectsDatabaseDataSource: any;
  projectsDatabaseNotLiveDataSource: any;

  //selection = new SelectionModel<any>(true, []);
  selection = new SelectionModel(true, []);

  router: Router;
  ProjectTitle: any;
  
  ShowWizardMenu: boolean = false;
  Names: any;

  @ViewChild('one', { read: MatSort, static: true }) sort: MatSort;
  @ViewChild('onepage', { read: MatPaginator, static: true }) paginator: MatPaginator;


  @ViewChild('publish', { read: MatSort, static: true }) publishDataSort: MatSort;
  @ViewChild('publishpage', { read: MatPaginator, static: true }) publishDataPaginator: MatPaginator;
  isLifeTime = false;

  constructor(_router: Router, private route: ActivatedRoute, private DbService: ProjectDatabaseService, public dialog: MatDialog,
    private lService: LoginService, private wizard: AccountSetupWizardService, private setService: SettingsService) {
    this.LoadAllCaptions();
    this.router = _router;

    if (sessionStorage.getItem("ContactID") != null) {
      this.WizardHideorShow();
      if (this.route.snapshot.queryParamMap.get('Year') != null) {
        this.loading = true;
        this.isLifeTime = false
        if (this.route.snapshot.queryParams['Ptype'] == "Lifetime") {
          this.isLifeTime = true;
          //this is for lifetime filter from performance dashboard
          if (this.route.snapshot.queryParamMap.get('OrganisationID') != "0") {
            if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
              //for cancel projects 
              if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
                this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for in-complete projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
                this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for completed projects
              else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
                this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              else {
                this.DbService.GetAllProjectsbyLifetime_Org_Owner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
            }
            else if (this.route.snapshot.queryParamMap.get('TeamID') != "0") {
              //for cancelled projects 
              if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
                this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for in-complete projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
                this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for completed projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
                this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              else {
                this.DbService.GetAllProjectsbyLifetime_Org_Team(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParamMap.get('TeamID')).subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
            }
            else {
              //for cancelled projects 
              if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
               
                this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for in completed projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
                this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for completed projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
                this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              } else {
                this.DbService.GetAllProjectsbyLifetime_Org(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParams['Year']).subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }

            }
          }
          else {
            if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
              //for cancelled  projects 
              if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
                this.DbService.GetAllCancelProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for in-complete  projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
                this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for completed projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
                this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              else {
                this.DbService.GetAllProjectsbyLifetime_Owner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
            }
            else {

              //for cancelled projects 
              if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
                this.DbService.GetAllCancelProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for in-complete projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
                this.DbService.GetAllInCompleteProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
              //for completed projects 
              else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
                this.DbService.GetAllCompletedProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              } else {
                this.DbService.GetAllProjectsbyLifetime(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['Year']).subscribe((data: any) => {
                  this.ProjectTitle = data;
                  this.projectsDatabaseDataSource = new MatTableDataSource(data);
                  this.projectsDatabaseDataSource.sort = this.sort;
                  this.projectsDatabaseDataSource.paginator = this.paginator;
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              }
            }
          }
          //
        }//In year -




        else if (this.route.snapshot.queryParamMap.get('type') != null) {
          this.DbService.GetPerformanceProjects(sessionStorage.getItem("ContactID"),
            this.route.snapshot.queryParamMap.get('OrganisationID'),
            this.route.snapshot.queryParamMap.get('Year'),
            this.route.snapshot.queryParamMap.get('TeamID'),
            this.route.snapshot.queryParamMap.get('Owner'),
            this.route.snapshot.queryParamMap.get('type'), this.route.snapshot.queryParamMap.get('performacetype')).subscribe((data: any) => {
              this.ProjectTitle = data;
              this.projectsDatabaseDataSource = new MatTableDataSource(data);
              this.projectsDatabaseDataSource.sort = this.sort;
              this.projectsDatabaseDataSource.paginator = this.paginator;
              this.loading = false;
            }, (error) => {
              this.loading = false;
            });

        }
        //added on 23/02/2020...this is for slippage
        else if (this.route.snapshot.queryParamMap.get('slippage') !== null) {
          if (this.route.snapshot.queryParamMap.get('OrganisationID') != "0") {
            if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
              this.DbService.GetAllProjectsbyFinanceYearSlippage_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;

                //this.projectsDatabaseNotLiveDataSource = this.paginator;

                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            else if (this.route.snapshot.queryParamMap.get('TeamID') != "0") {
              this.DbService.GetAllProjectsbyFinanceYearSlippage_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Statustype')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });

            }
            else {
              this.DbService.GetAllProjectsbyFinanceYearSlippage_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
          }
          else {
            if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
              this.DbService.GetAllProjectsbyFinanceYearSlippage_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            else {
              this.DbService.GetAllProjectsbyFinanceYearSlippage(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }

          }

        }

        //27/01/2020 vikas: completed project status deatils
        else if (this.route.snapshot.queryParamMap.get('ProjCancelStatus') !== null) {
          this.DbService.GetProjectCancelStatus(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
            this.ProjectTitle = data;
            this.projectsDatabaseDataSource = new MatTableDataSource(data);
            this.projectsDatabaseDataSource.sort = this.sort;
            this.projectsDatabaseDataSource.paginator = this.paginator;
            this.loading = false;
          }, (error) => {
            this.loading = false;
          });
        }
        //25/01/2020 vikas: completed project status deatils
        else if (this.route.snapshot.queryParamMap.get('ProjInCompleteStatus') !== null) {
          this.DbService.GetProjectInCompletedStatus(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('ProjInCompleteStatus'), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
            this.ProjectTitle = data;
            this.projectsDatabaseDataSource = new MatTableDataSource(data);
            this.projectsDatabaseDataSource.sort = this.sort;
            this.projectsDatabaseDataSource.paginator = this.paginator;
            this.loading = false;
          }, (error) => {
            this.loading = false;
          });
        }
        else if (this.route.snapshot.queryParamMap.get('ProjCompleteStatus') !== null) {
          this.DbService.GetProjectCompletedStatus(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('ProjCompleteStatus'), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
            this.ProjectTitle = data;
            this.projectsDatabaseDataSource = new MatTableDataSource(data);
            this.projectsDatabaseDataSource.sort = this.sort;
            this.projectsDatabaseDataSource.paginator = this.paginator;
            this.loading = false;
          }, (error) => {
            this.loading = false;
          });
        }

        //else if (this.route.snapshot.queryParams['Ptype'] == "Lifetime") {
        //  //this is for lifetime filter from performance dashboard
        //  if (this.route.snapshot.queryParamMap.get('OrganisationID') != "0") {
        //    if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
        //      //for cancel projects 
        //      if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
        //        this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for in-complete projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
        //        this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for completed projects
        //      else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
        //        this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      else {
        //        this.DbService.GetAllProjectsbyLifetime_Org_Owner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //    }
        //    else if (this.route.snapshot.queryParamMap.get('TeamID') != "0") {
        //      //for cancelled projects 
        //      if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
        //        this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for in-complete projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
        //        this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for completed projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
        //        this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      else {
        //        this.DbService.GetAllProjectsbyLifetime_Org_Team(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParamMap.get('TeamID')).subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //    }
        //    else {
        //      //for cancelled projects 
        //      if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
        //        this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for in completed projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
        //        this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for completed projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
        //        this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      } else {
        //        this.DbService.GetAllProjectsbyLifetime_Org(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParams['Year']).subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }

        //    }
        //  }
        //  else {
        //    if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
        //      //for cancelled  projects 
        //      if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
        //        this.DbService.GetAllCancelProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for in-complete  projects 
        //      if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
        //        this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for completed projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
        //        this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      else {
        //        this.DbService.GetAllProjectsbyLifetime_Owner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //    }
        //    else {

        //      //for cancelled projects 
        //      if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
        //        this.DbService.GetAllCancelProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for in-complete projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
        //        this.DbService.GetAllInCompleteProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //      //for completed projects 
        //      else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
        //        this.DbService.GetAllCompletedProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype'), 'lifetime').subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      } else {
        //        this.DbService.GetAllProjectsbyLifetime(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['Year']).subscribe((data: any) => {
        //          this.ProjectTitle = data;
        //          this.projectsDatabaseDataSource = new MatTableDataSource(data);
        //          this.projectsDatabaseDataSource.sort = this.sort;
        //          this.projectsDatabaseDataSource.paginator = this.paginator;
        //          this.loading = false;
        //        }, (error) => {
        //          this.loading = false;
        //        });
        //      }
        //    }
        //  }
        //  //
        //}//In year -



        else if (this.route.snapshot.queryParamMap.get('OrganisationID') != "0") {
          if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
            //for cancel projects 
            if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
              this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for in-complete projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
              this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
              this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrgOwner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            else {
              this.DbService.GetAllProjectsbyFinanceYear_Org_Owner(this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
          }
          else if (this.route.snapshot.queryParamMap.get('TeamID') != "0") {
            //for cancelled projects 
            if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
              this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for in-complete projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
              this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for completed projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
              this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrgTeam(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID'), this.route.snapshot.queryParamMap.get('Statustype'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            } else {
              this.DbService.GetAllProjectsbyFinanceYear_Org_Team(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('TeamID')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
          }
          else {
            //for cancelled projects 
            if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
              this.DbService.GetAllCancelProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for in completed projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
              this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for completed projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
              this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOrg(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            } else {
              this.DbService.GetAllProjectsbyFinanceYear_Org(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('OrganisationID'), this.route.snapshot.queryParamMap.get('Year')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
          }
        }
        else {
          if (this.route.snapshot.queryParamMap.get('Owner') != "0") {
            let isCall = false;
            //for cancelled  projects 
            if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
              isCall = true;
              this.DbService.GetAllCancelProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for in-complete  projects 
            if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
              isCall = true;
              this.DbService.GetAllInCompleteProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for completed projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
              isCall = true;
              this.DbService.GetAllCompletedProjectsbyFinanceYear_ByOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner'), this.route.snapshot.queryParamMap.get('Statustype'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            else if (!isCall) {
              this.DbService.GetAllProjectsbyFinanceYear_Owner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Owner')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
          }

          else {
            //for in-complete projects 
            if (this.route.snapshot.queryParamMap.get('Statustype_C') != null) {
              this.DbService.GetAllCancelProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for in-complete projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype_I') != null) {
              this.DbService.GetAllInCompleteProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype_I'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            //for completed projects 
            else if (this.route.snapshot.queryParamMap.get('Statustype') != null) {
              this.DbService.GetAllCompletedProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year'), this.route.snapshot.queryParamMap.get('Statustype'), 'inyear').subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
            else {
              this.DbService.GetAllProjectsbyFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('Year')).subscribe((data: any) => {
                this.ProjectTitle = data;
                this.projectsDatabaseDataSource = new MatTableDataSource(data);
                this.projectsDatabaseDataSource.sort = this.sort;
                this.projectsDatabaseDataSource.paginator = this.paginator;
                this.loading = false;
              }, (error) => {
                this.loading = false;
              });
            }
          }
        }
      }
      else if (this.route.snapshot.queryParamMap.get('ShowUnpublished') != null) {
        this.getNonPublishedProjects();
        this.PublishProjects();
      }
      else {
        // if(this.route.snapshot.queryParamMap.get('FilterBy') !=null)
        // {
        //   this.getProjectbyFilter(sessionStorage.getItem("ContactID"),this.route.snapshot.queryParamMap.get('FilterBy'));
        // }
        if (this.route.snapshot.queryParamMap.get('FilterBy') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('OwnerName') != null) {
          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getAllProjectsbyContactID_Status_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterBy'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
          else {
            this.getAllProjectsbyContactID_Status_OrgID_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterBy'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
        }
        else if (this.route.snapshot.queryParamMap.get('FilterByRisk') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('OwnerName') != null) {
          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getProjectsByFilter_Risk_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterByRisk'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
          else {
            this.getProjectsByFilter_Risk_OrgID_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterByRisk'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
        }
        else if (this.route.snapshot.queryParamMap.get('FilterbyCategory') != null && this.route.snapshot.queryParamMap.get('RiskType') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('OwnerName') != null) {
          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getProjectsByFilter_Category_Risk_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyCategory'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
          else {
            this.getProjectsByFilter_Category_Risk_OrgID_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyCategory'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
        }
        else if (this.route.snapshot.queryParamMap.get('FilterbyPriority') != null && this.route.snapshot.queryParamMap.get('RiskType') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('OwnerName') != null) {
          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getProjectsByFilter_Prority_Risk_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyPriority'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
          else {
            this.getProjectsByFilter_Prority_Risk_OrgID_ProjectOwner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyPriority'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('OwnerName'));
          }
        }
        else if (this.route.snapshot.queryParamMap.get('FilterBy') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('TeamID') != null) {
          this.getAllProjectsbyContactID_Status_OrgID_TeamID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterBy'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('TeamID'));
        }
        else if (this.route.snapshot.queryParamMap.get('FilterByRisk') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('TeamID') != null) {
          this.getProjectsByFilter_Risk_OrgID_TeamID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterByRisk'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('TeamID'));
        }
        else if (this.route.snapshot.queryParamMap.get('FilterbyCategory') != null && this.route.snapshot.queryParamMap.get('RiskType') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('TeamID') != null) {
          this.getProjectsByFilter_CategoryandRiskandOrgIDandTeamID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyCategory'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('TeamID'));
        }
        else if (this.route.snapshot.queryParamMap.get('FilterbyPriority') != null && this.route.snapshot.queryParamMap.get('RiskType') != null && this.route.snapshot.queryParamMap.get('OrgID') != null && this.route.snapshot.queryParamMap.get('TeamID') != null) {
          this.getProjectsByFilter_ProrityandRiskandOrgIDandTeamID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyPriority'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OrgID'), this.route.snapshot.queryParamMap.get('TeamID'));
        }
        else if (this.route.snapshot.queryParamMap.get('FilterBy') != null && this.route.snapshot.queryParamMap.get('OrgID') != null) {
          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getProjectbyFilter(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterBy'));
          }
          else {
            this.getAllProjectsbyContactID_Status_OrgID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterBy'), this.route.snapshot.queryParamMap.get('OrgID'));
          }

        }
        else if (this.route.snapshot.queryParamMap.get('FilterByRisk') != null && this.route.snapshot.queryParamMap.get('OrgID') != null) {
          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getProjectsByRisk(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterByRisk'));
          }
          else {
            this.getProjectsByFilter_Risk_OrgID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterByRisk'), this.route.snapshot.queryParamMap.get('OrgID'));
          }
        }
        else if (this.route.snapshot.queryParamMap.get('FilterbyCategory') != null && this.route.snapshot.queryParamMap.get('RiskType') != null && this.route.snapshot.queryParamMap.get('OrgID') != null) {
          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getProjectsbyCategory(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyCategory'), this.route.snapshot.queryParamMap.get('RiskType'));
          }
          else {
            this.getProjectsByFilter_CategoryandRiskandOrgID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyCategory'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OrgID'));
          }

        }
        else if (this.route.snapshot.queryParamMap.get('FilterbyPriority') != null && this.route.snapshot.queryParamMap.get('RiskType') != null && this.route.snapshot.queryParamMap.get('OrgID') != null) {

          if (this.route.snapshot.queryParamMap.get('OrgID') == "0") {
            this.getProjectsByPriority(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyPriority'), this.route.snapshot.queryParamMap.get('RiskType'));
          }
          else {
            this.getProjectsByFilter_ProrityandRiskandOrgID(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParamMap.get('FilterbyPriority'), this.route.snapshot.queryParamMap.get('RiskType'), this.route.snapshot.queryParamMap.get('OrgID'));
          }
        }
        else {
          this.getAllProjects(sessionStorage.getItem("ContactID"));
        }     
        this.getNonPublishedProjects();
      }


    }
    else {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    // this.data = this.initialData();
    // this.editorOptions = {
    //   vFormat: 'day'
    // }
  }

  openDialogAdvancedSearch() {
    const dialogRef = this.dialog.open(DialogAdvancedSearchComponent, {
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (sessionStorage.getItem("Item1") != null) {

        let item = JSON.parse(sessionStorage.getItem("Item1"));
        this.projectsDatabaseDataSource = new MatTableDataSource(item);
        this.projectsDatabaseDataSource.sort = this.sort;
        this.projectsDatabaseDataSource.paginator = this.paginator;
      }

    });
  }

  showProjectDetails(row) {
    //Is it a temporary project ?
    this.DbService.IsTemporaryProject(row.ProjectID, row.ContractID).subscribe((data: any) => {
      if (data.Status == "True") {
        //Awaiting a PO To accept
        if (data.StatusID == 15 && sessionStorage.getItem("ContactID") == data.POID) {
          if (data.Type == "Reprocure")
            this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: row.ContractID, Reprocure: "true", TMID: data.TMID } });
          else
            if (data.Type == "Extend")
              this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: row.ContractID, Extend: "true", TMID: data.TMID } });

        }
        else
          //Awaiting a TM to accept the PO
          if (data.StatusID == 16 && sessionStorage.getItem("usertype") == "Team Manager")
            this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: row.ContractID, For: data.Type, POID: data.POID } });
          else
            alert("You do not have permission to accept / reject this project");
      }
      else
        //Just navigate as normal
        this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: row.ProjectID } });
    });
  }
  showNotPublishedProjectDetails(row) {
    this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: row.ProjectID, ProjectsFor: "UnPublished" } });
  }
  getNonPublishedProjects() {
    this.lService.getContactByEmail(sessionStorage.getItem("userMail")).subscribe((data: any) => {
      if (data.ContractConfig == "No") {
        this.UploadedProjects = false;
      }
      else {
        this.DbService.GetNonPublishedProjects(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
          if (data.length > 0) {
            this.UploadedProjects = true;
          }
          else {
            this.UploadedProjects = false;
          }
        });
      }
    });
  }
  getAllProjects(ContactID: any) {
    this.loading = true;
    if (sessionStorage.getItem("usertype") == "Head of Procurement") {
      this.DbService.getAllProjectsbyContactID(ContactID).subscribe((data: any) => {
        this.ProjectTitle = data;
        this.projectsDatabaseDataSource = new MatTableDataSource(data);
        this.projectsDatabaseDataSource.sort = this.sort;
        this.projectsDatabaseDataSource.paginator = this.paginator;
        this.loading = false;
      }, (error) => {
        this.loading = false;
      });
    }
    else {
      this.DbService.GetProjectbyContactIDandUserType(ContactID, sessionStorage.getItem("usertype")).subscribe((data: any) => {
        this.ProjectTitle = data;
        this.projectsDatabaseDataSource = new MatTableDataSource(data);
        this.projectsDatabaseDataSource.sort = this.sort;
        this.projectsDatabaseDataSource.paginator = this.paginator;
        this.loading = false;
      }, (error) => {
        this.loading = false;
      });
      // this.DbService.GetProjectsForNonHOPbyContactID(ContactID).subscribe((data:any) =>
      // {
      //   this.ProjectTitle=data;
      //   this.projectsDatabaseDataSource= new MatTableDataSource(data);
      //   this.projectsDatabaseDataSource.sort = this.sort;
      //   this.loading=false;
      // },(error) =>{
      //   this.loading=false;
      // });
    }

    // this.DbService.getAllProjects().subscribe((data:any) =>
    // {
    //     this.ProjectTitle=data;
    //     this.projectsDatabaseDataSource= new MatTableDataSource(data);
    //     this.projectsDatabaseDataSource.sort = this.sort;
    // });
  }
  getProjectbyFilter(ContactID: any, Filter: string) {
    this.DbService.GetAllProjectsbyContactID_Status(ContactID, Filter).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  BindProjectList(ProjectID: any) {
    if (ProjectID != 0) {
      this.DbService.getProjectbyIDForDatabase(ProjectID).subscribe((Prjdata: any) => {
        this.projectsDatabaseDataSource = new MatTableDataSource(Prjdata);
        this.projectsDatabaseDataSource.sort = this.sort;
        this.projectsDatabaseDataSource.paginator = this.paginator;
      });
    }
    else {
      this.getAllProjects(sessionStorage.getItem("ContactID"));
      // this.DbService.getAllProjectsbyContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
      // {
      //     this.ProjectTitle=data;
      //     this.projectsDatabaseDataSource= new MatTableDataSource(data);
      //     this.projectsDatabaseDataSource.sort = this.sort;
      // });
    }

  }

  searchText(title: string) {
    if (title != "") {
      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        const formdata = new FormData();
        formdata.append("SearchText", title);
        formdata.append("LoggedUserID", sessionStorage.getItem("ContactID"));
        this.DbService.SearchProjectsbyTitleFor_HOP(formdata).subscribe((data: any) =>
        //this.DbService.SearchProjectsbyContactID(title, sessionStorage.getItem("ContactID")).subscribe((data:any) =>
        {

          if (data.length > 0) {
            this.projectsDatabaseDataSource = new MatTableDataSource(data);
            this.projectsDatabaseDataSource.sort = this.sort;
            this.projectsDatabaseDataSource.paginator = this.paginator;
          }
          else {
            alert("No Data found for the search !");
            this.projectsDatabaseDataSource = new MatTableDataSource(data);
            this.projectsDatabaseDataSource.sort = this.sort;
            this.projectsDatabaseDataSource.paginator = this.paginator;
          }


        });
      }
      else {
        const formdata = new FormData();
        formdata.append("SearchText", title);
        formdata.append("LoggedUserID", sessionStorage.getItem("ContactID"));
        formdata.append("UserType", sessionStorage.getItem("usertype"));
        this.DbService.SearchProjectbyTitlefor_NonHOP(formdata).subscribe((data: any) =>
        //this.DbService.GetProjectbyContactIDandUserTypewithSearchText(sessionStorage.getItem("ContactID"),sessionStorage.getItem("usertype"),title).subscribe((data:any) =>
        {
          if (data.length > 0) {
            this.projectsDatabaseDataSource = new MatTableDataSource(data);
            this.projectsDatabaseDataSource.sort = this.sort;
            this.projectsDatabaseDataSource.paginator = this.paginator;
          }
          else {
            alert("No Data found for the search !");
            this.projectsDatabaseDataSource = new MatTableDataSource(data);
            this.projectsDatabaseDataSource.sort = this.sort;
            this.projectsDatabaseDataSource.paginator = this.paginator;
          }
        });
        // this.DbService.SearchProjectsForNonHOPbyContactID(title, sessionStorage.getItem("ContactID")).subscribe((data:any) =>
        // {

        //   if(data.length > 0)
        //   {
        //   this.projectsDatabaseDataSource= new MatTableDataSource(data);
        //   this.projectsDatabaseDataSource.sort = this.sort;
        //   }
        //   else
        //   {
        //       alert("No Data found for the search !");
        //       this.projectsDatabaseDataSource= new MatTableDataSource(data);
        //       this.projectsDatabaseDataSource.sort = this.sort;
        //   }


        // });
      }
    }
    else {
      //alert("Please enter some search text !");
      this.getAllProjects(sessionStorage.getItem("ContactID"));
    }
  }

  getProjectsByRisk(ContactID: any, RiskType: string) {
    this.DbService.GetProjectsByFilter_Risk(ContactID, RiskType).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getProjectsByPriority(ContactID: any, Priority: string, RiskType: string) {
    this.DbService.GetProjectsByFilter_ProrityandRisk(ContactID, Priority, RiskType).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsbyCategory(ContactID: any, Cat: string, RiskType: string) {
    this.DbService.GetProjectsByFilter_CategoryandRisk(ContactID, Cat, RiskType).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getAllProjectsbyContactID_Status_OrgID(ContactID: any, pStatus: string, OrgID: any) {
    this.DbService.GetAllProjectsbyContactID_Status_OrgID(ContactID, pStatus, OrgID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getProjectsByFilter_Risk_OrgID(ContactID: any, RiskType: string, OrgID: any) {
    this.DbService.GetProjectsByFilter_Risk_OrgID(ContactID, RiskType, OrgID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getProjectsByFilter_ProrityandRiskandOrgID(ContactID: any, Priority: string, RiskType: string, OrgID: any) {
    this.DbService.GetProjectsByFilter_ProrityandRiskandOrgID(ContactID, Priority, RiskType, OrgID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_CategoryandRiskandOrgID(ContactID: any, Cat: string, RiskType: string, OrgID: any) {
    this.DbService.GetProjectsByFilter_CategoryandRiskandOrgID(ContactID, Cat, RiskType, OrgID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_Risk_OrgID_TeamID(ContactID: any, RiskType: string, OrgID: any, TeamID: any) {
    this.DbService.GetProjectsByFilter_Risk_OrgID_TeamID(ContactID, RiskType, OrgID, TeamID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getProjectsByFilter_ProrityandRiskandOrgIDandTeamID(ContactID: any, Priority: string, RiskType: string, OrgID: any, TeamID: any) {
    this.DbService.GetProjectsByFilter_ProrityandRiskandOrgIDandTeamID(ContactID, Priority, RiskType, OrgID, TeamID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getProjectsByFilter_CategoryandRiskandOrgIDandTeamID(ContactID: any, Cat: string, RiskType: string, OrgID: any, TeamID: any) {
    this.DbService.GetProjectsByFilter_CategoryandRiskandOrgIDandTeamID(ContactID, Cat, RiskType, OrgID, TeamID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getAllProjectsbyContactID_Status_OrgID_TeamID(ContactID: any, pStatus: string, OrgID: any, TeamID: any) {
    this.DbService.GetAllProjectsbyContactID_Status_OrgID_TeamID(ContactID, pStatus, OrgID, TeamID).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getAllProjectsbyContactID_Status_ProjectOwner(ContactID: any, pStatus: string, ProjectOwner: string) {
    this.DbService.GetAllProjectsbyContactID_Status_ProjectOwner(ContactID, pStatus, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getAllProjectsbyContactID_Status_OrgID_ProjectOwner(ContactID: any, pStatus: string, OrgID: any, ProjectOwner: string) {
    this.DbService.GetAllProjectsbyContactID_Status_OrgID_ProjectOwner(ContactID, pStatus, OrgID, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_Risk_ProjectOwner(ContactID: any, RiskType: string, ProjectOwner: string) {
    this.DbService.GetProjectsByFilter_Risk_ProjectOwner(ContactID, RiskType, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_Risk_OrgID_ProjectOwner(ContactID: any, RiskType: string, OrgID: any, ProjectOwner: string) {
    this.DbService.GetProjectsByFilter_Risk_OrgID_ProjectOwner(ContactID, RiskType, OrgID, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_Category_Risk_ProjectOwner(ContactID: any, Cat: string, RiskType: string, ProjectOwner: string) {
    this.DbService.GetProjectsByFilter_Category_Risk_ProjectOwner(ContactID, Cat, RiskType, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_Category_Risk_OrgID_ProjectOwner(ContactID: any, Cat: string, RiskType: string, OrgID: any, ProjectOwner: string) {
    this.DbService.GetProjectsByFilter_Category_Risk_OrgID_ProjectOwner(ContactID, Cat, RiskType, OrgID, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_Prority_Risk_ProjectOwner(ContactID: any, Priority: string, RiskType: string, ProjectOwner: string) {
    this.DbService.GetProjectsByFilter_Prority_Risk_ProjectOwner(ContactID, Priority, RiskType, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }
  getProjectsByFilter_Prority_Risk_OrgID_ProjectOwner(ContactID: any, Priority: string, RiskType: string, OrgID: any, ProjectOwner: string) {
    this.DbService.GetProjectsByFilter_Prority_Risk_OrgID_ProjectOwner(ContactID, Priority, RiskType, OrgID, ProjectOwner).subscribe((data: any) => {
      this.ProjectTitle = data;
      this.projectsDatabaseDataSource = new MatTableDataSource(data);
      this.projectsDatabaseDataSource.sort = this.sort;
      this.projectsDatabaseDataSource.paginator = this.paginator;
    });
  }

  PublishProjects() {
    this.LiveRecords = false;
    this.NotLiveRecords = true;
    this.loading = true;
    this.DbService.GetNonPublishedProjects(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

      this.projectsDatabaseNotLiveDataSource = new MatTableDataSource(data);
      this.selection = new SelectionModel(data, []);
      this.projectsDatabaseNotLiveDataSource.sort = this.publishDataSort;
      this.projectsDatabaseNotLiveDataSource.paginator = this.publishDataPaginator;
      this.loading = false;

    }, (error) => {
      this.loading = false;
    });
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.projectsDatabaseNotLiveDataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.projectsDatabaseNotLiveDataSource.data.forEach(row => this.selection.select(row));
  }
  // open(content) {
  //   this.modalService.open(content, {size: 'xl'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return  `with: ${reason}`;
  //   }
  // }
  PublishUploadedData() {
    console.log(this.selection.selected);
    if (this.selection.selected.length > 0) {
      let Error: number = 0;
      for (let i = 0; i < this.selection.selected.length; i++) {
        //console.log(this.selection.selected[i].ProjectID);
        //this.DbService.UpdateProjectFlag()
        this.DbService.UpdateProjectFlag(this.selection.selected[i].ProjectID, "Publish").subscribe((data: any) => {
          console.log(data.value);
          if (data.value != "Updated") {
            Error += 1;
          };
        });
      }
      if (Error == 0) {

        alert("Published Successfully");
      }
      else {
        alert("Published with errors");
      }
      this.DbService.GetNonPublishedProjects(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

        this.projectsDatabaseNotLiveDataSource = new MatTableDataSource(data);
        this.selection = new SelectionModel(data, []);
        this.projectsDatabaseNotLiveDataSource.sort = this.publishDataSort;;
        this.projectsDatabaseNotLiveDataSource.paginator = this.publishDataPaginator;
        if (data.length > 0) {
          this.UploadedProjects = true;
        }
        else {
          this.UploadedProjects = false;
         
        }
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['dashboard/projects/database']);
      });
    }
    else {
      alert("Please select atleast one record to publish");
    }

  }
  DeleteUploadedData() {
    //console.log(this.selection.selected);
    if (this.selection.selected.length > 0) {
      let Error: number = 0;
      for (let i = 0; i < this.selection.selected.length; i++) {
        this.DbService.DeleteUploadedProject(this.selection.selected[i].ProjectID).subscribe((data: any) => {
          if (data.value != "Deleted") {
            Error += 1;
          }
        });
        // this.DbService.UpdateProjectFlag(this.selection.selected[i].ProjectID,"Delete").subscribe((data:any) =>
        // {
        //   if(data.value !="Updated")
        //   {
        //     Error+=1;
        //   };
        // });
      }
      if (Error == 0) {

        alert("Deleted successfully");
      }
      else {
        alert("Deleted with errors");
      }
      this.DbService.GetNonPublishedProjects(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

        this.projectsDatabaseNotLiveDataSource = new MatTableDataSource(data);
        this.selection = new SelectionModel(data, []);
        this.projectsDatabaseNotLiveDataSource.sort = this.sort;
        this.projectsDatabaseNotLiveDataSource.paginator = this.paginator;
        if (data.length > 0) {
          this.UploadedProjects = true;
        }
        else {
          this.UploadedProjects = false;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/projects/database']);
        }

      });
    }
    else {
      alert("Please select atleast one record to delete");
    }

  }

  WizardHideorShow() {
    this.wizard.getWizardCheckListByContactID(Number(sessionStorage.getItem("ContactID")), sessionStorage.getItem("usertype")).subscribe((data: any) => {
      if (data.length == data.filter(x => x.WizardCheckList_Viewed == 1).length) {
        this.ShowWizardMenu = false;
      }
      else {
        this.ShowWizardMenu = true;
      }
    });
  }

  LoadAllCaptions() {
    this.setService.getCaptionFromDB("dashboard-projects-database").subscribe((data: any) => {
      this.Names = data;
    });

  }


  getCaption(sCaption: string) {
    var sResult = sCaption;
    if (this.Names != null) {
      this.Names.forEach(element => {
        if (element.OriginalName === sCaption) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }


}
