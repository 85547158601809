import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddNewTaskComponent } from 'src/app/dialogs/dialog-add-new-task/dialog-add-new-task.component';
import {SettingsService} from '../../settings/settings.service';


export interface ProjectPhaseDetails {
  taskDescription: string;
  startDate: Date;
  endDate: Date;
  action: string;
  taskComplete: boolean;
}

export interface ProjectedLifetimeSpendAndSavings {
  dateCreated: Date;
  dateSubmitted: Date;
  projectedLifetimeSpend: number;
  projectedLifetimeSavings: number;
}

export interface contractStartDate {
  dateCreated: Date;
  dateSubmitted: Date;
  contractStartDate: Date;
}

export interface projectBaseline {
  projectStage: string;
  startDate: Date;
  endDate: Date;
  numberOfDays: number;
}

export interface projectBaselineAdditionalInfo {
  dateCreated: Date;
  dateApproved: Date;
  dateContractStart: Date;
}

export interface projectFinancials {
  particulars: string;
  value: number;    
}

const PHASE1_DETAILS: ProjectPhaseDetails[] = [
  {
      taskDescription: 'This is the name of a task',
      startDate: new Date('2019/Jul/14'),
      endDate: new Date('2020/Jul/14'),
      action: 'Edit Task',
      taskComplete: true
  },
  {
      taskDescription: 'This is a different task',
      startDate: new Date('2019/Jul/14'),
      endDate: new Date('2020/Jul/14'),
      action: 'Edit Task',
      taskComplete: false
  },
  {
      taskDescription: 'This is the name of a task',
      startDate: new Date('2019/Jul/14'),
      endDate: new Date('2020/Jul/14'),
      action: 'Edit Task',
      taskComplete: true
  }
];

const PROJECTED_LIFETIME_SPEND_AND_SAVINGS_DATA: ProjectedLifetimeSpendAndSavings[] = [
  {
      dateCreated: new Date('2019/Sep/23'),
      dateSubmitted: new Date('2019/Sep/23'),
      projectedLifetimeSpend: 20000000,
      projectedLifetimeSavings: 20000000
  }
];

const CONTRACT_START_DATE_DATA: contractStartDate[] = [
  {
      dateCreated: new Date('2019/Sep/23'),
      dateSubmitted: new Date('2019/Sep/23'),
      contractStartDate: new Date('2019/Sep/23')
  }
];

const PROJECT_BASELINE_CURRENT_DATA: projectBaseline[] = [
  {
      projectStage: 'Project Timetable',
      startDate: new Date('2019/Sep/01'),
      endDate: new Date('2020/Apr/01'),
      numberOfDays: 213
  },
  {
      projectStage: 'Phase 1 - Initiation',
      startDate: new Date('2019/Sep/01'),
      endDate: new Date('2019/Sep/15'),
      numberOfDays: 14
  },
  {
      projectStage: 'Phase 2 - Planning',
      startDate: new Date('2019/Sep/15'),
      endDate: new Date('2019/Oct/20'),
      numberOfDays: 35
  },
  {
      projectStage: 'Phase 3 - Execution',
      startDate: new Date('2019/Oct/20'),
      endDate: new Date('2019/Dec/01'),
      numberOfDays: 42
  },
  {
      projectStage: 'Phase 4 - Closure',
      startDate: new Date('2019/Dec/01'),
      endDate: new Date('2020/Apr/01'),
      numberOfDays: 122
  }
];

const PROJECT_BASELINE_ADDITIONAL_INFO_CURRENT_DATA: projectBaselineAdditionalInfo = {
  dateCreated: new Date('2019/Dec/12'),
  dateApproved: new Date('2019/Dec/15'),
  dateContractStart: new Date('2020/06/01')
};

const PROJECT_FINANCIALS_CURRENT_CONTRACT_VALUE: projectFinancials[] = [
  {
      particulars: 'Financial Year 20/21',
      value: 100000
  },
  {
      particulars: 'Financial Year 21/22',
      value: 100000
  },
  {
      particulars: 'Total',
      value: 200000
  }
];

const PROJECT_FINANCIALS_CURRENT_CONTRACT_SAVINGS: projectFinancials[] = [
  {
      particulars: 'Financial Year 20/21',
      value: 25000
  },
  {
      particulars: 'Financial Year 21/22',
      value: 25000
  },
  {
      particulars: 'Total',
      value: 50000
  }
];

const PROJECT_FINANCIALS_CURRENT_TOTAL: number = 100000;

@Component({
  selector: 'app-dashboard-projects-report',
  templateUrl: './dashboard-projects-report.component.html',
  styleUrls: ['./dashboard-projects-report.component.scss']
})
export class DashboardProjectsReportComponent implements OnInit {

  startDatePhase1 = new FormControl(new Date('2019/Jul/14'));
    endDatePhase1 = new FormControl(new Date('2020/Jul/14'));
    startDatePhase2 = new FormControl(new Date('2019/Jul/14'));
    endDatePhase2 = new FormControl(new Date('2020/Jul/14'));
    startDatePhase3 = new FormControl(new Date('2019/Jul/14'));
    endDatePhase3 = new FormControl(new Date('2020/Jul/14'));
    startDatePhase4 = new FormControl(new Date('2019/Jul/14'));
    endDatePhase4 = new FormControl(new Date('2020/Jul/14'));
    phase1DisplayedColumns = ['taskDescription', 'startDate', 'endDate', 'action', 'status'];
    phase1DataSource = new MatTableDataSource(PHASE1_DETAILS);
    projectedLifetimeSpendAndSavingsDisplayedColumns = ['dateCreated', 'dateSubmitted', 'projectedLifetimeSpend', 'projectedLifetimeSavings'];
    projectedLifetimeSpendAndSavingsDataSource = new MatTableDataSource(PROJECTED_LIFETIME_SPEND_AND_SAVINGS_DATA);
    contractStartDateDisplayedColumns = ['dateCreated', 'dateSubmitted', 'contractStartDate'];
    contractStartDateDataSource = new MatTableDataSource(CONTRACT_START_DATE_DATA);
    
    projectBaselineCurrentDisplayedColumns = ['projectStage', 'startDate', 'endDate', 'numberOfDays'];
    projectBaselineCurrentDataSource = new MatTableDataSource(PROJECT_BASELINE_CURRENT_DATA);
    projectBaselineAdditionalInfoCurrent = PROJECT_BASELINE_ADDITIONAL_INFO_CURRENT_DATA;

    projectFinancialsCurrentContractValueDisplayedColumns = ['particulars', 'value'];
    projectFinancialsCurrentContractValueDataSource = new MatTableDataSource(PROJECT_FINANCIALS_CURRENT_CONTRACT_VALUE);
    projectFinancialsCurrentContractSavingsDisplayedColumns = ['particulars', 'value'];
    projectFinancialsCurrentContractSavingsDataSource = new MatTableDataSource(PROJECT_FINANCIALS_CURRENT_CONTRACT_SAVINGS);
    projectFinancialsCurrentTotal = PROJECT_FINANCIALS_CURRENT_TOTAL;
  Names : any;
  ProjectMSList : any;
  constructor(public dialog: MatDialog, private setService:SettingsService) {this.LoadAllCaptions(); }

  ngOnInit() {
  }

  openDialogAddNewTask() {
    const dialogRef = this.dialog.open(DialogAddNewTaskComponent, {
        width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
    });
}

  LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dashboard-projects-report").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	this.setService.getProjectPhaseLabelFromDB().subscribe((data:any) =>
			{
			  this.ProjectMSList = data;
			});
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
  
  getProjectCaption (projectID : any)
  {
       var sResult = "";
	   if (this.ProjectMSList != null)
	   {
		   this.ProjectMSList.forEach(element => {
			if (element.Id === projectID) {
				sResult = element.ProjectPhase_Type;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }

}
