import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectDatabaseService } from '../../dashboard/project-database.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';
import { DashboardPerformanceService } from '../../dashboard/dashboard-performance/dashboard-performance.service';

export interface ProjectFinanaceData {
  ContractSpend: string;
  ContractSavings: string;
  FinanceYeardisplay: string;
  FinanceYear: string;
  ProjectFinanceID: number,
  ProjectSavings: string,
  ProjectSpend: string,
}

@Component({
  selector: 'app-dialog-add-new-projectfinancials',
  templateUrl: './dialog-add-new-projectfinancials.component.html',
  styleUrls: ['./dialog-add-new-projectfinancials.component.scss']
})
export class DialogAddNewProjectfinancialsComponent implements OnInit {
  AddProjectFinance: FormGroup;
  submitted: boolean = false;
  FinancSelectedText: string;
  FinancialYears: any;
  findata: any = "";
  Heading: any = "Save";
  HeaderTop: any = "Add New";
  FinanceData: ProjectFinanaceData[] = [];

  constructor(private fb: FormBuilder, private DbService: ProjectDatabaseService, public dialogRef: MatDialogRef<DialogAddNewProjectfinancialsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
    private Cservice: DashboardPerformanceService) { }

  ngOnInit() {
    this.AddProjectFinance = this.fb.group({
      FinancialYear: ['', Validators.required],
      ProjectedSpend: ['', Validators.required],
      ProjectedSavings: ['', Validators.required]
      //ReasonforChange:['',Validators.required]
    });
    this.GetFinancialYear();
  }

  get f() { return this.AddProjectFinance.controls };

  GetFinancialYear() {
    console.log("data", this.data);


    this.Cservice.GetFinancialYear().subscribe((data: any) => {
      this.FinancialYears = data.value;
      if (this.data.Ptype == "Edit") {

        // alert(this.data.Yeardisplay);
        this.AddProjectFinance.get("FinancialYear").setValue(this.data.Year);
        this.AddProjectFinance.get("ProjectedSpend").setValue(this.data.Spend);
        this.AddProjectFinance.get("ProjectedSavings").setValue(this.data.Savings);
        this.Heading = "Update";
        this.HeaderTop = "Edit";
        this.AddProjectFinance.get("FinancialYear").disable();
        //if (this.data.Status == "All") {
        //  this.AddProjectFinance.get("FinancialYear").disable();
        //}
      }
    });
  }

  AddFinanceData() {
    // 

    this.submitted = true;
    if (!this.AddProjectFinance.valid) {
      return;
    }

    if (this.data.EditBaseLine) {
      const formData = new FormData();
      formData.append("ContractID", this.data.ContID);
      formData.append("ProjectID", this.data.PrjID);
      formData.append("FinanceYear", this.AddProjectFinance.get("FinancialYear").value);
      formData.append("ProjectedSpend", this.AddProjectFinance.get("ProjectedSpend").value);
      formData.append("ProjectedSavings", this.AddProjectFinance.get("ProjectedSavings").value);
      formData.append("FinanceYeardisplay", this.data.Yeardisplay);  
      formData.append("ReasonforChange", "");
      formData.append("ProjectFinanceID", this.data.ProjectFinanceID);

      this.DbService.AddprojectFinance(formData).subscribe((data: any) => {
        if (data.RetStatus == "Inserted" || data.RetStatus == "Updated") {
          alert("Updated successfully!");
          this.dialog.closeAll();
        }
        else {
          alert(data.RetStatus);
        }
      });
    }
    else if (this.data.AddTemporary) {

      this.FinanceData.push({
        ContractSpend: this.AddProjectFinance.get("ProjectedSpend").value,
        ContractSavings: this.AddProjectFinance.get("ProjectedSavings").value,
        FinanceYeardisplay: this.FinancSelectedText,
        FinanceYear: this.AddProjectFinance.get("FinancialYear").value,
        ProjectFinanceID: this.data.ProjectFinanceID && this.data.ProjectFinanceID > 0 ? this.data.ProjectFinanceID : 0,
        ProjectSavings: this.AddProjectFinance.get("ProjectedSavings").value,
        ProjectSpend: this.AddProjectFinance.get("ProjectedSpend").value,
      });
      this.dialogRef.close(this.FinanceData);
    }
    //added by zuber on 04/02/2020
    else if (this.data.PrjID == "dummy") {
      //alert("this is dummy entry !");
      //project Financial data......

      if (sessionStorage.getItem('FinancialdummyPrj') != null) {
        if (sessionStorage.getItem('FinancialdummyPrj') != 'null') {
          this.findata = sessionStorage.getItem('FinancialdummyPrj');
        }

      }
      this.findata += this.AddProjectFinance.get("FinancialYear").value + ";" + this.AddProjectFinance.get("ProjectedSpend").value + ";" + this.AddProjectFinance.get("ProjectedSavings").value + ";" + this.FinancSelectedText + "$";
      sessionStorage.setItem('FinancialdummyPrj', this.findata);
      alert("Added Successfully");
      //alert(this.findata);
      this.dialog.closeAll();
    }
    else if (this.data.Ptype == "Edit" || this.data.Ptype == "Add" || this.data.AddBaseLine) {
      //alert("hi");
    
      const formData = new FormData();
      formData.append("ContractID", this.data.ContID);
      formData.append("ProjectID", this.data.PrjID);
      formData.append("FinanceYear", this.AddProjectFinance.get("FinancialYear").value);
      formData.append("ProjectedSpend", this.AddProjectFinance.get("ProjectedSpend").value);
      formData.append("ProjectedSavings", this.AddProjectFinance.get("ProjectedSavings").value);
      formData.append("FinanceYeardisplay", this.data.Ptype == "Add" || this.data.AddBaseLine ? this.FinancSelectedText: this.data.Yeardisplay);     
      formData.append("ReasonforChange", "");
      if (this.data.AddBaseLine)
        formData.append("AddBaseLine", this.data.AddBaseLine);

      this.DbService.AddprojectFinance(formData).subscribe((data: any) => {
        if (data.RetStatus == "Inserted" || data.RetStatus == "Updated") {
          alert("Added Successfully");
          this.dialog.closeAll();
          // this.AddProjectFinance.get("ProjectedSpend").reset();
          // this.AddProjectFinance.get("ProjectedSavings").reset();
        }
        else {
          alert(data.RetStatus);
        }
      });
    }
    else {
      if (this.data.From != "Create Project") {

        const formData = new FormData();
        formData.append("ContractID", this.data.ContID);
        formData.append("ProjectID", this.data.PrjID);
        formData.append("FinanceYear", this.AddProjectFinance.get("FinancialYear").value);
        formData.append("ProjectedSpend", this.AddProjectFinance.get("ProjectedSpend").value);
        formData.append("ProjectedSavings", this.AddProjectFinance.get("ProjectedSavings").value);
        formData.append("FinanceYeardisplay", this.FinancSelectedText);
        //formData.append("ReasonforChange",this.AddProjectFinance.get("ReasonforChange").value);
        formData.append("ReasonforChange", "");

        this.DbService.AddprojectFinance(formData).subscribe((data: any) => {
          if (data.RetStatus == "Inserted") {
            alert("Added Successfully");
            this.dialog.closeAll();
            // this.AddProjectFinance.get("ProjectedSpend").reset();
            // this.AddProjectFinance.get("ProjectedSavings").reset();
          }
          else {
            alert(data.RetStatus);
          }
        });
      }
      else {

        this.FinanceData.push({
          ContractSpend: this.AddProjectFinance.get("ProjectedSpend").value,
          ContractSavings: this.AddProjectFinance.get("ProjectedSavings").value,
          FinanceYeardisplay: this.FinancSelectedText,
          FinanceYear: this.AddProjectFinance.get("FinancialYear").value,
          ProjectFinanceID: 0,
          ProjectSavings: this.AddProjectFinance.get("ProjectedSavings").value,
          ProjectSpend: this.AddProjectFinance.get("ProjectedSpend").value
        });
        this.dialogRef.close(this.FinanceData);
      }

    }

  }
  SelectedText(event) {
    this.FinancSelectedText = event.target.options[event.target.options.selectedIndex].text;

    //console.log(event.target.options[event.target.options.selectedIndex].text);

  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }

}
