import { Component, OnInit, ElementRef,ViewChild, ViewEncapsulation } from '@angular/core';
//import {ChartComponent} from '@syncfusion/ej2-angular-charts';
import { MatDialog } from '@angular/material/dialog';

import {DialogPopupTestComponent} from '../dialogs/dialog-popup-test/dialog-popup-test.component';

@Component({
  selector: 'app-test-gantt',
  templateUrl: './test-gantt.component.html',
  styleUrls: ['./test-gantt.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestGanttComponent implements OnInit {

  
  constructor(public dialog: MatDialog) { 
  }
  ngOnInit() {
  }

  openDialogGanttChart()
  {
    const dialogRef = this.dialog.open(DialogPopupTestComponent, {
      width: '1366px'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      
    });
  }

}
