import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectDatabaseService } from '../project-database.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { formatDate, DatePipe, JsonPipe } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddNewTaskComponent } from '../../dialogs/dialog-add-new-task/dialog-add-new-task.component';
import { DashboardContractsNewContractService } from '../../dashboard/dashboard-contracts-new-contract/dashboard-contracts-new-contract.service';
import { DialogAddNewProjectfinancialsComponent } from '../../dialogs/dialog-add-new-projectfinancials/dialog-add-new-projectfinancials.component';
import { DashboardContractDetailsService } from '../dashboard-contract-details/dashboard-contract-details.service';
import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
//import {ChartComponent} from '@syncfusion/ej2-angular-charts';
//import { Gantt } from '@syncfusion/ej2-angular-gantt';
import { GoogleChartsModule } from 'angular-google-charts';
import { AlertComponent } from '../../alert/alert.component';
import { DialogCompleteProjectComponent } from '../../dialogs/dialog-complete-project/dialog-complete-project.component';
import { DialogReasonForChangeComponent } from '../../dialogs/dialog-reason-for-change/dialog-reason-for-change.component';
import { DialogReasonforProjectapproverejectComponent } from '../../dialogs/dialog-reasonfor-projectapprovereject/dialog-reasonfor-projectapprovereject.component';
import { DialogResubmitProjectTimetableImpactComponent } from 'src/app/dialogs/dialog-resubmit-project-timetable-impact/dialog-resubmit-project-timetable-impact.component';
import { DialogResubmitProjectTimetableFinancialsAddNewComponent } from 'src/app/dialogs/dialog-resubmit-project-timetable-financials-add-new/dialog-resubmit-project-timetable-financials-add-new.component';
import { DialogResubmitProjectTimetableFinancialsComponent } from 'src/app/dialogs/dialog-resubmit-project-timetable-financials/dialog-resubmit-project-timetable-financials.component';
import { DialogResubmitProjectTimetableContractDatesComponent } from 'src/app/dialogs/dialog-resubmit-project-timetable-contract-dates/dialog-resubmit-project-timetable-contract-dates.component';
import { DialogResubmitProjectTimetableReasonComponent } from 'src/app/dialogs/dialog-resubmit-project-timetable-reason/dialog-resubmit-project-timetable-reason.component';
import { DialogProjectClosureComponent } from '../../dialogs/dialog-project-closure/dialog-project-closure.component';
import { SettingsService } from '../../settings/settings.service';
import { MatTabGroup } from '@angular/material';
import { SystemNotificationsService } from '../../system-notifications/system-notifications.service';
export interface ProjectPhase1Details {
  PK_TaskID: number;
  taskDescription: string;
  startDate: Date;
  endDate: Date;
  action: string;
  taskOrder: number;
  status: number;
}

export interface ProjectPhase2Details {
  PK_TaskID: number;
  taskDescription: string;
  startDate: Date;
  endDate: Date;
  action: string;
  taskOrder: number;
  status: number;
}

export interface ProjectPhase3Details {
  PK_TaskID: number;
  taskDescription: string;
  startDate: Date;
  endDate: Date;
  action: string;
  taskOrder: number;
  status: number;
}

export interface ProjectPhase4Details {
  PK_TaskID: number;
  taskDescription: string;
  startDate: Date;
  endDate: Date;
  action: string;
  taskOrder: number;
  status: number;
}
export interface MilseStoneHistory {
  FK_ProjectID: any;
  FK_PhaseID: any;
  StartDate: Date;
  EndDate: Date;
  ReasonforChange: string;
  PhaseStatus: string;

}

export interface MileStone {
  StartDate: string;
  EndDate: string;
  PhaseStatus: string;
  FK_ProjectPhaseID: string;
}



@Component({
  selector: 'app-dashboard-project-details',
  templateUrl: './dashboard-project-details.component.html',
  styleUrls: ['./dashboard-project-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardProjectDetailsComponent implements OnInit {



  ShowAssignUsers: boolean = false;
  ShowProjectOwner: boolean = true;
  AssignUsers: any;
  showF: boolean = true;
  @ViewChild('editor', { static: true }) editor: GanttEditorComponent;
  public editorOptions: any = {};
  public data: any;
  public LatsData: any;
  ProjectCurrentStatus: string = "";
  DisableProjectStatusbutton: boolean = false;
  DisableSubmitForApprove: boolean = false;
  SubmitforApprove: boolean = false;
  ReassignProject: boolean = false;
  ProjectStatus: boolean = true;
  @ViewChild('GanttChartID', { static: false }) public chart1: GoogleChartsModule;
  selectedIndex: number;
  ProjectTitle: string = "";
  ProjectDetails: FormGroup;
  ProjectPlan: FormGroup;
  ProjectSocialValue: FormGroup;
  Showchart: boolean = false;
  HideChartDiv: boolean = true;
  MileStoneDetails: FormGroup;
  ContractType: any;
  Organisation: any;
  Category: any;
  SubCategory: any;
  ExtensionPeriod: any;
  ProjectHistory: any;
  Taskdata: any;
  Charttitle: string;
  Charttype: string;
  ChartcolumnNames: any;
  Chartoptions: any;
  Chartheight: any;
  today = new Date();
  submitted: boolean = false;
  Phasesubmitted: boolean = false;
  phase1DataSource: any;
  phase2DataSource: any;
  phase3DataSource: any;
  phase4DataSource: any;
  NewFinance: boolean = true;
  yearlyFinancialsDisplayedColumns = ['FinanceYear', 'ContractSpend', 'ContractSavings', 'action'];
  yearlyFinancialsDataSource: any;
  ProjectedContractSpend: any;
  ProjectedAllualSpend: any;
  ProjectedContractSavings: any;
  ProjectedAnnualSavings: any;
  showYear: boolean = true;
  TaskStatus: any;
  public summarydata: any;
  InitiationClass: any;
  PlanningClass: any;
  ExecutionClass: any;
  ClosureClass: any;
  InitianDone: any;
  PlanningDone: any;
  ExecutionDone: any;
  ClosureDone: any;
  ProjectPhase1List: ProjectPhase1Details[] = [];
  ProjectPhase2List: ProjectPhase2Details[] = [];
  ProjectPhase3List: ProjectPhase3Details[] = [];
  ProjectPhase4List: ProjectPhase4Details[] = [];
  MileStoneHistoryList: MilseStoneHistory[] = [];
  Phase1Color: string = "";
  Phase2Color: string = "";
  Phase3Color: string = "";
  Phase4Color: string = "";
  Phase1Status: boolean = false;
  Phase2Status: boolean = false;
  Phase3Status: boolean = false;
  Phase4Status: boolean = false;
  MileStonedateChange: boolean = false;
  tabchange: boolean = false;
  tabchangeMilestone: boolean = false;
  isDisabledContent: boolean = true;
  CallShowMethod: boolean = false;
  ShowReasonPopup: boolean = false;
  isDisabled: boolean = false;
  RevisionHistory: boolean = false;
  RevisionHistory2: boolean = false;
  RevisionHistory3: boolean = false;
  RevisionHistory4: boolean = false;
  ResubmitDataAvailable: boolean = false;
  ProjectCreatedDate: any;
  ProjectApprovedDate: any;
  ProjectCreatedDate1: any;
  ProjectApprovedDate1: any;

  ProjectCreatedDate2: any;
  ProjectApprovedDate2: any;

  ProjectCreatedDate3: any;
  ProjectApprovedDate3: any;

  ProjectCreatedDate4: any;
  ProjectApprovedDate4: any;

  ContractStartDate: any;
  ContractStartDate1: any;
  ContractStartDate2: any;
  ContractStartDate3: any;
  ContractStartDate4: any;

  ContractStartDateVariance1: any;
  ContractStartDateVariance2: any;
  ContractStartDateVariance3: any;
  ContractStartDateVariance4: any;

  StatusPermission: boolean = false;
  RevisionData: any;

  milestoneData: MileStone[] = [];


  projectResubmissionColumns1 = ['ProjectStage', 'ProjectStartDate', 'ProjectEndDate', 'NumberOfDays', 'Variance'];
  projectResubmissionDataSource: any;

  projectResubmissionContractValueDisplayedColumns = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectResubmissionContractValueDataSource: any;
  projectResubmissionContractSavingsDisplayedColumns = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectResubmissionContractSavingsDataSource: any;
  projectResubmissionTotal: any;


  projectBaselineCurrentDisplayedColumns = ['ProjectStage', 'ProjectStartDate', 'ProjectEndDate', 'NumberOfDays'];
  projectBaselineCurrentDataSource: any;


  projectFinancialsCurrentContractValueDisplayedColumns = ['FinancialYearDisplay', 'Financevalue'];
  projectFinancialsCurrentContractValueDataSource: any;
  projectFinancialsCurrentContractSavingsDisplayedColumns = ['FinancialYearDisplay', 'Financevalue'];
  projectFinancialsCurrentContractSavingsDataSource: any;
  projectFinancialsCurrentTotal: any;

  projectBaselineCurrentDisplayedColumns1 = ['ProjectStage', 'ProjectStartDate', 'ProjectEndDate', 'NumberOfDays', 'Variance'];
  projectBaselineCurrentDataSource1: any;

  projectFinancialsCurrentContractValueDisplayedColumns1 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractValueDataSource1: any;
  projectFinancialsCurrentContractSavingsDisplayedColumns1 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractSavingsDataSource1: any;
  projectFinancialsCurrentTotal1: any;

  projectBaselineCurrentDisplayedColumns2 = ['ProjectStage', 'ProjectStartDate', 'ProjectEndDate', 'NumberOfDays', 'Variance'];
  projectBaselineCurrentDataSource2: any;

  projectFinancialsCurrentContractValueDisplayedColumns2 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractValueDataSource2: any;
  projectFinancialsCurrentContractSavingsDisplayedColumns2 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractSavingsDataSource2: any;
  projectFinancialsCurrentTotal2: any;

  projectBaselineCurrentDisplayedColumns3 = ['ProjectStage', 'ProjectStartDate', 'ProjectEndDate', 'NumberOfDays', 'Variance'];
  projectBaselineCurrentDataSource3: any;

  projectFinancialsCurrentContractValueDisplayedColumns3 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractValueDataSource3: any;
  projectFinancialsCurrentContractSavingsDisplayedColumns3 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractSavingsDataSource3: any;
  projectFinancialsCurrentTotal3: any;

  projectBaselineCurrentDisplayedColumns4 = ['ProjectStage', 'ProjectStartDate', 'ProjectEndDate', 'NumberOfDays', 'Variance'];
  projectBaselineCurrentDataSource4: any;

  projectFinancialsCurrentContractValueDisplayedColumns4 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractValueDataSource4: any;
  projectFinancialsCurrentContractSavingsDisplayedColumns4 = ['FinancialYearDisplay', 'Financevalue', 'Savings'];
  projectFinancialsCurrentContractSavingsDataSource4: any;
  projectFinancialsCurrentTotal4: any;
  phase1DisplayedColumns = ['taskDescription', 'startDate', 'endDate', 'action', 'status'];
  projectTimeTableDisplayedColumns = ['PrjCreatedDate', 'PrjSubmitDate', 'PrjtStartDate', 'PrjEndDate'];
  projectedLifetimeSpendAndSavingsDisplayedColumns = ['ContractCreated', 'ContractSubmitDate', 'ContractLifeTimeSpend', 'ContractLifetimeSavings'];
  contractStartDateDisplayedColumns = ['ContractCreatedDate', 'ContractSubmitDate', 'ContractStartDate', 'ContractEndDate'];
  HideHistoryTab: boolean = false;
  Names: any;
  ProjectMSList: any;
  Tooltips: any;
  bLLAEnabled: boolean = false;
  NotUnpublishedProject: boolean = false;
  AllowContractEdit: boolean = false;

  CategoryID: any;
  SubCategoryID: any;
  bUseSimpleSubCategory: boolean = false;
  SubCategorySelected: any;


  Phase1RiskStatus: '';
  Phase2RiskStatus: '';
  Phase3RiskStatus: '';
  Phase4RiskStatus: '';



  //resubmission work
  resubmitErrorMessage: string = "As you have already submitted a revised project timetable which is awaiting approval you are not able to submit another timetable until this has either been approved or rejected.";
  disableResubmitButton: boolean = false;
  resubmitReasonDisplay: string = "";
  reson1: string = "";
  reson2: string = "";
  reson3: string = "";
  reson4: string = "";
  resubmitContractStartDate: string = "";
  resubmitContractStartDateVariance: string = "";
  //
  keyword = 'Abbreviation';
  InitialSubCategory: any;
  RevisionHistoryList = [];
  

  constructor(private router: Router, private DbService: ProjectDatabaseService, private route: ActivatedRoute, private fb: FormBuilder, public dialog: MatDialog, private el: ElementRef, private ContractService: DashboardContractsNewContractService,
    private Dservice: DashboardContractDetailsService, private change: ChangeDetectorRef, private setService: SettingsService, private SysNotifications: SystemNotificationsService) {
    this.LoadAllCaptions();
    this.isLLAEnabled();
    this.UseSimpleSubCategory();

    if (sessionStorage.getItem("ContactID") != null) {
      this.Charttitle = "Raj";
      this.Charttype = 'Gantt';
      this.Taskdata = [[null, null, null, new Date(2019, 0, 1), new Date(2019, 0, 2), 0, 0, null]];
      this.TaskStatus = 0;
      if (sessionStorage.getItem("usertype") == "Head of Procurement") {
        this.getOrganisation();
        this.ReassignProject = true;
      }
      else {
        this.getOrganisationForNonHOP();
        if (sessionStorage.getItem("usertype") == "Team Manager") {
          this.ReassignProject = true;
        }
      }
      this.getCategory();

      this.getExtensionPeriod();
      this.getContractTypes();
      if (this.route.snapshot.queryParams['ProjectID'] != null) {
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(1), "1");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(2), "2");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(3), "3");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(4), "4");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(1), "1");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(2), "2");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(3), "3");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(4), "4");

        this.getProjectBaseLineEstimatedContractSavings(this.route.snapshot.queryParams['ProjectID']);
        this.getProjectBaseLineEstimatedContractValue(this.route.snapshot.queryParams['ProjectID']);

       

        //this.GetProjectBaseLineData1(this.route.snapshot.queryParams['ProjectID'], 2);
        //this.GetProjectBaseLineData1(this.route.snapshot.queryParams['ProjectID'], 3);
        //this.GetProjectBaseLineData1(this.route.snapshot.queryParams['ProjectID'], 4);
        //this.GetProjectBaseLineData1(this.route.snapshot.queryParams['ProjectID'], 5);


        if (this.route.snapshot.queryParams['StatusPermission'] != null) {
          this.StatusPermission = true;
          this.ProjectStatus = false;
          this.SubmitforApprove = false;
        }
        else if (this.route.snapshot.queryParams['ViewProjectResubmit'] != null) {
          //added on 29/01/2020
          this.DbService.CheckResubmitStatus(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
            if (data.value == "A" || data.value == "R") {
              alert("This request has been serviced already!")
            }
            else {
              this.StatusPermission = true;
              this.ProjectStatus = false;
              this.SubmitforApprove = false;
              this.selectedIndex = 4;
              this.isDisabledContent = false;
              this.isDisabled = true;

              this.GetProjectReSubmissionData(this.route.snapshot.queryParams['ProjectID']);

              if (this.route.snapshot.queryParams['ResubmitID'] != null) {
                this.DbService.GetProjectResubmissionReason(this.route.snapshot.queryParams['ResubmitID']).subscribe((result: any) => {
                  console.log("result", result);
                  if (result) {
                    this.resubmitReasonDisplay = result.ReasonChange;
                  }
                });
              }
            }

          });
        }
        else if (this.route.snapshot.queryParams['For'] != null) {

          this.StatusPermission = true;
          this.ProjectStatus = false;
          this.SubmitforApprove = false;
          this.isDisabledContent = false;
          this.isDisabled = true;

        }
        else if (this.route.snapshot.queryParams['ProjectClosure'] != null) {
          this.OpenDialogProjectClosure();
        }
        else if (this.route.snapshot.queryParams['StatusChange'] != null && this.route.snapshot.queryParams['NewStatus'] != null) {
          this.StatusPermission = true;
          this.ProjectStatus = false;
          this.SubmitforApprove = false;
          this.isDisabledContent = false;
        }
        sessionStorage.removeItem("ApproveProject");
      }
      else {
        this.router.navigate(["/login"]);
      }
    }
    else {

      if (this.route.snapshot.queryParams['StatusPermission'] != null || this.route.snapshot.queryParams['ProjectID'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['ViewProject'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['ViewProjectResubmit'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['For'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else if (this.route.snapshot.queryParams['StatusChange'] != null && this.route.snapshot.queryParams['NewStatus'] != null) {
        this.router.navigate(['/login']);
        sessionStorage.setItem("ApproveProject", this.router.url);
      }
      else {
        this.router.navigate(["/login"]);
      }

    }


  }

  ngOnInit() {
    this.resubmitReasonDisplay = "";


    this.ProjectDetails = this.fb.group({
      ProjectTitle: ['', Validators.required],
      Organisation: [''],
      ContractTitle: [],
      Category: [''],
      SubCategory: [''],
      ContractDesc: [''],
      ContractOwnerFirstName: [],
      ContractOwnerSurName: [],
      ContractOwnerEmail: [],
      ProjectOwnerFirstName: ['', Validators.required],
      //ProjectOwnerSurName:['',Validators.required],
      ProjectOwnerEmail: [''],
      Priority: [],
      Reprocurement: [],
      LifttimeContractSpend: [],
      AnnualisedContractSpend: [],
      LifetimeContractSavings: [],
      AnnualisedContractSavings: [],
      ExtensionAvailable: [],
      ExtensionPeriod: [],
      StartDate: [''],
      EndDate: [''],
      ContractGDPR: [''],
      ContractDepartment: [''],
      ContractStartDate: [''],
      ContractEndDate: [''],
      ContractReference: [''],
      ContractLLA: [''],
      ProjectRef: [''],
      AssignUsers: ['0']
    });

    this.MileStoneDetails = this.fb.group({
      Phase1StartDate: ['', Validators.required],
      Phase1EndDate: ['', Validators.required],
      ddlPhase1Status: ['Incomplete'],
      Phase2StartDate: ['', Validators.required],
      Phase2EndDate: ['', Validators.required],
      ddlPhase2Status: ['Incomplete'],
      Phase3StartDate: ['', Validators.required],
      Phase3EndDate: ['', Validators.required],
      ddlPhase3Status: ['Incomplete'],
      Phase4StartDate: ['', Validators.required],
      Phase4EndDate: ['', Validators.required],
      ddlPhase4Status: ['Incomplete']

    });

    this.ProjectPlan = this.fb.group({
      ProjectStartDate: [''],
      ProjectEndDate: ['']
    });


    this.ProjectSocialValue = this.fb.group({
      SocialValueDesc: ['', Validators.required]
    });

    if (this.route.snapshot.queryParams['ProjectID'] != null) {

      if (this.route.snapshot.queryParams['Move'] == "3") {
        this.selectedIndex = 3;
      }
      else if (this.route.snapshot.queryParams['Move'] == "2") {
        this.selectedIndex = 2;
      }
      else if (this.route.snapshot.queryParams['Move'] == "1") {
        this.selectedIndex = 1;
      }

      if (this.selectedIndex > 0) {
        this.onChanges();
        this.tabchange = false;
      }
      this.getProjectDetails(this.route.snapshot.queryParams['ProjectID']);
    }

    this.Binddata1();
    this.editorOptions = {
      vFormatArr: ['Day', 'Week', 'Month', 'Quarter'],
      vFormat: 'day',
      vShowRes: (0),
      vDayColWidth: 36,
      vWeekColWidth: 64,
      vQuarterColWidth: 36,
      vShowTaskInfoRes: (0),
      vShowTaskInfoNotes: (0),
      vMaxDate: "2025-12-31"
    };
    this.editor.setOptions(this.editorOptions);
  }
  get f() { return this.ProjectDetails.controls; };
  get g() { return this.MileStoneDetails.controls; };
  get s() { return this.ProjectSocialValue.controls; };

  isNumber(val: any) { return typeof val === 'number'; }

  getOrganisation() {
    this.DbService.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data.value;
      this.ProjectDetails.get("Organisation").setValue(data.value[0].OrganisationID);
    });
  }

  getOrganisationForNonHOP() {
    this.DbService.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
      this.Organisation = data;
      if (data.length > 0) {
        this.ProjectDetails.get("Organisation").setValue(data[0].OrganisationID);
      }
    });
  }

  getAllOrganisation() {
    this.DbService.getAllOrganistaion().subscribe((data: any) => {
      this.Organisation = data.value;
    });
  }

  getCategory() {
    this.DbService.getCategory().subscribe((data: any) => {

      this.Category = data.value;

    });
  }

  getSubCategory() {

    this.DbService.getSubCategory().subscribe((data: any) => {

      this.SubCategory = data.value;
      if (data.value.length > 0) {

        //this.ProjectDetails.get("SubCategory").setValue(data.value[0].PK_CategoryTypeID);
        //console.log("SubCategory" + this.ProjectDetails.get("Category").value);
        //this.SubCategoryID=data.value[0].PK_CategoryTypeID;
      }

    });
  }

  getExtensionPeriod() {
    this.DbService.getExtensionPeriod().subscribe((data: any) => {

      this.ExtensionPeriod = data.value;

      // if(data.value.length >0)
      //   {
      //     this.ExtensionID=data.value[0].extentionid;
      //   }
    });
  }

  OpenDialogforDetailsChange() {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: "DetailsEdit" },
      width: '640px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.UpdateProject();
      }
      else {
        this.Revert("Details");
      }
    });
  }



  OpenDialogforMileStoneChange() {

    //added on 06/02/2020
    if (sessionStorage.getItem("usertype") == "Procurement Officer") {
      this.setService.getSystemSettingswithoutcontactID().subscribe((data: any) => {
        if (data.ProjectApproval == "no" || this.MileStonedateChange == false) {
          this.UpdateMilestone();
          //const dialogRef = this.dialog.open(AlertComponent, {
          //  data: { RetStatus: "MileStoneEdit", Resubmit: 'no' },
          //  width: '640px'
          //});

          //dialogRef.afterClosed().subscribe(result => {
          //  console.log(`Dialog result: ${result}`);
          //  if (result == true) {
          //    this.UpdateMilestone();
          //  }
          //  else {
          //    this.Revert("MileStone");
          //  }
          //});
        }
        else {
          this.DbService.ProjectMileStoneDataExists(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
            if (data.value == "true" && sessionStorage.getItem("usertype") == "Procurement Officer" && this.ProjectCurrentStatus &&
              (this.ProjectCurrentStatus.toLowerCase() == "in progress" || this.ProjectCurrentStatus.toLowerCase() == "on hold")) {

              const dialogRef = this.dialog.open(AlertComponent, {
                data: { RetStatus: "MileStoneEdit", Resubmit: 'yes' },
                width: '640px'
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log(`Dialog result: ${result}`);
                if (result == true) {
                  this.DbService.CheckResubmitStatus(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
                    if (data.value == "S") {
                      alert("This project resubmit timetable is already submitted !");
                      this.Revert("MileStone");
                    }
                    else {
                      this.popupWorkflow();
                    }
                  });
                }
                else {
                  this.Revert("MileStone");
                }
              });
            }
            else {
              //this.UpdateMilestone();            
              const dialogRef = this.dialog.open(AlertComponent, {
                data: { RetStatus: "MileStoneEdit", Resubmit: 'no' },
                width: '640px'
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log(`Dialog result: ${result}`);
                if (result == true) {
                  this.UpdateMilestone();
                }
                else {
                  this.Revert("MileStone");
                }
              });
            }
          });
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(AlertComponent, {
        data: { RetStatus: "MileStoneEdit", Resubmit: 'no' },
        width: '640px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        if (result == true) {
          this.UpdateMilestone();
        }
        else {
          this.Revert("MileStone");
        }
      });
    }

    this.Phasesubmitted = true;
    if (!this.MileStoneDetails.valid) {

      return;
    }
  }

  checktabchanges(): void {

    if (this.tabchange) {
      //this.showSave =true;
      this.OpenDialogforDetailsChange();

    }
    if (this.tabchangeMilestone) {
      //this.showMilestoneActions=true;      
      this.OpenDialogforMileStoneChange();
    }
  }

  UpdateProjectSocialValue() {
    this.submitted = true;
    if (this.ProjectSocialValue.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append("SocialValueDesc", this.ProjectSocialValue.get("SocialValueDesc").value);
    formData.append("FK_ProjectID", this.route.snapshot.queryParams['ProjectID']);
    this.DbService.UpdateProjectSocialValue(formData).subscribe((data: any) => {
      if (data.RetStatus == 'Updated') {
        alert("Project social value has been updated successfully.");
        //this.ProjectSocialValue.reset();
      } else {
        alert(data.RetStatus);
      }
    });

  }


  UpdateProject() {
    console.log("this.SubCategorySelected", this.SubCategorySelected);
    console.log("SubCategory", this.ProjectDetails.get("SubCategory").value);

    this.submitted = true;
    if ((!this.ProjectDetails.valid) && (!this.AllowContractEdit)) {
      return;
    }
    if (this.ShowAssignUsers && this.ProjectDetails.get("AssignUsers").value == "0") {
      alert("Please select team member!");
      return;
    }
    var datepipe = new DatePipe('en-US');

    const formData = new FormData();
    formData.append("ProjectTitle", this.ProjectDetails.get("ProjectTitle").value);
    formData.append("PK_ProjectID", this.route.snapshot.queryParams['ProjectID']);
    formData.append("FK_CategoryTypeID", this.bUseSimpleSubCategory ? this.ProjectDetails.get("SubCategory").value : this.SubCategorySelected);
    formData.append("FK_CategoryID", this.ProjectDetails.get("Category").value);
    //formData.append("FK_ProjectStatusID","9");
    formData.append("ProjectDescription", this.ProjectDetails.get("ContractDesc").value);
    formData.append("ProjectReference", this.ProjectDetails.get("ProjectRef").value);
    if (this.ShowAssignUsers) {
      formData.append("ReassignUser", "Yes");
      formData.append("ProjectOwnerID", this.ProjectDetails.get("AssignUsers").value);
      formData.append("ProjectOwnerFirstName", this.ProjectDetails.get("ProjectOwnerFirstName").value);
      formData.append("ProjectOwnerSurName", this.ProjectDetails.get("ProjectOwnerFirstName").value);
    }
    else {
      formData.append("ReassignUser", "No");
      formData.append("ProjectOwnerID", sessionStorage.getItem("ContactID"));
      formData.append("ProjectOwnerFirstName", this.ProjectDetails.get("ProjectOwnerFirstName").value);
      formData.append("ProjectOwnerSurName", this.ProjectDetails.get("ProjectOwnerFirstName").value);
    }
    formData.append("FK_ContractID", this.route.snapshot.queryParams['ContractID']);
    formData.append("ProjectOwnerEmail", this.ProjectDetails.get("ProjectOwnerEmail").value);
    formData.append("ProjectPriority", this.ProjectDetails.get("Priority").value);
    if (this.AllowContractEdit) {

      formData.append("AllowContractEdit", this.AllowContractEdit ? "true" : "false");
      formData.append("ContractReference", this.ProjectDetails.get("ContractReference").value);
      formData.append("ContractTitle", this.ProjectDetails.get("ContractTitle").value);

      formData.append("Category", this.ProjectDetails.get("Category").value);
      formData.append("SubCategory", this.bUseSimpleSubCategory ? this.ProjectDetails.get("SubCategory").value : this.SubCategorySelected);
      formData.append("ContractOwnerFirstName", this.ProjectDetails.get("ContractOwnerFirstName").value);
      formData.append("ContractOwnerSurName", this.ProjectDetails.get("ContractOwnerSurName").value);
      formData.append("ContractOwnerEmail", this.ProjectDetails.get("ContractOwnerEmail").value);
      formData.append("ContractStartDate", datepipe.transform(this.ProjectDetails.get("ContractStartDate").value, 'MM/dd/yyyy'));
      formData.append("ContractEndDate", datepipe.transform(this.ProjectDetails.get("ContractEndDate").value, 'MM/dd/yyyy'));
      formData.append("ContractDepartment", this.ProjectDetails.get("ContractDepartment").value);
      formData.append("ExtensionAvailable", this.ProjectDetails.get("ExtensionAvailable").value);
      formData.append("ExtensionPeriod", this.ProjectDetails.get("ExtensionPeriod").value);
      formData.append("ContractGDPR", this.ProjectDetails.get("ContractGDPR").value);
      formData.append("ContractLLA", this.ProjectDetails.get("ContractLLA").value);
      formData.append("Reprocurement", this.ProjectDetails.get("Reprocurement").value);

    }

    this.DbService.CreateNewProject(formData).subscribe((data: any) => {

      if (data.RetStatus == "Created") {
        alert("Project created successfully");
        this.selectedIndex = 1;
      }
      else if (data.RetStatus == "Updated") {
        alert("Project updated successfully");
        if (this.route.snapshot.queryParams['ProjectsFor'] != null) {
          this.router.navigate(['dashboard/projects/database'], { queryParams: { ShowUnpublished: '1' } });
        }
        else {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
          if (this.ShowAssignUsers) {
            this.HideAssigUsersDropDown();
          }
        }

      }
      else if (data.RetStatus == "Exists") {
        alert("Project title already exists. Try with different title.");
        this.ProjectDetails.get("ProjectTitle").reset();
      }
      else {
        alert("Error:" + data.RetStatus);
      }
    });

  }

  ShowAssigUsersDropDown() {

    this.ShowAssignUsers = true;
    this.ShowProjectOwner = false;
  }
  HideAssigUsersDropDown() {
    this.ShowAssignUsers = false;
    this.ShowProjectOwner = true;
    this.DbService.getContractandprojectDataby(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
      this.ProjectDetails.get("ProjectOwnerFirstName").setValue(data.OwnerFirstName);
      this.ProjectDetails.get("ProjectOwnerEmail").setValue(data.OwnerEmail);
    });

  }
  FillUserEmail(ContactID: any) {

    this.DbService.getContactUser(ContactID).subscribe((data: any) => {
      this.ProjectDetails.get("ProjectOwnerEmail").setValue(data.ContactEMailAddress);
      this.ProjectDetails.get("ProjectOwnerFirstName").setValue(data.ContactFirstName + " " + data.ContactSurname);

    });
  }

  BindCurrentProjectSubCategory(SubCategoryID: any) {

    this.DbService.getSubCategoryForProject(SubCategoryID).subscribe((data: any) => {
      this.SubCategory = data;

    });
  }

  getProjectDetails(ProjectID: any) {

    //this.isDisabledContent = true; 
    this.AllowContractEdit = false;
    

    this.DbService.getContractandprojectDataby(ProjectID).subscribe((data: any) => {      
      if (data.ProjectStatus == "In Progress" && this.route.snapshot.queryParams['For'] != null && this.route.snapshot.queryParams['For'] == "Approval") {
        alert('This request has already been actioned');
        this.router.navigate(['dashboard/projects/database']);
      }


      // this.BindCurrentProjectSubCategory(data.SubCategory);

      //this.DbService.getSubCategoryForProject(data.SubCategory).subscribe((data: any) => {
      //  this.SubCategory = data;
      //  this.ProjectDetails.get("SubCategory").setValue(data.SubCategory);
      //});


      this.disableResubmitButton = data.TimeTableExist;
      
      //if (data.ProjectStatus == "Complete" && !this.disableResubmitButton) {
      //  this.disableCompleteProjectStatus = false;
      //  this.disableResubmitButton = true;
      //}

   
     


      var datepipe = new DatePipe('en-US');
      this.ProjectCreatedDate = data.DateCreated;
      this.ProjectApprovedDate = data.ProjectStatus == "Not Started" ? "" : data.ModifiedDate;
      this.ProjectSocialValue.get("SocialValueDesc").setValue(data.SocialValueDescription);

      this.DbService.Contract_ID = data.ContractID;
      this.ProjectDetails.get("ProjectTitle").setValue(data.ProjectTitle);
      this.ProjectDetails.get("Organisation").setValue(data.Organisation_Name);
      this.ProjectDetails.get("ContractTitle").setValue(data.ContractTitle);
      this.ProjectDetails.get("Category").setValue(data.Category);

      //this.getSubCategory();
      if (this.bUseSimpleSubCategory) {
        this.onCategorySelected(data.Category);
        this.ProjectDetails.get("SubCategory").setValue(data.SubCategory);
      }
      else {

        this.DbService.getSubCategoryForProject(data.SubCategory).subscribe((data1: any) => {
          console.log("data1", data1);
          this.SubCategory = data1;
          this.InitialSubCategory = data1[0].Abbreviation + " " + data1[0].CategoryTypeDescription;
          this.ProjectDetails.get("SubCategory").setValue(this.InitialSubCategory);
          this.SubCategorySelected = data1[0].PK_CategoryTypeID;
        });

        //this.DbService.getSubCategory().subscribe((data1: any) => {
        //  this.SubCategory = data1.value;
        //  if (data1.value.length > 0) {
        //    this.ProjectDetails.get("SubCategory").setValue(data.SubCategory);
        //  }
        //});
      }
      //  this.ProjectDetails.get("SubCategory").setValue(data.SubCategory);
      //console.log(this.ProjectDetails.get("SubCategory").value);
      this.ProjectDetails.get("StartDate").setValue(data.StartDate != "0001-01-01T00:00:00" ? data.StartDate : null);
      this.ProjectDetails.get("EndDate").setValue(data.EndDate != "0001-01-01T00:00:00" ? data.EndDate : null);
      this.ProjectDetails.get("ContractOwnerFirstName").setValue(data.ContractOwnerFirstName);
      this.ProjectDetails.get("ContractOwnerSurName").setValue(data.ContractOwnerSurName);
      this.ProjectDetails.get("ContractOwnerEmail").setValue(data.ContractOwnerEmail);
      this.ProjectDetails.get("ProjectOwnerFirstName").setValue(data.OwnerFirstName);
      //this.ProjectDetails.get("ProjectOwnerSurName").setValue(data.OwnerSurName);
      this.ProjectDetails.get("ProjectOwnerEmail").setValue(data.OwnerEmail);
      //this.ProjectDetails.get("Reprocurement").setValue("New");
      this.ContractService.getContractTypes().subscribe((data1: any) => {
        this.ContractType = data1.value;
        this.ProjectDetails.get("Reprocurement").setValue(data.ContractType);

      });

      //this.ProjectDetails.get("Reprocurement").setValue(data.ContractType);
      this.ProjectDetails.get("LifttimeContractSpend").setValue(data.ProjectedLifeTimeSpend);
      this.ProjectDetails.get("AnnualisedContractSpend").setValue(data.ProjectedAnnualisedSpend);
      this.ProjectDetails.get("LifetimeContractSavings").setValue(data.ProjectedLifetimeSavings);
      this.ProjectDetails.get("AnnualisedContractSavings").setValue(data.ProjectedAnnualisedSavings);
      this.ProjectDetails.get("ExtensionAvailable").setValue(data.ExtensionAvailable);
      this.ProjectDetails.get("ExtensionPeriod").setValue(data.ExtensionPeriod);
      this.ProjectDetails.get("Priority").setValue(data.ProjectPriority);
      this.ProjectDetails.get("ContractDesc").setValue(data.Description);
      this.ProjectDetails.get("ProjectRef").setValue(data.ProjectReference);

      this.ProjectDetails.get("ContractGDPR").setValue(data.ContractGDRP);
      this.ProjectDetails.get("ContractEndDate").setValue(data.ContractEndDate);
      this.ProjectDetails.get("ContractStartDate").setValue(data.ContractStartDate);
      this.ProjectDetails.get("ContractDepartment").setValue(data.ContractDepartment);
      this.ProjectDetails.get("ContractLLA").setValue(data.ContractLLA);

      if (data.ContractReference == null || data.ContractReference == "") {
        this.ProjectDetails.get("ContractReference").setValue(data.ContractID);
      }
      else {
        this.ProjectDetails.get("ContractReference").setValue(data.ContractReference);
      }

      this.ProjectTitle = data.ProjectTitle;
      this.ProjectCurrentStatus = data.ProjectStatus;

      this.GetProjectBaseLineData(ProjectID);
     

      //alert(data.ProjectStatus);
      if (data.ProjectStatus == "Closed" || data.ProjectStatus == "Cancelled" || this.route.snapshot.queryParams['StatusPermission'] != null) {
      
        this.DisableProjectStatusbutton = true;
        this.isDisabled = true;
        this.AllowContractEdit = false;
        this.isDisabledContent = false;
      }
      else if (data.ProjectFlag == "Live" && !this.StatusPermission && data.ProjectStatus == "Not Started") {
        this.SubmitforApprove = true;
        this.isDisabledContent = false;
        this.AllowContractEdit = true;
        this.DbService.CheckFinanceandMilestoneDataEnteredForProject(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {

          if (data == "Exists") {
            this.DisableSubmitForApprove = true;
          }
        });
        //DisableSubmitForApprove
        this.ProjectStatus = false;
      }
      else if (data.ProjectFlag == "Upload" && !this.StatusPermission && data.ProjectStatus == "Not Started") {
        this.SubmitforApprove = true;
        this.isDisabledContent = false;
        this.AllowContractEdit = true;
        this.DbService.CheckFinanceandMilestoneDataEnteredForProject(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {

          if (data == "Exists") {
            this.DisableSubmitForApprove = true;
          }
        });
        //DisableSubmitForApprove
        this.ProjectStatus = false;
      }
      else {
        this.AllowContractEdit = false;
        this.isDisabledContent = false;

      }
      //this.ContractData=data;
      //console.log(data);
      this.ProjectPlan.get("ProjectStartDate").setValue(data.StartDate != "0001-01-01T00:00:00" ? data.StartDate : null);
      this.ProjectPlan.get("ProjectEndDate").setValue(data.EndDate != "0001-01-01T00:00:00" ? data.EndDate : null);

      this.getProjectFinancials(ProjectID);
      this.getContractProjectedSpend(data.ContractID);
      this.getContractAnnualSpend(data.ContractID);
      this.getContractProjectedSavings(data.ContractID);
      this.getContractAnnualSavings(data.ContractID);


      this.getContractStartDate(data.ContractID);
      //this.getContractStartDate1(data.ContractID, this.route.snapshot.queryParams['ProjectID'], 2);
      //this.getContractStartDate1(data.ContractID, this.route.snapshot.queryParams['ProjectID'], 3);
      //this.getContractStartDate1(data.ContractID, this.route.snapshot.queryParams['ProjectID'], 4);
      //this.getContractStartDate1(data.ContractID, this.route.snapshot.queryParams['ProjectID'], 5);

      this.GetProjectBaseLineDataRevisionHistory(this.route.snapshot.queryParams['ProjectID'], data.ContractID);

      this.DbService.getResubmissionContractStartDate(data.ContractID).subscribe((data: any) => {
        if (data) {

          this.resubmitContractStartDate = data.ContractStartDate;
          this.resubmitContractStartDateVariance = data.Variance;
        }

      });




      this.DbService.GetAllProjectOwnersBy_OrgID_withoutCurrentUser(this.ProjectDetails.get("Organisation").value, data.ProjectOwnerID, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
        this.AssignUsers = data;
        console.log("Assign Users : " + data);
        if (this.selectedIndex == undefined) {

          this.onChanges();
          this.tabchange = false;
        }
      });

      //if (this.route.snapshot.queryParams['ViewProjectResubmit'] == null && sessionStorage.getItem("usertype") == "Team Manager" && this.disableResubmitButton) {
      //  this.StatusPermission = true;
      //  this.ProjectStatus = false;
      //  this.SubmitforApprove = false;
      //  this.selectedIndex = 4;
      //  this.isDisabledContent = false;
      //  this.isDisabled = true;
      //  this.ResubmitDataAvailable = true;

      //}

    });

  }

  Revert(tab: string) {
    if (tab == "Details") {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
    }
    else if (tab == "MileStone") {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      //  this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'], Move: '2' } });
      //this.selectedIndex = 2;
      this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
      this.selectedIndex = 0;

    }
    else if (this.route.snapshot.queryParams['ProjectsFor'] != null) {
      this.router.navigate(['dashboard/projects/database'], { queryParams: { ShowUnpublished: '1' } });
    }
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
    }

  }



  openDialogAddNewTask(Param: any) {

    if (Param == "Add") {
      const dialogRef = this.dialog.open(DialogAddNewTaskComponent, {
        data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(1), "1");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(2), "2");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(3), "3");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(4), "4");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(1), "1");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(2), "2");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(3), "3");
        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(4), "4");
        this.Binddata1();
        this.Revert("MileStone");
      });
    }
    else {

      const dialogRef = this.dialog.open(DialogAddNewTaskComponent, {
        data: { ProjectID: this.route.snapshot.queryParams['ProjectID'], TaskID: Param },
        width: '730px'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);

        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(1), "1");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(2), "2");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(3), "3");
        this.GetProjectPhaseTasks(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(4), "4");
        this.Binddata1();

      });
    }

  }



  GetProjectPhaseTasks(ProjectID: any, Phase: string, PhaseFor: string) {
    this.DbService.GetTasksByProjectIDandPhase(ProjectID, PhaseFor).subscribe((data: any) => {
      if (PhaseFor == "1") {
        this.phase1DataSource = [];
        this.phase1DataSource = new MatTableDataSource(data);
        if (data.length > 0) {
          this.Phase1Status = true;
          for (let i = 0; i < data.length; i++) {
            this.ProjectPhase1List.push({
              PK_TaskID: data[i].PK_TaskID,
              taskDescription: data[i].taskDescription,
              startDate: data[i].startDate,
              endDate: data[i].endDate,
              taskOrder: data[i].taskOrder,
              status: data[i].taskOrder,
              action: "Edit Task"
            });
          }
        }
      }
      else if (PhaseFor == "2") {
        this.phase2DataSource = [];
        this.phase2DataSource = new MatTableDataSource(data);
        if (data.length > 0) {
          this.Phase2Status = true;
          for (let i = 0; i < data.length; i++) {
            this.ProjectPhase2List.push({
              PK_TaskID: data[i].PK_TaskID,
              taskDescription: data[i].taskDescription,
              startDate: data[i].startDate,
              endDate: data[i].endDate,
              taskOrder: data[i].taskOrder,
              status: data[i].taskOrder,
              action: "Edit Task"
            });
          }
        }
      }
      else if (PhaseFor == "3") {
        this.phase3DataSource = [];
        this.phase3DataSource = new MatTableDataSource(data);
        if (data.length > 0) {
          this.Phase3Status = true;
          for (let i = 0; i < data.length; i++) {
            this.ProjectPhase3List.push({
              PK_TaskID: data[i].PK_TaskID,
              taskDescription: data[i].taskDescription,
              startDate: data[i].startDate,
              endDate: data[i].endDate,
              taskOrder: data[i].taskOrder,
              status: data[i].taskOrder,
              action: "Edit Task"
            });
          }
        }
      }
      else {
        this.phase4DataSource = [];
        this.phase4DataSource = new MatTableDataSource(data);
        if (data.length > 0) {
          this.Phase4Status = true;
          for (let i = 0; i < data.length; i++) {
            this.ProjectPhase4List.push({
              PK_TaskID: data[i].PK_TaskID,
              taskDescription: data[i].taskDescription,
              startDate: data[i].startDate,
              endDate: data[i].endDate,
              taskOrder: data[i].taskOrder,
              status: data[i].taskOrder,
              action: "Edit Task"
            });
          }
        }
      }
    });
  }
  onChanges(): void {
    this.ProjectDetails.get("Organisation").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.ProjectDetails.get("ProjectTitle").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.ProjectDetails.get("ContractDesc").valueChanges.subscribe(val => {
      this.tabchange = true;
      this.isDisabledContent = false;
    });

    if (this.AllowContractEdit) {
      this.ProjectDetails.get("ContractReference").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractTitle").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("Category").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("SubCategory").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractOwnerFirstName").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractOwnerSurName").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractOwnerEmail").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractStartDate").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractEndDate").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractDepartment").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ExtensionAvailable").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ExtensionPeriod").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });
      this.ProjectDetails.get("ContractGDPR").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("ContractLLA").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });

      this.ProjectDetails.get("Reprocurement").valueChanges.subscribe(val => {
        this.tabchange = true;
        this.isDisabledContent = false;
      });
    }


    this.ProjectDetails.get("ProjectRef").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.ProjectDetails.get("Priority").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
    this.ProjectDetails.get("AssignUsers").valueChanges.subscribe(val => {

      this.tabchange = true;
      this.isDisabledContent = false;
    });
  }

  onChangeMilestones(): void {

    this.MileStoneDetails.get("Phase1StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;

    });
    this.MileStoneDetails.get("Phase1EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;

    });
    this.MileStoneDetails.get("ddlPhase1Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });

    this.MileStoneDetails.get("Phase2StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("Phase2EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("ddlPhase2Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });

    this.MileStoneDetails.get("Phase3StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("Phase3EndDate").valueChanges.subscribe(val => {
      //alert("hi");
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("ddlPhase3Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });

    this.MileStoneDetails.get("Phase4StartDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("Phase4EndDate").valueChanges.subscribe(val => {
      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
    this.MileStoneDetails.get("ddlPhase4Status").valueChanges.subscribe(val => {

      this.tabchangeMilestone = true;
      this.isDisabledContent = false;
    });
  }
  // UpdateMilestone()
  // {
  //   this.Phasesubmitted=true;
  //   if (!this.MileStoneDetails.valid) {

  //     return;
  //     }
  //     var datepipe = new DatePipe('en-US');

  //     const Phaese1formData=new FormData();
  //     Phaese1formData.append("FK_ProjectID",this.route.snapshot.queryParams['ProjectID']);
  //     Phaese1formData.append("FK_ProjectPhaseID",this.getProjectCaption(1));
  //     Phaese1formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //     Phaese1formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //     Phaese1formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase1Status").value);
  //     this.DbService.AddprojectPhaseDates(Phaese1formData).subscribe((data:any) =>
  //     {
  //       if(data.RetStatus =="Created" || data.RetStatus =="Updated")
  //       {
  //         const Phaese2formData=new FormData();
  //         Phaese2formData.append("FK_ProjectID",this.route.snapshot.queryParams['ProjectID']);
  //         Phaese2formData.append("FK_ProjectPhaseID",this.getProjectCaption(2));
  //         Phaese2formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //         Phaese2formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //         Phaese2formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase2Status").value);
  //         this.DbService.AddprojectPhaseDates(Phaese2formData).subscribe((Phaese2Data:any) =>
  //         {
  //           if(Phaese2Data.RetStatus =="Created" || Phaese2Data.RetStatus =="Updated")
  //           {
  //             const Phaese3formData=new FormData();
  //             Phaese3formData.append("FK_ProjectID",this.route.snapshot.queryParams['ProjectID']);
  //             Phaese3formData.append("FK_ProjectPhaseID",this.getProjectCaption(3));
  //             Phaese3formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //             Phaese3formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //             Phaese3formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase3Status").value);
  //             this.DbService.AddprojectPhaseDates(Phaese3formData).subscribe((Phaese3Data:any) =>{
  //               if(Phaese3Data.RetStatus =="Created" || Phaese3Data.RetStatus =="Updated")
  //               {
  //                 const Phaese4formData=new FormData();
  //                 Phaese4formData.append("FK_ProjectID",this.route.snapshot.queryParams['ProjectID']);
  //                 Phaese4formData.append("FK_ProjectPhaseID",this.getProjectCaption(4));
  //                 Phaese4formData.append("StartDate",datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value,'MM/dd/yyyy hh:mm:ss'));
  //                 Phaese4formData.append("EndDate",datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value,'MM/dd/yyyy hh:mm:ss'));
  //                 Phaese4formData.append("PhaseStatus",this.MileStoneDetails.get("ddlPhase4Status").value);
  //                 this.DbService.AddprojectPhaseDates(Phaese4formData).subscribe((Phaese4Data:any) =>{
  //                   if(Phaese4Data.RetStatus =="Created" || Phaese4Data.RetStatus =="Updated")
  //                   {

  //                     this.getProjectDetails(this.route.snapshot.queryParams['ProjectID']);


  //                     if(this.ProjectPhase1List.length >0)
  //                     {

  //                       for(let i=0;i<this.ProjectPhase1List.length;i++)
  //                       {
  //                         this.DbService.UpdateTaskStatus(this.ProjectPhase1List[i].PK_TaskID,this.ProjectPhase1List[i].taskOrder).subscribe((data:any) =>
  //                         {
  //                           if(data.value !="Success") return;
  //                         });
  //                       }

  //                       this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'],this.getProjectCaption(1), "1");

  //                     }
  //                     if(this.ProjectPhase2List.length >0)
  //                     {
  //                       for(let i=0;i<this.ProjectPhase2List.length;i++)
  //                       {
  //                         this.DbService.UpdateTaskStatus(this.ProjectPhase2List[i].PK_TaskID,this.ProjectPhase2List[i].taskOrder).subscribe((data:any) =>
  //                         {
  //                           if(data.value !="Success") return;
  //                         });
  //                       }

  //                       this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'],this.getProjectCaption(2), "2");

  //                     }
  //                     if(this.ProjectPhase3List.length >0)
  //                     {
  //                       for(let i=0;i<this.ProjectPhase3List.length;i++)
  //                       {
  //                         this.DbService.UpdateTaskStatus(this.ProjectPhase3List[i].PK_TaskID,this.ProjectPhase3List[i].taskOrder).subscribe((data:any) =>
  //                         {
  //                           if(data.value !="Success") return;
  //                         });
  //                       }

  //                       this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'],this.getProjectCaption(3),"3");

  //                     }
  //                     if(this.ProjectPhase4List.length >0)
  //                     {
  //                       for(let i=0;i<this.ProjectPhase4List.length;i++)
  //                       {
  //                         this.DbService.UpdateTaskStatus(this.ProjectPhase4List[i].PK_TaskID,this.ProjectPhase4List[i].taskOrder).subscribe((data:any) =>
  //                         {
  //                           if(data.value !="Success") return;
  //                         });
  //                       }

  //                       this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'],this.getProjectCaption(4),"4");

  //                     }

  //                     alert("Saved Successfully");
  //                     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //                     this.router.onSameUrlNavigation = 'reload';
  //                     this.router.navigate(['dashboard/project/details'], { queryParams: {ProjectID: this.route.snapshot.queryParams['ProjectID'],Move:"3"}});
  //                     this.selectedIndex=3;


  //                   }
  //                   else if(Phaese4Data.RetStatus=="Invalid Date")
  //                   {
  //                     this.MileStoneDetails.get("Phase4StartDate").reset();
  //                     this.MileStoneDetails.get("Phase4EndDate").reset();
  //                     alert("Phase 4 Start Date & End Date should be with in Project Start Date & End date");
  //                   }
  //                   else
  //                   {
  //                     alert(Phaese4Data.RetStatus);
  //                   }
  //                 });
  //               }
  //               else if(Phaese3Data.RetStatus=="Invalid Date")
  //               {
  //                 this.MileStoneDetails.get("Phase3StartDate").reset();
  //                 this.MileStoneDetails.get("Phase3EndDate").reset();
  //                 alert("Phase 3 Start Date & End Date should be with in Project Start Date & End date");
  //               }
  //               else
  //               {
  //                 alert(Phaese3Data.RetStatus);
  //               }
  //             });
  //           }
  //           else if(Phaese2Data.RetStatus=="Invalid Date")
  //           {
  //             this.MileStoneDetails.get("Phase2StartDate").reset();
  //             this.MileStoneDetails.get("Phase2EndDate").reset();
  //              alert("Phase 2 Start Date & End Date should be with in Project Start Date & End date");
  //           }
  //           else
  //           {
  //             alert(Phaese2Data.RetStatus);
  //           }

  //         });
  //       }
  //       else if(data.RetStatus=="Invalid Date")
  //       {
  //         this.MileStoneDetails.get("Phase1StartDate").reset();
  //         this.MileStoneDetails.get("Phase1EndDate").reset();
  //         alert("Phase 1 Start Date & End Date should be with in Project Start Date & End date");
  //       }      
  //       else
  //       {
  //           alert(data.RetStatus);
  //       }
  //     });


  // }




  UpdateMilestone() {
    this.Phasesubmitted = true;
    // if (!this.MileStoneDetails.valid) {

    //   return;
    // }
    var datepipe = new DatePipe('en-US');


    const formData = new FormData();

    var Phase1 = {

      FK_ProjectPhaseID: this.getProjectCaption(1),
      StartDate: datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy hh:mm:ss'),
      EndDate: datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value, 'MM/dd/yyyy hh:mm:ss'),
      PhaseStatus: this.MileStoneDetails.get("ddlPhase1Status").value
    };

    var Phase2 = {
      FK_ProjectPhaseID: this.getProjectCaption(2),
      StartDate: datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value, 'MM/dd/yyyy hh:mm:ss'),
      EndDate: datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value, 'MM/dd/yyyy hh:mm:ss'),
      PhaseStatus: this.MileStoneDetails.get("ddlPhase2Status").value
    };

    var Phase3 = {
      FK_ProjectPhaseID: this.getProjectCaption(3),
      StartDate: datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value, 'MM/dd/yyyy hh:mm:ss'),
      EndDate: datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value, 'MM/dd/yyyy hh:mm:ss'),
      PhaseStatus: this.MileStoneDetails.get("ddlPhase3Status").value
    };

    var Phase4 = {
      FK_ProjectPhaseID: this.getProjectCaption(4),
      StartDate: datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value, 'MM/dd/yyyy hh:mm:ss'),
      EndDate: datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy hh:mm:ss'),
      PhaseStatus: this.MileStoneDetails.get("ddlPhase4Status").value
    };

    this.milestoneData.push(Phase1);
    this.milestoneData.push(Phase2);
    this.milestoneData.push(Phase3);
    this.milestoneData.push(Phase4);

    formData.append("milestoneData", JSON.stringify(this.milestoneData));
    formData.append("FK_ProjectID", this.route.snapshot.queryParams['ProjectID']);

    this.DbService.UpdateprojectPhaseDates(formData).subscribe((Phaese4Data: any) => {
      if (Phaese4Data.RetStatus == "Created" || Phaese4Data.RetStatus == "Updated") {

        this.getProjectDetails(this.route.snapshot.queryParams['ProjectID']);

        if (this.ProjectPhase1List.length > 0) {

          for (let i = 0; i < this.ProjectPhase1List.length; i++) {
            this.DbService.UpdateTaskStatus(this.ProjectPhase1List[i].PK_TaskID, this.ProjectPhase1List[i].taskOrder).subscribe((data: any) => {
              if (data.value != "Success") return;
            });
          }

          this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(1), "1");

        }
        if (this.ProjectPhase2List.length > 0) {
          for (let i = 0; i < this.ProjectPhase2List.length; i++) {
            this.DbService.UpdateTaskStatus(this.ProjectPhase2List[i].PK_TaskID, this.ProjectPhase2List[i].taskOrder).subscribe((data: any) => {
              if (data.value != "Success") return;
            });
          }

          this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(2), "2");

        }
        if (this.ProjectPhase3List.length > 0) {
          for (let i = 0; i < this.ProjectPhase3List.length; i++) {
            this.DbService.UpdateTaskStatus(this.ProjectPhase3List[i].PK_TaskID, this.ProjectPhase3List[i].taskOrder).subscribe((data: any) => {
              if (data.value != "Success") return;
            });
          }

          this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(3), "3");

        }
        if (this.ProjectPhase4List.length > 0) {
          for (let i = 0; i < this.ProjectPhase4List.length; i++) {
            this.DbService.UpdateTaskStatus(this.ProjectPhase4List[i].PK_TaskID, this.ProjectPhase4List[i].taskOrder).subscribe((data: any) => {
              if (data.value != "Success") return;
            });
          }

          this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(4), "4");

        }

        alert("Saved successfully");
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'], Move: "3" } });
        //this.selectedIndex = 3;
        this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
        this.selectedIndex = 0;


      }
      else if (Phaese4Data.RetStatus == "Invalid Date") {
        this.MileStoneDetails.get("Phase4StartDate").reset();
        this.MileStoneDetails.get("Phase4EndDate").reset();
        alert("Phase 4 start date & end date should be with in project start date & end date");
      }
      else {
        alert(Phaese4Data.RetStatus);
      }
    });

  }




  getContractTypes() {
    //alert(this.CategoryId);
    this.ContractService.getContractTypes().subscribe((data: any) => {
      this.ContractType = data.value;
      this.ProjectDetails.get("Reprocurement").setValue("0");

    });
  }

  getProjectPhaseDates(ProjectID: any, PhaseType: any) {
    this.DbService.GetProjectPhaseDatesbyProjectID_PhaseID(ProjectID, PhaseType).subscribe((data: any) => {

      if (PhaseType == "1") {
        if (data.StartDate != undefined) {
          this.ShowReasonPopup = true;
        }
        this.MileStoneDetails.get("Phase1StartDate").setValue(data.StartDate);
        this.MileStoneDetails.get("Phase1EndDate").setValue(data.EndDate);
        if (data.PhaseStatus != undefined) {
          this.MileStoneDetails.get("ddlPhase1Status").setValue(data.PhaseStatus);
        }

        if (data.PhaseStatus == "Completed") {
          this.InitiationClass = "psp-card--progress--complete";
          this.InitianDone = "";
        }
        this.Phase1RiskStatus = data.ProjectRisk;
      }
      else if (PhaseType == "2") {
        console.log("data : " + data.PhaseStatus);
        this.MileStoneDetails.get("Phase2StartDate").setValue(data.StartDate);
        this.MileStoneDetails.get("Phase2EndDate").setValue(data.EndDate);
        if (data.PhaseStatus != undefined) {
          this.MileStoneDetails.get("ddlPhase2Status").setValue(data.PhaseStatus);
        }


        if (data.PhaseStatus == "Completed") {
          this.PlanningClass = "psp-card--progress--complete";
          this.PlanningDone = "";
        }
        this.Phase2RiskStatus = data.ProjectRisk;
      }
      else if (PhaseType == "3") {

        this.MileStoneDetails.get("Phase3StartDate").setValue(data.StartDate);
        this.MileStoneDetails.get("Phase3EndDate").setValue(data.EndDate);
        if (data.PhaseStatus != undefined) {
          this.MileStoneDetails.get("ddlPhase3Status").setValue(data.PhaseStatus);
        }

        if (data.PhaseStatus == "Completed") {
          this.ExecutionClass = "psp-card--progress--complete";
          this.ExecutionDone = "";
        }
        this.Phase3RiskStatus = data.ProjectRisk;
      }
      else {

        this.MileStoneDetails.get("Phase4StartDate").setValue(data.StartDate);
        this.MileStoneDetails.get("Phase4EndDate").setValue(data.EndDate);
        if (data.PhaseStatus != undefined) {
          this.MileStoneDetails.get("ddlPhase4Status").setValue(data.PhaseStatus);
        }

        if (data.PhaseStatus == "Completed") {
          this.ClosureClass = "psp-card--progress--complete";
          this.ClosureDone = "";
        }
        this.Phase4RiskStatus = data.ProjectRisk;

        //this.onChangeMilestones();
        //this.tabchangeMilestone = false;
      }
    });
  }

  projectFinanceData: any;
  getProjectFinancials(ProjectID: any) {
    this.DbService.GetProjectFinancebyProjectID(ProjectID).subscribe((data: any) => {

      if (data.length > 0) {
        data.push(
          {
            FinanceYeardisplay: 'Total',
            ProjectSpend: data.reduce((summ, v) => summ += parseInt(v.ProjectSpend), 0),
            ContractSpend: data.reduce((summ, v) => summ += parseInt(v.ContractSpend), 0),
            ProjectSavings: data.reduce((summ, v) => summ += parseInt(v.ProjectSavings), 0),
            ContractSavings: data.reduce((summ, v) => summ += parseInt(v.ContractSavings), 0),

          });
        this.projectFinanceData = data;
        this.showF = false;

      }
      this.yearlyFinancialsDataSource = new MatTableDataSource(this.projectFinanceData);
      //this.showYear =false;
    });
  }
  openAddNewProjectFinancials() {
    let addTemporary = (this.ProjectCurrentStatus.toLowerCase() == "in progress" || this.ProjectCurrentStatus.toLowerCase() == "on hold") &&
      sessionStorage.getItem("usertype").toLowerCase() == "procurement officer" ? true : false;

    addTemporary = false;
    let addBaseline = this.ProjectCurrentStatus.toLowerCase() == "not started" && !this.disableResubmitButton ? true : false;
    const dialogRef = this.dialog.open(DialogAddNewProjectfinancialsComponent, {
      data: {
        PrjID: this.route.snapshot.queryParams['ProjectID'],
        ContID: this.route.snapshot.queryParams['ContractID'] != null ? this.route.snapshot.queryParams['ContractID'] : this.DbService.Contract_ID,
        AddBaseLine: addBaseline,
        Ptype: addBaseline ? '' : 'Add',
      },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result(openAddNewProjectFinancials)");
      console.log(result);
      if (addTemporary) {
        let exist = result[0] ? result[0].hasOwnProperty('FinanceYear') : false;
        if (exist) {
          this.projectFinanceData.pop();
          this.projectFinanceData.push(result[0]);
          this.projectFinanceData.push(
            {
              FinanceYeardisplay: 'Total',
              ProjectSpend: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ProjectSpend), 0),
              ContractSpend: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ContractSpend), 0),
              ProjectSavings: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ProjectSavings), 0),
              ContractSavings: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ContractSavings), 0),

            });
          console.log(this.projectFinanceData);
          this.yearlyFinancialsDataSource = new MatTableDataSource(this.projectFinanceData);
        }
      }
      else {
        this.getProjectFinancials(this.route.snapshot.queryParams['ProjectID']);
        //this.getProjectDetails(this.route.snapshot.queryParams['ProjectID']);
        this.DbService.getContractandprojectDataby(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
          this.getContractProjectedSpend(data.ContractID);
          this.getContractAnnualSpend(data.ContractID);
          this.getContractProjectedSavings(data.ContractID);
          this.getContractAnnualSavings(data.ContractID);

        });
        if (this.AllowContractEdit) {
          this.getProjectBaseLineEstimatedContractValue(this.route.snapshot.queryParams['ProjectID']);
          this.getProjectBaseLineEstimatedContractSavings(this.route.snapshot.queryParams['ProjectID']);
        }
      }
    });

  }

  openEditProjectFinancials(year: any, spend: any, savings: any, yeardisplay: any, ProjectFinanceID: any) {
    let addTemporary = (this.ProjectCurrentStatus.toLowerCase() == "in progress" || this.ProjectCurrentStatus.toLowerCase() == "on hold") &&
      sessionStorage.getItem("usertype").toLowerCase() == "procurement officer" ? true : false;
    addTemporary = false;

    const dialogRef = this.dialog.open(DialogAddNewProjectfinancialsComponent, {
      data: {
        PrjID: this.route.snapshot.queryParams['ProjectID'],
        ContID: this.route.snapshot.queryParams['ContractID'] != null ? this.route.snapshot.queryParams['ContractID'] : this.DbService.Contract_ID,
        Ptype: 'Edit',
        Year: year,
        Spend: spend,
        Savings: savings,
        Yeardisplay: yeardisplay,
        ProjectFinanceID: ProjectFinanceID,
        // AddTemporary: addTemporary,
        EditBaseLine: this.ProjectCurrentStatus.toLowerCase() == "not started" && !this.disableResubmitButton

      },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result(openEditProjectFinancials)");
      console.log(result);
      if (addTemporary) {
        let exist = result[0] ? result[0].hasOwnProperty('FinanceYear') : false;
        if (exist) {
          this.projectFinanceData.pop();
          for (var i in this.projectFinanceData) {
            if (this.projectFinanceData[i]["FinanceYear"] == result[0].FinanceYear) {
              this.projectFinanceData[i]["ContractSpend"] = result[0].ContractSpend;
              this.projectFinanceData[i]["ContractSavings"] = result[0].ContractSavings;
              this.projectFinanceData[i]["ProjectSavings"] = result[0].ProjectSavings;
              this.projectFinanceData[i]["ProjectSpend"] = result[0].ProjectSpend;
              break;
            }
          }


          this.projectFinanceData.push(
            {
              FinanceYeardisplay: 'Total',
              ProjectSpend: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ProjectSpend), 0),
              ContractSpend: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ContractSpend), 0),
              ProjectSavings: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ProjectSavings), 0),
              ContractSavings: this.projectFinanceData.reduce((summ, v) => summ += parseInt(v.ContractSavings), 0),

            });
          console.log(this.projectFinanceData);
          this.yearlyFinancialsDataSource = new MatTableDataSource(this.projectFinanceData);
        }
      }
      else {
        this.getProjectFinancials(this.route.snapshot.queryParams['ProjectID']);
        //this.getProjectDetails(this.route.snapshot.queryParams['ProjectID']);
        this.DbService.getContractandprojectDataby(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
          this.getContractProjectedSpend(data.ContractID);
          this.getContractAnnualSpend(data.ContractID);
          this.getContractProjectedSavings(data.ContractID);
          this.getContractAnnualSavings(data.ContractID);
        });
        if (this.AllowContractEdit) {
          this.getProjectBaseLineEstimatedContractValue(this.route.snapshot.queryParams['ProjectID']);
          this.getProjectBaseLineEstimatedContractSavings(this.route.snapshot.queryParams['ProjectID']);
        }
      }
    });

  }

  getContractProjectedSpend(Contract_ID: any) {

    // alert(id);
    this.Dservice.getContractProjectedSpend(Contract_ID).subscribe((data: any) => {

      this.ProjectedContractSpend = data.value;


    });
  }
  getContractProjectedSavings(Contract_ID: any) {

    // alert(id);
    this.Dservice.getContractProjectedSavings(Contract_ID).subscribe((data: any) => {

      this.ProjectedContractSavings = data.value;


    });
  }
  getContractAnnualSpend(Contract_ID: any) {
    this.Dservice.getAnnualContractProjectedSpend(Contract_ID).subscribe((data: any) => {


      this.ProjectedAllualSpend = data.value;


    });
  }
  getContractAnnualSavings(Contract_ID: any) {
    this.Dservice.getAnnualContractProjectedSavings(Contract_ID).subscribe((data: any) => {
      this.ProjectedAnnualSavings = data.value;


    });
  }

  ChangeStatus(Status: any) {
    console.log("Task" + status);
    if (Status == "0" || this.TaskStatus == 0) {
      this.TaskStatus = 1;
    }
    else {
      this.TaskStatus = 0;
    }
    console.log(this.TaskStatus);
  }

  public onselected(event) {


    if (this.tabchange) {
      //alert(event.index);
      this.isDisabledContent = false;
      if (event.index != 0) {

        //this.selectedIndex = 0;
        //this.showSave =true;
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();

        //this.OpenDialogforDetailsChange();

        this.UpdateProject();
      }
      else {
        //this.change.detach();
        let element: HTMLElement = document.getElementById('resetButton') as HTMLElement;
        element.click();
      }
    }
    else if (this.tabchangeMilestone) {
      this.isDisabledContent = false;
      if (event.index != 2) {
        this.selectedIndex = 2;
        //this.showMilestoneActions =true;
        if (sessionStorage.getItem("usertype") == "Procurement Officer" && this.ProjectCurrentStatus &&
          (this.ProjectCurrentStatus.toLowerCase() == "in progress" || this.ProjectCurrentStatus.toLowerCase() == "on hold")) {
          this.OpenDialogforMileStoneChange();
        }
        else {
          //alert(event.index);
          this.UpdateMilestone();
        }
      }

      else {
        this.change.detach();

      }
    }
    else {

      if (event.index == 2) {

        this.onChangeMilestones();
        this.tabchangeMilestone = false;

      }


    }

  }
  UpdateTaskStatus(TaskID: any, Phase: string) {

    this.tabchangeMilestone = true;
    this.isDisabledContent = false;
    if (Phase == "1") {
      var Exists = this.ProjectPhase1List.find(x => x.PK_TaskID == TaskID);
      if (Exists != null) {
        let index = this.ProjectPhase1List.findIndex(x => x.PK_TaskID == TaskID);
        if (this.ProjectPhase1List[index].taskOrder == 0) {
          this.ProjectPhase1List[index].taskOrder = 1;
          this.ProjectPhase1List[index].status = 1;
        }
        else {
          this.ProjectPhase1List[index].taskOrder = 0;
          this.ProjectPhase1List[index].status = 0;
        }
      }
      this.phase1DataSource = new MatTableDataSource(this.ProjectPhase1List);
      //console.log(this.ProjectPhase1List);
    }
    else if (Phase == "2") {
      var Exists = this.ProjectPhase2List.find(x => x.PK_TaskID == TaskID);
      if (Exists != null) {
        let index = this.ProjectPhase2List.findIndex(x => x.PK_TaskID == TaskID);
        if (this.ProjectPhase2List[index].taskOrder == 0) {
          this.ProjectPhase2List[index].taskOrder = 1;
          this.ProjectPhase2List[index].status = 1;
        }
        else {
          this.ProjectPhase2List[index].taskOrder = 0;
          this.ProjectPhase2List[index].status = 0;
        }
      }
      this.phase2DataSource = new MatTableDataSource(this.ProjectPhase2List);
      //console.log(this.ProjectPhase2List);
    }
    else if (Phase == "3") {
      var Exists = this.ProjectPhase3List.find(x => x.PK_TaskID == TaskID);
      if (Exists != null) {
        let index = this.ProjectPhase3List.findIndex(x => x.PK_TaskID == TaskID);
        if (this.ProjectPhase3List[index].taskOrder == 0) {
          this.ProjectPhase3List[index].taskOrder = 1;
          this.ProjectPhase3List[index].status = 1;
        }
        else {
          this.ProjectPhase3List[index].taskOrder = 0;
          this.ProjectPhase3List[index].status = 0;
        }
      }
      this.phase3DataSource = new MatTableDataSource(this.ProjectPhase3List);
      //console.log(this.ProjectPhase3List);
    }
    else {
      var Exists = this.ProjectPhase4List.find(x => x.PK_TaskID == TaskID);
      if (Exists != null) {
        let index = this.ProjectPhase4List.findIndex(x => x.PK_TaskID == TaskID);
        if (this.ProjectPhase4List[index].taskOrder == 0) {
          this.ProjectPhase4List[index].taskOrder = 1;
          this.ProjectPhase4List[index].status = 1;
        }
        else {
          this.ProjectPhase4List[index].taskOrder = 0;
          this.ProjectPhase4List[index].status = 0;
        }
      }
      this.phase4DataSource = new MatTableDataSource(this.ProjectPhase4List);
      //console.log(this.ProjectPhase4List);
    }
  }

  bindGanttChart() {
    //this.BindGantt();
    this.Charttitle = "Raj";
    this.Charttype = 'Gantt';

    let testdata = [];
    let i = 0;

    this.DbService.GetTasksByProjectID(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {

      if (data.length > 0) {
        data.forEach(element => {

          testdata.push(["" + element.TaskID + "", "" + element.TaskName + "", "", new Date(element.StartDate), new Date(element.EndDate), 0, 0, null]);

        });

        this.Taskdata = testdata;
        this.Chartoptions = {
          width: 1248,
          hAxis: {
            textStyle: {
              color: '#000'
            }
          },
          bars: 'horizontal',
          axes: {
            x: {
              0: { side: 'top' }
            }
          },
          gantt: {
            palette: [{
              color: "#5e97f6",
              dark: "#2a56c6",
              light: "#c6dafc"
            }],
            criticalPathEnabled: false,
            barHeight: 15,
            trackHeight: 40,
            labelStyle: {
              fontSize: 14,
              color: '#000000'
            },
            backgroundColor: {
              fill: 'transparent',
            },

            barCornerRadius: 2,
            innerGridHorizLine: {
              stroke: '#ddd',
              strokeWidth: 1
            },
            innerGridTrack: { fill: '#ffffff' },
            innerGridDarkTrack: { fill: '#ffffff' }
          }
        };
        this.Chartheight = 650;
      }
      else {
        this.HideChartDiv = false;
        this.Taskdata = [[null, null, null, new Date(2019, 0, 1), new Date(2019, 0, 2), 0, 0, null]];
      }
    });
  }

  GetTaskStatusByProjectIDandPhase(ProjectID: any, Phase: string, PhaseFor: string) {
    this.DbService.GetTaskStatusByProjectIDandPhase(ProjectID, PhaseFor).subscribe((data: any) => {
      //console.log("Phase" + data)
      if (PhaseFor == "1") {
        this.getProjectPhaseDates(this.route.snapshot.queryParams['ProjectID'], "1");
        if (data.Percent == 100) {
          this.InitiationClass = "psp-card--progress--complete";
          this.InitianDone = "";
        }
        else if (data.Dealy == -1 && data.Percent == 0) {
          this.InitiationClass = "psp-card--progress--incomplete";
          this.InitianDone = "";
        }
        else {
          // if(data.Dealy == -1)
          // {
          //   this.InitiationClass="psp-card--progress--incomplete";
          //   this.InitianDone="";
          // } 
          let del = parseInt(data.Dealy);
          let Mile = parseInt(data.MileStoneLevel);
          if (del > Mile) {
            this.InitiationClass = "psp-card--progress--value text-color-delay-danger";
            this.Phase1Color = "Red";
          }
          else if (del > 0 && del < Mile) {
            this.InitiationClass = "psp-card--progress--value text-color-delay-warning";
            this.Phase1Color = "Ambur";
          }
          else {
            this.InitiationClass = "psp-card--progress--value text-color-delay-normal";
            this.Phase1Color = "Green";
          }
          //this.InitiationClass="psp-card--progress--value text-color-danger";
          this.InitianDone = data.Percent + "%";
        }
      }
      else if (PhaseFor == "2") {

        this.getProjectPhaseDates(this.route.snapshot.queryParams['ProjectID'], "2");
        if (data.Percent == 100) {
          this.PlanningClass = "psp-card--progress--complete";
          this.PlanningDone = "";
        }
        else if (data.Dealy == -1 && data.Percent == 0) {
          this.PlanningClass = "psp-card--progress--incomplete";
          this.PlanningDone = "";
        }
        else {

          let del = parseInt(data.Dealy);
          let Mile = parseInt(data.MileStoneLevel);

          if (del > Mile) {
            this.PlanningClass = "psp-card--progress--value text-color-delay-danger";
            this.Phase2Color = "Red";
          }
          else if (del > 0 && del < Mile) {

            this.PlanningClass = "psp-card--progress--value text-color-delay-warning";
            this.Phase2Color = "Ambur";
          }
          else {

            this.PlanningClass = "psp-card--progress--value text-color-delay-normal";
            this.Phase2Color = "Green";
          }
          //this.PlanningClass="psp-card--progress--value text-color-danger";
          this.PlanningDone = data.Percent + "%";
        }
      }
      else if (PhaseFor == "3") {

        this.getProjectPhaseDates(this.route.snapshot.queryParams['ProjectID'], "3");
        if (data.Percent == 100) {
          this.ExecutionClass = "psp-card--progress--complete";
          this.ExecutionDone = "";
        }
        else if (data.Dealy == -1 && data.Percent == 0) {
          this.ExecutionClass = "psp-card--progress--incomplete";
          this.ExecutionDone = "";
        }
        else {
          let del = parseInt(data.Dealy);
          let Mile = parseInt(data.MileStoneLevel);

          if (del > Mile) {
            this.ExecutionClass = "psp-card--progress--value text-color-delay-danger";
            this.Phase3Color = "Red";
          }
          else if (del > 0 && del < Mile) {
            this.ExecutionClass = "psp-card--progress--value text-color-delay-warning";
            this.Phase3Color = "Ambur";
          }
          else {
            this.ExecutionClass = "psp-card--progress--value text-color-delay-normal";
            this.Phase3Color = "Green";
          }

          //this.ExecutionClass="psp-card--progress--value text-color-danger";
          this.ExecutionDone = data.Percent + "%";
        }
      }
      else {

        this.getProjectPhaseDates(this.route.snapshot.queryParams['ProjectID'], "4");
        if (data.Percent == 100) {
          this.ClosureClass = "psp-card--progress--complete";
          this.ClosureDone = "";
        }
        else if (data.Dealy == -1 && data.Percent == 0) {
          this.ClosureClass = "psp-card--progress--incomplete";
          this.ClosureDone = "";
        }
        else {

          let del = parseInt(data.Dealy);
          let Mile = parseInt(data.MileStoneLevel);

          if (del > Mile) {
            this.ClosureClass = "psp-card--progress--value text-color-delay-danger";
            this.Phase4Color = "Red";
          }
          else if (del > 0 && del < Mile) {
            this.ClosureClass = "psp-card--progress--value text-color-delay-warning";
            this.Phase4Color = "Ambur";
          }
          else {
            this.ClosureClass = "psp-card--progress--value text-color-delay-normal";
            this.Phase4Color = "Green";
          }
          this.ClosureDone = data.Percent + "%";
          //this.ClosureClass="psp-card--progress--value text-color-danger";

        }
      }
    });
  }
  ValidateStartDate(Phase: string) {
    var datepipe = new DatePipe('en-US');

    if (Phase == "1") {
      this.MileStonedateChange = true;
      if (this.MileStoneDetails.get("Phase1EndDate").value != "" && this.MileStoneDetails.get("Phase1EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to end date");
          this.MileStoneDetails.get("Phase1StartDate").reset();
        }
      }
    }
    else if (Phase == "2") {
      this.MileStonedateChange = true;
      if (this.MileStoneDetails.get("Phase2EndDate").value != "" && this.MileStoneDetails.get("Phase2EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to end date");
          this.MileStoneDetails.get("Phase2StartDate").reset();
        }
      }

    }
    else if (Phase == "3") {
      this.MileStonedateChange = true;
      if (this.MileStoneDetails.get("Phase3EndDate").value != "" && this.MileStoneDetails.get("Phase3EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to end date");
          this.MileStoneDetails.get("Phase3StartDate").reset();
        }
      }
    }
    else {
      this.MileStonedateChange = true;
      if (this.MileStoneDetails.get("Phase4EndDate").value != "" && this.MileStoneDetails.get("Phase4EndDate").value != undefined) {
        if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy'))) {
          alert("Start date should be less than or equal to end date");
          this.MileStoneDetails.get("Phase4StartDate").reset();
        }
      }
    }
  }

  CheckForDates(Phase: string) {
    var datepipe = new DatePipe('en-US');
    if (Phase == "1") {
      this.MileStonedateChange = true;
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to start date");
        this.MileStoneDetails.get("Phase1EndDate").reset();
      }
    }
    else if (Phase == "2") {
      this.MileStonedateChange = true;
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to start date");
        this.MileStoneDetails.get("Phase2EndDate").reset();
      }
    }
    else if (Phase == "3") {
      this.MileStonedateChange = true;
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to start date");
        this.MileStoneDetails.get("Phase3EndDate").reset();
      }
    }
    else {
      this.MileStonedateChange = true;
      if (new Date(datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value, 'MM/dd/yyyy')) > new Date(datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy'))) {
        alert("End date should be greater than or equal to start date");
        this.MileStoneDetails.get("Phase4EndDate").reset();
      }
    }
  }

  openCompleteProjectDialog() {
    const dialogRef = this.dialog.open(DialogCompleteProjectComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'], ProjectStatus: this.ProjectCurrentStatus },
      width: '640px'

    });
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
      console.log(`Dialog result: ${result}`);
    });
  }

  openAlertBox() {
    this.DbService.UpdateProjectTimetableSubmitDate(this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
      this.DbService.UpdateProjectFinanceSubmittedDate(this.route.snapshot.queryParams['ProjectID']).subscribe((datafinance: any) => {
        let retval = "";
        if (data == "Not Found" && datafinance == "Not Found") {
          retval = "Not Found";
        }
        else {
          retval = "Updated";
        }
        const dialogRef = this.dialog.open(AlertComponent, {
          data: { RetStatus: retval },
          width: '540px'
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);

        });

      });
    });
  }
  async Binddata1() {
    this.data = await this.DbService.GetPhaseandTasksbyProjectIDForGanttChart(this.route.snapshot.queryParams['ProjectID']);
  }

  CheckforPreviousPhaseStatus(Phase: string) {
    if (Phase == "2") {
      if (this.MileStoneDetails.get("ddlPhase2Status").value == "Completed" && this.MileStoneDetails.get("ddlPhase1Status").value == "Incomplete") {
        alert("Cannot update status to completed. Because, Project initiation phase still incomplete.");
        this.MileStoneDetails.get("ddlPhase2Status").setValue("Incomplete");
      }
    }
    else if (Phase == "3") {
      if (this.MileStoneDetails.get("ddlPhase3Status").value == "Completed" && (this.MileStoneDetails.get("ddlPhase2Status").value == "Incomplete" || this.MileStoneDetails.get("ddlPhase1Status").value == "Incomplete")) {
        alert("Cannot update status to completed. Because, Project planning phase still incomplete.");
        this.MileStoneDetails.get("ddlPhase3Status").setValue("Incomplete");
      }
    }
    else {
      if (this.MileStoneDetails.get("ddlPhase4Status").value == "Completed" && (this.MileStoneDetails.get("ddlPhase3Status").value == "Incomplete" || this.MileStoneDetails.get("ddlPhase2Status").value == "Incomplete" || this.MileStoneDetails.get("ddlPhase1Status").value == "Incomplete")) {
        alert("Cannot update status to completed. Because, Project execution phase still incomplete.");
        this.MileStoneDetails.get("ddlPhase4Status").setValue("Incomplete");
      }
    }
  }

  NotificationStatusUpdate(From: string, NotifyID: number, NotificationFor: number, NotificationTo: number) {
    if (From == "FromNotification") {
      this.SysNotifications.UpdateNoticationStatus(NotifyID).subscribe((data: any) => {
        if (data.value != "Updated") alert(data.value);
      });
    }
    else {
      this.SysNotifications.UpdateNoticationStatus_FromSentID(NotificationFor, NotificationTo).subscribe((data: any) => {
        if (data.value != "Success") alert(data.value);
      });
    }
  }
  AcceptProjectApproval() {
    if (this.route.snapshot.queryParams['ViewProjectResubmit'] != null) {

      this.DbService.ApproveResubmit(this.route.snapshot.queryParams['ResubmitID'], this.route.snapshot.queryParams['ProjectID'], sessionStorage.getItem("ContactID")).subscribe((data: any) => {
        if (data.value = "success") {
          this.DbService.SendMailForProjectResubmit(this.route.snapshot.queryParams['ResubmitID'], "Approved", "ProcurementOfficer").subscribe((data: any) => {

            if (data.RetStatus = "Success") {
              if (sessionStorage.getItem("NotificationID") != null) {
                this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
              }
              else {
                this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
              }
              alert("Revised Project timetable accepted.");
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
            }
          });
        }
      });
    }
    else if (this.route.snapshot.queryParams['For'] != null) {

      this.DbService.UpdateProjectStatus(this.route.snapshot.queryParams['ProjectID'], "In Progress").subscribe((data: any) => {
        this.DbService.SendMailtoPO_AcceptorRejectProjectApproval(this.route.snapshot.queryParams['POID'], this.route.snapshot.queryParams['ProjectID'], "Accept", "", Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
          if (data.RetStatus == "Success") {
            //alert("Project approval request accepted.");
            if (sessionStorage.getItem("NotificationID") != null) {
              this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
            }
            else {
              this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
            }
            alert("Project timetable approved");
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });

          }
        });
      });

    }
    else if (this.route.snapshot.queryParams['StatusChange'] != null && this.route.snapshot.queryParams['NewStatus'] != null) {

      this.DbService.Accept_RejectProjectStatusChangeRequestto_PO(this.route.snapshot.queryParams['POID'], this.route.snapshot.queryParams['ProjectID'], this.route.snapshot.queryParams['NewStatus'], "Accept", "", Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
        if (data.RetStatus == "Success") {
          if (sessionStorage.getItem("NotificationID") != null) {
            this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
          }
          else {
            this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
          }
          alert("Project approval request accepted.");
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
        }
      });
    }
    else {

      this.DbService.UpdateProjectStatus(this.route.snapshot.queryParams['ProjectID'], "Closed").subscribe((data: any) => {
        this.DbService.SendMailtoPOforApprovedProjectRequest(this.route.snapshot.queryParams['ProjectID'], "Accept", "", Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
          if (data.RetStatus == "Success") {
            if (sessionStorage.getItem("NotificationID") != null) {
              this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
            }
            else {
              this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
            }
            alert("Project approval request accepted.");
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });

          }
        });
      });
    }
  }

  RejectProjectApproval() {
    this.openDialogRejectApproveRequest();
  }
  openDialogRejectApproveRequest() {
    const dialogRef = this.dialog.open(DialogReasonforProjectapproverejectComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result != "") {
        //added on 28/01/2020 by zuber for resubmit reject
        if (this.route.snapshot.queryParams['ViewProjectResubmit'] != null) {
          //alert(result);
          this.DbService.RejectResubmit(this.route.snapshot.queryParams['ResubmitID'], this.route.snapshot.queryParams['ProjectID'], result, sessionStorage.getItem("ContactID")).subscribe((data: any) => {

            if (data.value = "success") {
              this.DbService.SendMailForProjectResubmit(this.route.snapshot.queryParams['ResubmitID'], "Rejected", "ProcurementOfficer").subscribe((data: any) => {

                if (data.RetStatus = "Success") {
                  if (sessionStorage.getItem("NotificationID") != null) {
                    this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
                  }
                  else {
                    this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
                  }
                  alert("Revised project timetable rejected.");
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = 'reload';
                  this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
                }
              });
            }
          });
        }
        else if (this.route.snapshot.queryParams['For'] != null) {
          this.DbService.SendMailtoPO_AcceptorRejectProjectApproval(this.route.snapshot.queryParams['POID'], this.route.snapshot.queryParams['ProjectID'], "Reject", result, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
            if (data.RetStatus == "Success") {
              //alert("Project approval request rejected.");
              if (sessionStorage.getItem("NotificationID") != null) {
                this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
              }
              else {
                this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
              }
              alert("Project timetable rejected");
              this.router.navigate(['/dashboard/projects/database']);

            }
          });
        }
        else if (this.route.snapshot.queryParams['StatusChange'] != null && this.route.snapshot.queryParams['NewStatus'] != null) {
          this.DbService.Accept_RejectProjectStatusChangeRequestto_PO(this.route.snapshot.queryParams['POID'], this.route.snapshot.queryParams['ProjectID'], this.route.snapshot.queryParams['NewStatus'], "Reject", result, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
            if (data.RetStatus == "Success") {
              if (sessionStorage.getItem("NotificationID") != null) {
                this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
              }
              else {
                this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
              }
              alert("Project accept request rejected.");
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
            }
          });
        }
        else {
          this.DbService.SendMailtoPOforApprovedProjectRequest(this.route.snapshot.queryParams['ProjectID'], "Reject", result, Number(sessionStorage.getItem("ContactID"))).subscribe((data: any) => {
            if (data.RetStatus == "Success") {
              if (sessionStorage.getItem("NotificationID") != null) {
                this.NotificationStatusUpdate("FromNotification", Number(sessionStorage.getItem("NotificationID")), 0, 0);
              }
              else {
                this.NotificationStatusUpdate("FromMail", 0, Number(this.route.snapshot.queryParams['ProjectID']), Number(sessionStorage.getItem("ContactID")));
              }
              alert("Project accept request rejected.");
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
            }
            else {
              alert(data.RetStatus);
            }
          });
        }
      }
    });
  }


  SendMailtoTMforApproval() {
    if (this.disableResubmitButton) {
      alert(this.resubmitErrorMessage);

    }
    else {
      this.DbService.SendMailtoTMforProjectApproval(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['ProjectID']).subscribe((data: any) => {
        if (data.RetStatus == "Success") {
          alert("Your project timetable has been submitted for approval.");
          this.router.navigate(['dashboard/projects/database']);
        }
        else if (data.TimeTableExist) {
          alert(this.resubmitErrorMessage);
          this.disableResubmitButton = true;
        }
      });
    }
  }

  OpenDialogProjectClosure() {
    const dialogRef = this.dialog.open(DialogProjectClosureComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
      width: '740px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  //addded by zuber on 21/1/2020 - 22/01/2020
  popupWorkflow() {

    const dialogRef = this.dialog.open(DialogResubmitProjectTimetableReasonComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result == undefined) {
        this.WorkFlow2();
      }
      else {
        sessionStorage.setItem('ResubmitReason', null);
        this.Revert("MileStone");
      }

    });
  }

  WorkFlow2() {

    const dialogRef = this.dialog.open(DialogResubmitProjectTimetableContractDatesComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("WorkFlow2", result);
      if (result == undefined && sessionStorage.getItem('ResubmitContractYes') && sessionStorage.getItem('ResubmitContractYes').toString() == "no") {

        sessionStorage.setItem('ResubmitProjectFinancial', "yes");

        const slipageForm = new FormData();
        slipageForm.append("ProjectID", this.route.snapshot.queryParams['ProjectID']);
        slipageForm.append("ContractStartDate", sessionStorage.getItem('ResubmitStartDate'));
        slipageForm.append("FinancialData", "");

        // this.DbService.getSlippageValueResubmitProject(this.route.snapshot.queryParams['ProjectID'], sessionStorage.getItem('ResubmitStartDate'), "").subscribe((data: any) => {
        this.DbService.getSlippageValueResubmitProjectPost(slipageForm).subscribe((data: any) => {
          sessionStorage.setItem('ResubmitProjectSlippage', data.value);
          //  this.WorkFlow5();

          let findata = "";

          if (sessionStorage.getItem('ResubmitProjectFinancialData') != null) {
            if (sessionStorage.getItem('ResubmitProjectFinancialData') != "null") {
              findata = sessionStorage.getItem('ResubmitProjectFinancialData');
            }
          }
          //
          //

          let arr: Array<any> = this.projectFinanceData.slice(0, this.projectFinanceData.length - 1);


          for (var i in arr) {
            findata += arr[i]["FinanceYear"] + ";" + arr[i]["ProjectSpend"] + ";" + arr[i]["ProjectSavings"] + ";" + arr[i]["FinanceYeardisplay"] + "$";
          }

          this.UpdateMilestone_Resubmit(findata);
        });

      }
      else if (result == undefined) {
        this.WorkFlow3();
      }
      else {
        sessionStorage.setItem('ResubmitContractYes', null);
        sessionStorage.setItem('ResubmitStartDate', null);
        sessionStorage.setItem('ResubmitEndDate', null);
        this.popupWorkflow();
      }

    });

  }

  WorkFlow3() {

    const dialogRef = this.dialog.open(DialogResubmitProjectTimetableFinancialsComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`WorkFlow3: ${result}`);
      //alert(result);
      if (result == undefined) {
        //alert(sessionStorage.getItem("ResubmitProjectFinancial"));
        if (sessionStorage.getItem("ResubmitProjectFinancial") != null) {
          if (sessionStorage.getItem("ResubmitProjectFinancial") != "null") {
            if (sessionStorage.getItem("ResubmitProjectFinancial") != "yes") {
              console.log("this.WorkFlow5()");
              this.WorkFlow5();
            }
            else {
              sessionStorage.setItem('ResubmitProjectFinancialData', null);
              console.log("this.WorkFlow4()");
              this.WorkFlow4();
            }
          }
        }

      }
      else {
        // this.Revert("MileStone");
        sessionStorage.setItem('ResubmitProjectFinancial', null);
        this.WorkFlow2();
      }

    });

  }

  WorkFlow4() {

    const dialogRef = this.dialog.open(DialogResubmitProjectTimetableFinancialsAddNewComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == undefined) {
        //alert("4th workFlow done");
        this.WorkFlow5();
      }
      else {
        // this.Revert("MileStone");
        this.WorkFlow3();
      }

    });

  }

  WorkFlow5() {

    const dialogRef = this.dialog.open(DialogResubmitProjectTimetableImpactComponent, {
      data: { ProjectID: this.route.snapshot.queryParams['ProjectID'] },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        //alert("5th workFlow done");
        //This is the final Submit for Resubmit Project TimeTable Workflow....
        var datepipe = new DatePipe('en-US');
        let findata = "";

        if (sessionStorage.getItem('ResubmitProjectFinancialData') != null) {
          if (sessionStorage.getItem('ResubmitProjectFinancialData') != "null") {
            findata = sessionStorage.getItem('ResubmitProjectFinancialData');
          }

        }



        //
        this.UpdateMilestone_Resubmit(findata);

      }
      else {
        // this.Revert("MileStone");


        sessionStorage.setItem('ResubmitProjectFinancialData', null);
        this.WorkFlow3();

      }

    });

  }

  UpdateMilestone_Resubmit(findata: any) {

    if (this.disableResubmitButton) {
      alert(this.resubmitErrorMessage);
      return;
    }

    this.Phasesubmitted = true;
    if (!this.MileStoneDetails.valid) {
      return;
    }
    this.DbService.AddProjectNotification_Resubmit(this.route.snapshot.queryParams['ProjectID']).subscribe((result: any) => {
      if (result.TimeTableExist) {
        this.disableResubmitButton = true;
        alert(this.resubmitErrorMessage);
        return;
      }



      var datepipe = new DatePipe('en-US');

      const Phaese1formData = new FormData();
      Phaese1formData.append("FK_ProjectID", this.route.snapshot.queryParams['ProjectID']);
      Phaese1formData.append("FK_ProjectPhaseID", this.getProjectCaption(1));
      Phaese1formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
      Phaese1formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase1EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
      Phaese1formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase1Status").value);

      this.DbService.AddProjectPhases_Resubmit(Phaese1formData).subscribe((data: any) => {
        if (data.RetStatus == "Created" || data.RetStatus == "Updated") {
          const Phaese2formData = new FormData();
          Phaese2formData.append("FK_ProjectID", this.route.snapshot.queryParams['ProjectID']);
          Phaese2formData.append("FK_ProjectPhaseID", this.getProjectCaption(2));
          Phaese2formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase2StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
          Phaese2formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase2EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
          Phaese2formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase2Status").value);
          this.DbService.AddProjectPhases_Resubmit(Phaese2formData).subscribe((Phaese2Data: any) => {
            if (Phaese2Data.RetStatus == "Created" || Phaese2Data.RetStatus == "Updated") {
              const Phaese3formData = new FormData();
              Phaese3formData.append("FK_ProjectID", this.route.snapshot.queryParams['ProjectID']);
              Phaese3formData.append("FK_ProjectPhaseID", this.getProjectCaption(3));
              Phaese3formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase3StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
              Phaese3formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase3EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
              Phaese3formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase3Status").value);
              this.DbService.AddProjectPhases_Resubmit(Phaese3formData).subscribe((Phaese3Data: any) => {
                if (Phaese3Data.RetStatus == "Created" || Phaese3Data.RetStatus == "Updated") {
                  const Phaese4formData = new FormData();
                  Phaese4formData.append("FK_ProjectID", this.route.snapshot.queryParams['ProjectID']);
                  Phaese4formData.append("FK_ProjectPhaseID", this.getProjectCaption(4));
                  Phaese4formData.append("StartDate", datepipe.transform(this.MileStoneDetails.get("Phase4StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
                  Phaese4formData.append("EndDate", datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
                  Phaese4formData.append("PhaseStatus", this.MileStoneDetails.get("ddlPhase4Status").value);
                  this.DbService.AddProjectPhases_Resubmit(Phaese4formData).subscribe((Phaese4Data: any) => {
                    if (Phaese4Data.RetStatus == "Created" || Phaese4Data.RetStatus == "Updated") {

                      this.getProjectDetails(this.route.snapshot.queryParams['ProjectID']);

                      if (this.ProjectPhase1List.length > 0) {

                        for (let i = 0; i < this.ProjectPhase1List.length; i++) {
                          this.DbService.UpdateTaskStatus(this.ProjectPhase1List[i].PK_TaskID, this.ProjectPhase1List[i].taskOrder).subscribe((data: any) => {
                            if (data.value != "Success") return;
                          });
                        }

                        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(1), "1");

                      }
                      if (this.ProjectPhase2List.length > 0) {
                        for (let i = 0; i < this.ProjectPhase2List.length; i++) {
                          this.DbService.UpdateTaskStatus(this.ProjectPhase2List[i].PK_TaskID, this.ProjectPhase2List[i].taskOrder).subscribe((data: any) => {
                            if (data.value != "Success") return;
                          });
                        }

                        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(2), "2");

                      }
                      if (this.ProjectPhase3List.length > 0) {
                        for (let i = 0; i < this.ProjectPhase3List.length; i++) {
                          this.DbService.UpdateTaskStatus(this.ProjectPhase3List[i].PK_TaskID, this.ProjectPhase3List[i].taskOrder).subscribe((data: any) => {
                            if (data.value != "Success") return;
                          });
                        }
                        // this.DbService.UpdateProjectPhaseStatus(Phaese3formData).subscribe((data:any) =>
                        // {
                        //   if(data.RetStatus !="Updated") return;
                        // });
                        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(3), "3");

                      }
                      if (this.ProjectPhase4List.length > 0) {
                        for (let i = 0; i < this.ProjectPhase4List.length; i++) {
                          this.DbService.UpdateTaskStatus(this.ProjectPhase4List[i].PK_TaskID, this.ProjectPhase4List[i].taskOrder).subscribe((data: any) => {
                            if (data.value != "Success") return;
                          });
                        }
                        // this.DbService.UpdateProjectPhaseStatus(Phaese4formData).subscribe((data:any) =>
                        // {
                        //   if(data.RetStatus !="Updated") return;
                        // });
                        this.GetTaskStatusByProjectIDandPhase(this.route.snapshot.queryParams['ProjectID'], this.getProjectCaption(4), "4");

                      }

                      //alert("Saved Successfully");
                      alert("Your project is resubmitted for approval.");
                      //send Mail
                      const formData = new FormData();
                      formData.append("ProjectID", this.route.snapshot.queryParams['ProjectID']);
                      formData.append("ReasonChange", sessionStorage.getItem('ResubmitReason'));
                      formData.append("ContractYes_No", sessionStorage.getItem('ResubmitContractYes'));
                      formData.append("ContractStartDate", sessionStorage.getItem('ResubmitStartDate'));
                      formData.append("ContractEndDate", sessionStorage.getItem('ResubmitEndDate'));
                      formData.append("ProjectStartDate", datepipe.transform(this.MileStoneDetails.get("Phase1StartDate").value, 'MM/dd/yyyy hh:mm:ss'));
                      formData.append("ProjectEndDate", datepipe.transform(this.MileStoneDetails.get("Phase4EndDate").value, 'MM/dd/yyyy hh:mm:ss'));
                      formData.append("FinancialYes_No", sessionStorage.getItem('ResubmitProjectFinancial'));
                      formData.append("CreatedBy", sessionStorage.getItem("ContactID"));
                      formData.append("SlippageValue", sessionStorage.getItem('ResubmitProjectSlippage'));
                      formData.append("FinancialData", findata);
                      this.DbService.ResubmitProjectTimeTable(formData).subscribe((data: any) => {
                        //alert(data.RetStatus);
                        if (data.RetStatus == "Updated") {
                          // alert(data.ResubmitID);
                          //
                          let ResubmitID = data.ResubmitID;
                          this.DbService.SendMailForProjectResubmit(data.ResubmitID, "Sent", "ProcurementOfficer").subscribe((data: any) => {

                            if (data.RetStatus = "Success") {
                              //alert(data.RetStatus);
                              this.DbService.SendMailForProjectResubmit_TeamManager(ResubmitID, "Approve").subscribe((data: any) => {
                                if (data.RetStatus = "Success") {
                                  this.ClearResubmitSession();
                                  //alert("Mails Send Successfully !");
                                }
                              });
                            }
                          });
                        }
                        else {
                          alert("Something went wrong !");
                        }
                      });


                      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                      this.router.onSameUrlNavigation = 'reload';
                      //this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'], Move: "3" } });                      
                      //this.selectedIndex = 3;

                      this.router.navigate(['dashboard/project/details'], { queryParams: { ProjectID: this.route.snapshot.queryParams['ProjectID'] } });
                      this.selectedIndex = 0;

                    }
                    else if (Phaese4Data.RetStatus == "Invalid Date") {
                      this.MileStoneDetails.get("Phase4StartDate").reset();
                      this.MileStoneDetails.get("Phase4EndDate").reset();
                      alert("Phase 4 start date & end date should be with in project start date & end date");
                    }
                    else {
                      alert(Phaese4Data.RetStatus);
                    }
                  });
                }
                else if (Phaese3Data.RetStatus == "Invalid Date") {
                  this.MileStoneDetails.get("Phase3StartDate").reset();
                  this.MileStoneDetails.get("Phase3EndDate").reset();
                  alert("Phase 3 start date & end date should be with in project start date & end date");
                }
                else {
                  alert(Phaese3Data.RetStatus);
                }
              });
            }
            else if (Phaese2Data.RetStatus == "Invalid Date") {
              this.MileStoneDetails.get("Phase2StartDate").reset();
              this.MileStoneDetails.get("Phase2EndDate").reset();
              alert("Phase 2 start date & end date should be with in project start and end date");
            }
            else {
              alert(Phaese2Data.RetStatus);
            }

          });
        }
        else if (data.RetStatus == "Invalid Date") {
          this.MileStoneDetails.get("Phase1StartDate").reset();
          this.MileStoneDetails.get("Phase1EndDate").reset();
          alert("Phase 1 start date & end date should be with in project start date & end date");
        }
        else {
          alert(data.RetStatus);
        }
      });

    });


  }

  ClearResubmitSession() {
    sessionStorage.setItem('ResubmitProjectFinancialData', null);
    sessionStorage.setItem('ResubmitContractYes', null);
    sessionStorage.setItem('ResubmitStartDate', null);
    sessionStorage.setItem('ResubmitEndDate', null);
    sessionStorage.setItem('ResubmitReason', null);
    sessionStorage.setItem('ResubmitProjectSlippage', null);
  }

  GetProjectBaseLineData(ProjectID: any) {
    if (this.StatusPermission || this.ProjectCurrentStatus.toLowerCase() != "not started") {
      this.DbService.GetProjectBaseLineData(ProjectID).subscribe((data: any) => {
        this.projectBaselineCurrentDataSource = new MatTableDataSource(data);

      });
    }
  }
  getContractStartDate(ContractID: any) {
    this.DbService.getContractStartDate(ContractID).subscribe((data: any) => {
      this.ContractStartDate = data.value;
    });
  }
  getProjectBaseLineEstimatedContractSavings(ProjectID: any) {
    this.DbService.GetEstimatedContractSavings_Baseline(ProjectID).subscribe((data: any) => {
      //this.contractFinancialSavingsDataSource=new MatTableDataSource(data);
      if (data.length > 0) {
        data.push({ FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0) });
      }
      this.projectFinancialsCurrentContractSavingsDataSource = new MatTableDataSource(data);
    });
  }

  getProjectBaseLineEstimatedContractValue(ProjectID: any) {
    this.DbService.GetEstimatedContractValue_Baseline(ProjectID).subscribe((data: any) => {
      //this.contractFinancialSavingsDataSource=new MatTableDataSource(data);
      if (data.length > 0) {
        data.push({ FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0) });
      }
      this.projectFinancialsCurrentContractValueDataSource = new MatTableDataSource(data);
    });
  }

  GetProjectReSubmissionData(ProjectID: any) {
    this.DbService.GetProjectResubmissionData(ProjectID).subscribe((data: any) => {

      if (data.length > 0) {
        data.push({
          ProjectStage: "Total",
          Variance: data.reduce((summ, v, index, array) => summ += index > 0 ? parseInt(v.Variance) : 0, 0)
        });
        this.projectResubmissionDataSource = new MatTableDataSource(data);
        this.ResubmitDataAvailable = true;

        this.getResubmissionBaseLineEstimatedContractSavings(this.route.snapshot.queryParams['ProjectID']);
        this.getResubmissionBaseLineEstimatedContractValue(this.route.snapshot.queryParams['ProjectID']);
      }
    });
  }

  getResubmissionBaseLineEstimatedContractSavings(ProjectID: any) {
    this.DbService.GetEstimatedContractSavings_Resubmission(ProjectID).subscribe((data: any) => {
      if (data.length > 0) {
        data.push({
          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
        }

        );
      }
      this.projectResubmissionContractSavingsDataSource = new MatTableDataSource(data);
    });
  }

  getResubmissionBaseLineEstimatedContractValue(ProjectID: any) {
    this.DbService.GetEstimatedContractValue_Resubmission(ProjectID).subscribe((data: any) => {
      if (data.length > 0) {
        data.push({
          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)

        });
      }
      this.projectResubmissionContractValueDataSource = new MatTableDataSource(data);

    });
  }



  GetProjectBaseLineDataRevisionHistory(ProjectID: any, ContractID) {
    this.DbService.GetProjectBaseLineDataRevisionHistory(ProjectID, ContractID).subscribe((rhdata: any) => {

      for (let item of rhdata) {

        if (item.projectBaseLineData.length > 0) {
          item.projectBaseLineData.push({
            ProjectStage: "Total",
            Variance: item.projectBaseLineData.reduce((summ, v, index, array) => summ += index > 0 ? parseInt(v.Variance) : 0, 0)
          });

          if (item.contractSavingsDataSource.length > 0) {
            item.contractSavingsDataSource.push({
              FinancialYearDisplay: 'Total', Financevalue: item.contractSavingsDataSource.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
              Savings: item.contractSavingsDataSource.reduce((summ, v) => summ += parseInt(v.Savings), 0)
            });
          }

          if (item.contractValueDataSource.length > 0) {
            item.contractValueDataSource.push(
              {
                FinancialYearDisplay: 'Total', Financevalue: item.contractValueDataSource.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
                Savings: item.contractValueDataSource.reduce((summ, v) => summ += parseInt(v.Savings), 0)
              }
            );
          }

          var listdata = {
            projectBaselineCurrentDataSource: new MatTableDataSource(item.projectBaseLineData),
            ProjectCreatedDate: item.projectBaseLineData[0].Modified,
            ProjectApprovedDate: item.projectBaseLineData[0].Modified,
            reson: item.reasonChange,
            ContractStartDate: item.ContractStartDate.ContractStartDate,
            ContractStartDateVariance: item.ContractStartDate.Variance,
            projectFinancialsCurrentContractValueDataSource: new MatTableDataSource(item.contractValueDataSource),
            projectFinancialsCurrentContractSavingsDataSource: new MatTableDataSource(item.contractSavingsDataSource),
          };
          this.RevisionHistoryList.push(listdata);
        }
      }

    });
  }





  //GetResubmitReasonByProjectAndReason(Version) {
  //  this.DbService.GetResubmitReasonByProjectAndReason(this.route.snapshot.queryParams['ProjectID'], Version).subscribe((result: any) => {
  //    if (result) {
  //      switch (Version) {
  //        case 2:
  //          this.reson1 = result.ReasonChange;
  //          break;
  //        case 3:
  //          this.reson2 = result.ReasonChange;
  //          break;
  //        case 4:
  //          this.reson3 = result.ReasonChange;
  //          break;
  //        case 5:
  //          this.reson4 = result.ReasonChange;
  //          break;
  //      }
  //      //
  //    }
  //  });
  //}

  //GetProjectBaseLineData1(ProjectID: any, Version: number) {
  //  this.DbService.GetProjectBaseLineData1(ProjectID, Version).subscribe((data: any) => {
  //    if (Version == 2) {


  //      if (data.length > 0) {
  //        data.push({
  //          ProjectStage: "Total",
  //          Variance: data.reduce((summ, v, index, array) => summ += index > 0 ? parseInt(v.Variance) : 0, 0)
  //        });

  //        this.projectBaselineCurrentDataSource1 = new MatTableDataSource(data);
  //        this.ProjectCreatedDate1 = data[0].Modified;
  //        this.ProjectApprovedDate1 = data[0].Modified;
  //        this.RevisionHistory = true;

  //        this.getProjectBaseLineEstimatedContractSavings1(this.route.snapshot.queryParams['ProjectID'], 2);
  //        this.getProjectBaseLineEstimatedContractValue1(this.route.snapshot.queryParams['ProjectID'], 2);

  //        this.GetResubmitReasonByProjectAndReason(2);

  //      }


  //    }
  //    else if (Version == 3) {

  //      if (data.length > 0) {
  //        data.push({
  //          ProjectStage: "Total",
  //          Variance: data.reduce((summ, v, index, array) => summ += index > 0 ? parseInt(v.Variance) : 0, 0)
  //        });
  //        this.projectBaselineCurrentDataSource2 = new MatTableDataSource(data);

  //        this.ProjectCreatedDate2 = data[0].Modified;
  //        this.ProjectApprovedDate2 = data[0].Modified;
  //        this.RevisionHistory2 = true;
  //        this.getProjectBaseLineEstimatedContractSavings1(this.route.snapshot.queryParams['ProjectID'], 3);
  //        this.getProjectBaseLineEstimatedContractValue1(this.route.snapshot.queryParams['ProjectID'], 3);
  //        this.GetResubmitReasonByProjectAndReason(3);
  //      }

  //    }
  //    else if (Version == 4) {

  //      if (data.length > 0) {
  //        data.push({
  //          ProjectStage: "Total",
  //          Variance: data.reduce((summ, v, index, array) => summ += index > 0 ? parseInt(v.Variance) : 0, 0)
  //        });
  //        this.projectBaselineCurrentDataSource3 = new MatTableDataSource(data);

  //        this.ProjectCreatedDate3 = data[0].Modified;
  //        this.ProjectApprovedDate3 = data[0].Modified;
  //        this.RevisionHistory3 = true;
  //        this.getProjectBaseLineEstimatedContractSavings1(this.route.snapshot.queryParams['ProjectID'], 4);
  //        this.getProjectBaseLineEstimatedContractValue1(this.route.snapshot.queryParams['ProjectID'], 4);
  //        this.GetResubmitReasonByProjectAndReason(4);
  //      }

  //    }
  //    else {
  //      if (data.length > 0) {
  //        data.push({
  //          ProjectStage: "Total",
  //          Variance: data.reduce((summ, v, index, array) => summ += index > 0 ? parseInt(v.Variance) : 0, 0)
  //        });
  //        this.projectBaselineCurrentDataSource4 = new MatTableDataSource(data);

  //        this.ProjectCreatedDate4 = data[0].Modified;
  //        this.ProjectApprovedDate4 = data[0].Modified;
  //        this.RevisionHistory4 = true;
  //        this.getProjectBaseLineEstimatedContractSavings1(this.route.snapshot.queryParams['ProjectID'], 5);
  //        this.getProjectBaseLineEstimatedContractValue1(this.route.snapshot.queryParams['ProjectID'], 5);
  //        this.GetResubmitReasonByProjectAndReason(5);
  //      }
  //    }
  //  });
  //}

  //getContractStartDate1(ContractID: any, ProjectID: number, Version: number) {
  //  this.DbService.getContractStartDate1(ContractID, ProjectID, Version).subscribe((data: any) => {
  //    if (Version == 2) {
  //      this.ContractStartDate1 = data.ContractStartDate;
  //      this.ContractStartDateVariance1 = data.Variance;
  //    }
  //    else if (Version == 3) {
  //      this.ContractStartDate2 = data.ContractStartDate;
  //      this.ContractStartDateVariance2 = data.Variance;
  //    }
  //    else if (Version == 4) {
  //      this.ContractStartDate3 = data.ContractStartDate;
  //      this.ContractStartDateVariance3 = data.Variance;
  //    }
  //    else {
  //      this.ContractStartDate4 = data.ContractStartDate;
  //      this.ContractStartDateVariance4 = data.Variance;
  //    }
  //  });
  //}

  //getProjectBaseLineEstimatedContractSavings1(ProjectID: any, Version: number) {
  //  this.DbService.GetEstimatedContractSavings_Baseline1(ProjectID, Version).subscribe((data: any) => {
  //    if (Version == 2) {
  //      if (data.length > 0) {
  //        data.push({
  //          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //        });
  //      }
  //      this.projectFinancialsCurrentContractSavingsDataSource1 = new MatTableDataSource(data);
  //    }
  //    else if (Version == 3) {
  //      if (data.length > 0) {
  //        data.push({
  //          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //        });
  //      }
  //      this.projectFinancialsCurrentContractSavingsDataSource2 = new MatTableDataSource(data);
  //    }
  //    else if (Version == 4) {
  //      if (data.length > 0) {
  //        data.push({
  //          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //        });
  //      }
  //      this.projectFinancialsCurrentContractSavingsDataSource3 = new MatTableDataSource(data);
  //    }
  //    else {
  //      if (data.length > 0) {
  //        data.push({
  //          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //        });
  //      }
  //      this.projectFinancialsCurrentContractSavingsDataSource4 = new MatTableDataSource(data);
  //    }

  //  });
  //}

  //getProjectBaseLineEstimatedContractValue1(ProjectID: any, Version: number) {
  //  this.DbService.GetEstimatedContractValue_Baseline1(ProjectID, Version).subscribe((data: any) => {
  //    if (Version == 2) {
  //      if (data.length > 0) {
  //        data.push(
  //          {
  //            FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //            Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //          }
  //        );
  //      }
  //      this.projectFinancialsCurrentContractValueDataSource1 = new MatTableDataSource(data);
  //    }
  //    else if (Version == 3) {
  //      if (data.length > 0) {
  //        data.push({
  //          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //        });
  //      }
  //      this.projectFinancialsCurrentContractValueDataSource2 = new MatTableDataSource(data);
  //    }
  //    else if (Version == 4) {
  //      if (data.length > 0) {
  //        data.push({
  //          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //        });
  //      }
  //      this.projectFinancialsCurrentContractValueDataSource3 = new MatTableDataSource(data);
  //    }
  //    else {
  //      if (data.length > 0) {
  //        data.push({
  //          FinancialYearDisplay: 'Total', Financevalue: data.reduce((summ, v) => summ += parseInt(v.Financevalue), 0),
  //          Savings: data.reduce((summ, v) => summ += parseInt(v.Savings), 0)
  //        });
  //      }
  //      this.projectFinancialsCurrentContractValueDataSource4 = new MatTableDataSource(data);
  //    }

  //  });
  //}


  LoadAllCaptions() {
    this.setService.getCaptionFromDB("dashboard-project-details").subscribe((data: any) => {
      this.Names = data;
    });
    this.setService.getProjectPhaseLabelFromDB().subscribe((data: any) => {
      this.ProjectMSList = data;
    });
    this.setService.getTooltipFromDB("dashboard-project-details").subscribe((data: any) => {
      this.Tooltips = data;
    });
  }


  getCaption(sCaption: string) {
    var sResult = sCaption;
    if (this.Names != null) {
      this.Names.forEach(element => {
        if (element.OriginalName === sCaption) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }

  getProjectCaption(projectID: any) {
    var sResult = "";
    if (this.ProjectMSList != null) {
      this.ProjectMSList.forEach(element => {
        if (element.Id === projectID) {
          sResult = element.ProjectPhase_Type;
          return false;
        }

      });
    }
    return sResult;
  }

  getProjectTooltip(sTooltip: string) {
    var sResult = "";
    if (this.Tooltips != null) {
      this.Tooltips.forEach(element => {
        if (element.OriginalName === sTooltip) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }

  isLLAEnabled() {
    this.setService.enableLondonLivingAllowance().subscribe((data: any) => {
      this.bLLAEnabled = (data.value == "yes")
    });

  }

  UseSimpleSubCategory() {
    this.setService.isSimpleSubCategory().subscribe((data: any) => {
      this.bUseSimpleSubCategory = (data.value == "yes")
    });

  }

  onCategorySelected(value: string) {
    this.DbService.getSubCategorybyCat(Number(value)).subscribe((data: any) => {
      if (this.SubCategorySelected == null)
        this.SubCategorySelected = data[0].PK_CategoryTypeID;
      this.SubCategory = data;
    });

    //console.log("the selected value is " + value);
  }

  saveProjectOverview() {
    this.UpdateProject();
  }

  cancelProjectOverview() {
    this.Revert("Details");
  }

  saveMileStone() {
    if (this.tabchangeMilestone && sessionStorage.getItem("usertype") == "Procurement Officer" && this.ProjectCurrentStatus &&
      (this.ProjectCurrentStatus.toLowerCase() == "in progress" || this.ProjectCurrentStatus.toLowerCase() == "on hold")) {
      this.OpenDialogforMileStoneChange();
    }
    else if (this.tabchangeMilestone) {
      this.UpdateMilestone();
    }
    this.tabchangeMilestone = false;
  }

  cancelMileStone() {
    this.Revert('MileStone');
  }

  routeToHome() {
    if (!this.tabchange && !this.tabchangeMilestone) {
      this.router.navigate(["/dashboard/performance"]);
    }
  }

  routeToProjects() {
    if (!this.tabchange && !this.tabchangeMilestone) {
      this.router.navigate(["/dashboard/projects"]);
    }
  }

  routeToDatabase() {
    if (!this.tabchange && !this.tabchangeMilestone) {
      this.router.navigate(["/dashboard/projects/database"]);
    }
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    //alert(val);
    if (val.length > 1) {
      this.getSubCategories(val);
    }
  }

  onFocused(e) {
    // do something when input is focused
  }

  getSubCategories(searchtext: any) {
    //alert(this.CategoryId);
    //this.Dservice.getSubCategory(this.newcontract.get("ddlCategory").value).subscribe((data:any) =>
    this.Dservice.getAllSubCategories_Search(searchtext).subscribe((data: any) => {
      //console.log(data);
      this.SubCategory = data;
      //this.newcontract.get("ddlSubCategory").setValue(data[0].PK_CategoryTypeID);
      //this.onChanges();
    });


  }

  ClearSubCategorySelection(e) {
    this.SubCategorySelected = "";
  }

  selectEvent(item) {
    // do something with selected item
    //alert(item.PK_CategoryTypeID);
    this.SubCategorySelected = item.PK_CategoryTypeID;
    //this.NewProject.get("SubCategory").setValue(item.PK_CategoryTypeID);
    //

    this.SubCategory = null;
  }

}

