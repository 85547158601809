import { Component, OnInit,Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {DashboardPerformanceService} from '../../dashboard/dashboard-performance/dashboard-performance.service';
import {DashboardContractDetailsService} from '../../dashboard/dashboard-contract-details/dashboard-contract-details.service'
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-dialog-add-new-contractfinance',
  templateUrl: './dialog-add-new-contractfinance.component.html',
  styleUrls: ['./dialog-add-new-contractfinance.component.scss']
})
export class DialogAddNewContractfinanceComponent implements OnInit { 
  
  AddProjectFinance:FormGroup;
  submitted:boolean=false;
  FinancSelectedText :any;
  FinancialYears : any;
  constructor(private Cservice: DashboardPerformanceService,private Dservice :DashboardContractDetailsService, private fb:FormBuilder,public dialogRef: MatDialogRef<DialogAddNewContractfinanceComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.AddProjectFinance=this.fb.group({
      FinancialYear:['',Validators.required],
      ProjectedSpend:['',Validators.required],
      ProjectedSavings:['',Validators.required]
    });

    this.GetFinancialYear();
  }

  get f() { return this.AddProjectFinance.controls };

  SelectedText(event)
  {
    this.FinancSelectedText=event.target.options[event.target.options.selectedIndex].text;

    //console.log(event.target.options[event.target.options.selectedIndex].text);
    
  }

  GetFinancialYear()
  {
    this.Cservice.GetFinancialYear().subscribe((data:any) =>{
        this.FinancialYears=data.value;
        
      });
  }


  AddFinanceData()
  {
    this.submitted=true;
    if (!this.AddProjectFinance.valid) {
      return;
      }
      const formData=new FormData();
      //alert(this.AddProjectFinance.get("FinancialYear").value);
     // alert(this.AddProjectFinance.get("ProjectedSpend").value);
      //alert(this.AddProjectFinance.get("ProjectedSavings").value);
      formData.append("ContractID",this.data.ContractID);
      formData.append("FinanceYear",this.AddProjectFinance.get("FinancialYear").value);
      formData.append("FinanceYearDisplay",this.FinancSelectedText);
      formData.append("ActualSpend",this.AddProjectFinance.get("ProjectedSpend").value);
      formData.append("ActualSavings",this.AddProjectFinance.get("ProjectedSavings").value);
      formData.append("CreatedBy","");
      this.Dservice.SaveContractFinance(formData).subscribe((data:any) =>
    {
        //alert(data);
        if(data =="success")
        {
          //this.router.navigate(['dashboard/contracts']);
          alert("Updated Successfully !");
          this.AddProjectFinance.reset();
          this.dialogRef.close();
          
        }
        else{
             alert("Something went wrong !");
        
        }
    });
  }
}
