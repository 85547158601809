import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import {CreateteamService} from './createteam.service';
import { Router, ActivatedRoute} from '@angular/router'; 
import {LoginService} from '../login/login.service';

@Component({
  selector: 'app-createteam',
  templateUrl: './createteam.component.html',
  styleUrls: ['./createteam.component.css']
})
export class CreateteamComponent implements OnInit {

  CreateTeam:FormGroup;
  //OrgID:any;
  //public LoginID:any;
  constructor(private fb:FormBuilder,private tService: CreateteamService,private router:Router,private route:ActivatedRoute,private lService: LoginService) {
    if(sessionStorage.getItem("ContactID") !=null)
     {
      //this.LoginID=sessionStorage.getItem("ContactID");
        // this.lService.getContactUser(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
        // {
        //     this.OrgID=data.OrganisationID;
        // });
     }
     else
     {
      if(this.route.snapshot.queryParamMap.get('valid') !="true")
      {
        this.router.navigate(["/login"]);
      }
      // if(this.route.snapshot.queryParamMap.get('valid') =="true" && this.route.snapshot.queryParamMap.get('OrgID') !=null)
      // {
      //   this.OrgID=this.route.snapshot.queryParamMap.get('OrgID');
      // }
      // else
      // {
      //   this.router.navigate(["/login"]);
      // }
     }
   }

  ngOnInit() {
    this.CreateTeam=this.fb.group({
      teamFormControl:['',Validators.required]
    });
  }

  Team()
  {
      if(!this.CreateTeam.valid)
      {
        return;
      }
      const formData=new FormData();
      formData.append("Team_Name",this.CreateTeam.get("teamFormControl").value);
      //formData.append("OrganisationID",this.OrgID);
      //formData.append("ContactID",this.LoginID);
      localStorage.setItem("team",JSON.stringify(this.CreateTeam.value));

      this.tService.TeamNameExists(this.CreateTeam.get("teamFormControl").value).subscribe((data:any)=>
      {
          if(data.value=="Exists")
          {
            alert("Team name already exists.");
            this.CreateTeam.get("teamFormControl").reset();
          }
          else
          {
            this.router.navigate(['/inviteuser'], { queryParams: {valid: 'true'}});
          }
      });
      // this.tService.createTeam(formData).subscribe((data:any) =>
      // {
      //   console.log(data);
      //     if(data.RetStatus =="Success")
      //     {
            
      //       this.router.navigate(['/inviteuser'], { queryParams: {Val: data.TeamID}});
      //     }
      //     else if(data.RetStatus =="Team Name already exists.")
      //     {
      //       alert("Team Name already exists.");
      //       this.CreateTeam.get("teamFormControl").reset();
      //     }
      //     else
      //     {
      //       alert(data.RetStatus);
      //       this.CreateTeam.get("teamFormControl").reset();
      //     }
      // });
  }
}
