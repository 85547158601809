import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboradContractsService } from './dashborad-contracts.service'
import { ProjectDatabaseService } from '../project-database.service';
import { LoginService } from '../../login/login.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogExtendContractComponent } from '../../dialogs/dialog-extend-contract/dialog-extend-contract.component';
import { DialogReprocureContractComponent } from '../../dialogs/dialog-reprocure-contract/dialog-reprocure-contract.component';
import { DialogAdvanceSearchcontractComponent } from '../../dialogs/dialog-advance-searchcontract/dialog-advance-searchcontract.component';
import { SelectionModel } from '@angular/cdk/collections';
import { SettingsService } from '../../settings/settings.service';
import { AlertComponent } from '../../alert/alert.component';
import { AccountSetupWizardService } from 'src/app/account-setup-wizard/account-setup-wizard.service';

@Component({
  selector: 'app-dashboard-contracts',
  templateUrl: './dashboard-contracts.component.html',
  styleUrls: ['./dashboard-contracts.component.css']
})
export class DashboardContractsComponent implements OnInit {

  displayedColumns: string[] = ['Organisation_Name', 'ContractTitle', 'type', 'ContractStartDate', 'ContractEndDate', 'category', 'subCategory', 'Reference', 'status', 'contractOwnerFirstName', 'contractOwnerSurname', 'contractOwnerEmail', 'Priority', 'extensionAvailable', 'extensionPeriod', 'projectedContractLifetimeSaving','projectedLifetimeContractSpend'];
  //displayedColumns: string[] = ['Organisation_Name', 'ContractTitle','type','ContractStartDate', 'ContractEndDate','category','subCategory','Reference','status','contractOwnerFirstName','contractOwnerSurname','contractOwnerEmail','projectedLifetimeContractSpend','projectedAnnualisedContractSpend','projectedContractLifetimeSaving','projectedAnnualisedLifetimeSaving','Priority','extensionAvailable','extensionPeriod'];
  displayedColumnsNotLive: string[] = ['Selection', 'Organisation_Name', 'ContractTitle', 'ContractStartDate', 'ContractEndDate', 'category', 'subCategory', 'Reference', 'status', 'contractOwnerFirstName', 'contractOwnerSurname', 'contractOwnerEmail', 'Priority', 'extensionAvailable', 'extensionPeriod', 'projectedContractLifetimeSaving', 'projectedLifetimeContractSpend'];

  contractsDatabaseDataSource:any;
  contractsDatabaseNotLiveDataSource:any;
  // ShowButton:boolean=true;
  UploadedContracts: boolean = false;
  NotLiveRecords: boolean = false;
  LiveRecords: boolean = true;

  selection = new SelectionModel(true, []);

  OrganisationID: any;
  public searchdata: any = [];
  ShowButtonR: boolean = true;
  ShowButtonE: boolean = true;

  DisableButtonR: boolean = false;
  DisableButtonE: boolean = false;

  public loading = false;
  ShowWizardMenu: boolean = false;

  Names: any;
  //@ViewChild(MatSort, {static: true}) sort: MatSort;
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('one', { read: MatSort, static: true }) sort: MatSort;
  @ViewChild('onepage', { read: MatPaginator, static: true }) paginator: MatPaginator;


  @ViewChild('publish', { read: MatSort, static: true }) publishDataSort: MatSort;
  @ViewChild('publishpage', { read: MatPaginator, static: true }) publishDataPaginator: MatPaginator;

  constructor(private router: Router, private route: ActivatedRoute, private Cservice: DashboradContractsService,
    private PDbService: ProjectDatabaseService, public dialog: MatDialog,
    private lService: LoginService, private setService: SettingsService, private wizard: AccountSetupWizardService) {
    this.LoadAllCaptions();


    if (sessionStorage.getItem("ContactID") != null) {
    
      this.router = router;
      this.WizardHideorShow();
      if (this.route.snapshot.queryParams['advancesearch'] != null) {
        let item = JSON.parse(sessionStorage.getItem('Item1'));
        //console.log(item);
        this.contractsDatabaseDataSource = new MatTableDataSource(item);
        this.contractsDatabaseDataSource.sort = this.sort;
        this.contractsDatabaseDataSource.paginator = this.paginator;
        return;
      }
      else if (this.route.snapshot.queryParams['Year'] != null) {
        if (this.route.snapshot.queryParams['Year'] == "Lifetime") {
          if (this.route.snapshot.queryParams['OrganisationID'] != "0") {
            if (this.route.snapshot.queryParams['TeamID'] != "0") {
              this.Cservice.GetAllContractListByLifetime_Org_Team(this.route.snapshot.queryParams['OrganisationID'], this.route.snapshot.queryParams['TeamID']).subscribe((data: any) => {
                console.log(data);
                this.contractsDatabaseDataSource = new MatTableDataSource(data);
                this.contractsDatabaseDataSource.sort = this.sort;
                this.contractsDatabaseDataSource.paginator = this.paginator;

              });
            }
            else if (this.route.snapshot.queryParams['Owner'] != "0") {
              this.Cservice.GetAllContractListByLifetime_Org_Owner(this.route.snapshot.queryParams['OrganisationID'], this.route.snapshot.queryParams['Owner']).subscribe((data: any) => {
                console.log(data);
                this.contractsDatabaseDataSource = new MatTableDataSource(data);
                this.contractsDatabaseDataSource.sort = this.sort;
                this.contractsDatabaseDataSource.paginator = this.paginator;

              });
            }
            else {

              this.Cservice.GetAllContractListByLifetime_Org(this.route.snapshot.queryParams['OrganisationID']).subscribe((data: any) => {
                console.log(data);
                this.contractsDatabaseDataSource = new MatTableDataSource(data);
                this.contractsDatabaseDataSource.sort = this.sort;
                this.contractsDatabaseDataSource.paginator = this.paginator;

              });
            }
          }
          else {
            if (this.route.snapshot.queryParams['Owner'] != "0") {
              this.Cservice.GetAllContractListByLifetime_Owner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['Owner']).subscribe((data: any) => {

                console.log(data);
                this.contractsDatabaseDataSource = new MatTableDataSource(data);
                this.contractsDatabaseDataSource.sort = this.sort;
                this.contractsDatabaseDataSource.paginator = this.paginator;

              });

            } else {
              this.getAllContracts();
            }
          }

        }
        else if (this.route.snapshot.queryParams['OrganisationID'] != "0") {
          if (this.route.snapshot.queryParams['TeamID'] != "0") {
            this.Cservice.GetAllContractListByFinanceYear_Org_Team(this.route.snapshot.queryParams['OrganisationID'], this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParams['TeamID']).subscribe((data: any) => {
              console.log(data);
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;

            });
          }
          else if (this.route.snapshot.queryParams['Owner'] != "0") {
            this.Cservice.GetAllContractListByFinanceYear_Org_Owner(this.route.snapshot.queryParams['OrganisationID'], this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParams['Owner']).subscribe((data: any) => {
              console.log(data);
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;

            });
          }
          else {
            this.Cservice.GetAllContractListByFinanceYear_Org(this.route.snapshot.queryParams['OrganisationID'], this.route.snapshot.queryParams['Year']).subscribe((data: any) => {
              console.log(data);
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;

            });
          }
        }
        else {
          if (this.route.snapshot.queryParams['Owner'] != "0") {
            this.Cservice.GetAllContractListByFinanceYear_Owner(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['Year'], this.route.snapshot.queryParams['Owner']).subscribe((data: any) => {
              console.log(data);
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;

            });

          } else {
            this.Cservice.GetAllContractListByFinanceYear(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['Year']).subscribe((data: any) => {
              console.log(data);
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;

            });
          }
        }

      }
      else if (this.route.snapshot.queryParams['OrganisationID'] != null) {
        if (this.route.snapshot.queryParams['ContractType'] != null) {
          if (this.route.snapshot.queryParams['Month'] != null) {
            if (this.route.snapshot.queryParams['OrganisationID'] == "0") {

              this.getExpiryContract(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['Month']);
            }
            else {

              this.getExpiryContractByOrg(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['Month']);
            }
          }
          else if (this.route.snapshot.queryParams['Category'] != null) {
            if (this.route.snapshot.queryParams['OrganisationID'] == "0") {

              this.getCategoryContract(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['Category']);
            }
            else {

              this.getCategoryContractByOrg(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['Category']);
            }
          }
          else if (this.route.snapshot.queryParams['ContractValue'] != null) {
            if (this.route.snapshot.queryParams['OrganisationID'] == "0") {

              this.getProcureContract(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['ContractValue']);
            }
            else {

              this.getProcureContractByOrg(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['ContractValue']);
            }
          }
          else if (this.route.snapshot.queryParams['Priority'] != null) {
            if (this.route.snapshot.queryParams['OrganisationID'] == "0") {

              this.getPriorityContract(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['Priority']);
            }
            else {

              this.getPriorityContractByOrg(this.route.snapshot.queryParams['ContractType'], this.route.snapshot.queryParams['Priority']);
            }
          }
          else {
            if (this.route.snapshot.queryParams['OrganisationID'] == "0") {

              this.getContractsByType(this.route.snapshot.queryParams['ContractType']);
            }
            else {

              this.getContractsByOrg_Type(this.route.snapshot.queryParams['ContractType']);
            }
          }
        }
        else {
          //if (this.route.snapshot.queryParams['OrganisationID'] != "0") {

        //  this.getAllContractsByOrg();
          this.getActiveContractsByContact();
         // }
         // else {

         //   this.getAllContracts();
        //  }
        }
      }
      else if (this.route.snapshot.queryParams['Type'] != null) {
        // this.ShowButton=true;
        //this.getContractsbyType(this.route.snapshot.queryParams['Type']);

      }
      else {


        // if(sessionStorage.getItem("usertype") =="Head of Procurement")
        // {

        this.getAllContracts();
        //  }
        /* else{
           this.Cservice.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
           {
             this.loading =true;
             this.OrganisationID = data[0].OrganisationID;
           this.Cservice.getContractsByOrganisationID(data[0].OrganisationID).subscribe((data:any) =>
              {
                 console.log(data);
                  this.contractsDatabaseDataSource= new MatTableDataSource(data);
                  this.contractsDatabaseDataSource.sort = this.sort;
                  this.loading =false;
             },(error) =>{
               this.loading=false;
             });
           });
         }*/

      }
      this.getNonPublishedContracts();
    }
    else {
      this.router.navigate(["/login"]);
    }

  }


  ngOnInit() {
    //this.contractsDatabaseDataSource= new MatTableDataSource(CONTRACT_DATABASE_DATA);
    //this.contractsDatabaseDataSource.sort = this.sort;
    //console.log("Hi there");
  }

  getAllContracts() {
    this.loading = true;
    this.Cservice.getContractsByContactID(sessionStorage.getItem("ContactID")).subscribe((data: any) => {
     
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
    
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  getContractsByType(ContractType: any) {
    this.Cservice.getContractsByType(sessionStorage.getItem("ContactID"), ContractType).subscribe((data: any) => {
      //console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getContractsByOrg_Type(ContractType: any) {
    this.Cservice.getContractsByOrg_Type(this.route.snapshot.queryParams['OrganisationID'], ContractType).subscribe((data: any) => {
      console.log("data" + data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  //
  getExpiryContract(ContractType: any, Month: any) {
    this.Cservice.getContractsExpiryByType(sessionStorage.getItem("ContactID"), ContractType, Month).subscribe((data: any) => {
      //console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  //
  getPriorityContract(ContractType: any, Priority: any) {
    this.Cservice.getContractsPriorityByType(sessionStorage.getItem("ContactID"), ContractType, Priority).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }
  //
  getProcureContract(ContractType: any, Value: any) {
    this.Cservice.getContractsProcureByType(sessionStorage.getItem("ContactID"), ContractType, Value).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }
  //
  //
  getCategoryContract(ContractType: any, Category: any) {
    //this.Cservice.getContractsCategoryByType(sessionStorage.getItem("ContactID"),ContractType,Category).subscribe((data:any) =>
    this.Cservice.getContractsCategoryByType_ForDrillDown(sessionStorage.getItem("ContactID"), ContractType, Category).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }
  //
  getExpiryContractByOrg(ContractType: any, Month: any) {
    this.Cservice.getContractsExpiryByOrg_Type(this.route.snapshot.queryParams['OrganisationID'], ContractType, Month).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  //
  getPriorityContractByOrg(ContractType: any, Priority: any) {
    this.Cservice.getContractsPriorityByOrg_Type(this.route.snapshot.queryParams['OrganisationID'], ContractType, Priority).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  //
  getProcureContractByOrg(ContractType: any, Value: any) {
    this.Cservice.getContractsProcureByOrg_Type(this.route.snapshot.queryParams['OrganisationID'], ContractType, Value).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  //
  getCategoryContractByOrg(ContractType: any, Category: any) {
    //this.Cservice.getContractsCategoryByOrg_Type(this.route.snapshot.queryParams['OrganisationID'],ContractType,Category).subscribe((data:any) =>
    this.Cservice.getContractsCategoryByOrg_Type_ForDrillDown(this.route.snapshot.queryParams['OrganisationID'], ContractType, Category).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getAllContractsByOrg() {
    this.Cservice.getContractsByOrganisationID(this.route.snapshot.queryParams['OrganisationID']).subscribe((data: any) => {
      console.log(data);
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  getActiveContractsByContact() {
    this.Cservice.getActiveContractsByContact(sessionStorage.getItem("ContactID"), this.route.snapshot.queryParams['OrganisationID']).subscribe((data: any) => {
      
      this.contractsDatabaseDataSource = new MatTableDataSource(data);
      this.contractsDatabaseDataSource.sort = this.sort;
      this.contractsDatabaseDataSource.paginator = this.paginator;
    });
  }

  showContractDetails(Id: any) {

    if (this.route.snapshot.queryParams['Type'] == null) {
      this.Cservice.GetSubCategoryForContract(Id).subscribe((data: any) => {
        //alert(data.value);
        sessionStorage.setItem('subcat', data.value);
        this.router.navigate(['dashboard/contract/details'], { queryParams: { ContractID: Id } });

      });
    }

    //ContacID
    // if(this.route.snapshot.queryParams['Type'] ==null)
    // {
    //   this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: Id}});
    // }
    //this.router.navigateByUrl('dashboard/contract/details');
  }

  searchText(title: string) {
    if (title != "") {
      if (this.route.snapshot.queryParams['OrganisationID'] != null) {
        if (this.route.snapshot.queryParams['OrganisationID'] != "0") {
          const formdata = new FormData();
          formdata.append("ContractTitle", title);
          formdata.append("OrganisationID", this.route.snapshot.queryParams['OrganisationID']);
          this.Cservice.SearchContractbyTitleandOrgID(formdata).subscribe((data: any) =>
          //this.Cservice.SearchAllContractsNewByOrg(title,this.route.snapshot.queryParams['OrganisationID']).subscribe((data:any) =>
          {
            console.log(data);
            if (data.length > 0) {
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;
            }
            else {
              alert("No data found for the search!");
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;
            }


          });
        }
        else {
          const formdata = new FormData();
          formdata.append("ContractTitle", title);
          formdata.append("ContactID", sessionStorage.getItem("ContactID"));
          this.Cservice.SearchContractbyTitleandContactID(formdata).subscribe((data: any) =>
          //this.Cservice.SearchAllContractsNew(title,sessionStorage.getItem("ContactID")).subscribe((data:any) =>
          {
            console.log(data);
            if (data.length > 0) {
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;
            }
            else {
              alert("No data found for the search!");
              this.contractsDatabaseDataSource = new MatTableDataSource(data);
              this.contractsDatabaseDataSource.sort = this.sort;
              this.contractsDatabaseDataSource.paginator = this.paginator;
            }


          });
        }
      }
      else {
        //if(sessionStorage.getItem("usertype") =="Head of Procurement")
        //{
        const formdata = new FormData();
        formdata.append("ContractTitle", title);
        formdata.append("ContactID", sessionStorage.getItem("ContactID"));
        this.Cservice.SearchContractbyTitleandContactID(formdata).subscribe((data: any) =>
        //this.Cservice.SearchAllContractsNew(title,sessionStorage.getItem("ContactID")).subscribe((data:any) =>
        {
          console.log(data);
          if (data.length > 0) {
            this.contractsDatabaseDataSource = new MatTableDataSource(data);
            this.contractsDatabaseDataSource.sort = this.sort;
            this.contractsDatabaseDataSource.paginator = this.paginator;
          }
          else {
            alert("No data found for the search!");
            this.contractsDatabaseDataSource = new MatTableDataSource(data);
            this.contractsDatabaseDataSource.sort = this.sort;
            this.contractsDatabaseDataSource.paginator = this.paginator;
          }


        });
        // }
        /* else{
           this.Cservice.SearchAllContractsNewByOrg(title,this.OrganisationID).subscribe((data:any) =>
           {
             console.log(data);
             if(data.length > 0)
             {
             this.contractsDatabaseDataSource= new MatTableDataSource(data);
             this.contractsDatabaseDataSource.sort = this.sort;
             }
             else{
                  alert("No data found for the search !");
                  this.contractsDatabaseDataSource= new MatTableDataSource(data);
                  this.contractsDatabaseDataSource.sort = this.sort;
                }
             
             
           });
         }*/
      }

    }
    else {
      if (this.route.snapshot.queryParams['OrganisationID'] != null) {
        if (this.route.snapshot.queryParams['OrganisationID'] != "0") {
          this.getAllContractsByOrg();
        }
        else {
          this.getAllContracts();
        }
      }
      else {
        if (sessionStorage.getItem("usertype") == "Head of Procurement") {
          this.getAllContracts();
        }
        else {
          this.getAllContracts();
          //const formdata = new FormData();
          //formdata.append("ContractTitle", title);
          //formdata.append("OrganisationID", this.OrganisationID);
          //this.Cservice.SearchContractbyTitleandOrgID(formdata).subscribe((data: any) =>
          ////this.Cservice.SearchAllContractsNewByOrg(title,this.OrganisationID).subscribe((data:any) =>
          //{
          //  console.log(data);
          //  if (data.length > 0) {
          //    this.contractsDatabaseDataSource = new MatTableDataSource(data);
          //    this.contractsDatabaseDataSource.sort = this.sort;
          //    this.contractsDatabaseDataSource.paginator = this.paginator;
          //  }
          //  else {
          //    alert("No Data found for the search !");
          //    this.contractsDatabaseDataSource = new MatTableDataSource(data);
          //    this.contractsDatabaseDataSource.sort = this.sort;
          //    this.contractsDatabaseDataSource.paginator = this.paginator;
          //  }


          //});
        }
      }
    }
  }

  // getContractsbyType(Type:string)
  // {
  //     this.PDbService.GetAllContractListByContractType(Type).subscribe((data:any) =>
  //     {
  //       this.contractsDatabaseDataSource= new MatTableDataSource(data);
  //       this.contractsDatabaseDataSource.sort = this.sort;
  //     });
  // }

  AlertDialogforExtendorReprocure(sType: string, ContractId: any) {

    const dialogRef = this.dialog.open(AlertComponent, {
      data: { RetStatus: sType },
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        //send mail
        this.PDbService.ReProcure_ExtendContractSendMailtoTM(sessionStorage.getItem("ContactID"), ContractId, sType).subscribe((data: any) => {
          if (data.RetStatus == "Success") {
            if (sType == "ExtendContract") {
              alert("Your request to extend the contract has been sent");
              this.contractsDatabaseDataSource.data.forEach((value, key) => {
                if (value.ContacID == ContractId) {
                  value.DisableExtend = 1; value.DisableReprocure = 1;
                }
              });
            }
            else {
              //alert("Your request sent Successfully");
              alert("Your request to reprocure the contract has been sent");
              this.contractsDatabaseDataSource.data.forEach((value, key) => {
                if (value.ContacID == ContractId) {
                  value.DisableReprocure = 1; value.DisableExtend = 1;
                }
              });
            }

            this.dialog.closeAll();
          }
          else {
            alert(data.RetStatus);
          }
        });
      }
    });
  }

  openDialogReprocureContract(ContractID: any, cTitle: string, OrganisationName: any) {
    if (sessionStorage.getItem("usertype") == "Procurement Officer") {
      //this.router.navigate(['dashboard/projects/new-project'], { queryParams: {ContractID: ContractID}});
      //this.dialog.closeAll();

      this.setService.getSystemSettingswithoutcontactID().subscribe((data: any) => {
        if (data.ProjectApproval == "no") {
          this.router.navigate(['dashboard/projects/new-project'], { queryParams: { ContractID: ContractID, ContractType: "Reprocure" } });
          this.dialog.closeAll();
          // const dialogRef = this.dialog.open(DialogReprocureContractComponent, { data: {ContractID:ContractID, ContractTitle:cTitle, OrgName:OrganisationName, Page:"Contract"},
          // width: '730px'
          // });
          //   dialogRef.afterClosed().subscribe(result => {
          //       console.log(`Dialog result: ${result}`);

          //   });
        }
        else {
          this.AlertDialogforExtendorReprocure("ReprocureContract", ContractID);
        }
      });
    }
    else {
      const dialogTeamRef = this.dialog.open(AlertComponent, {
        data: { RetStatus: "ContractRe-procure" },
        width: '600px'
      });
      dialogTeamRef.afterClosed().subscribe(result => {
        if (result == false) {
          this.router.navigate(['dashboard/projects/new-project'], { queryParams: { ContractID: ContractID, ContractType: "Reprocure" } });
          this.dialog.closeAll();
        }
        else {
          const dialogRef = this.dialog.open(DialogReprocureContractComponent, {
            data: { ContractID: ContractID, ContractTitle: cTitle, OrgName: OrganisationName, Page: "Contract" },
            width: '730px'
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
              this.contractsDatabaseDataSource.data.forEach((value, key) => {
                if (value.ContacID == ContractID) {
                  value.DisableExtend = 1; value.DisableReprocure = 1;
                }
              });
            }
            console.log(`Dialog result: ${result}`);

          });
        }
      });

    }

  }
  openDialogExtendContract(ContactID: any, OrganisationName: any) {
    if (sessionStorage.getItem("usertype") == "Procurement Officer") {
      this.setService.getSystemSettingswithoutcontactID().subscribe((data: any) => {
        if (data.ProjectApproval == "no") {
          const dialogRef = this.dialog.open(DialogExtendContractComponent, { data: { ContactID: ContactID, OrgName: OrganisationName } });

          dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
          });
        }
        else {
          this.AlertDialogforExtendorReprocure("ExtendContract", ContactID);
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(DialogExtendContractComponent, { data: { ContactID: ContactID, OrgName: OrganisationName } });

      dialogRef.afterClosed().subscribe(result => {

        if (result == true) {
          this.contractsDatabaseDataSource.data.forEach((value, key) => {
            if (value.ContacID == ContactID) {
              value.DisableExtend = 1; value.DisableReprocure = 1;
            }
          });
        }
        console.log(`Dialog result: ${result}`);
      });
    }

  }

  openDialogAdvanceSearch() {
    sessionStorage.removeItem("Item1");
    const dialogRef = this.dialog.open(DialogAdvanceSearchcontractComponent, {
      data: { ContactID: sessionStorage.getItem("ContactID") },
      width: '730px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      let key = 'Item1';
      console.log("key", sessionStorage.getItem(key));
      console.log("key1", sessionStorage.getItem('type'));
      if (sessionStorage.getItem(key) != null) {
        if (sessionStorage.getItem('type') == null) {
          let item = JSON.parse(sessionStorage.getItem(key));
          console.log(item);
          this.contractsDatabaseDataSource = new MatTableDataSource(item);
          this.contractsDatabaseDataSource.sort = this.sort;
          this.contractsDatabaseDataSource.paginator = this.paginator;
        }
        else {

          if (sessionStorage.getItem('type') == 'null') {
            let item = JSON.parse(sessionStorage.getItem(key));
            console.log(item);
            this.contractsDatabaseDataSource = new MatTableDataSource(item);
            this.contractsDatabaseDataSource.sort = this.sort;
            this.contractsDatabaseDataSource.paginator = this.paginator;

          } else {
            let type = sessionStorage.getItem('type');
            sessionStorage.setItem('type', 'null');
            this.router.navigate(['dashboard/contracts/filter'], { queryParams: { ContractType: type, advancesearch: 'yes' } });
          }
        }
      }
    });
  }

  getNonPublishedContracts() {
   
    this.lService.getContactByEmail(sessionStorage.getItem("userMail")).subscribe((data: any) => {
      if (data.ContractConfig == "No") {        
        this.UploadedContracts = false;
      }
      else {
        this.Cservice.GetAllNonPublishedContractListByContactID(sessionStorage.getItem("ContactID")).subscribe((data: any) => {                    
          if (data.length > 0) {
            this.UploadedContracts = true;
          }
          else {
            this.UploadedContracts = false;
          }
        });
      }
    });
  }


  ngAfterViewInit(): void {
  
  }

  ShowUploadedContracts() {
    this.LiveRecords = false;
    this.NotLiveRecords = true;
    this.loading = true;
    this.Cservice.GetAllNonPublishedContractListByContactID(sessionStorage.getItem("ContactID")).subscribe((data: any) => {     

      console.log("data", data);   

      this.contractsDatabaseNotLiveDataSource = new MatTableDataSource(data);
      this.selection = new SelectionModel(data, []);
      this.contractsDatabaseNotLiveDataSource.sort = this.publishDataSort;
      this.contractsDatabaseNotLiveDataSource.paginator = this.publishDataPaginator;
      this.loading = false; 
      
      //setTimeout(() => {
        
      //}, 2000);
    }, (error) => {
      this.loading = false;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.contractsDatabaseNotLiveDataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.contractsDatabaseNotLiveDataSource.data.forEach(row => this.selection.select(row));
  }

  PublishUploadedData() {
    //console.log(this.selection.selected);
    if (this.selection.selected.length > 0) {
      let Error: number = 0;
      for (let i = 0; i < this.selection.selected.length; i++) {
        this.Cservice.ContractIsPublishedUpdate(this.selection.selected[i].ContacID, "N").subscribe((data: any) => {
          console.log(data.value);
          if (data != "Updated") {
            Error += 1;
          };
        });
      }

      if (Error == 0) {

        alert("Published successfully");
      }
      else {
        alert("Published with errors");
      }

      this.Cservice.GetAllNonPublishedContractListByContactID(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

        this.contractsDatabaseNotLiveDataSource = new MatTableDataSource(data);
        this.selection = new SelectionModel(data, []);
        this.contractsDatabaseNotLiveDataSource.sort = this.publishDataSort;
        this.contractsDatabaseNotLiveDataSource.paginator = this.publishDataPaginator;
        if (data.length > 0) {
          this.UploadedContracts = true;
        }
        else {
          this.UploadedContracts = false;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/contracts/database']);
        }

      });
    }
    else {
      alert("Please select atleast one record to publish");
    }

  }
  DeleteUploadedData() {
    //console.log(this.selection.selected);
    if (this.selection.selected.length > 0) {
      let Error: number = 0;
      for (let i = 0; i < this.selection.selected.length; i++) {
        this.Cservice.DeleteUploadedContract(this.selection.selected[i].ContacID).subscribe((data: any) => {
          if (data != "Deleted") {
            Error += 1;
          }
        });
        // this.Cservice.ContractIsPublishedUpdate(this.selection.selected[i].ContacID,"D").subscribe((data:any) =>
        // {
        //   console.log(data.value);
        //   if(data !="Updated")
        //   {
        //     Error+=1;
        //   };
        // });
      }
      if (Error == 0) {

        alert("Deleted successfully");
      }
      else {
        alert("Deleted with errors");
      }
      this.Cservice.GetAllNonPublishedContractListByContactID(sessionStorage.getItem("ContactID")).subscribe((data: any) => {

        this.contractsDatabaseNotLiveDataSource = new MatTableDataSource(data);
        this.selection = new SelectionModel(data, []);
        this.contractsDatabaseNotLiveDataSource.sort = this.sort;
        this.contractsDatabaseNotLiveDataSource.paginator = this.paginator;

        if (data.length > 0) {
          this.UploadedContracts = true;
        }
        else {
          this.UploadedContracts = false;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/contracts/database']);
        }
      });

    }
    else {
      alert("Please select atleast one record to delete");
    }

  }

  //Date : 02 Mar 2020 by Arun
  WizardHideorShow() {
    this.wizard.getWizardCheckListByContactID(Number(sessionStorage.getItem("ContactID")), sessionStorage.getItem("usertype")).subscribe((data: any) => {
      if (data.length > 0 && data.length == data.filter(x => x.WizardCheckList_Viewed == 1).length) {
        this.ShowWizardMenu = false;
      }
      else {
        this.ShowWizardMenu = true;
      }
    });
  }

  LoadAllCaptions() {
    this.setService.getCaptionFromDB("dashboard-contracts").subscribe((data: any) => {
      this.Names = data;
    });

  }


  getCaption(sCaption: string) {
    var sResult = sCaption;
    if (this.Names != null) {
      this.Names.forEach(element => {
        if (element.OriginalName === sCaption) {
          sResult = element.Value;
          return false;
        }

      });
    }
    return sResult;
  }

}

/*export interface ContractsDatabase {
  Organisation_Name: string;
  ContractTitle: string;
  ContractStartDate: Date;
  ContractEndDate: Date;
  category: string;
  subCategory: string;
}*/




