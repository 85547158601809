import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardPerformanceService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  getAllOrganistaion()
  {
    return this.http.get(this.lService.ServiceIP +"/organisation");
  }

  getOrganisationByHOP(HOPContactID:any)
  {
   
    return this.http.get(this.lService.ServiceIP +"/organisation?$filter=ContactID eq "+ HOPContactID);
  }
  
  getTeamsbyOrganisation(Organisation_ID:string)
  {
    return this.http.get(this.lService.ServiceIP +"/team?$filter=OrganisationID eq " + Organisation_ID);
  }

  getOrganisationForNonHOP(ContactID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/organisation/GetOrganisationForNonHOP(ContactID="+ ContactID +")");
  }

  getTeamsbyOrganisationNonHOP(ContactID:any,Organisation_ID:string)
  {
    return this.http.get(this.lService.ServiceIP +"/team/GetAssignedTeamsForUserbyOrgID(ContactID="+ ContactID +  ",OrganisationID =" + Organisation_ID+")");
  }

  

  getAllTeams()
  {
    return this.http.get(this.lService.ServiceIP +"/team");
  }

  GetFinancialYear()
  {
    return this.http.get(this.lService.ServiceIP +"/FinanceYear");
  }
  GetProjectOwners()
  {
    return this.http.get(this.lService.ServiceIP +"/project/GetProjectOwners");
  }

  GetTotalAssignedProjectsandSavings()
  {
    return this.http.get(this.lService.ServiceIP +"/project/GetTotalAssignedProjectsandSavings");
  }

  GetTotalCompletedProjectssandSavings()
  {
    return this.http.get(this.lService.ServiceIP +"/project/GetTotalCompletedProjectssandSavings");
  }

  GetTotalIncompleteProjectssandSavings()
  {
    return this.http.get(this.lService.ServiceIP +"/project/GetTotalIncompleteProjectssandSavings");
  }

  GetTotalCancelledProjectssandSavings()
  {
    return this.http.get(this.lService.ServiceIP +"/project/GetTotalCancelledProjectssandSavings");
  }
    //added on 14/12/2019
    GetEstimatedSavings(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetEstimatedContractsSavings(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }

    GetEstimatedSavings_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetEstimatedContractsSavings_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");

    }

    GetActualSavings(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavings(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }

    GetActualSavings_ByOrg(OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavings_ByOrg(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");

    }
    //added on 16/12/2019
    GetTotalAssisgnedProjects(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjects(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }

    GetTotalAssisgnedProjects_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjects_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");

    }
    //added on 17/12/2019
    GetTotalAssisgnedProjectsTeam_ByOrg(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjectsTeam_ByOrg(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");

    }

    GetEstimatedContractsSavings_Owner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetEstimatedContractsSavings_Owner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetActualContractsSavings_Owner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavings_Owner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetTotalAssisgnedProjects_Owner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjects_Owner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetEstimatedContractsSavingsByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetEstimatedContractsSavingsByOrg_Owner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetActualContractsSavingsByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavingsByOrg_Owner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetTotalAssisgnedProjectsByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjectsByOrg_Owner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    //added on 18/12/2019

    GetTotalIncompleteProjects(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjects(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }

    GetTotalIncompleteProjects_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjects_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");

    }

    GetTotalIncompleteProjectsTeam_ByOrg(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjects_ByTeam(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");

    }

    GetTotalIncompleteProjects_ByOwner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjects_ByOwner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetTotalIncompleteProjectsByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjectsByOrg_Owner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }
   
    //added on 20/12/2019
    getAllProjectsOwnersByOrgID(OrganisationID : any,FinanceYear : any )
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetProjectOwners_ByOrg(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");
    }

    getAllProjectsOwners(ContactID : any,FinanceYear :any )
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetProjectOwnersAll(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");
    }

    GetProjectOwners_ByTeam(TeamID : any,FinanceYear :any )
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetProjectOwners_ByTeam(TeamID=" + TeamID +",FinanceYear='" + FinanceYear + "')");
    }

    //
    GetTotalCompleteProjects(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjects(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }

    GetTotalCompleteProjects_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjects_ByOrg(ContactID=" + ContactID + ",OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");

    }
    //added on 27/12/2019
    GetTotalCompleteProjectsTeam_ByOrg(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjects_ByTeam(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");

    }
    //added on 28/12/2019
    GetTotalCompleteProjects_ByOwner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjects_ByOwner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }
    GetTotalCompleteProjectsByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjectsByOrg_Owner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }
    //added on 10/01/2020
    GetActualSavingsLifetime(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavingsLifetime(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }
    //
    GetActualSavingsLifetime_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavingsLifeTime_ByOrg(ContactID="  + ContactID  +",OrganisationID="  + OrganisationID  +",FinanceYear='" + FinanceYear + "')");
    }

    GetTotalAssisgnedProjectsLifetime(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjectsLifetime(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }
    //
    GetTotalAssisgnedProjectsLifetime_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjectsLifetime_ByOrg(ContactID=" + ContactID +",OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");
    }

    GetTotalCompleteProjectsLifeTime(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjectsLifeTime(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }
    //
    GetTotalCompleteProjectsLifetime_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjectsLifetime_ByOrg(ContactID=" + ContactID +",OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");
    }

    GetTotalIncompleteProjectsLifetime(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjectsLifetime(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }
    //
    GetTotalIncompleteProjectsLifetime_ByOrg(ContactID : any,OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjectsLifetime_ByOrg(ContactID=" + ContactID +",OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");
    }
    //added on 11/01/2020
    GetTotalAssisgnedProjectsLifetime_ByTeam(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjectsLifetime_ByTeam(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear  + "',TeamID=" + TeamID + ")");

    }    

    GetTotalIncompleteProjectsLifetime_ByTeam(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjectsLifetime_ByTeam(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear  + "',TeamID=" + TeamID + ")");


    }    
    GetTotalCompleteProjectsLifetime_ByTeam(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjectsLifetime_ByTeam(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear  + "',TeamID=" + TeamID + ")");

    } 
    //added on 13/01/2020
    GetProjectOwnersAllLifetime(ContactID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetProjectOwnersAllLifetime(ContactID=" + ContactID +")");
    }

    GetProjectOwnersLifetime_ByTeam(TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetProjectOwnersLifetime_ByTeam(TeamID=" + TeamID +")");
    }

    GetProjectOwnersLifetime_ByOrg(OrganisationID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetProjectOwnersLifetime_ByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetTotalAssisgnedProjectsLifetime_Owner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjectsLifetime_Owner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear  + "',Owner='" + Owner + "')");

    }

    GetTotalIncompleteProjectsLifetime_ByOwner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjectsLifetime_ByOwner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear  + "',Owner='" + Owner + "')");

    }

    GetTotalCompleteProjectsLifetime_ByOwner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjectsLifetime_ByOwner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear  + "',Owner='" + Owner + "')");

    }

    GetActualContractsSavingsLifetime_Owner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavingsLifetime_Owner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetTotalAssisgnedProjectsLifetimeByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalAssisgnedProjectsLifetimeByOrg_Owner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetTotalIncompleteProjectsLifetimeByOrg_ByOwner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalIncompleteProjectsLifetimeByOrg_ByOwner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetTotalCompleteProjectsLifetimeByOrg_ByOwner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetTotalCompleteProjectsLifetimeByOrg_ByOwner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetActualContractsSavingsLifetimeByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetActualContractsSavingsLifetimeByOrg_Owner(OrganisationID=" + OrganisationID + ",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    //added on 14/02/2020
    GetEstimatedContractsSavings_ByOrg_Team(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetEstimatedContractsSavings_ByOrg_Team(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");

    }

    //added on 18/02/2020
    GetContractSavingsTarget_ByOrg(OrganisationID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetContractSavingsTarget_ByOrg(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "')");

    }

    GetContractSavingsTarget(ContactID : any,FinanceYear : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetContractSavingsTarget(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "')");

    }

    GetContractSavingsTarget_ByOrgTeam(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetContractSavingsTarget_ByOrgTeam(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");

    }

    GetContractSavingsTarget_ByOwner(ContactID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetContractSavingsTarget_ByOwner(ContactID=" + ContactID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    GetContractSavingsTarget_ByOrg_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetContractSavingsTarget_ByOrg_Owner(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',Owner='" + Owner + "')");

    }

    //added on 18/02/2020
    GetEstimatedContractsSavingsLifetime_ByOrg_Team(OrganisationID : any,FinanceYear : any,TeamID : any)
    {
      return this.http.get(this.lService.ServiceIP +"/contractfinance/GetEstimatedContractsSavingsLifetime_ByOrg_Team(OrganisationID=" + OrganisationID +",FinanceYear='" + FinanceYear + "',TeamID=" + TeamID + ")");

    }

    





}
