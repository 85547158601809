import { Component, OnInit } from '@angular/core';

import {DashboardPerformanceComponent} from '../../dashboard/dashboard-performance/dashboard-performance.component';

import {HeroDashboardPerformanceService} from './hero-dashboard-performance.service';

//import {ExportDashboardPerformanceComponent} from '../../dashboard/export-dashboard-performance/export-dashboard-performance.component';

@Component({
  selector: 'app-hero-dashboard-performance',
  templateUrl: './hero-dashboard-performance.component.html',
  styleUrls: ['./hero-dashboard-performance.component.css']
})
export class HeroDashboardPerformanceComponent implements OnInit {
  //@ViewChild('content', {static: false}) content: ElementRef;
  

  constructor(private page:DashboardPerformanceComponent,private ser:HeroDashboardPerformanceService) {
    
    
  }

  ngOnInit() {
    
  }

  public downloadPDF() {
    this.ser.visible=false;
    this.page.captureScreen();
    
  }
}
