import { Component, OnInit,Inject } from '@angular/core';
import {LoginService} from '../login/login.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginAlertDialogComponent } from '../login-alert-dialog/login-alert-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  datakey: string;
  constructor(private lService:LoginService,public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private router: Router) {
    //console.log(this.data.datakey);
   }

  ngOnInit() {
  }

  SendLinkagain()
  {
    const formData=new FormData();
    formData.append("email",this.data.datakey);

    this.lService.ResetPassword(formData).subscribe((data:any)=>
    {
        if(data.RetStatus=="Success")
        {
          this.dialog.closeAll();
          this.showdialog();
        }
        else
        {
         alert("Error : "+ data.RetStatus);
        }
    });
  }

  showdialog() {
    
    let dialogRef = this.dialog.open(AlertDialogComponent,{data: {datakey:this.data.datakey}});
    

    dialogRef.afterClosed().subscribe(result => {
      // NOTE: The result can also be nothing if the user presses the `esc` key or clicks outside the dialog
      if (result == 'confirm') {
        this.router.navigate(["/login"]);
      }
    });
  }

  // showdialog() {
  //   let dialogRef = this.dialog.open(LoginAlertDialogComponent,{data: {Message:"We just re-emailed the link."}});
  //       dialogRef.afterClosed().subscribe(result => {
  //     // NOTE: The result can also be nothing if the user presses the `esc` key or clicks outside the dialog
  //     if (result == 'confirm') {

  //       this.router.navigate(["/login"]);
  //     }
  //   });
  // }
}
