import { Injectable, } from '@angular/core';
import {LoginService} from '../login/login.service';
import {HttpClient, HttpHeaders} from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  GetTeamDataOrganigataion(TeamID:any,OrganigationId:number,UserType: number,ContactID:any)
  {
   
    return this.http.get(this.lService.ServiceIP + "/Team/GetTeams(TeamID="+TeamID+",OrganigationId="+OrganigationId+",UserType="+UserType+",ContactID="+ ContactID +")");
  }
  GetTeamMemCount(orgID:any,contactID:any)
  {
    return this.http.get(this.lService.ServiceIP + "/Team/GetTeamMemCount(orgID='"+orgID+"',contactID='"+contactID+"')");
  }
  getTeamandTeamUsersCount_ForNonHOP(OrgID:Number,ContactID:Number)
  {
    return this.http.get(this.lService.ServiceIP + "/Team/GetTeamandTeamUsersCount_ForNonHOP(OrgID="+OrgID+",ContactID="+ContactID+")");
  }

  GetTeamUsersbyTeamID(TeamID:Number)
  {
    return this.http.get(this.lService.ServiceIP + "/Team/GetTeamUsers(TeamID="+TeamID+")");
  }

  GetHOPUsersbyTeamID(OrganisationID:number,TeamID:Number)
  {
    return this.http.get(this.lService.ServiceIP + "/Team/GetHOPUsersbyTeamID(OrganisationID="+OrganisationID+",TeamID="+ TeamID +")");
  }
  GetTeamUsersbyUserType(TeamID:number,UserType:number)
  {
    return this.http.get(this.lService.ServiceIP + "/Team/GetTeamUsersbyUserType(TeamID="+TeamID+",UserType="+ UserType +")");
  }

  UpdateSystemSettings(formData:any)
  {
    
    return this.http.post(this.lService.ServiceIP +"/systemsettings/AddSystemSettings",formData);
  }
  getSystemSettings(contactID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/systemsettings/GetSystemSettings(contactID="+ contactID+")");
  }
  getSystemSettingswithoutcontactID()
  {
    return this.http.get(this.lService.ServiceIP +"/systemsettings/GetSystemSettings");
  }
  getCompanyLogo()
  {
    return this.http.get(this.lService.ServiceIP +"/systemsettings/GetCompanyLogo");
  }

  updateTeamName(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/Team/updateTeamName",formData);
  }
  getCurrencyCodes()
  {
    return this.http.get(this.lService.ServiceIP +"/systemsettings/GetCurrencyCodes");
  }

  AddEstimatedSavingsTarget(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/systemsettings/AddEstimatedSavingsTarget",formData);
  }
  GetEstimatedSavingsTargetsby_ContactID_FinancialYear(ContactID:any,FinancialYear:string)
  {
    return this.http.get(this.lService.ServiceIP + "/systemsettings/GetEstimatedSavingsTargetsby_ContactID_FinancialYear(ContactID="+ ContactID +",FinancialYear='"+FinancialYear+"')");
  }
  GetEST_by_ContactID_FY_TeamID(ContactID:number,TeamID:number,FinancialYear:string)
  {
    return this.http.get(this.lService.ServiceIP + "/systemsettings/GetEST_by_ContactID_FY_TeamID(ContactID="+ ContactID +",TeamID="+ TeamID +",FinancialYear='"+FinancialYear+"')");
  }

  GetNotifications()
  {
    //alert(this.lService.ServiceIP + "/Notifications");
    return this.http.get(this.lService.ServiceIP + "/Notifications");

  }
  SaveNotifications(Notifications:any)
  {
  
    return this.http.post(this.lService.ServiceIP +"/Notifications/SaveNotifications",Notifications);
  }
 
  UploadProjectDataFromExcel(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/project/UploadProjectDataFromExcel",formData);
  }

  ContractDataUploadFromExcel(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/project/ContractDataUploadFromExcel",formData);
  }

  getErrorDocument(sFileName:string)
 {

   this.http.get(this.lService.ServiceIP +"/project/ErrorFileDown(sFileName='"+ sFileName +"')", {responseType: 'blob'})
      .subscribe((data) => this.downloadFile(data, sFileName), error => console.log('Error downloading the file.'),
        () => alert("Error file downloaded successfully"));

  // this.http.get(this.lService.ServiceIP +"/project/ErrorFileDown(sFileName='"+ sFileName +"')", {responseType: 'blob'})
  //     .subscribe((data) =>
  //     {
  //       saveAs(data,sFileName);
  //     });
  
 }
 downloadFile(data: any, filename: string) {
  const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
  //const blob = new Blob([data], { type: 'application/octet-stream' });
  //const blob = new Blob([data], { type: 'application/vnd.ms-word.document' });
  saveAs(blob, filename);
}
//added on 10/02/2020
CheckNotificationBell()
{

  return this.http.get(this.lService.ServiceIP +"/Notifications/CheckNotificationBell");
}

getCaptionFromDB(sTemplateName:string)
{
   return this.http.get(this.lService.ServiceIP +"/systemsettings/GetCaption(TemplateName='"+ sTemplateName +"')");
}

getTooltipFromDB(sTemplateName:string)
{
   return this.http.get(this.lService.ServiceIP +"/systemsettings/GetToolTip(TemplateName='"+ sTemplateName +"')");
}

getProjectPhaseLabelFromDB()
{
   return this.http.get(this.lService.ServiceIP +"/project/GetProjectPhaseList");
}

enForceProjectClosure ()
{
   return this.http.get(this.lService.ServiceIP +"/systemsettings/GetSystemSettingProjectClosure");
}

enableLondonLivingAllowance ()
{
   return this.http.get(this.lService.ServiceIP +"/systemsettings/GetSystemSettingLondonLivingAllowance");
}

isSimpleSubCategory ()
{
   return this.http.get(this.lService.ServiceIP +"/systemsettings/GetSubCategoryType");
} 


}
