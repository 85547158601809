import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardContractsNewContractService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  getAllOrganistaion()
  {
    return this.http.get(this.lService.ServiceIP +"/organisation");
  }

  getExtentionPeriod()
  {
    return this.http.get(this.lService.ServiceIP +"/ExtentionPeriod");
  }

  getOrganisationForNonHOP(ContactID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/organisation/GetOrganisationForNonHOP(ContactID="+ ContactID +")");
  }

  getAllCategories()
  {
    return this.http.get(this.lService.ServiceIP +"/Category");
  }

  //changed on 11/02/2020
  getAllSubCategories()
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory/GetSubCategories_Autocomplete");
  }

  getSubCategory(CategoryID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory?$filter=FK_CategoryGroupID eq " + CategoryID);

  }

  getSubCategorybyCat(CategoryID:number)
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory/GetSubCategorybyCategoryID(CategoryID="+ CategoryID +")");
  }
  
  getContractTypes()
  {
     return this.http.get(this.lService.ServiceIP +"/ContractType");
  
  }

  getContractStatus()
  {
     return this.http.get(this.lService.ServiceIP +"/ContractStatus");
  
  }

  CreateContract(formData:any)
  {
    return this.http.post(this.lService.ServiceIP + "/Contract/", formData);
  }

  CreateContractHistory(formData:any)
  {
    return this.http.post(this.lService.ServiceIP + "/ContractHistory/", formData);
  }

  //added on 04/02/2020
  GetFinancialdataDummyPrj(findata:any)
  {
    return this.http.get(this.lService.ServiceIP +"/ContractFinance/GetFinancialdataDummyPrj(FinancialData='"+ findata +"')");
  }

  CreateNewWorkFlow(formData:any)
  {
    return this.http.post(this.lService.ServiceIP + "/ContractFinance/AddNewContractFlow/", formData);
  }

    //added on 05/02/2020
  GetAllContractSuppliersDummy(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/ContractFinance/GetAllContractSuppliersDummy/",formData);
  }

  //added on 12/02/2020
  GetSubCategoryForContract(ContractID : any)
  {
 
   return this.http.get(this.lService.ServiceIP +"/Contract/GetSubCategoryForContract(ContractID=" + ContractID + ")");
 
  }

  //added on 13/02/2020
  getAllSubCategories_Search(searchtext : any)
  {
    return this.http.get(this.lService.ServiceIP +"/SubCategory/GetSubCategories_Autocomplete_Search(SearchSubCat='" + searchtext + "')");
  }


}
