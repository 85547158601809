import { Component, OnInit,Inject } from '@angular/core';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AlertComponent } from '../../alert/alert.component';
import {SettingsService} from '../../settings/settings.service';

@Component({
  selector: 'app-dialog-add-new-task',
  templateUrl: './dialog-add-new-task.component.html',
  styleUrls: ['./dialog-add-new-task.component.scss']
})
export class DialogAddNewTaskComponent implements OnInit {
  AddTask:FormGroup;
  ProjectPhase:any;
  submitted:boolean=false;
  Show:boolean=false;
  ButtonText:string;
  ReturnStatus:boolean=true;
  ReasonDisable:boolean=true;
  Heading:string;
  CurrentStatus:any;
   Names : any;
 ProjectMSList :any;
  constructor(private DbService:ProjectDatabaseService, private fb:FormBuilder,public dialogRef: MatDialogRef<DialogAddNewTaskComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
  private setService:SettingsService) { 
    this.LoadAllCaptions();
	this.getProjectPhase();
    console.log(this.data.ProjectID);
  }

  ngOnInit() {
    this.AddTask=this.fb.group({
      ProjectPhase:[],
      TaskDescription:['',Validators.required], 
      TaskStartDate:['',Validators.required],
      TaskEndDate:['',Validators.required]
      //ReasonforChange:['']
        });
      if(this.data.TaskID !=null)
      {
        this.getTaskDetails(this.data.TaskID);
        this.Show=true;
        this.ButtonText="Update Task";
        this.ReasonDisable=false;
        this.Heading="Edit Task";
      }
      else
      {
        this.ButtonText="Add Task";
        this.Heading="Add New Task";
      }
  }

  get f() { return this.AddTask.controls; };

  getProjectPhase()
  {
    this.DbService.getProjectPhases().subscribe((data:any) =>
    {
      this.ProjectPhase=data;
      if(data.length >0)
      {
        console.log(data[0].ProjectPhase_Type);
        this.AddTask.get("ProjectPhase").setValue(data[0].ProjectPhaseID);
      }

    });
  }
  CheckForDates()
  {
    var datepipe = new DatePipe('en-US');
    console.log(this.AddTask.get("TaskEndDate").value);
    console.log(this.AddTask.get("TaskStartDate").value);
    if(new Date(datepipe.transform(this.AddTask.get("TaskStartDate").value,'MM/dd/yyyy')) > new Date(datepipe.transform(this.AddTask.get("TaskEndDate").value,'MM/dd/yyyy')))
      {
        alert("End date should be greater than or equal to start date");
        this.AddTask.get("TaskEndDate").reset();
      }
  }

  AddTaskData()
  {
    var datepipe = new DatePipe('en-US');

    const formData=new FormData();
    if(this.data.TaskID !=null)
    {
      formData.append("PK_TaskID", this.data.TaskID);
    }
    else
    {
      formData.append("FK_ProjectID", this.data.ProjectID);
    }
    
    formData.append("FK_ProjectPhase", this.AddTask.get("ProjectPhase").value);
    formData.append("taskDescription", this.AddTask.get("TaskDescription").value);
    formData.append("startDate", datepipe.transform(this.AddTask.get("TaskStartDate").value,'MM/dd/yyyy hh:mm:ss'));
    formData.append("endDate", datepipe.transform(this.AddTask.get("TaskEndDate").value,'MM/dd/yyyy hh:mm:ss'));
    formData.append("FK_ContactID", sessionStorage.getItem("ContactID"));
    formData.append("ReasonForChange", "");
    this.DbService.AddorUpdateTasks(formData).subscribe((data:any)=>
    {
      if(data.RetStatus =="Created")
      {
        alert("Task Added");
        // this.AddTask.get("TaskDescription").reset();
        // this.AddTask.get("TaskStartDate").reset();
        // this.AddTask.get("TaskEndDate").reset();
        this.dialog.closeAll();
      }
      else if(data.RetStatus =="Invalid Date")
      {
        alert("Task Start date & End date should be within Project Phase Start & End date or Project Phase dates not updated.");
        this.AddTask.get("TaskStartDate").setValue("");
        this.AddTask.get("TaskEndDate").setValue("");
      }
      else if(data.RetStatus =="Exists")
      {
        alert("Task Description already exists. Try with different Task Description.");
        this.AddTask.get("TaskDescription").reset();
        
      }
      else if(data.RetStatus =="MileStone Completed")
      {
        let PhaseText;
        if(this.AddTask.get("ProjectPhase").value =="1")
        {
          PhaseText=this.getProjectCaption(1);
        }
        else if(this.AddTask.get("ProjectPhase").value =="2")
        {
          PhaseText=this.getProjectCaption(2);
        }
        else if(this.AddTask.get("ProjectPhase").value =="3")
        {
          PhaseText=this.getProjectCaption(3);
        }
        else
        {
          PhaseText=this.getProjectCaption(4);
        }
        alert(PhaseText +" already completed. Please update status to Incomplete before addding a new Task.");
        this.AddTask.get("TaskDescription").reset();
      }
      else if(data.RetStatus =="Updated")
      {
        alert("Task Updated");
        this.dialog.closeAll();
        
      }
      else
      {
        alert("Error" + data.RetStatus);
        this.AddTask.get("TaskDescription").reset();
      }
    });
  }
  CreateTask()
  {
    this.submitted=true;
    if (!this.AddTask.valid) {
      return;
      }
       //  if(this.ButtonText =="Update Task" && this.AddTask.get("ReasonforChange").value=="")
      //  {
      //    alert("Please enter Reason for Change!");
      //   return;
      //  }

      var MileStonePhaseStatus="";
      if(this.AddTask.get("ProjectPhase").value =="1")
      {
        MileStonePhaseStatus=this.getProjectCaption(1);
      }
      else if(this.AddTask.get("ProjectPhase").value =="2")
      {
        MileStonePhaseStatus=this.getProjectCaption(2);
      }
      else if(this.AddTask.get("ProjectPhase").value =="3")
      {
        MileStonePhaseStatus=this.getProjectCaption(3);
      }
      else
      {
        MileStonePhaseStatus=this.getProjectCaption(4);
      }
      //this.getMileStonePhaseStatus(this.data.ProjectID,MileStonePhaseStatus);
      this.DbService.getMileStonePhaseStatus(this.data.ProjectID,MileStonePhaseStatus).subscribe((data:any) =>
      {
        //this.CurrentStatus=data.PhaseStatus;
        //console.log(data);
        if(this.ButtonText =="Add Task" &&  data.PhaseStatus =="Completed")
        {
          this.OpenDialogforConfirmation();
        }
        else
        {
          this.AddTaskData();
        }
      });
   
  }
  getTaskDetails(TaskID:any)
  {
    this.DbService.GetTaskbyTaskID(TaskID).subscribe((taskdata:any)=>
    {
      var datepipe = new DatePipe('en-US');
      console.log(taskdata);
      this.AddTask.get("ProjectPhase").setValue(taskdata.FK_ProjectPhase);
      this.AddTask.get("TaskDescription").setValue(taskdata.taskDescription);
      this.AddTask.get("TaskStartDate").setValue(taskdata.startDate);
      this.AddTask.get("TaskEndDate").setValue(taskdata.endDate);
    });
  }
  TaskDelete()
  {
    this.DbService.DeleteTask(this.data.TaskID).subscribe((data:any)=>
    {
        if(data.value =="Success")
        {
          //this.ReturnStatus=true;
          this.dialog.closeAll();
        }
        else
        {
          alert(data.value);
        }
    });
  }

  OpenDialogforConfirmation()
  {
    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"AddTask"},
    width: '640px'
  });

  dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result == true)
      {
        this.AddTaskData();
      }
  });
  }

  // getMileStonePhaseStatus(ProjectID:any,mPhase:string)
  // {
  //   return this.DbService.getMileStonePhaseStatus(ProjectID,mPhase);
  //   //console.log("cStatus : " + this.CurrentStatus.PhaseStatus);
  // }
  
  LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dashboard-projects-new-project").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	this.setService.getProjectPhaseLabelFromDB().subscribe((data:any) =>
			{
			  this.ProjectMSList = data;
			});
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
  
  getProjectCaption (projectID : any)
  {
       var sResult = "";
	   if (this.ProjectMSList != null)
	   {
		   this.ProjectMSList.forEach(element => {
			if (element.Id === projectID) {
				sResult = element.ProjectPhase_Type;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
}
