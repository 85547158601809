import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as jsPDF  from 'jspdf';
import * as html2canvas from 'html2canvas';
import {DashboardPerformanceService} from '../dashboard-performance/dashboard-performance.service';
//import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-export-dashboard-performance',
  templateUrl: './export-dashboard-performance.component.html',
  styleUrls: ['./export-dashboard-performance.component.css']
})
export class ExportDashboardPerformanceComponent implements OnInit {

  Organisation:any;
  nameId:string;
  Teams:any;
 TeamID:string;
 pagecontent:any;
 Financialyear:string;
 Projectowner:string;
 
 @ViewChild('divcontent', {static: false}) divcontent: ElementRef;

  constructor(private lservice:DashboardPerformanceService) { }

  ngOnInit() {
    this.nameId ="0";
    this.TeamID ="0";
    this.Financialyear="0";
    this.Projectowner="0";
    this.getOrganisation();
    this.getTeams();
  }

  getOrganisation()
  {
    this.lservice.getAllOrganistaion().subscribe((data:any) =>
      {
        console.log(data);
        this.Organisation=data.value;
        
      });
  }

  getTeams()
  {
    this.lservice.getAllTeams().subscribe((data:any)=>{
      this.Teams=data.value;
    });
  }
  
  selectName()
  {
    //alert(this.nameId);
    this.lservice.getTeamsbyOrganisation(this.nameId).subscribe((data:any) =>
    {
      console.log(data);
      this.Teams=data.value;
      
    });
  }
  
  ClearAllDropDowns()
  {
    
    this.nameId ="0";
    this.TeamID ="0";
    this.Financialyear="0";
    this.Projectowner="0";
  }
  public captureScreen() {
    //this.nav.hide();
    //var delay=2000;
    //setTimeout(function() { }, delay);
    const data = document.getElementById('divcontent');
      html2canvas(data).then(canvas => {
    const imgWidth = 210;
    const pageHeight = 300;
    //var top_left_margin = 15;
    const imgHeight = (canvas.height * imgWidth / canvas.width) +10;
    console.log("imgHeight" +imgHeight);
    let heightLeft = imgHeight;
    //var totalPDFPages = Math.ceil(data.clientHeight/canvas.height)-1;
    //var PDF_Width = data.clientWidth+(top_left_margin*2);
		//var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    const contentDataURL = canvas.toDataURL('image/png',1.0);
    const pdf = new jsPDF('p', 'mm','a4'); 
    let position = 0;
    
    pdf.addImage(contentDataURL, 'PNG', 0, position,imgWidth,imgHeight);
    heightLeft -= pageHeight;
    console.log("after minus heightLeft" +heightLeft);
        while (heightLeft >= 0) {
            position = (heightLeft - imgHeight) + 20;
            pdf.addPage();
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }  
    pdf.save('Performance_Report.pdf'); 
    });
    //this.nav.show();
  }

}
