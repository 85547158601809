import { Component, OnInit } from '@angular/core';
import { InvitesuserService } from '../invitesuser.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-edit-project-savings-target',
  templateUrl: './edit-project-savings-target.component.html',
  styleUrls: ['./edit-project-savings-target.component.scss']
})
export class EditProjectSavingsTargetComponent implements OnInit {

  contractsListDisplayedColumns = ['listName', 'action'];
  constructor(private InvserObj: InvitesuserService, private fb: FormBuilder) {
    this.LoadFinancialYearMasterList();
    this.LoadAddedFinancialYearList();

  }

  FinancialMasteryear = [];
  DeleteYears: any = ["", ""];
  SortedListYears: any = ["", ""];
  Financialyear: any = [];
  show: boolean = true;
  submitted = false;
  SelectedYear = {};
  YearGroup: FormGroup;
  ngOnInit() {
    this.YearGroup = this.fb.group({
      ddlyear: ['', Validators.required],
      ProjectSavingsTarget: ['', Validators.required],
    });

  }
  get f() { return this.YearGroup.controls; };
  LoadFinancialYearMasterList() {

    //
    this.InvserObj.GetFinancialYearList().subscribe((data: any) => {
      this.FinancialMasteryear = data.value;
      if (this.FinancialMasteryear.length > 0) {
        
        this.YearGroup.get("ddlyear").setValue(this.FinancialMasteryear[0].FinanceYearID);
        this.YearGroup.get("ProjectSavingsTarget").setValue(this.FinancialMasteryear[0].ProjectSavingTarget);
        this.SelectedYear=this.FinancialMasteryear[0];
      }
      else {
        this.show = false;
      }

    });
  }
  LoadAddedFinancialYearList() {
    this.InvserObj.GetFinanceYears().subscribe((data: any) => {
      //console.log(data);
      this.Financialyear = new MatTableDataSource(data);


    });
  }

  onFinanceYearChange(event) {

    this.SelectedYear = this.FinancialMasteryear.filter(x => x["FinanceYearID"] == event.target.value)[0];
   // alert(JSON.stringify( this.SelectedYear));
    this.YearGroup.get("ProjectSavingsTarget").setValue(this.SelectedYear["ProjectSavingTarget"]);
  }


  SaveFinancialYears() {
    this.submitted = true;

    if (this.YearGroup.invalid) {
      return;
    }

    //count =count + 1;
    const formdata = new FormData();
    formdata.append('FinanceYear', this.SelectedYear["FinanceYear"]);
    formdata.append('FinanceYearDisplay', this.SelectedYear["FinanceYearDisplay"]);
    formdata.append('ContactID', sessionStorage.getItem("ContactID"));
    formdata.append('ProjectSavingTarget',this.YearGroup.get("ProjectSavingsTarget").value);

    this.InvserObj.UpdateFinancialyears(formdata).subscribe((data: any) => {
      alert("Saved Successfully");
      this.LoadFinancialYearMasterList();
      this.LoadAddedFinancialYearList();
    });

  }

}
