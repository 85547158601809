import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DashboradContractsService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  getOrganisationForNonHOP(ContactID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/organisation/GetOrganisationForNonHOP(ContactID="+ ContactID +")");
  }
  getAllContracts()
  {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractList");
  }

  getContractsByContactID(ContactID : any)
  {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractList(ContactID="+ ContactID +")");
  }

  SearchAllContracts(searchtext:any)
  {
    //return this.http.get(this.lService.ServiceIP +"/Contract?$filter=indexof(ContractTitle,'" + searchtext + "') gt -1");

    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractList(ContractTitle="+ searchtext +")");
  }

  // SearchAllContractsNew(searchtext:any,ContactID : any)
  // {
  //   //return this.http.get(this.lService.ServiceIP +"/Contract?$filter=indexof(ContractTitle,'" + searchtext + "') gt -1");

  //   return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractList(ContractTitle='" +  searchtext + "',ContactID=" + ContactID +")");
  // }
  SearchContractbyTitleandContactID(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/Contract/SearchContractbyTitleandContactID",formData);
  }

  SearchContractbyTitleandOrgID(formData:any)
  {
    return this.http.post(this.lService.ServiceIP +"/Contract/SearchContractbyTitleandOrgID",formData);
  }

  getContractsByOrganisationID(OrganisationID : any)
  {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByOrg(OrganisationID="+ OrganisationID +")");
  }

  getActiveContractsByContact(ContactId: any, OrgId:any) {
    return this.http.get(this.lService.ServiceIP + "/Contract/GetAllContractListByContact(ContactId=" + ContactId + ",OrgId=" + OrgId +")");
  }

  SearchAllContractsNewByOrg(searchtext:any,OrganisationID : any)
  {
    //return this.http.get(this.lService.ServiceIP +"/Contract?$filter=indexof(ContractTitle,'" + searchtext + "') gt -1");

    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByOrg(ContractTitle='" +  searchtext + "',OrganisationID=" + OrganisationID +")");
  }

   getContractsByType(ContactID : any,Type : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListPieChart_Type(ContactID=" +  ContactID + ",Type='" + Type +"')");

   }

   getContractsByOrg_Type(OrganisationID : any,Type : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListPieChartByOrg_Type(OrganisationID=" +  OrganisationID + ",Type='" + Type +"')");

   }

   getContractsExpiryByType(ContactID : any,Type : any,Month : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChart_Expiry(ContactID=" +  ContactID + ",Type='" + Type +"',Month='" + Month + "')");

   }

   getContractsExpiryByOrg_Type(OrganisationID : any,Type : any,Month : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChartByOrg_Expiry(OrganisationID=" +  OrganisationID + ",Type='" + Type +"',Month='" + Month + "')");

   }

   getContractsPriorityByType(ContactID : any,Type : any,Priority : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChart_Priority(ContactID=" +  ContactID + ",Type='" + Type +"',Priority='" + Priority + "')");

   }

   getContractsPriorityByOrg_Type(OrganisationID : any,Type : any,Priority : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChartByOrg_Priority(OrganisationID=" +  OrganisationID + ",Type='" + Type +"',Priority='" + Priority + "')");

   }
   getContractsProcureByType(ContactID : any,Type : any,Value : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChart_Procure(ContactID=" +  ContactID + ",Type='" + Type +"',Value='" + Value + "')");

   }

   getContractsProcureByOrg_Type(OrganisationID : any,Type : any,Value : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChartByOrg_Procure(OrganisationID=" +  OrganisationID + ",Type='" + Type +"',Value='" + Value + "')");

   }

   getContractsCategoryByType(ContactID : any,Type : any,Category : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChart_Category(ContactID=" +  ContactID + ",Type='" + Type +"',ContractCategory='" + Category + "')");

   }
   getContractsCategoryByType_ForDrillDown(ContactID : any,Type : any,CategoryID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChart_Category_ForDrillDown(ContactID=" +  ContactID + ",Type='" + Type +"',CategoryID=" + CategoryID + ")");

   }

   getContractsCategoryByOrg_Type(OrganisationID : any,Type : any,Category : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChartByOrg_Category(OrganisationID=" +  OrganisationID + ",Type='" + Type +"',ContractCategory='" + Category + "')");

   }

   getContractsCategoryByOrg_Type_ForDrillDown(OrganisationID : any,Type : any,CategoryID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListBarChartByOrg_Category_ForDrillDown(OrganisationID=" +  OrganisationID + ",Type='" + Type +"',CategoryID=" + CategoryID + ")");

   }

   //added on 18/01/2020
   GetAllContractListByFinanceYear(ContactID : any,FinanceYear : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByFinanceYear(ContactID=" +  ContactID + ",FinanceYear='" + FinanceYear +"')");

   }

   GetAllContractListByFinanceYear_Org(OrganisationID : any,FinanceYear : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByFinanceYear_Org(OrganisationID=" +  OrganisationID + ",FinanceYear='" + FinanceYear +"')");

   }

   GetAllContractListByFinanceYear_Org_Team(OrganisationID : any,FinanceYear : any,TeamID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByFinanceYear_Org_Team(OrganisationID=" +  OrganisationID + ",FinanceYear='" + FinanceYear +"',TeamID =" + TeamID +")");

   }

   GetAllContractListByFinanceYear_Owner(ContactID : any,FinanceYear : any,Owner : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByFinanceYear_Owner(ContactID=" +  ContactID + ",FinanceYear='" + FinanceYear +"',Owner ='" + Owner +"')");

   }

   GetAllContractListByFinanceYear_Org_Owner(OrganisationID : any,FinanceYear : any,Owner : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByFinanceYear_Org_Owner(OrganisationID=" +  OrganisationID + ",FinanceYear='" + FinanceYear +"',Owner ='" + Owner +"')");

   }

   GetAllContractListByLifetime_Org(OrganisationID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByLifetime_Org(OrganisationID=" +  OrganisationID + ")");

   }

   GetAllContractListByLifetime_Org_Team(OrganisationID : any,TeamID : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByLifetime_Org_Team(OrganisationID=" +  OrganisationID + ",TeamID =" + TeamID +")");

   }

   GetAllContractListByLifetime_Owner(ContactID : any,Owner : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByLifetime_Owner(ContactID=" +  ContactID + ",Owner ='" + Owner +"')");

   }

   GetAllContractListByLifetime_Org_Owner(OrganisationID : any,Owner : any)
   {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllContractListByLifetime_Org_Owner(OrganisationID=" +  OrganisationID + ",Owner ='" + Owner +"')");

   }


   //Added by Arun on Jsn 22 2020
   GetAllNonPublishedContractListByContactID(ContactID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/Contract/GetAllNonPublishedContractList(ContactID="+ ContactID +")");
  }
  ContractIsPublishedUpdate(ContractID:any,sStatus:string)
  {
    return this.http.get(this.lService.ServiceIP +"/Contract/ContractIsPublishedUpdate(ContractID=" +  ContractID + ",sStatus ='" + sStatus +"')");
  }
  
  DeleteUploadedContract(ContractID:any)
  {
    return this.http.get(this.lService.ServiceIP +"/Contract/DeleteUploadedContract(ContractID=" +  ContractID + ")");
  }

   //added on 12/02/2020
   GetSubCategoryForContract(ContractID : any)
   {
  
    return this.http.get(this.lService.ServiceIP +"/Contract/GetSubCategoryForContract(ContractID=" + ContractID + ")");
  
   }
   
}
