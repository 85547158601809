import { Component, OnInit,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-resubmit-project-timetable-reason',
  templateUrl: './dialog-resubmit-project-timetable-reason.component.html',
  styleUrls: ['./dialog-resubmit-project-timetable-reason.component.scss']
})
export class DialogResubmitProjectTimetableReasonComponent implements OnInit {
  txtResubmissionReason: any= "";
  errorMessage: string = "";

  constructor(public dialogRef: MatDialogRef<DialogResubmitProjectTimetableReasonComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {    
    if(sessionStorage.getItem('ResubmitReason') != "null")
    {
      this.txtResubmissionReason = sessionStorage.getItem('ResubmitReason');
    }
  }

  Save()
  {
    var reason = "";

    this.errorMessage = "";
    reason = this.txtResubmissionReason ? this.txtResubmissionReason.replace(/\s/g, "").trim() : reason;
    if (!reason) {
      this.errorMessage = "Please specify the reason.";
      return;
    }
    sessionStorage.setItem('ResubmitReason', this.txtResubmissionReason);
    this.dialogRef.close();
  }

}
