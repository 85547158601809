import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class SystemNotificationsService {

  constructor(private http:HttpClient,private lService: LoginService) { }

  GetSystemNotifications(ContactID:any)
  {
   
    return this.http.get(this.lService.ServiceIP + "/project/GetSystemNotifications(ContactID="+ ContactID +")");
  }
  UpdateNoticationStatus(NotifyID:any)
  {
   
    return this.http.get(this.lService.ServiceIP + "/project/UpdateNoticationStatus(NotifyID="+ NotifyID +")");
  }
  UpdateNoticationStatus_FromSentID(NotificationFor:any,SentTo:any)
  {
   
    return this.http.get(this.lService.ServiceIP + "/project/NotficationStatusUpdatebyProjectorContractID(NotificationFor="+ NotificationFor +",SentTo="+ SentTo +")");
  }
}