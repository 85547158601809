import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';  
import {LoginService} from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardContractsNewService {

  constructor(private http:HttpClient,private lService: LoginService) { }

    getContractsByOrganisation(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract?$filter=FK_OrganistaionID eq " + OrganisationID);
    }

    

    // getTotalContracts(ContactID :any)
    // {
    //   return this.http.get(this.lService.ServiceIP +"/Contract/GettotalContracts(ContactID=" + ContactID +")");
    // }
    getTotalContracts(ContactID :any,OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GettotalContracts(ContactID=" + ContactID +",OrganisationID="+OrganisationID+")");
    }

    getTotalContractsValue(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/ContractFinance/GettotalContractsValue(ContactID=" + ContactID +")");
    }

    getTotalContractsValueByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/ContractFinance/GettotalContractsValueByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetPieChartContracts(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetPieChartContracts(ContactID=" + ContactID +")");
    }
    

    GetPieChartContractsByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetPieChartContractsByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetPieChartContractsValue(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetPieChartContractsValue(ContactID=" + ContactID +")");
    }
    

    GetPieChartContractsValueByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetPieChartContractsValueByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetChartContractsPriorityValue(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsPriorityValue(ContactID=" + ContactID +")");
    }

    GetChartContractsPriorityValueByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsPriorityValueByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetChartContractsCategoryValue(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsCategoryValue(ContactID=" + ContactID +")");
    }

    GetChartContractsCategoryValueByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsCategoryValueByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetChartContractsExpiryValue(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsExpiryValue(ContactID=" + ContactID +")");
    }

    GetChartContractsExpiryValueByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsExpiryValueByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetChartContractsProcurementValue(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsProcurementValue(ContactID=" + ContactID +")");
    }

    GetChartContractsProcurementValueByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsProcurementValueByOrg(OrganisationID=" + OrganisationID +")");
    }
    //added on 10/12/2019
    GetChartContractsExpiryNos(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsExpiryNo(ContactID=" + ContactID +")");
    }

    GetChartContractsExpiryNosByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsExpiryNoByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetChartContractsPriorityNo(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsPriorityNo(ContactID=" + ContactID +")");
    }

    GetChartContractsPriorityNoByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsPriorityNoByOrg(OrganisationID=" + OrganisationID +")");
    }
    //added on 11/12/2019
    GetChartContractsCategoryNo(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsCategoryNo(ContactID=" + ContactID +")");
    }

    GetChartContractsCategoryNoByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsCategoryNoByOrg(OrganisationID=" + OrganisationID +")");
    }

    GetChartContractsProcureValue_Real(ContactID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsProcurementValue_Real(ContactID=" + ContactID +")");
    }

    GetChartContractsProcureValueReal_ByOrg(OrganisationID :any)
    {
      return this.http.get(this.lService.ServiceIP +"/Contract/GetChartContractsProcurementValueReal_ByOrg(OrganisationID=" + OrganisationID +")");
    }

}
