import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {LoginService} from '../../login/login.service';

@Component({
  selector: 'app-hero-dashboard-contracts-database',
  templateUrl: './hero-dashboard-contracts-database.component.html',
  styleUrls: ['./hero-dashboard-contracts-database.component.scss']
})
export class HeroDashboardContractsDatabaseComponent implements OnInit {

  ImportbtnDisplay:boolean=false;
  constructor(private router:Router,private route:ActivatedRoute,private lService:LoginService) { 

    this.ShoworHideImportButton();
  }

  ngOnInit() {
  }

  ShoworHideImportButton()
  {
    this.lService.getContactUser(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    {
      if(data.ContractConfig =="No")
      {
        this.ImportbtnDisplay=false;
      }
      else
      {
        this.ImportbtnDisplay=true;
      }
    });
  }
  GotoImportDatePage()
  {
    this.router.navigate(['settings'],{ queryParams: {ShowDataImport: 'true'}});
  }
}
