import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import {LoginService} from '../login/login.service';
import {Router, ActivatedRoute} from '@angular/router';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  ResetPassword:FormGroup;
  show:boolean=false;
  constructor(private fb:FormBuilder,private lService:LoginService,private router: Router, private dialog: MatDialog) { 
    this.Clearsession();
    this.show=false;
  }

  ngOnInit() {
    this.ResetPassword=this.fb.group({
      emailFormControl: ['', [Validators.required, Validators.pattern('[ a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z ]{2,3}$')]],
    });
  }

  Clearsession()
  {
    sessionStorage.removeItem('ContactID');
    sessionStorage.removeItem('usertype');
    localStorage.removeItem('register');
    localStorage.removeItem('organisation');
    localStorage.removeItem('team');
    localStorage.removeItem('inviteuser');
    localStorage.removeItem('inviteClientAdmin');
  }

  PasswordReset()
  {
    if(!this.ResetPassword.valid)
    {
      return;
    }
    
    const formData=new FormData();
    formData.append("email",this.ResetPassword.get("emailFormControl").value);
    this.lService.ResetPassword(formData).subscribe((data:any)=>
    {
        if(data.RetStatus=="Success")
        {
          // this.ResetPassword.get('emailFormControl').reset();
          // alert("We just emailed you a link. \nPlease check your email and click the secure link.");
          // this.router.navigate(["/login"]);
          this.show=false;
          this.showdialog();
        }
        else if(data.RetStatus=="Email-ID not exists.")
        {
          this.ResetPassword.get('emailFormControl').reset();
          this.show=true;
          //alert("Incorrect email ID or user does not exists in the system");
          //this.router.navigate(["/login"]);
        }
        else
        {
          this.show=false;
          this.ResetPassword.get('emailFormControl').reset();
          alert(data.RetStatus);
        }
    });
  }

  showdialog() {
    
    let dialogRef = this.dialog.open(AlertDialogComponent,{data: {datakey:this.ResetPassword.get("emailFormControl").value}});
    

    dialogRef.afterClosed().subscribe(result => {
      // NOTE: The result can also be nothing if the user presses the `esc` key or clicks outside the dialog
      if (result == 'confirm') {
        this.router.navigate(["/login"]);
      }
    });
  }
  HideNotification()
  {
    this.show=false;
  }
}
