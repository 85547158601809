import { Component, OnInit } from '@angular/core';
import { InvitesuserService } from '../invitesuser.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface CategoryDelete {
  CategoryGroupDescription:string;
  Active:string;
}
@Component({
  selector: 'app-editcategorylist',
  templateUrl: './editcategorylist.component.html',
  styleUrls: ['./editcategorylist.component.scss']
})
export class EditcategorylistComponent implements OnInit {

  constructor(private InvserObj: InvitesuserService,private fb: FormBuilder) {
    //this.LoadCategoryMasterList();
    this.LoadCategoryList();
   }
   CategoryMaster:any;
   CategoryDeleteList:CategoryDelete[]=[];
   Categories:any;
   show:boolean=true;
   CategoryGroup : FormGroup;
   submitted:boolean=false;
   ngOnInit() {
     this.CategoryGroup = this.fb.group({
      txtcategory : ['',Validators.required],
     });
   }
   get f() { return this.CategoryGroup.controls; };
  //  LoadCategoryMasterList()
  //  {
    
  //    this.InvserObj.GetCategoryMaster().subscribe((data:any) => 
  //    {
  //      this.CategoryMaster=data;
       
  //      if(this.CategoryMaster.length>0)
  //      {
                
  //        this.CategoryGroup.get("ddlcategory").setValue(this.CategoryMaster[0].CategoryGroupDescription);
  //      }
  //      else
  //      {
  //        this.show=false;
  //      }
     
  //    });
  //  }
   LoadCategoryList()
   {
     this.InvserObj.GetSelectedCategories().subscribe((data:any) => 
     {
       this.Categories=data;
       //this.CategoryDeleteList=data;
      //console.log(data);
       
     });
   }
   UpdateCategory(cID:any,CategoryName:string)
   {
     const formData=new FormData();
     formData.append("CategoryID",cID);
     formData.append("CategoryGroupDescription",CategoryName);
     if(CategoryName.length > 30)
     {
      alert("Category Name cannot be greater than 30 characters!");
     }
     else
     {
      this.InvserObj.CategoryNameUpdate(formData).subscribe((data:any) =>
      {
       if(data.value =="Updated")
       {
         this.LoadCategoryList();
         alert("Category Updated Successfully.");
       }
       else
       {
         alert(data.value);
       }
      });
     }
    
    //alert(cID +"_" +Category);
   }

   RemoveCategory(Category:string)
   {

     for(let i=0;i<this.Categories.length;i++)
     {
       if(this.Categories[i].CategoryGroupDescription == Category)
       {
         //let jsonstring= '{"CategoryGroupDescription" :"'+this.Categories[i].CategoryGroupDescription +'"}';
         //this.CategoryMaster.push(JSON.parse(jsonstring));
         //console.log(this.CategoryMaster);
        //  let jsonstring= '{"CategoryGroupDescription" :"'+Category+'"}';
        //   this.CategoryDeleteList.push(JSON.parse(jsonstring));
        this.CategoryDeleteList.push({
          CategoryGroupDescription:Category,
          Active:"true"
        });
         this.Categories.splice(i, 1);
       
         //this.show=true;
         //this.CategoryGroup.get("ddlcategory").setValue(this.CategoryMaster[0].CategoryGroupDescription);
         //break;
       }
     }
   }
  
   AddCategorylist()
   {
    this.submitted = true;
    // stop here if form is invalid
    if (this.CategoryGroup.invalid) 
    {
      return;
    }
    let categoryexist=false;
    
     for(let p=0;p<this.Categories.length;p++)
     { 
       
       if(this.Categories[p].CategoryGroupDescription == this.CategoryGroup.get("txtcategory").value)
       {
       categoryexist=true;
        break;
        }
     }
     
        if(categoryexist == false)
        {
          let jsonstring= '{"CategoryGroupDescription" :"'+this.CategoryGroup.get("txtcategory").value+'"}';
          this.Categories.push(JSON.parse(jsonstring));
          this.CategoryGroup.get("txtcategory").setValue(" ");
        }
        else
        {
          alert("Category already exists!");
        }
  }
   SaveCategories()
   {
    if(this.Categories.length>0)
    {
     for(let i=0;i <this.Categories.length;i++)
       { 
         
         const formdata=new FormData();
        // formdata.append('FinanceYear', this.Categories[i].FinanceYear);
         formdata.append('CategoryGroupDescription',this.Categories[i].CategoryGroupDescription);
         formdata.append('ContactID',sessionStorage.getItem("ContactID"));
         if(i==0)
         {
         formdata.append('Status','true');
         }
         else
         {
           formdata.append('Status','false');
         }
         this.InvserObj.UpdateCategories(formdata).subscribe((data:any) => 
         {
           
           if(i== this.Categories.length-1)
           {
             if(this.CategoryDeleteList.length >0)
             {
              for(let j=0;j <this.CategoryDeleteList.length;j++)
              {
                const formdata1=new FormData();
                // formdata.append('FinanceYear', this.Categories[i].FinanceYear);
                 formdata1.append('CategoryGroupDescription',this.CategoryDeleteList[j].CategoryGroupDescription);
                 formdata1.append('ContactID',sessionStorage.getItem("ContactID"));
                 formdata1.append('Status',this.CategoryDeleteList[j].Active);
                 formdata1.append('DeleteStatus',this.CategoryDeleteList[j].Active);
                 this.InvserObj.UpdateCategories(formdata1).subscribe((data:any) => 
                  {

                  });
              }
              
             }

             alert("Saved Successfully");
               this.InvserObj.GetSelectedCategories().subscribe((data:any) => 
               {
                 this.Categories=data;
               
               });
           }
         });
         
       }
        
     }
     else
     {
       const formdata=new FormData();
      // formdata.append('FinanceYear', "No Data");
       formdata.append('CategoryGroupDescription',"No Data");
       formdata.append('ContactID',sessionStorage.getItem("ContactID"));
       
       formdata.append('Status','true');
       this.InvserObj.UpdateCategories(formdata).subscribe((data:any) => 
       {
         alert("Saved Successfully");
       });
      
     }
   }

}
