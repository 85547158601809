import { Component, OnInit, ViewChild,ViewEncapsulation } from '@angular/core';
import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';

@Component({
  selector: 'app-dialog-gantt-project',
  templateUrl: './dialog-gantt-project.component.html',
  styleUrls: ['./dialog-gantt-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogGanttProjectComponent implements OnInit {
  @ViewChild('editor' ,{static:true}) editor: GanttEditorComponent;
  public editorOptions: any = {};
  public data: any;
  constructor(private DbService:ProjectDatabaseService) { }

  ngOnInit() {
    this.BindGanttChart();
    
    this.editorOptions = {
     vFormatArr: ['Day', 'Week', 'Month', 'Quarter'],
     vFormat: 'week',
     vShowRes:(0),
     vDayColWidth:36,
     vWeekColWidth:54,
     vQuarterColWidth:36,
     vShowStartDate:(0),
     vShowEndDate:(0),
     vShowTaskInfoRes:(0),
     vShowTaskInfoNotes:(0),
     vMaxDate:"2025-12-31"
	 
	 
    };
    this.editor.setOptions(this.editorOptions);
  }

  async BindGanttChart()
  {
    //return this.DbService.GetAllProjectsbyContactIDForGanttChart(sessionStorage.getItem("ContactID"))
    //this.DbService.GetAllProjectsbyContactIDForGanttChart(sessionStorage.getItem("ContactID"))
    if(sessionStorage.getItem("usertype") == "Head of Procurement")
      {
      this.data=await this.DbService.GetAllProjectsbyContactIDForGanttChart(sessionStorage.getItem("ContactID"));
      }
      else
      {
        //this.data=await this.DbService.GetProjectsForNonHOPbyContactIDForGanttChart(sessionStorage.getItem("ContactID")); GetProjectGanttChartbyUserType

        this.data=await this.DbService.GetProjectGanttChartbyContactUserType(sessionStorage.getItem("ContactID"),sessionStorage.getItem("usertype"));
      }
  }
}
