//26052021
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { AppSettingsService } from '../service/app-settings.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {


  errorMessage: string;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  display = 'none'; //default Variable
  //show_button: Boolean = false;
  //show_eye: Boolean = false;
  hide: any = "password";
  show: boolean = false;
  chkStatus: boolean;
  isHidden: boolean = true;
  domain = '';
  buildVersion: string = "";
  loginLoading = false;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private lService: LoginService, private route: ActivatedRoute,
    private appSettings: AppSettingsService) {
    
    //this.Clearsession();
    this.isHidden = true;
    this.hide = "password";
    this.show = false;

  }
  


  ngOnInit() {
    this.domain = this.appSettings.siteKeys.domain;

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern('[ a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z ]{2,3}$')]],
      password: ['', Validators.required],
      termsFormControl: [true, [Validators.requiredTrue]]
      //rememberemail:[false]
    });
    //alert(sessionStorage.getItem("ApproveProject"));
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.Clearsession();
   
    //this.RememberMe();
    this.buildVersion = this.appSettings.siteKeys.BuildVersion;
  }
  get f() { return this.loginForm.controls; }

  
  // showPassword() {
  //   this.show_button = !this.show_button;
  //   this.show_eye = !this.show_eye;
  // }

  Clearsession() {
    sessionStorage.removeItem('ContactID');
    sessionStorage.removeItem('usertype');
    sessionStorage.removeItem('AdminPermission');
    sessionStorage.removeItem('userMail');
    localStorage.removeItem('register');
    localStorage.removeItem('organisation');
    localStorage.removeItem('team');
    localStorage.removeItem('inviteuser');
    localStorage.removeItem('inviteClientAdmin');
    localStorage.removeItem("rememberemail");
    sessionStorage.removeItem("IsSuperUser");
  }


  onSubmit() {
    this.isHidden = false;
    this.submitted = true;
   
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loginLoading = true;
    const formdata = new FormData();
    formdata.append('UserName', this.loginForm.get('username').value.trim());
    formdata.append('Password', this.loginForm.get('password').value);
    //console.log(this.loginForm.get('rememberemail').value);
    this.lService.Login(formdata).subscribe((data: any) => {
      this.loginLoading = false;
      console.log(data);
      //console.log(data.Status);
      if (data.Status == "Success") {
        sessionStorage.setItem('ContactID', data.ContactID);
        sessionStorage.setItem('usertype', data.UserType);
        sessionStorage.setItem('AdminPermission', data.AdminPermission);
        sessionStorage.setItem('userMail', data.ContactEmail);
        
        if (data.IsSuperUser) {
          sessionStorage.setItem('IsSuperUser', data.IsSuperUser);
        }
    


        // if(this.loginForm.get('rememberemail').value)
        // {

        //    localStorage.setItem("rememberemail",this.loginForm.get('username').value);
        // }
        // else
        // {
        //     localStorage.removeItem("rememberemail");
        // }
        //console.log("URL" + this.returnUrl);
        //this.router.navigate([this.returnUrl]); 
        if (data.PasswordCreate == "1") {
          if (sessionStorage.getItem("ApproveProject") != null) {
            this.router.navigateByUrl(sessionStorage.getItem("ApproveProject"));
          }
          else {
            if (this.domain == 'onesource') {
              this.router.navigate(['/dashboard/performanceonesource']);

            } else {
              if (data.AdminPermission == "Yes") {
                //this.router.navigate(['/createteam'], { queryParams: {valid: 'true', OrgID: data.OrgID}});
                this.router.navigate(['/dashboard/performance']);
              }
              else {
                this.router.navigate(['/dashboard/performance']);
              }
              //this.lService.getUserType(sessionStorage.getItem("usertype")).subscribe((data:any) =>{
            }

            //});                
          }
        }
        else {
          this.router.navigate(['/createpassword']);
        }
      }
      else {
        this.loginForm.controls['password'].reset();
        //alert("Invalid UserName/Password");
        this.show = true;
      }
    });
  }

  openModalDialog() {
    this.display = 'block'; //Set block css
  }

  closeModalDialog() {
    this.display = 'none';
  }
  HideNotification() {
    this.show = false;
  }
  //  RememberTermsandCondition()
  //  {
  //   var termsFormControl=localStorage.getItem("termsFormControl");
  //   if(termsFormControl !=null)
  //   {
  //     this.loginForm.get('termsFormControl').setValue(true);
  //   }
  //  }
  //  RememberMe()
  //  {
  //    var RememberEmail=localStorage.getItem("rememberemail");
  //    console.log(RememberEmail);
  //    if( RememberEmail !=null)
  //    {
  //     this.loginForm.get("username").setValue(localStorage.getItem("rememberemail"));
  //     this.loginForm.get('rememberemail').setValue(true);
  //    }


  //  }
}
