import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';

@Component({
  selector: 'app-dialog-resubmit-project-timetable-contract-dates',
  templateUrl: './dialog-resubmit-project-timetable-contract-dates.component.html',
  styleUrls: ['./dialog-resubmit-project-timetable-contract-dates.component.scss']
})
export class DialogResubmitProjectTimetableContractDatesComponent implements OnInit {
  txtStartDate : any;
  txtEndDate : any;
  isDisabled : boolean = false;
  ddlContractDateChanges: any;
  saveCaption: string = "Continue to Next Step";
  constructor(private DbService : ProjectDatabaseService,public dialogRef: MatDialogRef<DialogResubmitProjectTimetableContractDatesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    //alert(this.data.ProjectID);
    this.getContractDates();
    this.ddlContractDateChanges = "yes";
    
    
  }

  getContractDates()
  {
    this.DbService.getContractandprojectDataby(this.data.ProjectID).subscribe((data:any)=>
    {
      //alert(data.ContractStartDate);
       this.txtStartDate = data.ContractStartDate;
       this.txtEndDate =  data.ContractEndDate;
       this.ddlContractDateChanges = "yes";
      // alert(sessionStorage.getItem('ResubmitContractYes'));
       if(sessionStorage.getItem('ResubmitContractYes') != null)
       {
       if(sessionStorage.getItem('ResubmitContractYes') != "null")
        {
          //alert(sessionStorage.getItem('ResubmitStartDate'));
          //alert(sessionStorage.getItem('ResubmitEndDate'));
          this.ddlContractDateChanges = sessionStorage.getItem('ResubmitContractYes');
          this.txtStartDate = sessionStorage.getItem('ResubmitStartDate');
          this.txtEndDate =  sessionStorage.getItem('ResubmitEndDate');
          this.OptionChanged();
        }
      }
    });
  }

  OptionChanged()
  {
    this.saveCaption = "Continue to Next Step";
      if(this.ddlContractDateChanges == "yes")
      {
        //alert("yes");
        this.isDisabled =false;
      }else{
        //alert("no");
        this.isDisabled = true;
        this.saveCaption = "Resubmit Project Timetable";
      }
  }

  Save()
  {
    var datepipe = new DatePipe('en-US');
    if(this.txtStartDate  == "")
   {
         alert("Please select Start Date !");
         return;
   }
   if(this.txtEndDate  == "")
   {
         alert("Please select End Date !");
         return;
   }
    //
    //alert(this.txtEndDate);
    if(datepipe.transform(this.txtEndDate,'yyyy-MM-dd') <= datepipe.transform(this.txtStartDate,'yyyy-MM-dd'))
    {
      alert("Start Date cannot be greater than End Date !");
      return;
          
    }
    if(this.ddlContractDateChanges == "yes")
      {
       
      
    sessionStorage.setItem('ResubmitContractYes',this.ddlContractDateChanges);
    sessionStorage.setItem('ResubmitStartDate',  datepipe.transform(this.txtStartDate,'yyyy-MM-dd'));
    sessionStorage.setItem('ResubmitEndDate',  datepipe.transform(this.txtEndDate,'yyyy-MM-dd'));
      }
      else
      {
        sessionStorage.setItem('ResubmitContractYes',this.ddlContractDateChanges);
        sessionStorage.setItem('ResubmitStartDate',  datepipe.transform(this.txtStartDate,'yyyy-MM-dd'));
        sessionStorage.setItem('ResubmitEndDate',  datepipe.transform(this.txtEndDate,'yyyy-MM-dd'));
        
      }
    this.dialogRef.close();
  }

}
