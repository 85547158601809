import { Component, OnInit,Inject } from '@angular/core';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import { MatDialog, TransitionCheckState } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {DashboardContractDetailsService} from '../../dashboard/dashboard-contract-details/dashboard-contract-details.service';
import{DialogUploadContractDocumentsComponent} from '../dialog-upload-contract-documents/dialog-upload-contract-documents.component';
import {DialogAddNewSupplierComponent} from '../dialog-add-new-supplier/dialog-add-new-supplier.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dialog-project-closure',
  templateUrl: './dialog-project-closure.component.html',
  styleUrls: ['./dialog-project-closure.component.scss']
})
export class DialogProjectClosureComponent implements OnInit {

  ProjectTitle:string;
  ContractID:any; 
  Documents:boolean=false;
  DisableApproval:boolean=true;
  DocumentsData:any;
  Suppliers:boolean=false;
  SuppliersData:any;
  ActionButton:string="Submit for Approval";
  constructor(private DbService:ProjectDatabaseService,public dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,private cService:DashboardContractDetailsService,
  private router:Router) { 
    if(sessionStorage.getItem("usertype") =="Team Manager" || sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.ActionButton="Close Project";
    }
    else
    {
      this.ActionButton="Submit for Approval";
    }
  }

  ngOnInit() {
    this.GetProjectData(this.data.ProjectID);
   
  }

  GetProjectData(ProjectID:any)
  {
    this.DbService.getProjectDatabyProjectID(ProjectID).subscribe((data:any) =>
    {
      this.ProjectTitle=data.ProjectTitle;
      this.ContractID=data.FK_ContractID;
      this.getDocumentsCount(data.FK_ContractID);
      this.getSupplierCount(data.FK_ContractID);
    
    });
  }
  getDocumentsCount(ContractID:any)
  {
    this.cService.getContractDocuments(ContractID).subscribe((data:any) =>
    {
      this.DocumentsData=data.value;
      
        if(this.DocumentsData.length >0)
        {
          
          this.Documents=true;
          if(this.Documents && this.Suppliers)
          {
            this.DisableApproval=false;
          }
        }
       
    });
  }
  getSupplierCount(ContractID:any)
  {
    this.cService.getContractSuppliers(ContractID).subscribe((data:any) =>
    {
      this.SuppliersData=data;
      
        if(this.SuppliersData.length >0)
        {
         
          this.Suppliers=true;
          if(this.Documents && this.Suppliers)
          {
            this.DisableApproval=false;
          }
        }
       
    });
  }

  onUploadFileChange(event:any):void
  {
    if (event.target.files.length > 0) {
      let ErrorCount=0;
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];

        const formdata=new FormData();
        formdata.append('PK_ContractID',this.ContractID);
        formdata.append('ContractFile', file);
        this.cService.SaveContractDocument(formdata).subscribe((data:any) =>
        {
          
            if(data !="success")
            {
              ErrorCount+=1;
            }
        });
      }
      if(ErrorCount ==0)
      {
        alert("Upload successful !");
      }
      else
      {
          alert("Something went wrong !");
            
      }
      this.getDocumentsCount(this.ContractID);
    }
  }

  GotoContractDetails()
  {
    this.dialog.closeAll();
    this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: this.ContractID,ProjectID:this.data.ProjectID,"ViewDocument":"true"}});
  }

  openDialogUploadContractDocument()
  {
    
    const dialogRef = this.dialog.open(DialogUploadContractDocumentsComponent, { data :{ContractID:this.ContractID},
    width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.getDocumentsCount(this.ContractID);
    });
  }
  openDialogAddNewSupplier()
  {
    // const dialogRef = this.dialog.open(DialogAddNewSupplierComponent, { data :{ContractID:this.ContractID},
    //   width: '740px'
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(`Dialog result: ${result}`);
    //     this.getSupplierCount(this.ContractID);
    //   });
    this.dialog.closeAll();
    this.router.navigate(['dashboard/contract/details'], { queryParams: {ContractID: this.ContractID,ProjectID:this.data.ProjectID,"AddSupplier":"true"}});
  }

  SubmitforApproval()
  {
    if(sessionStorage.getItem("usertype") =="Team Manager" || sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.DbService.UpdateProjectStatus(this.data.ProjectID,"Closed").subscribe((data:any) =>
      {
          //alert("Project approval request accepted.");
          alert("Project closed successfully!");
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['dashboard/project/details'], { queryParams: {ProjectID: this.data.ProjectID}});
          this.dialog.closeAll();
      });
    }
    else
    {
      this.DbService.ProjectClosureMailSend(sessionStorage.getItem("ContactID"),this.data.ProjectID,this.ContractID).subscribe((data:any) =>
      {
        if(data.RetStatus =="Success")
        {
          //alert("Your request for Project Approval Submitted Successfully");
          //alert("Your completed project has been submitted for approval");
          alert("Your request to change the status of your project has been submitted for approval");
          this.router.navigate(['dashboard/project/details'], { queryParams: {ProjectID: this.data.ProjectID}});
          this.dialog.closeAll();
        }
        else
        {
          alert(data.RetStatus);
        }
      });
    }
  }
}
