import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewProjectComponent } from '../../dialogs/dialog-new-project/dialog-new-project.component';
import { Router } from '@angular/router';
import {DialogGanttProjectComponent} from '../../dialogs/dialog-gantt-project/dialog-gantt-project.component';

@Component({
  selector: 'app-hero-dashboard-projects-database',
  templateUrl: './hero-dashboard-projects-database.component.html',
  styleUrls: ['./hero-dashboard-projects-database.component.css']
})
export class HeroDashboardProjectsDatabaseComponent implements OnInit {
  router: Router;
  constructor(public dialog: MatDialog, _router: Router) { 
    this.router = _router;
  }

  ngOnInit() {
  }

  openDialogNewProject() {
    const dialogRef = this.dialog.open(DialogNewProjectComponent, {data: {From:"Project Database"}});

    dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
            this.router.navigate(['/dashboard/contracts/filter'], { queryParams: {Type: 'Re-procure', Extend:1}});
            //this.router.navigateByUrl('/dashboard/contracts');
        } else if (result === false) {
            this.router.navigateByUrl('/dashboard/projects/new-project');
            //this.router.navigate(['/dashboard/contracts'], { queryParams: {Type: 'New'}});
        }
    });
}

openDialogGanttChart()
{
  const dialogRef = this.dialog.open(DialogGanttProjectComponent, {
    width: '1366px'
  });
  
  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    
  });
}

}
