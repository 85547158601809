import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeroDashboardPerformanceService {
  visible: boolean;
  constructor() { 
    this.visible=true;
  }
}
