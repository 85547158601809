import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {LoginService} from '../../login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import {DatePipe} from '@angular/common';


export interface ProjectMilestone {
  task: string;
  startDate?: Date;
  endDate?: Date;
  numberOfDays?: number;
  dateCreated?: Date;
  dateApproved?: Date;
  reasonForChange?: string;
  impactDays?: number;
  impactPercent?: number;
}

export interface ContractStartDate {
  startDate: Date;
  dateCreated?: Date;
  dateApproved?: Date;
  reasonForChange?: string;
  impactDays?: number;
}

export interface ContractFinancial {
  projectedSavings: number;
  dateCreated?: Date;
  dateApproved?: Date;
  reasonForChange?: string;
  impactSavings?: number;
  impactPercentage?: number;
}

const PROJECT_MILESTONE_INITIATION_DATA: ProjectMilestone[] = [
  {
      task: 'Task 1'
  },
  {
      task: 'Task 2'
  }
];

const PROJECT_MILESTONE_PLANNING_DATA: ProjectMilestone[] = [
  {
      task: 'Task 1'
  },
  {
      task: 'Task 2'
  }
];

const PROJECT_MILESTONE_EXECUTION_1_DATA: ProjectMilestone[] = [
  {
      task: 'Task 1'
  },
  {
      task: 'Task 2'
  }
];

const PROJECT_MILESTONE_EXECUTION_2_DATA: ProjectMilestone[] = [
  {
      task: 'Task 1'
  },
  {
      task: 'Task 2'
  }
];

const CONTRACT_START_DATE_DATA: ContractStartDate[] = [
  {
      startDate: new Date('2019/Sep/23'),
      dateCreated: new Date('2019/Sep/23'),
      dateApproved: new Date('2019/Sep/24')
  },
  {
      startDate: new Date('2019/Oct/14'),
      dateCreated: new Date('2019/Oct/1'),
      dateApproved: new Date('2019/Oct/3'),
      impactDays: -21
  },
  {
      startDate: new Date('2019/Nov/1'),
      impactDays: -39
  }
];

const CONTRACT_FINANCIALS_20182019_DATA: ContractFinancial[] = [
  {
      projectedSavings: 1000000,
      dateCreated: new Date('2019/Dec/12'),
      dateApproved: new Date('2019/Dec/15')
  },
  {
      projectedSavings: 800000,
      dateCreated: new Date('2020/Jan/1'),
      dateApproved: new Date('2020/Jan/10'),
      impactSavings: -200000,
      impactPercentage: -0.20
  },
  {
      projectedSavings: 600000,
      impactSavings: -400000,
      impactPercentage: -0.40
  }
];

const CONTRACT_FINANCIALS_20192020_DATA: ContractFinancial[] = [
  {
      projectedSavings: 1000000,
      dateCreated: new Date('2019/Dec/12'),
      dateApproved: new Date('2019/Dec/15')
  },
  {
      projectedSavings: 800000
  },
  {
      projectedSavings: 600000
  }
];

const CONTRACT_FINANCIALS_20202021_DATA: ContractFinancial[] = [
  {
      projectedSavings: 1000000,
      dateCreated: new Date('2019/Dec/12'),
      dateApproved: new Date('2019/Dec/15')
  },
  {
      projectedSavings: 800000
  },
  {
      projectedSavings: 600000
  }
];


@Component({
  selector: 'app-reports-project',
  templateUrl: './reports-project.component.html',
  styleUrls: ['./reports-project.component.scss']
})
export class ReportsProjectComponent implements OnInit {

  OwnerName:string;
  OwnerEmail:string;
  DateAssign:any;

  projectPlanDisplayedColumns = ['StartDate', 'EndDate', 'NumberofDays', 'Created', 'DateApproved', 'ReasonforChange', 'ImpactDays', 'ImpactPercentage'];
  //projectPlanDataSource = new MatTableDataSource(PROJECT_PLAN_DATA);
  projectPlanDataSource:any;
  projectMilestoneDisplayedColumns = ['PhaseName','StartDate', 'EndDate', 'NumberofDays', 'Created', 'DateApproved', 'ImpactDays', 'ImpactPercentage'];
  projectMilestoneDataSource:any;
  //projectMilestoneInitiationDisplayedColumns = ['task', 'startDate', 'endDate', 'numberOfDays', 'dateCreated', 'dateApproved', 'reasonForChange', 'impactDays', 'impactPercent'];
  //projectMilestoneInitiationDataSource = new MatTableDataSource(PROJECT_MILESTONE_INITIATION_DATA);
  // projectMilestonePlanningDisplayedColumns = ['task', 'startDate', 'endDate', 'numberOfDays', 'dateCreated', 'dateApproved', 'reasonForChange', 'impactDays', 'impactPercent'];
  // projectMilestonePlanningDataSource = new MatTableDataSource(PROJECT_MILESTONE_PLANNING_DATA);
  // projectMilestoneExecution1DisplayedColumns = ['task', 'startDate', 'endDate', 'numberOfDays', 'dateCreated', 'dateApproved', 'reasonForChange', 'impactDays', 'impactPercent'];
  // projectMilestoneExecution1DataSource = new MatTableDataSource(PROJECT_MILESTONE_EXECUTION_1_DATA);
  // projectMilestoneExecution2DisplayedColumns = ['task', 'startDate', 'endDate', 'numberOfDays', 'dateCreated', 'dateApproved', 'reasonForChange', 'impactDays', 'impactPercent'];
  // projectMilestoneExecution2DataSource = new MatTableDataSource(PROJECT_MILESTONE_EXECUTION_2_DATA);
  contractDisplayColumns = ['StartDate', 'Created', 'DateApproved', 'ReasonforChange', 'ImpactDays'];
  //contractStartDateDataSource = new MatTableDataSource(CONTRACT_START_DATE_DATA);
  contractDataSource:any;
  contractFinancialSavingsDisplayColumns = ['FinancialYearDisplay', 'Financevalue','CreatedDate', 'DateApproved', 'Savings', 'PercentDone'];
  contractFinancialSavingsDataSource:any;
  //contractFinancial20182019DataSource = new MatTableDataSource(CONTRACT_FINANCIALS_20182019_DATA);
  contractFinancialSpendDisplayColumns = ['FinancialYearDisplay', 'Financevalue','CreatedDate', 'DateApproved', 'Savings', 'PercentDone'];
  contractFinancialSpendDataSource:any;
  //contractFinancial20192020DataSource = new MatTableDataSource(CONTRACT_FINANCIALS_20192020_DATA);
  //contractFinancial20202021DisplayColumns = ['projectedSavings', 'dateCreated', 'dateApproved', 'reasonForChange', 'impactSavings', 'impactPercentage'];
  //contractFinancial20202021DataSource = new MatTableDataSource(CONTRACT_FINANCIALS_20202021_DATA);
  constructor(private DbService:ProjectDatabaseService,private router:Router, private route:ActivatedRoute) 
  { 
    if(sessionStorage.getItem("ContactID") !=null)
    {
      if(this.route.snapshot.queryParams['ProjectID'] !=null)
      {
        this.getProjectDetails(this.route.snapshot.queryParams['ProjectID']);
        this.getProjectPlan(this.route.snapshot.queryParams['ProjectID']);
        this.getProjectMileStoneHistory(this.route.snapshot.queryParams['ProjectID']);
        this.getContractHistory(this.route.snapshot.queryParams['ProjectID']);
        this.getFinanceSavingsHistory(this.route.snapshot.queryParams['ProjectID']);
        this.getFinanceSpendHistory(this.route.snapshot.queryParams['ProjectID']);
      }
    }
    else
    {
      this.router.navigate(["/login"]);      
    }
  }

  ngOnInit() {
  }

  getProjectDetails(ProjectID:any)
  { 
    var datepipe = new DatePipe('en-US');
    this.DbService.getContractandprojectDataby(ProjectID).subscribe((data:any)=>
    { this.OwnerName=data.OwnerFirstName;
      this.OwnerEmail=data.OwnerEmail;
      this.DateAssign=datepipe.transform(data.DateCreated,'dd/MM/yyyy');

    });
  }
  getProjectPlan(ProjectID:any)
  {
    this.DbService.GetProjecPlanHistoryByProjectID(ProjectID).subscribe((data:any) =>
    {
      this.projectPlanDataSource=new MatTableDataSource(data);
    });
  }
  getProjectMileStoneHistory(ProjectID:any)
  {
    this.DbService.GetProjecMileStoneHistoryByProjectID(ProjectID).subscribe((data:any) =>
    {
      this.projectMilestoneDataSource=new MatTableDataSource(data);
    });    
  }
  getContractHistory(ProjectID:any)
  {
    this.DbService.GetContractHistoryByProjectID(ProjectID).subscribe((data:any) =>
    {
      this.contractDataSource=new MatTableDataSource(data);
    });
  }
  getFinanceSavingsHistory(ProjectID:any)
  {
    this.DbService.GetProjectFinanceSavingsHistorybyProjectID(ProjectID).subscribe((data:any) =>
    {
      this.contractFinancialSavingsDataSource=new MatTableDataSource(data);
    });
  }
  getFinanceSpendHistory(ProjectID:any)
  {
    this.DbService.GetProjectFinanceSpendHistorybyProjectID(ProjectID).subscribe((data:any) =>
    {
      this.contractFinancialSpendDataSource=new MatTableDataSource(data);
    });
  }
}
