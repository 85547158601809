import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettingsService } from '../service/app-settings.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public ServiceIP: any;
  public domain: string;

  constructor(private http: HttpClient, appSettings: AppSettingsService) {
    this.ServiceIP = appSettings.siteKeys.ServiceIP;
    this.domain = appSettings.siteKeys.domain;

    // this.ServiceIP="https://onesource.i-procuretech.net:1222/";
    //this.ServiceIP="https://uat.i-procuretech.net:1220/";
    // this.ServiceIP="https://psprocure.i-procuretech.net:1221/";
    //this.ServiceIP="http://68.169.60.139:1311";
    //this.ServiceIP="https://dev.i-test.com:1221";
    //this.ServiceIP="http://development.i-procuretech.net:1220/";
    //this.ServiceIP="https://metropolitan.i-procuretech.net:1224/";
    //this.ServiceIP="http://localhost:50613";
    //this.ServiceIP="https://enfield.i-procuretech.net:1223/";
  }

  Login(formdata: any) {
    //var a =this.ServiceIP + "/users/Login";
    //console.log("hi" + model.UserName);
    

    return this.http.post(this.ServiceIP + "/contact/LoginUser", formdata);
  }

  Createnewpassword(formData: any) {
    return this.http.post(this.ServiceIP + "/contact/CreatenewPassword", formData);
  }

  CreatenewPassword_ForAccount(formData: any) {
    return this.http.post(this.ServiceIP + "/contact/CreatenewPassword_ForAccount", formData);
  }

  PersonalDetailsUpdate(formData: any) {
    return this.http.post(this.ServiceIP + "/contact/PersonalDetailsUpDate", formData);
  }

  EmailExists(EmailID: string) {
    return this.http.get(this.ServiceIP + "/contact/EmailExists(Email='" + EmailID + "')");
  }
  ResetPassword(formData: any) {
    return this.http.post(this.ServiceIP + "/contact/ResetPassword", formData);
  }

  getUserType(UserType_ID: string) {
    return this.http.get(this.ServiceIP + "/usertype/" + UserType_ID + "");
  }

  GetUserTypesforInviteUser() {
    return this.http.get(this.ServiceIP + "/usertype/GetUserTypesforInviteUser");
  }
  getContactUser(ContactID: any) {
    return this.http.get(this.ServiceIP + "/contact/" + ContactID + "");
  }
  async getContactUserForMyAccount(email: any) {
    return await this.http.get(this.ServiceIP + "/contact/GetContactDetail(Email='" + email + "')").toPromise();
  }

  getContactByEmail(email: any) {
    return this.http.get(this.ServiceIP + "/contact/GetContactDetail(Email='" + email + "')");
  }
}
