import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'; 
import {LoginService} from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  

  constructor(private http:HttpClient,private lService: LoginService) { 
    
  }

  CreateContact(formData:any)
  {
    return this.http.post(this.lService.ServiceIP + "/contact/", formData);
  }
  CheckEmailExists(EmailID:string)
  {
    return this.http.get(this.lService.ServiceIP + "/contact/EmailExists(Email='"+ EmailID +"')");
  }
}
