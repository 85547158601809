import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {LoginService} from './login.service';
import { AppSettingsService } from '../service/app-settings.service';
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',  
  encapsulation: ViewEncapsulation.None
})

export class DefaultComponent implements OnInit {
  
  
  
  constructor(private router: Router,private formBuilder:FormBuilder,
    private lService: LoginService, private route: ActivatedRoute,
    appSettings:AppSettingsService) {
   
    
   }

  

  ngOnInit() {
   
  }
  

  
  


  

 
 


}
