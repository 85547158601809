import { Component, OnInit, Inject } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { MatDialog } from '@angular/material';
// import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-new-project',
  templateUrl: './dialog-new-project.component.html',
  styleUrls: ['./dialog-new-project.component.scss']
})
export class DialogNewProjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
