import { Component, OnInit,Inject } from '@angular/core';
import { InvitesuserService } from "../invitesuser.service";
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { InvitesuserComponent } from '../invitesuser.component';
import {ReassignprojectComponent} from '../reassignproject/reassignproject.component';
import {AlertComponent} from '../../alert/alert.component';
import { DashboardPerformanceService } from 'src/app/dashboard/dashboard-performance/dashboard-performance.service';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {
  visible:boolean = true;
  Roles:any;
Teams:any;
contactDetails:any;
newcontract : FormGroup;
submitted:boolean=false;
Organisations:any;
NonHOP:boolean=false;
EmailEnable:boolean=false;
btnRemoveUser:boolean=true;
  constructor(private IService:InvitesuserService,private fb: FormBuilder,public dialog: MatDialog ,@Inject(MAT_DIALOG_DATA) public data: any,
  private mtdg:MatDialog,private Dservice:DashboardPerformanceService) {
    
    this.getInviteeRoles();
    //this.getUserLoadTeams();
    this.getOrganisations();
   //alert(this.data.TeamID);

    if(this.data.OrgID == 0)
    {
      this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
      {
        this.data.OrgID = tdata.value[0].OrganisationID;
      });
    }
   }

  ngOnInit() {
    this.newcontract = this.fb.group({
      txtfirstname : ['',Validators.required],
      txtlastname : ['',Validators.required],
      ddlRole : ['',Validators.required],
      ddlaccountstatus : ['',Validators.required],
      ddlconfig :['',Validators.required],
      ddladmin :['',Validators.required],
      ddlCategory : ['',Validators.required],
      ddlOrganisation:['',Validators.required],
      txtemailaddress : ['',[Validators.required, Validators.email]],
   });

    this.GetContactDetails(this.data.Email);
  }

  openDialogRemoveUser()
  {
   
    //this.dialog.closeAll();
    const dialogRef = this.dialog.open(AlertComponent, { data :{RetStatus:"ReassignProject",ContactID:this.data.ContactID,TeamID:this.data.TeamID},
    width: '580px'
    });

    // const dialogRef = this.dialog.open(ReassignprojectComponent, { data: {ContactID:this.data.ContactID,TeamID:this.data.TeamID},
    //   width: '730px'
    // });

    dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        if(result==true)
        {
          //alert(this.data.TeamID);
          //this.dialog.closeAll();
          const dialogRefreassign = this.dialog.open(ReassignprojectComponent, { data: {ContactID:this.data.ContactID,TeamID:this.data.TeamID},
            width: '730px'
           });
           dialogRefreassign.afterClosed().subscribe(result => {
            console.log(`Reassign Dialog result: ${result}`);
           });
        }
    });
  
  }

  getInviteeRoles()
  {
    this.IService.GetConctactDetails(this.data.Email).subscribe((data:any) =>
    {
      if(data.ContactUser_Type =="1")
      {
        
        this.Roles=[{ UserType_Name: 'Head of Procurement',UserType_ID:'1'}];
        this.NonHOP=true;
        this.EmailEnable=false;
        this.btnRemoveUser=false;
      }
      else
      {
      
        this.btnRemoveUser=true;
        this.NonHOP=false;
        this.EmailEnable=false;
        if(sessionStorage.getItem("usertype") =="Head of Procurement")
        { 
          this.IService.GetUserTypesforInviteUserwithoutHOP().subscribe((data:any) =>
          {
              this.Roles=data;
             // this.newcontract.get("ddlRole").setValue(this.Roles[0].UserType_ID);
          });
        }
        else
        {
          this.IService.GetUserTypesforInviteUser().subscribe((data:any) =>
          {
              this.Roles=data;
              //this.newcontract.get("ddlRole").setValue(this.Roles[0].UserType_ID);
          });
        }
      }
    });
    
  }

  ContactFirstName:any;
  LastName:any;
  EmailAddress:any;
  Role:any;
  AccountStatus:any;
  Contractconfig:any;
  Admin:any;
  AllocateTeam:any;
  GetContactDetails(Email:any)
  {

    this.IService.GetConctactDetails(Email).subscribe((data:any) =>
    {
      this.contactDetails=data;
      this.newcontract.get("txtfirstname").setValue(this.contactDetails.ContactFirstName) ;
      this.ContactFirstName=this.contactDetails.ContactFirstName;
      this.newcontract.get("txtlastname").setValue(this.contactDetails.ContactSurname );
      this.LastName=this.contactDetails.ContactSurname;
      this.newcontract.get("txtemailaddress").setValue(this.contactDetails.ContactEMailAddress) ;
      this.EmailAddress=this.contactDetails.ContactEMailAddress;
      this.newcontract.get("ddlRole").setValue(this.contactDetails.ContactUser_Type) ;
      
      this.Role=this.contactDetails.ContactUser_Type;
      this.newcontract.get("ddlaccountstatus").setValue(this.contactDetails.ContractConfig =="1"?"Inactive":"Active") ;
      this.AccountStatus=this.contactDetails.ContractConfig;
      this.newcontract.get("ddlconfig").setValue(this.contactDetails.ContractConfig );
      this.Contractconfig=this.contactDetails.ContractConfig;
       this.newcontract.get("ddladmin").setValue(this.contactDetails.AdminPerMission) ;
       this.Admin=this.contactDetails.AdminPerMission;
      //this.newcontract.get("ddlCategory").setValue(this.contactDetails.TeamID);
      this.newcontract.get("ddlCategory").setValue(this.data.TeamID);
      //this.AllocateTeam=this.contactDetails.TeamID;
      this.AllocateTeam=this.data.TeamID;
    });
  }
  get f() { return this.newcontract.controls; };
  SaveNewContract()
  {
    this.submitted = true;
     // stop here if form is invalid
     if (this.newcontract.invalid) 
     {
       return;
     }
     const formdata=new FormData();
     formdata.append('FirstName', this.newcontract.get("txtfirstname").value);
     formdata.append('Config', this.newcontract.get("ddlconfig").value );
     formdata.append('LastName', this.newcontract.get("txtlastname").value);
     formdata.append('EmailAddress', this.newcontract.get("txtemailaddress").value);
     formdata.append('Admin', this.newcontract.get("ddladmin").value);
     formdata.append('Team', this.newcontract.get("ddlCategory").value);
     formdata.append('AccountStatus',this.newcontract.get("ddlaccountstatus").value== "Active" ? "0": "1");
     formdata.append('Role',this.newcontract.get("ddlRole").value);
     formdata.append('Organization',this.data.OrgID);
     formdata.append('ContactID', this.data.ContactID);
     formdata.append('CurrentTeam',this.data.TeamID);
     this.IService.UpdateContact(formdata).subscribe((data:any) =>
     {
           if(data.RetStatus =="Updated")
           {
             alert("User data Updated successfully !");
             if(this.ContactFirstName !=  this.contactDetails.ContactFirstName)
             {
               this.ContactFirstName=this.newcontract.get("txtfirstname").value;
             }
             else
             {
              this.ContactFirstName=null;
             }
             if(this.LastName !=  this.contactDetails.ContactSurname)
             {
               this.LastName=this.newcontract.get("txtlastname").value;
             }
             else
             {
              this.LastName=null;
             }
             if(this.EmailAddress !=  this.contactDetails.ContactEMailAddress)
             {
               this.EmailAddress=this.newcontract.get("txtemailaddress").value;
             }
             else
             {
              this.EmailAddress=null;
             }
             if(this.Contractconfig ! = this.contactDetails.ContractConfig)
             {
               this.Contractconfig=this.newcontract.get("ddlconfig").value;
             }
             else
             {
              this.Contractconfig=null;
             }
             if(this.AccountStatus != this.contactDetails.disabled)
             {
               this.AccountStatus=this.newcontract.get("ddladmin").value;
             }
             else
             {
              this.AccountStatus=null;
             }
             if(this.AllocateTeam != this.contactDetails.AllocateTeam)
             {
               this.AllocateTeam=this.newcontract.get("ddlCategory").value;
             }
             else
             {
              this.AllocateTeam=null;
             }
             if(this.Role != this.contactDetails.ContactUser_Type)
             {
               this.Role=this.newcontract.get("ddlRole").value;
             }
             else
             {
              this.Role=null;
             }
             if(this.Admin != this.contactDetails.AdminPerMission)
             {
               this.Admin=this.newcontract.get("ddlaccountstatus").value;
             }
             else
             {
              this.Admin=null;
             }
             const formdataLog=new FormData();
             formdataLog.append('FirstName', this.ContactFirstName);
             formdataLog.append('Config', this.Contractconfig);
             formdataLog.append('LastName', this.LastName);
             formdataLog.append('EmailAddress', this.EmailAddress);
             formdataLog.append('Admin', this.Admin);
             formdataLog.append('Team', this.AllocateTeam);
             formdataLog.append('AccountStatus',this.AccountStatus);
             formdataLog.append('Role',this.Role);
             formdataLog.append('ContactID', this.data.ContactID);
             this.IService.UpdateContactLog(formdataLog).subscribe((data:any) =>
             {
                
                 if(data.RetStatus =="Updated")
                 {
                   //alert("Contract Updated sucessfully !");
                   this.dialog.closeAll();
                 }
                 else
                 {
                  alert("Something went wrong !");
                 
                 }
             });
           }
           else if(data.RetStatus =="Exists")
           {
            alert("Email-ID already exists. Try with different Email-ID");
           }
           else if(data.RetStatus =="Team already exists")
           {
            alert("Selected Team already alloacted for this User.");
           }
           else
           {
            alert("Something went wrong !");
           
           }
       });
       
      
 
  }
  RemoveUser()
  {
    //alert('hi');
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(InvitesuserComponent, { data: {ContactID:this.data.ContactID},
      width: '730px'
    });
    
  }
  getUserLoadTeams()
  {
    //alert(this.data.OrgID);
   if(this.data.OrgID == "0")
   {
    this.IService.getTeamsbyContactID(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
    {
      this.Teams=data;
      this.newcontract.get("ddlCategory").setValue(this.Teams[0].TeamID);
    });
   }
   else
   {
      this.IService.getTeamsbyOrganisation(this.data.OrgID).subscribe((data:any) =>
      {
        this.Teams=data.value;
     
      });
   }
  }

  getOrganisations()
  {
    if(sessionStorage.getItem("usertype")=="Head of Procurement")
    {
      this.Dservice.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((odata:any)=>
      {
        this.Organisations=odata.value;
        
        if(this.data.OrgID != 0)
        {
          this.newcontract.get("ddlOrganisation").setValue(this.data.OrgID);
          this.getHOPTeamsByOrganisation(this.data.OrgID);
        }
        else
        {
          
          this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
          {
            this.newcontract.get("ddlOrganisation").setValue(tdata.value[0].OrganisationID);
            this.getHOPTeamsByOrganisation(tdata.value[0].OrganisationID);
          });
          //this.newcontract.get("ddlOrganisation").setValue(81);
        }
       
      });
    }
    else
    {
      this.IService.GetAssignedOrganisationForUser(Number(sessionStorage.getItem("ContactID"))).subscribe((adata:any) =>
      {
        this.Organisations=adata;
        //console.log("OrgData" + adata);
        if(this.data.OrgID !=0)
        {
          this.newcontract.get("ddlOrganisation").setValue(this.data.OrgID);
          //this.getHOPTeamsByOrganisation(this.data.OrgID);
          // this.getTeamsByOrganisation(this.data.OrgID);
          this.getNonHOPTeamsByOrganisation(Number(sessionStorage.getItem("ContactID")),this.data.OrgID);
        }
        else
        {
          this.IService.getTeamsbyTeamID(this.data.TeamID).subscribe((tdata:any) =>
          {
            this.newcontract.get("ddlOrganisation").setValue(tdata.value[0].OrganisationID);
            //this.getHOPTeamsByOrganisation(tdata.value[0].OrganisationID);
            //this.getTeamsByOrganisation(data.OrganisationID);
            this.getNonHOPTeamsByOrganisation(Number(sessionStorage.getItem("ContactID")),tdata.value[0].OrganisationID);
          });
        }
      });
    }
  }

  getHOPTeamsByOrganisation(OrganisationID:any)
  {
    
    this.IService.getTeamsbyOrganisation(OrganisationID).subscribe((tdata:any) =>
      {
        this.Teams=tdata.value;
        this.newcontract.get("ddlCategory").setValue(this.data.TeamID);
      });
  }
  getNonHOPTeamsByOrganisation(ContactID:number,OrganisationID:any)
  {
    
    this.IService.getAssignedTeamsForUserbyOrgID(ContactID, OrganisationID).subscribe((tdata:any) =>
      {
        this.Teams=tdata;
        this.newcontract.get("ddlCategory").setValue(this.data.TeamID);
      });
  }

  OrganisationSelectedChange()
  {
    this.getHOPTeamsByOrganisation(this.newcontract.get("ddlOrganisation").value);
  }
}
