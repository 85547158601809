import { Component, OnInit,Inject } from '@angular/core';
import {ProjectDatabaseService} from '../../dashboard/project-database.service';
import {DashboardContractsNewContractService} from '../../dashboard/dashboard-contracts-new-contract/dashboard-contracts-new-contract.service';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';
import {DashboardPerformanceService} from '../../dashboard/dashboard-performance/dashboard-performance.service';
import { DatePipe } from '@angular/common';
import {SettingsService} from '../../settings/settings.service';


@Component({
  selector: 'app-dialog-advance-searchcontract',
  templateUrl: './dialog-advance-searchcontract.component.html',
  styleUrls: ['./dialog-advance-searchcontract.component.scss']
})
export class DialogAdvanceSearchcontractComponent implements OnInit {
  AdvanceSearch:FormGroup;
  Organisation:any;
  Category:any;
  SubCategory:any;
  ExtensionPeriod:any;
  public ContractType = [];
  ExtentionNo:boolean=false;
  showOrg : boolean = true;
  Names:any;
  constructor(private Dservice : DashboardPerformanceService,private router:Router,private DbService:ProjectDatabaseService,private ContractService:DashboardContractsNewContractService,
             private fb:FormBuilder,public dialogRef: MatDialogRef<DialogAdvanceSearchcontractComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private setService:SettingsService) {
    this.LoadAllCaptions();
	if(sessionStorage.getItem("usertype") =="Head of Procurement")
    {
      this.getOrganisation();
     
    }
    else
    {
      this.showOrg =false;
      this.getOrganisationForNonHop();
    }
   
    this.getCategory();
    this.getExtensionPeriod();
    this.getContractTypes();
   }

  ngOnInit() {
    this.AdvanceSearch=this.fb.group({
      ddlOrganisation:[''],
      txtContractsTitle :[''],
      txtContractsRefNo:[''],
      ddlCategory:[''],
      ddlSubCategory : [''],
      txtContractOwner : [''],
      ddlPriority : [''],
      ddlReProcurementIntension : [''],
      txtStartDate :[''],
      txtEndDate :[''],
      ddlExtensionAvailable :[''],
      ddlExtensionPeriod :[''],
      ddlStatus :['']
        });

        this.AdvanceSearch.get("ddlOrganisation").setValue("All");
        this.AdvanceSearch.get("ddlCategory").setValue("All");
        this.AdvanceSearch.get("ddlSubCategory").setValue("All");
        this.AdvanceSearch.get("ddlPriority").setValue("All");
        this.AdvanceSearch.get("ddlReProcurementIntension").setValue("All");
        this.AdvanceSearch.get("ddlExtensionAvailable").setValue("All");
        this.AdvanceSearch.get("ddlExtensionPeriod").setValue("All");
        this.AdvanceSearch.get("ddlStatus").setValue("All");

  }

  getOrganisationForNonHop()
  {
    this.showOrg =false;
    this.Dservice.getOrganisationForNonHOP(sessionStorage.getItem("ContactID")).subscribe((data:any) =>
      {
                this.Organisation=data;
                //this.AdvanceSearch.get("ddlOrganisation").setValue(data[0].OrganisationID);
                //this.nameId = data[0].OrganisationID;
               
        
      });
  }

  getOrganisation()
  {
    this.DbService.getOrganisationByHOP(sessionStorage.getItem("ContactID")).subscribe((data:any)=>
    {
        this.Organisation=data.value;
        
    });
  }

  getCategory()
  {
    this.DbService.getCategory().subscribe((data:any) =>
    {
      
      this.Category=data.value;
    });
  }

  getSubCategory(PK_CategoryTypeID:any)
  {
    console.log(PK_CategoryTypeID);
    if(PK_CategoryTypeID =="All")
    {
      this.SubCategory=null;
    }
    else
    {
      this.DbService.getSubCategoryby(PK_CategoryTypeID).subscribe((data:any)=>
      {
          this.SubCategory=data.value;
      });
    }
   
  }
  getExtensionPeriod()
  {
    this.DbService.getExtensionPeriod().subscribe((data:any) =>
    {
      
      this.ExtensionPeriod=data.value;
    });
  }

  get f() { return this.AdvanceSearch.controls; };
  getContractTypes()
   {
     //alert(this.CategoryId);
   /* this.ContractService.getContractTypes().subscribe((data:any) =>
    {
      this.ContractType=data.value;
      //this.NewProject.get("Reprocurement").setValue("0");
    });*/
    this.ContractType = [];
    this.ContractService.getContractTypes().subscribe((data:any) =>
    {
      //console.log(data);
      let length = data.value.length;
     
      for(let i=0; i< length ; i++)
      {
        // alert(data.value[i].ContractTypeDescription);
        if(data.value[i].PK_ContractTypeID !="96")
        {
         // alert("hi");
         let jsonstring= '{"PK_ContractTypeID" :"'+  data.value[i].PK_ContractTypeID + '","ContractTypeDescription" :"' + data.value[i].ContractTypeDescription + '"}';
      //this.ContractType=data.value;
      //console.log(jsonstring);
      this.ContractType.push(JSON.parse(jsonstring));
        }
      }
    });
   }
   SelectedChnage(ExtendionAvailable:string)
   {
     if(ExtendionAvailable =="No")
     {
       this.ExtensionPeriod=null;
       this.ExtentionNo=true;
     }
     else
     {
       this.ExtentionNo=false;
       this.getExtensionPeriod();
     }
   }

    AddSearch()
    {
       
        var datepipe = new DatePipe('en-US');
    
        const formData=new FormData();
        formData.append("Search", "Yes");
        formData.append("UserType",sessionStorage.getItem("usertype"));
        formData.append("ContactID", this.data.ContactID);
        formData.append("OrganisationID", this.AdvanceSearch.get("ddlOrganisation").value);
        formData.append("ContractTitle", this.AdvanceSearch.get("txtContractsTitle").value);
        formData.append("ContractRefNo", this.AdvanceSearch.get("txtContractsRefNo").value);
        formData.append("Category", this.AdvanceSearch.get("ddlCategory").value);
        formData.append("SubCategory", this.AdvanceSearch.get("ddlSubCategory").value);
        formData.append("ContractOwner", this.AdvanceSearch.get("txtContractOwner").value);
        formData.append("Priority", this.AdvanceSearch.get("ddlPriority").value);
        formData.append("Type", this.AdvanceSearch.get("ddlReProcurementIntension").value);
        formData.append("Status",  this.AdvanceSearch.get("ddlStatus").value);
        formData.append("ExtensionAvailable", this.AdvanceSearch.get("ddlExtensionAvailable").value);
        formData.append("ExtensionPeriod", this.AdvanceSearch.get("ddlExtensionPeriod").value);
        formData.append("StartDate", datepipe.transform(this.AdvanceSearch.get("txtStartDate").value,'MM/dd/yyyy'));
        formData.append("EndDate", datepipe.transform(this.AdvanceSearch.get("txtEndDate").value,'MM/dd/yyyy'));
        this.ContractService.CreateContract(formData).subscribe((data:any) =>
    {
       // alert(data.length + " records found for the search !");
        console.log(data);
        let key = 'Item1';
        sessionStorage.setItem(key,JSON.stringify(data));
        // if(this.AdvanceSearch.get("ddlReProcurementIntension").value =='extend')
        // {
          //alert("hi1");
        //  sessionStorage.setItem('type','Extend');
        // }
        // else if (this.AdvanceSearch.get("ddlReProcurementIntension").value == '95')
        // {
          //alert("hi2");
         // sessionStorage.setItem('type','Reprocure');
        //}
        this.dialogRef.close();
        //this.router.navigate(['dashboard/contracts/database']);
        
       /* if(data =="success")
        {
          //this.router.navigate(['dashboard/contracts']);
          alert("Updated Successfully !");
         
        }
        else{
             alert("Something went wrong !");
        
        }*/
    });
    }
	
	LoadAllCaptions()
  {
    this.setService.getCaptionFromDB("dialog-advance-searchcontract").subscribe((data:any) =>
			{
			  this.Names = data;
			});
	
  }
  

  getCaption (sCaption:string)
  {
       var sResult = sCaption;
	   if (this.Names != null)
	   {
		   this.Names.forEach(element => {
			if (element.OriginalName === sCaption) {
				sResult = element.Value;
			   return false; 	
			 }
			 
		   });
	   }
	   return sResult;
  }
  
}
